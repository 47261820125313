import { API } from 'api';
import { i18n, Session } from 'dawere-commons';
import {
  Avatar,
  Button,
  CountryPicker,
  Dawere,
  Dropdown,
  Flex,
  IBaseProps,
  Logo,
  useRouter,
  Utils
} from 'dawere-uic';
import { useLayout, useSession } from 'hooks';
import { useCallback } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { PATHS } from 'router';
import {
  COUNTRIES_LIST,
  getDashboardPath,
  getFullCountryInfo,
  updateCountryInUrl
} from 'utils';
import { Notifications } from './Notifications';

export function Navbar(props: IBaseProps) {
  const { setUser } = useSession();

  const account = Session.getAccountInfo();
  const organizations = Session.getOrganizations();
  const currentCountry = getFullCountryInfo();

  const { setSidebarOpened, disableCountryPicker } = useLayout();
  const { push } = useRouter();

  const handleCountryChange = useCallback(() => {
    API.Account.updateLocale().finally(() => updateCountryInUrl());
  }, []);

  const handleLanguageChange = useCallback((lang: string) => {
    API.Account.updateLocale().finally(() => window.location.reload());
  }, []);

  const handleLogout = useCallback(() => {
    Session.clean();
    setUser(null);
    push(PATHS.HOME);
  }, [push, setUser]);

  return (
    <Dawere.Navbar
      {...props}
      sticky
      logo={
        <NavLink to={PATHS.HOME}>
          <Logo />
        </NavLink>
      }
      mobile={
        <Dawere.Navbar.Item>
          <Notifications />
        </Dawere.Navbar.Item>
      }
      onToggle={() => setSidebarOpened((status: boolean) => !status)}>
      <Flex align='center'>
        <ul>
          <Dawere.Navbar.Item>
            <NavLink to={PATHS.CATALOG}>
              {i18n('v2.sections.navbar.links.catalog')}
            </NavLink>
          </Dawere.Navbar.Item>
          <Dawere.Navbar.Item>
            <NavLink to={PATHS.ORGANIZATIONS}>
              {i18n('v2.sections.navbar.links.organizations')}
            </NavLink>
          </Dawere.Navbar.Item>
          <Dawere.Navbar.Item className='help-navbar-button'>
            <a
              href=' '
              onClick={(ev: any) => {
                ev.preventDefault();
                API.Zendesk.go();
              }}>
              {i18n('v2.sections.navbar.links.help')}
            </a>
          </Dawere.Navbar.Item>

          {account ? (
            <>
              {/* Notifications */}
              <Dawere.Navbar.Item>
                <Notifications />
              </Dawere.Navbar.Item>

              {/* Profile menu */}
              <Dawere.Navbar.Item>
                <Dropdown
                  toggle={
                    <>
                      <Avatar src={account?.picturePath} />{' '}
                      {Utils.getFirstPart(account?.name)}
                    </>
                  }
                  autoHeight
                  caret
                  align='right'
                  className='mr-2'>
                  <Dropdown.Item
                    hidden={!account?.student}
                    to={PATHS.STUDENTS_DASHBOARD}>
                    {i18n('v2.sections.navbar.links.studentDashboard')}
                  </Dropdown.Item>

                  <Dropdown.Item
                    hidden={!account?.tutor}
                    to={PATHS.TUTORS_DASHBOARD}>
                    {i18n('v2.sections.navbar.links.parentDashboard')}
                  </Dropdown.Item>

                  {organizations.map((organization: any) => (
                    <Dropdown.Item
                      key={organization.id}
                      hidden={organizations.length > 3}
                      to={getDashboardPath(organization)}>
                      {i18n('v2.sections.navbar.links.dashboardOf', {
                        name: organization.name
                      })}
                    </Dropdown.Item>
                  ))}

                  <Dropdown.Divider
                    hidden={
                      !(
                        account?.student ||
                        account?.tutor ||
                        (organizations.length <= 3 && organizations.length > 0)
                      )
                    }
                  />

                  {/* <Dropdown.Divider
                  hidden={
                    organizations.length === 0 || organizations.length > 3
                  }
                /> */}

                  <Dropdown.Item
                    to={PATHS.MY_ORGANIZATIONS}
                    hidden={!account?.organization}>
                    <Flex gap={2} align='center' justify='between'>
                      {i18n('v2.sections.navbar.links.myOrganizations')}
                      <span className='rounded bg-powderTint px-2 fs-small d-inline-block'>
                        {organizations.length}
                      </span>
                    </Flex>
                  </Dropdown.Item>
                  <Dropdown.Item to={PATHS.PROFILE}>
                    {i18n('v2.sections.navbar.links.profile')}
                  </Dropdown.Item>
                  <Dropdown.Item to={PATHS.SETTINGS}>
                    {i18n('v2.sections.navbar.links.settings')}
                  </Dropdown.Item>
                  <Dropdown.Item to={PATHS.PERMISSIONS}>
                    {i18n('v2.sections.navbar.links.permissions')}
                  </Dropdown.Item>
                  <Dropdown.Item to={PATHS.WALLET}>
                    {i18n('v2.sections.navbar.links.wallet')}
                  </Dropdown.Item>

                  <Dropdown.Divider />

                  <Dropdown.Item onClick={handleLogout}>
                    {i18n('v2.sections.navbar.links.closeSession')}
                  </Dropdown.Item>
                </Dropdown>
              </Dawere.Navbar.Item>
            </>
          ) : null}
        </ul>

        {account ? null : (
          <>
            <Button
              variant='outline'
              className='mx-2'
              as={Link}
              to={PATHS.LOGIN}>
              {i18n('v2.sections.navbar.buttons.login')}
            </Button>
            <Button
              variant='secondary'
              className='mr-2 | click_register'
              as={Link}
              to={PATHS.REGISTER}>
              {i18n('v2.sections.navbar.buttons.register')}
            </Button>
          </>
        )}

        <CountryPicker
          countries={COUNTRIES_LIST}
          onChangeCountry={handleCountryChange}
          onChangeLanguage={handleLanguageChange}
          disabled={disableCountryPicker}
        />
      </Flex>
    </Dawere.Navbar>
  );
}
