import { i18n, Session } from 'dawere-commons';
import { toast, useRouter, Utils } from 'dawere-uic';
import { useSession } from 'hooks';
import { Redirect } from 'react-router-dom';
import { addCountryToPath } from 'utils';
import { QPARAMS } from 'values';
import { PATHS } from './paths';

interface IPageRenderer {
  view: JSX.Element;
  restricted?: boolean;
}
export function PageRenderer({ view, restricted = false }: IPageRenderer) {
  const token = Session.getToken();
  const { location } = useRouter();
  const { setUser } = useSession();

  if (token) {
    // Check if session is expired
    const decoded = Utils.decodeJWT(token);
    const { exp } = decoded;

    // Expired session
    if (exp * 1000 < Date.now()) {
      toast.warning(i18n('ui.commons.sessionExpired'));
      Session.clean();
      setUser(null);

      return restricted ? (
        <Redirect
          to={PATHS.get(
            'LOGIN',
            {},
            {
              [QPARAMS.Redirect]: addCountryToPath(
                location.pathname + location.search
              )
            }
          )}
        />
      ) : (
        view
      );
    }
  } else {
    return restricted ? (
      <Redirect
        to={PATHS.get(
          'LOGIN',
          {},
          {
            [QPARAMS.Redirect]: addCountryToPath(
              location.pathname + location.search
            )
          }
        )}
      />
    ) : (
      view
    );
  }

  return view;
}
