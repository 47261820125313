import { API } from 'api';
import { i18n } from 'dawere-commons';
import { Button, Modal, Utils } from 'dawere-uic';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import ErrorModal from './ErrorModal';
import { PayingModal } from './PayingModal';

export function DirectCheckout({
  installments = [],
  onSuccess = () => {},
  onError = () => {},
  redirectTo = '',
  isBillingInfoCompleted = () => true,
  loading = false,
  disabled = false,
  walletAmount = null
}: any) {
  const [openRedirectModal, setOpenRedirectModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [paying, setPaying] = useState(false);
  const [asyncId, setAsyncId] = useState<string | null>(null);

  const handleClick = useCallback(() => {
    setPaying(true);

    if (walletAmount) {
      const payload = {
        installments,
        walletAmount
      };

      API.Cart.createWalletPayment(payload)
        .then(({ asyncProcessId }: any) => {
          if (Utils.isString(asyncProcessId)) {
            setAsyncId(asyncProcessId);
          } else {
            setPaying(false);
            onSuccess('directo');
            setOpenRedirectModal(true);
          }
        })
        .catch(() => {
          setPaying(false);
          onError();
          setOpenErrorModal(true);
        });
    } else {
      const payload = {
        installments
      };

      API.Cart.createDirectPayment(payload)
        .then(({ asyncProcessId }: any) => {
          if (Utils.isString(asyncProcessId)) {
            setAsyncId(asyncProcessId);
          } else {
            setPaying(false);
            onSuccess('directo');
            setOpenRedirectModal(true);
          }
        })
        .catch(() => {
          setPaying(false);
          onError();
          setOpenErrorModal(true);
        });
    }
  }, [installments, onError, onSuccess, walletAmount]);

  return (
    <>
      <Button
        block
        onClick={handleClick}
        disabled={!isBillingInfoCompleted() || loading || disabled}>
        {i18n('v2.pages.buyCoupons.steps.2.summary.confirmPayment')}
      </Button>

      <Modal
        type='success'
        open={openRedirectModal}
        backdrop='static'
        onClose={setOpenRedirectModal}
        closable={false}
        keyboard={false}
        title={i18n('v2.pages.buyCoupons.steps.2.creditCard.modal.title')}
        footer={
          <Button block as={Link} to={redirectTo}>
            {i18n('v2.pages.buyCoupons.steps.2.creditCard.modal.button')}
          </Button>
        }>
        <p
          dangerouslySetInnerHTML={{
            __html: i18n(
              'v2.pages.buyCoupons.steps.2.creditCard.modal.description'
            )
          }}
        />
      </Modal>

      <ErrorModal
        open={openErrorModal}
        onClose={setOpenErrorModal}
        redirectTo={redirectTo}
      />

      <PayingModal
        paying={paying}
        onClose={setPaying}
        asyncId={asyncId}
        onFinished={() => {
          setPaying(false);
          onSuccess('directo');
          setOpenRedirectModal(true);
        }}
        onError={() => {
          setPaying(false);
          onError();
          setOpenErrorModal(true);
        }}
      />
    </>
  );
}
