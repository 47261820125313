import { i18n } from 'dawere-commons';
import { Button, Flex } from 'dawere-uic';
import { PageWrapper } from 'pages';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { ASSETS } from 'values';

/**
 * Path: /token-expirado
 * Key: INVALID_TOKEN
 * @returns {React.ReactElement}
 */
export default function InvalidDataPage() {
  return (
    <PageWrapper>
      <Helmet>
        <title>El token ha expirado</title>
        <meta name='robots' content='noindex,nofollow' />
      </Helmet>

      <Flex
        direction='column'
        align='center'
        justify='center'
        className='p-3 py-6'>
        <img
          src={ASSETS.ILLUSTRATIONS.TOKEN_EXPIRED}
          alt='404'
          className='img-fluid w-md-35 | mb-4'
        />

        <h2 className='text-center mb-2'>Token expirado</h2>

        <h4 className='text-center mb-5 text-muted'>
          El token que se generó y que deseas usar ya ha expirado su tiempo.
        </h4>

        <Button as={Link} to={PATHS.HOME}>
          {i18n('v2.sections.navbar.links.home')}
        </Button>
      </Flex>
    </PageWrapper>
  );
}
