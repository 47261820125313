export const PDF_BASE_FONT_SIZE = 9;

export const PDF_MUTED_COLOR = '#74787b';

export const PDF_PAGE_STYLES = {
  fontFamily: 'Roboto',
  backgroundColor: '#fff',
  paddingTop: 35,
  paddingBottom: 50,
  paddingHorizontal: 35
};
