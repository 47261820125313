import { API } from 'api';
import { i18n } from 'dawere-commons';
import {
  Avatar,
  Button,
  Card,
  Col,
  Confirm,
  Container,
  Flex,
  Row,
  toast,
  Utils,
  Visible
} from 'dawere-uic';
import { useCallback, useState } from 'react';

export function PendingCard({
  invitation,
  onAccept = () => {},
  onReject = () => {},
  ...rest
}: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  const handleAccept = useCallback(() => {
    setIsLoading(true);

    API.Invitations.accept(invitation.id)
      .then(() => {
        toast.success(
          i18n('v2.pages.permissions.pending.toasts.successAccept', {
            name: Utils.getFullname(
              invitation.sender?.name,
              invitation.sender?.lastName
            )
          })
        );
        onAccept();
      })
      .finally(() => setIsLoading(false));
  }, [
    invitation.id,
    invitation.sender?.lastName,
    invitation.sender?.name,
    onAccept
  ]);

  const handleReject = useCallback(() => {
    setIsLoading(true);

    API.Invitations.reject(invitation.id)
      .then(() => {
        toast.success(
          i18n('v2.pages.permissions.pending.toasts.successReject', {
            name: Utils.getFullname(
              invitation.sender?.name,
              invitation.sender?.lastName
            )
          })
        );
        onReject();
      })
      .finally(() => setIsLoading(false));
  }, [
    invitation.id,
    invitation.sender?.lastName,
    invitation.sender?.name,
    onReject
  ]);

  return (
    <Card className='py-3 px-2 mb-3 translate-left-animation'>
      <Container fluid>
        <Row>
          <Col xs={12} md={7} lg={9}>
            <Flex align='start'>
              <Flex kept align='center'>
                <Visible md lg xl>
                  <span
                    className='bg-secondary d-inline-block rounded-circle mr-3'
                    style={{ height: '15px', width: '15px' }}></span>
                </Visible>

                <Avatar
                  src={invitation.sender?.profilePicture}
                  icon={Utils.getInitialLetters(
                    invitation.sender?.name,
                    invitation.sender?.lastName
                  )}
                  size={60}
                  className='mr-3'
                />
              </Flex>
              <div>
                <h4 className='m-0'>
                  {Utils.getFullname(
                    invitation.sender?.name,
                    invitation.sender?.lastName
                  )}
                </h4>
                <span className='d-block text-muted mb-2'>
                  {invitation.sender?.role}
                </span>
                <p>{invitation.displayText}</p>
              </div>
            </Flex>
          </Col>
          <Col xs={12} md={5} lg={3} className='text-md-right'>
            <Button
              variant='outline'
              className='w-100 w-md-auto | mt-4 mt-md-0 | mr-0 mr-md-2'
              onClick={() => setIsOpenConfirm(true)}
              loading={isLoading}>
              {i18n('v2.pages.permissions.pending.buttons.reject')}
            </Button>

            <Button
              className='w-100 w-md-auto | mt-2 mt-md-0'
              onClick={() => handleAccept()}
              loading={isLoading}>
              {i18n('v2.pages.permissions.pending.buttons.accept')}
            </Button>
          </Col>
        </Row>
      </Container>

      <Confirm
        type='warning'
        title={i18n('v2.pages.permissions.pending.modals.title')}
        open={isOpenConfirm}
        onClose={setIsOpenConfirm}
        onConfirm={handleReject}
        confirmText={i18n('v2.pages.permissions.pending.buttons.reject')}>
        <p
          dangerouslySetInnerHTML={{
            __html: i18n('v2.pages.permissions.pending.modals.text', {
              name: Utils.getFullname(
                invitation.sender?.name,
                invitation.sender?.lastName
              )
            })
          }}
        />
      </Confirm>
    </Card>
  );
}
