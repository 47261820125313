import { Block, Col, Hidden, IBaseProps, Visible } from 'dawere-uic';

interface SummaryPageWrapperProps extends IBaseProps {
  children?: React.ReactNode;
  mobileImage?: React.ReactNode;
  desktopImage?: React.ReactNode;
  align?: 'normal' | 'start' | 'center' | 'end' | 'stretch';
}

export function TwoColumnTemplate({
  children,
  mobileImage,
  desktopImage,
  align = 'center'
}: SummaryPageWrapperProps) {
  return (
    <Block narrow align={align}>
      <Col offset={{ md: 3, lg: 0 }} md={6} lg={5}>
        {mobileImage ? (
          <Visible xs sm md>
            <div className='text-center mb-5'>{mobileImage}</div>
          </Visible>
        ) : null}

        {children}
      </Col>

      {desktopImage ? (
        <Hidden xs sm md>
          <Col lg={7}>
            <div className='text-center'>{desktopImage}</div>
          </Col>
        </Hidden>
      ) : null}
    </Block>
  );
}
