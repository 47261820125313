import { Locales } from 'dawere-commons';
import { enCO, esDO, esHN, esMX, esUS, esVE } from 'i18n';

export enum Environment {
  Development = 'dev',
  QA = 'qa',
  Production = 'prod'
}

export const getEnvironment = (): Environment => {
  if (
    window.location.host.includes('localhost') ||
    window.location.host.includes('dev.')
  ) {
    return Environment.Development;
  } else if (window.location.host.includes('qa.')) {
    return Environment.QA;
  } else {
    return Environment.Production;
  }
};

export const temporalRedirect = (args: string, isLogin = false): any => {
  if (window.localStorage.getItem('disable_temporal_redirect')) {
    return args;
  }

  switch (Locales.getLocale()) {
    case esVE.lang:
      return `https://app.dawere.com.ve${isLogin ? '' : '/#!/?register=true'}`;
    case esMX.lang:
      return `https://app.dawere.com.mx${isLogin ? '' : '/#!/?register=true'}`;
    case enCO.lang:
      return `https://app.dawere.com.co${isLogin ? '' : '/#!/?register=true'}`;
    case esDO.lang:
      return `https://app.dawere.com.do${isLogin ? '' : '/#!/?register=true'}`;
    case esHN.lang:
      return `https://app.dawere.com.hn${isLogin ? '' : '/#!/?register=true'}`;
    case esUS.lang:
      return `https://app.dawere.us${isLogin ? '' : '/#!/?register=true'}`;
    default:
      return `https://app.dawere.us${isLogin ? '' : '/#!/?register=true'}`;
  }
};
