import { API } from 'api';
import { i18n, LOCAL_STORAGE_STARTED_KEY } from 'dawere-commons';
import {
  Block,
  Col,
  CountryPicker,
  Logo,
  useLocalStorage,
  Visible
} from 'dawere-uic';
import { PageWrapper } from 'pages';
import { useCallback } from 'react';
import { Redirect } from 'react-router';
import { PATHS } from 'router';
import { COUNTRIES_LIST, getSEO, updateCountryInUrl } from 'utils';
import { ASSETS } from 'values';

/**
 * Path: / | /paises
 * Key: ROOT | COUNTRIES
 * @returns {React.ReactElement}
 */
export default function CountryPickerPage() {
  const [started] = useLocalStorage<boolean>(LOCAL_STORAGE_STARTED_KEY, false);

  const handleChange = useCallback(() => {
    API.Account.updateLocale().finally(() => updateCountryInUrl());
  }, []);

  if (started) {
    return <Redirect to={PATHS.HOME} />;
  }

  return (
    <PageWrapper>
      {getSEO('v2.pages.countrySelection.seo')}

      <Block
        align='center'
        className='vh-100 d-flex align-items-center my-0 py-4'>
        <Col md={6} lg={5}>
          <div className='text-center text-md-left'>
            <Logo variant='FULL' size={300} />
          </div>

          <Visible xs sm>
            <div className='text-center mb-0 mt-4'>
              <img
                src={ASSETS.ILLUSTRATIONS.TOURIST}
                alt={i18n('v2.pages.countrySelection.title')}
                className='img-fluid'
              />
            </div>
          </Visible>

          <h2 className='mt-6'>{i18n('v2.pages.countrySelection.title')}</h2>
          <h4 className='mb-6'>{i18n('v2.pages.countrySelection.subtitle')}</h4>

          <CountryPicker
            variant='select'
            countries={COUNTRIES_LIST}
            onChangeCountry={handleChange}
          />
        </Col>

        <Visible md lg xl>
          <Col md={6} lg={7}>
            <div className='text-center'>
              <img
                src={ASSETS.ILLUSTRATIONS.TOURIST}
                alt={i18n('v2.pages.countrySelection.title')}
                className='img-fluid'
              />
            </div>
          </Col>
        </Visible>
      </Block>
    </PageWrapper>
  );
}
