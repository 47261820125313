import { Locales, i18n } from 'dawere-commons';
import { Helmet } from 'react-helmet';

export interface SEOProps {
  title: string;
  description?: string;
  keywords?: string;
  creator?: string;
  language?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: string;
}

const defaultValues: SEOProps = {
  title: 'Dawere.com: Educación Virtual de calidad',
  description:
    'Dawere.com es una plataforma que ofrece soluciones educativas online.',
  keywords: 'dawere, educacion, educacion virtual, educacion online',
  creator: '@dawereoficial',
  language: 'es_ES',
  ogTitle: '',
  ogDescription: '',
  ogImage: ''
};

function getValue(
  value: string | SEOProps,
  key:
    | 'title'
    | 'description'
    | 'keywords'
    | 'creator'
    | 'language'
    | 'ogTitle'
    | 'ogDescription'
    | 'ogImage'
) {
  let ret =
    typeof value === 'string'
      ? i18n(`${value}.${key}`)
      : value[key] || defaultValues[key];

  if (ret?.charAt(0) === '≪') {
    return defaultValues[key];
  }

  return ret;
}

export function getSEO(
  value: string | SEOProps = defaultValues,
  noindex = false
) {
  const url = `${window.location.origin}${window.location.pathname}`;

  const title = getValue(value, 'title');
  const description = getValue(value, 'description');
  const keywords = getValue(value, 'keywords');
  const creator = getValue(value, 'creator');
  const ogTitle = getValue(value, 'ogTitle') || title;
  const ogDescription = getValue(value, 'ogDescription') || description;
  const image = getValue(value, 'ogImage');

  const lang = Locales.getLanguage();
  const country = Locales.getCountry();
  const locale = `${lang}-${country}`;
  const language = `${lang}_${country.toUpperCase()}`;

  return (
    <Helmet htmlAttributes={{ lang: locale }}>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />
      <link rel='canonical' href={url} />

      {/* Twitter */}
      <meta
        name='twitter:card'
        content={image ? 'summary_large_image' : 'summary'}
      />
      {image && <meta name='twitter:image' content={image} />}
      <meta name='twitter:site' content={creator} />
      <meta name='twitter:creator' content={creator} />

      {/* OpenGraph */}
      <meta property='og:locale' content={language} />
      <meta property='og:site_name' content='Dawere.com' />
      <meta property='og:type' content='website' />
      <meta property='og:url' content={url} />
      <meta property='og:title' content={ogTitle} />
      <meta property='og:description' content={ogDescription} />
      {image && <meta property='og:image' content={image} />}

      {noindex && <meta name='robots' content='noindex,nofollow' />}
    </Helmet>
  );
}
