import { Utils } from 'dawere-uic';
import { useContext } from 'react';
import { PaymentContext } from '../PaymentProvider';

export const usePayment = () => {
  const context = useContext(PaymentContext);

  if (Utils.isEmpty(context)) {
    throw new Error('"usePayment" must be used within a PaymentProvider');
  }

  return context;
};
