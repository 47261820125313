import { Utils } from 'dawere-uic';
import { useContext } from 'react';
import { FreeEnrollContext } from '../FreeEnrollProvider';

export const useFreeEnroll = () => {
  const context = useContext(FreeEnrollContext);

  if (Utils.isEmpty(context)) {
    throw new Error('"useFreeEnroll" must be used within a FreeEnrollProvider');
  }

  return context;
};
