import { addTranslation, setDefaultTranslation } from 'dawere-commons';
import { addUITranslations } from 'dawere-uic';
import {
  en,
  enCO,
  enDO,
  enHN,
  enMX,
  enUS,
  enVE,
  es,
  esCO,
  esDO,
  esHN,
  esMX,
  esUS,
  esVE
} from 'i18n';

export function i18nSetup() {
  setDefaultTranslation({ v2: es });

  addUITranslations();

  // Base languages
  addTranslation({ v2: es }, es.lang);
  addTranslation({ v2: en }, en.lang);

  // Spanish
  addTranslation({ v2: esCO }, esCO.shortLang);
  addTranslation({ v2: esDO }, esDO.shortLang);
  addTranslation({ v2: esHN }, esHN.shortLang);
  addTranslation({ v2: esMX }, esMX.shortLang);
  addTranslation({ v2: esUS }, esUS.shortLang);
  addTranslation({ v2: esVE }, esVE.shortLang);
  // English
  addTranslation({ v2: enCO }, enCO.shortLang);
  addTranslation({ v2: enDO }, enDO.shortLang);
  addTranslation({ v2: enHN }, enHN.shortLang);
  addTranslation({ v2: enMX }, enMX.shortLang);
  addTranslation({ v2: enUS }, enUS.shortLang);
  addTranslation({ v2: enVE }, enVE.shortLang);
}
