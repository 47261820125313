import { API } from 'api';
import { useSession } from 'hooks';
import React, { useCallback, useEffect, useState } from 'react';

export interface PermissionsProps {
  invitations: any[];
  isLoadingInvitations: boolean;
  loadInvitations: () => void;

  myOrganizations: any[];
  isLoadingMyOrganizations: boolean;
  loadMyOrganizations: () => void;

  myStudents: any[];
  isLoadingMyStudents: boolean;
  loadMyStudents: () => void;

  myTutors: any[];
  myTutorInvitations: any[];
  isLoadingMyTutors: boolean;
  loadMyTutors: () => void;

  myAuthorizedOrganizations: any[];
  isLoadingMyAuthorizedOrganizations: boolean;
  loadMyAuthorizedOrganizations: () => void;

  onInvitationChange: () => void;
}

export const PermissionsContext = React.createContext<PermissionsProps>({
  invitations: [],
  isLoadingInvitations: false,
  loadInvitations: () => {},

  myStudents: [],
  isLoadingMyStudents: false,
  loadMyStudents: () => {},

  myOrganizations: [],
  isLoadingMyOrganizations: false,
  loadMyOrganizations: () => {},

  myTutors: [],
  myTutorInvitations: [],
  isLoadingMyTutors: false,
  loadMyTutors: () => {},

  myAuthorizedOrganizations: [],
  isLoadingMyAuthorizedOrganizations: false,
  loadMyAuthorizedOrganizations: () => {},

  onInvitationChange: () => {}
});

export function PermissionsProvider({ children }: any) {
  const { getMe } = useSession();

  const [invitations, setInvitations] = useState<any[]>([]);
  const [isLoadingInvitations, setIsLoadingInvitations] = useState(false);

  const [myOrganizations, setMyOrganizations] = useState<any[]>([]);
  const [isLoadingMyOrganizations, setIsLoadingMyOrganizations] =
    useState(false);

  const [myStudents, setMyStudents] = useState<any[]>([]);
  const [isLoadingMyStudents, setIsLoadingMyStudents] = useState(false);

  const [myTutors, setMyTutors] = useState<any[]>([]);
  const [myTutorInvitations, setMyTutorInvitations] = useState<any[]>([]);
  const [isLoadingMyTutors, setIsLoadingMyTutors] = useState(false);

  const [myAuthorizedOrganizations, setMyAuthorizedOrganizations] = useState<
    any[]
  >([]);
  const [
    isLoadingMyAuthorizedOrganizations,
    setIsLoadingMyAuthorizedOrganizations
  ] = useState(false);

  // Pending invitations
  const loadInvitations = useCallback(() => {
    setIsLoadingInvitations(true);

    API.Invitations.get({ size: 999 })
      .then((response: any) => {
        setInvitations(response.data.content);
      })
      .finally(() => setIsLoadingInvitations(false));
  }, []);

  useEffect(() => {
    loadInvitations();
  }, [loadInvitations]);

  // My organizations
  const loadMyOrganizations = useCallback(() => {
    setIsLoadingMyOrganizations(true);

    API.Organizations.list({
      size: 999
    })
      .then((response: any) => {
        setMyOrganizations(response.content);
      })
      .finally(() => setIsLoadingMyOrganizations(false));
  }, []);

  useEffect(() => {
    loadMyOrganizations();
  }, [loadMyOrganizations]);

  // My tutors
  const loadMyTutors = useCallback(() => {
    setIsLoadingMyTutors(true);

    API.Tutors.list({
      size: 999
    })
      .then((response: any) => {
        setMyTutors(response.content);
        return API.Tutors.getTutorInvitations();
      })
      .then((response) => {
        setMyTutorInvitations(response.data);
      })
      .finally(() => setIsLoadingMyTutors(false));
  }, []);

  useEffect(() => {
    loadMyTutors();
  }, [loadMyTutors]);

  // My students
  const loadMyStudents = useCallback(() => {
    setIsLoadingMyStudents(true);

    API.Tutors.getStudents({
      size: 999
    })
      .then((response: any) => {
        setMyStudents(response.data);
      })
      .finally(() => setIsLoadingMyStudents(false));
  }, []);

  useEffect(() => {
    loadMyStudents();
  }, [loadMyStudents]);

  // My authorized organizations
  const loadMyAuthorizedOrganizations = useCallback(() => {
    setIsLoadingMyAuthorizedOrganizations(true);

    API.Account.getMyAuthorizedOrganizations()
      .then((response: any) => {
        setMyAuthorizedOrganizations(response.data);
      })
      .finally(() => setIsLoadingMyAuthorizedOrganizations(false));
  }, []);

  useEffect(() => {
    loadMyAuthorizedOrganizations();
  }, [loadMyAuthorizedOrganizations]);

  // Accept or decline invitations
  const onInvitationChange = useCallback(() => {
    getMe().then(() => {
      loadInvitations();
      loadMyOrganizations();
      loadMyTutors();
      loadMyStudents();
      loadMyAuthorizedOrganizations();
    });
  }, [
    getMe,
    loadInvitations,
    loadMyAuthorizedOrganizations,
    loadMyOrganizations,
    loadMyStudents,
    loadMyTutors
  ]);

  return (
    <PermissionsContext.Provider
      value={{
        invitations,
        isLoadingInvitations,
        loadInvitations,

        myOrganizations,
        isLoadingMyOrganizations,
        loadMyOrganizations,

        myStudents,
        isLoadingMyStudents,
        loadMyStudents,

        myTutors,
        myTutorInvitations,
        isLoadingMyTutors,
        loadMyTutors,

        myAuthorizedOrganizations,
        isLoadingMyAuthorizedOrganizations,
        loadMyAuthorizedOrganizations,

        onInvitationChange
      }}>
      {children}
    </PermissionsContext.Provider>
  );
}
