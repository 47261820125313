import { i18n } from 'dawere-commons';
import { Button, IBaseProps, Icons } from 'dawere-uic';
import { useCallback } from 'react';
import { GoogleLogin } from 'react-google-login';

interface GoogleButtonProps extends IBaseProps {
  onAuth: (token: string) => void;
  text?: string;
  loading?: boolean;
}

export function GoogleButton({
  onAuth = (token: string) => {},
  loading = false,
  text = i18n('ui.commons.googleLogIn'),
  ...rest
}: GoogleButtonProps) {
  const handleGoogleResponse = useCallback(
    ({ tokenId }: any) => {
      onAuth(tokenId);
    },
    [onAuth]
  );

  const handleFailure = () => {};

  return (
    <GoogleLogin
      clientId='118121622583-o73ftkrj0h6f035ror64u79pled0k8ib.apps.googleusercontent.com'
      buttonText={i18n('ui.commons.googleLogIn')}
      onSuccess={handleGoogleResponse}
      onFailure={handleFailure}
      cookiePolicy={'single_host_origin'}
      render={({ onClick, disabled }) => (
        <Button
          variant='outline'
          block
          onClick={onClick}
          disabled={disabled || loading}
          icon={<Icons.Google colored />}
          {...rest}>
          {text}
        </Button>
      )}
    />
  );
}
