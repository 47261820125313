import React, { useState } from 'react';

/**
 * Colocar acá las propiedades y métodos que se desea hacer accesibles
 * desde cualquier parte del código. Estas deben coincidir con lo que se expone
 * en el "value" del "LayoutContext.Provider" abajo.
 */
export interface LayoutProps {
  disableCountryPicker: boolean;
  setDisableCountryPicker: React.Dispatch<React.SetStateAction<boolean>>;

  sidebarOpened: boolean;
  setSidebarOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LayoutContext = React.createContext<LayoutProps>({
  disableCountryPicker: false,
  setDisableCountryPicker: () => {},
  sidebarOpened: false,
  setSidebarOpened: () => {}
});

export function LayoutProvider({ children }: any) {
  /**
   * Variables y métodos a ser expuestos en toda la app.
   */
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const [disableCountryPicker, setDisableCountryPicker] = useState(false);

  return (
    <LayoutContext.Provider
      value={{
        sidebarOpened,
        setSidebarOpened,
        disableCountryPicker,
        setDisableCountryPicker
      }}>
      {children}
    </LayoutContext.Provider>
  );
}
