import { i18n } from 'dawere-commons';
import { Col, Tab, TabList, TabPanel, Tabs, useMatchMedia } from 'dawere-uic';
import { useCallback } from 'react';
import { NumberParam, useQueryParams } from 'use-query-params';
import { QPARAMS } from 'values';
import DocumentsTab from './DocumentsTab';
import { HistoricalTab } from './HistoricalTab';
import { InCourseTab } from './InCourseTab';
import { PaymentsAndInstallmentsTab } from './PaymentsAndInstallmentsTab';
import { ScorecardsTab } from './ScorecardsTab';

export function TabsSection() {
  const { isMobile } = useMatchMedia();
  const [query, setQuery] = useQueryParams({
    [QPARAMS.Tab]: NumberParam
  });

  const handleTabSelection = useCallback(
    (value: number) => {
      setQuery({ [QPARAMS.Tab]: value }, 'replace');
    },
    [setQuery]
  );

  return (
    <Col xs={12}>
      <Tabs
        onSelect={handleTabSelection}
        selectedIndex={query[QPARAMS.Tab] || 0}>
        <TabList
          type='pills'
          justify={isMobile ? 'true' : undefined}
          scrollable='true'
          className='mb-4'>
          <Tab className='student-dashboard-tab-1'>
            {i18n('v2.pages.studentDashboard.tabs.1.title')}
          </Tab>
          {/* <Tab>{i18n('v2.pages.studentDashboard.tabs.5.title')}</Tab> */}
          <Tab className='student-dashboard-tab-2'>
            {i18n('v2.pages.studentDashboard.tabs.2.title')}
          </Tab>
          <Tab>{i18n('v2.pages.studentDashboard.tabs.3.title')}</Tab>
          <Tab>{i18n('v2.pages.studentDashboard.tabs.4.title')}</Tab>
          <Tab>{i18n('v2.pages.studentDashboard.tabs.6.title')}</Tab>
        </TabList>

        {/* Tab de materias */}
        <TabPanel>
          <InCourseTab />
        </TabPanel>

        {/* Tab de calendario */}
        {/* <TabPanel>
          <CalendarTab />
        </TabPanel> */}

        {/* Tab de docs */}
        <TabPanel>
          <DocumentsTab />
        </TabPanel>

        {/* Tab historico */}
        <TabPanel>
          <HistoricalTab />
        </TabPanel>

        {/* Tab de pagos */}
        <TabPanel>
          <PaymentsAndInstallmentsTab />
        </TabPanel>

        {/* Boletines */}
        <TabPanel>
          <ScorecardsTab />
        </TabPanel>
      </Tabs>
    </Col>
  );
}
