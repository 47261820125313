function createItem(data: any, value = 'id', label = 'itemValue') {
  return data
    ? {
        value: data[value],
        label: data[label]
      }
    : null;
}

export function getData(response: any) {
  const data = {
    name: response.name,
    billingAddress: response.billingAddress,
    postalCode: response.postalCode,
    tin: response.tin,
    country: createItem(response?.country, 'id', 'name'),
    state: createItem(response?.state, 'id', 'name'),
    township: createItem(response?.township, 'id', 'name'),
    id: response.id,
    role: response.role
  };

  return data;
}
