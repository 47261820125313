import { API } from 'api';
import { i18n } from 'dawere-commons';
import { Callout, Col, Empty, Flex, Row, Skeleton, Utils } from 'dawere-uic';
import { useOrganization } from 'hooks';
import { useEffect, useState } from 'react';

export function WalletTab() {
  const { organization } = useOrganization();

  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    API.Organizations.getBalance(organization?.id)
      .then((response: any) => {
        setData(response.data);
      })
      .finally(() => setIsLoading(false));
  }, [organization?.id]);

  if (isLoading) {
    return (
      <Row>
        <Col xs={12}>
          <Skeleton.Paragraph lines={2} />
        </Col>

        {Array(3)
          .fill({})
          .map((_, index: number) => (
            <Col key={index} xs={12} md={6} lg={4}>
              <Skeleton.Image height={80} />
            </Col>
          ))}
      </Row>
    );
  }

  return (
    <>
      <Row>
        {data.length ? (
          <>
            <Col xs={12}>
              <p>
                {i18n('v2.pages.organizationDashboard.tabs.6.description')}!
              </p>
            </Col>

            {data.map((item: any) => (
              <Col xs={12} md={6} lg={4}>
                <Callout className='mb-2'>
                  <Flex align='center' justify='between'>
                    <small className='text-muted'>
                      Total {item.currency.isoCode}
                    </small>

                    <strong className='fs-1'>
                      {item.currency.symbol}
                      {Utils.toCurrency(item.value)}
                    </strong>
                  </Flex>
                </Callout>
              </Col>
            ))}
          </>
        ) : (
          <Col>
            <Empty />
          </Col>
        )}
      </Row>
    </>
  );
}
