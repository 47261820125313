import { Rest, i18n } from 'dawere-commons';
import { Badge, Card, Col, Row, Skeleton } from 'dawere-uic';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import styled from 'styled-components';
import { trackEvent } from 'utils';

const TertiaryCardStyled = styled(Card)`
  margin: var(--dw-spacer-1);
  width: 100%;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
  overflow: hidden;

  .description {
    background-color: #f8f9fa;
    border-bottom: solid 1px var(--dw-color-platinium);
    padding: var(--dw-spacer-4);
    padding-top: var(--dw-spacer-6);
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: end;

    h3 {
    }

    p {
      margin: 0;
    }
  }

  .details {
    padding: var(--dw-spacer-4);
    display: flex;
    align-items: center;
    min-height: 160px;
  }

  a {
    color: var(--dw-color-text);

    &:hover {
      color: var(--dw-color-text);
      text-decoration: none;
    }
  }

  :hover {
    .description {
      background-color: var(--dw-color-powderTint);
      border-bottom-color: transparent;
      /* color: #fff; */
      border-bottom-right-radius: 50px;

      h3 {
        /* color: #fff; */
      }
    }
  }

  @media (min-width: 768px) {
    .description {
      padding: var(--dw-spacer-5);
      padding-top: var(--dw-spacer-6);
      height: calc(100% - 160px);
    }

    .details {
      p {
        margin: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        @supports (-webkit-line-clamp: 4) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: initial;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
      }

      img {
        max-height: 100px;
        width: auto;
      }
    }
  }
`;

export function TertiaryCard({
  resourceId,
  title,
  description,
  color,
  thumbnail,
  url,
  friendlyPath
}: any) {
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (url && !data) {
      setIsLoading(true);

      Rest.get(url)
        .then(({ data }) => {
          setData(data);
        })
        .finally(() => setIsLoading(false));
    }
  }, [data, url]);

  const handleClick = useCallback(() => {
    trackEvent('program_clicks', { program_name: title });
  }, [title]);

  return (
    <div className='p-2 h-100'>
      <TertiaryCardStyled color={color}>
        <Link
          to={PATHS.get('PROGRAM', { programId: friendlyPath })}
          onClick={handleClick}>
          <div className='description'>
            <h3 className='text-primary'>{title}</h3>

            <p>{description}</p>

            {data?.freeTrial && (
              <div>
                <Badge small pilled variant='tertiary' className='mt-3'>
                  {i18n('v2.pages.home.section5.buttons.enroll')}
                </Badge>
              </div>
            )}
          </div>

          <div className='details'>
            <div>
              <Row align='center'>
                <Col xs={12} md={6} className='mb-3 mb-md-0'>
                  <img src={thumbnail} alt={title} className='img-fluid' />
                </Col>

                <Col xs={12} md={6}>
                  <small className='d-block text-spanish'>
                    {i18n('v2.pages.catalog.primary.degree')}
                  </small>
                  {isLoading ? <Skeleton /> : data?.degreeName || '-'}
                </Col>
              </Row>
            </div>
          </div>
        </Link>
      </TertiaryCardStyled>
    </div>
  );
}
