import { i18n } from 'dawere-commons';
import { Breadcrumbs, Col, Utils, Wizard, useRouter } from 'dawere-uic';
import { useCallback, useEffect } from 'react';
import { Link, Prompt } from 'react-router-dom';
import { PATHS } from 'router';
import { useEnroll } from '../hooks/useEnroll';
import { CalendarPicker } from './CalendarPicker';
import { Checkout } from './Checkout';

const initBeforeUnLoad = (showExitPrompt: boolean) => {
  window.onbeforeunload = (event) => {
    // Show prompt based on state
    if (showExitPrompt) {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = '';
      }
      return '';
    }
  };
};

export function ReEnrollmentWizard() {
  const { step, isPaid, calendar, period, onStepChange } = useEnroll();
  const { history } = useRouter();

  const handleClick = useCallback(
    (e: any) => {
      e.preventDefault();
      history?.goBack();
    },
    [history]
  );

  // Initialize the beforeunload event listener after the resources are loaded
  window.onload = function () {
    initBeforeUnLoad(!Utils.isEmpty(calendar));
  };

  // Re-Initialize the onbeforeunload event listener
  useEffect(() => {
    initBeforeUnLoad(!Utils.isEmpty(calendar));
    return () => initBeforeUnLoad(false);
  }, [calendar, period]);

  return (
    <Col xs={12} md={['periods', 'calendars'].includes(step) ? 12 : 7}>
      <Breadcrumbs
        as={Link}
        withBackArrow
        onBackClick={handleClick}
        routes={[
          {
            breadcrumb: i18n('ui.commons.exit'),
            isBack: true,
            to: PATHS.STUDENTS_DASHBOARD
          }
        ]}
        className='mb-4'
      />
      <Wizard
        onStepChange={onStepChange}
        stepsDisabled={!calendar ? ['checkout'] : []}>
        <Wizard.Step
          id='calendars'
          title={i18n('v2.pages.enrolment.steps.1.title')}>
          <CalendarPicker />
        </Wizard.Step>

        <Wizard.Step
          id='checkout'
          title={i18n('v2.pages.enrolment.steps.3.title')}>
          <Checkout />
        </Wizard.Step>
      </Wizard>
      <Prompt
        when={!Utils.isEmpty(calendar) && !isPaid}
        message={() => i18n('v2.pages.enrolment.leavePage')}
      />
    </Col>
  );
}
