import { Rest, Session } from 'dawere-commons';
import { Utils } from 'dawere-uic';
import { Documents } from './documents';
import { getPeriods } from './programs';

function _getRejectedReason(rejectReasons = [], rejectNotes: any = null) {
  const reasons = rejectReasons.map((item: any) => item.itemValue);

  if (rejectNotes) {
    reasons.push(rejectNotes);
  }

  return reasons;
}

function get(id: string) {
  return Rest.get(`/students/${id}`).then((response: any) => {
    if (response.data) {
      response.data.fullName = Utils.getFullname(
        response.data.name,
        response.data.lastName
      );
    }
    return response;
  });
}

function getNextSubject(enrollmentId: string) {
  return Rest.post(`enrolments/${enrollmentId}/continue`);
}

function getBadgeColor(status: string) {
  // 'APPROVED',
  // 'EQUIVALENCE',
  // 'EXONERATED',
  // 'FINAL_PERIOD',
  // 'IN_COURSE',
  // 'NOT_APPROVED',
  // 'PENDING',
  // 'TRANSFERRED',
  // 'TUTORSHIP',
  // 'REPEATING',
  // 'VALIDATED',
  switch (status) {
    case 'NOT_APPROVED':
      return 'error';
    case 'IN_COURSE':
      return 'wrong';
    default:
      return 'success';
  }
}

function getTranscripts(studentId: string, programId: string) {
  let programPeriods: any[] = [];
  const subjectsOfTheProgram: any = {};

  return getPeriods(programId)
    .then((data = []) => {
      programPeriods = data.map((period: any) => {
        return {
          id: period.id,
          isActive: period.isactive,
          name: period.name,
          order: period.order,
          subjects: period.subjects
            .map((subject: any) => {
              const newSubject = {
                id: subject.id,
                name: subject.title,
                isCourse: subject.course,
                credits: subject.credits,
                status: 'TO_COURSE',
                totalExams: subject.totalExams,
                totalTopics: subject.totalTopics,
                icon: subject.icon,
                isPending: true
              };

              subjectsOfTheProgram[subject.id] = newSubject;

              return newSubject;
            })
            .sort((a: any, b: any) => a.name.localeCompare(b.name))
        };
      });

      return Rest.get(
        `/students/${studentId}/programs/${programId}/transcripts`
      );
    })
    .then(({ data }: any) => {
      const { periods = [], ...rest } = data;
      const tempTranscripts = periods.map((value: any) => {
        return {
          id: value.period.id,
          isActive: value.period.isActive,
          name: value.period.name,
          order: value.period.order,
          yearGPA: value.yearGPA,
          cumulativeGPA: value.cumulativeGPA,
          averageScore: value.averageScore,
          approvedCredits: value.approvedCredits,
          inProgressCredits: value.inProgressCredits,
          subjects: value.transcripts
            .map((el: any) => {
              return {
                id: el.id,
                date: el.approvedDate
                  ? Utils.formatDate(el.approvedDate, 'dd/mmm/yyyy')
                  : null,
                inDawere: el.inDawere,
                school: el.schoolName ?? null,
                score: el.score,
                grade: el.grade,
                status: el.status,
                name: el.subjectResponse.title,
                credits: el.subjectResponse.credits,
                totalTopics: el.subjectResponse.totalTopics,
                totalExams: el.subjectResponse.totalExams,
                isCourse: el.subjectResponse.course,
                icon: el.subjectResponse.icon,
                isPending: false,
                isDeprecated:
                  !!subjectsOfTheProgram[el.subjectResponse.id] === false,
                badgeColor: getBadgeColor(el.status)
              };
            })
            .sort((a: any, b: any) => a.name.localeCompare(b.name))
        };
      });

      // Crear los periodos con el array de periodos del programa
      const transcripts: any[] = Utils.copy(programPeriods);

      // Rellenar periodos faltantes con los periodos del tempTrans
      tempTranscripts.forEach((tt: any) => {
        const foundPeriod = transcripts.find((p) => p.id === tt.id);

        if (foundPeriod) {
          // Si encuentro el periodo relleno lo faltante
          foundPeriod.yearGPA = tt.yearGPA;
          foundPeriod.cumulativeGPA = tt.cumulativeGPA;
          foundPeriod.averageScore = tt.averageScore;
          foundPeriod.approvedCredits = tt.approvedCredits;
          foundPeriod.inProgressCredits = tt.inProgressCredits;

          // Hago "merge" de la materias
          tt.subjects.forEach((ss: any) => {
            const foundSubjectIndex = foundPeriod.subjects.findIndex(
              (s: any) => s.id === ss.id
            );

            if (foundSubjectIndex >= 0) {
              foundPeriod.subjects[foundSubjectIndex] = { ...ss };
            } else {
              foundPeriod.subjects.push(ss);
            }
          });

          foundPeriod.subjects.sort((a: any, b: any) =>
            a.name.localeCompare(b.name)
          );
        } else {
          // Si el el periodo no existe en el programa, lo apilo
          transcripts.push(Utils.copy(tt));
        }
      });

      transcripts.sort((a: any, b: any) => a.order - b.order);

      return {
        data: transcripts,
        ...rest
      };
    });
}

function getDocuments(studentId: string, programId: string) {
  return Rest.get(
    `/students/${studentId}/programs/${programId}/collections`
  ).then((response: any) => {
    const data = response.data.map(
      ({
        id,
        status,
        path,
        collection,
        rejectNotes = '',
        rejectReasons = []
      }: any) => ({
        id,
        status: Documents.getStatus(status),
        src: path,
        name: collection?.name,
        mandatory: collection?.mandatory,
        physical: collection?.physicallyRequired,
        reasonsToReject: _getRejectedReason(rejectReasons, rejectNotes)
      })
    );

    return {
      mandatory: data.filter((item: any) => item.mandatory),
      optional: data.filter((item: any) => !item.mandatory),
      total: data.length
    };
  });
}

type EnrollmentStatus =
  | 'DEBT'
  | 'UNCOMPLETED_PROFILE'
  | 'DEPRECATED_PROGRAM'
  | 'GRADUATED'
  | 'ACTIVE'
  | 'RE_ENROLLMENT'
  | 'FREE_TRIAL_ENDED'
  | 'FREE_TRIAL'
  | 'CONTINUE'
  | 'FINISHED_COURSE'
  | 'UNKNOW';

function getEnrollmentStatus(enrollmentId: string) {
  // DEBT -> PENDIENTE DE PAGO
  // UNCOMPLETED_PROFILE -> PERFIL INCOMPLETO
  // DEPRECATED_PROGRAM -> PROGRAMA OBSOLETO
  // GRADUATED -> GRADUADO
  // ACTIVE -> CURSANDO
  // RE_ENROLLMENT -> REINSCRIPCIÓN
  // CONTINUE -> CONTINUAR PROGRAMA SIN CALENDARIO
  // FINISHED_COURSE -> CURSO FINALIZADO
  // FREE_TRIAL_ENDED -> PRUEBA GRATUITA FINALIZADA
  // FREE_TRIAL -> PRUEBA GRATUITA
  // UNKNOW -> DESCONOCIDO
  return Rest.get<EnrollmentStatus>('flows', {
    params: { enrolment_id: enrollmentId }
  }).then(({ data }) => data);
}

function getOnlineEnrollments(
  settings: {
    student_id?: string;
    latest?: boolean;
  } = {}
) {
  return Rest.get<any[]>('enrolments', {
    params: settings
  }).then(({ data }) => {
    let enrollments: any[] = Utils.copy(data);
    const statuses: any[] = [];

    if (Utils.isArray(enrollments)) {
      enrollments = enrollments.sort((a: any, b: any) =>
        Utils.isDateAfter(a.admissionDate, b.admissionDate) ? -1 : 1
      );
    }

    enrollments.forEach((enrollment: any) => {
      statuses.push(getEnrollmentStatus(enrollment.id));
    });

    return Promise.all(statuses).then((status) => {
      enrollments.forEach((enrolment: any, index: number) => {
        enrolment.status = status[index];
        enrolment.isTrial =
          enrolment.status === 'FREE_TRIAL_ENDED' ||
          enrolment.status === 'FREE_TRIAL';
      });

      // It's me!
      if (Utils.isEmpty(settings.student_id)) {
        Session.setEnrolments(enrollments);
      }

      return enrollments;
    });
  });
}

function getAllEnrollments(
  settings: {
    student_id?: string;
    latest?: boolean;
    online?: boolean;
  } = {}
) {
  const { online, ...conf } = settings;

  // It's me!
  if (Utils.isEmpty(conf.student_id)) {
    const cached = Session.getEnrolments();
    const last = Session.getEnrolmentsTimer();
    const now = new Date().getTime();
    const seconds = (now - last) / 1000;
    const THRESHOLD = 60;

    if (!online && cached.length > 0 && seconds <= THRESHOLD) {
      //  Use the cache
      return new Promise<any[]>((resolve) => {
        resolve(cached);
      });
    } else {
      return getOnlineEnrollments(conf);
    }
  } else {
    return getOnlineEnrollments(conf);
  }
}

function getEnrollmentProgress(enrollmentId: string) {
  return Rest.get('classroom/progress', {
    params: { enrolment_id: enrollmentId }
  }).then(({ data }) => data);
}

function getEnrollmentTeachers(enrollmentId: string) {
  return Rest.get('teachers', {
    params: { enrolment_id: enrollmentId }
  }).then(({ data }) => data);
}

function getOrders(studentId: string, status: 'PARTIAL' | 'FULL' = 'PARTIAL') {
  return Rest.get(`students/${studentId}/orders`, {
    params: { status, simple: true }
  });
}

function getBills(studentId: string, params: any = {}) {
  return Rest.get(`students/${studentId}/receipts`, {
    params
  }).then((response) => {
    let data = response.data.content.map((el: any) => {
      return {
        ...el,
        paymentDate: Utils.formatDate(el.paymentDate, 'dd/mmm/yyyy')
      };
    });

    return {
      data,
      totalPages: response.data.totalPages,
      totalRows: response.data.totalElements
    };
  });
}

function getPayments(studentId: string, params: any = {}) {
  return Rest.get(`students/${studentId}/payments`, {
    params
  }).then((response) => {
    let data = response.data.content.map((el: any) => {
      return {
        ...el,
        paymentDate: Utils.formatDate(el.paymentDate, 'dd/mmm/yyyy')
      };
    });

    return {
      data,
      totalPages: response.data.totalPages,
      totalRows: response.data.totalElements
    };
  });
}

function getPaymentDetails(studentId: string, paymentId: string) {
  return Rest.get(`students/${studentId}/payments/${paymentId}/details`);
}

function getInstallments(studentId: string, orderId: string) {
  return Rest.get(`students/${studentId}/orders/${orderId}/installments`).then(
    (response: any) => {
      response.data.forEach((el: any) => {
        el.dueDate = el.dueDate
          ? Utils.formatDate(el.dueDate, 'dd/mmm/yyyy')
          : null;
      });

      return response;
    }
  );
}

export const Students = {
  get,
  getTranscripts,
  getDocuments,
  getOrders,
  getBills,
  getPayments,
  getPaymentDetails,
  getInstallments,
  getNextSubject,
  getAllEnrollments,
  getEnrollmentStatus,
  getEnrollmentProgress,
  getEnrollmentTeachers
};
