import { i18n } from 'dawere-commons';
import { Col, Header, Utils } from 'dawere-uic';
import { InviteFriendModal } from '../components/InviteFriendModal';
import { useStudentDashboard } from '../hooks/useStudentDashboard';

export function Heading() {
  const { student, isLoading, readOnly } = useStudentDashboard();

  return (
    <>
      <Col xs={12} md={9}>
        <Header
          loading={isLoading}
          src={student?.picturePath}
          title={i18n('v2.pages.studentDashboard.welcome', {
            name: Utils.getFirstPart(student?.name)
          })}
          subtext={[i18n('v2.pages.studentDashboard.title')]}
        />
      </Col>
      {!readOnly && (
        <Col xs={12} md={3} className='text-right'>
          <InviteFriendModal />
        </Col>
      )}
    </>
  );
}
