import { useQuery } from '@tanstack/react-query';
import { API } from 'api';
import { Session, i18n } from 'dawere-commons';
import { useEffect, useState } from 'react';
import { PATHS } from 'router';
import {
  DEFAULT_STALE_TIME,
  QPARAMS,
  QUERY_KEYS,
  createQueryKey
} from 'values';

export function useProgramFreeTrial(
  programId: string,
  earlyPayment = false,
  disabled = false
) {
  const account = Session.getAccountInfo();
  const enrollment = Session.getEnrolmentByProgram(programId);

  const [priceSelected, setPriceSelected] = useState<any>(null);

  const { data: program, isLoading: loadingProgram } = useQuery({
    staleTime: DEFAULT_STALE_TIME,
    enabled: !!programId && !disabled,
    queryKey: createQueryKey(QUERY_KEYS.PROGRAM_DETAILS, programId),
    queryFn: () => API.Programs.get(programId)
  });

  const { data: product, isLoading: loadingCurrency } = useQuery({
    staleTime: DEFAULT_STALE_TIME,
    enabled: !!programId && !disabled,
    queryKey: createQueryKey(QUERY_KEYS.PROGRAM_PRICES, programId),
    queryFn: () => API.Programs.getPrices(programId)
  });

  useEffect(() => {
    if (product) {
      setPriceSelected([...product?.prices].shift());
    }
  }, [product]);

  if (!programId) {
    return {
      loading: loadingProgram || loadingCurrency,
      available: false,
      days: program?.freeTrialDays,
      name: program?.name,
      route: null
    };
  }

  if (!priceSelected) {
    return {
      available: false,
      days: program?.freeTrialDays,
      name: program?.name,
      route: null
    };
  }

  if (account?.freeTrialAvailable === false) {
    // Usuario agoto sus muestras gratis
    return {
      loading: loadingProgram || loadingCurrency,
      available: false,
      days: program?.freeTrialDays,
      name: program?.name,
      route: null
    };
  }

  // Si el programa tiene trial y tiene moneda
  if (program?.freeTrial) {
    // Con sesion
    if (account) {
      // Si ya esta inscrita como trial
      if (['FREE_TRIAL', 'FREE_TRIAL_ENDED'].includes(enrollment?.status)) {
        return {
          loading: loadingProgram || loadingCurrency,
          available: true,
          days: program?.freeTrialDays,
          name: program?.name,
          text: i18n('v2.pages.program.details.buttons.studentDashboard'),
          route: PATHS.get(
            'STUDENTS_DASHBOARD',
            {},
            { [QPARAMS.Program]: programId }
          )
        };
      } else {
        return {
          loading: loadingProgram || loadingCurrency,
          available: true,
          days: program?.freeTrialDays,
          name: program?.name,
          text: i18n('v2.pages.home.section1.buttons.inscription'),
          route: PATHS.get(
            'ENROLL',
            {
              programId,
              currencyId: priceSelected?.currency?.id
            },
            { [QPARAMS.EarlyPayment]: earlyPayment ? 1 : 0 }
          )
        };
      }
    }
    // Sin sesion
    else {
      return {
        loading: loadingProgram || loadingCurrency,
        available: true,
        days: program?.freeTrialDays,
        name: program?.name,
        text: i18n('v2.pages.home.section1.buttons.inscription'),
        route: PATHS.get('REGISTER', null, {
          [QPARAMS.Redirect]: encodeURIComponent(
            PATHS.get(
              'ENROLL',
              {
                programId: program?.id,
                currencyId: priceSelected?.currency?.id
              },
              { [QPARAMS.EarlyPayment]: earlyPayment ? 1 : 0 }
            )
          )
        })
      };
    }
  }

  return {
    loading: loadingProgram || loadingCurrency,
    available: false,
    days: program?.freeTrialDays,
    route: null
  };
}
