import { DEFAULT_COUNTRY, Locales } from 'dawere-commons';
import { COUNTRIES_REGEXP } from 'utils';
import { routes } from './routes';

const regex = new RegExp(`/${COUNTRIES_REGEXP}(/.*)?$`, 'gi').exec(
  window.location.href
);

const lang = regex?.length
  ? regex[1].replaceAll('/', '')
  : Locales.getCountry()
  ? Locales.getCountry()
  : DEFAULT_COUNTRY; /* Default country if there is nothing in cache */

export const PATHS = lang
  ? routes
      .filter((route) => !route.ignoreIntl)
      .reduce((accum: any, route) => {
        accum[route.key] = route.key.includes('_DUAL')
          ? route.rootPath
          : `/${lang}${route.rootPath}`;

        return accum;
      }, {})
  : {};

PATHS.get = (key: string, params: any = {}, search: any = {}) => {
  let path = PATHS[key];
  let queryString = '';

  for (const param in params) {
    if (params.hasOwnProperty(param)) {
      path = path.replace(`:${param}`, params[param]);
    }
  }

  for (const q in search) {
    if (search.hasOwnProperty(q) && search[q] !== undefined) {
      queryString += `${q}=${search[q]}&`;
    }
  }

  return path + (queryString.length ? `?${queryString.slice(0, -1)}` : '');
};
