import { i18n } from 'dawere-commons';
import { Block, Button, Col, Iframe, useRouter } from 'dawere-uic';
import { PageWrapper } from 'pages';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { getSEO } from 'utils';
import { ASSETS } from 'values';

/**
 * Path: /organizaciones/demostracion-solicitada
 * Key: ORGANIZATIONS_DEMO_REQUESTED
 * @returns {React.ReactElement}
 */
export default function DemoRequestedPage() {
  const { query } = useRouter();

  return (
    <PageWrapper>
      {getSEO('v2.pages.demoRequested.seo', true)}

      <Block className='text-center'>
        <Col>
          {(query?.firstName || query?.lastName) && query?.email ? null : (
            <img
              src={ASSETS.ICONS.DEMO_REQUESTED}
              alt={i18n('v2.pages.demoRequested.title')}
              className='mb-6 img-fluid'
              width={150}
            />
          )}

          <h2 className='mb-3'>{i18n('v2.pages.demoRequested.title')}</h2>
          <h4 className='mb-4'>{i18n('v2.pages.demoRequested.subtitle')}</h4>

          {(query?.firstName || query?.lastName) && query?.email ? (
            <Iframe
              className='rounded shadow'
              height={860}
              src={`https://meetings.hubspot.com/lizmar/demo-dawere-para-organizaciones?firstName=${encodeURIComponent(
                query?.firstName
              )}&lastName=${encodeURIComponent(query?.lastName)}&email=${
                query?.email
              }`}
            />
          ) : null}

          <div className='mt-6'>
            <Button
              variant='outline'
              className='mb-2 mb-md-0 mr-md-4 | w-100 w-md-auto'
              as={Link}
              to={PATHS.ORGANIZATIONS}>
              {i18n('v2.pages.demoRequested.buttons.home')}
            </Button>
            <Button
              as={Link}
              to={PATHS.CATALOG}
              className='mb-1 mb-md-0 | w-100 w-md-auto'>
              {i18n('v2.pages.demoRequested.buttons.catalog')}
            </Button>
          </div>
        </Col>
      </Block>
    </PageWrapper>
  );
}
