import { API } from 'api';
import { i18n } from 'dawere-commons';
import {
  AvatarChip,
  Button,
  Card,
  Col,
  Confirm,
  Container,
  Row,
  toast,
  Utils
} from 'dawere-uic';
import { useCallback, useState } from 'react';

export function StudentCard({ item, onChange = () => {}, ...rest }: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const fullName = Utils.getFullname(item.name, item.lastName);

  const handleResignation = useCallback(() => {
    setIsLoading(true);

    API.Tutors.resignStudent(item.id)
      .then(() => {
        toast.success(
          i18n('v2.pages.permissions.myTutors.revoke.toast', {
            fullName
          })
        );
        onChange();
      })
      .finally(() => setIsLoading(false));
  }, [fullName, item.id, onChange]);

  return (
    <Card className='py-3 px-2 mb-2 translate-left-animation'>
      <Container fluid>
        <Row align='center'>
          <Col xs={12} md={8} lg={9}>
            <AvatarChip
              size={60}
              src={item.picturePath}
              className='overflow-hidden'
              icon={Utils.getInitialLetters(item.name, item.lastName)}>
              <h4 className='m-0 lines-1'>
                {Utils.getFullname(item.name, item.lastName)}
              </h4>
              <span className='d-block text-muted lines-1'>{item.email}</span>
            </AvatarChip>
          </Col>
          <Col xs={12} md={4} lg={3} className='text-md-right'>
            <Button
              variant='outline'
              className='w-100 w-md-auto | mt-4 mt-md-0'
              onClick={() => setIsOpenConfirm(true)}
              loading={isLoading}>
              {i18n('v2.pages.permissions.myOrganizations.buttons.revoke')}
            </Button>
          </Col>
        </Row>
      </Container>

      <Confirm
        type='warning'
        title={i18n('v2.pages.permissions.myOrganizations.modals.title')}
        open={isOpenConfirm}
        onClose={setIsOpenConfirm}
        onConfirm={handleResignation}
        confirmText={i18n(
          'v2.pages.permissions.myOrganizations.buttons.revoke'
        )}>
        <p
          dangerouslySetInnerHTML={{
            __html: i18n('v2.pages.permissions.myTutors.revoke.description', {
              fullName
            })
          }}
        />
      </Confirm>
    </Card>
  );
}
