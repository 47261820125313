import { Rest } from 'dawere-commons';
import { Utils } from 'dawere-uic';

function getMyBills(params: any = {}) {
  return Rest.get('me/receipts', {
    params
  }).then((response) => {
    let data = response.data.content.map((el: any) => {
      return {
        ...el,
        paymentDate: Utils.formatDate(el.paymentDate, 'dd/mmm/yyyy')
      };
    });

    return {
      data,
      totalPages: response.data.totalPages,
      totalRows: response.data.totalElements
    };
  });
}

function getMyPayments(params: any = {}) {
  return Rest.get('me/payments', {
    params
  }).then((response) => {
    let data = response.data.content.map((el: any) => {
      return {
        ...el,
        paymentDate: Utils.formatDate(el.paymentDate, 'dd/mmm/yyyy')
      };
    });

    return {
      data,
      totalPages: response.data.totalPages,
      totalRows: response.data.totalElements
    };
  });
}

function getMyPaymentDetails(
  paymentId: string,
  sourceId?: string,
  type: 'STUDENT' | 'ORGANIZATION' = 'STUDENT'
) {
  if (sourceId) {
    if (type === 'STUDENT') {
      return Rest.get(`students/${sourceId}/payments/${paymentId}/details`);
    }
    return Rest.get(
      `my-organizations/${sourceId}/payments/${paymentId}/details`
    );
  }

  return Rest.get(`me/payments/${paymentId}/details`);
}

function getMyOrders(status: 'PARTIAL' | 'FULL' = 'PARTIAL') {
  return Rest.get('me/orders', { params: { status, simple: true } });
}

function getMyInstallments(orderId: string) {
  return Rest.get(`me/orders/${orderId}/installments`).then((response: any) => {
    response.data.forEach((el: any) => {
      el.dueDate = el.dueDate
        ? Utils.formatDate(el.dueDate, 'dd/mmm/yyyy')
        : null;
    });

    return response;
  });
}

function cancelOrder(receiptId: string) {
  return Rest.delete(`me/orders/${receiptId}`);
}

function getReceipt(receiptId: string) {
  return Rest.get(`public/receipts/${receiptId}`).then(({ data }: any) => data);
}

function getInstallmentsOrder(installmentId: string) {
  return Rest.get(`payments/draft?installment=${installmentId}`).then(
    ({ data }: any) => data
  );
}

function getWalletBalance(orderId: string) {
  return Rest.get(`/orders/${orderId}/wallet-balance`).then(({ data }: any) =>
    data ? data?.value : null
  );
}

function deleteOrder(orderId: string) {
  return Rest.delete(`orders/${orderId}`);
}

export const Payments = {
  getMyPayments,
  getMyPaymentDetails,
  getMyBills,
  getMyOrders,
  getMyInstallments,
  cancelOrder,
  getReceipt,
  getInstallmentsOrder,
  getWalletBalance,
  deleteOrder
};
