import { Text, View } from '@react-pdf/renderer';
import { i18n } from 'dawere-commons';
import { colors } from 'dawere-uic/lib/styles/colors';
import { PDF_BASE_FONT_SIZE, PDF_MUTED_COLOR } from 'values';

export function InvoiceInfoSection({ data }: any) {
  return (
    <View style={{ marginBottom: 15 }}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
        <View style={{ width: '50%', paddingRight: 10 }}>
          <Text
            style={{
              fontSize: PDF_BASE_FONT_SIZE * 0.85,
              color: PDF_MUTED_COLOR
            }}>
            {i18n('v2.pages.pdfReceipt.billingInfo.title')}
          </Text>
          <Text style={{ fontSize: PDF_BASE_FONT_SIZE }}>
            {data?.billing?.billTo}
            {data?.billing?.tin ? ', ' : ''}
            {data?.billing?.tin}
          </Text>
          <Text style={{ fontSize: PDF_BASE_FONT_SIZE }}>
            {data?.billing?.billingAddress}
          </Text>
          <Text style={{ fontSize: PDF_BASE_FONT_SIZE }}>
            {data?.billing?.billingTownship?.name}
            {data?.billing?.billingState?.name ? ', ' : ''}
            {data?.billing?.billingState?.name}
          </Text>
          <Text style={{ fontSize: PDF_BASE_FONT_SIZE }}>
            {data?.billing?.billingPostalCode}
            {data?.billing?.billingCountry?.name ? ', ' : ''}
            {data?.billing?.billingCountry?.name}
          </Text>
        </View>

        <View
          style={{
            width: '50%',
            paddingLeft: 10
          }}>
          <View
            style={{
              textAlign: 'right'
            }}>
            <Text
              style={{
                fontSize: PDF_BASE_FONT_SIZE * 0.85,
                color: PDF_MUTED_COLOR
              }}>
              {i18n('v2.pages.pdfReceipt.billingInfo.number')}
            </Text>

            <View>
              <Text
                style={{
                  fontSize: PDF_BASE_FONT_SIZE * 1.45,
                  fontWeight: 'bold',
                  color: colors.primary
                }}>
                {data?.number}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}
