const en = {
  lang: `en`,
  pages: {
    notFound: {
      title: `Page not found`,
      subtitle: `Please return to our home page:`
    },
    pisaEvaluationResultDetails: {
      seo: {
        title: `Details: Benchmark assessment — PISA`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      breadcrumbs: {
        2: `Details`
      },
      title: `Score`,
      description: `This report presents the grade obtained in the evaluation and a summary of your performance by competencies`,
      level: `Readiness level`,
      topic: `Topic`,
      cognitiveDemand: `Cognitive demand`,
      button: `Keep practicing`,
      answers: `Answers {{points}}/{{qty}}`
    },
    wallet: {
      seo: {
        title: `Discount vouchers`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      title: `Discount vouchers`,
      coupons: {
        title: `Coupons`,
        description: `Here is a list of coupons that have been assigned to you by the organization you belong to or by a sponsor. You can use each coupon to pay for the specific product indicated in it. Using a coupon will remove it from this list.`,
        table: {
          count: `coupon(s)`,
          columns: {
            coupon: `Coupon`,
            product: `Product`,
            code: `Coupon code`,
            date: `Coupon assignment date`
          }
        }
      },
      balance: {
        title: `Balance`,
        description: `You can use the balance in your discount vouchers to make payments in Dawere as long as it is in the same currency as your available credit.`
      }
    },
    program: {
      seo: {
        title: `Program details`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      details: {
        start: `START`,
        end: `FINISH`,
        calendarsDescription: `Open Enrollment Calendars`,
        discountPerc: `With a {{perc}}% discount`,
        installments: `In {{installments}} monthly installments`,
        tooltip: `Users who choose this payment method are responsible for meeting all monthly installments through a payment commitment they agree on with the institution`,

        tabs: {
          1: {
            title: `Full`
          },
          2: {
            title: `Installments`
          }
        },
        buttons: {
          moreDates: `More dates`,
          lessDates: `Fewer dates`,
          enroll: `Enroll`,
          studentDashboard: `Student dashboard`
        }
      },
      section1: {
        pretitle: `Educational program`,
        buttons: {
          tryIt: `7-day free trial`
        }
      },
      section2: {
        subtitle1: `Earn your high school diploma`,
        subtitle2: `Earn your certificate`,
        license: `Operating license`,
        buttons: {
          enroll: `Enroll now`
        }
      },
      section3: {
        title1: `Enrollment documents required`,
        title2: `Requirements`
      },
      section4: {
        title1: `Program content`,
        title2: `Course content`,
        topics: `Topics`,
        exams: `Assessments`,
        links: {
          details: `See details`
        }
      }
    },
    subject: {
      seo: {
        title: `Subject details`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      breadcrumbs: {
        1: `Educational programs`,
        3: `Subject details`
      },
      details: {
        description: `It's always open enrollment`,
        buttons: {
          enroll: `Enroll now`
        }
      },
      section1: {
        description1: `This subject is part of the program`,
        description2: `This subject is part of the course`,
        buttons: {
          tryIt: `Try it for free (7 days)`
        }
      },
      section2: {
        title: `Subject content`,
        topic: `Topic {{number}}`,
        goals: `Goals`,
        videos: `Videos`,
        guides: `Reading material`,
        tests: `Practice assessments`,
        buttons: {
          showAll: `See all`
        }
      }
    },
    rememberPasswordRequested: {
      seo: {
        title: `Did you forget your password?`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      title: `Check your email`,
      subtitle: `Check your inbox and click on the link we sent you to reset your password`,
      noEmail: `You didn't get an email?`,
      buttons: {
        login: `Log in`
      },
      links: {
        contactUs: `Contact us`
      }
    },
    checkEmail: {
      seo: {
        title: `Verify your email`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      title: `Verify your email`,
      subtitle: `Check your inbox and click on the link we sent you to verify your account.`,
      noEmail: `You didn't get an email?`,
      buttons: {
        login: `Log in`,
        continue: `Continue`
      },
      links: {
        contactUs: `Contact us`
      }
    },
    passwordCreated: {
      seo: {
        title: `A new password was created`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      title: `A new password was created`,
      subtitle: `Your password has been successfully changed`,
      buttons: {
        login: `Log in`
      }
    },
    verifiedAccount: {
      seo: {
        title: `Verified account`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      title: `Verified account`,
      subtitle: `Your account has been successfully verified.`,
      verifyingTitle: `Verifying account`,
      verifyingSubtitle: `Please wait as we verify your account.`,
      buttons: {
        login: `Log in`
      }
    },
    login: {
      title: `Sign in to your online school`,
      withoutAccount: `You don't have an account?`,
      form: {
        email: `Email`,
        password: `Password`
      },
      help: {},
      tooltips: {},
      buttons: {
        login: `Log in`,
        googleLogIn: `Log in with Google`
      },
      links: {
        forgotPassword: `Did you forget your password?`,
        register: `Register`
      },
      url: {},
      images: {},
      seo: {
        title: `Dawere.com | Login`,
        description: `Log in to your private account at Dawere.com. `,
        keywords: `Sign in to your online school`,
        ogTitle: `Log in`,
        ogDescription: `Log in to your private account at Dawere.com. `,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/seo_iniciodesesion.jpg`
      }
    },
    forgotPassword: {
      title: `Forgot your password? Recover access to your online school`,
      subtitle: `Enter the email you use to log in`,
      withoutAccount: `You don't have an account?`,
      toast: ` We have sent you an email with a link to reset your password.`,
      form: {
        email: `Email`
      },
      buttons: {
        submit: `Submit`
      },
      links: {
        register: `Register`
      },
      seo: {
        title: `Password Reset - Recover Access to Your Online School - Dawere USA `,
        description: `Forgot your password? Reset it and keep on the good work in Dawere USA's online school. `,
        keywords: `Recover access to your online school`,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/seo_iniciodesesion.jpg`
      },
      checkEmail: {
        title: `Verify your email`,
        text: `In order to request a new password, you must first verify your account.`,
        buttons: {
          submit: `Send verification email`
        }
      }
    },
    resetPassword: {
      title: `Create a new password`,
      subtitle: `Enter a new password to access your account.`,
      form: {
        confirmPassword: `Confirm your password`,
        success: `Your password has been reset!`
      },
      buttons: {
        submit: `Change password`
      },
      helps: {
        password: ``
      },
      seo: {
        title: `Change password`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      }
    },
    countrySelection: {
      seo: {
        title: `Dawere.com | Virtual schooling for Latin America`,
        description: `Dawere.com is the only virtual school in Latin America endorsed by the educational authorities of Mexico, Colombia, Venezuela, Honduras and the Dominican Republic. In the United States it is registered with the Florida Department of Education.`,
        keywords: `virtual schooling, classes`,
        ogTitle: `Dawere: Virtual schooling for Latinamerica`,
        ogDescription: `Dawere.com is the only virtual school in Latin America endorsed by the educational authorities of Mexico, Colombia, Venezuela, Honduras and the Dominican Republic. In the United States it is registered with the Florida Department of Education.`,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/seo_seleccionaunpais.jpg`
      },
      title: `Welcome to your international virtual school`,
      subtitle: `No matter where you are in the world, you can enroll in the education system of your choice and earn your high school diploma.`
    },
    registration: {
      seo: {
        title: `Enrollment`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      }
    },
    organizations: {
      seo: {
        title: ``,
        description: `Dawere works hand in hand with governments, foundations and sport clubs to educate young people and adults through quality, flexible and affordable virtual schooling.`,
        keywords: ``,
        ogTitle: ``,
        ogDescription: `Dawere works hand in hand with governments, foundations and sport clubs to educate young people and adults through quality, flexible and affordable virtual schooling.`,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/Illustrations/*FOR%20ORGANIZATIONS/ID_Page_Organizations_Block1_HERO.png`
      },
      section1: {
        slogan: `Accredited and quality education`,
        title: `Become part of a great virtuous circle`,
        subtitle: `"The best investment that a company, society and nation can make is in the education of its members."`,
        buttons: {
          login: `Log in as an organization`,
          signup: `Register as an organization`
        }
      },
      certifications: {
        title: `Accredited by COGNIA`,
        text: `
          <p>
          This certification is a distinctive mark of quality recognized worldwide. Having it demonstrates our commitment to excellence in education and to the students of Dawere.
            </p>
        `,
        images: {
          1: {
            url: `https://storage.googleapis.com/dawere-web-assets/Ilustraciones/COGNIA/SELLO%20COGNIA_Mesa%20de%20trabajo%201.svg`,
            alt: `Cognia`
          }
        }
      },
      section2: {
        title: `Request a free demo`,
        subtitle: `Discover why NGOs, companies, and governments choose Dawere to educate their citizens or beneficiaries.`,
        benefits: {
          1: {
            title: `Manage`,
            subtitle: `the purchase and allocation of your enrollment coupons`
          },
          2: {
            title: `Monitor`,
            subtitle: `the academic progress of the students enrolled`
          },
          3: {
            title: `Access`,
            subtitle: `the quality academic content being used to properly educate your beneficiaries.`
          }
        },
        form: {
          fullName: `Full name`,
          email: `Corporate email`,
          phoneNumber: `Phone number`,
          organizationName: `Name of the organization`,
          role: `Your role in the organization`,
          summary: `How can we help you?`,
          errors: {
            fullName: `Invalid name`
          }
        },
        buttons: {
          send: `Send`
        },
        links: {
          1: `Terms and conditions`,
          2: `Privacy policies`
        },
        url: {
          1: `https://storage.googleapis.com/dawere-web-assets/pdf/TermsandConditionsUSA.pdf`,
          2: `https://storage.googleapis.com/dawere-web-assets/pdf/PoliticadetratamientodedatosEEUUV2.pdf`
        },
        footer: {
          sentence1: `By submitting this form, you agree to Dawere's`,
          sentence2: `and its`
        }
      },
      // Section 3: ../shared/alleis
      section4: {
        foundations: {
          title: `For foundations`,
          subtitle: `DAWERE`,
          text: `
<p>
From multilateral entities to local foundations use our platform to serve and strengthen communities whose future is threatened by the lack of access to quality education.
</p>
<p>
Take advantage of Dawere's educational programs to serve vulnerable groups of the population that you have already identified as in need of our services. You can also ask the Dawere Scholarship team to find the demographic you'd like to target and serve.
</p>
`,
          url: {
            video: {
              url: `https://www.youtube.com/embed/l42u9s7n8_o`
            }
          }
        },
        governments: {
          title: `For governments`,
          subtitle: `DAWERE`,
          text: `
<p>
According to UNESCO, in the traditional educational systems of Latin America, only 45% of those who start high school graduate.
</p>
<p>
Every year, 15% of students drop out and more than 30% of school-age youth are not in school. With the use of our educational technology, this could change.
</p>

<p>
You can now provide all your citizens with free quality education. This seemingly utopian possibility can become a reality thanks to Dawere's online education services. Our Virtual High School has been designed to educate millions of people at a fraction of the cost of traditional face-to-face education. See for yourself and empower your country with the excellent education that everyone deserves.
</p>


`
        },
        companies: {
          title: `For sport clubs`,
          subtitle: `DAWERE`,
          text: `
<p>
The world of professional sports is extremely competitive, which is why only a minuscule percentage of athletes manage to become professional players. What happens to the rest?

</p>
<p>
Receiving quality education in parallel to training can guarantee a future for all your players without exception.

</p>
<p>
Our Online High School adapts to the rhythm and schedule of the students. With Dawere, your players can advance academically and pursue their dreams as professional athletes at the same time.
</p>
`
        }
      }
      // Section 5: ../shared/contact
    },
    helpCenter: {
      seo: {
        title: `Dawere.com | FAQ about Dawere`,
        description: `If you are interested in Dawere or already use our platform, many of your questions can be easily answered in the FAQ section.`,
        keywords: ``,
        ogTitle: `FAQ about Dawere`,
        ogDescription: `If you are interested in Dawere or already use our platform, many of your questions can be easily answered in the FAQ section.`,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/seo_help.jpg`
      },
      breadcrumbs: {
        1: `Help`
      },
      url: {
        register: `https://app.dawere.com.{{iso}}/#!/?register=true`,
        scholarshipForm: ``
      },
      contactCard: {
        description: `Do you still need help?`,
        link: `Talk to us`
      },
      landing: {
        title: `How can we help you?`,
        subtitle: `Select an option from the list below:`
      },
      article: {
        seo: {
          description: `If you are interested in Dawere or already use our platform, many of your questions can be easily answered in the FAQ section.`,
          Keywords: `dawere, dawere.com`,
          ogTitle: ``,
          ogDescription: ``,
          ogImage: ``
        },
        listTitle: `Other articles about:`,
        link: {
          back: `Go back`
        }
      },
      contact: {
        seo: {
          title: `Dawere.com | Contact us`,
          description: `If you are interested in Dawere or already use our platform, many of your questions can be easily answered in the FAQ section.`,
          keywords: `contact, dawere, dawere.com`,
          ogTitle: `Get in touch with Dawere`,
          ogDescription: `Dawere wants to help you solve your problem. Provide the necessary information and we will contact you as soon as possible.`,
          ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/seo_contact.jpg`
        },
        title: `Talk to us`,
        subtitle: `We want to help you solve your problem.
Please provide more information below:`,
        info: {
          description: `If you need more personalized help, contact us here:`,
          enrolled: {
            students: `Enrolled students`,
            phone: `0800 567 8765`,
            whatsapp: `0800 567 8765`
          },
          unregistered: {
            students: `Non-enrolled students`,
            phone: `0800 567 0098`,
            whatsapp: `+58 412 321 7653`
          }
        },
        cards: {
          description: `You will also explore our FAQ sections to clarify your doubts`,
          link: `View list of help topics`
        },
        links: {
          back: `Back`
        },
        buttons: {
          continue: `Continue`,
          submit: `Submit`
        },
        steps: {
          0: {
            title: `Are you already enrolled in one of Dawere's educational programs?`,
            option1: `I'm not a Dawere student`,
            option2: `Yes, I'm a Dawere student`
          },
          1: {
            title: `What do you need help with?`
          },
          2: {
            title: `Select a specific topic`
          },
          3: {
            title: `Provide more information`,
            subtitle: `Confirm your email and describe your problem or question. you can too
attach a file if you want`,
            form: {
              name: `Name`,
              email: `Email`,
              phoneNumber: `Phone number`,
              comments: `Comments`,
              errors: {
                name: `Invalid Name`
              }
            },
            helps: {
              file: `Attach a file if applicable.`
            }
          }
        }
      }
    },
    demoRequested: {
      seo: {
        title: `Dawere.com | Demo requested`,
        description: `Demo requested.`,
        keywords: `demo, demonstration, organizations`,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      title: `We have received your information!`,
      subtitle: `Please select the date you want to schedule the meeting`,
      buttons: {
        home: `Go to the home page`,
        catalog: `See educational programs`
      }
    },
    catalog: {
      seo: {
        title: ``,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      primary: {
        degree: `Certificate to obtain`,
        language: `Language`,
        duration: `Duration`,
        school: `School`,
        description: `Description`,
        more: `See details`
      }
    },
    home: {
      mainProgramId: {
        dev: ``,
        qa: ``,
        prod: ``
      },
      doubleDegreeProgramId: {
        dev: ``,
        qa: ``,
        prod: ``
      },
      seo: {
        title: ``,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      info: {
        whatsappText: `Talk to an admissions counselor.`,
        text: `Contact us and we will clarify any questions you have.`,
        whatsappNumber: ``,
        phoneNumber: ``,
        emailInfo: ``
      },
      section1: {
        title: `Complete your high school education online, in Spanish, and at your own pace.`,
        subtitle: `Dawere International High School`,
        buttons: {
          inscription: `Try it free`,
          moreInfo: `More info`,
          moreCatalog: `Choose the educational program to try`
        }
      },
      certifications: {
        title: `Accreditations`,
        text: `
          <p>
           Dawere International High School is accredited by Cognia™—a prestigious global nonprofit organization that offers accreditation and certification, assessment, professional learning, and improvement solutions to institutions and other education providers. Cognia works in 90+ countries and serves 36,000 institutions, nearly 25 million students, and five million educators every day. This certification is a distinctive mark of quality recognized worldwide. Having earned it is irrefutable proof of our commitment to the students of Dawere.
            </p>
        `,
        images: {
          1: {
            url: `https://storage.googleapis.com/dawere-web-assets/Ilustraciones/COGNIA/SELLO%20COGNIA_Mesa%20de%20trabajo%201.svg`,
            alt: `Cognia`
          }
        }
      },
      section2: {
        title: ``,
        subtitle: `Online High School for Spanish Speakers`,
        text: ``,
        pictures: {
          1: {
            url: `https://storage.googleapis.com/dawere-web-assets/logos/logo_ministeriopoder.svg`,
            alt: `MPPE`
          }
        }
      },
      section3: {
        title: `Benefits of studying high school with us`,
        benefits: {
          1: {
            title: `Quality`,
            description: `We follow quality standards established by the FLDOE, CPALM, and PISA.`
          },
          3: {
            title: `Convenience`,
            description: `Classes adjusted to your schedule, at your pace and from anywhere.`
          },
          2: {
            title: `In Spanish`,
            description: `All coursework in your native language guided by Hispanic teachers to support you.`
          },
          4: {
            title: `Competitive prices`,
            description: `Affordable education and scholarship opportunities.`
          }
        }
      },
      section4: {
        title: `Start your free trial period`,
        text: `You can take the educational program <strong>{{program}}</strong> for free for {{days}} days. Learn first-hand the benefits of the virtual classroom, including video classes, readings, evaluations (formative and summative) and access to forum-chats by subject and synchronous meetings with teachers. `,
        buttons: {
          enroll: `Try it free`
        }
      },
      download: {
        title: `No matter what, you'll be able to study`,
        text: `Through Dawere you can advance in your studies wherever and whenever you want. Now, with our new app, you can do it even without internet! You just have to download the educational content to view your classes offline when you need it. Your progress will automatically update when you connect to the Internet again. `,
        slogan: 'Download the app now!'
      },
      section5: {
        title: `Get a double diploma`,
        text: `We offer a dual diploma through our school in Miami registered with the Florida Department of Education under No. 6139`,
        buttons: {
          enroll: `Try it free`,
          moreInfo: `More info`
        },
        pictures: {
          1: {
            url: `https://storage.googleapis.com/dawere-web-assets/logos/logo_floridadepartment.svg`,
            alt: `Florida`
          },
          2: {
            url: ``,
            alt: `Cognia`
          }
        }
      },
      section6: {
        title: `How does Dawere work?`,
        steps: {
          1: {
            title: `Sign up`,
            subtitle: `STEP 1`,
            text: `If you are 15 years of age or older you can enroll in Dawere regardless of your geographic location. You only have to fill out the registration form, indicate your school years approved to date, and make your payment.`
          },
          2: {
            title: `Submit your requirements`,
            subtitle: `STEP 2`,
            text: `You must deliver a copy of your identity card and a copy of the birth certificate, among other things.`
          },
          3: {
            title: `Start studying`,
            subtitle: `STEP 3`,
            text: `In our virtual school you learn with pre-recorded video classes, PDF guides, practice tests, a forum-chat where you get your questions answered and more. Study at your own pace and from anywhere with internet access. Dawere is easy to navigate from a computer, tablet, or smartphone.`
          },
          4: {
            title: `Graduate`,
            subtitle: `StEP 4`,
            text: `After successfully finishing all your school courses, you'll attend a virtual graduation ceremony, and will receive your high school diploma.`
          }
        },
        links: {
          requirements: `See full list of requirements`
        },
        buttons: {
          moreInfo: `More info`
        }
      },
      section7: {
        title: `Meet our students`
      },
      // Section 8: ../shared/alleis
      section9: {
        categories: {
          1: {
            subtitle: `PARENTS`,
            title: `Follow your child's academic progress`,
            text: `As a parent you will be able to monitor the academic progress of your children and promptly identify weaknesses and strengths. You'll also receive notices about their grades, their school performance in each subject to date, upcoming tests, and more. Register in our virtual school to receive more information about the benefits of our distance learning modality.`,
            buttons: {
              enroll: `Sign up as a parent`
            }
          },
          2: {
            subtitle: `ORGANIZATIONS`,
            title: `Manage your education project`,
            text: `Empower the members of your company, community or nation by giving them quality virtual education. You can buy enrollment coupons in batches and assign them to each student in order. You will be able to monitor the administrative aspect of your beneficiaries as well as closely follow their academic progress. Have total control over your educational project. Sign up to receive more information about the benefits that Dawere offers to organizations like yours.`,
            buttons: {
              enroll: `Sign up as an organization`
            }
          },
          3: {
            subtitle: `STUDENTS`,
            title: `You are closer to graduating high school`,
            text: ``,
            buttons: {
              enroll: `Sign up as student`
            }
          }
        }
      },
      section10: {
        subtitle: `Results of the satisfaction survey of our students in 2020`,
        title: `93% of students report that the educational quality they receive at Dawere is equal to or higher than what they received at their face-to-face school.`
      },
      // Section 11: ../shared/contact
      section12: {
        title: `Explore all our courses`,
        text: `Dawere has a variety of educational programs and courses. Take a look at our catalog and explore our offer.`,
        buttons: {
          catalog: `View educational programs`,
          enroll: `Try it free`
        }
      },
      reviews: {
        title: 'Our reviews on Google',
        footnote: `Rated <strong>{{ranking}}</strong> / 5, based on <strong>{{total}}</strong> reviews.`
      }
    },
    shared: {
      allies: {
        title: `Big and small organizations trust Dawere`
      },
      contact: {
        title: `Contact us`,
        text: `We are happy to answer any questions you have about Dawere.`,
        info: {
          phone: {
            title: `Phone Number`,
            text: ``
          },
          WhatsApp: {
            title: `WHATSAPP`,
            text: ``
          },
          email: {
            title: `EMAIL`,
            text: ``
          }
        },
        buttons: {
          faq: `FAQ`
        }
      }
    },
    register: {
      seo: {
        title: `Dawere.com | Sign up free`,
        description: `By registering at Dawere.com you will have access to private classes for free and will be one step closer to enrolling in the educational program of your choice.`,
        Keywords: `registration, students, parents, organizations, dawere, dawere.com`,
        ogTitle: `Sign up free`,
        ogDescription: `By registering at Dawere.com you will have access to private classes for free and will be one step closer to enrolling in the educational program of your choice.`,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/seo_registro.jpg`
      },
      title: `Sign up`,
      subtitle: `Create your account by filling in the data`,
      subtext: `He has invited you to join his team as`,
      withAccount: `Do you already have an account?`,
      tabs: {
        1: {
          title: `Student`,
          form: {
            name: `Name`,
            lastName: `Last name`,
            email: `Email`,
            confirmEmail: `Confirm your email`,
            password: `Password`
          }
        },
        2: {
          title: `Parents`,
          form: {
            name: `Name`,
            lastName: `Last name`,
            email: `Email`,
            confirmEmail: `Confirm your email`,
            password: `Password`
          }
        },
        3: {
          title: `Organization`,
          form: {
            organizationName: `Organization's legal name`,
            name: `Name`,
            lastName: `Last name`,
            email: `Email`,
            confirmEmail: `Confirm your email`,
            password: `Password`
          }
        }
      },
      buttons: {
        create: `Create your account`,
        googleLogIn: `Create your account with Google`
      },
      form: {
        errors: {
          name: `Invalid name`,
          lastname: `Invalid lastname`
        }
      },
      helps: {
        password: ``
      },
      links: {
        login: `Login`
      }
    },
    organizationDashboard: {
      seo: {
        title: `Organization dashboard`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      breadcrumbs: {
        1: `My organizations`,
        2: `Organization dashboard`
      },
      welcome: `Welcome, {{name}}`,
      alerts: {
        profile: {
          title: `Incomplete user profile`,
          description: `Complete your profile and fill in any missing information
to enjoy all the benefits of Dawere.`,
          button: `Complete user profile`
        },
        data: {
          title: `Incomplete organization data`,
          description: `Provide the billing information required so that you can make
optimal use of the platform.`,
          button: `Complete organization data`
        }
      },
      tabs: {
        1: {
          title: `Coupons`,
          empty: {
            title: `How many coupons do you want to buy?`,
            description: `You have not yet purchased any registration coupons.
Buying coupons is the first step of your educational project. Each coupon has a code.
When you assign coupons to your recipients, they can enroll and start studying with Dawere.
<br />
<br />
What are you waiting for?`,
            buttons: {
              buy: `Buy coupons`
            },
            contact: {
              email: `allianzas@dawere.com`,
              whatsapp: `+58 4242427150`
            }
          },
          full: {
            tabs: {
              1: {
                title: `Not assigned`,
                buttons: {
                  download: `Download`,
                  assign: `Assign`,
                  buy: `Buy coupons`
                },
                table: {
                  count: `Coupons`,
                  selected: `Selected`,
                  columns: {
                    coupon: `Coupon`,
                    type: `Product`,
                    code: `Coupon code`,
                    date: `Purchase date`
                  }
                },
                modal: {
                  title: `Assign coupon`,
                  description: ` <p>
Please enter the email of the beneficiary who you want to assign this coupon.
An email will be sent to it with instructions to sign up.
</p>
<p>
Once the student enrolls, you will be able to monitor their progress in the
"Students" tab".
</p>
`,
                  product: `Product:`,
                  code: `Coupon to assign:`,
                  placeholder: `Enter the student's email...`,
                  questions: `Do you have any questions about the assigned of coupons?`,
                  contactUs: `Contact us`,
                  button: `Assign coupon`
                },
                successModal: {
                  title: `Your coupon was successfully assigned`,
                  sentTo: `Coupon sent to:`,
                  product: `Product:`,
                  code: `Coupon assigned to:`,
                  button: `Done`
                }
              },
              2: {
                title: `Assigned`,
                buttons: {
                  download: `Download`,
                  assign: `Re-assign`
                },
                table: {
                  count: `Coupons`,
                  selected: `Selected`,
                  columns: {
                    coupon: `Coupon`,
                    type: `Product`,
                    code: `Coupon code`,
                    assignedTo: `Assigned to`,
                    date: `Purchase date`
                  }
                },
                modal: {
                  title: `Re-assign coupon`,
                  description: ` <p>
Please enter the email of the beneficiary who you want to assign this coupon.
An email will be sent to it with instructions to sign up.
</p>
<p>
We will also tell the previous beneficiary that they can no longer do
use of the coupon because it has been granted to another person.
</p>
`,
                  prevOwner: `Previous owner:`,
                  product: `Product:`,
                  code: `Coupon to re-assign:`,
                  placeholder: `Enter the student's email...`,
                  questions: `Do you have any questions about the assigned of coupons?`,
                  contactUs: `Contact us`,
                  button: `Assigned to`
                },
                successModal: {
                  title: `Your coupon was successfully assigned`,
                  curOwner: `New owner:`,
                  prevOwner: `Previous owner:`,
                  product: `Product:`,
                  code: `Reassigned coupon:`,
                  button: `Done`
                }
              },
              3: {
                title: `Used`,
                buttons: {
                  download: `Download`
                },
                table: {
                  count: `Coupons`,
                  columns: {
                    coupon: `Coupon`,
                    type: `Product`,
                    code: `Coupon code`,
                    assignedTo: `Assigned to`,
                    date: `Use date`
                  }
                }
              }
            }
          }
        },
        2: {
          title: `Students`,
          empty: {
            title: `None of your students have enrolled yet`,
            description: `Your beneficiaries will be able to enroll once you purchase coupons for them.
Please don't make them wait any longer!`,
            buttons: {
              buy: `Buy coupons`,
              add: `Add student`
            }
          },
          full: {
            tabs: {
              1: {
                title: `In progress`,
                buttons: {
                  add: `Add student`,
                  download: `Download`,
                  remove: `Remove`,
                  program: `Education program`,
                  period: `Period`,
                  calendar: `Calendar`
                },
                table: {
                  count: `Enrollments in progress`,
                  selected: `Selected`,
                  columns: {
                    name: `Student name`,
                    program: `Education program`,
                    period: `Period`,
                    calendar: `Calendar`,
                    admission: `Enrollment`,
                    student: `Student`
                  }
                }
              },
              2: {
                title: `Invitations sent`,
                PENDING: `Pending`,
                ACCEPTED: `Accepted`,
                REJECTED: `Rejected`,
                buttons: {
                  download: `Download`,
                  delete: `Delete`,
                  resend: `Resend`
                },
                table: {
                  count: `Invitations`,
                  selected: `Selected`,
                  columns: {
                    email: `Email`,
                    date: `Invitation date`,
                    status: `Status`,
                    invitation: `Invitation`
                  }
                }
              },
              3: {
                title: `Removed`,
                REMOVED: `Removed`,
                ROVOKED: `Rovoked`,
                buttons: {
                  download: `Download`,
                  program: `Education program`,
                  period: `Period`
                },
                table: {
                  count: `Removed program`,
                  columns: {
                    name: `Student name`,
                    program: `Education program`,
                    period: `Period`,
                    date: `Retirement date`,
                    status: `Status`,
                    student: `Student`
                  }
                }
              },
              4: {
                title: `Graduate`,
                buttons: {
                  download: `Download`,
                  program: `Education program`,
                  period: `Period`
                },
                table: {
                  count: `graduations`,
                  columns: {
                    name: `Student name`,
                    program: `Education program`,
                    phoneNumber: `Phone number`,
                    date: `Graduation date`,
                    student: `Student`
                  }
                }
              },
              5: {
                title: `Concluded`,
                buttons: {
                  add: `Add student`,
                  download: `Download`,
                  remove: `Remove`,
                  reroll: `Reroll`,
                  program: `Education program`,
                  period: `Period`,
                  calendar: `Calendar`
                },
                table: {
                  count: `Pending re-registration`,
                  selected: `Selected`,
                  columns: {
                    name: `Name student`,
                    program: `Education program`,
                    period: `Period`,
                    calendar: `Calendar`,
                    student: `Student`
                  }
                }
              },
              6: {
                title: `To enroll`,
                buttons: {
                  add: `Assign coupon`,
                  download: `Download`,
                  remove: `Remove`,
                  program: `Education program`,
                  period: `Period`,
                  calendar: `Calendar`
                },
                table: {
                  count: `Students`,
                  selected: `Selected`,
                  columns: {
                    name: `Student name`,
                    date: `Listing date`
                  }
                }
              }
            }
          }
        },
        3: {
          title: `Reports`
        },
        4: {
          title: `My payments`,
          DRAFT: `Incomplete`,
          PENDING: `Processing`,
          CONFIRMED: `Paid`,
          PAID: `Paid`,
          REJECTED: `Rejected`,
          ERROR: `In revision`,
          VOIDED: `Voided`,
          buttons: {
            filter: `Filter by date`,
            download: `Download PDF`,
            cancel: `Cancel`,
            pay: `Pay`,
            seeDetails: `See details`,
            continue: `Continue`
          },
          table: {
            count: `Payments`,
            selected: `Selected`,
            columns: {
              status: `Status`,
              number: `Invoice number`,
              paymentNumber: `Payment number`,
              method: `Payment method`,
              date: `Payment date`,
              amount: `Amount`,
              payments: `Payments`
            }
          },
          revisionModal: {
            title: `There was a problem with your payment`,
            description: `We ran into a technical problem while processing your payment. Please, do not try to pay again. Go to our help center and report this problem. We will get back to you ASAP.`,
            button: `Report this problem`
          },
          modal: {
            successTitle: `Payment details code {{code}}`,
            failureTitle: `Your payment has been declined`,
            failureDescription: `Below are the reasons for rejection of your payment code {{code}}:`,
            billingTitle: `Billing information`,
            paymentMethod: `Payment method`,
            bank: `Bank`,
            transferDate: `Transfer date`,
            reference: `Full reference code`,
            payer: `Payer name (please enter the full name)`,
            amount: `Amount`,
            STRIPE: `Credit or debit card`,
            PAYPAL: `PayPal`,
            CASH: `Cash`,
            WALLET: `Discount vouchers`,
            TRANSFER: `Transfer`,
            footnote: `If you think there was an error or have any questions about the payment process, please create a ticket in our help section and we will contact you as soon as possible.`,
            button: `Ask for help`,
            brand: `Brand`,
            last4digits: `Credit card number`
          },
          deleteModal: {
            title: `Attention!`,
            description: `Are you sure you want to void this payment?`,
            button: `Void`,
            toast: `The payment has been voided successfully.`
          }
        },
        5: {
          title: `Organization charts`,
          placeholder: `Select a program...`,
          avatars: {
            members: `Members`,
            teachers: `Teachers`,
            more: `More info`
          }
        },
        6: {
          title: `Discount vouchers`,
          description: `You can use the balance in your discount vouchers to make payments in Dawere as long as they are in the same currency as the credit in favor.`
        }
      }
    },
    guardianDashboard: {
      seo: {
        title: `Parent/Mentor dashboard`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      add: `Enter the email of the student you want to monitor. The student
will receive an invitation from you. Once you accept it, you can continue and
support their academic advancement.`,
      title: `Parent/Mentor dashboard`,
      welcome: `Welcome, {{name}}`,
      buttons: {
        addStudent: `Add student`
      },
      empty: {
        title: ` You don't have any students under your guardianship yet.`,
        description: `Ask a student to grant you access to their academic data.
Upon receiving this permit, you will be able to monitor their qualifications,
Enrollment documents, academic history and administrative solvency.`
      }
    },
    tutorDashboard: {
      seo: {
        title: `Parent/Mentor dashboard`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      add: `Enter the email of the student that you want to monitor. The student
        will receive an invitation. Once it is accepted, you'll be able to follow
        and support the student's academic progress.`,
      title: `Parent/Mentor dashboard`,
      welcome: `Welcome, {{name}}`,
      buttons: {
        addStudent: `Add student`
      },
      empty: {
        title: ` You are not monitoring any students yet.`,
        description: `Ask for access to a student’s data. Upon receiving this permit,
    you will be able to monitor his / her grades, enrollment documents, academic
    history and administrative solvency`
      }
    },
    studentDashboard: {
      seo: {
        title: `Student dashboard`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      welcome: `Welcome, {{name}}`,
      title: `Student dashboard`,
      tabs: {
        1: {
          title: `In progress`,
          programs: `Educational programs`,
          noProgramsTitle: `You don't have been enrolled in any educational programs yet`,
          noPrograms: `Explore our educational programs and choose the program that best suits your needs and aspirations. Remember that all programs have a 7-day free trial period!`,
          enrollToday: `Sign up now`,
          congrats: `Congratulations on completing the {{period}} of the {{program}}!`,
          congrats2: `Congrats! You've passed the {{period}}`,
          subtext: `We have added the next subjects that you need to tackle.`,
          degree: `You're on your way to earning your {{degreeName}}.`,
          enrollNextPeriod: `Enroll in the next period`,
          continueNextPeriod: `Continue`,
          askForHelp: `Ask for help`,
          deprecated: `This educational program has been superseded`,
          chooseProgram: `Choose one of the following programs to continue with
your studies`,
          trial: {
            active: `The free trial of this program will end on <strong>{{trialEndsAt}}</strong>, You can complete your enrollment at any time`,
            activeLink: `here`,
            noActive1: `The free trial of this program has expired. Complete your enrollment`,
            noActiveLink: `here`,
            noActive2: `to continue with the program and to maintain your academic progress.`
          },
          graduated: {
            title: `Congrats, you have graduated!`,
            description: `You've completed all the requirements to obtain a {{degree}}.`,
            details: `Go to the Help Center to request your graduation documents.`,
            button: `Request graduation documents`
          },
          finishedCourse: {
            title: ` Congrats!`,
            description: `You've successfully finished this course.`,
            button: `Check out your final grades`
          },
          pendingPayment: {
            title: `You have overdue payments`,
            description: `You can make the payments you have pending in the Payments and installments section.`,
            button: `Go to Payments and installments`
          },
          pendingProfile: {
            title: `Incomplete profile`,
            description: `To continue your studies you must complete all the information in your profile.`,
            button: `Go to Profile`
          },
          pendingTutor: {
            title: `Invite parent or guardian`,
            description1: `Underage students must have a parent or guardian involved on their account. `,
            description2: `To continue your studies, link your parents or guardians to your account through the “Permissions” section:`,
            button: `Go to Permissions`
          }
        },
        2: {
          title: `My Documents`
        },
        3: {
          title: `Academic record`
        },
        4: {
          title: `Payments and installments`,
          refresh: `Refresh`,
          tabs: {
            1: {
              title: `Payments`
            },
            2: {
              title: `Installments`,
              PAID: `Paid`,
              FAILED: `Failed`,
              NOT_ALIVE: `Processing`,
              PENDING: `Pending`,
              DUE: `Overdue payment`,
              PROCESSING: `Processing`,
              VOID: `Void`,
              VOIDED: `Voided`,
              buttons: {
                pay: `Pay`,
                orderNumber: `Order number`,
                payPending: `Pay overdue Installments`,
                payAll: `Pay all`
              },
              table: {
                columns: {
                  number: `Installment number`,
                  dueDate: `Duedate`,
                  amount: `Amount`,
                  status: `Status`,
                  installment: `Installment`
                }
              }
            },
            3: {
              title: `Billing`
            }
          }
        },
        5: {
          title: `Calendar`
        },
        6: {
          title: `Scorecards`,
          ACTIVE: `Active`,
          CONCLUDED: `Concluded`,
          RE_ENROLLMENT: `Concluded`,
          FINISHED_COURSE: `Concluded`,
          CONTINUE: `Concluded`,
          DEBT: `Debt`,
          GRADUATED: `Graduated`,
          DEPRECATED_PROGRAM: `Deprecated`,
          FREE_TRIAL: `Free trial`,
          FREE_TRIAL_ENDED: `End of free trial`,
          period: `Period: `,
          enrollmentDate: `Enrollment date: `,
          calendar: `Calendar: `,
          order: `Order: `,
          orderDescription: `Description: `,
          school: `School: `,
          button: `See scorecard`
        }
      }
    },
    profile: {
      title: `Edit profile`,
      subtitle: `Edit your personal information`,
      section1Title: `CONTACT INFORMATION`,
      section2Title: `IDENTITY INFORMATION`,
      section3Title: `BIRTH INFORMATION`,
      section4Title: `RESIDENCE INFORMATION`,
      section5Title: `OTHER INFORMATION`,
      toast: {
        incomplete: `Your profile is incomplete. To continue your studies you must complete all the information in the "Profile" section.`,
        parentIncomplete: `Fill out your profile to be able to monitor your clients.`,
        successPhoneNumber: `Phone number verified successfully.`,
        successResend: `Code resent successfully.`,
        successUpdate: `Profile data updated successfully.`
      },
      form: {
        name: `Name`,
        lastName: `Last name`,
        phone: `Phone number`,
        email: `Email`,
        identityType: `Identity type`,
        indetityNumber: `Identity number`,
        gender: `Gender`,
        race: `Race and ethnicity`,
        birthCountry: `Country of birth`,
        birthState: `State of birth`,
        birthTownship: `Township of birth`,
        birthday: `Birthday`,
        country: `Country of residence`,
        state: `State of residence`,
        township: `Town of residence`,
        howFoundUs: `How did you find out about Dawere?`,
        specialNeeds: `Special educational needs`
      },
      buttons: {
        submit: `Save changes`
      },
      helps: {
        phoneNumber: `By entering a phone number, you agree to receive automated SMS text messages with information relevant to the educational and promotional service. Message delivery and mobile data usage rates may apply. You can change these parameters in your settings / notifications section.`
      },
      modal: {
        title: `Verify your phone number`,
        button: `Check`,
        description: `Enter the 6-digit code sent to the cell phone number you provided.`,
        placeholder: `Verification code`,
        text1: `If you did not receive the code,`,
        text2: `reapply`
      },
      seo: {
        title: `Edit your Profile`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      }
    },
    studentDetails: {
      seo: {
        title: `Student details`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      breadcrumbs: {
        2: `Student details`
      },
      title: `Educational programs`,
      tabs: {
        1: {
          title: `School Subjects`,
          yearGPA: `Period GPA:`,
          cumulativeGPA: `GPA:`,
          calendar: `Calendar`,
          tooltip: `It is obtained by adding the grades obtained in each subject and then dividing by the total number of subjects in the period.`
        },
        2: {
          title: `Documents`,
          mandatory: `REQUIRED Enrollment documents`,
          optional: `OPTIONAL DOCUMENTS`
        },
        3: {
          title: `Academic record`,
          TO_COURSE: `Pending to attend`,
          FINAL_PERIOD: `Final period`,
          TRANSFERRED: `Transferred`,
          EXONERATED: `Exonerated`,
          EQUIVALENCE: `Equivalence`,
          TUTORSHIP: `Tutorship`,
          PENDING: `Pending`,
          APPROVED: `Approved`,
          NOT_APPROVED: `Not Approved`,
          IN_COURSE: `In progress`,
          VALIDATED: `Validated`,
          REPEATING: `Repeating`,
          table: {
            columns: {
              name: `School Subjects`,
              school: `Educational institution`,
              score: `Score`,
              date: `Due date`,
              status: `State`,
              credits: `Credits`
            }
          }
        }
      },
      labels: {
        student: `Student`,
        phoneNumber: `Phone number`,
        identityType: `Identity type`,
        email: `Email`
      }
    },
    myDocuments: {
      seo: {
        title: `My documents`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      title: `My documents`,
      text: `Upload, edit or view your enrollment documents. Our team will review the documents
you upload to the platform and approve them if they meet the criteria. <strong>Once they are approved, you will not be able to delete or replace them. </strong>`,
      mandatory: `REQUIRED ENROLLMENT DOCUMENTS`,
      optional: `OPTIONAL DOCUMENTS`,
      uploadSuccess: `Document successfully updated.`
    },
    philosophy: {
      seo: {
        title: `The School's Educational Philosophy - Mission & Vision`,
        description: `Our online learning model promotes students' autonomy and self-determination. The school respects the freedom to choose when, where and how to learn.`,
        keywords: `The School's Mission, Vision, and Educational Philosophy`,
        ogTitle: `Dawere Mission, Vision and Philosophy: Education Without Limits`,
        ogDescription: `At Dawere we use methods and techniques that promote student autonomy and seek their self-determination. We respect our students’ freedom to choose when, where and how to learn.`,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/Illustrations/*FILOSOFIA/ID_Pagina_Filosofia2.png`
      },
      section1: {
        title: `Mission, Vision and Educational Philosophy`,
        text: `At Dawere we strongly believe in the transformative power of education. This has a domino effect where the positive impact on the individual implies a positive impact on their family, community, city, nation, and planet. The well-being that derives from education is what motivates Dawere's mission, vision and educational philosophy.`,
        video: `https://www.youtube.com/embed/l42u9s7n8_o`
      },
      section2: {
        title: `Our mission`,
        subtitle: `Grant Hispanics access to quality education through technology`,
        text: `<p>Dawere's mission is to spread quality education to the Spanish-speaking world. Dawere exists in order to successfully include all those people who do not have access to a quality academic experience through the traditional or face-to-face system. Through this democratization and the incessant improvement of its teaching processes, Dawere breaks with the norm that establishes educational quality as a luxury for the elites and erects a new order: <strong>premium education is available to everyone</strong>.</p >
<p>We are committed to developing citizens’ essential skills so that they can fully participate in society. We carry out our mission through technology and the highest quality standards that we pursue with every step we take.</p>`
      },
      section3: {
        title: `Our vision`,
        subtitle: `Lead formal online learning for Hispanics around the world`,
        text: `<p>Dawere aspires to be the first reference for virtual formal secondary or high school education for the Spanish-speaking population.</p>
<p>Our study modality based on international quality standards seeks to combine a virtual and autonomous teaching method with live collaborative work. Dawere's educational model will be endorsed by educational accreditation agencies. Additionally, the effectiveness of said model will be verified through the success of our students in the PISA standardized tests.</p>`
      },
      section4: {
        title: `Educational philosophy`,
        item1: {
          title: `Student focused`,
          text: `In keeping with the liberal conception of pedagogy, we use student-centered approaches, as opposed to teacher-directed approaches. That is why we use methods and techniques that promote student autonomy and seek their self-determination. At Dawere we respect our students’freedom to choose when, where and how you learn.`
        },
        item2: {
          title: `Creative process`,
          text: `<p>
We believe that learning is a creative process of experimentation and
search for the unknown. Our contents and evaluations are
based on scenarios that allow students to project themselves as
active beings capable of analyzing and solving real problems. That's why
we look for "meaningful learning" (*) and distance ourselves from rote learning.
</p>
<p class='fs-small text-muted'>
(*) David Ausubel: Psychology of meaningful verbal learning,
New York, 1963.
</p>`
        },
        item3: {
          title: `Ubiquitous education`,
          text: `Thanks to technology, education becomes ubiquitous and has unimaginable power: being able to deliver quality everywhere, in a massive way, and at the same time.`
        },
        item4: {
          title: `Continuous and critical evaluation`,
          text: `We seek to promote knowledge, values and ideals that lead to the common good for the individual and for society, all with the ultimate goal of students discovering their ability to transform their reality.`
        }
      }
    },
    formativeEvaluation: {
      seo: {
        title: `Formative assessment`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      leavePage: `Are you sure you want to exit the evaluation?`,
      title: `Formative assessment`,
      correct: `Correct answer`,
      incorrect: `Wrong answer`,
      finishModal: {
        title: `Finish the assessment`,
        yes: `Yes`,
        description: `Are you sure you have finished taking this assessment and want to receive your grade?`,
        sending: `Sending`,
        sendingDesc: ` Soon you will be able to see your score.`
      }
    },
    pisaEvaluation: {
      seo: {
        title: `Benchmark assessment - PISA`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      leavePage: `Are you sure you want to exit the evaluation?`,
      source: `Font:`,
      inf: `Unlimited`
    },
    classroom: {
      seo: {
        title: `Virtual Classroom`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      title: `Virtual Classroom`,
      TOPIC: `Topic {{number}}`,
      PISA: `Benchmark assessment - PISA`,
      PROJECT: `Project {{number}}`,
      VIDEO: `Video {{number}}`,
      LECTURE: `Lecture {{number}}`,
      FORMATIVE: `Formative assessment`,
      seen: `Seen`,
      read: `Read`,
      browserIssue: `We have detected that you are using the browser{' '}
<strong>Safari</strong>. The recommended browsers are <strong>Chrome</strong> and <strong>Firefox</strong>.
Please change your browser.`,
      markAsSeen: {
        toast: `The content has been marked as {{verb}}.`,
        button: ``
      },
      buttons: {
        openTelegram: `Open forum-chat`,
        disabledTelegram: `This chat, with your teacher and classmates, will not be available during the free trial.`,
        openCalendly: `Take appointment`,
        previous: `Previous`,
        next: `Next`,
        markAsSeen: `Mark as {{verb}}`,
        checkAnswer: `Check answer`,
        end: `Finish`,
        continue: `Continue`,
        back: `Back to Virtual Classroom`
      },
      timeIsOver: {
        title: `Time is over`,
        description: `The time to present the assessment is over. Continue to the results report to see the grade you obtained on this opportunity.`
      },
      tooltips: {
        close: `Close`,
        content: `Content`
      },
      subtype: {
        VIDEO: `Video`,
        LECTURE: `Lecture`,
        FORMATIVE: `Formative assessment`,
        PISA: `Benchmark assessment - PISA`,
        PROJECT: `Benchmark assessment - Project`
      },
      tabs: {
        1: {
          title: `Content`,
          filter: {
            title: `Filter by`,
            all: `Lesson plan`,
            evaluations: `Assessment`
          }
        },
        2: {
          title: `School Subjects`
        },
        3: {
          title: `Qualification`,
          subtitle: `Cumulative grade point average:`,
          table: {
            name: `Assessment`,
            weight: `Weight`,
            score: `Score`,
            status: `Status`
          }
        },
        4: {
          title: `Chat`
        },
        5: {
          title: `Calendar`
        },
        6: {
          title: `Teachers`
        },
        7: {
          title: `Recorded live lessons`,
          description: `In this section, you'll find all the live lessons that your teacher delivered during the academic calendar. If you missed a live lesson, you can watch the recording here.`
        }
      },
      formativeEvaluationDetails: {
        title1: `General instructions`,
        details: `Formative Assessments are practice tests for you to measure your competencies and see how prepared you are for your Benchmark Assessments.`,
        detailsList: `
<ul className='mb-4'>
<li>
<strong>Assessment Type:</strong> Benchmark
</li>
<li>
<strong>Weight:</strong> It has no weight in your final grade
</li>
<li>
<strong>Number of questions:</strong> {{numberOfQuestions}}
</li>
<li>
<strong>Topic being evaluated:</strong> {{topic}}
</li>
<li>
<strong>Number of tries left:</strong> Unlimited
</li>
</ul>
`,
        title2: `Code of ethics and academic integrity`,
        ethicalCode: `Before starting this activity, you must agree to comply with the following rules of conduct:`,
        ethicalCodeList: `
<ul className='mb-0'>
<li>
The answers you provide in your assessments must be the result of your own work and intellectual efforts.
</li>
<li>
Do not share your answers with anyone else.
</li>
<li>
Do not take part in activities that improve your grades in a dishonest way.
</li>
<li>
For all written assignments, plagiarism is forbidden.
</li>
</ul>
`,
        checkbox: `<p>I have read and agree to fully comply with the code of conduct and academic integrity. If it is detected that there was dishonesty or that I broke the code in any way, I accept the full extent of the consequences, which include, but are not limited to, automatically being expelled from Dawere and not being able to enroll in the future.</p>
        <p>Discussion forums are designed for collaborative learning and the exchange of ideas. By participating, you agree to the forum's policies and code of conduct. For more information, see the student handbook.</p>`,
        button: `Start Formative Assessment`,
        tooltip: `Practice as many times as you need to until you feel prepared.`
      },
      pisaEvaluationDetails: {
        title1: `General instructions`,
        details: `The grade obtained in this assessment will form part of your academic record. The platform will automatically assign the highest score among the opportunities presented. Take into account your level of preparation in each of the topics being evaluated before presenting.`,
        detailsWarning: `Make sure you take the test in a quiet, comfortable environment with good internet connection. Once the evaluation begins, focus solely and exclusively on finishing it. You may lose the opportunity if you do not follow the instructions specified here.`,
        detailsList: `
<ul className='mb-4'>
<li>
<strong>Type of assessment:</strong> Benchmark - PISA
</li>
<li>
<strong>Deadline:</strong> {{date}}
</li>
<li>
<strong>Time allotted:</strong> {{time}}
</li>
<li>
<strong>Weight:</strong> {{weight}}%
</li>
<li>
<strong>Number of questions:</strong> {{numberOfQuestions}}
</li>
<li>
<strong>Minimum passing grade:</strong> {{minGrade}}
</li>
<li>
<strong>Score:</strong> {{grade}}
</li>
</ul>
`,
        title2: `Code of ethics and academic integrity`,
        ethicalCode: `Before starting this activity, you must agree to comply with the following rules of conduct:`,
        ethicalCodeList: `
<ul className='mb-0'>
<li>
The answers you provide in your assessments must be the result of your own work and intellectual efforts.
</li>
<li>
Do not share your answers with anyone else.
</li>
<li>
Do not take part in activities that improve your grades in a dishonest way.
</li>
<li>
For all written assignments, plagiarism is forbidden.
</li>
<li>
Once you start this assessment, you should focus solely on this activity. You should not have any more windows open on your device.
</li>
</ul>
`,
        checkbox: `I have read and agree to fully comply with the code of conduct and academic integrity. If it is detected that there was dishonesty or that I broke the code in any way, I accept the full extent of the consequences, which include, but are not limited to, automatically being expelled from Dawere and not being able to enroll in the future.`,
        button: `Start Benchmark Assessment`,
        tooltip: `Remember that this evaluation is a Benchmark Assessment and will be reflected in your final grade; take it when you feel ready.`,
        dateTooltip: `You will be able to take this assessment on <strong>{{date}}</strong>.`
      },
      projectDetails: {
        project: `My Project`,
        download: `Open my project`,
        markAsRead: `Mark Instructions as Read`,
        markAsReadToast: `The instructions have been marked as read.`,
        breadcrumbs: {
          1: `Project {{number}} - Instructions`,
          2: `Project {{number}} - File Upload`,
          3: `Project {{number}} - Co-evaluation`,
          4: `Project {{number}} - Results`
        },
        title1: `General Instructions`,
        step1: `Instructions`,
        step2: `File Upload`,
        step3: `Co-evaluation`,
        step4: `Results`,
        details: `First, you must submit your project before the deadline. Then, the system will assign you projects from your classmates to evaluate before the co-evaluation deadline. Finally, all students will receive their grade along with feedback from their peers.`,
        rubrics: {
          title: `Evaluation Criteria`,
          aspect: `Aspects to Evaluate`,
          aspect100: `Excellent (100%)`,
          aspect66: `Good (85%)`,
          aspect33: `Regular (65%)`,
          aspect0: `Not Observed (10%)`
        },
        detailsListRe: `
<ul className='mb-4'>
<li>
<strong>Evaluation Type:</strong> Summative - Project
</li>
<li>
<strong>Submission Date:</strong> {{date}}
</li>
<li>
<strong>Estimated Seat Time:</strong> {{time}}
</li>
<li>
<strong>Weight:</strong> {{weight}}%
</li>
<li>
<strong>Minimum Passing Grade:</strong> {{minGrade}}
</li>
<li>
<strong>Grade Obtained:</strong> {{grade}}
</li>

  </ul>
    `,
        detailsListCo: `
      <ul className='mb-4'>
    <li>
      <strong>Evaluation Type:</strong> Summative - Project with Co-evaluation
    </li>
    <li>
      <strong>Submission Date:</strong> {{dueDate}}
    </li>
    <li>
      <strong>Co-evaluation Deadline:</strong> {{dateToCoevaluate}}
    </li>
    <li>
      <strong>Estimated Seat Time:</strong> {{time}}
    </li>
    <li>
      <strong>Weight:</strong> {{weight}}%
    </li>
    <li>
      <strong>Minimum Passing Grade:</strong> {{minGrade}}
    </li>
    <li>
      <strong>Grade Obtained:</strong> {{grade}}
    </li>
  </ul>
    `,
        uploadDetails: `In this section, you must upload and submit your project to be evaluated by your classmates.`,
        uploading: `Submitting project...`,
        uploadDetails2: `You must submit or replace your project to be evaluated by your classmates:`,
        uploadDetails3: `We have already received your project.`,
        uploadWarning: `The deadline to submit your project is {{date}}.`,
        expiredTimeWarning: `The deadline to submit this project was {{date}}. However, we did not receive a submission from you. Unfortunately, it is no longer possible to participate in this activity.`,
        title2: `Code of Ethics and Academic Integrity`,
        ethicalCode: `Before starting this activity, you must commit to comply with the following rules of conduct:`,
        ethicalCodeList: `
      <ul className='mb-0'>
      <li>
        The answers to each evaluation must be your own work and intellectual content.
      </li>
      <li>
        You must not share your answers with anyone else.
      </li>
      <li>
        You cannot engage in activities that dishonestly enhance your results or grades.
      </li>
      <li>
        In the case of written production activities, any form of plagiarism is prohibited.
      </li>
      <li>
        When starting this evaluation, you should focus solely on this activity. You should not have any other windows open on your device.
      </li>
    </ul>
    `,
        checkbox: `I have read and agree to fully comply with the code of conduct and academic integrity. If dishonesty is detected or if I fail to comply with the stated code in any way, I accept the consequences, which may include, but are not limited to, being automatically expelled from the Dawere educational system and being unable to enroll in the future.`,
        button: `Submit Project`,
        confirmUpload: {
          title: `Submit project`,
          text: `Are you sure you want to submit this project?`,
          button: `Confirm`,
          toast: `Your project has been successfully submitted.`
        },
        tooltip: `Remember that this evaluation is summative and will be reflected in your final grade. Complete it when you feel prepared.`,
        dateTooltip: `You can submit this evaluation starting on <strong>{{date}}</strong>.`,
        coevaluation: {
          empty: `It is not yet time to evaluate your classmates. This section will unlock starting from {{date}}.`,
          description: `Now, you must evaluate the projects of your classmates. Remember that co-evaluation is completely anonymous, and you have until {{date}} to finish evaluating the assigned projects below.`,
          alert: `Evaluating your classmates is mandatory. Otherwise, it will be considered as if you never submitted the project, and you will not receive a grade.`,
          table: {
            partners: `Classmates`,
            status: `Status`,
            grade: `Grade`,
            date: `Date`,
            evaluate: `Evaluate`,
            evaluated: `Evaluated`,
            partner: `Classmate #{{number}}`
          }
        },
        evaluationModal: {
          text1: `For each of the items to be assessed in the following rubric, you must select a grade according to what you observed in your classmate's project. Remember that this co-evaluation is completely anonymous. No student will be able to know the grade selected by another student.`,
          text2: `Next, write a comment to your classmate where you point out what you liked most about her/his project and the opportunities for improvement:`,

          title1: `Co-evaluation classmate #{{number}}`,
          submit: `Submit co-evaluation`,
          openProject: `See project`,
          title2: `Comments`,
          placeholder: `Write your comments...`
        },
        results: {
          title: `Co-evaluation #{{number}}: `,
          noevaluated: `This classmate did not complete the co-evaluation of your project.`,
          empty: `We have not yet received all co-evaluations. Starting from {{date}}, we will process all the results, and you will be able to see your grade in this section.`,
          description: `In this report, you will find an average of the grades assigned to your project by your classmates. You will also find the feedback they left for you.`
        }
      }
    },
    myOrganizations: {
      seo: {
        title: `My organizations`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      title: `My organizations`,
      buttons: {
        add: `Create organization`,
        configuration: `Settings`
      },
      pagination: `organization(s)`
    },
    createOrganization: {
      seo: {
        title: `Create organization`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      breadcrumbs: {
        1: `My organizations`,
        2: `Create organization`
      },
      title: `Create organization`,
      section1Title: `TAX INFORMATION`,
      section2Title: `FISCAL ADDRESS`,
      form: {
        name: `Business name`,
        tin: `Tax registration number or identity`,
        country: `Country`,
        state: `State`,
        township: `Province`,
        billingAddress: `Fiscal address`,
        postalCode: `Zip code`
      },
      buttons: {
        submit: `Create organization`
      },
      toast: {
        successCreated: `The organization {{name}} has been successfully created.`
      }
    },
    editOrganization: {
      seo: {
        title: `Edit organization`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      breadcrumbs: {
        1: `My organizations`,
        3: `Edit organization`
      },
      title: `Edit`,
      tabs: {
        1: {
          title: `Billing information`,
          section1Title: `TAX INFORMATION`,
          section2Title: `FISCAL ADDRESS`,
          form: {
            name: `Business name`,
            tin: `Tax registration number or identity`,
            country: `Country`,
            state: `State`,
            township: `Province`,
            billingAddress: `Fiscal address`,
            postalCode: `Zip code`
          },
          buttons: {
            submit: `Save information`
          },
          toast: {
            successUpdated: `Your billing information was successfully saved.`
          }
        },
        2: {
          title: `Campus organization chart`
        }
      }
    },
    members: {
      seo: {
        title: `Staff members`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      breadcrumbs: {
        2: `Staff members`
      },
      title: `{{name}} members`,
      buttons: {
        add: `Invite new member`
      },
      modals: {
        add: {
          title: `Invite a new member`,
          text: `Enter the email of the person you want to invite to become part of your staff.`,
          placeholder: `Write an email...`,
          button: `Send invitation`,
          success: `An invitation has been sent to the email {{email}}.`
        },
        delete: {
          title: `Delete team member`,
          invitationTitle: `Delete invitation`,
          text: `Are you sure you want to remove <strong>{{name}}</strong> from your team?`,
          invitationText: `Are you sure you want to delete the invitation sent to <strong>{{name}}</strong>?`,
          confirmText: `Delete`,
          success: `Member {{name}} deleted successfully.`,
          invitationSuccess: `Invitation deleted successfully.`
        },
        resend: {
          title: `Resend invitation`,
          text: `Are you sure you want to send another invitation to <strong>{{email}}</strong> to join your staff?`,
          confirmText: `Resend`,
          success: `Invitation successfully sent.`
        }
      },
      tabs: {
        1: {
          title: `Assets`,
          ADMINISTRATOR: `Administrator`,
          OWNER: `Owner`,
          TEACHER: `Teacher`,
          buttons: {
            delete: `Delete`
          },
          table: {
            count: `member(s)`,
            columns: {
              name: `Member name`,
              phone: `Phone`,
              role: `Role`,
              member: `Member`
            }
          }
        },
        2: {
          title: `To accept`,
          buttons: {
            delete: `Delete`,
            resend: `Resend`
          },
          table: {
            count: `member(s)`,
            columns: {
              email: `Email`,
              date: `Send Date`,
              member: `Member`
            }
          }
        }
      }
    },
    teachers: {
      seo: {
        title: `School teachers`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      breadcrumbs: {
        2: `School teachers`
      },
      title: `Teachers of {{name}}`,
      buttons: {
        add: `Invite teacher`
      },
      modals: {
        add: {
          title: `Invite teacher`,
          text: ` Enter the email of the teacher you want to invite to be part of your campus staff.`,
          textAssign: `Enter the email of the teacher you want to invite to be part of`,
          placeholder: `Write an email...`,
          placeholderAssign: `Type a name or email...`,
          placeholderChecking: `Verifying {{email}}...`,
          button: `Send invitation(s)`,
          success: `An invitation has been sent to the email {{email}}.`,
          successMultiple: `{{qty}} invitations have been sent.`
        },
        delete: {
          title: `Delete teacher`,
          invitationTitle: `Delete invitation`,
          text: `Are you sure you want to remove <strong>{{name}}</strong> from your teachers?`,
          textUnassign: `Are you sure you want to remove <strong>{{name}}</strong> of <strong>{{subject}} - {{period}}</strong> from the program <strong>{{program} }</strong>?`,
          invitationText: `Are you sure you want to delete the invitation sent to <strong>{{name}}</strong>?`,
          confirmText: `Delete`,
          success: `Teacher {{name}} deleted successfully.`,
          invitationSuccess: `Invitation deleted successfully.`
        },
        resend: {
          title: `Resend invitation`,
          text: `Are you sure you want to send another invitation to <strong>{{email}}</strong> to join your staff?`,
          confirmText: `Resend`,
          success: `Invitation successfully sent.`
        }
      },
      tabs: {
        1: {
          title: `Assets`,
          buttons: {
            delete: `Delete`
          },
          table: {
            count: `teacher(s)`,
            columns: {
              name: `Teacher Name`,
              phone: `Phone`,
              subjects: `Subjects`,
              member: `Teacher`
            }
          }
        },
        2: {
          title: `To accept`,
          buttons: {
            delete: `Delete`,
            resend: `Resend`
          },
          table: {
            count: `teacher(s)`,
            columns: {
              email: `Email`,
              date: `Send Date`,
              member: `Teacher`
            }
          }
        }
      }
    },
    documentsReport: {
      seo: {
        title: `Enrollment documents`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      breadcrumbs: {
        2: `Enrollment documents report`
      },
      title: `Enrollment documents`,
      description: `View the progress made by your beneficiaries in their enrollment process. Until the student delivers all the requested documents, their enrollment will not be legally valid in the eyes of the educational authorities of the corresponding country.`,
      count: `student(s)`,
      buttons: {
        download: `Download`
      },
      forms: {
        program: `Educational program`,
        calendar: `Academic calendar`,
        period: `Period`,
        submit: `Generate report`
      }
    },
    progressReport: {
      seo: {
        title: `Cumulative scores`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      breadcrumbs: {
        2: `Cumulative score`
      },
      title: `Cumulative scores`,
      description: `Here you can take a look at the results of the evaluations taken by your beneficiaries. Grades are a good indicator of student performance.`,
      count: `student(s)`,
      buttons: {
        download: `Download`
      },
      columns: {
        period: `Period`,
        subject: `Subject`,
        subjects: `Subjects`,
        progress: `Progress`,
        score: `Cumulative score`,
        evaluations: `Evaluations`
      }
    },
    permissions: {
      seo: {
        title: `Permissions management`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      title: `Permissions management`,
      pending: {
        title: `Pending invitations`,
        nothing: `There are no pending invites.`,
        buttons: {
          accept: `Accept`,
          reject: `Reject`
        },
        modals: {
          title: `Decline invitation`,
          text: `Do you wish to decline <strong>{{name}}</strong>'s invitation?`
        },
        toast: {
          successAccept: `The invitation from {{name}} has been accepted successfully.`,
          successReject: `The invitation from {{name}} has been rejected.`
        }
      },
      invitations: {
        sent: `Invitation sent on {{date}}`,
        buttons: {
          send: `Resend invitation`,
          delete: `Delete`
        },
        sendQuestion: `Are you sure you want to resend the invitation to
<strong>{{email}}</strong>?`,
        deleteQuestion: `Are you sure you want to delete the invitation sent to
<strong>{{email}}</strong>?`
      },
      myOrganizations: {
        title: `Organizations you belong to`,
        description: `The following organizations have given you permission to be part of their teams.`,
        nothing: `You are not part of the team of any organization within the platform.`,
        buttons: {
          revoke: `Revoke permissions`
        },
        modals: {
          title: `Revoke permissions`,
          text: ` Are you sure? If you revoke permissions, you will no longer be able to serve as <strong>{{role}}</strong> of <strong>{{name}}</strong>.`
        },
        toast: {
          success: `You have revoked your permissions as {{role}} of {{name}}.`
        }
      },
      myStudents: {
        title: `Authorized students`,
        description: `You have permission to view the activity of the following students.`,
        nothing: `You are not following anyone’s academic activity yet.`,
        revoke: {
          toast: `You have revoked parent/mentor permissions from {{fullName}}.`,
          description: `Currently, <strong>{{fullName}}</strong> can monitor your academic activity within the platform. If you revoke their permissions, they will no longer be able to do it.`
        }
      },
      myAuthorizedOrganizations: {
        title: `Authorized Organizations`,
        description: `The following organizations are allowed to follow your activity within the platform.`,
        nothing: ` You are not yet part of any organization within the platform.`
      },
      myTutors: {
        title: `Authorized Parents and Mentors`,
        description: `The following people have permission to follow your activity within the platform.`,
        nothing: `You don't have an authorized parent/mentor yet.`,
        buttons: {
          add: `Add parent/mentor`
        },
        revoke: {
          toast: `You have revoked your permission as parent/mentor of {{fullName}}.`,
          description: `Do you wish to relinquish the permissions granted to you as a parent/mentor?
You currently have access to the academic activity of
<strong>{{fullName}}</strong>. If you relinquish permissions, you will stop viewing their activity within the platform.`
        },
        modals: {
          title: `Add parent/mentor`,
          description1: `For your parents or mentors to follow and support your academic progress, you must invite them to the platform.`,
          description2: ` Write below the email of the person you want to invite.`,
          error: `Your parent/mentor's email must be different from yours.`,
          errorStudent: `The student's email must be different from yours.`
        }
      }
    },
    buyCoupons: {
      seo: {
        title: `Buy Coupon`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      leavePage: `Are you sure you want to leave the process?`,
      steps: {
        1: {
          title: `Select Coupons`,
          description: `Choose the coupons that you wish to buy for your students.`
        },
        2: {
          title: `Confirmation`,
          description: `Confirm your purchase and make the payment`,
          billing: {
            title: `Tax information`,
            cancel: `Cancel`,
            save: `Save tax information`,
            edit: `Edit tax information`,
            alertTitle: `Incomplete tax information`,
            alertDescription: `It is necessary that you enter all the billing information in order to make your purchase.`
          },
          gateways: {
            title: `Proceed to checkout`,
            creditCard: `Credit or debit card`,
            paypal: `Paypal Platform`,
            transfer: `Transfer`,
            cash: `Cash`
          },
          products: {
            title: `Products`,
            quantity: `Quantity: {{qty}}`
          },
          summary: {
            title: `Purchase Summary`,
            subtotal: `Sub-total: {{qty}} x {{price}}`,
            beforeTaxes: `Amount before taxes`,
            earlyPaymentDiscount: `Early bird discount`,
            discounts: `Discounts for coupons`,
            total: `ORDER TOTAL`,
            hasCoupon: ` Do you have a discount coupon?`,
            placeholder: `Enter code here`,
            installment: `Installment {{number}}`,
            payToday: `What you pay today`,
            payLater: `What you pay before {{date}}`,
            vouchers: `Discount for vouchers`,
            confirmPayment: `Confirm Payment`
          },
          transfer: {
            title: `Bank Transfer`,
            description: `Follow these three steps to complete your payment. All three steps are required to access the educational service.`,
            step1: `1. Choose the bank to which you want to make the transfer.`,
            step2: `2. Use the information below to make the bank transfer.`,
            step3: `3. Complete the information in the following form to confirm your payment.`,
            step3Note: `Keep in mind that the verification process of your payment can take up to 3 business days. If you do not register your payment, it will not be verified and you will not be able to access the educational service.`,
            form: {
              date: `Date of transfer`,
              bank: `Receiving bank`,
              reference: `Full reference code`,
              amount: `Amount`,
              button: `Submit payment`,
              referenceError: `The reference code is invalid.`,
              payer: `Full name of the payer`
            },
            modal: {
              title: `Your payment data has been received successfully`,
              description: ` <p>
Our administrative team must verify the payment before confirming
your purchase.
</p>
<p>
Varification can take up to 3 business days. you will receive
a notification in your email inbox.
</p>`,
              button: `Continue`
            }
          },
          creditCard: {
            title: `Credit or debit card`,
            description: `Enter your credit or debit card details.`,
            button: `Pay`,
            errors: {
              invalid_number: `The card number is invalid.`,
              invalid_expiry_year: `The expiration year of the card is invalid.`,
              invalid_expiry_year_past: `The expiration year of the card is in the past.`,
              invalid_expiry_month_past: `Card expiry date is in the past.`,
              invalid_expiry_month: `The expiration month of the card is invalid.`,
              incomplete_cvc: `Card security code is incomplete.`,
              incomplete_number: `The card number is incomplete.`,
              incomplete_expiry: `The expiration date of the card is incomplete.`,
              incomplete_zip: `Zip code is incomplete.`,
              incorrect_cvc: `The card security code is not correct.`,
              expired_card: `The card has expired.`,
              processing_error: `There was an error processing your card. Please wait a moment and try again.`,
              generic_decline: `Your card was declined.`,
              fraudulent: `Your card has been declined.`,
              insufficient_funds: `Your card has insufficient funds.`,
              lost_card: `Your card has been declined.`,
              stolen_card: `Your card has been declined.`,
              card_declined: `Your card has been declined.`,
              undefined: `Your card was declined.`,
              null: `Your card was declined.`
            },
            modal: {
              title: `Congratulations!`,
              description: ` <p>
Your payment has been made successfully.
</p>
<p>
Your invoice will be sent to your email inbox and you can also find it in the <strong>My payments</strong> section.
</p>`,
              button: `Continue`
            },
            modalError: {
              title: `Payment declined`,
              description: `<p>The payment was declined. Please check the information and try again.</p>`,
              button: `Continue`
            },
            modalPaying: {
              title: `Your payment is being processed`,
              description: `Please wait. Do not refresh or close this tab.`
            },
            modalFreePaying: {
              title: `Your enrollment is being processed`,
              description: `Please wait. Do not refresh or close this tab.`
            },
            modalFree: {
              title: `Congratulations!`,
              description: `You have successfully been enrolled for the free trial of the program <strong>{{programName}}</strong>. At any time during the <strong>{{freePeriodDays}}</strong> days that the free trial lasts, you can make the payment to continue your studies as a regular student of the program.`,
              button: `Start studying`
            }
          },
          paypal: {
            title: `PayPal Platform`,
            description: `You will be redirected to the PayPal website to complete your payment.`,
            modal: {
              title: `Congratulations!`,
              description: ` <p>
Your payment has been made successfully.
</p>
<p>
Your invoice will be sent to your email inbox and you can also find it in the <strong>My payments</strong> section.
</p>`,
              button: `Continue`
            }
          }
        }
      }
    },
    enrolment: {
      seo: {
        title: `Enrollment process`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      leavePage: `Are you sure you want to leave this page?`,
      steps: {
        1: {
          title: `Calendar`,
          description: `Select the academic calendar in which you wish to enroll`,
          format: `From {{start}} to {{end}}`
        },
        2: {
          title: `Academic level`,
          description: `Indicate the academic level in which you wish to enroll`,
          lastOption: `Not sure`,
          alertDocs: {
            title: `Enrollment documents`,
            p1: `To verify that the academic level chosen is correct and
to be able to formalize your enrollment in Dawere, deliver the
following documents:`,
            p2: `Once you enroll, you will have 15 days to
deliver these documents.`
          }
        },
        3: {
          title: `Checkout`,
          description: `Review your purchase and pay`,
          billing: {
            title: `Billing information`,
            cancel: `Cancel`,
            save: `Save billing information`,
            edit: `Edit billing information`,
            alertTitle: `Incomplete billing information`,
            alertDescription: `The billing information is necessary to complete your purchase.`
          },
          gateways: {
            title: `Choose a payment method`,
            creditCard: `Credit or debit card`,
            paypal: `Paypal Platform`,
            transfer: `Transfer`,
            cash: `Cash`
          },
          products: {
            title: `Products`,
            quantity: `Quantity: {{qty}}`
          },
          summary: {
            isFullPayment: `Full payment or in installments?`,
            fullPayment: `Full payment`,
            partialPayment: `Installments`,
            title: `Order Summary`,
            subtotal: `Sub-total: {{qty}} x {{price}}`,
            beforeTaxes: `Amount before taxes`,
            total: `TOTAL`,
            hasCoupon: `Do you have a discount coupon?`,
            placeholder: `Enter code here`,
            tooltip: `You agree to pay all installments, even if you do not complete the academic calendar. You must pay each installment before the indicated date to continue to have access to the educational content.`
          },
          transfer: {
            title: `Bank Transfer`,
            description: `Follow these three steps to complete your payment. All three steps are required to access the educational service.`,
            step1: `1. Choose the bank to which you want to make the transfer.`,
            step2: `2. Use the information below to make the bank transfer.`,
            step3: `3. Complete the information in the following form to confirm your payment.`,
            step3Note: `Keep in mind that the verification process of your payment can take up to 3 business days. If you do not register your payment, it will not be verified and you will not be able to access the educational service.`,
            form: {
              date: `Date of transfer`,
              bank: `Receiving bank`,
              reference: `Full reference code`,
              payer: `Full name of the payer`,
              amount: `Amount`,
              button: `Submit payment`,
              referenceError: `The reference code is invalid.`
            },
            modal: {
              title: `Your payment data has been received successfully`,
              description: ` <p>
Our administrative team must verify the payment before confirming
your purchase.
</p>
<p>
Verification can take up to 3 business days. you will receive
a notification in your email inbox.
</p>`,
              button: `Continue`
            }
          },
          creditCard: {
            title: `Credit or debit card`,
            description: `Enter your credit or debit card details.`,
            button: `Pay`,
            errors: {
              invalid_number: `The card number is invalid.`,
              invalid_expiry_year: `The expiration year of the card is invalid.`,
              invalid_expiry_year_past: `The expiration year of the card is in the past.`,
              invalid_expiry_month_past: `Card expiry date is in the past.`,
              invalid_expiry_month: `The expiration month of the card is invalid.`,
              incomplete_cvc: `Card security code is incomplete.`,
              incomplete_number: `The card number is incomplete.`,
              incomplete_expiry: `The expiration date of the card is incomplete.`,
              incomplete_zip: `Zip code is incomplete.`,
              incorrect_cvc: `The card security code is not correct.`,
              expired_card: `The card has expired.`,
              processing_error: `There was an error processing your card. Please wait a moment and try again.`,
              generic_decline: `Your card was declined.`,
              fraudulent: `Your card has been declined.`,
              insufficient_funds: `Your card has insufficient funds.`,
              lost_card: `Your card has been declined.`,
              stolen_card: `Your card has been declined.`,
              card_declined: `Your card has been declined.`,
              undefined: `Your card was declined.`,
              null: `Your card was declined.`
            },
            modal: {
              title: `Congratulations!`,
              description: ` <p>
Your payment has been made successfully.
</p>
<p>
Your invoice will be sent to your email inbox and you can also find it in the <strong>My payments</strong> section.
</p>`,
              button: `Continue`
            },
            modalPaying: {
              title: `Your payment is being processed`,
              description: `Please wait. Do not refresh or close this tab.`
            }
          },
          paypal: {
            title: `PayPal Platform`,
            description: `You will be redirected to the PayPal website to complete your payment.`,
            modal: {
              title: `Congratulations!`,
              description: ` <p>
Your payment has been made successfully.
</p>
<p>
Your invoice will be sent to your email inbox and you can also find it in the <strong>My payments</strong> section.
</p>`,
              button: `Continue`
            }
          }
        }
      }
    },
    pdfReceipt: {
      title: `RECEIPT`,
      docName: `Receipt{{code}}`,
      info: {
        title: `Dawere International Inc.`,
        address: `8554 NW 61st ST`,
        country: `Miami, FL 33166 US`,
        phoneNumber: `+1 4245277554`,
        email: `admin@dawere.com`,
        web: `www.dawere.com`
      },
      billingInfo: {
        title: `BILLED TO`,
        number: `RECEIPT Nº`,
        date: `PAYMENT DATE`
      },
      table: {
        columns: {
          lineNumber: `#`,
          product: `Product`,
          quantity: `Qty`,
          price: `Price`,
          total: `Subtotal`
        },
        subTotal: `SUB-TOTAL`,
        discounts: `DISCOUNTS`,
        earlyPayment: `EARLY BIRD PAYMENT`,
        vouchers: `VÁUCHERS`,
        tax: `TAX`,
        total: `TOTAL`,
        payment: `PAYMENT`,
        PAID: `PAID`,
        PENDING: `PENDING`,
        FAILED: `REJECTED`,
        VOID: `VOID`,
        VOIDED: `VOIDED`
      }
    },
    settings: {
      seo: {
        title: `Settings`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      title: `Settings`,
      tabs: {
        1: {
          title: `My account`,
          changePassword: {
            title: `CHANGE PASSWORD`,
            oldPassword: `Current password`,
            newPassword: `New password`,
            confirmPassword: `Confirm your new password`,
            submit: `Save changes`
          },
          profile: {
            title: `PROFILE`,
            sentence1: `I want to use Dawere as:`,
            student: `Student`,
            teacher: `Parent/Mentor`,
            organization: `Organization`,
            sentence2: `I want my default profile to be:`,
            submit: `Save changes`,
            STUDENT: `Student`,
            ORGANIZATION: `Organization`,
            GUARDIAN: `Parent/Mentor`,
            toast: `Changes saved.`
          },
          deleteAccount: {
            title: `Delete account`,
            shortDesc: `Once you delete your account, there is no going back. Please be certain.`,
            longDesc1: `Before deleting your account you should know that doing so will delete your <strong>email</strong>, <strong>phone</strong> and <strong>password</strong> of our servers. However, by requirements of the educational authorities of each country, the
              record and history of your academic record cannot be
              removed. This information is recorded in the schools and not.
              It can be modified or deleted.`,
            longDesc2: `Type the word <code>BORRAR</code> to confirm the action:`,
            toast: `Your account was successfully deleted.`
          }
        },
        2: {
          title: `Notifications`,
          description: `How do you want to receive relevant information?`,
          option1: `I agree to receive emails with promotions and news from Dawere.`,
          option2: `I agree to receive SMS text messages with promotions and news from
Dawere. <br />
Message and data usage rates may apply.`,
          submit: `Save preferences`,
          toast: `Your preferences were successfully updated.`
        },
        3: {
          title: `Billing information`,
          billing: {
            title: `BILLING INFORMATION`,
            description: `Invoices will be issued with the information you enter below.`,
            billingName: `Business name`,
            billingTin: `Tax registration number or identity`,
            billingCountry: `Country`,
            billingState: `State`,
            billingTownship: `Province`,
            billingAddress: `Address`,
            billingPostalCode: `Zip code`,
            submit: `Save`
          }
        }
      }
    },
    teacherDashboard: {
      seo: {
        title: `Teacher dashboard`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      breadcrumbs: {
        1: `My organizations`,
        2: `Teacher dashboard`
      },
      welcome: `Welcome to, {{name}}`,
      title: `Teacher dashboard`,
      description: `Here are your assigned subjects`,
      descriptionEmpty: `You have no subjects assigned yet. Contact your organization.`,
      buttons: {
        monitor: `Monitoring`,
        virtualClass: `Virtual classroom`,
        chat: `Chat`
      }
    },
    teacherMonitoring: {
      seo: {
        title: `Student Monitoring`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      breadcrumbs: {
        2: `Student Monitoring`
      },
      title: `Monitoring`,
      table: {
        count: `Student(s)`,
        columns: {
          name: `Student Name`,
          progress: `Progress`,
          score: `GPA`,
          evaluations: `Evaluations`,
          students: `Students`
        }
      },
      buttons: {
        virtualClass: `Virtual classroom`,
        chat: `Chat`
      }
    },
    formativeEvaluationGrades: {
      seo: {
        title: `Achieved progress: Formative assessment`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      breadcrumbs: {
        2: `Grades obtained `
      },
      title: `Grades obtained`,
      description: `This report shows your test results and a summary of your performance by competencies`,
      level: `Readiness level`,
      topic: `Topic`,
      cognitiveDemand: `Cognitive Demand`,
      button: `Keep practicing`,
      answers: `Answers {{points}}/{{qty}}`
    },
    pisaEvaluationGrades: {
      seo: {
        title: `Score obtained: Benchmark Assessment - PISA`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      breadcrumbs: {
        2: `Grades obtained`
      },
      title: `Grades obtained`,
      description: `This report shows your test results and a summary of your performance by competencies`,
      level: `Readiness level`,
      topic: `Topic`,
      cognitiveDemand: `Cognitive Demand`,
      button: `Keep practicing`,
      answers: `Answers {{points}}/{{qty}}`
    },
    grades: {
      seo: {
        title: `Score card`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      title: `Score card`,
      description: `Here are the grades obtained for each enrolled subject:`,
      download: `Download`,
      average: `Average`,
      position: `Position`,
      school: {
        title: `School’s information`,
        1: `School name`,
        2: `School code`,
        3: `Address`,
        4: `Educational circuit`,
        5: `Educational authority`
      },
      student: {
        title: `Student’s information`,
        1: `Names`,
        2: `Surname`,
        3: `ID Type`,
        4: `Identification number`
      },
      enrollment: {
        title: `Enrollment data`,
        1: `Educational program`,
        2: `Academic level`,
        3: `Calendar`,
        4: `Issuing Date`
      }
    }
  },
  sections: {
    navbar: {
      roles: {
        ORGANIZATION: `Organization`,
        STUDENT: `Student`
      },
      links: {
        catalog: `Educational programs`,
        help: `Help center`,
        home: `Home`,
        organizations: `For organizations`,
        organizationsDashboard: `Organizations’ dashboard`,
        parentDashboard: `Parent dashboard`,
        studentDashboard: `Student dashboard`,
        dashboardOf: `Dashboard of {{name}}`,
        profile: `Profile`,
        myDocuments: `My Documents`,
        myOrganizations: `My organizations`,
        settings: `Settings`,
        procedures: `Registrar`,
        payments: `Installments and payments`,
        permissions: `Permissions`,
        wallet: `Discount vouchers`,
        closeSession: `Log out`
      },
      buttons: {
        login: `Login`,
        register: `Register`
      }
    },
    footer: {
      column1: {
        title: `Dawere`,
        text: `Dawere International High School is registered in the Florida Department of Education under the school code 6139 in Miami-Dade County`,
        copyright: `© 2023 Dawere`
      },
      column2: {
        title: `Institution`,
        links: {
          1: `Educational Philosophy`,
          2: `Student and Parent Manual`,
          3: `Terms and Conditions`,
          4: `Privacy Policy`
        },
        url: {
          1: ``,
          2: `https://storage.googleapis.com/dawere-web-assets/pdf/PT-222-038%20Student%20Handbook%20Dawere%20USA.pdf`,
          3: `https://storage.googleapis.com/dawere-web-assets/pdf/TermsandConditionsUSA.pdf`,
          4: `https://storage.googleapis.com/dawere-web-assets/pdf/PoliticadetratamientodedatosEEUUV2.pdf`
        }
      },
      column3: {
        title: `Resources`,
        links: {
          1: `Help center`,
          2: `Scholarships`,
          3: `Blog`,
          4: `Countries`
        }
      },
      column4: {
        title: `Social Networks`,
        links: {
          1: `Contact us`,
          2: `Instagram`,
          3: `Facebook`,
          4: `X`,
          5: `YouTube`
        },
        url: {
          1: ``,
          2: `https://www.instagram.com/dawereoficial/`,
          3: `https://www.facebook.com/dawereoficial`,
          4: `https://twitter.com/dawereoficial`,
          5: `https://www.youtube.com/channel/UC4EJUs2GGy2Ja2Wu9Urkf_g`
        }
      }
    }
  },
  latex: {
    loading: 'Loading...'
  },
  tours: {
    finish: `Finish`,
    studentDahboard: {
      intro1: `<p class="fw-bold"> Welcome to the {{ programName }}
program!</p><p>Next, we&#39;ll take you on a little tour of the student dashboard.</ p>`,
      intro2: `Here you must upload <span class="fw-bold">your documents</span>, as
soon as possible, to complete your enrollment process.`,
      intro3: `In the <span section class="fw-bold">"Help"</span> section, you can
create tickets to request assistance on technical, administrative and educational
content issues.`,
      intro4: `Here are the subjects in which you are currently enrolled.`,
      intro5: `Click on any of the subjects to access its educational content.`,
      intro6: `Here you will receive important communications for your academic success. Check this inbox regularly.`
    },
    classroom: {
      intro1: `You should review each topic listed here. Click on a topic to access its
educational content.`,
      intro2: `Videos provide an engaging and dynamic way of learning your school topics. To watch a videoclass, just click on it.`,
      intro3: `Lectures provide written content about the topic. They reflect or complement the videoclass. To open a lecture, click on it.`,
      intro4: `Click on a <span class="fw-bold">formative assessment</span> to practice
what you have learned about the topic and prepare for your Benchmark assessments.`,
      intro5: `<p>When you feel ready, click here to take the summative assessment.</p>
<p>The results you obtain in these assessments will be considered for your final
grade.</p>`,
      intro6: `Click on the chat to interact with your teacher and classmates.`
    }
  },
  inviteFriends: {
    button: 'Invite a friend',
    title: 'Invite a friend',
    text1:
      "Invite your friends to enroll in one of Dawere's educational programs and earn discount vouchers.",
    text2:
      'For every friend who signs up for one of our programs thanks to your invitation link, you will earn a $20 discount voucher to use on your next enrollment. The person who creates an account with Dawere through your invitation link will receive a $10 discount voucher to use on their enrollment.',
    text3: 'Share the following invitation link with your friends:',
    successModal: {
      title: `You've earned a $10 discount value!`,
      text1: `By registering on Dawere through the <strong>{{name}}</strong> invitation link you have earned a $10 discount voucher. You can use this voucher as part of the payment for your next enrollment in any of our programs.`,
      text2: `You can also invite your friends and earn a $20 discount voucher for each friend who signs up for Dawere thanks to your invitation link. Go to the <strong>"Discount vouchers"</strong> section to see your balance and your own invitation link.`,
      button1: 'View Educational programs',
      button2: 'Go to Discount vouchers'
    }
  }
};

export { en };
