import { i18n } from 'dawere-commons';
import { Accordion, Block, Col, Row, Skeleton } from 'dawere-uic';
import { Subject } from '../components/Subject';
import { useProgram } from '../hooks/useProgram';

export function ContentSection({ isCourse = false, programId }: any) {
  const { periods, isLoading, priceSelected } = useProgram();

  if (!periods?.length && !isLoading) {
    return null;
  }

  return (
    <Block>
      <Col>
        <h2 className='mb-4'>
          {i18n('v2.pages.program.section4.title' + (isCourse ? '2' : '1'))}
        </h2>

        <div>
          {isLoading ? (
            <Skeleton count={4} height='2rem' className='mb-3' />
          ) : (
            periods?.map(({ id, name, subjects = [] }: any, index: number) => (
              <Accordion key={id} expanded={index === 0} summary={name}>
                <Row gutterWidth={16}>
                  {subjects.map((subject: any, index: number) => (
                    <Col key={index} xs={12} md={6} lg={4} className='mb-3'>
                      <Subject
                        isCourse={isCourse}
                        programId={programId}
                        currencyId={priceSelected?.currency?.id}
                        {...subject}
                      />
                    </Col>
                  ))}
                </Row>
              </Accordion>
            ))
          )}
        </div>
      </Col>
    </Block>
  );
}
