import { Block } from 'dawere-uic';
import { PageWrapper } from 'pages';
import { getSEO } from 'utils';
import { Heading } from './sections/Heading';
import { MainWrapper } from './sections/MainWrapper';

/**
 * Path: /detalles/estudiante/:studentId
 * Key: STUDENT_VIEW
 * @returns {React.ReactElement}
 */
export default function StudentViewPage() {
  return (
    <PageWrapper>
      {getSEO('v2.pages.studentDetails.seo', true)}

      <Block narrow>
        <Heading />

        <MainWrapper />
      </Block>
    </PageWrapper>
  );
}
