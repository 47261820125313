import { Rest } from 'dawere-commons';
import { Utils } from 'dawere-uic';

function list() {
  return Rest.get('public/programs');
}

function get(programId: string) {
  return Rest.get(`public/catalog/programs/${programId}`).then(
    ({ data }) => data
  );
}

function getPrices(programId: string) {
  return Rest.get(`public/catalog/programs/${programId}/prices`).then(
    ({ data }) => data
  );
}

function getCalendars(programId: string) {
  return Rest.get(`public/catalog/programs/${programId}/calendars`).then(
    ({ data }) => data
  );
}

export function getPeriods(programId: string) {
  return Rest.get(`public/catalog/programs/${programId}/periods`).then(
    ({ data }) => data
  );
}

function getDocuments(programId: string) {
  return Rest.get(`public/programs/${programId}/collections`, {
    params: { simple: true }
  });
}

function getCatalog(simple: boolean = true) {
  return Rest.get(`public/catalog${simple ? '?simple=' : ''}`).then(
    ({ data = [] }: any) => {
      // Create friendly paths
      data.map((item: any) => {
        if (Utils.isArray(item.subcategories)) {
          item.subcategories.forEach((sub: any, index: number) => {
            // substring from last appearance of / to end
            sub.friendlyPath = sub.url.substring(
              sub.url.lastIndexOf('/') + 1,
              sub.url.length
            );
          });
        }
        return item;
      });

      return {
        data,
        primary: data.filter((item: any) => item.style === 'PRIMARY'),
        secondary: data.filter((item: any) => item.style === 'SECONDARY'),
        tertiary: data.filter((item: any) => item.style === 'TERTIARY'),
        quaternary: data.filter((item: any) => item.style === 'QUATERNARY')
      };
    }
  );
}

function getReplacements(programId: string) {
  return Rest.get(`programs/${programId}/replacements`).then(
    ({ data }: any) => data
  );
}

export const Programs = {
  list,
  get,
  getPrices,
  getCalendars,
  getPeriods,
  getDocuments,
  getCatalog,
  getReplacements
};
