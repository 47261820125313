import { DEFAULT_LANGUAGE, Locales } from 'dawere-commons';
import {
  COUNTRIES_ISO2,
  COUNTRIES_REGEXP,
  getFullCountryInfo
} from './countries';

export function languageSetup() {
  // Case: COUNTRY.dawere.com
  const regexCountry1 = new RegExp(`^${COUNTRIES_REGEXP}.dawere.+$`, 'gi').exec(
    window.location.host
  );
  // Case: dawere.com/COUNTRY
  const regexCountry2 = new RegExp(
    `^dawere.+/${COUNTRIES_REGEXP}/?.*$`,
    'gi'
  ).exec(window.location.host);
  // Case: dawere.com.COUNTRY
  const regexCountry3 = new RegExp(
    `^dawere.+.${COUNTRIES_REGEXP}/?.*$`,
    'gi'
  ).exec(window.location.host);
  // Case: ***/COUNTRY/...
  const regexCountry4 = new RegExp(`/${COUNTRIES_REGEXP}(/.*)?$`, 'gi').exec(
    window.location.href
  );

  const urlCountry1 = (
    regexCountry1?.length ? regexCountry1[1] : ''
  ).toLowerCase();
  const urlCountry2 = (
    regexCountry2?.length ? regexCountry2[1] : ''
  ).toLowerCase();
  const urlCountry3 = (
    regexCountry3?.length ? regexCountry3[1] : ''
  ).toLowerCase();
  const urlCountry4 = (regexCountry4?.length ? regexCountry4[1] : '')
    .toLowerCase()
    .replaceAll('/', '');

  if (urlCountry1.length && COUNTRIES_ISO2.includes(urlCountry1)) {
    console.log('Found XX.dawere.com pattern');
    Locales.setCountry(urlCountry1);
  } else if (urlCountry2.length && COUNTRIES_ISO2.includes(urlCountry2)) {
    console.log('Found dawere.com/XX pattern');
    Locales.setCountry(urlCountry2);
  } else if (urlCountry3.length && COUNTRIES_ISO2.includes(urlCountry3)) {
    console.log('Found dawere.com.XX pattern');
    Locales.setCountry(urlCountry3);
  } else if (urlCountry4.length && COUNTRIES_ISO2.includes(urlCountry4)) {
    console.log('Using country from href');
    Locales.setCountry(urlCountry4);
  } else {
    console.log('Using country from storage');
  }

  // Check url language
  // Case whatever.com?language=XX
  const queryString = (
    new URLSearchParams(window.location.search).get('l') ||
    new URLSearchParams(window.location.search).get('lang') ||
    new URLSearchParams(window.location.search).get('language') ||
    new URLSearchParams(window.location.search).get('lan') ||
    ''
  ).toLowerCase();

  if (['es', 'en'].includes(queryString)) {
    console.log('Using QueryString to set Language');
    Locales.setLanguage(queryString);
  }

  // Reset language in case user navigates from URL
  // Example: en-US -> CO, results es-CO instead of en-CO
  resetLanguage();

  console.log(`Language setup: ${Locales.getLocale()}`);
}

function resetLanguage() {
  const country = getFullCountryInfo();

  if (!country?.multilanguage && Locales.getLanguage() !== DEFAULT_LANGUAGE) {
    Locales.setLanguage(DEFAULT_LANGUAGE);
  }
}
