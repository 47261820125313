import { i18n } from 'dawere-commons';
import {
  Col,
  Skeleton,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  useMatchMedia
} from 'dawere-uic';
import { useOrganization } from 'hooks';
import { useCallback } from 'react';
import { NumberParam, useQueryParams } from 'use-query-params';
import { QPARAMS } from 'values';
import { BillingTab } from './BillingTab';
import { CouponsTab } from './CouponsTab';
import { PaymentsTab } from './PaymentsTab';
import { ReportsTab } from './ReportsTab';
import StudentsTab from './StudentsTab';
import { WalletTab } from './WalletTab';
import { ChartTab } from './organizationChart/ChartTab';

export function TabsSection() {
  const { isMobile } = useMatchMedia();
  const { isLoading } = useOrganization();

  const [query, setQuery] = useQueryParams({
    [QPARAMS.Tab]: NumberParam
  });

  const handleTabSelection = useCallback(
    (value: number) => {
      setQuery({ [QPARAMS.Tab]: value }, 'replace');
    },
    [setQuery]
  );

  return isLoading ? (
    <Col>
      <Skeleton.Title />
      <Skeleton.Table />
    </Col>
  ) : (
    <Col xs={12}>
      <Tabs
        onSelect={handleTabSelection}
        selectedIndex={query[QPARAMS.Tab] || 0}>
        <TabList
          type='pills'
          justify={isMobile ? 'true' : undefined}
          scrollable='true'
          className='mb-4'>
          <Tab>{i18n('v2.pages.organizationDashboard.tabs.1.title')}</Tab>
          <Tab>{i18n('v2.pages.organizationDashboard.tabs.2.title')}</Tab>
          <Tab>{i18n('v2.pages.organizationDashboard.tabs.3.title')}</Tab>
          <Tab>{i18n('v2.pages.organizationDashboard.tabs.5.title')}</Tab>
          <Tab>{i18n('v2.pages.organizationDashboard.tabs.4.title')}</Tab>
          <Tab>{i18n('v2.pages.studentDashboard.tabs.4.tabs.3.title')}</Tab>
          <Tab>{i18n('v2.pages.organizationDashboard.tabs.6.title')}</Tab>
        </TabList>

        {/* Tab de cupones */}
        <TabPanel>
          <CouponsTab />
        </TabPanel>

        {/* Tab de estudiantes */}
        <TabPanel>
          <StudentsTab />
        </TabPanel>

        {/* Tab para reportes */}
        <TabPanel>
          <ReportsTab />
        </TabPanel>

        {/* Tab de organigrama del plantel */}
        <TabPanel>
          <ChartTab />
        </TabPanel>

        {/* Tabs para pagos */}
        <TabPanel>
          <PaymentsTab />
        </TabPanel>

        {/* Tabs para facturacion */}
        <TabPanel>
          <BillingTab />
        </TabPanel>

        {/* Tabs para monedero */}
        <TabPanel>
          <WalletTab />
        </TabPanel>
      </Tabs>
    </Col>
  );
}
