import { Rest } from 'dawere-commons';

function getStatus(status: string) {
  const statusMap: any = {
    pending: 'none',
    approved: 'approved',
    rejected: 'rejected',
    submitted: 'pending'
  };

  return statusMap[status.toLowerCase()];
}

function upload(documentId: string, payload: any = {}) {
  return Rest.post(`me/collections/${documentId}`, payload);
}

export const Documents = {
  getStatus,
  upload
};
