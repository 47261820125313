import { i18n } from 'dawere-commons';
import { Block, Col } from 'dawere-uic';
import { ASSETS } from 'values';

export function BenefitsSection() {
  return (
    <Block>
      <Col className='text-center' xs={12}>
        <h2 className='mb-6'>{i18n('v2.pages.home.section3.title')}</h2>
      </Col>

      <Col xs={6} lg={3} className='text-center mb-7 mb-lg-0'>
        <img
          src={ASSETS.ICONS.SHIELD}
          alt={i18n('v2.pages.home.section3.benefits.1.description')}
          width={80}
          className='img-fluid mb-4'
        />
        <h3 className='mb-3 text-primary'>
          {i18n('v2.pages.home.section3.benefits.1.title')}
        </h3>
        <h4 className='mb-0'>
          {i18n('v2.pages.home.section3.benefits.1.description')}
        </h4>
      </Col>

      <Col xs={6} lg={3} className='text-center mb-7 mb-lg-0'>
        <img
          src={ASSETS.ICONS.CERTIFICATE}
          alt={i18n('v2.pages.home.section3.benefits.2.description')}
          width={80}
          className='img-fluid mb-4'
        />
        <h3 className='mb-3 text-primary'>
          {i18n('v2.pages.home.section3.benefits.2.title')}
        </h3>
        <h4 className='mb-0'>
          {i18n('v2.pages.home.section3.benefits.2.description')}
        </h4>
      </Col>

      <Col xs={6} lg={3} className='text-center'>
        <img
          src={ASSETS.ICONS.CLOCK}
          alt={i18n('v2.pages.home.section3.benefits.3.description')}
          width={80}
          className='img-fluid mb-4'
        />
        <h3 className='mb-3 text-primary'>
          {i18n('v2.pages.home.section3.benefits.3.title')}
        </h3>
        <h4 className='mb-0'>
          {i18n('v2.pages.home.section3.benefits.3.description')}
        </h4>
      </Col>

      <Col xs={6} lg={3} className='text-center'>
        <img
          src={ASSETS.ICONS.QUALITY}
          alt={i18n('v2.pages.home.section3.benefits.4.description')}
          width={80}
          className='img-fluid mb-4'
        />
        <h3 className='mb-3 text-primary'>
          {i18n('v2.pages.home.section3.benefits.4.title')}
        </h3>
        <h4 className='mb-0'>
          {i18n('v2.pages.home.section3.benefits.4.description')}
        </h4>
      </Col>
    </Block>
  );
}
