import { Rest } from 'dawere-commons';
import { Utils } from 'dawere-uic';

function _processMembersResponse(
  response: any,
  status: 'ACTIVE' | 'PENDING' = 'ACTIVE'
) {
  let data = [];

  if (status === 'ACTIVE') {
    data = response.data.content.map((el: any) => {
      const { user } = el;
      const fullName = Utils.getFullname(user.name, user.lastName);
      const phoneNumber = Utils.joinStrings(user.phoneCode, user.phoneNumber);

      return {
        id: user.id,
        name: fullName,
        email: user.email,
        avatar: user.picturePath,
        phoneNumber,
        role: el.role
      };
    });
  }

  if (status === 'PENDING') {
    data = response.data.content.map((el: any) => {
      return {
        id: el.id,
        email: el.email,
        date: Utils.formatDate(el.invitationDate, 'dd/mmm/yyyy')
      };
    });
  }

  return {
    data: data,
    totalPages: response.data.totalPages,
    totalRows: response.data.totalElements
  };
}

function invite(organizationId: string, data: any = {}) {
  return Rest.post(
    `my-organizations/${organizationId}/member-invitations`,
    data
  );
}

function list(organizationId: string, params: any = {}) {
  return Rest.get(`my-organizations/${organizationId}/members`, {
    params
  }).then((response: any) => {
    return Utils.has(params, 'simple')
      ? response.data
      : _processMembersResponse(response, 'ACTIVE');
  });
}

function remove(organizationId: string, memberId: string) {
  return Rest.delete(`my-organizations/${organizationId}/members/${memberId}`);
}

function getPending(organizationId: string, params: any = {}) {
  return Rest.get(`my-organizations/${organizationId}/member-invitations`, {
    params
  }).then((response: any) => {
    return Utils.has(params, 'simple')
      ? response.data
      : _processMembersResponse(response, 'PENDING');
  });
}

export const Members = {
  invite,
  list,
  remove,
  getPending
};
