import { Facebook, Instagram, X, YouTube } from '@mui/icons-material';
import { API } from 'api';
import { i18n, Locales } from 'dawere-commons';
import {
  Button,
  Col,
  Dawere,
  Flex,
  IBaseProps,
  Logo,
  Visible
} from 'dawere-uic';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { COUNTRIES_LIST, getFullCountryInfo, updateCountryInUrl } from 'utils';

export function Footer(props: IBaseProps) {
  const handleClick = useCallback((iso2: string) => {
    Locales.setCountry(iso2);
    API.Account.updateLocale().finally(() => updateCountryInUrl());
  }, []);

  return (
    <>
      <Dawere.Footer
        bottomSection={
          <Col className='text-md-center'>
            <Visible md lg xl>
              <div className='d-inline-block mb-4'>
                <Logo negative variant='FULL' size={200} />
              </div>
            </Visible>

            <Flex
              align='center'
              justify='center'
              gap={3}
              className='flex-column flex-md-row'>
              {COUNTRIES_LIST.map(({ nameFrom, iso2 }: any) => (
                <Button
                  key={iso2}
                  variant='link'
                  onClick={() => handleClick(iso2)}
                  className='text-white'>
                  {i18n(nameFrom)}
                </Button>
              ))}
            </Flex>
          </Col>
        }
        {...props}>
        <Col className='mb-6 mb-lg-0' md={6} lg={3}>
          <h5 className='text-white mb-4'>
            {i18n('v2.sections.footer.column1.title')}{' '}
            {getFullCountryInfo().iso3 === 'USA'
              ? ''
              : getFullCountryInfo().name}
          </h5>
          <p
            dangerouslySetInnerHTML={{
              __html: i18n('v2.sections.footer.column1.text')
            }}
          />
          <small>© {new Date().getFullYear()} Dawere</small>
        </Col>

        <Col className='mb-6 mb-lg-0' md={6} lg={3}>
          <h5 className='text-white mb-4'>
            {i18n('v2.sections.footer.column2.title')}
          </h5>
          <ul>
            <li>
              <Link to={PATHS.PHILOSOPHY}>
                {i18n('v2.sections.footer.column2.links.1')}
              </Link>
            </li>
            <li>
              <a
                href={i18n('v2.sections.footer.column2.urls.2')}
                target='_blank'
                rel='noreferrer'>
                {i18n('v2.sections.footer.column2.links.2')}
              </a>
            </li>
            <li>
              <Link to={PATHS.TERMS_AND_CONDITIONS}>
                {i18n('v2.sections.footer.column2.links.3')}
              </Link>
            </li>
            <li>
              <Link to={PATHS.PRIVACY_POLICY}>
                {i18n('v2.sections.footer.column2.links.4')}
              </Link>
            </li>
          </ul>
        </Col>

        <Col className='mb-6 mb-md-0' md={6} lg={3}>
          <h5 className='text-white mb-4'>
            {i18n('v2.sections.footer.column3.title')}
          </h5>
          <ul>
            <li>
              <span
                className='anchor cursor-pointer text-white'
                onClick={() => API.Zendesk.go()}>
                {i18n('v2.sections.footer.column3.links.1')}
              </span>
            </li>
            {/* <li>{i18n('v2.sections.footer.column3.links.2')}</li>
            <li>{i18n('v2.sections.footer.column3.links.3')}</li> */}
          </ul>
        </Col>

        <Col md={6} lg={3}>
          <h5 className='text-white mb-4'>
            {i18n('v2.sections.footer.column4.title')}
          </h5>
          <ul>
            <li>
              <a
                href={i18n('v2.sections.footer.column4.urls.2')}
                target='_blank'
                rel='noreferrer'>
                <Instagram className='mr-2' fontSize='small' />
                {i18n('v2.sections.footer.column4.links.2')}
              </a>
            </li>
            <li>
              <a
                href={i18n('v2.sections.footer.column4.urls.3')}
                target='_blank'
                rel='noreferrer'>
                <Facebook className='mr-2' fontSize='small' />
                {i18n('v2.sections.footer.column4.links.3')}
              </a>
            </li>
            <li>
              <a
                href={i18n('v2.sections.footer.column4.urls.4')}
                target='_blank'
                rel='noreferrer'>
                <X className='mr-2' fontSize='small' />
                {i18n('v2.sections.footer.column4.links.4')}
              </a>
            </li>
            <li>
              <a
                href={i18n('v2.sections.footer.column4.urls.5')}
                target='_blank'
                rel='noreferrer'>
                <YouTube className='mr-2' fontSize='small' />
                {i18n('v2.sections.footer.column4.links.5')}
              </a>
            </li>
          </ul>
        </Col>
      </Dawere.Footer>
    </>
  );
}
