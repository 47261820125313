import { API } from 'api';
import {
  AutoComplete,
  AvatarChip,
  Button,
  Callout,
  Form,
  Modal,
  Utils
} from 'dawere-uic';
import { useCallback, useEffect, useState } from 'react';

const AutoCompleteOption = ({ children, data, ...rest }: any) => {
  return (
    <AutoComplete.Option {...rest}>
      {data?.__isNew__ ? (
        <>{children}</>
      ) : (
        <AvatarChip
          size={40}
          src={data.avatar}
          icon={data.initials || Utils.getInitialLetters(data.name)}
          gap={2}>
          <strong className='d-block'>{data.name}</strong>
          <small className='d-block text-muted'>{data.email}</small>
        </AvatarChip>
      )}
    </AutoComplete.Option>
  );
};

export function ModalAssignCoupon({
  organizationId,
  coupon,
  open,
  onClose,
  onSuccess = (email: string) => {},
  title = '',
  description = '',
  details = [],
  placeholder = '',
  button = '',
  question
}: any) {
  const [students, setStudents] = useState<any[]>([]);
  const [email, setEmail] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (open) {
      setIsLoading(true);

      API.Organizations.getStudents(organizationId, { size: 9999 })
        .then(({ data }: any) => {
          setStudents(data);
        })
        .finally(() => setIsLoading(false));
    }
  }, [open, organizationId]);

  const handleSubmit = useCallback(
    (data: any) => {
      setIsSaving(true);

      API.Coupons.assign(organizationId, {
        email: data.email.value,
        voucher: { id: coupon?.id }
      })
        .then((response) => {
          setEmail(null);
          onClose(false);
          onSuccess(data.email.value);
        })
        .finally(() => setIsSaving(false));
    },
    [coupon?.id, onClose, onSuccess, organizationId]
  );

  const handleChange = useCallback((data: any) => {
    setEmail(data);
  }, []);

  const handleCreate = useCallback((data: any) => {
    const newEmail = data.toLowerCase();
    const newEntry = {
      id: newEmail,
      name: newEmail,
      email: newEmail
    };

    setStudents((state: any[]) => [...state, newEntry]);

    setEmail({
      label: newEmail,
      value: newEmail,
      ...newEntry
    });
  }, []);

  return (
    <Modal
      open={open}
      onClose={onClose}
      closable={isSaving ? false : true}
      keyboard={isSaving ? false : true}
      type='info'
      title={title}
      backdrop='static'>
      <p
        dangerouslySetInnerHTML={{
          __html: description
        }}
      />

      <Callout variant='powderTint' className='mb-4'>
        {details.map((item: any, index: number) => (
          <p key={index} className='text-break'>
            <strong className='d-block'>{item.label}</strong>
            {item.value}
          </p>
        ))}
      </Callout>

      <Form onSubmit={handleSubmit}>
        <AutoComplete
          name='email'
          customOption={AutoCompleteOption}
          placeholder={placeholder}
          disabled={isSaving}
          creatable
          options={students.map((item: any) => ({
            value: item.email,
            label: item.name,
            ...item
          }))}
          onIsNewValue={(inputValue: string, options: any[]) => {
            const found = options
              .map((item: any) => item.email)
              .find((item: string) =>
                item.toLowerCase().includes(inputValue.toLowerCase())
              );

            return Utils.isEmail(inputValue) && !found;
          }}
          value={email}
          onChange={handleChange}
          onCreate={handleCreate}
          menuPlacement='top'
          maxMenuHeight={180}
          loading={isLoading}
        />

        <Button
          type='submit'
          block
          loading={isSaving}
          disabled={isLoading || !Utils.isEmail(email?.value)}>
          {button}
        </Button>
      </Form>

      <Callout variant='white' className='text-center pb-0 fs-small'>
        {question}
      </Callout>
    </Modal>
  );
}
