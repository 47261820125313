import { API } from 'api';
import { i18n } from 'dawere-commons';
import { Button, Checkbox, Form, toast } from 'dawere-uic';
import { useCallback, useEffect, useState } from 'react';

export function NotificationsSection() {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [data, setData] = useState<any>(null);

  const handleSubmit = useCallback((data: any) => {
    setIsSaving(true);

    API.Account.updateOptOut(data)
      .then(() => toast.success(i18n('v2.pages.settings.tabs.2.toast')))
      .finally(() => setIsSaving(false));
  }, []);

  useEffect(() => {
    setIsLoading(true);

    API.Account.getOptOut()
      .then((data: any) => setData(data))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <>
      <p>{i18n('v2.pages.settings.tabs.2.description')}</p>

      <Form onSubmit={handleSubmit}>
        <Checkbox
          name='mailSuppressed'
          label={i18n('v2.pages.settings.tabs.2.option1')}
          className='mb-3'
          value={data?.mailSuppressed}
          loading={isSaving || isLoading}
        />
        <Checkbox
          name='smsSuppressed'
          label={
            <span
              dangerouslySetInnerHTML={{
                __html: i18n('v2.pages.settings.tabs.2.option2')
              }}
            />
          }
          className='mb-4'
          value={data?.smsSuppressed}
          loading={isSaving || isLoading}
        />

        <Button type='submit' disabled={isLoading} loading={isSaving}>
          {i18n('v2.pages.settings.tabs.2.submit')}
        </Button>
      </Form>
    </>
  );
}
