import TagManager from 'react-gtm-module';

export function trackGTMEvent(event: string, data: any = {}) {
  TagManager.dataLayer({
    dataLayer: {
      event,
      ...data
    }
  });
}
