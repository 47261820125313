import { i18n } from 'dawere-commons';
import {
  Avatar,
  Button,
  Empty,
  Flex,
  Popover,
  Search,
  Skeleton,
  useMatchMedia,
  Utils
} from 'dawere-uic';
import { useOrganization } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import styled from 'styled-components';
import { useOrganizationChart } from './hooks/useOrganizationChart';

const GridStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 0.15em;

  > span {
    border: solid 2px var(--dw-color-cultured);

    &.active {
      border-color: var(--dw-color-secondary);
    }
  }
`;

const MAX_VISIBLE = 5;

export function TeachersAvatars({
  teacherIds = [],
  onSelectTeacher = () => {}
}: any) {
  const { organization } = useOrganization();
  const { isDesktop } = useMatchMedia();
  const { teachers, isLoadingTeachers } = useOrganizationChart();
  const [filteredMembers, setFilteredMembers] = useState<any[]>(teachers);

  const restTotal = teachers.length - MAX_VISIBLE;
  const firstAvatars = teachers.slice(0, MAX_VISIBLE);

  const handleSearch = useCallback(
    (data: any) => {
      const { search } = data;
      let sliced = teachers;

      if (search) {
        const filtered = sliced.filter(({ user }: any) =>
          Utils.getFullname(user.name, user.lastName)
            .toLowerCase()
            .includes(search.toLowerCase())
        );

        setFilteredMembers(filtered);
      } else {
        setFilteredMembers(sliced);
      }
    },
    [teachers]
  );

  const handleSelectTeacher = useCallback(
    (id: string) => {
      onSelectTeacher((state: any[]) => {
        if (state.includes(id)) {
          return state.filter((item: any) => item !== id);
        } else {
          return [...state, id];
        }
      });
    },
    [onSelectTeacher]
  );

  useEffect(() => {
    setFilteredMembers(teachers);
  }, [teachers]);

  return (
    <Flex direction='column' className='mx-lg-3 mb-3 mb-lg-0'>
      <small className='text-muted'>
        {i18n('v2.pages.organizationDashboard.tabs.5.avatars.teachers')}
      </small>

      {isLoadingTeachers ? (
        <Flex>
          {Array(5)
            .fill(0)
            .map((_, index) => (
              <div key={index} className='mr-1'>
                <Skeleton
                  height={isDesktop ? 33 : 47}
                  width={isDesktop ? 33 : 47}
                  circle
                />
              </div>
            ))}
        </Flex>
      ) : (
        <Flex justify={isDesktop ? 'start' : 'between'}>
          {firstAvatars.length ? (
            <Avatar.Group className='ml-2 mr-1'>
              {firstAvatars.map((teacher) => (
                <Avatar
                  key={teacher.id}
                  size={isDesktop ? 34 : 48}
                  src={teacher.user?.picturePath}
                  icon={Utils.getInitialLetters(
                    teacher.user?.name,
                    teacher.user?.lastName
                  )}
                  tooltip={Utils.getFullname(
                    teacher.user?.name,
                    teacher.user?.lastName
                  )}
                  active={teacherIds.includes(teacher.user?.id)}
                  onClick={() => handleSelectTeacher(teacher.user?.id)}
                />
              ))}

              {teachers.length > MAX_VISIBLE ? (
                <Popover
                  placement='right'
                  content={
                    <>
                      <Search
                        name='search'
                        onSubmit={handleSearch}
                        allowEmptySearch
                      />
                      {filteredMembers.length === 0 ? (
                        <Empty size='xsmall' />
                      ) : (
                        <GridStyled>
                          {filteredMembers.map((teacher) => (
                            <Avatar
                              size={40}
                              inPopover
                              key={teacher.id}
                              src={teacher.user?.picturePath}
                              icon={Utils.getInitialLetters(
                                teacher.user?.name,
                                teacher.user?.lastName
                              )}
                              tooltip={Utils.getFullname(
                                teacher.user?.name,
                                teacher.user?.lastName
                              )}
                              active={teacherIds.includes(teacher.user?.id)}
                              onClick={() =>
                                handleSelectTeacher(teacher.user?.id)
                              }
                            />
                          ))}
                        </GridStyled>
                      )}
                    </>
                  }>
                  <Button circle size={isDesktop ? 34 : 48}>
                    {restTotal < 100 ? `+${restTotal}` : '...'}
                  </Button>
                </Popover>
              ) : null}
            </Avatar.Group>
          ) : null}

          <Button
            variant='link'
            style={{ height: '34px' }}
            as={Link}
            to={PATHS.get('TEACHERS_LIST', {
              organizationId: organization?.id
            })}>
            {i18n('v2.pages.organizationDashboard.tabs.5.avatars.more')}
          </Button>
        </Flex>
      )}
    </Flex>
  );
}
