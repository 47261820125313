const esVE = {
  lang: `es-VE,es`,
  shortLang: 'es-VE',
  pages: {
    home: {
      seo: {
        title: `Dawere Venezuela | Colegio Virtual de Calidad`,
        description: `Educación prémium, asequible y avalada por el Ministerio del Poder Popular para la Educación de Venezuela. Continúa tus estudios con la mejor educación virtual de América Latina.`,
        keywords: `colegio, escuela`,
        ogTitle: `Dawere Venezuela | Colegio Virtual de Calidad`,
        ogDescription: `Educación prémium, asequible y avalada por el Ministerio del Poder Popular para la Educación de Venezuela. Continúa tus estudios con la mejor educación virtual de América Latina. `,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/seo_homepage.jpg`
      },
      mainProgramId: {
        dev: `bachillerato-online-venezuela`,
        qa: `bachillerato-online-venezuela`,
        prod: `bachillerato-online-venezuela`
      },
      doubleDegreeProgramId: {
        dev: `bachillerato-virtual-estados-unidos`,
        qa: `bachillerato-virtual-estados-unidos`,
        prod: `bachillerato-virtual-estados-unidos`
      },
      info: {
        whatsappNumber: `+58 412-2318073`,
        phoneNumber: `+58 (212) 232-6398`,
        emailInfo: ``
      },
      section1: {
        subtitle: `Colegio: Dawere Venezuela`
      },
      section2: {
        subtitle: `Educación acreditada y de calidad`,
        title: `El título de bachiller es avalado por el MPPE`,
        images: {
          1: {
            url: `https://storage.googleapis.com/dawere-web-assets/logotipos/logo_ministeriopoder.svg`,
            alt: `MPPE`
          }
        },
        text: `
          <p>Obtén tu título de bachiller avalado por el Ministerio del Poder Popular para la Educación de Venezuela. </p>

          <p>Nuestra institución, UEPA Dawere, está registrada ante la Zona Educativa de Miranda bajo el código de plantel PN-18181519, RIF J-406694215 y permiso de funcionamiento número 05-698-20.  </p>
        `
      },
      section6: {
        steps: {
          1: {
            text: ` Si tienes 15 años de edad o más puedes inscribirte en Dawere sin importar tu ubicación geográfica. Solo debes llenar el formulario de inscripción, señalar los años aprobados hasta la fecha y realizar tu pago.`
          },
          2: {
            title: `Entrega tus requisitos`,
            text: `Debes entregar copia de tu cédula de identidad y copia de la partida de nacimiento, entre otros recaudos.`
          },
          3: {
            text: `En nuestro colegio virtual aprendes con videoclases pregrabadas, guías PDF, evaluaciones de práctica, un foro-chat para aclarar dudas y más. Estudia a tu ritmo, en tu horario y desde cualquier lugar con acceso a internet. Dawere es fácil de navegar desde una computadora, tablet o teléfono inteligente. `
          },
          4: {
            text: `Al aprobar todas las materias de rigor y asistir a tu ceremonia de graduación virtual, podrás retirar tu título en nuestras oficinas o solicitar que se te envíe hasta la puerta de tu casa.`
          }
        }
      },
      section9: {
        categories: {
          1: {
            text: `Como padre podrás monitorear el avance académico de tus hijos e identificar con prontitud sus oportunidades de mejora. También recibirás avisos sobre sus calificaciones, su rendimiento escolar en cada materia hasta la fecha, los exámenes por presentar y más. Regístrate en nuestro colegio virtual para recibir más información sobre los beneficios de nuestra modalidad de estudio a distancia.  `
          },
          3: {
            text: `Estudia en un colegio virtual de calidad y obtén tu título de bachiller avalado por el MPPE. Estudiarás de forma rápida y divertida a través de videoclases pregrabadas, material de apoyo, evaluaciones de práctica, un foro-chat para aclarar tus dudas y más. Regístrate para recibir más información.`
          }
        }
      }
    },
    catalog: {
      seo: {
        title: `Programas Educativos de Venezuela | Avalados por el MPPE`,
        description: `Explora el catálogo de programas educativos y descubre las bondades de una educación virtual que se ajusta a tu ritmo y horario. Ofrecemos becas a quienes califiquen. `,
        keywords: `MPPE, Ministerio de Educacion, Educacion en Venezuela`,
        ogTitle: `Programas Educativos de Venezuela | Avalados por el MPPE`,
        ogDescription: `Explora el catálogo de programas educativos y descubre las bondades de una educación virtual que se ajusta a tu ritmo y horario. Ofrecemos becas a quienes califiquen.  `,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/seo_logo_mppe_venezuela.jpg`
      }
    },
    organizations: {
      seo: {
        title: `Dawere Venezuela | Para Organizaciones`,
        keywords: `organizaciones, fundaciones, sistema educativo`,
        ogTitle: `Dawere Venezuela para Organizaciones`
      },
      section2: {
        subtitle: `Descubre por qué fundaciones, empresas y gobiernos eligen el Bachillerato Online de Dawere para la formación de sus ciudadanos o beneficiarios.`
      },
      section4: {
        governments: {
          text: `
<p>
              Según la UNESCO, en los sistemas educativos tradicionales de América Latina, solo 45% de los que inician el bachillerato se gradúan.
              </p>
            <p>
              Hay una deserción del 15% de los estudiantes todos los años y más del 30% de los jóvenes en edad escolar están fuera del sistema educativo tradicional. Con el uso de nuestra tecnología educativa, esto puede cambiar.
              </p>

            <p>
              Te invitamos a proveerle a todos tus ciudadanos educación de calidad gratuita. Parece una propuesta utópica pero se puede convertir en realidad gracias a los servicios de educación a distancia de Dawere. Nuestro Bachillerato Online ha sido diseñado para educar a millones de personas a una fracción del costo de la educación tradicional presencial. Compruébalo por ti mismo y empodera al país con la excelente educación que todos merecen.
              </p>
          `
        },
        companies: {
          text: `
           <p>
              El mundo del deporte profesional es extremadamente reñido, por lo que solo un porcentaje minúsculo logra convertirse en jugador profesional. ¿Qué ocurre con el resto?

              </p>
            <p>
              Recibir educación de calidad en paralelo a los entrenamientos puede garantizarle un futuro a todos tus jugadores sin excepción.

              </p>
            <p>
              Nuestro Bachillerato Online se adapta al ritmo y horario de los estudiantes. Con Dawere tus jugadores pueden formarse académicamente y perseguir sus sueños.
              </p>
          `
        }
      }
    },
    shared: {
      contact: {
        info: {
          phone: {
            title: `TELÉFONO`,
            text: `+58 (212) 232-6398`
          },
          whatsapp: {
            title: `WHATSAPP`,
            text: `+58 412-2318073`
          },
          email: {
            title: `EMAIL`,
            text: ``
          }
        },
        buttons: {
          faq: `Preguntas frecuentes`
        }
      }
    },
    helpCenter: {
      seo: {
        keywords: `dawere, dawere venezuela`
      },
      urls: {
        scholarshipForm: `https://forms.gle/zMjs6DX4cutsWFXG8`
      },
      contact: {
        info: {
          enrolled: {
            whatsapp: `+58 4122318072`
          },
          unregistered: {
            phone: `+58 (212) 232-6398`,
            whatsapp: `+58 412-2318073`
          }
        }
      }
    },
    philosophy: {
      seo: {
        keywords: `Educacion, modelo educativo, educacion de calidad, calidad educativa, enseñanza virtual`
      }
    }
  },
  sections: {
    footer: {
      column1: {
        text: `<p>UEPA Dawere - Registrados en el Ministerio del Poder Popular para la Educación de Venezuela bajo el Permiso de Funcionamiento Nº 05-698-20 como una institución de educación para adultos con el siguiente Código del plantel: PN-18181519. </p>

        <p>RIF: J-406694215 </p>`
      }
    }
  }
};

export default esVE;

export { esVE };
