import { Rest } from 'dawere-commons';

function get(programId: string, subjectId: string) {
  return Rest.get(`public/catalog/programs/${programId}/subjects/${subjectId}`);
}

function getTopics(subjectId: string) {
  return Rest.get(`/public/catalog/subjects/${subjectId}/topics`);
}

export const Subjects = {
  get,
  getTopics
};
