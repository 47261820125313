import { i18n } from 'dawere-commons';
import { Block, Col } from 'dawere-uic';

export function CompaniesAndGovermentsSection() {
  return (
    <Block>
      <Col md={6} className='mb-4 mb-md-0'>
        <h5 className='text-primary text-uppercase'>
          {i18n('v2.pages.organizations.section4.governments.subtitle')}
        </h5>
        <h2 className='mb-4'>
          {i18n('v2.pages.organizations.section4.governments.title')}
        </h2>
        <div
          dangerouslySetInnerHTML={{
            __html: i18n('v2.pages.organizations.section4.governments.text')
          }}
        />
      </Col>

      <Col md={6}>
        <h5 className='text-primary text-uppercase'>
          {i18n('v2.pages.organizations.section4.companies.subtitle')}
        </h5>
        <h2 className='mb-4'>
          {i18n('v2.pages.organizations.section4.companies.title')}
        </h2>
        <div
          dangerouslySetInnerHTML={{
            __html: i18n('v2.pages.organizations.section4.companies.text')
          }}
        />
      </Col>
    </Block>
  );
}
