import { Main } from 'dawere-uic';
import { Route } from 'react-router-dom';
import { PageRenderer, RouteProps } from 'router';

/**
 * Layout sencillo sin navbar, sin footer, etc.
 */
export function BareLayout({
  component: Component,
  restricted,
  ...rest
}: RouteProps) {
  return (
    <Route
      {...rest}
      render={(props) => {
        const View = (
          <Main>
            {/* Main content */}
            <Component {...props} />
          </Main>
        );

        return <PageRenderer view={View} restricted={restricted} />;
      }}
    />
  );
}
