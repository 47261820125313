import { API } from 'api';
import { i18n } from 'dawere-commons';
import { Skeleton } from 'dawere-uic';
import { useEffect, useState } from 'react';
import { Topic } from '../components/Topic';
import { useSubject } from '../hooks/useSubject';

export function TopicsSection() {
  const { isLoading, subject } = useSubject();
  const [topics, setTopics] = useState<any[]>([]);

  useEffect(() => {
    if (subject === null) {
      return;
    }

    API.Subjects.getTopics(subject?.id).then((response: any) =>
      setTopics(response.data)
    );
  }, [subject]);

  return (
    <>
      {isLoading ? (
        <Skeleton.Title className='mb-4' />
      ) : (
        <>
          <h2 className='mb-4'>{i18n('v2.pages.subject.section2.title')}</h2>
        </>
      )}

      {isLoading ? (
        <>
          <Skeleton count={2} />
        </>
      ) : (
        <>
          {topics.map((topic: any, index: number) => (
            <Topic key={topic?.id} index={index + 1} {...topic} />
          ))}
        </>
      )}
    </>
  );
}
