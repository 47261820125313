import { API } from 'api';
import { Session } from 'dawere-commons';
import { useEffect, useState } from 'react';

export function useProgramStatus(programId: any, studentId?: string) {
  const [status, setStatus] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    // If we have a session, we get the programs and set program status
    if (Session.getToken() && programId) {
      if (studentId) {
        API.Students.getAllEnrollments({ student_id: studentId, latest: true })
          .then((response: any[]) => {
            console.log(response);
            const found = response.find(
              ({ program }: any) =>
                program.id === programId || program.friendlyPath === programId
            );
            setStatus(found?.status ?? undefined);
          })
          .finally(() => setIsLoading(false));
      } else {
        API.Students.getAllEnrollments({ latest: true })
          .then(() => {
            const found = Session.getEnrolmentByProgram(programId);
            setStatus(found?.status ?? undefined);
          })
          .finally(() => setIsLoading(false));
      }
    }
  }, [programId, studentId]);

  return { status, isLoading };
}
