import { Route } from 'react-router-dom';
import { PageRenderer, RouteProps } from 'router';
import styled from 'styled-components';

const WrapperStyled = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function PDFLayout({
  component: Component,
  restricted,
  ...rest
}: RouteProps) {
  return (
    <Route
      {...rest}
      render={(props) => {
        const View = (
          <WrapperStyled>
            {/* Main content */}
            <Component {...props} />
          </WrapperStyled>
        );

        return <PageRenderer view={View} restricted={restricted} />;
      }}
    />
  );
}
