import { Session } from 'dawere-commons';
import { AvatarChip, Dawere, IBaseProps, Logo, Utils } from 'dawere-uic';

export function NavbarSlim(props: IBaseProps) {
  const account = Session.getAccountInfo();

  return (
    <Dawere.Navbar {...props} sticky logo={<Logo />} burger={false}>
      <ul className='user-select-none'>
        <Dawere.Navbar.Item>
          <AvatarChip src={account?.picturePath} size={36}>
            <div className='fw-normal'>
              {Utils.getFullname(account?.name, account?.lastName)}
            </div>
          </AvatarChip>
        </Dawere.Navbar.Item>
      </ul>
    </Dawere.Navbar>
  );
}
