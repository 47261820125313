import { useQuery } from '@tanstack/react-query';
import { API } from 'api';
import { useRouter } from 'dawere-uic';
import React from 'react';

export interface ReceiptProps {
  isLoading: boolean;
  data: any;
}

export const ReceiptContext = React.createContext<ReceiptProps>({
  isLoading: true,
  data: null
});

export function ReceiptProvider({ children, receiptId }: any) {
  const { push } = useRouter();

  const { isLoading, isFetching, data } = useQuery({
    enabled: !!receiptId,
    queryKey: ['receipt', receiptId],
    queryFn: () => API.Payments.getReceipt(receiptId),
    onError: () => {
      push('/404');
    }
  });

  return (
    <ReceiptContext.Provider
      value={{
        isLoading: isLoading || isFetching,
        data
      }}>
      {children}
    </ReceiptContext.Provider>
  );
}
