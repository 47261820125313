import { Document, Font, Page, PDFViewer, View } from '@react-pdf/renderer';
import { i18n } from 'dawere-commons';
import { Spinner } from 'dawere-uic';
import { PDF_PAGE_STYLES } from 'values';
import { useReceipt } from '../hooks/useReceipt';
import { DateInfoSection } from './DateInfoSection';
import { DawereInfoSection } from './DawereInfoSection';
import { FooterSection } from './FooterSection';
import { InvoiceInfoSection } from './InvoiceInfoSection';
import { TableSection } from './TableSection';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: 'https://fonts.cdnfonts.com/s/12165/Roboto-Regular.woff' },
    {
      src: 'https://fonts.cdnfonts.com/s/12165/Roboto-Bold.woff',
      fontWeight: 'bold'
    }
  ]
});

export function PDFWrapper() {
  const { data, isLoading } = useReceipt();

  if (isLoading) {
    return (
      <div className='text-center'>
        <Spinner variant='pulse' color='primary' size={5} />
        <span className='d-block fs-2 text-muted mt-2'>
          {i18n('ui.commons.loading')}
        </span>
      </div>
    );
  }

  return (
    <PDFViewer width='100%' height='100%'>
      <Document
        title={i18n('v2.pages.pdfReceipt.docName', { code: data?.number })}>
        <Page size='A4' style={PDF_PAGE_STYLES}>
          {/* Header */}
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 15
            }}>
            <DawereInfoSection />

            <DateInfoSection data={data} />
          </View>

          {/* Sub-header */}
          <InvoiceInfoSection data={data} />

          {/* Table */}
          <TableSection data={data} />

          {/* Footer */}
          <FooterSection data={data} />
        </Page>
      </Document>
    </PDFViewer>
  );
}
