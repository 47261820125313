import { API } from 'api';
import { i18n } from 'dawere-commons';
import { Button, Callout, Col, Row } from 'dawere-uic';
import { ASSETS } from 'values';

export function GraduatedPanel({ program }: any) {
  return (
    <Row>
      <Col>
        <Callout className='text-center border border-platinium bg-white'>
          <Row align='center'>
            <Col>
              <h2>
                {i18n('v2.pages.studentDashboard.tabs.1.graduated.title')}
              </h2>

              <p className='fs-1 text-muted'>
                {i18n(
                  'v2.pages.studentDashboard.tabs.1.graduated.description',
                  { degree: program?.degreeName }
                )}
              </p>

              <Callout variant='powderTint'>
                <p>
                  {i18n('v2.pages.studentDashboard.tabs.1.graduated.details')}
                </p>

                <Button
                  className='w-100 w-md-auto'
                  onClick={(ev: any) => {
                    ev.preventDefault();
                    API.Zendesk.goto(
                      'https://ayuda.dawere.com/hc/es-419/articles/9478599748109--Cu%C3%A1ndo-y-c%C3%B3mo-puedo-solicitar-mi-paquete-de-grado-'
                    );
                  }}>
                  {i18n('v2.pages.studentDashboard.tabs.1.graduated.button')}
                </Button>
              </Callout>
            </Col>
            <Col className='order-first order-md-last | mb-4 mb-md-0'>
              <img
                src={ASSETS.ILLUSTRATIONS.HANDS_DIPLOMAS}
                alt={i18n('v2.pages.studentDashboard.tabs.1.graduated.title')}
                className='img-fluid'
              />
            </Col>
          </Row>
        </Callout>
      </Col>
    </Row>
  );
}
