import { API } from 'api';
import { i18n } from 'dawere-commons';
import { Button, Icons, Loading, Utils, useRouter } from 'dawere-uic';
import { PageWrapper, TwoColumnTemplate } from 'pages';
import { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PATHS } from 'router';
import { getSEO, trackEvent } from 'utils';
import { ASSETS, QPARAMS } from 'values';

/**
 * Path: /verificar-cuenta/verificada
 * Key: VERIFIED_ACCOUNT
 * @returns {React.ReactElement}
 */
export default function VerifiedAccountPage() {
  const {
    query: { [QPARAMS.Token]: token },
    push
  } = useRouter();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (token) {
      const decoded = Utils.decodeJWT(token);
      const { userEmail } = decoded;

      if (userEmail) {
        setIsLoading(true);
        API.Account.verifyEmail(token, userEmail)
          .then(() => {
            setIsLoading(false);
            trackEvent('sign_up', { method: 'formulario' });
          })
          .catch(() => push(PATHS.INVALID_TOKEN));
      }
    }
  }, [push, token]);

  if (!token) {
    return <Redirect to={PATHS.HOME} />;
  }

  if (isLoading) {
    return <Loading className='py-9' />;
  }

  return (
    <PageWrapper>
      {getSEO('v2.pages.verifiedAccount.seo', true)}

      <TwoColumnTemplate
        mobileImage={
          <span className='rounded-circle bg-platinium d-inline-block p-4'>
            <Icons.Celebration className='text-secondary fs-9' />
          </span>
        }
        desktopImage={
          <img
            src={ASSETS.ILLUSTRATIONS.CELEBRATION}
            alt={i18n('v2.pages.verifiedAccount.title')}
            className='img-fluid'
          />
        }>
        <h2 className='mb-3 text-center text-lg-left'>
          {isLoading
            ? i18n('v2.pages.verifiedAccount.verifyingTitle')
            : i18n('v2.pages.verifiedAccount.title')}
        </h2>

        <h5 className='mb-5'>
          {isLoading
            ? i18n('v2.pages.verifiedAccount.verifyingSubtitle')
            : i18n('v2.pages.verifiedAccount.subtitle')}
        </h5>

        {isLoading ? null : (
          <Button as={Link} to={PATHS.LOGIN} block>
            {i18n('v2.pages.verifiedAccount.buttons.login')}
          </Button>
        )}
      </TwoColumnTemplate>
    </PageWrapper>
  );
}
