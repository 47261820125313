import { i18n } from 'dawere-commons';
import { Button, Callout, Col, Row, Visible } from 'dawere-uic';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { ASSETS } from 'values';

export function ToEnrollPanel() {
  return (
    <Row>
      <Col>
        <Callout className='text-center border border-platinium bg-white'>
          <Row align='center'>
            <Visible xs sm>
              <Col md={6} className='text-center'>
                <img
                  src={ASSETS.ILLUSTRATIONS.STUDENT_READING}
                  alt={i18n('v2.pages.home.section4.title')}
                  className='img-fluid w-70 w-md-100 mb-4'
                />
              </Col>
            </Visible>

            <Col md={6} className='text-center'>
              <h3 className='mb-4'>
                {i18n('v2.pages.studentDashboard.tabs.1.noProgramsTitle')}
              </h3>
              <p className='mb-4'>
                {i18n('v2.pages.studentDashboard.tabs.1.noPrograms')}
              </p>

              <Button as={Link} to={PATHS.CATALOG} className='w-100 w-md-auto'>
                {i18n('v2.pages.home.section12.buttons.catalog')}
              </Button>
            </Col>

            <Visible md lg xl xxl>
              <Col md={6} className='text-center'>
                <img
                  src={ASSETS.ILLUSTRATIONS.STUDENT_READING}
                  alt={i18n('v2.pages.home.section4.title')}
                  className='img-fluid w-70 w-md-100 mb-4'
                />
              </Col>
            </Visible>
          </Row>
        </Callout>
      </Col>
    </Row>
  );
}
