import { BillingInfoForm } from 'components';
import { i18n } from 'dawere-commons';
import { Divider, Visible } from 'dawere-uic';
import { usePayment } from '../hooks/usePayment';
import { SummaryCard } from './SummaryCard';

export function Checkout() {
  const { isLoading, data } = usePayment();

  return (
    <>
      <h2 className='mb-4'>
        {i18n('v2.pages.buyCoupons.steps.2.description')}
      </h2>

      <div className='shadow shadow-md-0 | p-3 p-md-0'>
        {/* Datos fiscales */}
        <BillingInfoForm readonly loading={isLoading} data={data?.billing} />

        <Visible md lg xl>
          <Divider />
        </Visible>
      </div>

      <Visible xs sm>
        <div
          className='bg-cultured p-3 my-4'
          style={{ marginLeft: '-15px', marginRight: '-15px' }}>
          <SummaryCard />
        </div>
      </Visible>
    </>
  );
}
