import {
  AltRouteOutlined,
  AutoStoriesOutlined,
  Celebration,
  PaymentOutlined,
  PersonOff,
  SchoolOutlined,
  ScienceOutlined
} from '@mui/icons-material';
import { i18n } from 'dawere-commons';
import { Badge, Button, Utils } from 'dawere-uic';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import styled from 'styled-components';

export function ScorecardLink({ entry, enrollment }: any) {
  const getIcon = useCallback((status) => {
    switch (status) {
      case 'UNCOMPLETED_PROFILE':
        return <PersonOff />;
      case 'DEBT':
        return <PaymentOutlined />;
      case 'GRADUATED':
        return <SchoolOutlined />;
      case 'CONTINUE':
      case 'CONCLUDED':
      case 'RE_ENROLLMENT':
        return <Celebration />;
      case 'DEPRECATED':
        return <AltRouteOutlined />;
      case 'FREE_TRIAL_ENDED':
      case 'FREE_TRIAL':
        return <ScienceOutlined />;
      default:
        return <AutoStoriesOutlined />;
    }
  }, []);

  return (
    <ScoreCardLinkStyled
      className='shadow bezier'
      key={enrollment.id}
      to={PATHS.get('SCORECARDS_VIEW', {
        enrollmentId: enrollment.id
      })}
      target='_blank'>
      <div
        className={Utils.styles(
          'left bezier',
          enrollment.status === 'ACTIVE' && 'active'
        )}>
        {getIcon(enrollment.status)}

        <Badge
          small
          className='bg-transparent border border-primary text-primary'>
          {i18n(`v2.pages.studentDashboard.tabs.6.${enrollment.status}`)}
        </Badge>
      </div>

      <div className='right'>
        {entry?.program?.byCalendar && enrollment.calendar && (
          <div>
            <strong>{i18n('v2.pages.studentDashboard.tabs.6.calendar')}</strong>
            <span>
              {Utils.formatDate(enrollment.calendar?.dateInit, 'dd/mmm/yyyy')} -{' '}
              {Utils.formatDate(enrollment.calendar?.dateEnd, 'dd/mmm/yyyy')}
            </span>
          </div>
        )}
        <div>
          <strong>{i18n('v2.pages.studentDashboard.tabs.6.period')}</strong>
          {enrollment.period?.name}
        </div>
        <div className='fs-small text-muted'>
          <strong>
            {i18n('v2.pages.studentDashboard.tabs.6.enrollmentDate')}
          </strong>
          {Utils.formatDate(enrollment.admissionDate, 'dd/mmm/yyyy')}
        </div>
        <div className='fs-small text-muted'>
          <strong>{i18n('v2.pages.studentDashboard.tabs.6.order')}</strong>
          {enrollment.order?.number}
        </div>
        <div className='fs-small text-muted'>
          <strong>{i18n('v2.pages.studentDashboard.tabs.6.school')}</strong>
          {enrollment.school?.name}
        </div>

        <Button block variant='action' className='mt-3'>
          {i18n('v2.pages.studentDashboard.tabs.6.button')}
        </Button>
      </div>
    </ScoreCardLinkStyled>
  );
}

const ScoreCardLinkStyled = styled(Link)`
  display: flex;
  background-color: #fff;
  color: var(--dw-color-text);
  border: 2px solid #fff;
  border-radius: 8px;

  &:hover {
    text-decoration: none;
    color: var(--dw-color-text);
  }

  &:hover {
    border-color: var(--dw-color-primary);

    .left {
      transform: rotate(-6.5deg) scale(1.18);
      box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
    }

    .right {
      button {
        background-color: var(--dw-color-platinium);
      }
    }
  }

  &.disabled {
    pointer-events: none;
    filter: grayscale(1);
  }

  .left {
    --line: 30px;

    display: flex;
    gap: var(--dw-spacer-2);
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-shrink: 0;
    border-radius: 8px;
    margin: var(--dw-spacer-2);
    padding: var(--dw-spacer-2);
    min-width: 120px;
    background-color: var(--dw-color-powderTint);
    position: relative;
    border: solid 1px var(--dw-color-platinium);
    background-image: repeating-linear-gradient(
      var(--dw-color-lightGray) 0px,
      var(--dw-color-lightGray) var(--line),
      rgba(0, 128, 128, 0.2) calc(var(--line) + 1px)
    );
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.161)
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

    ::before {
      content: '';
      width: 2px;
      height: 100%;
      background-color: rgba(255, 0, 0, 0.15);
      position: absolute;
      top: 0;
      left: 10px;
    }

    svg {
      font-size: 4.5rem;
      color: var(--dw-color-primaryShade);
    }

    &.active {
      border: solid 1px var(--dw-color-warning);
      background-image: repeating-linear-gradient(
        var(--dw-color-warningTint) 0px,
        var(--dw-color-warningTint) var(--line),
        rgba(0, 128, 128, 0.2) calc(var(--line) + 1px)
      );
    }
  }

  .right {
    padding: var(--dw-spacer-2);
    padding-left: var(--dw-spacer-3);
    flex-grow: 1;
  }
`;
