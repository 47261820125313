import { i18n } from 'dawere-commons';
import { Block, Button, Col, Row } from 'dawere-uic';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { ASSETS } from 'values';

export function StartSection() {
  return (
    <Block>
      <Col xs={12}>
        <Row align='center' className='mb-6 mb-lg-8'>
          <Col lg={6}>
            <img
              src={ASSETS.ILLUSTRATIONS.MOM_HOMEWORK}
              alt={i18n('v2.pages.home.section9.categories.1.title')}
              className='mb-5 mb-md-0 | img-fluid'
            />
          </Col>
          <Col lg={6}>
            <h5 className='text-primary text-uppercase'>
              {i18n('v2.pages.home.section9.categories.1.subtitle')}
            </h5>
            <h3 className='mb-4'>
              {i18n('v2.pages.home.section9.categories.1.title')}
            </h3>
            <p>{i18n('v2.pages.home.section9.categories.1.text')}</p>
            <Button
              to={`${PATHS.REGISTER}?tab=1`}
              as={Link}
              className='w-100 w-md-auto | click_register'>
              {i18n('v2.pages.home.section9.categories.1.buttons.enroll')}
            </Button>
          </Col>
        </Row>

        <Row align='center' className='mb-6 mb-lg-8'>
          <Col lg={6} className='text-center'>
            <img
              src={ASSETS.ILLUSTRATIONS.STUDENT_BACKPACK}
              alt={i18n('v2.pages.home.section9.categories.3.title')}
              className='mb-5 mb-md-0 | img-fluid'
            />
          </Col>
          <Col lg={6} className='order-lg-first'>
            <h5 className='text-primary text-uppercase'>
              {i18n('v2.pages.home.section9.categories.3.subtitle')}
            </h5>
            <h3 className='mb-4'>
              {i18n('v2.pages.home.section9.categories.3.title')}
            </h3>
            <p>{i18n('v2.pages.home.section9.categories.3.text')}</p>

            <Button
              as={Link}
              to={`${PATHS.REGISTER}?tab=0`}
              className='w-100 w-md-auto | click_register'>
              {i18n('v2.pages.home.section9.categories.3.buttons.enroll')}
            </Button>
          </Col>
        </Row>

        <Row align='center'>
          <Col lg={6} className='text-center'>
            <img
              src={ASSETS.ILLUSTRATIONS.VIDEO_CHAT}
              alt={i18n('v2.pages.home.section9.categories.2.title')}
              className='mb-5 mb-md-0 | img-fluid'
            />
          </Col>

          <Col lg={6}>
            <h5 className='text-primary text-uppercase'>
              {i18n('v2.pages.home.section9.categories.2.subtitle')}
            </h5>
            <h3 className='mb-4'>
              {i18n('v2.pages.home.section9.categories.2.title')}
            </h3>
            <p>{i18n('v2.pages.home.section9.categories.2.text')}</p>
            <Button
              to={`${PATHS.REGISTER}?tab=2`}
              as={Link}
              variant='outline'
              className='w-100 w-md-auto | mr-0 mr-md-2 | mb-2 mb-md-0 | click_register'>
              {i18n('v2.pages.home.section9.categories.2.buttons.enroll')}
            </Button>

            <Button className='| w-100 w-md-auto' as={Link} to={PATHS.LOGIN}>
              {i18n('v2.pages.organizations.section1.buttons.login')}
            </Button>
          </Col>
        </Row>
      </Col>
    </Block>
  );
}
