import { Block, Col, Skeleton } from 'dawere-uic';
import { QuaternarySlider } from '../components/QuaternarySlider';
import { useCatalog } from '../hooks/useCatalog';

export function QuaternarySection() {
  const { isLoading, catalog } = useCatalog();

  if (isLoading) {
    return (
      <Block narrow>
        <Col xs={12}>
          <Skeleton.Title />
          <Skeleton.Paragraph />
        </Col>

        {Array(3)
          .fill({})
          .map((_, i: number) => (
            <Col key={i} xs={12} md={4}>
              <Skeleton.Image />
            </Col>
          ))}
      </Block>
    );
  }

  return (
    <>
      {catalog?.quaternary?.map((entry: any) => (
        <QuaternarySlider key={entry.id} entry={entry} />
      ))}
    </>
  );
}
