import { API } from 'api';
import { i18n } from 'dawere-commons';
import {
  AvatarChip,
  Block,
  Breadcrumbs,
  Col,
  Pagination,
  Progress,
  Row,
  Skeleton,
  Table,
  useRouter,
  Utils,
  Visible
} from 'dawere-uic';
import { PageWrapper } from 'pages';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { getSEO } from 'utils';
import { QPARAMS } from 'values';

/**
 * Path: /monitoreo/profesor/:organizationId/:programId/:subjectId/:periodName
 * Key: TEACHERS_MONITORING
 * @returns {React.ReactElement}
 */
export default function TeacherMonitoringPage() {
  const { query, push } = useRouter();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [program, setProgram] = useState<any>(null);
  const [subject, setSubject] = useState<any>(null);
  const [data, setData] = useState<any[]>([]);

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);

  const desktopColumns = useMemo(
    () => [
      {
        Header: i18n('v2.pages.teacherMonitoring.table.columns.name'),
        accessor: 'name',
        disableSortBy: true,
        Cell: ({ value, row }: any) => {
          return (
            <AvatarChip size={35} src={row.original?.avatar} gap={2}>
              <div>{value}</div>
              <small
                className='d-block text-muted'
                style={{ marginTop: '-2px' }}>
                {row.original?.email}
              </small>
            </AvatarChip>
          );
        }
      },
      {
        Header: i18n('v2.pages.teacherMonitoring.table.columns.progress'),
        accessor: 'progress',
        disableSortBy: true,
        Cell: ({ cell }: any) => {
          return (
            <Progress
              variant='info'
              value={cell.value}
              width={150}
              pre={`${cell.value}%`}
            />
          );
        }
      },
      {
        Header: i18n('v2.pages.teacherMonitoring.table.columns.score'),
        accessor: 'score',
        disableSortBy: true,
        Cell: ({ cell }: any) => {
          return (
            <Progress
              variant={cell.value < program?.passingGrade ? 'error' : 'success'}
              value={cell.value}
              max={program?.maxGrade}
              width={150}
              pre={
                <span
                  className={
                    cell.value < program?.passingGrade
                      ? 'text-error'
                      : 'text-success'
                  }>
                  {cell.value}/{program?.maxGrade}
                </span>
              }
            />
          );
        }
      },
      {
        Header: i18n('v2.pages.teacherMonitoring.table.columns.evaluations'),
        accessor: 'evaluations',
        disableSortBy: true,
        Cell: ({ cell }: any) => {
          return <>{cell.value}</>;
        }
      }
    ],
    [program?.maxGrade, program?.passingGrade]
  );

  const mobileColumns = useMemo(
    () => [
      {
        Header: i18n('v2.pages.teacherMonitoring.table.columns.students'),
        accessor: 'name',
        disableSortBy: true,
        Cell: ({ cell }: any) => {
          const {
            row: { original }
          } = cell;
          return (
            <>
              <AvatarChip
                className='mb-3'
                size={50}
                src={original?.avatar}
                gap={2}>
                <strong className='fs-1'>{cell.value}</strong>
                <p className='text-muted mb-0'>{original.email}</p>
              </AvatarChip>

              <p className='mb-1'>
                <strong className='fs-small'>
                  {i18n('v2.pages.teacherMonitoring.table.columns.progress')}
                </strong>
                <Progress
                  variant='info'
                  value={original.progress}
                  pre={`${original.progress}%`}
                />
              </p>
              <p className='mb-1'>
                <strong className='fs-small'>
                  {i18n('v2.pages.teacherMonitoring.table.columns.score')}
                </strong>
                <Progress
                  variant={
                    original.score < program?.passingGrade ? 'error' : 'success'
                  }
                  value={original.score}
                  max={program?.maxGrade}
                  pre={
                    <span
                      className={
                        original.score < program?.passingGrade
                          ? 'text-error'
                          : 'text-success'
                      }>
                      {original.score}/{program?.maxGrade}
                    </span>
                  }
                />
              </p>
              <p>
                <strong className='fs-small'>
                  {i18n('v2.pages.teacherMonitoring.table.columns.evaluations')}
                </strong>
                <span className='d-block'>{original.evaluations}</span>
              </p>
            </>
          );
        }
      }
    ],
    [program?.maxGrade, program?.passingGrade]
  );

  const load = useCallback(() => {
    if (query?.organizationId && query?.programId && query?.subjectId) {
      setIsLoading(true);

      API.Organizations.monitoring(
        query?.organizationId,
        query?.programId,
        query?.subjectId,
        {
          page: pageIndex,
          size: pageSize
        }
      )
        .then((response: any) => {
          setProgram(response.program);
          setSubject(response.subject);
          setData(response.data);
          setPageCount(response.totalPages);
          setTotal(response.totalRows);
        })
        .catch((error: any) => {
          push(PATHS.MY_ORGANIZATIONS);
        })
        .finally(() => setIsLoading(false));
    }
  }, [
    pageIndex,
    pageSize,
    push,
    query?.organizationId,
    query?.programId,
    query?.subjectId
  ]);

  useEffect(() => {
    load();
  }, [load]);

  const handlePaging = useCallback(({ pageSize, pageIndex }: any) => {
    setPageSize(pageSize);
    setPageIndex(pageIndex);
  }, []);

  const handleRowClick = useCallback(
    (row: any) => {
      const [first] = Utils.isArray(row) ? row : [row];

      const path = PATHS.get(
        'CLASSROOM_VARIANT',
        {
          enrollmentId: query?.enrollmentId,
          subjectId: query?.subjectId
        },
        {
          [QPARAMS.Student]: first?.id,
          [QPARAMS.Back]: encodeURIComponent(
            PATHS.get('TEACHERS_MONITORING', {
              organizationId: query?.organizationId,
              programId: query?.programId,
              subjectId: query?.subjectId,
              periodName: query?.periodName
            })
          )
        }
      );
      push(path);
    },
    [
      query?.enrollmentId,
      query?.subjectId,
      query?.organizationId,
      query?.programId,
      query?.periodName,
      push
    ]
  );

  return (
    <PageWrapper>
      {getSEO('v2.pages.teacherMonitoring.seo', true)}

      <Block narrow>
        {/* Titulo */}
        {isLoading && !program && !subject ? (
          <Col xs={12} className='mb-4'>
            <Skeleton.Breadcrumbs count={2} />
            <Skeleton.Title className='d-block mb-2' />
          </Col>
        ) : (
          <Col xs={12} className='mb-4'>
            <Row align='end'>
              <Col xs={12} md={8}>
                <Breadcrumbs
                  as={Link}
                  withBackArrow
                  routes={[
                    {
                      breadcrumb: program?.name,
                      to: PATHS.get('TEACHERS_DASHBOARD', {
                        organizationId: query?.organizationId
                      })
                    },
                    {
                      breadcrumb: i18n(
                        'v2.pages.teacherMonitoring.breadcrumbs.2'
                      )
                    }
                  ]}
                />

                <h2 className='mb-2'>
                  {i18n('v2.pages.teacherMonitoring.title')}
                </h2>

                <h3 className='mb-2'>{program?.name}</h3>
                <h4 className='mb-0 text-muted'>
                  {subject?.title} - {query?.periodName}
                </h4>
              </Col>

              <Col xs={12} md={4}>
                <div className='d-flex flex-column flex-md-row justify-content-end'>
                  {/* <Button
                    variant='outline'
                    className='mr-md-2 | mt-2 mt-md-0 | order-last order-md-first | w-100 w-md-auto'>
                    {i18n('v2.pages.teacherMonitoring.buttons.virtualClass')}
                  </Button>
                  <Button className='order-first order-md-last | w-100 w-md-auto | mt-4 mt-md-0'>
                    {i18n('v2.pages.teacherMonitoring.buttons.chat')}
                  </Button> */}
                </div>
              </Col>
            </Row>
          </Col>
        )}

        {/* Tabla */}
        <Col xs={12}>
          <Visible xs sm>
            <Table
              data={data}
              columns={mobileColumns}
              loading={isLoading}
              pageable={false}
            />
          </Visible>
          <Visible md lg xl>
            <Table
              data={data}
              columns={desktopColumns}
              loading={isLoading}
              pageable={false}
              clickable
              onRowClick={handleRowClick}
            />
          </Visible>

          <Row>
            <Visible lg xl>
              <Col xs={3} />
            </Visible>

            <Col>
              <Pagination
                align='center'
                className='my-4'
                onUpdate={handlePaging}
                pageIndex={pageIndex}
                pageCount={pageCount}
                disabled={isLoading}
              />
            </Col>

            <Visible md lg xl>
              <Col
                xs={3}
                className='d-flex align-items-center justify-content-end'>
                {isLoading ? (
                  <Skeleton width={120} wrapper='small' />
                ) : (
                  <small>
                    <strong>{total}</strong>{' '}
                    {i18n('v2.pages.teacherMonitoring.table.count')}
                  </small>
                )}
              </Col>
            </Visible>
          </Row>
        </Col>
      </Block>
    </PageWrapper>
  );
}
