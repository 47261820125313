import { ScorecardLink } from 'components';
import { Mesh } from 'dawere-uic';

export function ScorecardPanel({ entry }: any) {
  return (
    <Mesh gap={3} minSize={380}>
      {entry?.enrollments.map((enrollment: any) => (
        <ScorecardLink
          key={enrollment.id}
          enrollment={enrollment}
          entry={entry}
        />
      ))}
    </Mesh>
  );
}
