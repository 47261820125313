import { API } from 'api';
import { i18n, Session } from 'dawere-commons';
import { Tab, TabList, TabPanel, Tabs, useRouter, Utils } from 'dawere-uic';
import { useApp, useSession } from 'hooks';
import { PageWrapper, TwoColumnTemplate } from 'pages';
import { useCallback, useState } from 'react';
import {
  getFullCountryInfo,
  getLoginRedirectPath,
  getSEO,
  Referrals,
  trackEvent
} from 'utils';
import { ASSETS, QPARAMS } from 'values';
import { OrganizationSignupForm } from './forms/OrganizationSignupForm';
import { ParentSignupForm } from './forms/ParentSignupForm';
import { StudentSignupForm } from './forms/StudentSignupForm';

const USER_MAP: any = {
  ORGANIZATION: 'Organización',
  STUDENT: 'Estudiante',
  TUTOR: 'Padre'
};

/**
 * Path: /registro
 * Key: REGISTER
 * @returns {React.ReactElement}
 */
export default function SignupPage() {
  const [isLoading, setIsLoading] = useState(false);
  const { setReferral } = useApp();
  const { push, query } = useRouter();
  const { getMe } = useSession();
  const { iso3: country } = getFullCountryInfo();

  const handleSubmit = useCallback(
    async (data: any) => {
      setIsLoading(true);

      API.Account.signup(data)
        .then((response: any) => {
          trackEvent('sign_up_not_confirmed', {
            method: 'formulario',
            user_type: USER_MAP[data.defaultDashboard]
          });

          // Hubspot form
          if (data.defaultDashboard === 'STUDENT') {
            API.Account.hubspotSignupTrack(
              data.email,
              data.name,
              data.lastName,
              country
            );
          }

          // Checks if there is a referral
          const referralId = Referrals.getId();
          if (Utils.isString(referralId)) {
            API.Account.referrals(referralId).then((response: any) => {
              setReferral(response);
            });
          }

          Session.setToken(response.token);
          // Get account info
          getMe().then((response: any) => {
            push(getLoginRedirectPath(query[QPARAMS.Redirect]));
          });
        })
        .finally(() => setIsLoading(false));
    },
    [country, getMe, push, query, setReferral]
  );

  const handleGoogleAuth = useCallback(
    (
      gToken: string,
      defaultDashboard: 'STUDENT' | 'TUTOR' = 'STUDENT',
      invitationToken?: string
    ) => {
      setIsLoading(true);

      API.Account.signup(
        {
          token: gToken,
          defaultDashboard,
          invitationToken
        },
        'google'
      )
        .then((response: any) => {
          Session.setToken(response.token);

          trackEvent('sign_up ', {
            method: 'gmail',
            user_type: USER_MAP[defaultDashboard]
          });

          // Checks if there is a referral
          const referralId = Referrals.getId();
          if (Utils.isString(referralId)) {
            API.Account.referrals(referralId).then((response: any) => {
              setReferral(response);
            });
          }

          // Get account info
          getMe().then((response: any) => {
            // Hubspot form
            if (defaultDashboard === 'STUDENT') {
              API.Account.hubspotSignupTrack(
                response.email,
                response.name,
                response.lastName,
                country
              );
            }

            push(getLoginRedirectPath(query[QPARAMS.Redirect]));
          });
        })
        .finally(() => setIsLoading(false));
    },
    [country, getMe, push, query]
  );

  return (
    <PageWrapper>
      {getSEO('v2.pages.register.seo')}

      <TwoColumnTemplate
        align='start'
        desktopImage={
          <img
            src={ASSETS.ILLUSTRATIONS.STUDENT_EXAM}
            alt={i18n('v2.login.title')}
            className='img-fluid'
          />
        }>
        <h1 className='mb-4 h3'>{i18n('v2.pages.register.title')}</h1>

        {/* <h5 className='mb-4'>
          {i18n('v2.pages.register.subtitle')}
        </h5> */}

        {/* Tabs */}
        <Tabs defaultIndex={+query[QPARAMS.Tab] || 0}>
          <TabList type='pills' justify='true' className='pills mb-5'>
            <Tab>{i18n('v2.pages.register.tabs.1.title')}</Tab>
            <Tab>{i18n('v2.pages.register.tabs.2.title')}</Tab>
            <Tab>{i18n('v2.pages.register.tabs.3.title')}</Tab>
          </TabList>

          <TabPanel>
            <StudentSignupForm
              onSubmit={handleSubmit}
              onGoogleAuth={handleGoogleAuth}
              isLoading={isLoading}
            />
          </TabPanel>

          <TabPanel>
            <ParentSignupForm
              onSubmit={handleSubmit}
              onGoogleAuth={handleGoogleAuth}
              isLoading={isLoading}
            />
          </TabPanel>

          <TabPanel>
            <OrganizationSignupForm
              onSubmit={handleSubmit}
              onGoogleAuth={handleGoogleAuth}
              isLoading={isLoading}
            />
          </TabPanel>
        </Tabs>
      </TwoColumnTemplate>
    </PageWrapper>
  );
}
