const esUS = {
  lang: `es-US,es`,
  shortLang: 'es-US',
  pages: {
    home: {
      seo: {
        title: `Colegio Virtual Dawere USA - Programas Acreditados`,
        description: `Dawere USA es un colegio virtual americano registrado en el FLDOE. Ofrecemos educación acreditada en español que se ajusta al ritmo del estudiante.`,
        keywords: `colegio virtual`,
        ogTitle: `Colegio Virtual Dawere USA - Programas Acreditados`,
        ogDescription: `Dawere USA es un colegio virtual americano registrado en el FLDOE. Ofrecemos educación acreditada en español que se ajusta al ritmo del estudiante.`,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/og/Open%20Graphs_Homepage.jpg`
      },
      mainProgramId: {
        dev: `bachillerato-virtual-estados-unidos`,
        qa: `bachillerato-virtual-estados-unidos`,
        prod: `bachillerato-virtual-estados-unidos`
      },
      doubleDegreeProgramId: {
        dev: ``,
        qa: ``,
        prod: ``
      },
      info: {
        whatsappNumber: `+58 412-2318073`,
        phoneNumber: ``,
        emailInfo: `inscripciones@dawere.us`
      },
      section1: {
        subtitle: `Escuela: Dawere USA`,
        title: `El colegio virtual acreditado que se adapta a tu horario y ritmo.`
      },
      section2: {
        title: `Obtén tu título de bachillerato norteamericano`,
        text: `
          <p>Obtén tu título de bachiller de Dawere International High School. Estamos  registrados en el Departamento de Educación de Florida, EEUU bajo el número 6139 en el Distrito de Miami-Dade. Somos el único colegio online con el pénsum curricular americano en español acreditado por Cognia.</p>
        `,
        images: {
          1: {
            url: `https://storage.googleapis.com/dawere-web-assets/Ilustraciones/COGNIA/FLDOE%20-%20COGNIA_Mesa%20de%20trabajo%201.svg`,
            alt: `Departamento de Educación de la Florida`
          }
        }
      },
      section4: {
        title: `Comienza tu periodo de prueba gratis`,
        text: `Puedes cursar cualquier programa educativo de forma gratuita durante 7 días. Conoce de primera mano las bondades del aula virtual, incluyendo video-clases, lecturas, evaluaciones (formativas y sumativas) y acceso a foro-chats por materia y encuentros síncronos con profesores.  `,
        buttons: {
          enroll: `Pruébalo gratis`
        }
      },
      section6: {
        steps: {
          1: {
            text: ` Si tienes 13 años de edad o más puedes inscribirte en Dawere sin importar tu ubicación geográfica. Solo debes llenar el formulario de inscripción, señalar los años aprobados hasta la fecha y realizar tu pago.`
          },
          2: {
            title: `Sube tus requisitos`,
            text: `Debes subir de forma digital tu documento de identidad y certificado del último grado aprobado, entre otros recaudos.`
          },
          4: {
            text: `Al aprobar todas las materias de rigor y asistir a tu ceremonia de graduación virtual, podrás solicitar que se envíe tu título hasta la puerta de tu casa.`
          }
        }
      },
      section9: {
        categories: {
          3: {
            text: `Estudia en una escuela virtual de calidad y obtén tu título de bachiller norteamericano. Estudiarás de forma rápida y divertida a través de videoclases pregrabadas, material de apoyo, evaluaciones de práctica, un foro-chat para aclarar tus dudas y más. Regístrate para recibir más información.`
          }
        }
      }
    },
    login: {
      seo: {
        title: `Inicia Sesión en tu Colegio Virtual - Dawere USA`,
        description: `Inicia sesión para acceder a los programas educativos virtuales en los que estás inscrito. Sigue avanzando en tus estudios con Dawere USA.`,
        keywords: `Inicia sesión en tu colegio virtual`,
        ogTitle: `Inicia Sesión en tu Colegio Virtual - Dawere USA`,
        ogDescription: `Inicia sesión para acceder a los programas educativos virtuales en los que estás inscrito. Sigue avanzando en tus estudios con Dawere USA. `,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/og/Open%20Graphs_Inicio%20de%20sesio%CC%81n.jpg`
      }
    },
    register: {
      seo: {
        title: `Regístrate y Accede a Programas Educativos Gratuitos - Dawere USA`,
        description: `Regístrate hoy para acceder a un abanico de programas educativos virtuales a través de la prueba gratis de 7 días. Descubre si Dawere USA es para ti.`,
        keywords: `Regístrate y accede a programas educativos gratuitos`,
        ogTitle: `Regístrate y Accede a Programas Educativos Gratuitos - Dawere USA`,
        ogDescription: `Regístrate hoy para acceder a un abanico de programas educativos virtuales a través de la prueba gratis de 7 días. Descubre si Dawere USA es para ti. `,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/og/Open%20Graphs_Registro.jpg`
      }
    },
    forgotPassword: {
      seo: {
        title: `Cambia tu Clave - Recupera el Acceso a tu Colegio Virtual- Dawere USA`,
        description: `¿Olvidaste tu clave? Resetéala y sigue avanzando académicamente con tu colegio virtual, Dawere USA.`,
        keywords: `Recurpera el acceso a tu colegio virtual`,
        ogTitle: `Cambia tu Clave - Recupera el Acceso a tu Colegio Virtual- Dawere USA `,
        ogDescription: `¿Olvidaste tu clave? Resetéala y sigue avanzando académicamente con tu colegio virtual, Dawere USA. `,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/og/Open%20Graphs_Contrasen%CC%83a.jpg`
      }
    },
    catalog: {
      seo: {
        title: `Programas Online de Dawere USA - Registrados en el FLDOE`,
        description: `CColegio registrado en el Departamento de Educación de Florida (FLDOE). Oferta virtual: Bachillerato 7mo-12vo grado (18 y 24 créditos), Adultos y más.  `,
        keywords: `Programas online, FLDOE`,
        ogTitle: `Programas Online de Dawere USA - Registrados en el FLDOE`,
        ogDescription: `Colegio registrado en el Departamento de Educación de Florida (FLDOE). Oferta virtual: Bachillerato 7mo-12vo grado (18 y 24 créditos), Adultos y más. `,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/og/Open%20Graphs_Programas%20Educativos.jpg`
      }
    },
    organizations: {
      seo: {
        title: `Escuela Secundaria Para Organizaciones - Educación Online - Dawere USA`,
        description: `ONGs, clubs deportivos, gobiernos y empresas utilizan el modelo de estudio online de la escuela secundaria Dawere para educar a jóvenes y adultos.`,
        keywords: `Escuela secundaria, educación online`,
        ogTitle: `Escuela Secundaria Para Organizaciones - Educación Online - Dawere USA`,
        ogDescription: `ONGs, clubs deportivos, gobiernos y empresas utilizan el modelo de estudio online de la escuela secundaria Dawere para educar a jóvenes y adultos.`,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/og/Open%20Graphs_%20Para%20Organizaciones.jpg`
      },
      section1: {
        title: `Escuela secundaria para organizaciones | Únete a nuestro círculo virtuoso`
      },
      section4: {
        governments: {
          text: `
            <p>
              Según la UNESCO, en los sistemas educativos tradicionales de América Latina, solo 45% de los que inician el bachillerato se gradúan.
              </p>
            <p>
              Hay una deserción del 15% de los estudiantes todos los años y más del 30% de los jóvenes en edad escolar están fuera del sistema educativo tradicional. Con el uso de nuestra tecnología educativa, esto puede cambiar.
              </p>

            <p>
              Te invitamos a proveerle a todos tus ciudadanos educación de calidad gratuita. Parece una propuesta utópica pero se puede convertir en realidad gracias a los servicios de educación a distancia de Dawere. Nuestro Bachillerato Virtual ha sido diseñado para educar a millones de personas a una fracción del costo de la educación tradicional presencial. Compruébalo por ti mismo y empodera al país con la excelente educación que todos merecen.
              </p>
          `
        },
        companies: {
          text: `
           <p>
              El mundo del deporte profesional es extremadamente reñido, por lo que solo un porcentaje minúsculo logra convertirse en jugador profesional. ¿Qué ocurre con el resto?

              </p>
            <p>
              Recibir educación de calidad en paralelo a los entrenamientos puede garantizarle un futuro a todos tus jugadores sin excepción.

              </p>
            <p>
              Nuestro Bachillerato Virtual se adapta al ritmo y horario de los estudiantes. Con Dawere tus jugadores pueden formarse académicamente y perseguir sus sueños.
              </p>
          `
        }
      }
    },
    shared: {
      contact: {
        info: {
          phone: {
            title: `TELÉFONO`,
            text: ``
          },
          whatsapp: {
            title: `WHATSAPP`,
            text: `+58 412-2318073`
          },
          email: {
            title: `EMAIL`,
            text: ``
          }
        },
        buttons: {
          faq: `Preguntas frecuentes`
        }
      }
    },
    helpCenter: {
      seo: {
        keywords: `dawere, dawere estados unidos, dawere usa, dawere eeuu`
      },
      urls: {
        scholarshipForm: `https://forms.gle/FSZeAAn24C35bMiJ9`
      },
      contact: {
        info: {
          enrolled: {
            whatsapp: `+58 412-2318073`
          },
          unregistered: {
            whatsapp: `+58 412-2318073`
          }
        }
      }
    },
    philosophy: {
      seo: {
        title: `Nuestra Filosofía Educativa, Misión & Visión - Dawere USA`,
        keywords: `Misión, Visión y Filosofía Educativa`,
        ogTitle: `Nuestra Filosofía Educativa, Misión & Visión - Dawere USA`,
        ogDescription: `Nuestro modelo de aprendizaje virtual promueve la autonomía y autodeterminación del estudiante: este elige cuándo, dónde y cómo aprender.  `,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/og/Open%20Graphs_Filosofi%CC%81a%20educativa.jpg`
      }
    }
  },
  sections: {
    footer: {
      column1: {
        text: `Dawere International High School está acreditado por Cognia y registrado en el Departamento de Educación de Florida, EEUU bajo el número 6139 en el Distrito de Miami-Dade. `
      }
    }
  }
};

export default esUS;

export { esUS };
