import { API } from 'api';
import { i18n } from 'dawere-commons';
import {
  Button,
  Callout,
  Col,
  Flex,
  Icons,
  Pagination,
  Row,
  Search,
  Skeleton,
  Table,
  Utils,
  Visible
} from 'dawere-uic';
import { useOrganization } from 'hooks';
import { SearchKeyword } from 'pages/private/organizationCharts/components/SearchKeyword';
import { useCallback, useEffect, useMemo, useState } from 'react';

export function StudentsPendingInvitationTab() {
  const { organization } = useOrganization();

  const [data, setData] = useState<any[]>([]);
  const [selection, setSelection] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [sorting, setSorting] = useState('');
  const [keyword, setKeyword] = useState('');

  const desktopColumns = useMemo(
    () => [
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.2.full.tabs.2.table.columns.email'
        ),
        accessor: 'email',
        disableSortBy: true
      },
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.2.full.tabs.2.table.columns.date'
        ),
        accessor: 'date',
        disableSortBy: true
      },
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.2.full.tabs.2.table.columns.status'
        ),
        accessor: 'status',
        disableSortBy: true,
        Cell: ({ value, row }: any) => {
          return i18n(
            `v2.pages.organizationDashboard.tabs.2.full.tabs.2.${value}`
          );
        }
      }
      // {
      //   Header: '',
      //   accessor: 'action',
      //   disableSortBy: true,
      //   unclickable: true,
      //   Cell: (cell: any) => {
      //     return (
      //       <div className='text-right'>
      //         <Dropdown
      //           fixed
      //           toggle={<Icons.Dots className='anchor mx-3' />}
      //           align='right'>
      //           <Dropdown.Item
      //            onClick={() => handleOpenDeleteModal(cell.data[0])}
      //           >
      //             {i18n(
      //               'v2.pages.organizationDashboard.tabs.2.full.tabs.2.buttons.delete'
      //             )}
      //           </Dropdown.Item>
      //           <Dropdown.Item  onClick={() => handleResend(cell.data[0])}
      //           >
      //             {i18n(
      //               'v2.pages.organizationDashboard.tabs.2.full.tabs.2.buttons.resend'
      //             )}
      //           </Dropdown.Item>
      //         </Dropdown>
      //       </div>
      //     );
      //   }
      // }
    ],
    []
  );

  const mobileColumns = useMemo(
    () => [
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.2.full.tabs.2.table.columns.invitation'
        ),
        accessor: 'id',
        Cell: ({ value, row }: any) => {
          return (
            <div>
              <strong className='mb-0 d-block fs-1  mb-2'>
                {row.original?.email}
              </strong>
              <div>{row.original?.date}</div>
              <div>
                {i18n(
                  `v2.pages.organizationDashboard.tabs.2.full.tabs.2.${row.original?.status}`
                )}
              </div>
            </div>
          );
        }
      }
    ],
    []
  );

  // Reload data
  useEffect(() => {
    setIsLoading(true);

    API.Organizations.getStudentInvitations(organization.id, {
      page: pageIndex,
      size: pageSize,
      sort: sorting,
      keyword: keyword
    })
      .then((response: any) => {
        setData(response.data);
        setPageCount(response.totalPages);
        setTotal(response.totalRows);
      })
      .finally(() => setIsLoading(false));
  }, [organization.id, pageIndex, pageSize, sorting, keyword]);

  const handlePaging = useCallback(({ pageSize, pageIndex }: any) => {
    setPageSize(pageSize);
    setPageIndex(pageIndex);
  }, []);

  const handleSorting = useCallback((data: any) => {
    const [sort] = data;
    if (sort) {
      const description = `student.name,${sort.desc ? 'desc' : 'asc'}`;
      setSorting(description);
    } else {
      setSorting('');
    }
  }, []);

  const handleSearch = useCallback((data: any) => {
    setKeyword(() => {
      setPageIndex(0);
      return data.search;
    });
  }, []);

  const handleDownload = useCallback(() => {
    setIsDownloading(true);

    API.Organizations.getStudentInvitations(
      organization.id,
      {
        keyword: keyword
      },
      true
    ).finally(() => setIsDownloading(false));
  }, [keyword, organization.id]);

  const handleSelect = useCallback(
    (data: any) => {
      const ids: string[] = data.map((item: any) => item.id);
      const unique = ids.filter((v, i, a) => a.indexOf(v) === i);

      if (!Utils.isExactlySame(unique, selection)) {
        setSelection(unique);
      }
    },
    [selection]
  );

  // const handleDelete = useCallback(() => {
  //   setOpenDeleteModal(false);

  //   toast.success(
  //     idsToDelete.length > 1
  //       ? 'Las invitaciones han sido eliminadas correctamente'
  //       : 'La invitación ha sido eliminada correctamente.'
  //   );
  // }, [idsToDelete]);

  // function handleResend(cell?: any) {
  //   console.log(cell);
  //   setOpenDeleteModal(true);
  // }

  return (
    <>
      <SearchKeyword keyword={keyword} onSearch={handleSearch} />

      <Visible xs sm>
        <Search
          name='search'
          allowEmptySearch
          value={keyword}
          onSubmit={handleSearch}
          disabled={isLoading}
        />
      </Visible>

      {selection.length > 0 ? (
        <Callout className='py-2 px-4 mb-2 bg-powder'>
          <Flex align='center' justify='between'>
            <span>
              <strong>{selection.length}</strong>{' '}
              {i18n(
                'v2.pages.organizationDashboard.tabs.2.full.tabs.2.table.selected'
              )}
            </span>

            <div>
              {/* <Visible lg xl>
                <Button
                  variant='outline'
                  loading={isLoading}
                  className='mx-2'
                  onClick={() => handleOpenDeleteModal()}>
                  {i18n(
                    'v2.pages.organizationDashboard.tabs.2.full.tabs.2.buttons.delete'
                  )}
                </Button>

                <Button variant='outline' loading={isLoading}>
                  {i18n(
                    'v2.pages.organizationDashboard.tabs.2.full.tabs.2.buttons.resend'
                  )}
                </Button>
              </Visible>

              <Visible xs sm md>
                <Dropdown
                  toggle={<Icons.Dots className='anchor my-3' />}
                  align='right'>
                  <Dropdown.Item>
                    {i18n(
                      'v2.pages.organizationDashboard.tabs.2.full.tabs.2.buttons.delete'
                    )}
                  </Dropdown.Item>
                  <Dropdown.Item>
                    {i18n(
                      'v2.pages.organizationDashboard.tabs.2.full.tabs.2.buttons.resend'
                    )}
                  </Dropdown.Item>
                </Dropdown>
              </Visible> */}
            </div>
          </Flex>
        </Callout>
      ) : (
        <Flex className='mb-2' align='center' justify='between'>
          <div></div>

          <Visible md lg xl>
            <div>
              <Button
                variant='clear'
                className='ml-2'
                disabled={isLoading}
                loading={isDownloading}
                onClick={handleDownload}
                icon={<Icons.Download />}>
                {i18n(
                  'v2.pages.organizationDashboard.tabs.2.full.tabs.2.buttons.download'
                )}
              </Button>
            </div>
          </Visible>
        </Flex>
      )}

      <Visible xs sm>
        <Table
          data={data}
          // selectable
          columns={mobileColumns}
          loading={isLoading}
          pageable={false}
          manualSorting
          onSorting={handleSorting}
          onSelect={handleSelect}
        />
      </Visible>

      <Visible md lg xl>
        <Table
          data={data}
          // selectable
          columns={desktopColumns}
          loading={isLoading}
          pageable={false}
          manualSorting
          onSorting={handleSorting}
          onSelect={handleSelect}
        />
      </Visible>

      <Row>
        <Visible lg xl>
          <Col xs={3} />
        </Visible>

        <Col>
          <Pagination
            align='center'
            className='my-4'
            onUpdate={handlePaging}
            pageIndex={pageIndex}
            pageCount={pageCount}
            disabled={isLoading}
          />
        </Col>

        <Visible md lg xl>
          <Col xs={3} className='d-flex align-items-center justify-content-end'>
            {isLoading ? (
              <Skeleton width={120} wrapper='small' />
            ) : (
              <small>
                <strong>{total}</strong>{' '}
                {i18n(
                  'v2.pages.organizationDashboard.tabs.2.full.tabs.2.table.count'
                )}
              </small>
            )}
          </Col>
        </Visible>
      </Row>

      {/* <Modal
        open={openDeleteModal}
        onClose={setOpenDeleteModal}
        type='warning'
        title='Eliminar invitación'
        backdrop='static'
        footer={
          <>
            <Button
              variant='outline'
              block
              onClick={() => setOpenDeleteModal(false)}>
              Cancelar
            </Button>
            <Button block onClick={handleDelete}>
              Eliminar
            </Button>
          </>
        }>
        <p>¿Estás seguro que deseas eliminar {deleteModalMessage}?</p>
      </Modal> */}
    </>
  );
}
