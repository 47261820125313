import { API } from 'api';
import { i18n } from 'dawere-commons';
import {
  Button,
  Card,
  Col,
  Confirm,
  Container,
  Flex,
  Row,
  toast,
  Utils
} from 'dawere-uic';
import { useCallback, useState } from 'react';

export function TutorInvitationCard({
  item,
  onChange = () => {},
  ...rest
}: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState(false);
  const [isOpenConfirmResend, setIsOpenConfirmResend] = useState(false);

  const handleOpenDelete = useCallback(() => {
    setIsOpenConfirmDelete(true);
  }, []);

  const handleOpenResend = useCallback(() => {
    setIsOpenConfirmResend(true);
  }, []);

  const handleInvite = useCallback(() => {
    setIsLoading(true);

    API.Tutors.inviteTutor({ email: item.email })
      .then(() => {
        onChange();
        toast.success(
          i18n('v2.pages.members.modals.add.success', { email: item.email })
        );
      })
      .finally(() => setIsLoading(false));
  }, [item.email, onChange]);

  const handleDelete = useCallback(() => {
    setIsLoading(true);

    API.Invitations.remove(item.id)
      .then(() => {
        onChange();
        toast.success(i18n('v2.pages.members.modals.delete.invitationSuccess'));
      })
      .finally(() => setIsLoading(false));
  }, [item.id, onChange]);

  return (
    <Card className='py-3 px-2 mb-2 | bg-cultured translate-left-animation'>
      <Container fluid>
        <Row align='center'>
          <Col xs={12} md={6} lg={6}>
            <h4 className='m-0'>{item.email}</h4>
            <span className='d-block text-muted'>
              {i18n('v2.pages.permissions.invitations.sent', {
                date: Utils.formatDate(item.invitationDate, 'dd/mmm/yyyy')
              })}
            </span>
          </Col>
          <Col xs={12} md={6} lg={6} className='text-md-right'>
            <Flex
              gap={1}
              className='mt-3 mt-md-0 | justify-content-center justify-content-md-end'>
              <Button
                className='mr-2'
                variant='clear'
                onClick={handleOpenDelete}
                loading={isLoading}>
                {i18n('v2.pages.permissions.invitations.buttons.delete')}
              </Button>
              <Button
                variant='outline'
                onClick={handleOpenResend}
                loading={isLoading}>
                {i18n('v2.pages.permissions.invitations.buttons.send')}
              </Button>
            </Flex>
          </Col>
        </Row>
      </Container>

      <Confirm
        type='warning'
        title={i18n('v2.pages.members.modals.delete.invitationTitle')}
        open={isOpenConfirmDelete}
        onClose={setIsOpenConfirmDelete}
        onConfirm={handleDelete}
        confirmText={i18n('v2.pages.members.modals.delete.confirmText')}>
        <p
          dangerouslySetInnerHTML={{
            __html: i18n('v2.pages.permissions.invitations.deleteQuestion', {
              email: item?.email
            })
          }}
        />
      </Confirm>

      <Confirm
        type='email'
        title={i18n('v2.pages.members.modals.resend.title')}
        open={isOpenConfirmResend}
        onClose={setIsOpenConfirmResend}
        onConfirm={handleInvite}
        confirmText={i18n('v2.pages.members.modals.resend.confirmText')}>
        <p
          dangerouslySetInnerHTML={{
            __html: i18n('v2.pages.permissions.invitations.sendQuestion', {
              email: item?.email
            })
          }}
        />
      </Confirm>
    </Card>
  );
}
