import { API } from 'api';
import { i18n, Session } from 'dawere-commons';
import { useMatchMedia } from 'dawere-uic';
import { Steps } from 'intro.js-react';
import { useCallback } from 'react';

export function ClassroomTour({ enabled = false }: any) {
  const { isMobile } = useMatchMedia();
  const isVisible = !Session.isTourCompleted('SYLLABUS_TOUR');

  const turnOff = useCallback(() => {
    if (Session.getToken()) {
      API.Account.completeTour('SYLLABUS_TOUR').then(() =>
        Session.completeTour('SYLLABUS_TOUR')
      );
    }
  }, []);

  return (
    <Steps
      enabled={enabled && isVisible}
      steps={[
        {
          element: '.first-topic',
          intro: i18n('v2.tours.classroom.intro1')
          // position: 'right',
          // tooltipClass: 'myTooltipClass',
          // highlightClass: 'myHighlightClass'
        },
        {
          element: '.first-video',
          intro: i18n('v2.tours.classroom.intro2')
        },
        {
          element: '.first-lecture',
          intro: i18n('v2.tours.classroom.intro3')
        },
        {
          element: '.first-formative',
          intro: i18n('v2.tours.classroom.intro4')
        },
        {
          element: isMobile ? '.first-pisa' : '.pisa-start-button',
          intro: i18n('v2.tours.classroom.intro5')
        },
        {
          element: '.telegram-chat',
          intro: i18n('v2.tours.classroom.intro6')
        }
      ]}
      initialStep={0}
      onChange={(nextStepIndex, nextElement) => {
        // ['mousedown', 'click', 'mouseup'].forEach((event) =>
        //   nextElement?.firstChild?.firstChild?.dispatchEvent(
        //     new MouseEvent(event, {
        //       view: window,
        //       bubbles: true,
        //       cancelable: true,
        //       buttons: 1
        //     })
        //   )
        // );
      }}
      onBeforeChange={(nextStepIndex, nextElement) => {}}
      onAfterChange={(nextStepIndex, nextElement) => {}}
      onComplete={turnOff}
      onExit={turnOff}
      options={{
        // showStepNumbers: true,
        // scrollPadding: -130
        scrollToElement: true,
        showButtons: true,
        hidePrev: true,
        exitOnOverlayClick: false,
        exitOnEsc: false,
        nextLabel: i18n('ui.components.pagination.next'),
        prevLabel: i18n('ui.components.pagination.prev'),
        doneLabel: i18n('v2.tours.finish'),
        buttonClass: 'btn-tour',
        overlayOpacity: 0.8
      }}
    />
  );
}
