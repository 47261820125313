import { API } from 'api';
import { GoogleButton } from 'components';
import { i18n, Locales, Session } from 'dawere-commons';
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Spinner,
  useRouter,
  Utils
} from 'dawere-uic';
import { useApp, usePath, useSession } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { getLoginRedirectPath, Referrals, updateCountryInUrl } from 'utils';
import { QPARAMS } from 'values';

export function SignInForm() {
  // useTokenValidation();
  const { setReferral } = useApp();
  const { setUser } = useSession();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [redirecting, setRedirecting] = useState<boolean>(false);
  const [data, setData] = useState({
    email: '',
    password: ''
  });
  const { query } = useRouter();
  const { getPathWithRedirect } = usePath();

  const setToken = useCallback((response: any) => {
    const { token } = response;

    Session.setToken(token);
  }, []);

  const redirect = useCallback(() => {
    // Redirect to Zendesk if I came from there
    if (Utils.has(query, 'return_to')) {
      setRedirecting(true);

      API.Zendesk.go(query?.return_to);
      return;
    }

    const path = getLoginRedirectPath(query[QPARAMS.Redirect]);
    updateCountryInUrl(path);
  }, [query]);

  const setAccount = useCallback(
    (response: any) => {
      Session.setAccountInfo(response);

      const sessionLocale = Session.getLocale();
      if (sessionLocale) {
        Locales.setLocale(sessionLocale);
      }

      // Trigger provider
      setUser(response);
    },
    [setUser]
  );

  const handleSubmit = useCallback(
    (data: any) => {
      setIsLoading(true);

      return API.Account.login(data)
        .then((response: any) => {
          setToken(response);
          // Get enrolments
          return API.Students.getAllEnrollments({ latest: true, online: true });
        })
        .then((response: any) => {
          // Get account info
          return API.Account.me();
        })
        .then(({ data }: any) => {
          setAccount(data);

          // Checks if there is a referral
          const referralId = Referrals.getId();
          if (Utils.isString(referralId) && data.id !== referralId) {
            API.Account.referrals(referralId)
              .then((response: any) => {
                Referrals.save(response);
              })
              .finally(() => redirect());
          } else {
            Referrals.clean();
            redirect();
          }
        })
        .catch(() => setIsLoading(false));
    },
    [setToken, setAccount, redirect]
  );

  const handleGoogleAuth = useCallback(
    (token: string) => {
      setIsLoading(true);

      return API.Account.login(token, 'google')
        .then((response: any) => {
          setToken(response);
          // Get account info
          return API.Account.me();
        })
        .then(({ data }: any) => {
          setAccount(data);

          // Checks if there is a referral
          const referralId = Referrals.getId();
          if (Utils.isString(referralId) && data.id !== referralId) {
            API.Account.referrals(referralId)
              .then((response: any) => {
                Referrals.save(response);
              })
              .finally(() => redirect());
          } else {
            Referrals.clean();
            redirect();
          }
        })
        .catch(() => setIsLoading(false));
    },
    [setToken, setAccount, redirect]
  );

  useEffect(() => {
    if (Utils.has(query, 'return_to')) {
      if (Session.getToken()) {
        setRedirecting(true);
      }
      API.Zendesk.go(query?.return_to, false);
    } else {
      // Close active session
      // ? Redirect to home or dashboard
      Session.clean();
      setUser(null);
    }
  }, [query, setUser]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Input
          name='email'
          label={i18n('v2.pages.login.form.email')}
          type='email'
          onChange={(e: any) => setData({ ...data, email: e.target.value })}
          validators={{
            required: true,
            email: true
          }}
          loading={isLoading}
        />

        <Input
          name='password'
          label={i18n('v2.pages.login.form.password')}
          type='password'
          validators={{
            required: true
          }}
          loading={isLoading}
        />

        <Button type='submit' block loading={isLoading}>
          {i18n('v2.pages.login.buttons.login')}
        </Button>

        <p className='mt-5 text-center'>
          <Link
            to={PATHS.get(
              'FORGOT_PASSWORD',
              {},
              { [QPARAMS.Email]: data.email }
            )}>
            {i18n('v2.pages.login.links.forgotPassword')}
          </Link>
        </p>
      </Form>

      <Divider>{i18n('v2.pages.login.or')}</Divider>

      <GoogleButton
        onAuth={handleGoogleAuth}
        loading={isLoading}
        text={i18n('v2.pages.login.buttons.googleLogIn')}
      />

      <p className='mt-4 text-center'>
        {i18n('v2.pages.login.withoutAccount')}{' '}
        <Link
          to={getPathWithRedirect(
            'REGISTER',
            {},
            { [QPARAMS.Email]: data.email }
          )}>
          {i18n('v2.pages.login.links.register')}
        </Link>
      </p>

      <Modal
        open={redirecting}
        backdrop='static'
        onClose={setRedirecting}
        closable={false}
        keyboard={false}>
        <div className='text-center'>
          <Spinner
            variant='pulse'
            size={4}
            color='primary'
            className='mb-3 mt-3'
          />
          <p className='fs-2'>Redirigiendo</p>
          <p className='text-muted'>
            Por favor espera. No refresques ni cierres esta pestaña.
          </p>
        </div>
      </Modal>
    </>
  );
}
