import { SubjectCardLink } from 'components';
import { Col, Empty, Row, SubjectCard, useRouter } from 'dawere-uic';
import { PATHS } from 'router';

export function SubjectsSection({ programId, subjects = [] }: any) {
  const { query } = useRouter();

  return (
    <>
      <Row>
        {subjects.length ? (
          <>
            {subjects.map((subject: any) => (
              <Col key={subject.id} xs={12} md={6} lg={4} className='mb-4'>
                <SubjectCardLink
                  to={PATHS.get('TEACHERS_MONITORING', {
                    organizationId: query?.organizationId,
                    programId: programId,
                    subjectId: subject.id,
                    periodName: subject.period?.name
                  })}>
                  <SubjectCard
                    title={subject.title}
                    description={subject.period?.name}
                    teacher={subject.author?.name}
                    thumbnail={subject.icon}
                    hideProgress
                    hideGrades
                  />
                </SubjectCardLink>
              </Col>
            ))}
          </>
        ) : (
          <Col>
            <Empty />
          </Col>
        )}
      </Row>
    </>
  );
}
