import { Utils } from 'dawere-uic';
import { useContext } from 'react';
import { ProgramContext } from '../ProgramProvider';

export const useProgram = () => {
  const context = useContext(ProgramContext);

  if (Utils.isEmpty(context)) {
    throw new Error('"useProgram" must be used within a ProgramProvider');
  }

  return context;
};
