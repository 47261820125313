import { i18n } from 'dawere-commons';
import { Avatar, Callout } from 'dawere-uic';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { QPARAMS } from 'values';

export function Subject({
  isCourse = false,
  programId,
  currencyId,
  id,
  title,
  totalExams,
  totalTopics,
  icon
}: any) {
  return (
    <Callout className='d-flex align-items-start h-100 bg-cultured'>
      <div>
        <Avatar src={icon} alt={title} size={50} />
      </div>
      <div className='ml-3'>
        <h5 className='mb-2' style={{ lineHeight: 1.35 }}>
          {title}
        </h5>
        <p className='text-spanish fs-small'>
          {totalTopics} {i18n('v2.pages.program.section4.topics')} |{' '}
          {totalExams} {i18n('v2.pages.program.section4.exams')}
        </p>
        <Link
          to={PATHS.get(
            isCourse ? 'COURSE_SUBJECT' : 'PROGRAM_SUBJECT',
            {
              programId,
              subjectId: id
            },
            {
              [QPARAMS.Currency]: currencyId,
              [QPARAMS.Course]: isCourse
            }
          )}>
          {i18n('v2.pages.program.section4.links.details')}
        </Link>
      </div>
    </Callout>
  );
}
