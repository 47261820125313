import { useRouter } from 'dawere-uic';
import { ReceiptProvider } from './ReceiptProvider';
import { PDFWrapper } from './sections/PDFWrapper';

/**
 * Path: /recibo/{receiptId}
 * Key: RECEIPT
 * @returns {React.ReactElement}
 */
export default function ReceiptPDFPage() {
  const { query } = useRouter();

  return (
    <ReceiptProvider receiptId={query?.receiptId}>
      <PDFWrapper />
    </ReceiptProvider>
  );
}
