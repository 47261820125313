import { createItem } from './createItem';

export function getData(response: any) {
  const data = {
    id: response.id,
    name: response.name,
    billingAddress: response.billingAddress,
    postalCode: response.postalCode,
    tin: response.tin,
    country: createItem(response?.country, 'id', 'name'),
    state: createItem(response?.state, 'id', 'name'),
    township: createItem(response?.township, 'id', 'name')
  };

  return data;
}
