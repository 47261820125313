import { Utils } from 'dawere-uic';
import { useContext } from 'react';
import { ReceiptContext } from '../ReceiptProvider';

export const useReceipt = () => {
  const context = useContext(ReceiptContext);

  if (Utils.isEmpty(context)) {
    throw new Error('"useReceipt" must be used within a ReceiptProvider');
  }

  return context;
};
