import { i18n } from 'dawere-commons';
import { Block, Col, Row } from 'dawere-uic';
import { getFullCountryInfo } from 'utils';

export function CertificationsSection() {
  const { iso3: country } = getFullCountryInfo();

  if (country !== 'USA') {
    return null;
  }

  return (
    <Block>
      <Col xs={12}>
        <Row align='center'>
          <Col md={3} className='order-last order-md-first text-center'>
            <img
              src={i18n('v2.pages.organizations.certifications.images.1.url')}
              alt={i18n('v2.pages.organizations.certifications.images.1.alt')}
              className='img-fluid mt-6 mt-md-0 w-100'
            />
          </Col>
          <Col md={9}>
            <h2 className='mb-4'>
              {i18n('v2.pages.organizations.certifications.title')}
            </h2>

            <p
              className='m-0'
              dangerouslySetInnerHTML={{
                __html: i18n('v2.pages.organizations.certifications.text')
              }}
            />
          </Col>
        </Row>
      </Col>
    </Block>
  );
}
