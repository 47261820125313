import { API } from 'api';
import { i18n } from 'dawere-commons';
import { Button, Form, Input, toast, useRouter } from 'dawere-uic';
import { PageWrapper, TwoColumnTemplate } from 'pages';
import { useCallback, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { PATHS } from 'router';
import { getSEO } from 'utils';
import { ASSETS, QPARAMS } from 'values';

/**
 * Path: /reiniciar-contrasena
 * Key: RESET_PASSWORD
 * @returns {React.ReactElement}
 */
export default function ResetPasswordPage() {
  const passwordRef = useRef({});
  const { push, query } = useRouter();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(
    (data: any) => {
      setIsLoading(true);

      API.Account.resetPassword(data.password, query[QPARAMS.Token])
        .then(() => {
          toast.success(i18n('v2.pages.resetPassword.form.success'));
          push(PATHS.LOGIN);
        })
        .finally(() => setIsLoading(false));
    },
    [push, query]
  );

  const handleChange = useCallback((data: any) => {
    passwordRef.current = data.password;
  }, []);

  if (!query[QPARAMS.Token]) {
    return <Redirect to={PATHS.HOME} />;
  }

  return (
    <PageWrapper>
      {getSEO('v2.pages.resetPassword.seo', true)}

      <TwoColumnTemplate
        desktopImage={
          <img
            src={ASSETS.ILLUSTRATIONS.GUY_LAPTOP}
            alt={i18n('v2.pages.resetPassword.title')}
            className='img-fluid'
          />
        }>
        <h2 className='mb-3'>{i18n('v2.pages.resetPassword.title')}</h2>

        <h5 className='mb-5'>{i18n('v2.pages.resetPassword.subtitle')}</h5>

        <Form onSubmit={handleSubmit} onChange={handleChange}>
          <Input
            name='password'
            label={i18n('v2.pages.resetPassword.form.password')}
            type='password'
            loading={isLoading}
            helpText={i18n('v2.pages.resetPassword.helps.password')}
            validators={{
              required: true,
              minLength: 8
            }}
          />

          <Input
            name='password_repeat'
            label={i18n('v2.pages.resetPassword.form.confirmPassword')}
            type='password'
            loading={isLoading}
            helpText={i18n('v2.pages.register.helps.password')}
            validators={{
              required: true,
              minLength: 8,
              passwordMatch: (password: string) =>
                password === passwordRef.current
            }}
          />

          <Button type='submit' block loading={isLoading}>
            {i18n('v2.pages.resetPassword.buttons.submit')}
          </Button>
        </Form>
      </TwoColumnTemplate>
    </PageWrapper>
  );
}
