import { Utils } from 'dawere-uic';
import { AppContext } from 'providers';
import { useContext } from 'react';

export const useApp = () => {
  const context = useContext(AppContext);

  if (Utils.isEmpty(context)) {
    throw new Error('"useApp" must be used within a AppProvider');
  }

  return context;
};
