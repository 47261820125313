import { i18n } from 'dawere-commons';
import { Block, Col, Tab, TabList, TabPanel, Tabs } from 'dawere-uic';
import { PageWrapper } from 'pages';
import { useCallback } from 'react';
import { NumberParam, useQueryParams } from 'use-query-params';
import { getSEO } from 'utils';
import { QPARAMS } from 'values';
import { BillingInfoSection } from './sections/BillingInfoSection';
import { DropOutSection } from './sections/DropOutSection';
import { NotificationsSection } from './sections/NotificationsSection';
import { PasswordSection } from './sections/PasswordSection';
import { ProfileSection } from './sections/ProfileSection';

/**
 * Path: /configuracion
 * Key: SETTINGS
 * @returns {React.ReactElement}
 */
export default function SettingsPage() {
  const [query, setQuery] = useQueryParams({
    [QPARAMS.Tab]: NumberParam
  });

  const handleTabSelection = useCallback(
    (value: number) => {
      setQuery({ [QPARAMS.Tab]: value }, 'pushIn');
    },
    [setQuery]
  );

  return (
    <PageWrapper>
      {getSEO('v2.pages.settings.seo', true)}

      <Block narrow>
        <Col xs={12}>
          <h2 className='mb-4'>{i18n('v2.pages.settings.title')}</h2>
        </Col>

        <Col xs={12}>
          <Tabs
            onSelect={handleTabSelection}
            defaultIndex={query[QPARAMS.Tab] || 0}>
            <TabList scrollable='true' className='mb-4'>
              <Tab>{i18n('v2.pages.settings.tabs.1.title')}</Tab>
              <Tab>{i18n('v2.pages.settings.tabs.2.title')}</Tab>
              <Tab>{i18n('v2.pages.settings.tabs.3.title')}</Tab>
            </TabList>

            <TabPanel>
              <div className='mb-6'>
                <PasswordSection />
              </div>

              <div className='mb-6'>
                <ProfileSection />
              </div>

              <DropOutSection />
            </TabPanel>

            <TabPanel>
              <NotificationsSection />
            </TabPanel>

            <TabPanel>
              <BillingInfoSection />
            </TabPanel>
          </Tabs>
        </Col>
      </Block>
    </PageWrapper>
  );
}
