import { i18n, Session } from 'dawere-commons';
import { Col, Divider, Empty, Skeleton } from 'dawere-uic';
import { useSession } from 'hooks';
import { useCallback } from 'react';
import { OrganizationCard } from '../components/OrganizationCard';
import { usePermissions } from '../hooks/usePermissions';

export function MyOrganizations({ ...rest }: any) {
  const profiles = Session.getProfiles();
  const { getMe } = useSession();
  const { myOrganizations, isLoadingMyOrganizations, loadMyOrganizations } =
    usePermissions();

  const handleChange = useCallback(() => {
    getMe().then(() => loadMyOrganizations());
  }, [getMe, loadMyOrganizations]);

  return (
    <Col xs={12} className='mb-4'>
      <Divider section>
        {i18n('v2.pages.permissions.myOrganizations.title')}
      </Divider>

      <p className='mb-4'>
        {i18n('v2.pages.permissions.myOrganizations.description')}
      </p>

      {isLoadingMyOrganizations ? (
        <>
          {Array(3)
            .fill(0)
            .map((_, index) => (
              <Skeleton height={80} key={index} className='mb-2' />
            ))}
        </>
      ) : (
        <>
          {myOrganizations.length > 0 ? (
            <>
              {myOrganizations.map((item) => (
                <OrganizationCard
                  key={item.id}
                  item={item}
                  onChange={handleChange}
                />
              ))}
            </>
          ) : (
            <div className='bg-cultured rounded-3 '>
              <Empty size='xsmall'>
                {i18n('v2.pages.permissions.myOrganizations.nothing')}
              </Empty>
            </div>
          )}
        </>
      )}
    </Col>
  );
}
