import { Utils } from 'dawere-uic';
import { useContext } from 'react';
import { OrganizationChartContext } from '../providers/OrganizationChartProvider';

export const useOrganizationChart = () => {
  const context = useContext(OrganizationChartContext);

  if (Utils.isEmpty(context)) {
    throw new Error(
      '"useOrganizationChart" must be used within a OrganizationChartProvider'
    );
  }

  return context;
};
