import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { API } from 'api';
import { PayingModal } from 'components';
import { i18n } from 'dawere-commons';
import { Button, Form, Modal, Utils, theme, toast } from 'dawere-uic';
import { colors } from 'dawere-uic/lib/styles/colors';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ErrorModal from './ErrorModal';

export function CreditCardGateway({
  installments = [],
  onSuccess = () => {},
  onError = () => {},
  redirectTo = '',
  isBillingInfoCompleted = () => true,
  walletAmount = null
}: any) {
  const elements = useElements();
  const stripe = useStripe();

  const [paying, setPaying] = useState(false);
  const [openRedirectModal, setOpenRedirectModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [state, setState] = useState<any>(null);
  const [secret, setSecret] = useState<any>(null);
  const [isLoadingIntent, setIsLoadingIntent] = useState<boolean>(true);
  const [installmentsIds, setInstallmentsIds] = useState<any[]>(installments);
  const [asyncId, setAsyncId] = useState<string | null>(null);

  const handleSubmit = useCallback(async () => {
    if (!stripe || !elements || !secret) {
      return;
    }

    setPaying(true);

    const card = elements.getElement(CardElement);

    // Confirm the payment on the client
    const { error } = await stripe.confirmCardPayment(secret, {
      payment_method: {
        card: card as any
      }
    });

    if (error) {
      setAsyncId(null);
      setPaying(false);

      const errorType = error?.decline_code || error?.code;

      switch (errorType) {
        case 'incorrect_cvc':
        case 'expired_card':
        case 'processing_error':
        case 'generic_decline':
        case 'fraudulent':
        case 'insufficient_funds':
        case 'lost_card':
        case 'stolen_card':
          toast.error(
            i18n(
              `v2.pages.buyCoupons.steps.2.creditCard.errors.${
                error?.decline_code || error?.code
              }`
            )
          );
          break;

        default:
          toast.error(
            i18n(
              'v2.pages.buyCoupons.steps.2.creditCard.errors.processing_error'
            )
          );
          break;
      }

      // API.Cart.updateStripeOrder(secret, errorType);

      onError();
      setOpenErrorModal(true);
    } else {
      if (!asyncId) {
        setPaying(false);
        onSuccess('débito o crédito');
        setOpenRedirectModal(true);
      }
    }
  }, [asyncId, elements, onError, onSuccess, secret, stripe]);

  const handleChange = useCallback((data: any) => {
    setState(data);
  }, []);

  useEffect(() => {
    setIsLoadingIntent(true);

    const payload = {
      installments: installmentsIds,
      walletAmount
    };

    API.Cart.createStripeIntent(payload)
      .then(({ data, asyncProcessId }: any) => {
        setSecret(data);

        if (Utils.isString(asyncProcessId)) {
          setAsyncId(asyncProcessId);
        }
      })
      .finally(() => setIsLoadingIntent(false));
  }, [installmentsIds, walletAmount]);

  useEffect(() => {
    if (!Utils.isExactlySame(installments, installmentsIds)) {
      setInstallmentsIds(installments);
    }
  }, [installments, installmentsIds]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <h4>{i18n('v2.pages.buyCoupons.steps.2.creditCard.title')}</h4>
        <p>{i18n('v2.pages.buyCoupons.steps.2.creditCard.description')}</p>

        <ContainerStyled>
          <WrapperStyled
            className={Utils.styles(
              isFocused && 'focus',
              state?.error && 'has-error',
              paying && 'loading'
            )}>
            <CardElement
              options={cardElementOptions}
              onChange={handleChange}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
          </WrapperStyled>

          {state?.error ? (
            <ErrorStyled>
              {i18n(
                `v2.pages.buyCoupons.steps.2.creditCard.errors.${state?.error?.code}`
              )}
            </ErrorStyled>
          ) : null}
        </ContainerStyled>

        <Button
          type='submit'
          block
          disabled={!state?.complete || !isBillingInfoCompleted()}
          loading={paying || isLoadingIntent}>
          {i18n('v2.pages.buyCoupons.steps.2.creditCard.button')}
        </Button>
      </Form>

      <Modal
        type='success'
        open={openRedirectModal}
        backdrop='static'
        onClose={setOpenRedirectModal}
        closable={false}
        keyboard={false}
        title={i18n('v2.pages.buyCoupons.steps.2.creditCard.modal.title')}
        footer={
          <Button block as={Link} to={redirectTo}>
            {i18n('v2.pages.buyCoupons.steps.2.creditCard.modal.button')}
          </Button>
        }>
        <p
          dangerouslySetInnerHTML={{
            __html: i18n(
              'v2.pages.buyCoupons.steps.2.creditCard.modal.description'
            )
          }}
        />
      </Modal>

      <ErrorModal
        open={openErrorModal}
        onClose={setOpenErrorModal}
        redirectTo={redirectTo}
      />

      <PayingModal
        paying={paying}
        onClose={setPaying}
        asyncId={asyncId}
        onFinished={() => {
          setPaying(false);
          onSuccess('débito o crédito');
          setOpenRedirectModal(true);
        }}
        onError={() => {
          setPaying(false);
          onError();
          setOpenErrorModal(true);
        }}
      />
    </>
  );
}

const ContainerStyled = styled.div`
  margin-bottom: var(--dw-spacer-3);
`;

const ErrorStyled = styled.p`
  color: var(--dw-color-error);
  font-size: 85%;
  line-height: 16px;
  margin-top: var(--dw-spacer-2);
  margin-bottom: 0;
`;

const WrapperStyled = styled.div`
  display: inline-flex;
  align-items: center;
  height: 48px;
  border-radius: 2px;
  padding: 0 16px;
  line-height: 1;
  transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border: solid 1px var(--dw-color-text);
  width: 100%;

  &.focus {
    border-color: var(--dw-color-primary);
  }

  &.has-error {
    border-color: var(--dw-color-error);
  }

  &.loading,
  &.disabled {
    pointer-events: none;
    cursor: default;
    color: var(--dw-color-spanish);
    border-color: var(--dw-color-spanish);
  }

  & .StripeElement {
    width: 100%;
  }
`;

const cardElementOptions = {
  showIcon: true,
  style: {
    base: {
      fontSize: '16px',
      fontFamily: theme.body.fontFamily,
      color: colors.text,
      '::placeholder': {
        color: colors.spanish
      }
    },
    invalid: {
      color: colors.error,
      iconColor: colors.error
    }
  }
};
