import { Rest, Session } from 'dawere-commons';

const ZENDESK_URL = 'https://dawere.zendesk.com';
const ZENDESK_ACCESS_PATH = '/access/jwt?jwt=';

function redirect(token: string, returnTo?: string, target: string = '_blank') {
  const returnToPath = returnTo ? `&return_to=${returnTo}` : '';
  const url = `${ZENDESK_URL}${ZENDESK_ACCESS_PATH}${token}${returnToPath}`;

  if (returnTo) {
    window.location.href = url;
  } else {
    window.open(url, target);
  }
}

function go(
  returnTo?: string,
  redirectNow: boolean = true,
  target: string = '_blank'
) {
  if (Session.getToken()) {
    return Rest.get('zendesk/auth').then(({ data: token }: any) => {
      redirect(token, returnTo, target);
    });
  } else {
    if (redirectNow) {
      window.open(ZENDESK_URL, target);
    }
  }
}

function goto(path: string, target: string = '_blank') {
  if (Session.getToken()) {
    return Rest.get('zendesk/auth').then(({ data: token }: any) => {
      const url = `${ZENDESK_URL}${ZENDESK_ACCESS_PATH}${token}&return_to=${path}`;
      window.open(url, target);
    });
  } else {
    window.open(path, target);
  }
}

export const Zendesk = {
  go,
  goto
};
