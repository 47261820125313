import { Utils } from 'dawere-uic';
import { SubjectContext } from 'pages/public/subject/SubjectProvider';
import { useContext } from 'react';

export const useSubject = () => {
  const context = useContext(SubjectContext);

  if (Utils.isEmpty(context)) {
    throw new Error('"useSubject" must be used within a SubjectProvider');
  }

  return context;
};
