import { NavbarSlim } from 'components';
import { Main } from 'dawere-uic';
import { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { PageRenderer, RouteProps } from 'router';
import styled from 'styled-components';

const Grid = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'navbar'
    'main';
`;

/**
 * El layout por defecto de la web. Compuesto por:
 * - Navbar
 * - Contenido
 */
export function EvaluationLayout({
  component: Component,
  restricted,
  ...rest
}: RouteProps) {
  useEffect(() => {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden auto';
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        const View = (
          <Grid>
            {/* See components/NavbarSlim */}
            <NavbarSlim />
            {/* Main content */}
            <Main>
              <Component {...props} />
            </Main>
          </Grid>
        );

        return <PageRenderer view={View} restricted={restricted} />;
      }}
    />
  );
}
