import { PageWrapper } from 'pages';
import { getSEO } from 'utils';
import { AlliesSection } from '../home/sections/AlliesSection';
import { ContactSection } from '../home/sections/ContactSection';
import { ApplySection } from './sections/ApplySection';
import { CertificationsSection } from './sections/CertificationsSection';
import { CompaniesAndGovermentsSection } from './sections/CompaniesAndGovermentsSection';
import { FoundationsSection } from './sections/FoundationsSection';
import { WelcomeSection } from './sections/WelcomeSection';

/**
 * Path: /organizaciones
 * Key: ORGANIZATIONS
 * @returns {React.ReactElement}
 */
export default function OrganizationsPage() {
  return (
    <PageWrapper>
      {getSEO('v2.pages.organizations.seo')}

      {/* Educar es tarea de todos */}
      <WelcomeSection />
      {/* Cognia */}
      <CertificationsSection />
      {/* Solicita una demostración gratuita */}
      <ApplySection />
      {/* Aliados y clientes de Dawere */}
      <AlliesSection />
      {/* Para Fundaciones */}
      <FoundationsSection />
      {/* Para Gobiernos y Empresas */}
      <CompaniesAndGovermentsSection />
      {/* Contáctanos */}
      <ContactSection />
    </PageWrapper>
  );
}
