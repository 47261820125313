import { API } from 'api';
import { i18n } from 'dawere-commons';
import {
  AvatarChip,
  Button,
  Callout,
  Col,
  Dropdown,
  Flex,
  Icons,
  Pagination,
  Row,
  Search,
  Skeleton,
  Table,
  useRouter,
  Utils,
  Visible
} from 'dawere-uic';
import { useOrganization } from 'hooks';
import { SearchKeyword } from 'pages/private/organizationCharts/components/SearchKeyword';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PATHS } from 'router';
import { QPARAMS } from 'values';
import { ModalAddStudent } from '../../components/ModalAddStudent';

export function StudentsInCourseTab() {
  const { organization } = useOrganization();
  const { push } = useRouter();

  const [data, setData] = useState<any[]>([]);
  const [selection, setSelection] = useState<string[]>([]);
  const [isLoadingPrograms, setIsLoadingPrograms] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [sorting, setSorting] = useState('');
  const [keyword, setKeyword] = useState('');
  const [filters, setFilters] = useState([]);
  const [activeProgram, setActiveProgram] = useState<any>(null);
  const [activePeriod, setActivePeriod] = useState<any>(null);
  const [activeCalendar, setActiveCalendar] = useState<any>(null);

  const [openAddModal, setOpenAddModal] = useState(false);

  const desktopColumns = useMemo(
    () => [
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.2.full.tabs.1.table.columns.name'
        ),
        accessor: 'id',
        Cell: ({ value, row }: any) => {
          return (
            <AvatarChip
              align={row.subRows.length > 1 ? 'start' : 'center'}
              size={35}
              src={row.subRows[0]?.original?.avatar}
              gap={2}>
              <div>{row.subRows[0]?.original?.name}</div>
              <small
                className='d-block text-muted'
                style={{ marginTop: '-2px' }}>
                {row.subRows[0]?.original?.email}
              </small>
            </AvatarChip>
          );
        }
      },
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.2.full.tabs.1.table.columns.program'
        ),
        accessor: 'program',
        disableSortBy: true,
        aggregate: (leafValues: any[], aggregatedValues: any[]) => {
          return aggregatedValues.map((v, idx: number) => (
            <div key={idx} className='aggregated'>
              {v}
            </div>
          ));
        }
      },
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.2.full.tabs.1.table.columns.period'
        ),
        accessor: 'period',
        // disableSortBy: true,
        aggregate: (leafValues: any[], aggregatedValues: any[]) => {
          return aggregatedValues.map((v, idx: number) => (
            <div key={idx} className='aggregated'>
              {v}
            </div>
          ));
        }
      },
      {
        Header: i18n(
          `v2.pages.organizationDashboard.tabs.2.full.tabs.1.table.columns.${
            activeProgram?.byCalendar ? 'calendar' : 'admission'
          }`
        ),
        accessor: activeProgram?.byCalendar ? 'calendar' : 'admission',
        disableSortBy: true,
        aggregate: (leafValues: any[], aggregatedValues: any[]) => {
          return aggregatedValues.map((v, idx: number) => (
            <div key={idx} className='aggregated'>
              {v}
            </div>
          ));
        }
      }
      // {
      //   Header: '',
      //   accessor: 'action',
      //   disableSortBy: true,
      //   unclickable: true,
      //   Cell: ({ value, row }: any) => {
      //     return (
      //       <div className='text-right'>
      //         <Button>
      //           {i18n(
      //             'v2.pages.organizationDashboard.tabs.2.full.tabs.1.buttons.remove'
      //           )}
      //         </Button>
      //       </div>
      //     );
      //   }
      // }
    ],
    [activeProgram?.byCalendar]
  );

  const mobileColumns = useMemo(
    () => [
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.2.full.tabs.1.table.columns.student'
        ),
        accessor: 'id',
        Cell: ({ value, row }: any) => {
          return (
            <div>
              <strong className='mb-0 d-block fs-1'>
                {row.original?.name}
              </strong>
              <small
                className='d-block text-muted mb-2'
                style={{ marginTop: '-2px' }}>
                {row.original?.email}
              </small>
              <div>{row.original?.program}</div>
              <div>{row.original?.period}</div>
              <div>
                {
                  row.original[
                    activeProgram?.byCalendar ? 'calendar' : 'admission'
                  ]
                }
              </div>
            </div>
          );
        }
      }
    ],
    [activeProgram?.byCalendar]
  );

  // Reload data
  useEffect(() => {
    if (!activeProgram?.id) {
      return;
    }

    setIsLoading(true);

    API.Organizations.getStudents(
      organization.id,
      {
        page: pageIndex,
        size: pageSize,
        sort: sorting,
        keyword: keyword,
        program_id: activeProgram?.id,
        period: activePeriod?.id,
        calendar_id: activeCalendar?.id
      },
      'IN_COURSE'
    )
      .then((response: any) => {
        setData(response.data);
        setPageCount(response.totalPages);
        setTotal(response.totalRows);
      })
      .finally(() => setIsLoading(false));
  }, [
    organization.id,
    pageIndex,
    pageSize,
    sorting,
    keyword,
    activeProgram?.id,
    activePeriod?.id,
    activeCalendar?.id
  ]);

  // Get filters
  useEffect(() => {
    setIsLoadingPrograms(true);
    API.Organizations.getPrograms(organization.id, 'IN_COURSE')
      .then((response: any) => {
        setFilters(response);
        if (response.length) {
          setActiveProgram(response[0]);
        }
      })
      .finally(() => setIsLoadingPrograms(false));
  }, [organization.id]);

  const handlePaging = useCallback(({ pageSize, pageIndex }: any) => {
    setPageSize(pageSize);
    setPageIndex(pageIndex);
  }, []);

  const handleSorting = useCallback((data: any) => {
    const [sort] = data;

    if (sort) {
      const description = `${
        sort.id === 'period' ? 'period.name' : 'student.name'
      },${sort.desc ? 'desc' : 'asc'}`;
      setSorting(description);
    } else {
      setSorting('');
    }
  }, []);

  const handleSearch = useCallback((data: any) => {
    setKeyword(() => {
      setPageIndex(0);
      return data.search;
    });
  }, []);

  const handleDownload = useCallback(() => {
    setIsDownloading(true);

    API.Organizations.getStudents(
      organization.id,
      {
        keyword: keyword,
        program_id: activeProgram?.id,
        period: activePeriod?.id,
        calendar_id: activeCalendar?.id
      },
      'IN_COURSE',
      true
    ).finally(() => setIsDownloading(false));
  }, [
    activeCalendar?.id,
    activePeriod?.id,
    activeProgram?.id,
    keyword,
    organization.id
  ]);

  const handleSelect = useCallback(
    (data: any) => {
      const ids: string[] = data.map((item: any) => item.id);
      const unique = ids.filter((v, i, a) => a.indexOf(v) === i);

      if (!Utils.isExactlySame(unique, selection)) {
        setSelection(unique);
      }
    },
    [selection]
  );

  const handleSelectProgram = useCallback((program: any) => {
    setActiveProgram(program);
    setActivePeriod(null);
    setActiveCalendar(null);
  }, []);

  const handleSelectPeriod = useCallback(
    (period: any) => {
      setActivePeriod(period.id === activePeriod?.id ? null : period);
    },
    [activePeriod?.id]
  );

  const handleSelectCalendar = useCallback(
    (calendar: any) => {
      setActiveCalendar(calendar.id === activeCalendar?.id ? null : calendar);
    },
    [activeCalendar?.id]
  );

  const handleRowClick = useCallback(
    (row: any) => {
      const [first] = Utils.isArray(row) ? row : [row];
      const path = PATHS.get(
        'STUDENT_VIEW',
        { studentId: first.id },
        {
          [QPARAMS.Organization]: `id-${organization?.id}_name-${organization?.name}`
        }
      );
      push(path);
    },
    [organization?.id, organization?.name, push]
  );

  // const handleOpenAddModal = useCallback(() => {
  //   setOpenAddModal(true);
  // }, []);

  return (
    <>
      <SearchKeyword keyword={keyword} onSearch={handleSearch} />

      <Visible xs sm>
        {/* <Button
          block
          className='mb-2'
          loading={isLoading}
          onClick={handleOpenAddModal}>
          {i18n(
            'v2.pages.organizationDashboard.tabs.2.full.tabs.1.buttons.add'
          )}
        </Button> */}

        <Search
          name='search'
          allowEmptySearch
          value={keyword}
          onSubmit={handleSearch}
          disabled={isLoading || isLoadingPrograms}
        />
      </Visible>
      {selection.length > 0 ? (
        <Callout className='py-2 px-4 mb-2 bg-powder'>
          <Flex align='center' justify='between'>
            <span>
              <strong>{selection.length}</strong>{' '}
              {i18n(
                'v2.pages.organizationDashboard.tabs.2.full.tabs.1.table.selected'
              )}
            </span>

            {/* <Button variant='outline' loading={isLoading}>
              {i18n(
                'v2.pages.organizationDashboard.tabs.2.full.tabs.1.buttons.remove'
              )}
            </Button> */}
          </Flex>
        </Callout>
      ) : (
        <Flex className='mb-2' align='center' justify='between'>
          <div>
            {filters?.length ? (
              <Dropdown
                className='mr-1'
                disabled={isLoading || isLoadingPrograms}
                autoWidth
                style={{ maxWidth: '400px' }}
                toggle={
                  <Button
                    variant='filter'
                    caret
                    loading={isLoading || isLoadingPrograms}>
                    {activeProgram?.name ??
                      i18n(
                        'v2.pages.organizationDashboard.tabs.2.full.tabs.1.buttons.program'
                      )}
                  </Button>
                }>
                {filters?.map((item: any) => (
                  <Dropdown.Item
                    key={item.id}
                    active={activeProgram?.id === item.id}
                    onClick={() => handleSelectProgram(item)}>
                    {item.name}
                  </Dropdown.Item>
                ))}
              </Dropdown>
            ) : null}

            {activeProgram && activeProgram?.periods?.length ? (
              <Dropdown
                className='mr-1'
                disabled={isLoading || isLoadingPrograms}
                style={{ maxWidth: '220px' }}
                toggle={
                  <Button
                    variant='filter'
                    caret
                    loading={isLoading || isLoadingPrograms}
                    disabled={!activeProgram}>
                    {activePeriod?.name ??
                      i18n(
                        'v2.pages.organizationDashboard.tabs.2.full.tabs.1.buttons.period'
                      )}
                  </Button>
                }>
                {activeProgram?.periods?.map((item: any) => (
                  <Dropdown.Item
                    key={item.id}
                    active={activePeriod?.id === item.id}
                    onClick={() => handleSelectPeriod(item)}>
                    {item.name}
                  </Dropdown.Item>
                ))}
              </Dropdown>
            ) : null}

            {activeProgram && activeProgram?.calendars?.length ? (
              <Dropdown
                disabled={isLoading || !activeProgram || isLoadingPrograms}
                style={{ maxWidth: '220px' }}
                toggle={
                  <Button
                    variant='filter'
                    caret
                    loading={isLoading || isLoadingPrograms}
                    disabled={!activeProgram}>
                    {activeCalendar?.dates ??
                      i18n(
                        'v2.pages.organizationDashboard.tabs.2.full.tabs.1.buttons.calendar'
                      )}
                  </Button>
                }>
                {activeProgram?.calendars?.map((item: any) => (
                  <Dropdown.Item
                    key={item.id}
                    active={activeCalendar?.id === item.id}
                    onClick={() => handleSelectCalendar(item)}>
                    {item.dates}
                  </Dropdown.Item>
                ))}
              </Dropdown>
            ) : null}
          </div>

          <Visible md lg xl>
            <div>
              <Button
                variant='clear'
                className='ml-2'
                disabled={isLoading || isLoadingPrograms}
                loading={isDownloading}
                onClick={handleDownload}
                icon={<Icons.Download />}>
                {i18n(
                  'v2.pages.organizationDashboard.tabs.2.full.tabs.1.buttons.download'
                )}
              </Button>

              {/* <Button loading={isLoading} onClick={handleOpenAddModal}>
                {i18n(
                  'v2.pages.organizationDashboard.tabs.2.full.tabs.1.buttons.add'
                )}
              </Button> */}
            </div>
          </Visible>
        </Flex>
      )}
      <Visible xs sm>
        <Table
          data={data}
          // selectable
          columns={mobileColumns}
          loading={isLoading || isLoadingPrograms}
          pageable={false}
          manualSorting
          onSorting={handleSorting}
          onSelect={handleSelect}
          clickable
          onRowClick={handleRowClick}
        />
      </Visible>
      <Visible md lg xl>
        <Table
          data={data}
          // selectable
          groupByField='id'
          columns={desktopColumns}
          loading={isLoading || isLoadingPrograms}
          pageable={false}
          manualSorting
          onSorting={handleSorting}
          onSelect={handleSelect}
          clickable
          onRowClick={handleRowClick}
        />
      </Visible>

      <Row>
        <Visible lg xl>
          <Col xs={3} />
        </Visible>

        <Col>
          <Pagination
            align='center'
            className='my-4'
            onUpdate={handlePaging}
            pageIndex={pageIndex}
            pageCount={pageCount}
            disabled={isLoading || isLoadingPrograms}
          />
        </Col>

        <Visible md lg xl>
          <Col xs={3} className='d-flex align-items-center justify-content-end'>
            {isLoading || isLoadingPrograms ? (
              <Skeleton width={120} wrapper='small' />
            ) : (
              <small>
                <strong>{total}</strong>{' '}
                {i18n(
                  'v2.pages.organizationDashboard.tabs.2.full.tabs.1.table.count'
                )}
              </small>
            )}
          </Col>
        </Visible>
      </Row>

      <ModalAddStudent open={openAddModal} onClose={setOpenAddModal} />
    </>
  );
}

// <Dropdown
//   disabled={selection.length === 0}
//   className='w-100'
//   toggleClassName='w-100'
//   autoWidth
//   toggle={
//     <Button
//       block
//       variant='dropdown'
//       caret
//       caretDirection='up'
//       disabled={selection.length === 0}>
//       Acciones
//     </Button>
//   }
//   position='top'>
//   <Dropdown.Item>Eliminar</Dropdown.Item>
//   <Dropdown.Item>Retirar</Dropdown.Item>
// </Dropdown>
