import esHN from './es-HN';

const enHN = {
  ...esHN,
  lang: `en-HN,es`,
  shortLang: 'en-HN'
};

export default enHN;

export { enHN };
