import { i18n } from 'dawere-commons';
import { Block, Col, Iframe } from 'dawere-uic';

export function FoundationsSection() {
  return (
    <Block>
      <Col md={6} className='mb-4 mb-md-0'>
        <h5 className='text-primary text-uppercase'>
          {i18n('v2.pages.organizations.section4.foundations.subtitle')}
        </h5>
        <h2 className='mb-4'>
          {i18n('v2.pages.organizations.section4.foundations.title')}
        </h2>
        <div
          dangerouslySetInnerHTML={{
            __html: i18n('v2.pages.organizations.section4.foundations.text')
          }}
        />
      </Col>

      <Col md={6}>
        <Iframe
          height={380}
          className='shadow rounded-8 d-inline-block'
          src={i18n(
            'v2.pages.organizations.section4.foundations.urls.video.url'
          )}
        />
      </Col>
    </Block>
  );
}
