import { Utils } from 'dawere-uic';
import { SessionContext } from 'providers';
import { useContext } from 'react';

export const useSession = () => {
  const context = useContext(SessionContext);

  if (Utils.isEmpty(context)) {
    throw new Error('"useSession" must be used within a SessionProvider');
  }

  return context;
};
