import { i18n } from 'dawere-commons';
import { Button, Callout, Col, Row } from 'dawere-uic';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { ASSETS, QPARAMS } from 'values';
import { useStudentDashboard } from '../../hooks/useStudentDashboard';

export function FinishedCoursePanel({ program }: any) {
  const { rootRoute, studentId } = useStudentDashboard();

  return (
    <Row>
      <Col>
        <Callout className='text-center border border-platinium bg-white'>
          <Row align='center'>
            <Col>
              <h2>
                {i18n('v2.pages.studentDashboard.tabs.1.finishedCourse.title')}
              </h2>

              <p className='fs-1 text-muted'>
                {i18n(
                  'v2.pages.studentDashboard.tabs.1.finishedCourse.description',
                  { degree: program?.degreeName }
                )}
              </p>

              <Button
                className='w-100 w-md-auto'
                as={Link}
                to={PATHS.get(
                  rootRoute,
                  { studentId },
                  {
                    [QPARAMS.Tab]: 2
                  }
                )}>
                {i18n('v2.pages.studentDashboard.tabs.1.finishedCourse.button')}
              </Button>
            </Col>
            <Col className='order-first order-md-last | mb-4 mb-md-0'>
              <img
                src={ASSETS.ILLUSTRATIONS.HANDS_DIPLOMAS}
                alt={i18n(
                  'v2.pages.studentDashboard.tabs.1.finishedCourse.title'
                )}
                className='img-fluid'
              />
            </Col>
          </Row>
        </Callout>
      </Col>
    </Row>
  );
}
