import { i18n } from 'dawere-commons';
import { Button, Callout, Col, Row } from 'dawere-uic';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { ASSETS } from 'values';

export function PendingProfilePanel() {
  return (
    <Row align='center'>
      <Col>
        <Callout className='text-center border border-platinium bg-white'>
          <img
            src={ASSETS.ILLUSTRATIONS.PROFILE}
            alt=''
            className='img-fluid w-70 w-md-20 mb-4'
          />

          <h3>
            {i18n('v2.pages.studentDashboard.tabs.1.pendingProfile.title')}
          </h3>

          <p>
            {i18n(
              'v2.pages.studentDashboard.tabs.1.pendingProfile.description'
            )}
          </p>
          <Button className='w-100 w-md-auto' as={Link} to={PATHS.PROFILE}>
            {i18n('v2.pages.studentDashboard.tabs.1.pendingProfile.button')}
          </Button>
        </Callout>
      </Col>
    </Row>
  );
}
