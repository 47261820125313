import { API } from 'api';
import { BillingInfoForm } from 'components';
import { useGeo } from 'hooks';
import { useCallback, useState } from 'react';
import { useBuyCoupons } from '../hooks/useBuyCoupons';

export function BillingForm() {
  const {
    organization,
    setBillingInfo,
    summary,
    setSummary,
    billingInfo,
    isBillingInfoCompleted
  } = useBuyCoupons();
  const picklists = useGeo(billingInfo?.country, billingInfo?.state);

  const [isEditing, setIsEditing] = useState(false);

  const handleSubmit = useCallback(
    (data: any) => {
      setBillingInfo(data);
      setIsEditing(false);

      if (summary?.id) {
        // Lista de descuentos previos aplicados
        const discounts = summary?.discounts.map((d: any) => d.coupon);

        API.Cart.updateOrder(organization?.id, summary?.id, {
          discountCodes: discounts,
          taxData: {
            address: data.billingAddress,
            name: data.name,
            postalCode: data.postalCode,
            tin: data.tin,
            township: {
              id: data.township?.value
            }
          }
        }).then((response: any) => {
          setSummary(response);
        });
      }
    },
    [
      setBillingInfo,
      summary?.id,
      summary?.discounts,
      organization?.id,
      setSummary
    ]
  );

  const handleCancel = useCallback(
    (data: any) => {
      setBillingInfo(organization);
      setIsEditing(false);
    },
    [organization, setBillingInfo]
  );

  return (
    <BillingInfoForm
      data={billingInfo}
      editing={isEditing}
      onEditing={setIsEditing}
      picklists={picklists}
      showAlert={!isBillingInfoCompleted()}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onChange={setBillingInfo}
    />
  );
}
