import { Rest } from 'dawere-commons';
import { Utils } from 'dawere-uic';

function _processTeachersResponse(
  response: any,
  status: 'ACTIVE' | 'PENDING' = 'ACTIVE'
) {
  let data = [];

  if (status === 'ACTIVE') {
    data = response.data.content.map((el: any) => {
      const { user } = el;
      const fullName = Utils.getFullname(user.name, user.lastName);
      const phoneNumber = Utils.joinStrings(user.phoneCode, user.phoneNumber);

      return {
        id: user.id,
        name: fullName,
        email: user.email,
        avatar: user.picturePath,
        phoneNumber
      };
    });
  }

  if (status === 'PENDING') {
    data = response.data.content.map((el: any) => {
      return {
        id: el.id,
        email: el.email,
        date: Utils.formatDate(el.invitationDate, 'dd/mmm/yyyy')
      };
    });
  }

  return {
    data: data,
    totalPages: response.data.totalPages,
    totalRows: response.data.totalElements
  };
}

function list(organizationId: string, params: any = {}) {
  return Rest.get(`my-organizations/${organizationId}/teachers`, {
    params
  }).then((response: any) => {
    return Utils.has(params, 'simple')
      ? response.data
      : _processTeachersResponse(response, 'ACTIVE');
  });
}

function getPending(organizationId: string, params: any = {}) {
  return Rest.get(`my-organizations/${organizationId}/teacher-invitations`, {
    params
  }).then((response: any) => {
    return Utils.has(params, 'simple')
      ? response.data
      : _processTeachersResponse(response, 'PENDING');
  });
}

function remove(organizationId: string, teacherId: string) {
  return Rest.delete(
    `my-organizations/${organizationId}/teachers/${teacherId}`
  );
}

function assign(organizationId: string, data: any = {}) {
  return Rest.post(`my-organizations/${organizationId}/lineups`, data);
}

function unassign(organizationId: string, assigmentId: string) {
  return Rest.delete(
    `my-organizations/${organizationId}/lineups/${assigmentId}`
  );
}

function invite(organizationId: string, data: any = {}) {
  return Rest.post(
    `my-organizations/${organizationId}/teacher-invitations`,
    data
  );
}

function getSubjects(id: string) {
  return Rest.get(`my-organizations/${id}/subjects`).then(({ data }: any) => {
    const grouped = Utils.groupBy(
      data?.subjects || [],
      (el: any) => el.program.id
    );

    const programs = Object.values(grouped).map(([value]: any) => {
      return {
        ...value.program,
        subjects: value.subject
      };
    });

    return {
      organization: data.organization,
      programs
    };
  });
}

export const Teachers = {
  list,
  getPending,
  remove,
  assign,
  unassign,
  invite,
  getSubjects
};
