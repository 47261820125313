import { Session, i18n } from 'dawere-commons';
import { Button } from 'dawere-uic';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { QPARAMS } from 'values';

export function EnrollButton({
  onClick = () => {},
  programId,
  program,
  isLoadingMyPrograms,
  status,
  currencyId
}: any) {
  if (status === 'ACTIVE') {
    return (
      <Button
        variant='outline'
        block
        as={Link}
        loading={isLoadingMyPrograms}
        to={PATHS.get(
          'STUDENTS_DASHBOARD',
          {},
          { [QPARAMS.Program]: programId }
        )}>
        {i18n('v2.pages.program.details.buttons.studentDashboard')}
      </Button>
    );
  }

  if (!Session.getAccountInfo()) {
    return (
      <Button
        variant='outline'
        block
        as={Link}
        onClick={onClick}
        loading={isLoadingMyPrograms}
        to={PATHS.get('REGISTER', null, {
          [QPARAMS.Redirect]: encodeURIComponent(
            PATHS.get('ENROLL', {
              programId,
              currencyId
            })
          )
        })}>
        {i18n('v2.pages.subject.details.buttons.enroll')}
      </Button>
    );
  }

  return (
    <Button
      variant='outline'
      block
      as={Link}
      onClick={onClick}
      loading={isLoadingMyPrograms}
      to={PATHS.get('ENROLL', {
        programId,
        currencyId
      })}>
      {i18n('v2.pages.subject.details.buttons.enroll')}
    </Button>
  );
}
