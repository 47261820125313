import { useRouter, Utils } from 'dawere-uic';
import { useCallback } from 'react';
import { PATHS } from 'router';
import { autofill, QPARAMS } from 'values';

export function usePath() {
  const { query } = useRouter();

  const getPathWithRedirect = useCallback(
    (key: string, params: any = {}, search: any = {}) => {
      // Get redirect attr
      const redirect = Utils.has(query, QPARAMS.Redirect)
        ? autofill(query, [QPARAMS.Redirect])
        : {};
      // Merge redirect and search
      const queryString = { ...redirect, ...search };
      // Get final query string
      const qs = new URLSearchParams(queryString).toString();
      // Get final path
      const path = PATHS.get(key, params);

      return `${path}${qs ? `?${qs}` : ''}`;
    },
    [query]
  );

  return { getPathWithRedirect };
}
