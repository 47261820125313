import { i18n } from 'dawere-commons';
import {
  Affix,
  Card,
  Checkbox,
  Col,
  Divider,
  Flex,
  Icons,
  Radio,
  RadioGroup,
  Row,
  Search,
  Skeleton,
  Tooltip,
  Utils
} from 'dawere-uic';
import { useCallback } from 'react';
import { getTotals } from 'utils';

export function OrderBox({
  loading = false,
  loadingDiscount = false,
  removingDiscount = false,
  lines = [],
  discounts = [],
  installments = [],
  taxes = {},
  title = i18n('v2.pages.buyCoupons.steps.2.summary.title'),
  totalLabel = i18n('v2.pages.buyCoupons.steps.2.summary.total'),
  currency = {},
  total = 0,
  taxBase = 0,
  admitCoupons = false,
  onSubmitDiscount = () => {},
  onRemoveDiscount = () => {},
  className = '',
  admitPartialPayment = false,
  isEarlyPayment = false,
  onEarlyPaymentChange = (value: boolean) => {},
  earlyPaymentDiscount = 0,
  detail = false,
  totalDiscounts = 0,
  walletBalance = null,
  onInstallmentsChange = () => {},
  ...rest
}: any) {
  const handleSubmitDiscount = useCallback(
    (data: any) => {
      const coupon = data.coupon.trim().toUpperCase();
      const list = discounts.map((d: any) => d.coupon.trim().toUpperCase());

      if (list.includes(coupon)) {
        return;
      }

      onSubmitDiscount(coupon);
    },
    [discounts, onSubmitDiscount]
  );

  const finalTotals = getTotals(total, installments, walletBalance);

  const handleInstallmentSelection = useCallback(
    (installment: any, checked: boolean) => {
      if (checked) {
        installments.forEach((i: any) => {
          if (i.number <= installment.number) {
            i.checked = true;
          } else {
            i.checked = false;
          }
        });
      } else {
        installments.forEach((i: any) => {
          if (i.number >= installment.number) {
            i.checked = false;
          }
        });
      }

      onInstallmentsChange(installments);
    },
    [installments, onInstallmentsChange]
  );

  return (
    <Card className={className} {...rest}>
      {/* If admit partial payments or not */}
      {admitPartialPayment ? (
        <div className='bg-powderTint p-3 p-md-4'>
          <strong className='d-block mb-3'>
            {i18n('v2.pages.enrolment.steps.3.summary.isFullPayment')}

            <Tooltip text={i18n('v2.pages.enrolment.steps.3.summary.tooltip')}>
              <span className='ml-2'>
                <Icons.Info filled />
              </span>
            </Tooltip>
          </strong>
          <RadioGroup
            value={`${isEarlyPayment}`}
            onChange={onEarlyPaymentChange}>
            <div className='d-flex'>
              <Radio
                className='mr-4'
                optionWrapperClassName='align-items-start'
                name='isFullPayment'
                id='isFullPayment'
                value='false'
                label={i18n(
                  'v2.pages.enrolment.steps.3.summary.partialPayment'
                )}
                disabled={loading || loadingDiscount || removingDiscount}
              />

              <Radio
                optionWrapperClassName='align-items-start'
                name='isFullPayment'
                id='isFullPayment'
                value='true'
                label={i18n('v2.pages.enrolment.steps.3.summary.fullPayment')}
                disabled={loading || loadingDiscount || removingDiscount}
              />
            </div>
          </RadioGroup>
        </div>
      ) : null}

      <div className='p-3 p-md-4'>
        <h4 className='mb-4'>{title}</h4>

        {loading ? (
          <>
            {Array(3)
              .fill(0)
              .map((_, index: number) => (
                <Row key={index} className='mb-2'>
                  <Col>
                    <Skeleton height={30} />
                    <Skeleton width='50%' />
                  </Col>
                  <Col xs={4}>
                    <Skeleton />
                  </Col>
                </Row>
              ))}

            <Divider margin={3} className='border-light' />

            <Row className='mb-2'>
              <Col></Col>
              <Col xs={6} className='text-right'>
                <Skeleton width='80%' />
                <Skeleton height={45} />
              </Col>
            </Row>
          </>
        ) : (
          <>
            {/* Líneas */}
            {lines.map((line: any, index: number) => (
              <Row key={index} className='mb-2'>
                <Col>
                  <strong className='d-block'>{line.description} </strong>
                  {line.qty} x {currency?.symbol}
                  {Utils.toCurrency(line.listPrice)}
                </Col>

                <Col xs={4} className='text-right fw-500'>
                  {currency?.symbol}
                  {Utils.toCurrency(line.listPrice * line.qty)}
                </Col>
              </Row>
            ))}

            <Divider margin={3} />

            {/* Pronto-pago */}
            {earlyPaymentDiscount ? (
              <>
                <Row>
                  <Col>
                    {i18n(
                      'v2.pages.buyCoupons.steps.2.summary.earlyPaymentDiscount'
                    )}{' '}
                  </Col>

                  <Col xs={4} className='text-right fw-500'>
                    -{currency?.symbol}
                    {Utils.toCurrency(earlyPaymentDiscount)}
                  </Col>
                </Row>

                <Divider margin={3} />
              </>
            ) : null}

            {/* Discounts */}
            {detail ? (
              <>
                {totalDiscounts ? (
                  <>
                    <Row className='mb-2'>
                      <Col>
                        {i18n('v2.pages.buyCoupons.steps.2.summary.discounts')}
                      </Col>
                      <Col xs={4} className='text-right fw-500'>
                        -{currency?.symbol}
                        {Utils.toCurrency(totalDiscounts)}
                      </Col>
                    </Row>

                    <Divider margin={3} />
                  </>
                ) : null}
              </>
            ) : (
              <>
                {loadingDiscount ? (
                  <>
                    <Row className='mb-2'>
                      <Col>
                        <Skeleton height={30} />
                        <Skeleton width='50%' />
                      </Col>
                      <Col xs={4}>
                        <Skeleton />
                      </Col>
                    </Row>

                    <Divider margin={3} />
                  </>
                ) : (
                  <>
                    {discounts.map((discount: any, index: number) => (
                      <Row key={index} className='mb-2'>
                        <Col>
                          <Flex align='center'>
                            {admitCoupons ? (
                              <Icons.Close
                                className='mr-3 cursor-pointer anchor'
                                onClick={() => onRemoveDiscount(discount)}
                              />
                            ) : null}
                            <div>
                              <strong className='d-block'>
                                {discount.description}
                              </strong>
                              {discount.coupon}
                            </div>
                          </Flex>
                        </Col>
                        <Col xs={4} className='text-right fw-500'>
                          -{currency?.symbol}
                          {Utils.toCurrency(discount.amount)}
                        </Col>
                      </Row>
                    ))}

                    {discounts?.length ? <Divider margin={3} /> : null}
                  </>
                )}
              </>
            )}

            {/* Amount before taxes */}
            <Row>
              <Col>
                {i18n('v2.pages.buyCoupons.steps.2.summary.beforeTaxes')}
              </Col>
              <Col xs={4} className='text-right fw-500'>
                {loadingDiscount || removingDiscount ? (
                  <>
                    <Skeleton />
                  </>
                ) : (
                  <>
                    {currency?.symbol}
                    {Utils.toCurrency(taxBase)}
                  </>
                )}
              </Col>
            </Row>

            {/* Taxes */}
            {Object.entries(taxes || {}).map(
              ([key, value]: any, index: number) => (
                <Row key={index}>
                  <Col>{key}</Col>
                  <Col xs={4} className='text-right fw-500'>
                    {loadingDiscount || removingDiscount ? (
                      <>
                        <Skeleton />
                      </>
                    ) : (
                      <>
                        {currency?.symbol}
                        {Utils.toCurrency(value)}
                      </>
                    )}
                  </Col>
                </Row>
              )
            )}

            <Divider margin={3} />

            {/* Instalments */}
            {installments.length > 1 ? (
              <>
                {installments.map((i: any) => (
                  <Flex key={i.id} className='mb-2'>
                    <Flex full>
                      <Checkbox
                        name={`installment-${i.number}`}
                        disabled={i.number === 1 || loading}
                        value={i.checked}
                        onChange={(e) =>
                          handleInstallmentSelection(i, e.target.checked)
                        }
                        label={
                          <div>
                            <strong className='text-text'>
                              {i18n(
                                'v2.pages.buyCoupons.steps.2.summary.installment',
                                {
                                  number: i.number
                                }
                              )}
                            </strong>
                            <small
                              className={Utils.styles(
                                'd-block',
                                i.status === 'PENDING' && i.due
                                  ? 'text-primary'
                                  : ''
                              )}>
                              {i18n(
                                `v2.pages.buyCoupons.steps.2.summary.${
                                  i.status === 'PENDING' && i.due
                                    ? 'payToday'
                                    : 'payLater'
                                }`,
                                {
                                  date: Utils.formatDate(
                                    i.dueDate,
                                    'dd/mmm/yyyy'
                                  )
                                }
                              )}
                            </small>
                          </div>
                        }
                      />
                    </Flex>
                    <Flex kept className='text-right fw-500'>
                      {currency?.symbol}
                      {Utils.toCurrency(i.amount * total)}
                    </Flex>
                  </Flex>
                ))}

                <Divider margin={3} />
              </>
            ) : null}

            {/* Wallet */}
            {walletBalance ? (
              <>
                <Row>
                  <Col>
                    {i18n('v2.sections.navbar.links.wallet')} (
                    {currency?.symbol}
                    {Utils.toCurrency(walletBalance)})
                  </Col>
                  <Col xs={4} className='text-right fw-500'>
                    {loadingDiscount || removingDiscount ? (
                      <>
                        <Skeleton />
                      </>
                    ) : (
                      <>
                        -{currency?.symbol}
                        {Utils.toCurrency(finalTotals?.usedWalletAmount)}
                      </>
                    )}
                  </Col>
                </Row>

                <Divider margin={3} />
              </>
            ) : null}

            {/* Total */}
            <div className='text-right'>
              <h5 className='m-0 text-primary'>
                {totalLabel} ({currency?.isoCode})
              </h5>
              {loadingDiscount || removingDiscount ? (
                <>
                  <Skeleton height={45} width='60%' />
                </>
              ) : (
                <h3 className='fw-500 m-0 text-primary'>
                  {currency?.symbol}
                  {Utils.toCurrency(finalTotals?.total)}
                </h3>
              )}
            </div>

            {/* Add coupons */}
            {admitCoupons && finalTotals?.total > 0 ? (
              <div className='mt-5'>
                <p className='mb-2'>
                  {i18n('v2.pages.buyCoupons.steps.2.summary.hasCoupon')}
                </p>
                <Search
                  onSubmit={handleSubmitDiscount}
                  name='coupon'
                  placeholder={i18n(
                    'v2.pages.buyCoupons.steps.2.summary.placeholder'
                  )}
                  validators={{
                    required: true,
                    pattern: {
                      value: /^(?![_])([a-z0-9_\s]*)[a-z0-9\s]$$/i,
                      message: i18n(
                        'ui.components.forms.errors.invalidCouponCode'
                      )
                    }
                  }}
                  className='mb-2'
                  disabled={loadingDiscount || removingDiscount}
                  clearAfterSubmit
                  icon={<Icons.Plus />}
                />
              </div>
            ) : null}
          </>
        )}
      </div>
    </Card>
  );
}

export function PaymentBar({ children }: any) {
  return (
    <Col md={5}>
      <div className='bg-cultured rounded-3 h-100'>
        <Affix top={70} bottomBoundary='#summary-content' innerClass='p-3'>
          {children}
        </Affix>
      </div>
    </Col>
  );
}
