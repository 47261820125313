import { i18n } from 'dawere-commons';
import { Alert, Col, Empty, Radio, RadioGroup, Row } from 'dawere-uic';
import { useCallback } from 'react';
import { useEnroll } from '../hooks/useEnroll';

export function PeriodsPicker() {
  const { periods, period, setPeriod, documents } = useEnroll();

  const handleSelection = useCallback(
    (period: any) => {
      setPeriod(period);
    },
    [setPeriod]
  );

  return (
    <Row>
      <Col xs={12} md={7}>
        <h2 className='mb-4'>
          {i18n('v2.pages.enrolment.steps.2.description')}
        </h2>

        {periods.length ? (
          <RadioGroup value={period} onChange={handleSelection}>
            {periods.map((period: any, index: number) => (
              <Radio
                key={index}
                name='period'
                value={period.id}
                label={period.name}
                className='mb-4'
              />
            ))}
            <Radio
              name='period'
              value='Empty'
              label={i18n('v2.pages.enrolment.steps.2.lastOption')}
              className='mb-4'
            />
          </RadioGroup>
        ) : (
          <Empty />
        )}
      </Col>

      <Col xs={12} md={5}>
        {documents.length ? (
          <Alert
            icon='warning'
            title={i18n('v2.pages.enrolment.steps.2.alertDocs.title')}
            layout='vertical'>
            {i18n('v2.pages.enrolment.steps.2.alertDocs.p1')}
            <ul>
              {documents.map((document: any, index: number) => (
                <li key={index}>{document.name}</li>
              ))}
            </ul>
            <p>{i18n('v2.pages.enrolment.steps.2.alertDocs.p2')}</p>
          </Alert>
        ) : null}
      </Col>
    </Row>
  );
}
