import { i18n } from 'dawere-commons';
import { Breadcrumbs, Skeleton } from 'dawere-uic';
import { useProgram } from 'pages/public/program/hooks/useProgram';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { useSubject } from '../hooks/useSubject';

export function Heading({ isCourse = false }: any) {
  const { isLoading: loadingProgram, program } = useProgram();
  const { isLoading: loadingSubject, subject, programId } = useSubject();

  return (
    <>
      <Breadcrumbs
        as={Link}
        loading={loadingProgram}
        routes={[
          {
            breadcrumb: i18n('v2.pages.subject.breadcrumbs.1'),
            to: PATHS.CATALOG
          },
          {
            breadcrumb: program?.name,
            to: PATHS.get(isCourse ? 'COURSE' : 'PROGRAM', { programId })
          },
          {
            breadcrumb: i18n('v2.pages.subject.breadcrumbs.3')
          }
        ]}
      />

      {loadingSubject ? (
        <div>
          <Skeleton.Title />
        </div>
      ) : (
        <h1 className='mb-4'>
          {subject?.title} {subject?.period.name}
        </h1>
      )}
      {loadingProgram ? (
        <>
          <Skeleton />
        </>
      ) : (
        <>
          <p>
            {i18n(
              'v2.pages.subject.section1.description' + (isCourse ? '2' : '1')
            )}{' '}
            <strong>{program?.name}</strong>.
          </p>
        </>
      )}
    </>
  );
}
