import { Rest } from 'dawere-commons';
import { Utils } from 'dawere-uic';
import { Documents } from './documents';

function generateDocumentsReport(
  organizationId: string,
  params: any = {},
  download: boolean = false
) {
  if (download) {
    const fileName = `report-documents.csv`;

    return Rest.get(
      `my-organizations/${organizationId}/reports/enrolment-documents.csv`,
      {
        params: {
          ...params
        },
        responseType: 'blob'
      }
    ).then((response) => {
      Utils.saveBlob(response.data, fileName);
    });
  }

  return Rest.get(
    `my-organizations/${organizationId}/reports/enrolment-documents`,
    {
      params
    }
  ).then((response) => {
    let data: any[] = [];

    data = response.data.content.map((el: any) => {
      const { student, documents } = el;

      return {
        id: student.id,
        name: Utils.titleize(student.name),
        lastName: Utils.titleize(student.lastName),
        avatar: student.picturePath,
        identification: student.userDetails?.identityCard || student.email,
        email: student.email,
        documents: documents.map((doc: any) => {
          return {
            id: doc.id,
            status: Documents.getStatus(doc.status),
            name: doc.documentType?.name,
            mandatory: doc.collection?.mandatory,
            physical: doc.collection?.physicallyRequired
          };
        })
      };
    });

    return {
      data,
      totalPages: response.data.totalPages,
      totalRows: response.data.totalElements
    };
  });
}

function generateTranscriptsReport(
  organizationId: string,
  params: any = {},
  download: boolean = false
) {
  if (download) {
    const fileName = `report-progress.csv`;

    return Rest.get(`kpis/my-organizations/${organizationId}/progress.csv`, {
      params: {
        ...params
      },
      responseType: 'blob'
    }).then((response) => {
      Utils.saveBlob(response.data, fileName);
    });
  }

  return Rest.get(`kpis/my-organizations/${organizationId}/progress`, {
    params
  }).then((response) => {
    return {
      data: response.data.content,
      totalPages: response.data.totalPages,
      totalRows: response.data.totalElements
    };
  });
}

export const Reports = {
  generateDocumentsReport,
  generateTranscriptsReport
};
