import { useRouter, Visible } from 'dawere-uic';
import { PageWrapper } from 'pages';
import { ProgramProvider } from './ProgramProvider';
import { ContentSection } from './sections/ContentSection';
import { DegreeSection } from './sections/DegreeSection';
import { Details } from './sections/Details';
import { Heading } from './sections/Heading';
import { RequirementsSection } from './sections/RequirementsSection';

/**
 * Path: /programa/:programId
 * Key: PROGRAM
 * @returns {React.ReactElement}
 */
export default function ProgramPage() {
  const { query } = useRouter();

  return (
    <PageWrapper disableCountryPicker>
      <ProgramProvider id={query?.programId}>
        {/* Details */}
        <Heading programId={query?.programId}>
          {/* SEO inside */}
          <Details programId={query?.programId} />
        </Heading>

        {/* Details mobile */}
        <Visible xs sm>
          <Details programId={query?.programId} />
        </Visible>

        {/* Degrees */}
        <DegreeSection programId={query?.programId} />

        {/* Requirements */}
        <RequirementsSection />

        {/* Content */}
        <ContentSection programId={query?.programId} />
      </ProgramProvider>
    </PageWrapper>
  );
}
