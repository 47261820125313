import { i18n } from 'dawere-commons';
import { Breadcrumbs, Col, useRouter } from 'dawere-uic';
import { useCallback, useEffect } from 'react';
import { Link, Prompt } from 'react-router-dom';
import { PATHS } from 'router';
import { useFreeEnroll } from '../hooks/useFreeEnroll';
import { Checkout } from './Checkout';

const initBeforeUnLoad = (showExitPrompt: boolean) => {
  window.onbeforeunload = (event) => {
    // Show prompt based on state
    if (showExitPrompt) {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = '';
      }
      return '';
    }
  };
};

export function EnrollmentWizard() {
  const { isPaid } = useFreeEnroll();
  const { history } = useRouter();

  const handleClick = useCallback(
    (e: any) => {
      e.preventDefault();
      history?.goBack();
    },
    [history]
  );

  // Initialize the beforeunload event listener after the resources are loaded
  window.onload = function () {
    initBeforeUnLoad(true);
  };

  // Re-Initialize the onbeforeunload event listener
  useEffect(() => {
    initBeforeUnLoad(true);
    return () => initBeforeUnLoad(false);
  }, []);

  return (
    <Col xs={12} md={7}>
      <Breadcrumbs
        as={Link}
        withBackArrow
        onBackClick={handleClick}
        routes={[
          {
            breadcrumb: i18n('ui.commons.exit'),
            isBack: true,
            to: PATHS.STUDENTS_DASHBOARD
          }
        ]}
        className='mb-4'
      />

      <Checkout />

      <Prompt
        when={!isPaid}
        message={() => i18n('v2.pages.enrolment.leavePage')}
      />
    </Col>
  );
}
