import { Block, useRouter, Visible } from 'dawere-uic';
import { PageWrapper } from 'pages';
import { getSEO } from 'utils';
import { QPARAMS } from 'values';
import { EnrollProvider } from './EnrollProvider';
import { MainWrapper } from './sections/MainWrapper';
import { SummarySidebar } from './sections/SummarySidebar';

/**
 * Path: /inscripcion/:programId/m/:currencyId
 * Key: ENROLL
 * @returns {React.ReactElement}
 */
export default function EnrollPage() {
  const { query } = useRouter();

  return (
    <PageWrapper disableCountryPicker>
      {getSEO('v2.pages.enrolment.seo', true)}

      <EnrollProvider
        programId={query?.programId}
        currencyId={query?.currencyId}
        studentId={query[QPARAMS.Student]}
        redirectTo={query[QPARAMS.Redirect]}
        earlyPayment={query[QPARAMS.EarlyPayment] === '1'}>
        <div id='summary-content'>
          <Block narrow align='stretch'>
            <MainWrapper />

            {/* Barra gris del summary para desktop */}
            <Visible md lg xl>
              <SummarySidebar />
            </Visible>
          </Block>
        </div>
      </EnrollProvider>
    </PageWrapper>
  );
}
