import { i18n, Rest } from 'dawere-commons';
import { Badge, Card, Col, Container, Row, Skeleton } from 'dawere-uic';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import styled from 'styled-components';
import { trackEvent } from 'utils';

const PrimaryCardStyled = styled(Card)`
  padding: var(--dw-spacer-5) var(--dw-spacer-3);
  /* margin: var(--dw-spacer-1); */
  width: 100%;
  border: solid 2px var(--dw-color-lightGray);

  &:hover {
    border: solid 2px var(--dw-color-primary);
  }

  .thumbnail {
    padding-bottom: var(--dw-spacer-4);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
      max-width: 270px;
    }
  }

  .details {
    border-top: solid 1px var(--dw-color-platinium);
    padding: var(--dw-spacer-4) 0 0;
    height: 100%;
  }

  a {
    color: var(--dw-color-text);

    &:hover {
      color: var(--dw-color-text);
      text-decoration: none;
    }
  }

  @media (min-width: 768px) {
    .details {
      border-top: none;
      border-left: solid 1px var(--dw-color-platinium);
      padding: 0 var(--dw-spacer-4);
    }

    .thumbnail {
      padding-bottom: 0;
    }
  }
`;

export function PrimaryCard({
  resourceId,
  title,
  thumbnail,
  url,
  friendlyPath
}: any) {
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (url && !data) {
      setIsLoading(true);

      Rest.get(url)
        .then(({ data }) => {
          setData(data);
        })
        .finally(() => setIsLoading(false));
    }
  }, [data, url]);

  const handleClick = useCallback(() => {
    trackEvent('program_clicks', { program_name: title });
  }, [title]);

  return (
    <div className='p-2 h-100'>
      <PrimaryCardStyled className='bezier h-100'>
        <Link
          to={PATHS.get('PROGRAM', { programId: friendlyPath })}
          onClick={handleClick}>
          <Container fluid className='h-100'>
            <Row className='h-100'>
              <Col xs={12} md={4} className='text-center'>
                <div className='thumbnail'>
                  <img src={thumbnail} alt={title} className='img-fluid w-85' />

                  {data?.freeTrial && (
                    <Badge small pilled variant='tertiary' className='mt-3'>
                      {i18n('v2.pages.home.section5.buttons.enroll')}
                    </Badge>
                  )}
                </div>
              </Col>

              <Col xs={12} md={8}>
                <div className='details'>
                  <h3 className='mb-4'>{title}</h3>

                  <Row>
                    <Col xs={12} md={8} className='mb-3'>
                      <small className='d-block text-spanish'>
                        {i18n('v2.pages.catalog.primary.degree')}
                      </small>
                      {isLoading ? <Skeleton /> : data?.degreeName || '-'}
                    </Col>

                    <Col xs={12} md={4} className='mb-3'>
                      <small className='d-block text-spanish'>
                        {i18n('v2.pages.catalog.primary.language')}
                      </small>
                      {isLoading ? <Skeleton /> : data?.language || '-'}
                    </Col>

                    <Col xs={12} md={8} className='mb-3'>
                      <small className='d-block text-muted'>
                        {i18n('v2.pages.catalog.primary.school')}
                      </small>
                      {isLoading ? <Skeleton /> : data?.school?.name || '-'}
                    </Col>

                    <Col xs={12} md={4}>
                      <small className='d-block text-spanish'>
                        {i18n('v2.pages.catalog.primary.duration')}
                      </small>
                      {isLoading ? (
                        <Skeleton width={100} />
                      ) : (
                        data?.duration || '-'
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </Link>
      </PrimaryCardStyled>
    </div>
  );
}
