import { API } from 'api';
import { i18n, Session } from 'dawere-commons';
import { Button, Form, Input, Modal, toast, Utils } from 'dawere-uic';
import { useCallback, useState } from 'react';

export function ModalAddTutor({ open, onClose, onSuccess = () => {} }: any) {
  const userEmail = Session.getAccountInfo()?.email;

  const [email, setEmail] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(
    (data: any) => {
      setIsLoading(true);
      API.Tutors.inviteTutor({ email })
        .then((response) => {
          toast.success(i18n('v2.pages.members.modals.add.success', { email }));
          onClose(false);
          onSuccess();
        })
        .finally(() => setIsLoading(false));
    },
    [email, onClose, onSuccess]
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      closable={isLoading ? false : true}
      keyboard={isLoading ? false : true}
      type='email'
      title={i18n('v2.pages.permissions.myTutors.buttons.add')}
      backdrop='static'>
      <p>{i18n('v2.pages.permissions.myTutors.modals.description1')}</p>
      <p>{i18n('v2.pages.permissions.myTutors.modals.description2')}</p>

      <Form onSubmit={handleSubmit}>
        <Input
          type='email'
          name='email'
          placeholder={i18n('v2.pages.members.modals.add.placeholder')}
          disabled={isLoading}
          onChange={(e: any) => setEmail(e.target.value)}
          validators={{
            required: true,
            email: true,
            match: {
              value: (email: string) => !Utils.isSameText(email, userEmail),
              message: i18n('v2.pages.permissions.myTutors.modals.error')
            }
          }}
        />

        <Button
          type='submit'
          block
          disabled={!Utils.isEmail(email)}
          loading={isLoading}>
          {i18n('v2.pages.members.modals.add.button')}
        </Button>
      </Form>
    </Modal>
  );
}
