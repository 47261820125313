import { i18n } from 'dawere-commons';
import { Col, Flex, Row, Tab, TabList, TabPanel, Tabs } from 'dawere-uic';
import { useCallback } from 'react';
import { NumberParam, useQueryParams } from 'use-query-params';
import { QPARAMS } from 'values';
import { CouponsAssignedTab } from './CouponsAssignedTab';
import { CouponsUnassignedTab } from './CouponsUnassignedTab';
import { CouponsUsedTab } from './CouponsUsedTab';

export function CouponsSubtabs() {
  const [query, setQuery] = useQueryParams({
    [QPARAMS.Subtab]: NumberParam
  });

  const handleTabSelection = useCallback(
    (value: number) => {
      setQuery({ [QPARAMS.Subtab]: value }, 'pushIn');
    },
    [setQuery]
  );

  return (
    <Row>
      <Col>
        <Tabs
          onSelect={handleTabSelection}
          defaultIndex={query[QPARAMS.Subtab] || 0}>
          <Flex align='center' justify='between' className='mb-4'>
            <TabList scrollable='true'>
              <Tab>
                {i18n(
                  'v2.pages.organizationDashboard.tabs.1.full.tabs.1.title'
                )}
              </Tab>
              <Tab>
                {i18n(
                  'v2.pages.organizationDashboard.tabs.1.full.tabs.2.title'
                )}
              </Tab>
              <Tab>
                {i18n(
                  'v2.pages.organizationDashboard.tabs.1.full.tabs.3.title'
                )}
              </Tab>
            </TabList>

            <div id='grid-portal-search'></div>
          </Flex>

          <TabPanel>
            <CouponsUnassignedTab />
          </TabPanel>
          <TabPanel>
            <CouponsAssignedTab />
          </TabPanel>
          <TabPanel>
            <CouponsUsedTab />
          </TabPanel>
        </Tabs>
      </Col>
    </Row>
  );
}
