import { Rest, Session } from 'dawere-commons';
import { toast } from 'dawere-uic';
import React, { useCallback, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Referrals } from 'utils';

/**
 * Colocar acá las propiedades y métodos que se desea hacer accesibles
 * desde cualquier parte del código. Estas deben coincidir con lo que se expone
 * en el "value" del "LayoutContext.Provider" abajo.
 */
export interface AppProps {
  activeOrganization: any;
  setActiveOrganization: React.Dispatch<React.SetStateAction<any>>;
  referral: any;
  setReferral: React.Dispatch<React.SetStateAction<any>>;
}

export const AppContext = React.createContext<AppProps>({
  activeOrganization: null,
  setActiveOrganization: () => {},
  referral: null,
  setReferral: () => {}
});

export function AppProvider({ children }: any) {
  // Menu active organization
  const [activeOrganization, setActiveOrganization] = useState(
    Session.getActiveOrganization()
  );

  const [referral, setReferral] = useState<any>(Referrals.get());

  // Setting up recaptcha
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    if (executeRecaptcha !== undefined) {
      Rest.setRecaptcha(executeRecaptcha);
    }
  }, [executeRecaptcha]);

  // Setting up toast for error and warning messages
  Rest.setErrorToast(toast.error);
  Rest.setWarningToast(toast.warning);

  const unauthorized = useCallback(() => {
    const isLogged = Session.getToken();

    if (isLogged) {
      Session.clean();
      window.location.href = '/';
    }
  }, []);

  // Setting up unauthorized callback
  Rest.setUnauthorizedCallback(unauthorized);

  return (
    <AppContext.Provider
      value={{
        activeOrganization,
        setActiveOrganization,
        referral,
        setReferral
      }}>
      {children}
    </AppContext.Provider>
  );
}
