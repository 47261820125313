import { API } from 'api';
import { Historical } from 'components';
import { Session } from 'dawere-commons';
import { Empty } from 'dawere-uic';
import { useEffect, useState } from 'react';
import { useStudentDashboard } from '../../hooks/useStudentDashboard';
import { PendingProfilePanel } from './PendingProfilePanel';

export function HistoricalPanel({ program, isExpanded = false }: any) {
  const { studentId, ignorePendingProfile } = useStudentDashboard();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (studentId && program?.id && isExpanded && !data) {
      setIsLoading(true);

      API.Students.getTranscripts(studentId, program?.id)
        .then((response) => {
          setData(response);
        })
        .finally(() => setIsLoading(false));
    }
  }, [studentId, data, isExpanded, program?.id]);

  /* Nada que mostrar */
  if (data?.length === 0 && !isLoading) {
    return <Empty />;
  }

  /* Pendiente por completar perfil */
  if (
    !ignorePendingProfile &&
    data?.length > 0 &&
    !Session.isProfileCompleted() &&
    !isLoading
  ) {
    return <PendingProfilePanel />;
  }

  return (
    <Historical program={program} transcripts={data} isLoading={isLoading} />
  );
}
