import { GoogleButton } from 'components';
import { i18n } from 'dawere-commons';
import { Button, Divider, Form, Input } from 'dawere-uic';
import { useCallback } from 'react';

export function OrganizationSpecialForm({
  onSubmit = (data: any) => {},
  isLoading = false,
  onGoogleAuth = (token: string) => {},
  decoded = {}
}: any) {
  const handleSubmit = useCallback(
    (data: any) => {
      onSubmit({ defaultDashboard: 'ORGANIZATION', ...data });
    },
    [onSubmit]
  );

  return (
    <Form onSubmit={handleSubmit}>
      <GoogleButton
        onAuth={onGoogleAuth}
        loading={isLoading}
        text={i18n('v2.pages.register.buttons.googleLogIn')}
      />

      <Divider>{i18n('ui.commons.or')}</Divider>

      <Input
        type='hidden'
        name='organizationName'
        label={i18n('v2.pages.register.tabs.3.form.organizationName')}
        value={decoded?.organizationName}
        validators={{
          required: true
        }}
        className='m-0'
      />

      <Input
        name='name'
        label={i18n('v2.pages.register.tabs.3.form.name')}
        validators={{
          required: true,
          pattern: {
            value: /^[a-z\sáéíóúÁÉÍÓÚñÑçÇ]*$/gi,
            message: i18n('v2.pages.register.form.errors.name')
          }
        }}
        loading={isLoading}
      />

      <Input
        name='lastName'
        label={i18n('v2.pages.register.tabs.3.form.lastName')}
        validators={{
          required: true,
          pattern: {
            value: /^[a-z\sáéíóúÁÉÍÓÚñÑçÇ]*$/gi,
            message: i18n('v2.pages.register.form.errors.lastname')
          }
        }}
        loading={isLoading}
      />

      <Input
        name='email'
        label={i18n('v2.pages.register.tabs.3.form.email')}
        type='email'
        value={decoded?.userEmail}
        readOnly
      />

      <Input
        strength
        name='password'
        label={i18n('v2.pages.register.tabs.3.form.password')}
        type='password'
        helpText={i18n('v2.pages.register.helps.password')}
        validators={{
          required: true,
          minLength: 8
        }}
        loading={isLoading}
      />

      <Button type='submit' className='mt-2' block loading={isLoading}>
        {i18n('v2.pages.register.buttons.create')}
      </Button>
    </Form>
  );
}
