import { Block, Col } from 'dawere-uic';
import { PageWrapper } from 'pages';
import { getSEO } from 'utils';

/**
 * Path: /terminos-y-condiciones
 * Key: TERMS_AND_CONDITIONS
 * @returns {React.ReactElement}
 */
export default function TermsNConditionsPage() {
  return (
    <PageWrapper>
      {getSEO('v2.pages.termsNConditions.seo', true)}

      <Block narrow>
        <Col xs={12} md={8} offset={{ md: 2 }}>
          <h2>Términos y Condiciones</h2>

          <strong>Actualizado el 2023-10-18</strong>

          <h3 className='mt-5'>Términos generales</h3>

          <p>
            Al acceder y realizar un pedido con Dawere, usted confirma que está
            de acuerdo y sujeto a los términos de servicio contenidos en los
            Términos y condiciones que se describen a continuación. Estos
            términos se aplican a todo el sitio web y a cualquier correo
            electrónico u otro tipo de comunicación entre usted y Dawere.
          </p>

          <p>
            Bajo ninguna circunstancia el equipo de Dawere será responsable de
            ningún daño directo, indirecto, especial, incidental o consecuente,
            que incluye, entre otros, la pérdida de datos o ganancias que surjan
            del uso o la incapacidad de usar, los materiales de este sitio,
            incluso si el equipo de Dawere o un representante autorizado han
            sido informados de la posibilidad de tales daños. Si su uso de
            materiales de este sitio resulta en la necesidad de servicio,
            reparación o corrección de equipos o datos, usted asume los costos
            de los mismos.
          </p>

          <p>
            Dawere no será responsable de ningún resultado que pueda ocurrir
            durante el curso del uso de nuestros recursos. Nos reservamos el
            derecho de cambiar los precios y revisar la política de uso de
            recursos en cualquier momento.
          </p>

          <h3 className='mt-5'>Licencia</h3>

          <p>
            Dawere le otorga una licencia revocable, no exclusiva,
            intransferible y limitada para descargar, instalar y/o usar la
            plataforma estrictamente de acuerdo con los términos de este
            Acuerdo.
          </p>

          <p>
            Estos Términos y condiciones son un contrato entre usted y Dawere
            (referidos en estos Términos y condiciones como "Dawere",
            "nosotros", o "nuestro"), el proveedor del sitio web de Dawere y los
            servicios accesibles desde el sitio web de Dawere (que se denominan
            colectivamente en estos Términos y condiciones como el "Servicio de
            Dawere").
          </p>

          <p>
            Usted acepta estar sujeto a estos Términos y condiciones. Si no está
            de acuerdo con estos Términos y condiciones, no utilice el Servicio
            Dawere. En estos Términos y condiciones, "usted" se refiere tanto a
            usted como individuo como a la entidad que representa. Si viola
            cualquiera de estos Términos y condiciones, nos reservamos el
            derecho de cancelar su cuenta o bloquear el acceso a su cuenta sin
            previo aviso.
          </p>

          <p>
            Se entiende que al aceptar los términos y condiciones de Dawere
            International, Inc o al utilizar la plataforma, el usuario que sea
            menor de 18 años de edad acepta que tiene el permiso de su
            representante. Se entiende que todas las actividades que realicen
            los usuarios menores de edad en la plataforma o al utilizar las
            mismas, es responsabilidad de sus padres o representantes, y de
            ninguna manera Dawere International, Inc. podrá ser responsable de
            aquellas acciones que haga el menor de edad sin supervisión de sus
            padres o responsables en internet o en la plataforma, así como las
            interacciones que pueda tener con otros usuarios.
          </p>

          <h3 className='mt-5'>Definiciones y términos clave</h3>

          <p>
            Para ayudar a explicar las cosas de la manera más clara posible en
            estos Términos y Condiciones, cada vez que se hace referencia a
            cualquiera de estos términos, se definen estrictamente como:
          </p>

          <ul>
            <li>
              Cookie: pequeña cantidad de datos generados por un sitio web y
              guardados por su navegador web. Se utiliza para identificar su
              navegador, proporcionar análisis, recordar información sobre
              usted, como su preferencia de idioma o información de inicio de
              sesión.
            </li>
            <li>
              Compañía: cuando estos Términos y Condiciones mencionan
              "Compañía", "nosotros", "nos" o "nuestro", se refiere a Dawere
              International Inc (8554 NW 61st ST Miami Florida 33166), que es
              responsable de su información en virtud de estos Términos y
              Condiciones.
            </li>
            <li>
              Plataforma: sitio web de Internet, aplicación web o aplicación
              digital de cara al público de Dawere.
            </li>
            <li>
              País: donde se encuentra Dawere o los propietarios / fundadores de
              Dawere, en este caso es Estados Unidos
            </li>
            <li>
              Dispositivo: cualquier dispositivo conectado a Internet, como un
              teléfono, tablet, computadora o cualquier otro dispositivo que se
              pueda usar para visitar Dawere y usar los servicios.
            </li>
            <li>
              Servicio: se refiere al servicio brindado por Dawere como se
              describe en los términos relativos (si están disponibles) y en
              esta plataforma.
            </li>
            <li>
              Terceros: se refiere a anunciantes, patrocinadores de concursos,
              socios promocionales y de marketing, y otros que brindan nuestro
              contenido o cuyos productos o servicios que creemos que pueden
              interesarle.
            </li>
            <li>
              Sitio web: el sitio de Dawere, al que se puede acceder a través de
              esta URL: <a href='https://dawere.com/'>https://dawere.com/</a>
            </li>
            <li>
              Usted: una persona o entidad que está registrada con Dawere para
              utilizar los Servicios.
            </li>
          </ul>

          <h3 className='mt-5'>Restricciones</h3>

          <p>Usted acepta no hacerlo y no permitirá que otros:</p>

          <ul>
            <li>
              Licenciar, vender, alquilar, arrendar, asignar, distribuir,
              transmitir, alojar, subcontratar, divulgar o explotar
              comercialmente la plataforma o poner la plataforma a disposición
              de terceros.
            </li>
            <li>
              Modificar, realizar trabajos derivados, desensamblar, descifrar,
              realizar una compilación inversa o realizar ingeniería inversa de
              cualquier parte de la plataforma.
            </li>
            <li>
              Eliminar, alterar u ocultar cualquier aviso de propiedad (incluido
              cualquier aviso de derechos de autor o marca registrada) de sus
              afiliados, socios, proveedores o licenciatarios de la plataforma.
            </li>
          </ul>

          <h3 className='mt-5'>Política de Devolución y Reembolso</h3>

          <p>
            Gracias por comprar en Dawere. Apreciamos el hecho de que le guste
            comprar los servicios que ofrecemos. También queremos asegurarnos de
            que tenga una experiencia gratificante mientras explora, evalúa y
            compra nuestros productos.
          </p>

          <p>
            Al igual que con cualquier experiencia de compra, existen términos y
            condiciones que se aplican a las transacciones en Dawere. Lo
            principal que debe recordar es que al realizar un pedido o realizar
            una compra en Dawere, acepta los términos junto con la Política de
            privacidad de Dawere.
          </p>

          <p>
            Si por alguna razón no está completamente satisfecho con algún bien
            o servicio que le brindamos, no dude en contactarnos y discutiremos
            cualquiera de los problemas que esté atravesando con nuestro
            producto.
          </p>

          <p>
            Si se considera que el usuario viola los Términos y Condiciones de
            alguna forma, Dawere International, Inc. no emitirá reembolso o
            crédito de ningún tipo. Si el usuario no aprueba un curso, o
            programa, o recibe una calificación no satisfactoria tampoco se
            aplicará ningún tipo de reembolso, ya que dicha situación es
            completa responsabilidad del alumno. Dawere International Inc no
            emite reembolso alguno después de que un estudiante tiene 15 días de
            haber pagado por su inscripción. Por el contrario, si un estudiante
            solicita un reembolso antes de que se cumplan los 15 días desde el
            pago de su inscripción en algún programa educativo, Dawere
            International Inc emitirá el reembolso sin excepciones. Siempre que
            se emita un reembolso, Dawere International Inc se cobrará un 10%
            del reembolso para cubrir gastos administrativos.
          </p>

          <h3 className='mt-5'>Tus sugerencias</h3>

          <p>
            Cualquier, comentario, idea, mejora o sugerencia (colectivamente,
            "Sugerencias") que usted proporcione a Dawere con respecto a la
            plataforma seguirá siendo propiedad única y exclusiva de Dawere.
          </p>

          <p>
            Dawere tendrá la libertad de usar, copiar, modificar, publicar o
            redistribuir las Sugerencias para cualquier propósito y de cualquier
            manera sin ningún crédito o compensación para usted.
          </p>

          <h3 className='mt-5'>Tu consentimiento</h3>

          <p>
            Hemos actualizado nuestros Términos y condiciones para brindarle
            total transparencia sobre lo que se establece cuando visita nuestro
            sitio y cómo se utiliza. Al utilizar nuestra plataforma, registrar
            una cuenta o realizar una compra, por la presente acepta nuestros
            Términos y condiciones.
          </p>

          <h3 className='mt-5'>Enlaces a otros Sitios Web</h3>

          <p>
            Estos Términos y Condiciones se aplican solo a los Servicios. Los
            Servicios pueden contener enlaces a otros sitios web que Dawere no
            opera ni controla. No somos responsables por el contenido, la
            precisión o las opiniones expresadas en dichos sitios web, y dichos
            sitios web no son investigados, monitoreados o verificados por
            nosotros para garantizar la precisión o integridad de lo que ahí se
            informe. Recuerde que cuando utiliza un enlace para ir de los
            Servicios a otro sitio web, nuestros Términos y condiciones dejan de
            estar vigentes. Su navegación e interacción en cualquier otro sitio
            web, incluidos aquellos que tienen un enlace en nuestra plataforma,
            están sujetos a las propias reglas y políticas de ese sitio web.
            Dichos terceros pueden utilizar sus propias cookies u otros métodos
            para recopilar información sobre usted.
          </p>

          <h3 className='mt-5'>Cookies</h3>

          <p>
            Dawere utiliza "cookies" para identificar las áreas de nuestro sitio
            web que ha visitado. Una cookie es una pequeña porción de datos que
            su navegador web almacena en su computadora o dispositivo móvil.
            Usamos cookies para mejorar el rendimiento y la funcionalidad de
            nuestra plataforma, pero no son esenciales para su uso. Sin embargo,
            sin estas cookies, es posible que ciertas funciones, como los
            videos, no estén disponibles o se le solicitará que ingrese sus
            datos de inicio de sesión cada vez que visite la plataforma, ya que
            no podríamos recordar que había iniciado sesión anteriormente. La
            mayoría de los navegadores web se pueden configurar para desactivar
            el uso de cookies. Sin embargo, si desactiva las cookies, es posible
            que no pueda acceder a la funcionalidad de nuestro sitio web
            correctamente o en absoluto. Nunca colocamos información de
            identificación personal en cookies.
          </p>

          <h3 className='mt-5'>Cambios en nuestros Términos y Condiciones</h3>

          <p>
            Usted reconoce y acepta que Dawere puede dejar de brindarle (de
            forma permanente o temporal) el Servicio (o cualquier función dentro
            del Servicio) a usted o a los usuarios en general, a discreción
            exclusiva de Dawere, sin previo aviso. Puede dejar de utilizar el
            Servicio en cualquier momento. No es necesario que informe
            específicamente a Dawere cuando deje de usar el Servicio. Usted
            reconoce y acepta que si Dawere deshabilita el acceso a su cuenta,
            es posible que no pueda acceder al Servicio, los detalles de su
            cuenta o cualquier archivo u otro material contenido en su cuenta.
          </p>

          <p>
            Si decidimos cambiar nuestros Términos y condiciones, publicaremos
            esos cambios en esta página y / o actualizaremos la fecha de
            modificación de los Términos y condiciones a continuación.
          </p>

          <h3 className='mt-5'>Modificaciones a nuestra plataforma</h3>

          <p>
            Dawere se reserva el derecho de modificar, suspender o interrumpir,
            temporal o permanentemente, la plataforma o cualquier servicio al
            que se conecte, con o sin previo aviso y sin responsabilidad ante
            usted.
          </p>

          <h3 className='mt-5'>Actualizaciones a nuestra plataforma</h3>

          <p>
            Dawere puede, de vez en cuando, proporcionar mejoras a las
            características / funcionalidad de la plataforma, que pueden incluir
            parches, corrección de errores, actualizaciones, mejoras y otras
            modificaciones ("Actualizaciones").
          </p>

          <p>
            Las actualizaciones pueden modificar o eliminar ciertas
            características y / o funcionalidades de la plataforma. Usted acepta
            que Dawere no tiene la obligación de (i) proporcionar
            Actualizaciones, o (ii) continuar proporcionándole o habilitando
            características y / o funcionalidades particulares de la plataforma.
          </p>

          <p>
            Además, acepta que todas las Actualizaciones (i) se considerarán una
            parte integral de la plataforma y (ii) estarán sujetas a los
            términos y condiciones de este Acuerdo.
          </p>

          <h3 className='mt-5'>Servicios de Terceros</h3>

          <p>
            Podemos mostrar, incluir o poner a disposición contenido de terceros
            (incluidos datos, información, aplicaciones y otros servicios de
            productos) o proporcionar enlaces a sitios web o servicios de
            terceros ("Servicios de terceros").
          </p>

          <p>
            Usted reconoce y acepta que Dawere no será responsable de ningún
            Servicio de terceros, incluida su precisión, integridad,
            puntualidad, validez, cumplimiento de los derechos de autor,
            legalidad, decencia, calidad o cualquier otro aspecto de los mismos.
            Dawere no asume ni tendrá ninguna obligación o responsabilidad ante
            usted o cualquier otra persona o entidad por los Servicios de
            terceros.
          </p>

          <p>
            Los Servicios de terceros y los enlaces a los mismos se brindan
            únicamente para su conveniencia y usted accede a ellos y los usa
            completamente bajo su propio riesgo y sujeto a los términos y
            condiciones de dichos terceros.
          </p>

          <h3 className='mt-5'>Sistema de Mercadeo por Referencia</h3>

          <p>
            El sistema de mercadeo por referencia es un esquema de prepago de
            servicios a través de vales de descuento. Este sistema es parte del
            funcionamiento normal y corriente de la plataforma. Es decir, no se
            tiene previsto una fecha de finalización al funcionamiento de este
            sistema. Bajo ningún concepto se debe confundir esta funcionalidad,
            ni ninguna otra funcionalidad de Dawere con servicios financieros.
            Dawere solo ofrece servicios educativos.
          </p>

          <p>
            Los montos de los vales de descuento otorgados a raíz del sistema de
            mercadeo por referencia pueden ser modificados en cualquier momento
            sin previo aviso. Los vales de descuento no son susceptibles a
            devoluciones. Si una inscripción debe ser anulada y el estudiante
            hace uso de nuestra política de devoluciones, el vale de descuento
            no entrará dentro de esa devolución.
          </p>

          <h3 className='mt-5'>Duración y Terminación</h3>

          <p>
            Este Acuerdo permanecerá en vigor hasta que usted o Dawere lo
            rescindan.
          </p>

          <p>
            Dawere puede, a su entera discreción, en cualquier momento y por
            cualquier motivo o sin él, suspender o rescindir este Acuerdo con o
            sin previo aviso.
          </p>

          <p>
            Este Acuerdo terminará inmediatamente, sin previo aviso de Dawere,
            en caso de que no cumpla con alguna de las disposiciones de este
            Acuerdo. También puede rescindir este Acuerdo eliminando la
            plataforma y todas las copias del mismo de su computadora.
          </p>

          <p>
            Tras la rescisión de este Acuerdo, deberá dejar de utilizar la
            plataforma y eliminar todas las copias de la plataforma de su
            computadora.
          </p>

          <p>
            La rescisión de este Acuerdo no limitará ninguno de los derechos o
            recursos de Dawere por ley o en equidad en caso de incumplimiento
            por su parte (durante la vigencia de este Acuerdo) de cualquiera de
            sus obligaciones en virtud del presente Acuerdo.
          </p>

          <p>
            Se entiende que el usuario es responsable de pagar todas las tarifas
            e impuestos aplicables de manera oportuna, con los mecanismos de
            pago disponibles en la plataforma. Queda entendido que Dawere.com
            prestará sus Servicios siempre y cuando el usuario esté al día con
            los pagos del servicio. En este sentido, en caso de que el usuario
            no cumpla con la obligación de pago hacia Dawere, se le suspenderá
            el servicio. Dawere acepta pagos en USD y en las monedas locales de
            los países donde opera.
          </p>

          <h3 className='mt-5'>Aviso de infracción de Derechos de Autor</h3>

          <p>
            Si usted es propietario de los derechos de autor o el agente de
            dicho propietario y cree que cualquier material de nuestra
            plataforma constituye una infracción de sus derechos de autor,
            comuníquese con nosotros y proporcione la siguiente información: (a)
            una firma física o electrónica del propietario de los derechos de
            autor o una persona autorizada para actuar en su nombre; (b)
            identificación del material que se alega infringe; (c) su
            información de contacto, incluida su dirección, número de teléfono y
            un correo electrónico; (d) una declaración suya de que cree de buena
            fe que el uso del material no está autorizado por los propietarios
            de los derechos de autor; y (e) la declaración de que la información
            en la notificación es precisa y, bajo pena de perjurio, usted está
            autorizado a actuar en nombre del propietario.
          </p>

          <h3 className='mt-5'>Indemnización</h3>

          <p>
            Usted acepta indemnizar y eximir de responsabilidad a Dawere y a sus
            empresas matrices, subsidiarias, afiliadas, funcionarios, empleados,
            agentes, socios y otorgantes de licencias (si corresponde) de
            cualquier reclamo o demanda, incluidos los honorarios razonables de
            abogados, debido a que surja de su: (a) uso de la plataforma; (b)
            violación de este Acuerdo o cualquier ley o reglamento; o (c)
            violación de cualquier derecho de un tercero.
          </p>

          <h3 className='mt-5'>Sin garantías</h3>

          <p>
            La plataforma se le proporciona "TAL CUAL" y "SEGúN DISPONIBILIDAD"
            y con todas las fallas y defectos sin garantía de ningún tipo. En la
            medida máxima permitida por la ley aplicable, Dawere, en su propio
            nombre y en nombre de sus afiliados y sus respectivos licenciantes y
            proveedores de servicios, renuncia expresamente a todas las
            garantías, ya sean expresas, implícitas, legales o de otro tipo, con
            con respecto a la plataforma, incluidas todas las garantías
            implícitas de comerciabilidad, idoneidad para un propósito
            particular, título y no infracción, y garantías que puedan surgir
            del curso del trato, el curso del desempeño, el uso o la práctica
            comercial. Sin limitación a lo anterior, Dawere no ofrece garantía
            ni compromiso, y no hace ninguna representación de ningún tipo de
            que la plataforma cumplirá con sus requisitos, logrará los
            resultados previstos, será compatible o funcionará con cualquier
            otro software, sitios web, sistemas o servicios, operen sin
            interrupciones, cumplan con los estándares de rendimiento o
            confiabilidad o que no tengan errores o que cualquier error o
            defecto puede o será corregido.
          </p>

          <p>
            Sin limitar lo anterior, ni Dawere ni ningún proveedor de Dawere
            hace ninguna representación o garantía de ningún tipo, expresa o
            implícita: (i) en cuanto al funcionamiento o disponibilidad de la
            plataforma, o la información, contenido y materiales o productos
            incluidos en el mismo; (ii) que la plataforma será ininterrumpida o
            libre de errores; (iii) en cuanto a la precisión, confiabilidad o
            vigencia de cualquier información o contenido proporcionado a través
            de la plataforma; o (iv) que la plataforma, sus servidores, el
            contenido o los correos electrónicos enviados desde o en nombre de
            Dawere están libres de virus, scripts, troyanos, gusanos, malware,
            bombas de tiempo u otros componentes nocivos.
          </p>

          <p>
            Algunas jurisdicciones no permiten la exclusión o las limitaciones
            de las garantías implícitas o las limitaciones de los derechos
            legales aplicables de un consumidor, por lo que algunas o todas las
            exclusiones y limitaciones anteriores pueden no aplicarse en su
            caso.
          </p>

          <h3 className='mt-5'>Limitación de Responsabilidad</h3>

          <p>
            Sin perjuicio de los daños en los que pueda incurrir, la
            responsabilidad total de Dawere y cualquiera de sus proveedores en
            virtud de cualquier disposición de este Acuerdo y su recurso
            exclusivo por todo lo anterior se limitará al monto realmente pagado
            por usted para la plataforma.
          </p>

          <p>
            En la máxima medida permitida por la ley aplicable, en ningún caso
            Dawere o sus proveedores serán responsables de ningún daño especial,
            incidental, indirecto o consecuente de ningún tipo (incluidos, entre
            otros, daños por lucro cesante, por pérdida de datos u otra
            información, por interrupción del negocio, por lesiones personales,
            por la pérdida de privacidad que surja de alguna manera relacionada
            con el uso o la imposibilidad de usar la plataforma, software de
            terceros y / o - hardware de terceros utilizado con la plataforma, o
            de otro modo en relación con cualquier disposición de este Acuerdo),
            incluso si Dawere o cualquier proveedor ha sido informado de la
            posibilidad de tales daños e incluso si el recurso no cumple con su
            propósito esencial.
          </p>

          <p>
            Algunos estados / jurisdicciones no permiten la exclusión o
            limitación de daños incidentales o consecuentes, por lo que es
            posible que la limitación o exclusión anterior no se aplique en su
            caso.
          </p>

          <h3 className='mt-5'>Divisibilidad</h3>

          <p>
            Si alguna disposición de este Acuerdo se considera inaplicable o
            inválida, dicha disposición se cambiará e interpretará para lograr
            los objetivos de dicha disposición en la mayor medida posible según
            la ley aplicable y las disposiciones restantes continuarán en pleno
            vigor y efecto.
          </p>

          <p>
            Este Acuerdo, junto con la Política de privacidad y cualquier otro
            aviso legal publicado por Dawere en los Servicios, constituirá el
            acuerdo completo entre usted y Dawere con respecto a los Servicios.
            Si alguna disposición de este Acuerdo es considerada inválida por un
            tribunal de jurisdicción competente, la invalidez de dicha
            disposición no afectará la validez de las disposiciones restantes de
            este Acuerdo, que permanecerán en pleno vigor y efecto. Ninguna
            renuncia a cualquier término de este Acuerdo se considerará una
            renuncia adicional o continua de dicho término o cualquier otro
            término, y el hecho de que Dawere no haga valer ningún derecho o
            disposición en virtud de este Acuerdo no constituirá una renuncia a
            dicho derecho. o provisión. USTED Y Dawere ACEPTAN QUE CUALQUIER
            CAUSA DE ACCIóN QUE SURJA O ESTé RELACIONADA CON LOS SERVICIOS DEBE
            COMENZAR DENTRO DE UN (1) AñO DESPUéS DE QUE LA CAUSA DE ACCIóN SE
            ACUERDA. DE LO CONTRARIO, DICHA CAUSA DE ACCIóN ESTá PERMANENTEMENTE
            PROHIBIDA.
          </p>

          <h3 className='mt-5'>Renuncia</h3>

          <p>
            Salvo lo dispuesto en el presente, el hecho de no ejercer un derecho
            o exigir el cumplimiento de una obligación en virtud de este Acuerdo
            no afectará la capacidad de una de las partes para ejercer dicho
            derecho o requerir dicho cumplimiento en cualquier momento
            posterior, ni constituirá la renuncia a una infracción. cualquier
            incumplimiento posterior.
          </p>

          <p>
            Ninguna falla en el ejercicio, ni demora en el ejercicio, por parte
            de cualquiera de las partes, de cualquier derecho o poder bajo este
            Acuerdo operará como una renuncia a ese derecho o poder. El
            ejercicio único o parcial de cualquier derecho o poder en virtud de
            este Acuerdo tampoco impedirá el ejercicio posterior de ese o
            cualquier otro derecho otorgado en este documento. En caso de
            conflicto entre este Acuerdo y cualquier compra u otros términos
            aplicables, regirán los términos de este Acuerdo.
          </p>

          <h3 className='mt-5'>Enmiendas a este Acuerdo</h3>

          <p>
            Dawere se reserva el derecho, a su entera discreción, de modificar o
            reemplazar este Acuerdo en cualquier momento. Si una revisión es
            importante, proporcionaremos un aviso de al menos 30 días antes de
            que entren en vigencia los nuevos términos. Lo que constituye un
            cambio material se determinará a nuestro exclusivo criterio.
          </p>

          <p>
            Si continúa accediendo o utilizando nuestra plataforma después de
            que las revisiones entren en vigencia, usted acepta estar sujeto a
            los términos revisados. Si no está de acuerdo con los nuevos
            términos, ya no está autorizado para usar Dawere.
          </p>

          <h3 className='mt-5'>Acuerdo completo</h3>

          <p>
            El Acuerdo constituye el acuerdo completo entre usted y Dawere con
            respecto a su uso de la plataforma y reemplaza todos los acuerdos
            escritos u orales anteriores y contemporáneos entre usted y Dawere.
          </p>

          <p>
            Es posible que esté sujeto a términos y condiciones adicionales que
            se aplican cuando usa o compra otros servicios de Dawere, que Dawere
            le proporcionará en el momento de dicho uso o compra.
          </p>

          <h3 className='mt-5'>Actualizaciones de nuestros Términos</h3>

          <p>
            Podemos cambiar nuestro Servicio y nuestras políticas, y es posible
            que debamos realizar cambios en estos Términos para que reflejen con
            precisión nuestro Servicio y nuestras políticas. A menos que la ley
            exija lo contrario, le notificaremos (por ejemplo, a través de
            nuestro Servicio) antes de realizar cambios en estos Términos y le
            daremos la oportunidad de revisarlos antes de que entren en
            vigencia. Luego, si continúa utilizando el Servicio, estará sujeto a
            los Términos actualizados. Si no desea aceptar estos o alguno de los
            Términos actualizados, puede eliminar su cuenta.
          </p>

          <h3 className='mt-5'>Propiedad intelectual</h3>

          <p>
            El sitio web/aplicación y todo su contenido, características y
            funcionalidad (incluidos, entre otros, toda la información,
            software, texto, pantallas, imágenes, video y audio, y el diseño,
            selección y disposición de los mismos) son propiedad de Dawere, sus
            licenciantes u otros proveedores de dicho material están protegidos
            por los Estados Unidos y por las leyes internacionales de derechos
            de autor, marcas registradas, patentes, secretos comerciales y otras
            leyes de propiedad intelectual o derechos de propiedad. El material
            no se puede copiar, modificar, reproducir, descargar o distribuir de
            ninguna manera, en su totalidad o en parte, sin el permiso previo
            expreso por escrito de Dawere, a menos que se establezca
            expresamente en estos Términos y condiciones. Cualquier uso no
            autorizado del material está prohibido.
          </p>

          <p>
            Dawere International, Inc. se reserva el derecho a utilizar la
            información publicada por el usuario y la data que sea generada por
            la actividad del mismo en la plataforma para uso comercial, de
            estadísticas y mercadeo. Se entiende que de ninguna manera se
            identificará al usuario de manera personal ni se expondrán sus datos
            públicamente.
          </p>

          <h3 className='mt-5'>Acuerdo de Arbitraje</h3>

          <p>
            Esta sección se aplica a cualquier disputa, EXCEPTO QUE NO INCLUYE
            UNA DISPUTA RELACIONADA CON RECLAMOS POR RECURSOS EQUITATIVOS CON
            RESPECTO A LA EJECUCIóN O VALIDEZ DE SUS DERECHOS DE PROPIEDAD
            INTELECTUAL O DE Dawere. El término "disputa" significa cualquier
            disputa, acción u otra controversia entre usted y Dawere en relación
            con los Servicios o este acuerdo, ya sea en contrato, garantía,
            agravio, estatuto, regulación, ordenanza o cualquier otra base legal
            o equitativa. "Disputa" tendrá el significado más amplio posible
            permitido por la ley.
          </p>

          <h3 className='mt-5'>Aviso de Disputa</h3>

          <p>
            En el caso de una disputa, usted o Dawere deben darle al otro un
            Aviso de Disputa, que es una declaración escrita que establece el
            nombre, la dirección y la información de contacto de la parte que la
            proporcionó, los hechos que dieron lugar a la disputa y la
            reparación solicitada. Debe enviar cualquier Aviso de disputa por
            correo electrónico a:{' '}
            <a href='mailto:atencionalestudiante@dawere.com'>
              atencionalestudiante@dawere.com
            </a>
            . Dawere le enviará cualquier Aviso de disputa por correo a su
            dirección si la tenemos, o de otra manera a su dirección de correo
            electrónico. Usted y Dawere intentarán resolver cualquier disputa
            mediante una negociación informal dentro de los sesenta (60) días a
            partir de la fecha en que se envíe la Notificación de disputa.
            Después de sesenta (60) días, usted o Dawere pueden comenzar el
            arbitraje.
          </p>

          <h3 className='mt-5'>Arbitraje Obligatorio</h3>

          <p>
            Si usted y Dawere no resuelven ninguna disputa mediante una
            negociación informal, cualquier otro esfuerzo para resolver la
            disputa se llevará a cabo exclusivamente mediante arbitraje
            vinculante como se describe en esta sección. Está renunciando al
            derecho de litigar (o participar como parte o miembro de la clase)
            todas las disputas en la corte ante un juez o jurado. La disputa se
            resolverá mediante arbitraje vinculante de acuerdo con las reglas de
            arbitraje comercial de la Asociación Americana de Arbitraje.
            Cualquiera de las partes puede buscar medidas cautelares
            provisionales o preliminares de cualquier tribunal de jurisdicción
            competente, según sea necesario para proteger los derechos o la
            propiedad de las partes en espera de la finalización del arbitraje.
            Todos y cada uno de los costos, honorarios y gastos legales,
            contables y de otro tipo en los que incurra la parte ganadora
            correrán a cargo de la parte no ganadora.
          </p>

          <h3 className='mt-5'>Envíos y Privacidad</h3>

          <p>
            En el caso de que envíe o publique ideas, sugerencias creativas,
            diseños, fotografías, información, anuncios, datos o propuestas,
            incluidas ideas para productos, servicios, funciones, tecnologías o
            promociones nuevos o mejorados, acepta expresamente que dichos
            envíos se realizarán automáticamente. será tratado como no
            confidencial y no propietario y se convertirá en propiedad exclusiva
            de Dawere sin ningún tipo de compensación o crédito para usted.
            Dawere y sus afiliados no tendrán obligaciones con respecto a dichos
            envíos o publicaciones y pueden usar las ideas contenidas en dichos
            envíos o publicaciones para cualquier propósito en cualquier medio a
            perpetuidad, incluyendo, pero no limitado a desarrollo, fabricación,
            y comercializar productos y servicios utilizando tales ideas.
          </p>

          <h3 className='mt-5'>Promociones </h3>

          <p>
            Dawere puede, de vez en cuando, incluir concursos, promociones,
            sorteos u otras actividades ("Promociones") que requieren que envíe
            material o información sobre usted. Tenga en cuenta que todas las
            promociones pueden regirse por reglas independientes que pueden
            contener ciertos requisitos de elegibilidad, como restricciones de
            edad y ubicación geográfica. Usted es responsable de leer todas las
            reglas de Promociones para determinar si es elegible para participar
            o no. Si participa en alguna Promoción, acepta cumplir con todas las
            Reglas de promociones.
          </p>

          <p>
            Es posible que se apliquen términos y condiciones adicionales a las
            compras de bienes o servicios a través de los Servicios, cuyos
            términos y condiciones forman parte de este Acuerdo mediante esta
            referencia.
          </p>

          <p>
            Dawere se permite hacer descuentos o promociones para garantizar el
            uso del sistema por parte de una mayor cantidad de usuarios. En este
            sentido, Dawere se valdrá de otras aplicaciones tecnológicas y redes
            sociales para que sus usuarios compartan contenido e información de
            la plataforma. Se entiende que los usuarios de la plataforma
            autorizan a Dawere International Inc a usar la imagen del usuario en
            las fotografías y/o videos que este envíe a cualquier trabajador de
            la empresa. Las fotografías y/o videos serán utilizadas
            específicamente en la plataforma de Dawere.com con el objeto de
            promocionar sus servicios. En virtud de lo anterior, Dawere
            International inc, está facultada para reproducir, almacenar
            digitalmente, distribuir, transmitir, adaptar, editar, comunicar
            públicamente y utilizar en cualquier medio físico o digital,
            incluyendo expresamente, pero sin limitar, su página web
            www.dawere.com, así como sus redes sociales, las fotografías y/o
            videos en las que el usuario aparezca. Asimismo, queda establecido
            que la presente autorización de uso de imagen es gratuita, por lo
            que los usuarios se comprometen a no reclamar ninguna remuneración
            por concepto alguno que se derive del uso de las fotografías y/o
            videos antes descritos donde aparezcan los usuarios.
          </p>

          <h3 className='mt-5'>Errores Tipográficos</h3>

          <p>
            En el caso de que un producto y / o servicio se enumere a un precio
            incorrecto o con información incorrecta debido a un error
            tipográfico, tendremos el derecho de rechazar o cancelar cualquier
            pedido realizado para el producto y / o servicio enumerado al precio
            incorrecto. Tendremos derecho a rechazar o cancelar cualquier
            pedido, ya sea que se haya confirmado o no y se haya cargado a su
            tarjeta de crédito. Si su tarjeta de crédito ya ha sido cargada por
            la compra y su pedido es cancelado, emitiremos inmediatamente un
            crédito a su cuenta de tarjeta de crédito u otra cuenta de pago por
            el monto del cargo.
          </p>

          <h3 className='mt-5'>Diverso</h3>

          <p>
            Si por alguna razón un tribunal de jurisdicción competente determina
            que alguna disposición o parte de estos Términos y condiciones no se
            puede hacer cumplir, el resto de estos Términos y condiciones
            continuará en pleno vigor y efecto. Cualquier renuncia a cualquier
            disposición de estos Términos y condiciones será efectiva solo si
            está por escrito y firmada por un representante autorizado de
            Dawere. Dawere tendrá derecho a una medida cautelar u otra
            compensación equitativa (sin la obligación de depositar ninguna
            fianza o garantía) en caso de incumplimiento anticipado por su
            parte. Dawere opera y controla el Servicio Dawere desde sus oficinas
            en Estados Unidos. El Servicio no está destinado a ser distribuido
            ni utilizado por ninguna persona o entidad en ninguna jurisdicción o
            país donde dicha distribución o uso sea contrario a la ley o
            regulación. En consecuencia, las personas que eligen acceder al
            Servicio Dawere desde otras ubicaciones lo hacen por iniciativa
            propia y son las únicas responsables del cumplimiento de las leyes
            locales, en la medida en que las leyes locales sean aplicables.
            Estos Términos y condiciones (que incluyen e incorporan la Política
            de privacidad de Dawere) contienen el entendimiento completo y
            reemplazan todos los entendimientos previos entre usted y Dawere con
            respecto a su tema, y usted no puede cambiarlos ni modificarlos. Los
            títulos de las secciones que se utilizan en este Acuerdo son solo
            por conveniencia y no se les dará ninguna importancia legal.
          </p>

          <h3 className='mt-5'>Descargo de Responsabilidad</h3>

          <p>
            Dawere no es responsable de ningún contenido, código o cualquier
            otra imprecisión.
          </p>

          <p>Dawere no ofrece garantías.</p>

          <p>
            En ningún caso Dawere será responsable de ningún daño especial,
            directo, indirecto, consecuente o incidental o de cualquier daño, ya
            sea en una acción contractual, negligencia u otro agravio, que surja
            de o en conexión con el uso del Servicio o el contenido del
            Servicio. Dawere se reserva el derecho de realizar adiciones,
            eliminaciones o modificaciones al contenido del Servicio en
            cualquier momento sin previo aviso.
          </p>

          <p>
            El Servicio Dawere y su contenido se proporcionan "tal cual" y
            "según esté disponible" sin ninguna garantía o representación de
            ningún tipo, ya sea expresa o implícita. Dawere es un distribuidor y
            no un editor del contenido proporcionado por terceros; como tal,
            Dawere no ejerce ningún control editorial sobre dicho contenido y no
            ofrece ninguna garantía o representación en cuanto a la precisión,
            confiabilidad o vigencia de cualquier información, contenido,
            servicio o mercancía proporcionada o accesible a través del Servicio
            Dawere. Sin limitar lo anterior, Dawere renuncia específicamente a
            todas las garantías y representaciones en cualquier contenido
            transmitido en conexión con el Servicio Dawere o en sitios que
            pueden aparecer como enlaces en el Servicio Dawere, o en los
            productos proporcionados como parte o en relación con el Servicio
            Dawere, incluidas, entre otras, las garantías de comerciabilidad,
            idoneidad para un propósito particular o no infracción de derechos
            de terceros. Ningún consejo oral o información escrita proporcionada
            por Dawere o cualquiera de sus afiliados, empleados, funcionarios,
            directores, agentes o similares creará una garantía. La información
            sobre precios y disponibilidad está sujeta a cambios sin previo
            aviso. Sin limitar lo anterior, Dawere no garantiza que el Servicio
            de Dawere sea ininterrumpido, sin corrupción, oportuno o sin
            errores.
          </p>

          <h3 className='mt-5'>Política de Uso Aceptable:</h3>

          <p>
            Se entiende que el usuario es responsable por su navegación y
            utilización de la plataforma de Dawere International, Inc., así como
            el contenido que es publicado por el mismo, incluyendo, pero no
            limitado a, la publicación en los diferentes foros, notas,
            preguntas, y comentarios que puedan ser cargados en la plataforma.
            Por lo tanto, Dawere International, Inc. no se hace responsable por
            cualquier ataque, insultos, robos de identidad, u ofensas de algún
            tipo que pueda recibir por parte de otro de los usuarios de la
            plataforma. Adicionalmente, Dawere International, Inc. se reserva el
            derecho a hacer publicaciones de otro tipo que no sean académicas,
            tales como anuncios publicitarios, los cuales deberán ser aptos para
            los usuarios de Dawere International, Inc. El usuario de ninguna
            manera empleará los servicios de la plataforma buscando dañarla,
            deshabilitarla, hackearla o sabotearla en algún modo o interferir
            con el uso, por parte de otros usuarios de la plataforma. En este
            sentido, queda entendido que el usuario de ninguna manera podrá
            realizar las siguientes actividades:
          </p>
          <ul>
            <li>
              Intentar acceder a la plataforma de Dawere International, Inc. sin
              autorización o registro previo en la plataforma.
            </li>
            <li>
              Intentar adquirir los servicios de Dawere International, Inc.
              haciendo uso de una identidad falsa o que no corresponda con la
              información personal real del usuario.
            </li>
            <li>
              Obtener o intentar obtener el material o la información que se
              encuentra en la plataforma sin ser previamente usuario de la
              misma. Adicionalmente, se entiende que los usuarios de ninguna
              manera podrán publicar contenido que pueda ser abusivo o que de
              alguna manera pueda ser inapropiado.
            </li>
            <li>
              Viole la propiedad intelectual, privacidad u otros derechos.
            </li>
            <li>
              Envíe correo basura a otros. No compartas contenido publicitario,
              promocional, inapropiado o de solitud irrelevante que de alguna
              manera viola los Términos de Uso de Dawere.
            </li>
          </ul>

          <h3 className='mt-5'>Tampoco está permitido:</h3>

          <ul>
            <li>
              Hacer cualquier cosa que viole cualquier ley local, estatal,
              nacional o internacional o incumplir cualquiera de tus
              obligaciones contractuales u obligaciones fiduciarias.
            </li>
            <li>
              Compartir la contraseña de usuario, permitir que alguien acceda a
              la cuenta, o hacer algo que pudiera poner la cuenta en peligro.
            </li>
            <li>Intentar acceder a la cuenta de otro usuario.</li>
            <li>
              Reproducir, transferir, vender, revender o de alguna manera hacer
              uso indebido de ningún contenido de nuestros Servicios, a menos
              que estés específicamente autorizado para hacerlo.
            </li>
            <li>
              Acceder, falsificar o usar áreas privadas de nuestros sistemas, a
              menos que estés autorizado específicamente para hacerlo.
            </li>
            <li>
              Romper o burlar nuestra autenticación o medidas de seguridad o de
              alguna manera probar la vulnerabilidad de nuestros sistemas o
              redes, a menos que estés autorizado específicamente para hacerlo.
            </li>
            <li>
              Tratar de aplicar ingeniería inversa a cualquier parte de nuestros
              Servicios.
            </li>
            <li>
              Tratar de interferir con cualquier usuario, huésped o red, por
              ejemplo enviando virus, sobrecargando, enviando correo spam o
              bombardeando el correo.
            </li>
            <li>Usar nuestros Servicios para distribuir malware.</li>
            <li>
              Hacerte pasar por o tergiversar tu afiliación con cualquier
              persona o entidad.
            </li>
            <li>
              Fomentar o ayudar a alguien a hacer cualquiera de las cosas de
              esta lista.
            </li>
          </ul>

          <p>
            Todos los estudiantes que participen en los servicios de la
            plataforma deben estar de acuerdo en atenerse al siguiente código de
            comportamiento:
          </p>

          <ol>
            <li>Solo se registrarán con una cuenta.</li>
            <li>
              Las soluciones a las tareas, cuestionarios y exámenes serán mi
              propio trabajo (excepto para aquellas tareas que permitan
              colaboración de manera explícita).
            </li>
            <li>
              No proporcionará las soluciones de los deberes, cuestionarios,
              exámenes, proyectos y otras tareas, a nadie (excepto en el caso en
              el que una tarea permita explícitamente compartir las soluciones).
              Esto incluye tanto las soluciones escritas por el usuario, como
              cualquier solución proporcionada por el personal del curso a otras
              personas.
            </li>
          </ol>

          <h3 className='mt-5'>Código de ética del estudiante:</h3>

          <p>
            El trabajo propio y honesto es parte de la cultura Dawere y de las
            enseñanzas que queremos propiciar en nuestros estudiantes. Por tal
            razón, el estudiante al inscribirse en Dawere acepta el código de
            ética de los estudiantes del Bachillerato Online de Dawere, donde se
            establecen, entre otras, las siguientes condiciones:
          </p>
          <ol>
            <li>
              El estudiante tiene una sola cuenta con Dawere vinculada a su
              email. Si se inscribió con dos emails está incumpliendo y debe
              comunicarse con nosotros para arreglar la eliminación de la cuenta
              sobrante.
            </li>
            <li>
              Las respuestas a las evaluaciones y tareas deben ser su propio
              trabajo.
            </li>
            <li>
              No deben compartirse respuestas a las evaluaciones o tareas con
              otra persona.
            </li>
            <li>
              No se puede participar o realizar actividades que mejoren de
              manera deshonesta sus resultados o notas.
            </li>
            <li>
              Si se detecta que hubo deshonestidad o se incumplió de alguna
              manera con el código planteado, el estudiante queda
              automáticamente fuera del sistema educativo de Dawere y no podrá
              inscribirse en el futuro.
            </li>
          </ol>

          <h3 className='mt-5'>Contáctenos</h3>

          <p>No dude en contactarnos si tiene alguna pregunta.</p>

          <ul>
            <li>
              A través de correo electrónico:{' '}
              <a href='mailto:alvaro.gonzalez@dawere.com'>
                alvaro.gonzalez@dawere.com
              </a>
            </li>
            <li>A través del número de teléfono: +58 4123461012</li>
            <li>
              A través de este enlace:{' '}
              <a href='https://ayuda.dawere.com/hc/es-419'>
                https://ayuda.dawere.com/hc/es-419
              </a>
            </li>
            <li>
              A través de esta dirección: 8554 NW 61st ST Miami Florida 33166
            </li>
          </ul>
        </Col>
      </Block>
    </PageWrapper>
  );
}
