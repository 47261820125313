import { Utils } from 'dawere-uic';

export const QPARAMS = {
  Back: 'back',
  Category: 'cat',
  Content: 'cont',
  Course: 'course',
  Currency: 'ccy',
  Draft: 'draft',
  EarlyPayment: 'earlypayment',
  Email: 'e',
  Enrollment: 'enr',
  EvaluationName: 'evalname',
  Organization: 'org',
  OrganizationName: 'orgname',
  Program: 'prg',
  Question: 'q',
  Redirect: 'r',
  Search: 'srch',
  Selection: 'slctn',
  Status: 'status',
  Step: 'step',
  Student: 'stdnt',
  Subtab: 'subtab',
  Tab: 'tab',
  Token: 't',
  TopicName: 'topicname',
  TopicNumber: 'topicnum'
};

export function autofill(query: any = {}, fields: string[] = []): any {
  const ret: any = {};

  fields.forEach((field) => {
    if (Utils.has(query, field)) {
      ret[field] = query[field];
    }
  });

  return ret;
}
