import esVE from './es-VE';

const enVE = {
  ...esVE,
  lang: `en-VE,en`,
  shortLang: 'en-VE'
};

export default enVE;

export { enVE };
