import { API } from 'api';
import { i18n } from 'dawere-commons';
import { Flex, Icons, Skeleton, useCollapse, Utils, Visible } from 'dawere-uic';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const TopicStyled = styled.div`
  div[type='button'] {
    appearance: none;
    background-color: white;
    background: white;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    border: none;

    &:focus,
    &:active,
    &:hover {
      outline: none;
      box-shadow: none;
    }
  }
`;

export function Topic({ id, title, index, goals }: any) {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  const [guides, setGuides] = useState<any[] | null>(null);
  const [videos, setVideos] = useState<any[] | null>(null);
  const [tests, setTests] = useState<any[] | null>(null);

  const [loadingGuides, setLoadingGuides] = useState(false);
  const [loadingVideos, setLoadingVideos] = useState(false);
  const [loadingTests, setLoadingTests] = useState(false);

  useEffect(() => {
    if (isExpanded && id && guides === null) {
      setLoadingGuides(true);
      API.Topics.getGuides(id)
        .then(({ data }: any) => {
          setGuides(data);
        })
        .finally(() => setLoadingGuides(false));
    }
  }, [guides, id, isExpanded]);

  useEffect(() => {
    if (isExpanded && id && videos === null) {
      setLoadingVideos(true);
      API.Topics.getVideos(id)
        .then(({ data }: any) => {
          setVideos(data);
        })
        .finally(() => setLoadingVideos(false));
    }
  }, [videos, id, isExpanded]);

  useEffect(() => {
    if (isExpanded && id && tests === null) {
      setLoadingTests(true);
      API.Topics.getTests(id)
        .then(({ data }: any) => {
          setTests(data);
        })
        .finally(() => setLoadingTests(false));
    }
  }, [tests, id, isExpanded]);

  return (
    <TopicStyled className='border-bottom'>
      <div>
        <Visible xs sm>
          <Flex align='center' justify='between' className='pt-3'>
            <h3 className='mb-0'>
              {i18n('v2.pages.subject.section2.topic', {
                number: Utils.pad(index, 2, '0')
              })}
            </h3>

            <div
              className='text-primary cursor-pointer fw-500'
              {...getToggleProps()}>
              {i18n('v2.pages.subject.section2.buttons.showAll')}{' '}
              <Icons.Chevron direction={isExpanded ? 'up' : 'down'} />
            </div>
          </Flex>

          <div className='pb-3'>
            <h4 className='mb-0 text-muted'>{title}</h4>
          </div>
        </Visible>

        <Visible md lg xl>
          <Flex align='center' justify='between' className='py-3'>
            <Flex align='center'>
              <h3 className='mb-0 text-nowrap'>
                {i18n('v2.pages.subject.section2.topic', {
                  number: Utils.pad(index, 2, '0')
                })}
              </h3>

              <h4 className='mb-0 mx-3 text-muted'>{title}</h4>
            </Flex>

            <div
              className='text-primary cursor-pointer text-nowrap fw-500'
              {...getToggleProps()}>
              {i18n('v2.pages.subject.section2.buttons.showAll')}{' '}
              <Icons.Chevron direction={isExpanded ? 'up' : 'down'} />
            </div>
          </Flex>
        </Visible>
      </div>

      <Flex {...getCollapseProps()}>
        <Visible md lg xl>
          <h3 className='mb-0 mr-3 text-nowrap invisible'>
            {i18n('v2.pages.subject.section2.topic', {
              number: Utils.pad(index, 2, '0')
            })}
          </h3>
        </Visible>

        <div>
          <div>
            <h5>{i18n('v2.pages.subject.section2.goals')}</h5>
            <div
              dangerouslySetInnerHTML={{
                __html: goals
              }}
            />
          </div>

          {loadingVideos ? (
            <>
              <Skeleton width='30%' className='mb-3' />
              <div className='ml-4'>
                <Skeleton.List />
              </div>
            </>
          ) : (
            <>
              {videos?.length ? (
                <div>
                  <h5>{i18n('v2.pages.subject.section2.videos')}</h5>
                  <ul>
                    {videos?.map((video: any) => (
                      <li key={video?.id}>{video?.name}</li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </>
          )}

          {loadingGuides ? (
            <>
              <Skeleton width='30%' className='mb-3' />
              <div className='ml-4'>
                <Skeleton.List />
              </div>
            </>
          ) : (
            <>
              {guides?.length ? (
                <div>
                  <h5>{i18n('v2.pages.subject.section2.guides')}</h5>
                  <ul>
                    {guides?.map((guide: any) => (
                      <li key={guide?.id}>{guide?.name}</li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </>
          )}

          {loadingTests ? (
            <>
              <Skeleton width='30%' className='mb-3' />
              <div className='ml-4'>
                <Skeleton.List />
              </div>
            </>
          ) : (
            <>
              {tests?.length ? (
                <div>
                  <h5>{i18n('v2.pages.subject.section2.tests')}</h5>
                  <ul>
                    {tests?.map((test: any) => (
                      <li key={test?.id}>{test?.name}</li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </>
          )}
        </div>
      </Flex>
    </TopicStyled>
  );
}
