export function getWhatsappLink(number: string) {
  const cleanNumber = number
    .replaceAll(' ', '')
    .replaceAll('+', '')
    .replaceAll('-', '')
    .replaceAll('(', '')
    .replaceAll(')', '');

  return `https://wa.me/${cleanNumber}`;
}
