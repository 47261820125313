import { PageWrapper } from 'pages';
import { getSEO } from 'utils';
import { MisionSection } from './sections/MisionSection';
import { PhilosophySection } from './sections/PhilosophySection';
import { VisionSection } from './sections/VisionSection';
import { WelcomeSection } from './sections/WelcomeSection';

/**
 * Path: /filosofia
 * Key: PHILOSOPHY
 * @returns {React.ReactElement}
 */
export default function PhilosophyPage() {
  return (
    <PageWrapper>
      {getSEO('v2.pages.philosophy.seo')}

      {/* Misión, Visión & Filosofía Educativa */}
      <WelcomeSection />
      {/* Nuestra misión */}
      <MisionSection />
      {/* Nuestra visión */}
      <VisionSection />
      {/* Filosofía educativa */}
      <PhilosophySection />
    </PageWrapper>
  );
}
