import { API } from 'api';
import { Session } from 'dawere-commons';
import { useEffect } from 'react';
import { useApp } from './useApp';

export function useSetDefaultDashboard(
  dashboard: 'ORGANIZATION' | 'STUDENT' | 'TUTOR',
  organizationId?: string
) {
  const { setActiveOrganization } = useApp();

  useEffect(() => {
    const account = Session.getProfiles();

    if (!account?.organization && dashboard === 'ORGANIZATION') {
      return;
    }

    if (!account?.student && dashboard === 'STUDENT') {
      return;
    }

    if (!account?.tutor && dashboard === 'TUTOR') {
      return;
    }

    API.Account.setDefaultDashboard({
      dashboard,
      ...(dashboard === 'ORGANIZATION' ? { organizationId } : {})
    })
      .then(() => API.Account.me())
      .then(({ data }: any) => {
        Session.setAccountInfo(data);
        // Just in case
        setActiveOrganization(Session.getActiveOrganization());
      });
  }, [dashboard, organizationId, setActiveOrganization]);
}
