import { API } from 'api';
import { i18n } from 'dawere-commons';
import {
  Button,
  Callout,
  Col,
  Copier,
  Flex,
  Icons,
  Modal,
  Pagination,
  Row,
  Search,
  Skeleton,
  Table,
  Visible
} from 'dawere-uic';
import { useOrganization } from 'hooks';
import { SearchKeyword } from 'pages/private/organizationCharts/components/SearchKeyword';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { ModalAssignCoupon } from '../../components/ModalAssignCoupon';

export function CouponsUnassignedTab() {
  const { organization } = useOrganization();

  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [keyword, setKeyword] = useState('');

  const [openModal, setOpenModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [rowSelected, setRowSelected] = useState<any>(null);
  const [owner, setOwner] = useState<string>('');

  const handleAssign = useCallback((row: any) => {
    setOpenModal(true);
    setRowSelected(row);
  }, []);

  const desktopColumns = useMemo(
    () => [
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.1.full.tabs.1.table.columns.type'
        ),
        accessor: 'type'
      },
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.1.full.tabs.1.table.columns.code'
        ),
        accessor: 'code',
        Cell: ({ cell }: any) => {
          return <Copier text={cell.value} />;
        }
      },
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.1.full.tabs.1.table.columns.date'
        ),
        accessor: 'date'
      },
      {
        Header: '',
        accessor: 'action',
        unclickable: true,
        Cell: ({ cell }: any) => {
          return (
            <div className='text-right' style={{ minWidth: '100px' }}>
              <Button onClick={() => handleAssign(cell.row.original)}>
                {i18n(
                  'v2.pages.organizationDashboard.tabs.1.full.tabs.1.buttons.assign'
                )}
              </Button>
            </div>
          );
        }
      }
    ],
    [handleAssign]
  );

  const mobileColumns = useMemo(
    () => [
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.1.full.tabs.1.table.columns.coupon'
        ),
        accessor: 'id',
        Cell: ({ value, row }: any) => {
          return (
            <div>
              <strong className='mb-0 d-block fs-1 mb-2'>
                {row.original?.type}
              </strong>
              <div>
                <Copier text={row.original?.code} />
              </div>
              <div>{row.original?.date}</div>
              <div>
                <Button
                  onClick={() => handleAssign(row.original)}
                  block
                  variant='outline'
                  className='mt-2'>
                  {i18n(
                    'v2.pages.organizationDashboard.tabs.1.full.tabs.1.buttons.assign'
                  )}
                </Button>
              </div>
            </div>
          );
        }
      }
    ],
    [handleAssign]
  );

  const load = useCallback(() => {
    if (organization?.id) {
      setIsLoading(true);

      API.Coupons.list(
        organization.id,
        {
          page: pageIndex,
          size: pageSize,
          keyword: keyword
        },
        'UNASSIGNED'
      )
        .then((response: any) => {
          setData(response.data);
          setPageCount(response.totalPages);
          setTotal(response.totalRows);
        })
        .finally(() => setIsLoading(false));
    }
  }, [keyword, organization.id, pageIndex, pageSize]);

  // Reload data
  useEffect(() => {
    load();
  }, [load]);

  const handlePaging = useCallback(({ pageSize, pageIndex }: any) => {
    setPageSize(pageSize);
    setPageIndex(pageIndex);
  }, []);

  const handleSearch = useCallback((data: any) => {
    setKeyword(() => {
      setPageIndex(0);
      return data.search;
    });
  }, []);

  const handleDownload = useCallback(() => {
    if (organization?.id) {
      setIsDownloading(true);

      API.Coupons.list(
        organization.id,
        {
          keyword: keyword
        },
        'UNASSIGNED',
        true
      ).finally(() => setIsDownloading(false));
    }
  }, [keyword, organization?.id]);

  const handleAssignSuccess = useCallback(
    (email: string) => {
      setOpenConfirmModal(true);
      setOwner(email);
      load();
    },
    [load]
  );

  return (
    <>
      <SearchKeyword keyword={keyword} onSearch={handleSearch} />
      <Visible xs sm>
        <Button
          as={Link}
          to={PATHS.BUY_COUPONS}
          block
          className='mb-2'
          disabled={isLoading}>
          {i18n(
            'v2.pages.organizationDashboard.tabs.1.full.tabs.1.buttons.buy'
          )}
        </Button>

        <Search
          name='search'
          allowEmptySearch
          value={keyword}
          onSubmit={handleSearch}
          disabled={isLoading}
        />
      </Visible>
      <Flex className='mb-2' align='center' justify='between'>
        <div></div>

        <Visible md lg xl>
          <div>
            <Button
              variant='clear'
              className='mx-2'
              disabled={isLoading}
              loading={isDownloading}
              onClick={handleDownload}>
              {i18n(
                'v2.pages.organizationDashboard.tabs.1.full.tabs.1.buttons.download'
              )}{' '}
              <Icons.Download />
            </Button>

            <Button as={Link} to={PATHS.BUY_COUPONS} disabled={isLoading}>
              {i18n(
                'v2.pages.organizationDashboard.tabs.1.full.tabs.1.buttons.buy'
              )}
            </Button>
          </div>
        </Visible>
      </Flex>
      <Visible xs sm>
        <Table
          data={data}
          columns={mobileColumns}
          loading={isLoading}
          pageable={false}
          sortable={false}
        />
      </Visible>
      <Visible md lg xl>
        <Table
          data={data}
          columns={desktopColumns}
          loading={isLoading}
          pageable={false}
          sortable={false}
        />
      </Visible>
      <Row>
        <Visible lg xl>
          <Col xs={3} />
        </Visible>

        <Col>
          <Pagination
            align='center'
            className='my-4'
            onUpdate={handlePaging}
            pageIndex={pageIndex}
            pageCount={pageCount}
            disabled={isLoading}
          />
        </Col>

        <Visible md lg xl>
          <Col xs={3} className='d-flex align-items-center justify-content-end'>
            {isLoading ? (
              <Skeleton width={120} wrapper='small' />
            ) : (
              <small>
                <strong>{total}</strong>{' '}
                {i18n(
                  'v2.pages.organizationDashboard.tabs.1.full.tabs.1.table.count'
                )}
              </small>
            )}
          </Col>
        </Visible>
      </Row>

      <ModalAssignCoupon
        open={openModal}
        onClose={setOpenModal}
        onSuccess={handleAssignSuccess}
        organizationId={organization?.id}
        coupon={rowSelected}
        title={i18n(
          'v2.pages.organizationDashboard.tabs.1.full.tabs.1.modal.title'
        )}
        description={i18n(
          'v2.pages.organizationDashboard.tabs.1.full.tabs.1.modal.description'
        )}
        details={[
          {
            label: i18n(
              'v2.pages.organizationDashboard.tabs.1.full.tabs.1.modal.product'
            ),
            value: rowSelected?.type
          },
          {
            label: i18n(
              'v2.pages.organizationDashboard.tabs.1.full.tabs.1.modal.code'
            ),
            value: rowSelected?.code
          }
        ]}
        placeholder={i18n(
          'v2.pages.organizationDashboard.tabs.1.full.tabs.1.modal.placeholder'
        )}
        button={i18n(
          'v2.pages.organizationDashboard.tabs.1.full.tabs.1.modal.button'
        )}
        question={
          <p>
            {i18n(
              'v2.pages.organizationDashboard.tabs.1.full.tabs.1.modal.questions'
            )}{' '}
            <span
              className='anchor cursor-pointer'
              onClick={() => API.Zendesk.go()}>
              {i18n(
                'v2.pages.organizationDashboard.tabs.1.full.tabs.1.modal.contactUs'
              )}
            </span>
          </p>
        }
      />

      <Modal
        open={openConfirmModal}
        onClose={setOpenConfirmModal}
        type='success'
        title={i18n(
          'v2.pages.organizationDashboard.tabs.1.full.tabs.1.successModal.title'
        )}
        backdrop='static'
        footer={
          <Button block onClick={() => setOpenConfirmModal(false)}>
            {i18n(
              'v2.pages.organizationDashboard.tabs.1.full.tabs.1.successModal.button'
            )}
          </Button>
        }>
        <Callout variant='powderTint' className='mb-4'>
          <p>
            <strong className='d-block'>
              {i18n(
                'v2.pages.organizationDashboard.tabs.1.full.tabs.1.successModal.sentTo'
              )}
            </strong>
            {owner}
          </p>
          <p>
            <strong className='d-block'>
              {i18n(
                'v2.pages.organizationDashboard.tabs.1.full.tabs.1.successModal.product'
              )}
            </strong>
            {rowSelected?.type}
          </p>
          <p>
            <strong className='d-block'>
              {i18n(
                'v2.pages.organizationDashboard.tabs.1.full.tabs.1.successModal.code'
              )}
            </strong>
            {rowSelected?.code}
          </p>
        </Callout>
      </Modal>
    </>
  );
}
