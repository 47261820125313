import { Utils } from 'dawere-uic';
import { LayoutContext } from 'providers/LayoutProvider';
import { useContext } from 'react';

export const useLayout = () => {
  const context = useContext(LayoutContext);

  if (Utils.isEmpty(context)) {
    throw new Error('"useLayout" must be used within a LayoutProvider');
  }

  return context;
};
