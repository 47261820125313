import { Rest } from 'dawere-commons';
import { Utils } from 'dawere-uic';

const PICS_LOOKUP: any = {};

async function formatComment(comment: any) {
  const id = comment.user?.id;
  const url = `https://storage.googleapis.com/storage.dawere.com/newBE/profilePictures/${id}.jpg`;

  try {
    if (PICS_LOOKUP[id] === undefined) {
      const response = await fetch(url);
      if (response.ok) {
        PICS_LOOKUP[id] = url;
      } else {
        PICS_LOOKUP[id] = null;
      }
    }
  } catch (e) {
    console.error(e);
  }

  return {
    id: comment.id,
    avatar: PICS_LOOKUP[id],
    date: comment.publicationDate.endsWith('Z')
      ? comment.publicationDate
      : `${comment.publicationDate}Z`,
    pinned: comment.pinned,
    censored: comment.censored,
    hasVoted: comment.hasVoted,
    verified: comment.user?.isModerator,
    open: false,
    text: comment.content,
    votes: comment.numberOfVotes,
    numberOfComments: comment.numberOfComments,
    userId: comment.user?.id,
    name: comment.user?.name,
    lastName: comment.user?.lastName,
    parentId: comment.parentId,
    offset: 0,
    comments: []
  };
}

function list(
  contentId: string,
  params?: {
    parent_id?: number;
    lastId?: number;
    size?: number;
    offset?: number;
    sort?: 'CHRONOLOGICAL' | 'RELEVANCE';
  }
) {
  return Rest.get(`forum/threads/${contentId}/comments`, { params }).then(
    async ({ data }) => {
      // const comments = await data.comments.map(formatComment);
      const comments: any[] = [];
      for (const comment of data.comments) {
        comments.push(await formatComment(comment));
      }

      return {
        comments,
        lastId: data.lastId,
        eoc:
          comments.length === 0 ||
          (!Utils.isUndefined(params) &&
            !Utils.isUndefined(params.size) &&
            comments.length < params.size)
      };
    }
  );
}

function comment(
  contentId: string,
  subjectId: string,
  content: string,
  parentId?: number
) {
  return Rest.post(`forum/threads/${contentId}/comments`, {
    content,
    subjectId,
    parentId
  }).then(async ({ data }) => {
    return await formatComment(data);
  });
}

function vote(commentId: number, vote: boolean = true) {
  return Rest.post(`forum/comments/${commentId}/votes`, {
    vote: vote ? 1 : 0
  });
}

function getTotal(contentId: string) {
  return Rest.get(`forum/threads/${contentId}/comments?count`).then(
    ({ data }) => data
  );
}

export const Forum = {
  list,
  comment,
  getTotal,
  vote
};
