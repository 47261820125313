import { i18n } from 'dawere-commons';
import { Block, Col } from 'dawere-uic';

export function PhilosophySection() {
  return (
    <Block>
      <Col className='mb-4' xs={12}>
        <h2 className='text-primary'>
          {i18n('v2.pages.philosophy.section4.title')}
        </h2>
      </Col>

      <Col xs={12} md={6} className='mb-4'>
        <h3>{i18n('v2.pages.philosophy.section4.item1.title')}</h3>
        <p
          dangerouslySetInnerHTML={{
            __html: i18n('v2.pages.philosophy.section4.item1.text')
          }}
        />
      </Col>
      <Col xs={12} md={6} className='mb-4'>
        <h3>{i18n('v2.pages.philosophy.section4.item2.title')}</h3>
        <p
          dangerouslySetInnerHTML={{
            __html: i18n('v2.pages.philosophy.section4.item2.text')
          }}
        />
      </Col>
      <Col xs={12} md={6} className='mb-4'>
        <h3>{i18n('v2.pages.philosophy.section4.item3.title')}</h3>
        <p
          dangerouslySetInnerHTML={{
            __html: i18n('v2.pages.philosophy.section4.item3.text')
          }}
        />
      </Col>
      <Col xs={12} md={6} className='mb-4'>
        <h3>{i18n('v2.pages.philosophy.section4.item4.title')}</h3>
        <p
          dangerouslySetInnerHTML={{
            __html: i18n('v2.pages.philosophy.section4.item4.text')
          }}
        />
      </Col>
    </Block>
  );
}
