import { i18n } from 'dawere-commons';
import { Button, Col, Divider, Empty, Row, Skeleton } from 'dawere-uic';
import { useSession } from 'hooks';
import { useCallback, useState } from 'react';
import { ModalAddTutor } from '../components/ModalAddTutor';
import { TutorCard } from '../components/TutorCard';
import { TutorInvitationCard } from '../components/TutorInvitationCard';
import { usePermissions } from '../hooks/usePermissions';

export function MyTutors({ ...rest }: any) {
  const { getMe } = useSession();
  const { myTutors, myTutorInvitations, isLoadingMyTutors, loadMyTutors } =
    usePermissions();

  const [isOpen, setIsOpen] = useState(false);

  const handleChange = useCallback(() => {
    getMe().then(() => loadMyTutors());
  }, [getMe, loadMyTutors]);

  const handleOpen = useCallback(() => setIsOpen(true), []);

  return (
    <>
      <Col xs={12} className='mb-4'>
        <Divider section>{i18n('v2.pages.permissions.myTutors.title')}</Divider>

        <Row align='center' className='mb-4'>
          <Col xs={12} md={8} lg={9}>
            <p className='mb-0'>
              {i18n('v2.pages.permissions.myTutors.description')}
            </p>
          </Col>

          <Col xs={12} md={4} lg={3} className='text-md-right'>
            <Button
              className='w-100 w-md-auto | mb-4 mb-md-0 mt-md-2'
              disabled={isLoadingMyTutors}
              onClick={handleOpen}>
              {i18n('v2.pages.permissions.myTutors.buttons.add')}
            </Button>
          </Col>
        </Row>

        {isLoadingMyTutors ? (
          <>
            {Array(3)
              .fill(0)
              .map((_, index) => (
                <Skeleton height={80} key={index} className='mb-2' />
              ))}
          </>
        ) : (
          <>
            {myTutors.length > 0 || myTutorInvitations.length > 0 ? (
              <>
                {myTutors.map((item) => (
                  <TutorCard
                    key={item.id}
                    item={item}
                    onChange={handleChange}
                  />
                ))}

                {myTutorInvitations.map((item) => (
                  <TutorInvitationCard
                    key={item.id}
                    item={item}
                    onChange={handleChange}
                  />
                ))}
              </>
            ) : (
              <div className='bg-cultured rounded-3'>
                <Empty size='xsmall'>
                  {i18n('v2.pages.permissions.myTutors.nothing')}
                </Empty>
              </div>
            )}
          </>
        )}
      </Col>

      <ModalAddTutor
        open={isOpen}
        onClose={setIsOpen}
        onSuccess={loadMyTutors}
      />
    </>
  );
}
