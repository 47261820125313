import { i18n } from 'dawere-commons';
import { Block, Col, Iframe, Visible } from 'dawere-uic';

export function WelcomeSection() {
  return (
    <>
      <Visible xs sm>
        <Col md={7}>
          <Iframe
            height={380}
            src={i18n('v2.pages.philosophy.section1.video')}
          />
        </Col>
      </Visible>

      <Block align='center'>
        <Col md={5}>
          <h1 className='mb-4 text-primary'>
            {i18n('v2.pages.philosophy.section1.title')}
          </h1>

          <p
            dangerouslySetInnerHTML={{
              __html: i18n('v2.pages.philosophy.section1.text')
            }}
          />
        </Col>
        <Visible md lg xl>
          <Col md={7}>
            <Iframe
              height={380}
              className='shadow rounded-8 d-inline-block'
              src={i18n('v2.pages.philosophy.section1.video')}
            />
          </Col>
        </Visible>
      </Block>
    </>
  );
}
