import { i18n } from 'dawere-commons';
import { Block, Button, Col } from 'dawere-uic';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';

export function CatalogSection() {
  return (
    <Block>
      <Col>
        <h2>{i18n('v2.pages.home.section12.title')}</h2>
        <p className='mb-5'>{i18n('v2.pages.home.section12.text')}</p>

        <Button
          as={Link}
          to={PATHS.CATALOG}
          variant='outline'
          className='mb-2 mb-md-0 mr-md-2 | w-100 w-md-auto'>
          {i18n('v2.pages.home.section12.buttons.catalog')}
        </Button>
      </Col>
    </Block>
  );
}
