import * as accountApi from './account';
import * as cartApi from './cart';
import * as classroomApi from './classroom';
import * as couponsApi from './coupons';
import * as documentsApi from './documents';
import * as enrollmentApi from './enrollment';
import * as evaluationsApi from './evaluations';
import * as forumApi from './forum';
import * as geoApi from './geo';
import * as helpApi from './help';
import * as invitationsApi from './invitations';
import * as notificationsApi from './notifications';
import * as organizationsApi from './organizations';
import * as paymentsApi from './payments';
import * as picklistsApi from './picklists';
import * as programsApi from './programs';
import * as reportsApi from './reports';
import * as studentsApi from './students';
import * as subjectsApi from './subjects';
import * as taskmanagerApi from './taskmanager';
import * as topicsApi from './topics';
import * as tutorsApi from './tutors';
import * as walletApi from './wallet';
import * as zendeskApi from './zendesk';

export const API = {
  ...accountApi,
  ...programsApi,
  ...topicsApi,
  ...subjectsApi,
  ...helpApi,
  ...organizationsApi,
  ...studentsApi,
  ...couponsApi,
  ...geoApi,
  ...picklistsApi,
  ...documentsApi,
  ...notificationsApi,
  ...reportsApi,
  ...invitationsApi,
  ...cartApi,
  ...enrollmentApi,
  ...classroomApi,
  ...walletApi,
  ...evaluationsApi,
  ...paymentsApi,
  ...zendeskApi,
  ...tutorsApi,
  ...forumApi,
  ...taskmanagerApi
};
