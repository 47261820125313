import { i18n } from 'dawere-commons';
import {
  Block,
  Col,
  Flex,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  useRouter,
  Visible
} from 'dawere-uic';
import { PageWrapper } from 'pages';
import { OrganizationProvider } from 'providers';
import { getSEO } from 'utils';
import { ActiveTab } from './sections/ActiveTab';
import { Heading } from './sections/Heading';
import { PendingTab } from './sections/PendingTab';

/**
 * Path: /miembros/:organizationId
 * Key: MEMBERS_LIST
 * @returns {React.ReactElement}
 */
export default function MembersPage() {
  const { query } = useRouter();

  return (
    <PageWrapper>
      {getSEO('v2.pages.members.seo', true)}

      <OrganizationProvider id={query?.organizationId}>
        <Block narrow>
          <Heading />

          <Col xs={12}>
            <Tabs>
              <Flex align='center' justify='between' className='mb-4'>
                <TabList scrollable='true'>
                  <Tab>{i18n('v2.pages.members.tabs.1.title')}</Tab>
                  <Tab>{i18n('v2.pages.members.tabs.2.title')}</Tab>
                </TabList>

                <Flex align='center' className='pl-2 bg-white'>
                  <Visible md lg xl>
                    <div id='invite-member-portal'></div>

                    <div id='grid-portal-search'></div>
                  </Visible>
                </Flex>
              </Flex>

              <Visible xs sm>
                <div id='invite-member-portal'></div>

                <div id='grid-portal-search'></div>
              </Visible>

              <TabPanel>
                <ActiveTab />
              </TabPanel>
              <TabPanel>
                <PendingTab />
              </TabPanel>
            </Tabs>
          </Col>
        </Block>
      </OrganizationProvider>
    </PageWrapper>
  );
}
