import { Share } from '@mui/icons-material';
import { Session, i18n } from 'dawere-commons';
import { Button, Callout, Copier, Flex, Modal } from 'dawere-uic';
import { useState } from 'react';
import { PATHS } from 'router';
import styled from 'styled-components';

export function InviteFriendModal() {
  const [open, setOpen] = useState(false);
  const account = Session.getAccountInfo();
  const path = PATHS.get('REFERENCE', { referredBy: account?.id }).substring(3);

  return (
    <>
      <Button
        className='w-100 w-md-auto | mb-3 mb-md-0'
        variant='outline'
        onClick={() => setOpen(true)}
        icon={<Share fontSize='small' />}>
        {i18n('v2.inviteFriends.button')}
      </Button>

      <Modal
        title={i18n('v2.inviteFriends.title')}
        open={open}
        onClose={() => setOpen(false)}>
        <p className='fs-1 text-primary'>{i18n('v2.inviteFriends.text1')}</p>
        <p>{i18n('v2.inviteFriends.text2')}</p>
        <p> {i18n('v2.inviteFriends.text3')}</p>

        <Callout variant='powderTint'>
          <Flex gap={3}>
            <LinkStyled>{`${window.location.origin}${path}`}</LinkStyled>

            <Flex kept>
              <Copier text={`${window.location.origin}${path}`} hideText />
            </Flex>
          </Flex>
        </Callout>
      </Modal>
    </>
  );
}

const LinkStyled = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 24px - var(--dw-spacer-3));
`;
