import { i18n } from 'dawere-commons';
import { Breadcrumbs, Col, Wizard, useRouter } from 'dawere-uic';
import { useCallback, useEffect } from 'react';
import { Link, Prompt } from 'react-router-dom';
import { useBuyCoupons } from '../hooks/useBuyCoupons';
import { Checkout } from './Checkout';
import { Step1 } from './Step1';

const initBeforeUnLoad = (showExitPrompt: boolean) => {
  window.onbeforeunload = (event) => {
    // Show prompt based on state
    if (showExitPrompt) {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = '';
      }
      return '';
    }
  };
};

export function MainWrapper() {
  const { total, step, setStep, isPaid } = useBuyCoupons();
  const { history } = useRouter();

  const handleClick = useCallback(
    (e: any) => {
      e.preventDefault();
      history?.goBack();
    },
    [history]
  );

  // Initialize the beforeunload event listener after the resources are loaded
  window.onload = function () {
    initBeforeUnLoad(total > 0);
  };

  // Re-Initialize the onbeforeunload event listener
  useEffect(() => {
    initBeforeUnLoad(total > 0);
    return () => initBeforeUnLoad(false);
  }, [total]);

  return (
    <Col xs={12} md={step === '1' ? 12 : 7}>
      <Breadcrumbs
        as={Link}
        onBackClick={handleClick}
        withBackArrow
        routes={[{ breadcrumb: i18n('ui.commons.exit'), isBack: true }]}
        className='mb-4'
      />

      <Wizard onStepChange={setStep} stepsDisabled={total === 0 ? ['2'] : []}>
        <Wizard.Step id='1' title={i18n('v2.pages.buyCoupons.steps.1.title')}>
          <Step1 />
        </Wizard.Step>

        <Wizard.Step id='2' title={i18n('v2.pages.buyCoupons.steps.2.title')}>
          <Checkout />
        </Wizard.Step>
      </Wizard>

      <Prompt
        when={total > 0 && !isPaid}
        message={() => i18n('v2.pages.buyCoupons.leavePage')}
      />
    </Col>
  );
}
