import { API } from 'api';
import { i18n } from 'dawere-commons';
import { Block, Col } from 'dawere-uic';
import { PageWrapper } from 'pages';
import { useEffect } from 'react';
import { getSEO } from 'utils';
import { ASSETS } from 'values';

/**
 * Path: /ayuda
 * Key: HELP_CENTER
 * @returns {React.ReactElement}
 */
export default function RedirectHelpPage() {
  useEffect(() => {
    setTimeout(() => {
      API.Zendesk.go('', true, '_self');
    }, 5000);
  }, []);

  return (
    <PageWrapper>
      {getSEO('v2.pages.helpCenter.seo')}

      <Block className='text-center'>
        <Col>
          <img
            src={ASSETS.ILLUSTRATIONS.GENERAL_VIDEO_CLASS}
            alt={i18n('v2.pages.helpCenter.landing.title')}
            className='mb-3 img-fluid'
            width={500}
          />

          <h2 className='mb-3'>Redirigiendo...</h2>

          <h4 className='m-0 text-muted'>
            Por favor espera. No refresques ni cierres esta pestaña.
          </h4>
        </Col>
      </Block>
    </PageWrapper>
  );
}
