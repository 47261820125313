import { i18n } from 'dawere-commons';
import { Block, Col } from 'dawere-uic';
import { ASSETS } from 'values';

export function MisionSection() {
  return (
    <Block align='center'>
      <Col className='mb-4 mb-md-0' md={6}>
        <img
          src={ASSETS.ILLUSTRATIONS.ROCKETS}
          alt={i18n('v2.pages.philosophy.section2.title')}
          className='img-fluid'
        />
      </Col>

      <Col md={6}>
        <h2 className='text-primary'>
          {i18n('v2.pages.philosophy.section2.title')}
        </h2>
        <h4 className='mb-4'>
          {i18n('v2.pages.philosophy.section2.subtitle')}
        </h4>
        <p
          dangerouslySetInnerHTML={{
            __html: i18n('v2.pages.philosophy.section2.text')
          }}
        />
      </Col>
    </Block>
  );
}
