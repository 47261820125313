import { API } from 'api';
import { i18n } from 'dawere-commons';
import {
  AutoComplete,
  Button,
  Empty,
  Flex,
  Kanban,
  Skeleton,
  toast,
  Visible
} from 'dawere-uic';
import { useOrganization } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { ObjectParam, useQueryParams } from 'use-query-params';
import { QPARAMS } from 'values';
import { ModalAddMember } from '../../components/ModalAddMember';
import { ModalAddTeacher } from '../../components/ModalAddTeacher';
import { ModalAssignTeacher } from '../../components/ModalAssignTeacher';
import { useOrganizationChart } from './hooks/useOrganizationChart';
import { MembersAvatars } from './MembersAvatars';
import { TeachersAvatars } from './TeachersAvatars';

export function MainWrapper() {
  const [query, setQuery] = useQueryParams({
    [QPARAMS.Program]: ObjectParam
  });

  const { organization } = useOrganization();
  const { programs, isLoadingPrograms, teachers } = useOrganizationChart();

  const [isLoading, setIsLoading] = useState(false);
  const [kanban, setKanban] = useState<any[]>([]);
  const [program, setProgram] = useState<any>();
  const [teacherIds, setTeacherIds] = useState<any[]>([]);

  const [openAddTeacherModal, setOpenAddTeacherModal] = useState(false);
  const [openAddMemberModal, setOpenAddMemberModal] = useState(false);
  const [openAssignTeacherModal, setOpenAssignTeacherModal] = useState(false);
  const [subjectTo, setSubjectTo] = useState<any>(null);

  const load = useCallback(
    ({ value, label }: any) => {
      setIsLoading(true);
      setProgram({ value, label });

      setQuery({ [QPARAMS.Program]: { v: value, l: label } }, 'pushIn');

      API.Organizations.getLineup(organization?.id, value)
        .then((response: any) => {
          setKanban(response);
        })
        .finally(() => setIsLoading(false));
    },
    [organization?.id, setQuery]
  );

  const handleProgramChange = useCallback(
    (data: any) => {
      load(data);
    },
    [load]
  );

  const handleAssignTeacher = useCallback((data: any) => {
    setOpenAssignTeacherModal(true);
    setSubjectTo(data);
  }, []);

  const showSubject = useCallback(
    (teachers: any[] = []) => {
      if (!teacherIds.length) {
        return true;
      }
      const some = teachers.some((teacher: any) =>
        teacherIds.includes(teacher.id)
      );
      return some;
    },
    [teacherIds]
  );

  const handleUninvite = useCallback(
    (teacher: any) => {
      API.Organizations.removePendingInvitation(
        organization?.id,
        teacher.id
      ).then(() => {
        toast.success(
          i18n('v2.pages.teachers.modals.delete.invitationSuccess')
        );
        load(program);
      });
    },
    [organization?.id, load, program]
  );

  const handleUnassign = useCallback(
    (teacher: any) => {
      API.Organizations.Teachers.unassign(
        organization?.id,
        teacher.relationshipId
      ).then(() => {
        toast.success(
          i18n('v2.pages.teachers.modals.delete.success', {
            name: teacher?.name
          })
        );
        load(program);
      });
    },
    [organization?.id, load, program]
  );

  const handleResend = useCallback(
    (teacher: any) => {
      API.Organizations.Teachers.invite(organization?.id, {
        emails: [teacher.email]
      }).then(() => {
        toast.success(i18n('v2.pages.teachers.modals.resend.success'));
        load(program);
      });
    },
    [organization?.id, load, program]
  );

  useEffect(() => {
    if (query[QPARAMS.Program]) {
      load({
        value: query[QPARAMS.Program]?.v,
        label: query[QPARAMS.Program]?.l
      });
    }
  }, [load, query]);

  return (
    <>
      <Visible xs sm md>
        <div>
          <MembersAvatars />

          <Button
            className='mb-4'
            block
            onClick={() => setOpenAddMemberModal(true)}
            disabled={isLoading}>
            {i18n('v2.pages.members.buttons.add')}
          </Button>
        </div>

        <div>
          <TeachersAvatars
            teacherIds={teacherIds}
            onSelectTeacher={setTeacherIds}
          />

          <Button
            className='mb-4'
            block
            onClick={() => setOpenAddTeacherModal(true)}
            disabled={isLoading}>
            {i18n('v2.pages.teachers.buttons.add')}
          </Button>
        </div>

        <AutoComplete
          placeholder={i18n(
            'v2.pages.organizationDashboard.tabs.5.placeholder'
          )}
          name='programs'
          options={programs}
          loading={isLoadingPrograms}
          className='mb-4'
          onChange={handleProgramChange}
          value={program}
        />
      </Visible>
      <Visible lg xl>
        <Flex align='end' justify='between' className='mb-3'>
          <Flex align='end' className='mr-3'>
            <div
              style={{
                width: '300px',
                minWidth: '300px',
                maxWidth: '300px'
              }}>
              <AutoComplete
                placeholder={i18n(
                  'v2.pages.organizationDashboard.tabs.5.placeholder'
                )}
                name='programs'
                options={programs}
                loading={isLoadingPrograms}
                className='mb-0'
                onChange={handleProgramChange}
                value={program}
              />
            </div>

            <TeachersAvatars
              teacherIds={teacherIds}
              onSelectTeacher={setTeacherIds}
            />

            <MembersAvatars />
          </Flex>

          <Flex align='center'>
            <Button
              variant='outline'
              onClick={() => setOpenAddMemberModal(true)}
              disabled={isLoading}>
              {i18n('v2.pages.members.buttons.add')}
            </Button>
            <Button
              className='ml-2'
              onClick={() => setOpenAddTeacherModal(true)}
              disabled={isLoading}>
              {i18n('v2.pages.teachers.buttons.add')}
            </Button>
          </Flex>
        </Flex>
      </Visible>
      {isLoading ? (
        <Skeleton.Kanban />
      ) : kanban.length > 0 ? (
        <Kanban>
          {kanban.map((period: any) => (
            <Kanban.Column key={period.id} name={period.name}>
              {period.subjects.map((subject: any) => (
                <>
                  {showSubject(subject.teachers) ? (
                    <Kanban.Card
                      key={subject.id}
                      id={subject.id}
                      program={{ id: program?.value, name: program?.label }}
                      period={{ id: period.id, name: period.name }}
                      title={subject.title}
                      thumbnail={subject.icon}
                      teachers={subject.teachers}
                      onAdd={handleAssignTeacher}
                      onUninvite={handleUninvite}
                      onRemove={handleUnassign}
                      onResend={handleResend}
                    />
                  ) : null}
                </>
              ))}
            </Kanban.Column>
          ))}
        </Kanban>
      ) : (
        <Empty className='py-7 bg-cultured rounded-3' />
      )}

      <ModalAddTeacher
        organizationId={organization?.id}
        open={openAddTeacherModal}
        onClose={setOpenAddTeacherModal}
      />

      <ModalAddMember
        organizationId={organization?.id}
        open={openAddMemberModal}
        onClose={setOpenAddMemberModal}
      />

      <ModalAssignTeacher
        organizationId={organization?.id}
        teachers={teachers}
        subjectTo={subjectTo}
        open={openAssignTeacherModal}
        onClose={setOpenAssignTeacherModal}
        onSuccess={() => load(program)}
      />
    </>
  );
}
