const es = {
  lang: `es`,
  pages: {
    notFound: {
      title: `Página no encontrada`,
      subtitle: `Por favor vuelve a nuestra página de inicio:`
    },
    pisaEvaluationResultDetails: {
      seo: {
        title: `Detalles: Evaluación sumativa - PISA`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      breadcrumbs: {
        2: `Detalles`
      },
      title: `Calificación obtenida`,
      description: `Este reporte presenta la calificación obtenida en la evaluación y un resumen de tu desempeño por competencias`,
      level: `Nivel de preparación`,
      topic: `Tema`,
      cognitiveDemand: `Demanda cognitiva`,
      button: `Seguir practicando`,
      answers: `Respuestas {{points}}/{{qty}}`
    },
    wallet: {
      seo: {
        title: `Vales de descuento`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      title: `Vales de descuento`,
      coupons: {
        title: `Cupones`,
        description: `Aquí se listan los cupones que te han sido asignados por la organización a la que perteneces o por algún patrocinante. Puedes usar estos cupones para pagar por el producto señalado en cada uno de ellos. Al usar un cupón, desaparecerá de esta lista.`,
        table: {
          count: `cupón(es)`,
          columns: {
            coupon: `Cupón`,
            product: `Producto`,
            code: `Código de cupón`,
            date: `Fecha de asignación`
          }
        }
      },
      balance: {
        title: `Balance`,
        description: `Puedes usar el saldo en tus vales de descuento para hacer pagos en Dawere siempre que sean en la misma divisa del crédito a favor.`
      }
    },
    program: {
      seo: {
        title: `Detalles del programa`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      details: {
        start: `INICIO`,
        end: `FIN`,
        calendarsDescription: `Calendarios con inscripciones abiertas`,
        discountPerc: `Con {{perc}}% de descuento`,
        installments: `En {{installments}} cuotas mensuales`,
        tooltip: `Los usuarios que usen esta modalidad de pago, se hacen responsables de cumplir con todas las cuotas mensuales a través de un compromiso de pago que adquieren con la institución.`,

        tabs: {
          1: {
            title: `Completo`
          },
          2: {
            title: `Fraccionado`
          }
        },
        buttons: {
          moreDates: `Más fechas`,
          lessDates: `Menos fechas`,
          enroll: `Inscríbete`,
          studentDashboard: `Panel de estudiante`
        }
      },
      section1: {
        pretitle: `Programas educativos del aula virtual`,
        buttons: {
          tryIt: `Pruébalo gratis por 7 días`
        }
      },
      section2: {
        subtitle1: `Obtén tu título de`,
        subtitle2: `Obtén tu certificado de`,
        license: `Licencia de funcionamiento`,
        buttons: {
          enroll: `Inscríbete ahora`
        }
      },
      section3: {
        title1: `Requisitos para aplicar al programa educativo`,
        title2: `Requisitos`
      },
      section4: {
        title1: `Contenido del programa`,
        title2: `Contenido del curso`,
        topics: `Temas`,
        exams: `Evaluaciones`,
        links: {
          details: `Ver Detalle`
        }
      }
    },
    subject: {
      seo: {
        title: `Detalles de la materia`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      breadcrumbs: {
        1: `Programas educativos`,
        3: `Detalles de la materia`
      },
      details: {
        description: `Inscripciones siempre abiertas`,
        buttons: {
          enroll: `Inscríbete ahora`
        }
      },
      section1: {
        description1: `Esta materia forma parte del programa`,
        description2: `Esta materia forma parte del curso`,
        buttons: {
          tryIt: `Pruébalo gratis por 7 días`
        }
      },
      section2: {
        title: `Contenido de la materia`,
        topic: `Tema {{number}}`,
        goals: `Objetivos`,
        videos: `Videos`,
        guides: `Material de lectura`,
        tests: `Evaluaciones de práctica`,
        buttons: {
          showAll: `Mostrar todo`
        }
      }
    },
    rememberPasswordRequested: {
      seo: {
        title: `¿Olvidaste tu contraseña?`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      title: `Revisa tu email`,
      subtitle: `Revisa tu email y haz clic en el enlace que te enviamos para cambiar tu contraseña`,
      noEmail: `¿No te llegó?`,
      buttons: {
        login: `Inicia sesión`
      },
      links: {
        contactUs: `Contáctanos`
      }
    },
    checkEmail: {
      seo: {
        title: `Verifica tu email`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      title: `Verifica tu email`,
      subtitle: `Revisa tu email y haz clic en el enlace que te enviamos para confirmar tu cuenta.`,
      noEmail: `¿No te llegó?`,
      buttons: {
        login: `Inicia sesión`,
        continue: `Continuar`
      },
      links: {
        contactUs: `Contáctanos`
      }
    },
    passwordCreated: {
      seo: {
        title: `Nueva contraseña creada`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      title: `Nueva contraseña creada`,
      subtitle: `Tu contraseña ha sido cambiada exitosamente`,
      buttons: {
        login: `Inicia sesión`
      }
    },
    verifiedAccount: {
      seo: {
        title: `Cuenta verificada`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      title: `Cuenta verificada`,
      subtitle: `Tu cuenta ha sido verificada correctamente.`,
      verifyingTitle: `Verificando cuenta`,
      verifyingSubtitle: `Estamos verificando tu cuenta, espera un momento.`,
      buttons: {
        login: `Inicia sesión`
      }
    },
    login: {
      title: `Inicia sesión en tu colegio virtual`,
      withoutAccount: `¿No tienes cuenta?`,
      form: {
        email: `Email`,
        password: `Contraseña`
      },
      helps: {},
      tooltips: {},
      buttons: {
        login: `Inicia sesión`,
        googleLogIn: `Inicia sesión con Google`
      },
      links: {
        forgotPassword: `¿Se te olvidó la contraseña?`,
        register: `Regístrate`
      },
      urls: {},
      images: {},
      seo: {
        title: `Dawere.com | Iniciar sesión`,
        description: `Ingresa a tu cuenta privada en Dawere.com. `,
        keywords: `inicio de sesión, login, dawere, dawere.com`,
        ogTitle: `Iniciar sesión`,
        ogDescription: `Ingresa a tu cuenta privada en Dawere.com.  `,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/seo_iniciodesesion.jpg`
      }
    },
    forgotPassword: {
      title: `¿Olvidaste tu clave? Recupera el acceso a tu colegio virtual`,
      subtitle: `Introduce el email al que tienes asociado la cuenta`,
      withoutAccount: `¿No tienes cuenta?`,
      toast: `Te hemos enviado un correo electrónico con un enlace para cambiar tu contraseña.`,
      form: {
        email: `Email`
      },
      buttons: {
        submit: `Enviar`
      },
      links: {
        register: `Regístrate`
      },
      seo: {
        title: `Verifica tu email primero`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      checkEmail: {
        title: `Verifica tu email`,
        text: `Para poder solicitar una nueva contraseña, debes primero verificar tu cuenta.`,
        buttons: {
          submit: `Enviar email de verificación`
        }
      }
    },
    resetPassword: {
      title: `Crea una nueva contraseña`,
      subtitle: `Escribe una nueva contraseña para acceder a tu cuenta.`,
      form: {
        confirmPassword: `Confirma tu contraseña`,
        success: `¡Tu contraseña ha sido cambiada!`
      },
      buttons: {
        submit: `Cambiar contraseña`
      },
      helps: {
        password: ``
      },
      seo: {
        title: `Cambiar contraseña`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      }
    },
    countrySelection: {
      seo: {
        title: `Dawere.com | Clases Virtuales para Latinoamérica`,
        description: `Dawere.com es el único colegio virtual de América Latina avalado por las autoridades educativas de México, Colombia, Venezuela, Honduras y Rep. Dominicana. En Estados Unidos está registrado en el Departamento de Educación de Florida.`,
        keywords: `clases virtuales, clases`,
        ogTitle: `Dawere: Clases Virtuales para Latinoamérica`,
        ogDescription: `Dawere es el único colegio virtual avalado por las autoridades educativas de México, Colombia, Venezuela, Honduras y Rep. Dominicana. En Estados Unidos está registrado en el Departamento de Educación de Florida.`,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/seo_seleccionaunpais.jpg`
      },
      title: `Bienvenido a tu colegio virtual internacional`,
      subtitle: `Sin importar en qué parte del mundo te encuentres, puedes inscribirte en el sistema educativo de tu preferencia y obtener tu diploma de bachillerato o secundaria.`
    },
    inscription: {
      seo: {
        title: `Inscripción`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      }
    },
    organizations: {
      seo: {
        title: ``,
        description: `Dawere trabaja de la mano con gobiernos, fundaciones y clubs deportivos para formar a jóvenes y adultos a través de educación de calidad, flexible y asequible.`,
        keywords: ``,
        ogTitle: ``,
        ogDescription: `Dawere trabaja de la mano con gobiernos, fundaciones y clubs deportivos para formar a jóvenes y adultos con educación de calidad, flexible y asequible.`,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/Ilustraciones/*PARA%20ORGANIZACIONES/ID_Pagina_Organizaciones_Bloque1_HERO.png`
      },
      section1: {
        slogan: `Educación acreditada y de calidad`,
        title: `Forma parte de un gran círculo virtuoso`,
        subtitle: `La mejor inversión que puede hacer una empresa, sociedad y nación es la educación de sus miembros.`,
        buttons: {
          login: `Inicia sesión como organización`,
          signup: `Regístrate como organización`
        }
      },
      certifications: {
        title: `Acreditados por COGNIA`,
        text: `
          <p>
           Esta certificación es una marca distintiva de calidad reconocida en el mundo entero. Contar con ella es prueba fehaciente de nuestro compromiso con la excelencia en la educación y con los estudiantes de Dawere.
            </p>
        `,
        images: {
          1: {
            url: `https://storage.googleapis.com/dawere-web-assets/Ilustraciones/COGNIA/SELLO%20COGNIA_Mesa%20de%20trabajo%201.svg`,
            alt: `Cognia`
          }
        }
      },
      section2: {
        title: `Solicita una demostración gratuita`,
        subtitle: `Descubre por qué fundaciones, empresas y gobiernos eligen el Bachillerato Virtual de Dawere para la formación de sus ciudadanos o beneficiarios.`,
        benefits: {
          1: {
            title: `Control`,
            subtitle: `y gestión de la compra y asignación de cupones de inscripción`
          },
          2: {
            title: `Monitoreo`,
            subtitle: `detallado del progreso académico de los estudiantes inscritos`
          },
          3: {
            title: `Acceso`,
            subtitle: `al contenido educativo con el que se están formando tus beneficiarios`
          }
        },
        form: {
          fullName: `Nombre completo`,
          email: `Email corporativo`,
          phoneNumber: `Teléfono`,
          organizationName: `Nombre de la organización`,
          role: `Cargo en tu organización`,
          summary: `¿Cómo podemos ayudarlos?`,
          errors: {
            fullName: `Nombre Inválido`
          }
        },
        buttons: {
          send: `Enviar`
        },
        links: {
          1: `Términos y condiciones`,
          2: `Políticas de privacidad`
        },
        urls: {
          1: `https://storage.googleapis.com/dawere-web-assets/pdf/TerminosyCondicionesEEUU.pdf`,
          2: `https://storage.googleapis.com/dawere-web-assets/pdf/PoliticadetratamientodedatosEEUUV2.pdf`
        },
        footer: {
          sentence1: `Al enviar tus datos, aceptas los`,
          sentence2: `de Dawere y sus`
        }
      },
      // Section 3: ../shared/alleis
      section4: {
        foundations: {
          title: `Para fundaciones`,
          subtitle: `DAWERE`,
          text: `
            <p>
              Desde entidades multilaterales hasta fundaciones locales utilizan nuestra plataforma para servir y fortalecer a comunidades cuyo futuro se ve amenazado por la falta de acceso a una educación de calidad.
            </p>
            <p>
              Te invitamos a que te apoyes en los programas educativos de Dawere para atender a grupos de individuos que ya tengas identificados como población vulnerable y en necesidad de nuestros servicios. También puedes  solicitarle a nuestro equipo de Becas Dawere que encuentre la demográfica que te gustaría beneficiar.
            </p>
          `,
          urls: {
            video: {
              url: `https://www.youtube.com/embed/l42u9s7n8_o`
            }
          }
        },
        governments: {
          title: `Para gobiernos`,
          subtitle: `DAWERE`,
          text: `
          <p>
              Según la UNESCO, en los sistemas educativos tradicionales de América Latina, solo 45% de los que inician el bachillerato se gradúan.
              </p>
            <p>
              Hay una deserción del 15% de los estudiantes todos los años y más del 30% de los jóvenes en edad escolar están fuera del sistema educativo tradicional. Con el uso de nuestra tecnología educativa, esto puede cambiar.
              </p>

            <p>
              Te invitamos a proveerle a todos tus ciudadanos educación de calidad gratuita. Parece una propuesta utópica pero se puede convertir en realidad gracias a los servicios de educación a distancia de Dawere. Nuestro Bachillerato Virtual ha sido diseñado para educar a millones de personas a una fracción del costo de la educación tradicional presencial. Compruébalo por ti mismo y empodera al país con la excelente educación que todos merecen.
              </p>


          `
        },
        companies: {
          title: `Para clubs deportivos`,
          subtitle: `DAWERE`,
          text: `
            <p>
              El mundo del deporte profesional es extremadamente reñido, por lo que solo un porcentaje minúsculo logra convertirse en jugador profesional. ¿Qué ocurre con el resto?

              </p>
            <p>
              Recibir educación de calidad en paralelo a los entrenamientos puede garantizarle un futuro a todos tus jugadores sin excepción.

              </p>
            <p>
              Nuestro Bachillerato Online se adapta al ritmo y horario de los estudiantes. Con Dawere tus jugadores pueden formarse académicamente y perseguir sus sueños.
              </p>
          `
        }
      }
      // Section 5: ../shared/contact
    },
    helpCenter: {
      seo: {
        title: `Dawere.com | Preguntas Frecuentes sobre Dawere`,
        description: `Si estás interesado en Dawere o ya utilizas nuestra plataforma, muchas de tus dudas pueden ser fácilmente aclaradas en la sección de preguntas frecuentes.`,
        keywords: ``,
        ogTitle: `Preguntas frecuentes sobre Dawere`,
        ogDescription: `Si estás interesado en Dawere o ya utilizas nuestra plataforma, muchas de tus dudas pueden ser fácilmente aclaradas en la sección de preguntas frecuentes.  `,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/seo_ayuda.jpg`
      },
      breadcrumbs: {
        1: `Ayuda`
      },
      urls: {
        register: `https://app.dawere.com.{{iso}}/#!/?register=true`,
        scholarshipForm: ``
      },
      contactCard: {
        description: `¿No pudiste resolver tu problema y aún necesitas ayuda?`,
        link: `Comunícate con nosotros`
      },
      landing: {
        title: `¿En qué podemos ayudarte?`,
        subtitle: `Selecciona una opción de la lista a continuación:`
      },
      article: {
        seo: {
          description: `Si estás interesado en Dawere o ya utilizas nuestra plataforma, muchas de tus dudas pueden ser fácilmente aclaradas en la sección de preguntas frecuentes.`,
          keywords: `dawere, dawere.com`,
          ogTitle: ``,
          ogDescription: ``,
          ogImage: ``
        },
        listTitle: `Otros artículos de:`,
        links: {
          back: `Volver`
        }
      },
      contact: {
        seo: {
          title: `Dawere.com | Contáctanos`,
          description: `Si estás interesado en Dawere o ya utilizas nuestra plataforma, muchas de tus dudas pueden ser fácilmente aclaradas en la sección de preguntas frecuentes.`,
          keywords: `contacto, dawere, dawere.com`,
          ogTitle: `Comunícate con Dawere`,
          ogDescription: `Dawere quiere brindarte apoyo para solucionar tu problema específico. Provee la información necesaria y te contactaremos a la brevedad posible. `,
          ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/seo_contacto.jpg`
        },
        title: `Comunícate con nosotros`,
        subtitle: ` Queremos brindarte apoyo para solucionar tu problema específico.
          Danos más información a continuación.`,
        info: {
          description: ` Si necesitas una ayuda más personalizada, comunícate a
                    nuestros números:`,
          enrolled: {
            students: `Estudiantes inscritos`,
            phone: `0800 567 8765`,
            whatsapp: `0800 567 8765`
          },
          unregistered: {
            students: `Estudiantes no inscritos`,
            phone: `0800 567 0098`,
            whatsapp: `+58 412 321 7653`
          }
        },
        card: {
          description: `También puedes explorar nuestros artículos de ayuda para aclarar tus dudas`,
          link: `Ver lista de tópicos de ayuda `
        },
        links: {
          back: `Volver`
        },
        buttons: {
          continue: `Continuar`,
          submit: `Envía tu solicitud`
        },
        steps: {
          0: {
            title: `¿Ya estás inscrito en alguno de los programas educativos de Dawere?`,
            option1: `No soy estudiante de Dawere`,
            option2: `Sí, soy estudiante de Dawere`
          },
          1: {
            title: `¿Con qué necesitas ayuda?`
          },
          2: {
            title: `Selecciona un tema en específico`
          },
          3: {
            title: `Provee más información`,
            subtitle: `Confirma tu correo y describe tu problema o duda. También puedes
              adjuntar un archivo si lo deseas`,
            form: {
              name: `Nombre`,
              email: `Email`,
              phoneNumber: `Teléfono`,
              comments: `Descripcion`,
              errors: {
                name: `Nombre Inválido`
              }
            },
            helps: {
              file: `Adjunta un archivo si aplica.`
            }
          }
        }
      }
    },
    demoRequested: {
      seo: {
        title: `Dawere.com | Demostración solicitada`,
        description: `Demostración solicitada.`,
        keywords: `demostracion, organizaciones`,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      title: `¡Hemos recibido tu información!`,
      subtitle: `Por favor selecciona la fecha en la que deseas agendar la reunión`,
      buttons: {
        home: `Ir a la página principal`,
        catalog: `Ver programas educativos`
      }
    },
    catalog: {
      seo: {
        title: ``,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      primary: {
        degree: `Certificado a obtener`,
        language: `Idioma`,
        duration: `Duración`,
        school: `Institución educativa`,
        description: `Descripción`,
        more: `Ver detalles`
      }
    },
    home: {
      mainProgramId: {
        dev: ``,
        qa: ``,
        prod: ``
      },
      doubleDegreeProgramId: {
        dev: ``,
        qa: ``,
        prod: ``
      },
      seo: {
        title: ``,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      info: {
        whatsappText: `Habla con un Asesor de Admisiones`,
        text: `Contáctanos y aclararemos cualquier duda que tengas`,
        whatsappNumber: ``,
        phoneNumber: ``,
        emailInfo: ``
      },
      section1: {
        title: `¡Ahora sí puedes graduarte! Cursa tu bachillerato virtualmente`,
        subtitle: `Colegio: Dawere`,
        buttons: {
          inscription: `Pruébalo gratis`,
          moreInfo: `Más información`,
          moreCatalog: `Elige el programa educativo a probar`
        }
      },
      section2: {
        title: ``,
        subtitle: `Educación acreditada y de calidad`,
        text: ``,
        images: {
          1: {
            url: `https://storage.googleapis.com/dawere-web-assets/logotipos/logo_ministeriopoder.svg`,
            alt: `MPPE`
          }
        }
      },
      section3: {
        title: `Beneficios de estudiar el bachillerato con nosotros`,
        benefits: {
          1: {
            title: `Calidad`,
            description: `Acreditado por las autoridades educativas del país`
          },
          2: {
            title: `Conveniencia`,
            description: `Clases en tu horario, a tu ritmo y desde dónde quieras
`
          },
          3: {
            title: `Rapidez`,
            description: `El bachillerato puede ser cursado en 3 años o menos`
          },
          4: {
            title: `Accesibilidad`,
            description: `Educación a módico precio y programa de becas`
          }
        }
      },
      section4: {
        title: `Comienza tu periodo de prueba gratis`,
        text: `Puedes cursar el programa <strong>{{program}}</strong> de forma gratuita durante {{days}} días. Conoce de primera mano las bondades del aula virtual, incluyendo video-clases, lecturas, evaluaciones (formativas y sumativas) y acceso a foro-chats por materia y encuentros síncronos con profesores.  `,
        buttons: {
          enroll: `Pruébalo gratis`
        }
      },
      download: {
        title: `Pase lo que pase, podrás estudiar`,
        text: `Con Dawere ya estudias donde y cuando quieras, y ahora, con la nueva aplicación, ¡puedes hacerlo incluso sin internet! Solo debes descargar el contenido educativo para ver tus clases de manera offline cuando lo necesites. Tu progreso se actualizará automáticamente al conectarte de nuevo a Internet. `,
        slogan: '¡Descarga la app ahora!'
      },
      section5: {
        title: `Obtén la doble titulación`,
        text: `
          <p>
            Ofrecemos la doble titulación a través de nuestro colegio en Miami registrado ante el Departamento de Educación de Florida bajo el Nº 6139
            </p>
        `,
        buttons: {
          enroll: `Prueba gratis`,
          moreInfo: `Mas información`
        },
        images: {
          1: {
            url: `https://storage.googleapis.com/dawere-web-assets/logotipos/logo_floridadepartment.svg`,
            alt: `Florida`
          },
          2: {
            url: ``,
            alt: `Cognia`
          }
        }
      },
      certifications: {
        title: `Acreditaciones`,
        text: `
          <p>
            Dawere International High School está acreditado por Cognia. Una prestigiosa organización global sin fines de lucro que trabaja en más de 90 países y que, todos los días, atiende a 36,000 instituciones, casi 25 millones de estudiantes y 5 millones de educadores. Esta certificación de Cognia es una marca distintiva de calidad reconocida en el mundo entero. Contar con ella es prueba fehaciente de nuestro compromiso con la excelencia en la educación y con los estudiantes de Dawere.
            </p>
        `,
        images: {
          1: {
            url: `https://storage.googleapis.com/dawere-web-assets/Ilustraciones/COGNIA/SELLO%20COGNIA_Mesa%20de%20trabajo%201.svg`,
            alt: `Cognia`
          }
        }
      },
      section6: {
        title: `¿Cómo funciona Dawere?`,
        steps: {
          1: {
            title: `Inscríbete`,
            subtitle: `PASO 1`,
            text: ` Si tienes 15 años de edad o más puedes inscribirte en Dawere sin importar tu ubicación geográfica. Solo debes llenar el formulario de inscripción, señalar los años aprobados hasta la fecha y realizar tu pago.`
          },
          2: {
            title: `Entrega tus requisitos`,
            subtitle: `PASO 2`,
            text: `Debes entregar copia de tu cédula de identidad y copia de la partida de nacimiento, entre otros recaudos.`
          },
          3: {
            title: `Estudia`,
            subtitle: `PASO 3`,
            text: `En nuestra escuela virtual aprendes con videoclases pregrabadas, guías PDF, evaluaciones de práctica, un foro-chat para aclarar dudas y más. Estudia a tu ritmo, en tu horario y desde cualquier lugar con acceso a internet. Dawere es fácil de navegar desde una computadora, tablet o teléfono inteligente.`
          },
          4: {
            title: `Gradúate`,
            subtitle: `PASO 4`,
            text: `Al aprobar todas las materias de rigor y asistir a tu ceremonia de graduación virtual, podrás retirar tu título por nuestras oficinas o solicitar que se te envíe hasta la puerta de tu casa.`
          }
        },
        links: {
          requirements: `Ver lista completa de requisitos`
        },
        buttons: {
          moreInfo: `Más información`
        }
      },
      section7: {
        title: `Conoce a nuestros estudiantes`
      },
      // Section 8: ../shared/alleis
      section9: {
        categories: {
          1: {
            subtitle: `PADRES`,
            title: `Monitorea el avance académico de tus hijos`,
            text: `Como padre podrás monitorear el avance académico de tus hijos e identificar con prontitud sus oportunidades de mejora. También recibirás avisos sobre sus calificaciones, su rendimiento escolar en cada materia hasta la fecha, los exámenes por presentar y más. Regístrate en nuestra escuela virtual para recibir más información sobre los beneficios de nuestra modalidad de estudio a distancia.   `,
            buttons: {
              enroll: `Regístrate como padre`
            }
          },
          2: {
            subtitle: `ORGANIZACIONES`,
            title: `Gestiona tu proyecto educativo`,
            text: `Empodera a los miembros de tu empresa, comunidad o nación dándoles educación virtual de calidad. Puedes comprar cupones de inscripción por lotes y asignarlos a cada estudiante de forma ordenada. Podrás monitorear el aspecto administrativo de tus beneficiarios así como seguir muy de cerca su progreso académico. Ten total control sobre tu proyecto educativo. Regístrate para recibir más información sobre las bondades que Dawere ofrece a organizaciones como la tuya.`,
            buttons: {
              enroll: `Regístrate como organización`
            }
          },
          3: {
            subtitle: `ESTUDIANTES`,
            title: `Estás más cerca de ser bachiller`,
            text: ``,
            buttons: {
              enroll: `Regístrate como estudiante`
            }
          }
        }
      },
      section10: {
        subtitle: `Resultados de la encuesta de satisfacción de nuestros estudiantes en el 2020`,
        title: `El 93% de los estudiantes reportan que la calidad educativa que reciben en Dawere es igual o superior a lo que recibían en su colegio presencial.`
      },
      // Section 11: ../shared/contact
      section12: {
        title: `Explora todos nuestros cursos`,
        text: `Dawere tiene una variedad de programas educativos y cursos además del programa de Bachillerato Online. Entra en nuestro catálogo y explora nuestra oferta.`,
        buttons: {
          catalog: `Ver programas educativos`,
          enroll: `Pruébalo gratis`
        }
      },
      reviews: {
        title: 'Nuestras reseñas en Google',
        footnote: `Calificación promedio de <strong>{{ranking}}</strong> / 5, basado en <strong>{{total}}</strong> reseñas.`
      }
    },
    shared: {
      allies: {
        title: `Grandes organizaciones confían en Dawere`
      },
      contact: {
        title: `Contáctanos`,
        text: `Con gusto atenderemos cualquier pregunta que tengas sobre Dawere.`,
        info: {
          phone: {
            title: `TELÉFONO`,
            text: ``
          },
          whatsapp: {
            title: `WHATSAPP`,
            text: ``
          },
          email: {
            title: `EMAIL`,
            text: ``
          }
        },
        buttons: {
          faq: `Preguntas frecuentes`
        }
      }
    },
    register: {
      seo: {
        title: `Dawere.com | Regístrate gratis`,
        description: `Al registrarte en Dawere.com tendrás acceso a las clases particulares de forma gratuita y estarás un paso más cerca de inscribirte en el programa educativo de tu preferencia. `,
        keywords: `registro, estudiantes, padres, organizaciones, dawere, dawere.com`,
        ogTitle: `Regístrate gratis`,
        ogDescription: `Al registrarte en Dawere.com tendrás acceso a las clases particulares de forma gratuita y estarás un paso más cerca de inscribirte en el programa educativo de tu preferencia.  `,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/seo_registro.jpg`
      },
      title: `Regístrate`,
      // title: `Regístrate y accede a programas educativos gratuitos`,
      subtitle: `Crea tu cuenta llenando los datos`,
      subtext: `Te ha invitado a formar parte de su equipo como`,
      withAccount: `¿Ya tienes cuenta?`,
      tabs: {
        1: {
          title: `Estudiante`,
          form: {
            name: `Nombres`,
            lastName: `Apellidos`,
            email: `Email`,
            confirmEmail: `Confirma tu email`,
            password: `Contraseña`
          }
        },
        2: {
          title: `Padres`,
          form: {
            name: `Nombres`,
            lastName: `Apellidos`,
            email: `Email`,
            confirmEmail: `Confirma tu email`,
            password: `Contraseña`
          }
        },
        3: {
          title: `Organización`,
          form: {
            organizationName: `Nombre legal de la organización`,
            name: `Nombres`,
            lastName: `Apellidos`,
            email: `Email`,
            confirmEmail: `Confirma tu email`,
            password: `Contraseña`
          }
        }
      },
      buttons: {
        create: `Crea tu cuenta`,
        googleLogIn: `Crea tu cuenta con Google`
      },
      form: {
        errors: {
          name: `Nombre Inválido`,
          lastname: `Apellido Inválido`
        }
      },
      helps: {
        password: ``
      },
      links: {
        login: `Inicia sesión`
      }
    },
    organizationDashboard: {
      seo: {
        title: `Panel de organización`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      breadcrumbs: {
        1: `Mis organizaciones`,
        2: `Panel de organización`
      },
      welcome: `Bienvenido, {{name}}`,
      alerts: {
        profile: {
          title: `Perfil de usuario incompleto`,
          description: `Provee la información de perfil faltante para que puedas gozar de
            las bondades de Dawere.`,
          button: `Completar perfil usuario`
        },
        data: {
          title: `Datos de organización incompletos`,
          description: `Provee los datos de facturación requeridos para que puedas hacer
            uso óptimo de la plataforma.`,
          button: `Completar datos organización`
        }
      },
      tabs: {
        1: {
          title: `Cupones`,
          empty: {
            title: `¿Cuántos cupones quieres comprar?`,
            description: `Aún no has comprado ningún cupón de inscripción. La compra de cupones
        es el primer paso de tu proyecto educativo. Cada cupón tiene un código
        único. Cuando le asignas cupones a tus beneficiarios, estos pueden
        inscribirse y empezar a estudiar con Dawere.
        <br />
        <br />
        ¿Qué estás esperando?`,
            buttons: {
              buy: `Comprar cupones`
            },
            contact: {
              email: `alianzas@dawere.com`,
              whatsapp: `+58 4242427150`
            }
          },
          full: {
            tabs: {
              1: {
                title: `Sin asignar`,
                buttons: {
                  download: `Descargar`,
                  assign: `Asignar`,
                  buy: `Comprar cupones`
                },
                table: {
                  count: `cupón(es)`,
                  selected: `seleccionado(s)`,
                  columns: {
                    coupon: `Cupón`,
                    type: `Producto`,
                    code: `Código de cupón`,
                    date: `Fecha de compra`
                  }
                },
                modal: {
                  title: `Asignar cupón`,
                  description: ` <p>
      Por favor indica el correo electrónico del beneficiario a quien deseas
      asignarle este cupón. Un email será enviado al mismo con instrucciones
      para inscribirse.
    </p>
    <p>
      Una vez el estudiante se inscriba, podrás monitorear su progreso en la
      pestaña "Estudiantes".
    </p>
`,
                  product: `Producto:`,
                  code: `Cupón a asignar:`,
                  placeholder: `Escribe el email del estudiante...`,
                  questions: `¿Tienes preguntas sobre la asignación de cupones?`,
                  contactUs: `Contáctanos`,
                  button: `Asignar`
                },
                successModal: {
                  title: `Tu cupón fue asignado con éxito`,
                  sentTo: `Cupón enviado a:`,
                  product: `Producto:`,
                  code: `Cupón asignado:`,
                  button: `Listo`
                }
              },
              2: {
                title: `Asignado`,
                buttons: {
                  download: `Descargar`,
                  assign: `Reasignar`
                },
                table: {
                  count: `cupón(es)`,
                  selected: `seleccionado(s)`,
                  columns: {
                    coupon: `Cupón`,
                    type: `Producto`,
                    code: `Código de cupón`,
                    assignedTo: `Asignado a`,
                    date: `Fecha de compra`
                  }
                },
                modal: {
                  title: `Reasignar cupón`,
                  description: ` <p>
      Por favor indica el correo electrónico del beneficiario a quien deseas
      reasignar este cupón. Un email será enviado al mismo con instrucciones
      para inscribirse. Una vez la persona se inscriba, podrás monitorear su
      progreso en la pestaña "Estudiantes".
    </p>
    <p>
      También le comunicaremos al beneficiario anterior que ya no puede hacer
      uso del cupón porque ha sido otorgado a otra persona.
    </p>
`,
                  prevOwner: `Beneficiario anterior:`,
                  product: `Producto:`,
                  code: `Cupón a reasignar:`,
                  placeholder: `Escribe el email del estudiante...`,
                  questions: `¿Tienes preguntas sobre la asignación de cupones?`,
                  contactUs: `Contáctanos`,
                  button: `Asignar`
                },
                successModal: {
                  title: `Tu cupón ha sido reasignado con éxito`,
                  currOwner: `Nuevo beneficiario:`,
                  prevOwner: `Beneficiario anterior:`,
                  product: `Producto:`,
                  code: `Cupón reasignado:`,
                  button: `Listo`
                }
              },
              3: {
                title: `Usado`,
                buttons: {
                  download: `Descargar`
                },
                table: {
                  count: `cupón(es)`,
                  columns: {
                    coupon: `Cupón`,
                    type: `Producto`,
                    code: `Código de cupón`,
                    assignedTo: `Asignado a`,
                    date: `Fecha de uso`
                  }
                }
              }
            }
          }
        },
        2: {
          title: `Estudiantes`,
          empty: {
            title: `Ninguno de tus estudiantes se ha inscrito aún`,
            description: `Tus beneficiarios podrán inscribirse una vez compres cupones para
        ellos. ¡No los hagas esperar más!`,
            buttons: {
              buy: `Comprar cupones`,
              add: `Añadir estudiante`
            }
          },
          full: {
            tabs: {
              1: {
                title: `En curso`,
                buttons: {
                  add: `Añadir estudiante`,
                  download: `Descargar`,
                  remove: `Retirar`,
                  program: `Programa educativo`,
                  period: `Periodo`,
                  calendar: `Calendario`
                },
                table: {
                  count: `inscripción(es) en curso`,
                  selected: `seleccionado(s)`,
                  columns: {
                    name: `Nombre del estudiante`,
                    program: `Programa educativo`,
                    period: `Periodo`,
                    calendar: `Calendario`,
                    admission: `Inscripción`,
                    student: `Estudiante`
                  }
                }
              },
              2: {
                title: `Invitaciones enviadas`,
                PENDING: `Pendiente`,
                ACCEPTED: `Aceptado`,
                REJECTED: `Rechazado`,
                buttons: {
                  download: `Descargar`,
                  delete: `Eliminar`,
                  resend: `Reenviar`
                },
                table: {
                  count: `invitación(es)`,
                  selected: `seleccionado(s)`,
                  columns: {
                    email: `Email`,
                    date: `Fecha de invitación`,
                    status: `Estado`,
                    invitation: `Invitación`
                  }
                }
              },
              3: {
                title: `Retirado`,
                REMOVED: `Retirado`,
                ROVOKED: `Rovocado`,
                buttons: {
                  download: `Descargar`,
                  program: `Programa educativo`,
                  period: `Periodo`
                },
                table: {
                  count: `programa(s) retirado(s)`,
                  columns: {
                    name: `Nombre del estudiante`,
                    program: `Programa educativo`,
                    period: `Periodo`,
                    date: `Fecha de retiro`,
                    status: `Estado`,
                    student: `Estudiante`
                  }
                }
              },
              4: {
                title: `Graduado`,
                buttons: {
                  download: `Descargar`,
                  program: `Programa educativo`,
                  period: `Periodo`
                },
                table: {
                  count: `graduación(es)`,
                  columns: {
                    name: `Nombre del estudiante`,
                    program: `Programa educativo`,
                    phoneNumber: `Número de teléfono`,
                    date: `Fecha de graduación`,
                    student: `Estudiante`
                  }
                }
              },
              5: {
                title: `Concluido`,
                buttons: {
                  add: `Añadir estudiante`,
                  download: `Descargar`,
                  remove: `Retirar`,
                  reenroll: `Reinscribir`,
                  program: `Programa educativo`,
                  period: `Periodo`,
                  calendar: `Calendario`
                },
                table: {
                  count: `reinscripción(es) pendiente(s)`,
                  selected: `seleccionado(s)`,
                  columns: {
                    name: `Nombre del estudiante`,
                    program: `Programa educativo`,
                    period: `Periodo`,
                    calendar: `Calendario`,
                    student: `Estudiante`
                  }
                }
              },
              6: {
                title: `Por inscribir`,
                buttons: {
                  add: `Asignar cupón`,
                  download: `Descargar`,
                  remove: `Retirar`,
                  program: `Programa educativo`,
                  period: `Periodo`,
                  calendar: `Calendario`
                },
                table: {
                  count: `estudiante(s)`,
                  selected: `seleccionado(s)`,
                  columns: {
                    name: `Nombre del estudiante`,
                    date: `Fecha de inclusión`
                  }
                }
              }
            }
          }
        },
        3: {
          title: `Reportes`
        },
        4: {
          title: `Mis pagos`,
          DRAFT: `Incompleto`,
          PENDING: `Procesando`,
          CONFIRMED: `Pagado`,
          REJECTED: `Rechazado`,
          ERROR: `En revisión`,
          VOIDED: `Anulado`,
          PAID: `Pagado`,
          buttons: {
            filter: `Filtrar por fecha`,
            download: `Descargar PDF`,
            cancel: `Anular`,
            pay: `Pagar`,
            seeDetails: `Ver detalles`,
            continue: `Continuar`
          },
          table: {
            count: `pago(s)`,
            selected: `seleccionado(s)`,
            columns: {
              status: `Estado`,
              number: `Número de factura`,
              paymentNumber: `Número de pago`,
              method: `Método de pago`,
              date: `Fecha de pago`,
              amount: `Monto`,
              payments: `Pagos`
            }
          },
          revisionModal: {
            title: `Hubo un problema con tu pago`,
            description: `Tuvimos un problema técnico con tu pago. Por favor no intentes volver a pagar. Crea un ticket en nuestra sección de ayuda y nos pondremos en contacto contigo lo antes posible.`,
            button: `Reportar problema`
          },
          modal: {
            successTitle: `Detalles del pago código {{code}}`,
            failureTitle: `Tu pago ha sido rechazado`,
            failureDescription: `A continuación, se listan las razones de rechazo de tu pago código {{code}}:`,
            billingTitle: `Información de facturación`,
            paymentMethod: `Método de pago`,
            bank: `Banco`,
            transferDate: `Fecha de la transferencia`,
            reference: `Código de referencia completo`,
            payer: `Nombre completo del pagador`,
            amount: `Monto`,
            STRIPE: `Tarjeta de crédito o débito`,
            PAYPAL: `PayPal`,
            CASH: `Efectivo`,
            WALLET: `Vales de descuento`,
            TRANSFER: `Transferencia`,
            footnote: `Si piensas que hubo un error o tienes alguna pregunta sobre el proceso de pago, crea un ticket en nuestra sección de ayuda y nos comunicaremos contigo lo antes posible.`,
            button: `Solicitar ayuda`,
            brand: `Compañía`,
            last4digits: `Número de tarjeta`
          },
          deleteModal: {
            title: `¡Atención!`,
            description: `¿Estás seguro que desea anular este pago?`,
            button: `Anular`,
            toast: `El pago ha sido anulado exitosamente.`
          }
        },
        5: {
          title: `Organigrama plantel`,
          placeholder: `Selecciona un programa...`,
          avatars: {
            members: `Miembros`,
            teachers: `Profesores`,
            more: `Ver más`
          }
        },
        6: {
          title: `Vales de descuento`,
          description: `Puedes usar el saldo en tus vales de descuento para hacer pagos en Dawere siempre que sean en la misma divisa del crédito a favor.`
        }
      }
    },
    tutorDashboard: {
      seo: {
        title: `Panel de padre/mentor`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      add: `Introduce el email del estudiante que deseas monitorear. El estudiante
        recibirá una invitación de tu parte. Una vez la acepte, podrás seguir y
        apoyar su avance académico.`,
      title: `Panel de padre/mentor`,
      welcome: `Bienvenido, {{name}}`,
      buttons: {
        addStudent: `Añadir estudiante`
      },
      empty: {
        title: ` Aún no tienes estudiantes bajo tu tutela.`,
        description: `Solicita a un estudiante que te otorgue acceso a sus datos
                      académicos. Al recibir este permiso, podrás monitorear sus
                      calificaciones, documentos de inscripción, histórico
                      académico y solvencia administrativa.`
      }
    },
    studentDashboard: {
      seo: {
        title: `Panel de estudiante`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      welcome: `Bienvenido, {{name}}`,
      title: `Panel de estudiante`,
      tabs: {
        1: {
          title: `En curso`,
          programs: `Programas educativos`,
          noProgramsTitle:
            'No te has inscrito en ningún programa educativo aún',
          noPrograms: `Explora nuestros programas educativos y escoge el programa que mejor se adapte a tus necesidades y aspiraciones. ¡Recuerda que todos los programas tienen un periodo de prueba gratuito de 7 días!`,
          enrollToday: `Inscríbete hoy`,
          congrats: `¡Felicitaciones por haber completado el {{period}} del {{program}}!`,
          congrats2: `¡Felicitaciones por haber aprobado el {{period}}!`,
          subtext: `Agregaremos las siguientes materias que te toca cursar.`,
          degree: `Sigue avanzando hacia tu título de {{degreeName}}`,
          enrollNextPeriod: `Inscríbete en el siguiente periodo`,
          continueNextPeriod: `Continuar`,
          askForHelp: `Solicitar ayuda`,
          deprecated: `Este programa educativo ha sido reemplazado`,
          chooseProgram: `Escoge entre alguno de los siguientes programas para continuar con
            tus estudios`,
          trial: {
            active: `La prueba gratuita de este programa se acabará el <strong>{{trialEndsAt}}</strong>, puedes inscribirte en cualquier momento realizando el pago`,
            activeLink: `aquí`,
            noActive1: `Se ha vencido la prueba gratuita de este programa. Completa tu
          inscripción`,
            noActiveLink: `aquí`,
            noActive2: `para continuar cursando el programa y conservar tu avance académico.`
          },
          graduated: {
            title: `¡Felicitaciones, te has graduado!`,
            description: `Has cumplido con los requerimientos para obtener el título de {{degree}}.`,
            details: `Ve a la sección de ayuda para iniciar el proceso de retiro de tu paquete de grado.`,
            button: `Obtén tu paquete de grado`
          },
          finishedCourse: {
            title: `¡Felicitaciones!`,
            description: `Has culminado el curso satisfactoriamente.`,
            button: `Ver calificación final `
          },
          pendingPayment: {
            title: ` Tienes cuotas vencidas`,
            description: `Puedes hacer los pagos que tienes pendiente en la sección de Pagos y
            cuotas.`,
            button: `Ir a Pagos y cuotas`
          },
          pendingProfile: {
            title: `Perfil incompleto`,
            description: ` Para continuar tus estudios debes completar toda la información de
            tu perfil.`,
            button: `Ir a Perfil`
          },
          pendingTutor: {
            title: `Invita a un padre / tutor`,
            description1: `Los estudiantes menores de edad deben tener un padre o tutor relacionado en su cuenta. `,
            description2: `Para continuar tus estudios, enlaza a tus padres o tutores a tu cuenta a través del la sección de "Gestión de permisos" `,
            button: ` Ir a Gestión de permisos`
          }
        },
        2: {
          title: `Mis documentos`
        },
        3: {
          title: `Historial académico`
        },
        4: {
          title: `Pagos y cuotas`,
          refresh: `Refrescar`,
          tabs: {
            1: {
              title: `Pagos`
            },
            2: {
              title: `Cuotas`,
              PAID: `Pagado`,
              FAILED: `Rechazado`,
              NOT_ALIVE: `Procesando`,
              PENDING: `Pendiente`,
              DUE: `Vencido`,
              PROCESSING: `Procesando`,
              VOID: `Anulado`,
              VOIDED: `Anulado`,
              buttons: {
                pay: `Pagar`,
                orderNumber: `Número de orden`,
                payPending: `Pagar vencidas`,
                payAll: `Pagar todo`
              },
              table: {
                columns: {
                  number: `Número de cuota`,
                  dueDate: `Fecha de vencimiento`,
                  amount: `Monto`,
                  status: `Estado`,
                  installment: `Cuota`
                }
              }
            },
            3: {
              title: `Facturación`
            }
          }
        },
        5: {
          title: `Calendario`
        },
        6: {
          title: `Boletines`,
          ACTIVE: `Activo`,
          RE_ENROLLMENT: `Concluido`,
          FINISHED_COURSE: `Concluido`,
          CONTINUE: `Concluido`,
          DEBT: `Deuda`,
          GRADUATED: `Graduado`,
          DEPRECATED_PROGRAM: `Descontinuado`,
          FREE_TRIAL: `Prueba gratis`,
          FREE_TRIAL_ENDED: `Fin de prueba`,
          period: `Periodo: `,
          enrollmentDate: `Fecha de inscripción: `,
          calendar: `Calendario: `,
          order: `Orden de compra: `,
          orderDescription: `Descripción: `,
          school: `Institución educativa: `,
          button: `Ver boletín`
        }
      }
    },
    profile: {
      title: `Editar Perfil`,
      subtitle: `Edita tus datos personales`,
      section1Title: `INFORMACIÓN DE CONTACTO`,
      section2Title: `INFORMACIÓN DE IDENTIDAD`,
      section3Title: `INFORMACIÓN DE NACIMIENTO`,
      section4Title: `INFORMACIÓN DE RESIDENCIA`,
      section5Title: `OTROS`,
      toasts: {
        incomplete: `Tu perfil está incompleto. Para continuar tus estudios debes completar toda la información en la sección "Perfil".`,
        parentIncomplete: `Llena tu perfil para poder monitorear a tus representados.`,
        successPhoneNumber: `Número celular verificado con éxito.`,
        successResend: `Código reenviado con éxito.`,
        successUpdate: `Datos de perfil actualizados con éxito.`
      },
      form: {
        name: `Nombres`,
        lastName: `Apellidos`,
        phone: `Teléfono`,
        email: `Email`,
        identityType: `Tipo de documento de identidad`,
        indetityNumber: `Número de documento de identidad`,
        gender: `Género`,
        race: `Raza y etnia`,
        birthCountry: `País de nacimiento`,
        birthState: `Estado de nacimiento`,
        birthTownship: `Localidad de nacimiento`,
        birthday: `Fecha de nacimiento`,
        country: `País de residencia`,
        state: `Estado de residencia`,
        township: `Localidad de residencia`,
        howFoundUs: `¿Cómo te enteraste de Dawere?`,
        specialNeeds: `Necesidades educativas especiales`
      },
      buttons: {
        submit: `Guardar cambios`
      },
      helps: {
        phoneNumber: `Al introducir un número de teléfono, aceptas recibir mensajes de texto SMS automatizados con información relevante para el servicio educativo y de promoción. Pueden aplicar tarifas por envío de mensajes y uso de datos móviles. Puedes cambiar estos parámetros en tu sección de configuración / notificaciones.`
      },
      modal: {
        title: `Verifica tu número de teléfono celular`,
        button: `Verificar`,
        description: `Ingresa el código de 6 dígitos enviado al número de teléfono celular que indicaste.`,
        placeholder: `Código de verificación`,
        text1: `Si no recibiste el código, `,
        text2: `vuelve a solicitarlo`
      },
      seo: {
        title: `Edita tu Perfil`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      }
    },
    studentDetails: {
      seo: {
        title: `Detalles del estudiante`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      breadcrumbs: {
        2: `Detalles del estudiante`
      },
      title: `Programas educativos`,
      tabs: {
        1: {
          title: `Materias`,
          yearGPA: `Promedio del periodo:`,
          cumulativeGPA: `Promedio acumulado:`,
          calendar: `Calendario`,
          tooltip: `Se obtiene al sumar las calificaciones obtenidas en cada materia y luego dividir entre el número total de materias del periodo.`
        },
        2: {
          title: `Documentos`,
          mandatory: `DOCUMENTOS DE INSCRIPCIÓN REQUERIDOS`,
          optional: `DOCUMENTOS OPCIONALES`
        },
        3: {
          title: `Historial académico`,
          TO_COURSE: `Pendiente por cursar`,
          FINAL_PERIOD: `Final de período`,
          TRANSFERRED: `Transferencia`,
          EXONERATED: `Exonerada`,
          EQUIVALENCE: `Equivalencia`,
          TUTORSHIP: `Tutoría`,
          PENDING: `Pendiente`,
          APPROVED: `Aprobada`,
          NOT_APPROVED: `No Aprobada`,
          IN_COURSE: `En Curso`,
          VALIDATED: `Convalidada`,
          REPEATING: `Repitiente`,
          table: {
            columns: {
              name: `Materia`,
              school: `Institución educativa`,
              score: `Calificación`,
              date: `Fecha de culminación`,
              status: `Estado`,
              credits: `Créditos`
            }
          }
        }
      },
      labels: {
        student: `Estudiante`,
        phoneNumber: `Teléfono`,
        identityType: `Documento de identidad`,
        email: `Email`
      }
    },
    myDocuments: {
      seo: {
        title: `Mis documentos`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      title: `Mis documentos`,
      text: `Carga, edita o visualiza tus documentos de inscripción. Nuestro equipo
      evaluará los documentos que subas a la plataforma y los aprobará si
      cumplen con lo requerido. <strong>Una vez estén aprobados, no podrás eliminarlos o sustituirlos. </strong>`,
      mandatory: `DOCUMENTOS DE INSCRIPCIÓN REQUERIDOS`,
      optional: `DOCUMENTOS OPCIONALES`,
      uploadSuccess: `Documento actualizado con éxito.`
    },
    philosophy: {
      seo: {
        title: `Nuestra Filosofía Educativa, Misión y Visión`,
        description: `Nuestro modelo de aprendizaje virtual promueve la autonomía y autodeterminación del estudiante: este elige cuándo, dónde y cómo aprender.`,
        keywords: `Misión, Visión y Filosofía Educativa`,
        ogTitle: `Misión, Visión y Filosofía de Dawere: Educación sin Límites`,
        ogDescription: `En Dawere utilizamos métodos y técnicas que promueven la autonomía del estudiante y buscan su autodeterminación. Nuestra pedagogía  incluye el respeto hacia la libertad de elegir cuándo, dónde y cómo aprender.`,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/Ilustraciones/*FILOSOFIA/ID_Pagina_Filosofia2.png`
      },
      section1: {
        title: `Misión, Visión y Filosofía Educativa`,
        text: `En Dawere creemos firmemente en el poder transformativo de la educación. Esta tiene un efecto dominó en donde el impacto positivo en el individuo implica un impacto positivo en su familia, comunidad, ciudad, nación y planeta. El bienestar humano que deriva de la educación es lo que motiva la misión, visión y filosofía educativa de Dawere.`,
        video: `https://www.youtube.com/embed/l42u9s7n8_o`
      },
      section2: {
        title: `Nuestra misión`,
        subtitle: `Masificar el acceso a la educación de calidad a través de la tecnología`,
        text: `<p>La misión de Dawere es masificar la educación de calidad para el mundo de habla hispana. Dawere existe con el fin de incluir de forma exitosa a todas aquellas personas que no tienen acceso a una experiencia académica de calidad a través del sistema tradicional o presencial. Mediante esta masificación y de la incesante mejora de sus procesos de enseñanza, Dawere rompe con la norma que establece la calidad educativa como un lujo para las élites y erige un nuevo orden: <strong>la educación premium está al alcance de todos</strong>.</p>
        <p>Estamos comprometidos a desarrollar las habilidades esenciales de los ciudadanos para su completa participación en la sociedad. Llevamos a cabo nuestra misión a través de la tecnología y de los máximos estándares de calidad que perseguimos con cada paso que damos.</p>`
      },
      section3: {
        title: `Nuestra visión`,
        subtitle: `Liderar el camino de la educación formal virtual en español`,
        text: `<p>Dawere aspira ser la primera referencia de educación virtual formal de secundaria o bachillerato para la población hispanohablante del continente Americano y el Caribe.</p>
        <p>Nuestra modalidad de estudio basada en estándares de calidad internacionales busca combinar un método de enseñanza virtual y autónomo con trabajo colaborativo en vivo. El modelo educativo de Dawere será avalado por agencias de acreditación educativa. Adicionalmente, la efectividad de dicho modelo será comprobada a través del éxito de nuestros estudiantes en las pruebas estandarizadas PISA.</p>`
      },
      section4: {
        title: `Filosofía educativa`,
        item1: {
          title: `Enfoque en el estudiante`,
          text: `De acuerdo con la concepción liberal de la pedagogía, utilizamos los enfoques centrados en el estudiante, en contraposición a los enfoques dirigidos por el maestro. Es por ello que utilizamos métodos y técnicas que promueven la autonomía del estudiante y buscan su autodeterminación. En Dawere respetamos su libertad para elegir cuándo, dónde y cómo aprender.`
        },
        item2: {
          title: `Proceso creativo`,
          text: `<p>
        Creemos que el aprendizaje es un proceso creativo de experimentación y
        búsqueda de lo desconocido. Nuestros contenidos y evaluaciones se
        basan en escenarios que permiten a los estudiantes proyectarse como
        seres activos capaces de analizar y resolver problemas reales. Por eso
        buscamos el "aprendizaje significativo" (*) y nos distanciamos de lo
        memorístico y repetitivo.
      </p>
      <p class='fs-small text-muted'>
        (*) David Ausubel: Psicología del aprendizaje verbal significativo,
        New York, 1963.
      </p>`
        },
        item3: {
          title: `Educación ubicua`,
          text: `Gracias a la tecnología, la educación pasa a ser ubicua y a tener un poder inimaginable: ser capaz de llegar con calidad, de forma masiva, al mismo tiempo, a todas partes.`
        },
        item4: {
          title: `Evaluación continua y crítica`,
          text: `Buscamos fomentar los conocimientos, valores e ideales que conlleven a encontrar lo mejor para el individuo y la sociedad y con el fin último de que el estudiante descubra su capacidad de transformar su realidad.`
        }
      }
    },
    formativeEvaluation: {
      seo: {
        title: `Evaluación formativa`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      leavePage: `¿Estás seguro que deseas salir de la evaluación?`,
      title: `Evaluación formativa`,
      correct: `Respuesta correcta`,
      incorrect: `Respuesta incorrecta`,
      finishModal: {
        title: `Finalizar evaluación`,
        yes: `Si`,
        description: ` ¿Estás seguro que terminaste de presentar esta evaluación y deseas
          recibir tu calificación?`,
        sending: `Enviando`,
        sendingDesc: ` En breve podrás ver tu calificación.`
      }
    },
    pisaEvaluation: {
      seo: {
        title: `Evaluación sumativa - PISA`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      leavePage: `¿Estás seguro que deseas salir de la evaluación?`,
      source: `Fuente:`,
      inf: `Ilimitado`
    },
    classroom: {
      seo: {
        title: `Aula virtual`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      title: `Aula virtual`,
      TOPIC: `Tema {{number}}`,
      PISA: `Evaluación sumativa - PISA`,
      PROJECT: `Proyecto {{number}}`,
      VIDEO: `Video {{number}}`,
      LECTURE: `Lectura {{number}}`,
      FORMATIVE: `Evaluación formativa`,
      seen: `visto`,
      read: `leído`,
      browserIssue: `Hemos detectado que estás usando el navegador{' '}
            <strong>Safari</strong>. Los navegadores recomendados para presentar
            evaluaciones son <strong>Chrome</strong> y <strong>Firefox</strong>.
            Por favor cambia de navegador.`,
      markAsSeen: {
        toast: `El contenido ha sido marcado como {{verb}}.`,
        button: ``
      },
      buttons: {
        openTelegram: `Abrir foro-chat`,
        disabledTelegram: `Este chat, con tu profesor y compañeros de clase, no estará disponible durante la prueba gratuita.`,
        openCalendly: `Agendar cita`,
        previous: `Anterior`,
        next: `Siguiente`,
        markAsSeen: `Marcar como {{verb}}`,
        checkAnswer: `Verificar respuesta`,
        end: `Finalizar`,
        continue: `Continuar`,
        back: `Regresar al aula virtual`
      },
      timeIsOver: {
        title: `El tiempo se ha agotado`,
        description: `El tiempo para presentar la evaluación se ha terminado. Continúa al reporte de resultados para ver la calificación que obtuviste en esta oportunidad.`
      },
      tooltips: {
        close: `Cerrar`,
        content: `Contenido`
      },
      subtypes: {
        VIDEO: `Video`,
        LECTURE: `Lectura`,
        FORMATIVE: `Evaluación formativa`,
        PISA: `Evaluación sumativa - PISA`,
        PROJECT: `Evaluación sumativa - Proyecto`
      },
      tabs: {
        1: {
          title: `Contenido`,
          filter: {
            title: `Filtrar por`,
            all: `Plan de clases`,
            evaluations: `Evaluaciones`
          }
        },
        2: {
          title: `Materia`
        },
        3: {
          title: `Calificaciones`,
          subtitle: `Calificación acumulada hasta la fecha:`,
          table: {
            name: `Evaluaciones`,
            weight: `Ponderación`,
            score: `Calificación obtenida`,
            status: `Estado`
          }
        },
        4: {
          title: `Chat`
        },
        5: {
          title: `Calendario`
        },
        6: {
          title: `Profesores`
        },
        7: {
          title: `Lecciones grabadas`,
          description: `En esta sección están listadas todas las lecciones en vivo que ha realizado tu profesor durante el calendario académico. Si por alguna
          razón no pudiste conectarte a la lección en vivo, aquí podrás
          conseguir la clase grabada.`
        }
      },
      formativeEvaluationDetails: {
        title1: `Instrucciones generales`,
        details: `Las Evaluaciones Formativas son pruebas de práctica para que midas tus
        competencias y descubras cuán preparado estás para tus Evaluaciones
        Sumativas.`,
        detailsList: `
         <ul className='mb-4'>
        <li>
          <strong>Tipo de evaluación:</strong> formativa
        </li>
        <li>
          <strong>Ponderación:</strong> no tiene peso en tu calificación final
        </li>
        <li>
          <strong>Total de preguntas:</strong> {{numberOfQuestions}}
        </li>
        <li>
          <strong>Tema evaluado:</strong> {{topic}}
        </li>
        <li>
          <strong>Intentos restantes:</strong> Ilimitados
        </li>
      </ul>
        `,
        title2: `Código de ética e integridad académica`,
        ethicalCode: `Antes de iniciar esta actividad, debes comprometerte a cumplir las
          siguientes reglas de conducta:`,
        ethicalCodeList: `
          <ul className='mb-0'>
          <li>
            Las respuestas a las evaluaciones deben ser de tu propio trabajo y
            contenido intelectual.
          </li>
          <li>
            No debes compartir respuestas de las evaluaciones con otra persona.
          </li>
          <li>
            No puedes participar o realizar actividades que mejoren de manera
            deshonesta tus resultados o notas.
          </li>
          <li>
            En el caso de actividad de producción escrita queda prohibido
            cualquier tipo de plagio.
          </li>
        </ul>
        `,
        checkbox: `<p>He leído y acepto cumplir a cabalidad el código de conducta e
              integridad académica. Si se detecta que hubo deshonestidad o que
              incumplí, de alguna manera, con el código planteado, acepto las
              consecuencias de esto, las cuales incluyen, pero no se limitan, a
              quedar automáticamente fuera del sistema educativo de Dawere y no
              poder inscribirme en un futuro.</p>

              <p>Los foros están diseñados para el aprendizaje colaborativo y el intercambio de ideas. Al participar, aceptas las políticas y el código de conducta del foro. Para más información, consulta el manual del estudiante.</p>`,
        button: `Iniciar práctica`,
        tooltip: `Practica cuantas veces lo necesites hasta que te sientas preparado.`
      },
      pisaEvaluationDetails: {
        title1: `Instrucciones generales`,
        details: `La calificación obtenida en esta evaluación formará parte de tu historial académico. La plataforma automáticamente asignará como nota la calificación más alta entre las oportunidades presentadas. Considera el nivel de preparación que tienes en cada uno de los temas a evaluar antes de presentar.`,
        detailsWarning: `Procura estar en un lugar en silencio, cómodo y con una buena conexión a internet. Una vez iniciada la evaluación, concéntrate única y exclusivamente en terminarla. Puedes llegar a perder la oportunidad si no sigues las instrucciones aquí especificadas. `,
        detailsList: `
         <ul className='mb-4'>
        <li>
          <strong>Tipo de evaluación:</strong> Sumativa - PISA
        </li>
        <li>
          <strong>Fecha limite para presentar:</strong> {{date}}
        </li>
        <li>
          <strong>Tiempo para presentar:</strong> {{time}}
        </li>
        <li>
          <strong>Ponderación:</strong> {{weight}}%
        </li>
        <li>
          <strong>Total de preguntas:</strong> {{numberOfQuestions}}
        </li>
        <li>
          <strong>Calificación minima aprobatoria:</strong> {{minGrade}}
        </li>
        <li>
          <strong>Calificación obtenida:</strong> {{grade}}
        </li>
      </ul>
        `,
        title2: `Código de ética e integridad académica`,
        ethicalCode: `Antes de iniciar esta actividad, debes comprometerte a cumplir las
          siguientes reglas de conducta:`,
        ethicalCodeList: `
          <ul className='mb-0'>
          <li>
            Las respuestas a las evaluaciones deben ser de tu propio trabajo y
            contenido intelectual.
          </li>
          <li>
            No debes compartir respuestas de las evaluaciones con otra persona.
          </li>
          <li>
            No puedes participar o realizar actividades que mejoren de manera
            deshonesta tus resultados o notas.
          </li>
          <li>
            En el caso de actividad de producción escrita queda prohibido
            cualquier tipo de plagio.
          </li>
          <li>
            Al iniciar esta evaluación, debes concentrarte únicamente en esta actividad. No debes tener más ventanas abiertas en tu dispositivo.
          </li>
        </ul>
        `,
        checkbox: `He leído y acepto cumplir a cabalidad el código de conducta e integridad académica. Si se detecta que hubo deshonestidad o que incumplí, de alguna manera, con el código planteado, acepto las consecuencias de esto, las cuales incluyen, pero no se limitan, a quedar automáticamente fuera del sistema educativo de Dawere y no poder inscribirme en un futuro. `,
        button: `Iniciar evaluación`,
        tooltip: `Recuerda que esta evaluación es sumativa y se verá reflejada en tu calificación final, realízala cuando te sientas preparado.`,
        dateTooltip: `Podrás presentar esta evaluación a partir del <strong>{{date}}</strong>.`
      },
      projectDetails: {
        project: `Mi proyecto`,
        download: `Ver mi proyecto`,
        markAsRead: `Marcar instrucciones como leídas`,
        markAsReadToast: `Las instrucciones han sido marcadas como leídas.`,
        breadcrumbs: {
          1: `Proyecto {{number}} - Instrucciones`,
          2: `Proyecto {{number}} - Carga de archivo`,
          3: `Proyecto {{number}} - Co-evaluación`,
          4: `Proyecto {{number}} - Resultados`
        },
        title1: `Instrucciones generales`,
        step1: `Instrucciones`,
        step2: `Carga de archivo`,
        step3: `Co-evaluación`,
        step4: `Resultados`,
        details: `Primero, debes entregar tu proyecto antes de la fecha límite. Luego, el sistema te asignará proyectos de tus compañeros para que los evalúes antes de la fecha límite para co-evaluar. Finalmente, todos los estudiantes recibirán su calificación junto con la observación de sus compañeros.`,
        rubrics: {
          title: `Criterios de evaluación`,
          aspect: `Aspectos a evaluar`,
          aspect100: `Excelente (100%)`,
          aspect66: `Bien (85%)`,
          aspect33: `Regular (65%)`,
          aspect0: `No observado (10%)`
        },
        detailsListRe: `
         <ul className='mb-4'>
        <li>
          <strong>Tipo de evaluación:</strong> Sumativa - Proyecto
        </li>
        <li>
          <strong>Fecha de entrega:</strong> {{date}}
        </li>
        <li>
          <strong>Tiempo estimado de dedicación:</strong> {{time}}
        </li>
        <li>
          <strong>Ponderación:</strong> {{weight}}%
        </li>
        <li>
          <strong>Calificación minima aprobatoria:</strong> {{minGrade}}
        </li>
        <li>
          <strong>Calificación obtenida:</strong> {{grade}}
        </li>
      </ul>
        `,
        detailsListCo: `
         <ul className='mb-4'>
        <li>
          <strong>Tipo de evaluación:</strong> Sumativa - Proyecto con co-evaluación
        </li>
        <li>
          <strong>Fecha de entrega:</strong> {{dueDate}}
        </li>
        <li>
          <strong>Fecha limite para co-evaluar:</strong> {{dateToCoevaluate}}
        </li>
        <li>
          <strong>Tiempo estimado de dedicación:</strong> {{time}}
        </li>
        <li>
          <strong>Ponderación:</strong> {{weight}}%
        </li>
        <li>
          <strong>Calificación minima aprobatoria:</strong> {{minGrade}}
        </li>
        <li>
          <strong>Calificación obtenida:</strong> {{grade}}
        </li>
      </ul>
        `,
        uploading: `Enviando proyecto...`,
        uploadDetails: `En esta sección debes subir y enviar tu proyecto para que sea evaluado por tus compañeros de clase.`,
        uploadDetails2: `Envia o reemplaza tu proyecto para que sea evaluado por tus compañeros de clase:`,
        uploadDetails3: `Ya hemos recibido tu proyecto.`,
        uploadWarning: `La fecha límite para enviar tu proyecto es {{date}}.`,
        expiredTimeWarning: `La fecha límite para enviar este proyecto era {{date}} y no recibimos una entrega de tu parte. Lamentablemente, ya no es posible participar en esta actividad.`,
        title2: `Código de ética e integridad académica`,
        ethicalCode: `Antes de iniciar esta actividad, debes comprometerte a cumplir las
          siguientes reglas de conducta:`,
        ethicalCodeList: `
          <ul className='mb-0'>
          <li>
            Las respuestas a las evaluaciones deben ser de tu propio trabajo y
            contenido intelectual.
          </li>
          <li>
            No debes compartir respuestas de las evaluaciones con otra persona.
          </li>
          <li>
            No puedes participar o realizar actividades que mejoren de manera
            deshonesta tus resultados o notas.
          </li>
          <li>
            En el caso de actividad de producción escrita queda prohibido
            cualquier tipo de plagio.
          </li>
          <li>
            Al iniciar esta evaluación, debes concentrarte únicamente en esta actividad. No debes tener más ventanas abiertas en tu dispositivo.
          </li>
        </ul>
        `,
        checkbox: `He leído y acepto cumplir a cabalidad el código de conducta e integridad académica. Si se detecta que hubo deshonestidad o que incumplí, de alguna manera, con el código planteado, acepto las consecuencias de esto, las cuales incluyen, pero no se limitan, a quedar automáticamente fuera del sistema educativo de Dawere y no poder inscribirme en un futuro. `,
        button: `Enviar proyecto`,
        confirmUpload: {
          title: `Enviar proyecto`,
          text: `¿Estás seguro que quieres enviar este proyecto?`,
          button: `Confirmar`,
          toast: `Tu proyecto ha sido enviado exitosamente.`
        },
        tooltip: `Recuerda que esta evaluación es sumativa y se verá reflejada en tu calificación final, realízala cuando te sientas preparado.`,
        dateTooltip: `Podrás presentar esta evaluación a partir del <strong>{{date}}</strong>.`,
        coevaluation: {
          empty: `Aún no es momento de evaluar a tus compañeros. Esta sección se desbloqueará a partir del día {{date}}.`,
          description: `Ahora, debes evaluar los proyectos de tus compañeros. Recuerda que la
        co-evaluación es totalmente anónima y tienes hasta el {{date}} para
        terminar de evaluar los proyectos que te fueron asignados a
        continuación.`,
          alert: `Evaluar a tus compañeros es obligatorio. De lo contrario, se considera
          como si nunca hubieses entregado el proyecto y no recibirás una
          calificación.`,
          table: {
            partners: `Compañeros a evaluar`,
            status: `Estado`,
            grade: `Calificación`,
            date: `Fecha`,
            evaluate: `Evaluar`,
            evaluated: `Evaluado`,
            partner: `Compañero #{{number}}`
          }
        },
        evaluationModal: {
          title1: `Co-evaluación compañero de clases #{{number}}`,
          submit: `Enviar co-evaluación`,
          text1: `Para cada uno de los aspectos a evaluar de la siguiente rúbrica, debes
        seleccionar una valoración de acuerdo a lo que viste en el proyecto de
        tu compañero. Recuerda que la coevaluación es completamente anónimo.
        Ningún estudiante podrá saber las valoraciones seleccionadas por otro
        estudiante.`,
          openProject: `Ver proyecto`,
          title2: `Comentarios`,
          text2: `A continuación, escribe un comentario a tu compañero en donde señales los
        aspectos que más te gustaron de su proyecto y las oportunidades de
        mejora:`,
          placeholder: `Escribe tus comentarios...`
        },
        results: {
          title: `Co-evaluación #{{number}}: `,
          noevaluated: `Este compañero no concluyó la co-evaluación de tu proyecto.`,
          empty: `Aún no hemos recibido todas las co-evaluaciones. A partir del día {{date}}, procesaremos todos los resultados y podrás ver tu calificación en esta sección.`,
          description: `En este reporte conseguirás un promedio de las calificaciones que le asignaron tus compañeros de clase a tu proyecto. También conseguirás la retroalimentación que te dejaron.`
        }
      }
    },
    myOrganizations: {
      seo: {
        title: `Mis organizaciones`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      title: `Mis organizaciones`,
      buttons: {
        add: `Crear organización`,
        configuration: `Configuración`
      },
      pagination: `organización(es)`
    },
    createOrganization: {
      seo: {
        title: `Crear organización`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      breadcrumbs: {
        1: `Mis organizaciones`,
        2: `Crear organización`
      },
      title: `Crear organización`,
      section1Title: `INFORMACIÓN FISCAL`,
      section2Title: `DOMICILIO FISCAL`,
      form: {
        name: `Nombre o razón social`,
        tin: `Número de registro fiscal o identidad`,
        country: `País`,
        state: `Estado`,
        township: `Localidad`,
        billingAddress: `Dirección fiscal`,
        postalCode: `Código postal`
      },
      buttons: {
        submit: `Crear organización`
      },
      toasts: {
        successCreated: `La organización {{name}} ha sido creada exitosamente.`
      }
    },
    editOrganization: {
      seo: {
        title: `Configurar organización`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      breadcrumbs: {
        1: `Mis organizaciones`,
        3: `Configurar organización`
      },
      title: `Configurar`,
      tabs: {
        1: {
          title: `Datos de facturación`,
          section1Title: `INFORMACIÓN FISCAL`,
          section2Title: `DOMICILIO FISCAL`,
          form: {
            name: `Nombre o razón social`,
            tin: `Número de registro fiscal o identidad`,
            country: `País`,
            state: `Estado`,
            township: `Localidad`,
            billingAddress: `Dirección fiscal`,
            postalCode: `Código postal`
          },
          buttons: {
            submit: `Guardar datos`
          },
          toasts: {
            successUpdated: `Datos de facturación fueron guardados exitosamente.`
          }
        },
        2: {
          title: `Organigrama plantel`
        }
      }
    },
    members: {
      seo: {
        title: `Miembros del plantel`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      breadcrumbs: {
        2: `Miembros del plantel`
      },
      title: `Miembros de {{name}}`,
      buttons: {
        add: `Invitar miembro`
      },
      modals: {
        add: {
          title: `Invitar miembro`,
          text: ` Introduce el email del miembro que deseas invitar a formar parte de tu
      plantel.`,
          placeholder: `Escribe un email...`,
          button: `Enviar invitación`,
          success: `Se ha enviado una invitación al correo electrónico {{email}}.`
        },
        delete: {
          title: `Eliminar miembro`,
          invitationTitle: `Eliminar invitación`,
          text: `¿Estás seguro de que quieres eliminar a <strong>{{name}}</strong> de miembros?`,
          invitationText: `¿Estás seguro que deseas eliminar la invitación de <strong>{{name}}</strong>?`,
          confirmText: `Eliminar`,
          success: `Miembro {{name}} borrado con éxito.`,
          invitationSuccess: `Invitación eliminada exitosamente.`
        },
        resend: {
          title: `Reenviar invitación`,
          text: `¿Estás seguro que deseas reenviar la invitación al plantel a <strong>{{email}}</strong>?`,
          confirmText: `Reenviar`,
          success: `Invitación reenviada exitosamente.`
        }
      },
      tabs: {
        1: {
          title: `Activos`,
          ADMINISTRATOR: `Administrador`,
          OWNER: `Propietario`,
          TEACHER: `Profesor`,
          buttons: {
            delete: `Eliminar`
          },
          table: {
            count: `miembro(s)`,
            columns: {
              name: `Nombre del miembro`,
              phone: `Teléfono`,
              role: `Rol`,
              member: `Miembro`
            }
          }
        },
        2: {
          title: `Por aceptar`,
          buttons: {
            delete: `Eliminar`,
            resend: `Reenviar`
          },
          table: {
            count: `miembro(s)`,
            columns: {
              email: `Email`,
              date: `Fecha de envío`,
              member: `Miembro`
            }
          }
        }
      }
    },
    teachers: {
      seo: {
        title: `Profesores del plantel`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      breadcrumbs: {
        2: `Profesores del plantel`
      },
      title: `Profesores de {{name}}`,
      buttons: {
        add: `Invitar profesor`
      },
      modals: {
        add: {
          title: `Invitar profesor`,
          text: ` Introduce el email del profesor que deseas invitar a formar parte de tu
      plantel.`,
          textAssign: `Introduce el email del profesor que deseas invitar a formar parte de`,
          placeholder: `Escribe un email...`,
          placeholderAssign: `Escribe un nombre o email...`,
          placeholderChecking: `Verificando {{email}}...`,
          button: `Enviar invitación(es)`,
          success: `Se ha enviado una invitación al correo electrónico {{email}}.`,
          successMultiple: `Se han enviado {{qty}} invitaciones.`
        },
        delete: {
          title: `Eliminar profesor`,
          invitationTitle: `Eliminar invitación`,
          text: `¿Estás seguro de que quieres eliminar a <strong>{{name}}</strong> de profesores?`,
          textUnassign: `¿Estás seguro que quieres eliminar a <strong>{{name}}</strong> de <strong>{{subject}} - {{period}}</strong> del programa <strong>{{program}}</strong>?`,
          invitationText: `¿Estás seguro que deseas eliminar la invitación de <strong>{{name}}</strong>?`,
          confirmText: `Eliminar`,
          success: `Profesor {{name}} borrado con éxito.`,
          invitationSuccess: `Invitación eliminada exitosamente.`
        },
        resend: {
          title: `Reenviar invitación`,
          text: `¿Estás seguro que deseas reenviar la invitación al plantel a <strong>{{email}}</strong>?`,
          confirmText: `Reenviar`,
          success: `Invitación reenviada exitosamente.`
        }
      },
      tabs: {
        1: {
          title: `Activos`,
          buttons: {
            delete: `Eliminar`
          },
          table: {
            count: `profesor(es)`,
            columns: {
              name: `Nombre del profesor`,
              phone: `Teléfono`,
              subjects: `Materias`,
              member: `Profesor`
            }
          }
        },
        2: {
          title: `Por aceptar`,
          buttons: {
            delete: `Eliminar`,
            resend: `Reenviar`
          },
          table: {
            count: `profesor(es)`,
            columns: {
              email: `Email`,
              date: `Fecha de envío`,
              member: `Profesor`
            }
          }
        }
      }
    },
    documentsReport: {
      seo: {
        title: `Documentos de inscripción`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      breadcrumbs: {
        2: `Reporte de documentos de inscripción`
      },
      title: `Documentos de inscripción`,
      description: `Visualiza el progreso hecho por tus beneficiarios en su proceso de             inscripción. Hasta que el estudiante no haga entrega de todos los documentos solicitados, su inscripción no tendrá validez legal ante las autoridades educativas del país competente.`,
      count: `estudiante(s)`,
      buttons: {
        download: `Descargar`
      },
      forms: {
        program: `Programa educativo`,
        calendar: `Calendario académico`,
        period: `Periodo`,
        submit: `Generar reporte`
      }
    },
    progressReport: {
      seo: {
        title: `Calificaciones`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      breadcrumbs: {
        2: `Reporte de calificaciones`
      },
      title: `Calificaciones`,
      description: `Echa un vistazo al resultado de las evaluaciones presentadas por tus beneficiarios. Las calificaciones son un buen indicador del rendimiento de los estudiantes.`,
      count: `estudiante(s)`,
      buttons: {
        download: `Descargar`
      },
      columns: {
        period: `Periodo`,
        subject: `Materia`,
        subjects: `Materias`,
        progress: `Progreso`,
        score: `Calificación acumulada`,
        evaluations: `Evaluaciones`
      }
    },
    permissions: {
      seo: {
        title: `Gestión de permisos`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      title: `Gestión de permisos`,
      pending: {
        title: `Invitaciones pendientes`,
        nothing: `No hay invitaciones pendientes.`,
        buttons: {
          accept: `Aceptar`,
          reject: `Rechazar`
        },
        modals: {
          title: `Rechazar invitación`,
          text: `¿Deseas rechazar la invitación de <strong>{{name}}</strong>?`
        },
        toasts: {
          successAccept: `La invitación de {{name}} ha sido aceptada exitosamente.`,
          successReject: `La invitación de {{name}} ha sido rechazada exitosamente.`
        }
      },
      invitations: {
        sent: `Invitación enviada el {{date}}`,
        buttons: {
          send: `Reenviar invitación`,
          delete: `Eliminar`
        },
        sendQuestion: `¿Estás seguro que deseas reenviar la invitación a
          <strong>{{email}}</strong>?`,
        deleteQuestion: `¿Estás seguro que deseas eliminar la invitación a
          <strong>{{email}}</strong>?`
      },
      myOrganizations: {
        title: `Organizaciones a las que perteneces`,
        description: `Las siguientes organizaciones te dieron permiso para desempeñarte dentro
      de sus equipos de trabajo.`,
        nothing: `Aún no formas parte del equipo de trabajo de ninguna
              organización dentro de la plataforma.`,
        buttons: {
          revoke: `Revocar permisos`
        },
        modals: {
          title: `Revocar permisos`,
          text: ` ¿Estás seguro? Si revocas los permisos, ya no podrás desempeñarte como <strong>{{role}}</strong> de <strong>{{name}}</strong>.`
        },
        toasts: {
          success: `Has revocado tus permisos como {{role}} de {{name}}.`
        }
      },
      myStudents: {
        title: `Estudiantes autorizados`,
        description: ` Tienes permiso de ver la actividad de los siguientes estudiantes.`,
        nothing: `Aún no tienes ningún estudiante autorizado.`,
        revoke: {
          toast: `Has revocado los permisos como padre/mentor de {{fullName}}.`,
          description: `Actualmente <strong>{{fullName}}</strong> puede monitorear tu actividad
          académica dentro de la plataforma. Si revocas sus permisos, ya no
          podrá hacerlo.`
        }
      },
      myAuthorizedOrganizations: {
        title: `Organizaciones autorizadas`,
        description: `Las siguientes organizaciones tienen permiso de ver tu actividad
        académica.`,
        nothing: ` Aún no formas parte de ninguna organización dentro de la
                plataforma.`
      },
      myTutors: {
        title: `Padres y mentores autorizados`,
        description: `Las siguientes personas tienen permiso de ver tu actividad
              académica.`,
        nothing: `Aún no tienes ningún padre/mentor autorizado.`,
        buttons: {
          add: `Agregar padre/mentor`
        },
        revoke: {
          toast: `Has revocado tu permiso como padre/mentor de {{fullName}}.`,
          description: `¿Deseas renunciar a los permisos que te concedieron como padre/mentor?
          Actualmente tienes acceso a la actividad académica de
          <strong>{{fullName}}</strong>. Si renuncias a los permisos, dejarás de
          ver su actividad dentro de la plataforma.`
        },
        modals: {
          title: `Agregar padre/mentor`,
          description1: `Para que tus padres o mentores puedan seguir y apoyar tus avances
        académicos, debes invitarlos a la plataforma.`,
          description2: ` Escribe a continuación el correo electrónico de la persona que deseas
        invitar.`,
          error: 'El correo de tu padre/mentor debe ser distinto al tuyo.',
          errorStudent: 'El correo del estudiante debe ser distinto al tuyo.'
        }
      }
    },
    buyCoupons: {
      seo: {
        title: `Comprar cupón`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      leavePage: `¿Estás seguro que quieres abandonar el proceso?`,
      steps: {
        1: {
          title: `Seleccionar cupones`,
          description: `Escoge los cupones que quieres comprarle a tus estudiantes.`
        },
        2: {
          title: `Confirmación`,
          description: `Confirma tu compra y realiza el pago`,
          billing: {
            title: `Datos fiscales`,
            cancel: `Cancelar`,
            save: `Guardar datos fiscales`,
            edit: `Editar datos fiscales`,
            alertTitle: `Información fiscal incompleta`,
            alertDescription: `Es necesario que ingreses todos los datos de facturación para poder realizar tu compra.`
          },
          gateways: {
            title: `Realiza tu pago`,
            creditCard: `Tarjeta de crédito o débito`,
            paypal: `Plataforma Paypal`,
            transfer: `Transferencia`,
            cash: `Efectivo`
          },
          products: {
            title: `Productos`,
            quantity: `Cantidad: {{qty}}`
          },
          summary: {
            title: `Resumen de compra`,
            subtotal: `Sub-total: {{qty}} x {{price}}`,
            beforeTaxes: `Monto antes de impuestos`,
            earlyPaymentDiscount: `Descuento de pago anticipado`,
            discounts: `Descuentos por cupones`,
            total: `TOTAL A PAGAR`,
            hasCoupon: ` ¿Tienes cupón de descuento?`,
            placeholder: `Ingresa el código aquí`,
            installment: `Cuota {{number}}`,
            payToday: `Lo que pagas hoy`,
            payLater: `Lo que pagas antes del {{date}}`,
            vouchers: `Descuento por váuchers`,
            confirmPayment: `Confirmar pago`
          },
          transfer: {
            title: `Transferencia bancaria`,
            description: `Debes tomar los 3 pasos a continuación para realizar tu pago. Los tres
      pasos son obligatorios para poder acceder al servicio educativo.`,
            step1: `1. Elige el banco al que deseas realizar la transferencia.`,
            step2: `2. Utiliza la información a continuación para realizar la transferencia bancaria.`,
            step3: `3. Completa los datos del siguiente formulario para que confirmes tu pago.`,
            step3Note: `Ten en cuenta que el proceso de validación de tu pago puede tomar hasta 3 días laborales. Si no registras tu pago, este no será validado y no podrás acceder al servicio educativo.`,
            form: {
              date: `Fecha de la transferencia`,
              bank: `Banco receptor`,
              reference: `Código de referencia completo`,
              amount: `Monto`,
              button: `Registrar pago`,
              referenceError: `El código de referencia es inválido.`,
              payer: `Nombre completo del pagador`
            },
            modal: {
              title: `Los datos de tu pago se han recibido con éxito`,
              description: ` <p>
        Nuestro equipo administrativo debe validar el pago antes de confirmar
        tu compra.
      </p>
      <p>
        La validación puede tomar hasta 3 días laborales. Recibirás
        una notificación en tu buzón de correo electrónico.
      </p>`,
              button: `Continuar`
            }
          },
          creditCard: {
            title: `Tarjeta de crédito o débito`,
            description: `Ingresa los datos de tu tarjeta de crédito o débito.`,
            button: `Pagar`,
            errors: {
              invalid_number: `El número de tarjeta no es válido.`,
              invalid_expiry_year: `El año de vencimiento de la tarjeta no es válido.`,
              invalid_expiry_year_past: `El año de vencimiento de la tarjeta está en el pasado.`,
              invalid_expiry_month_past: `La fecha de vencimiento de la tarjeta está en el pasado.`,
              invalid_expiry_month: `El mes de vencimiento de la tarjeta no es válido.`,
              incomplete_cvc: `El código de seguridad de la tarjeta está incompleto.`,
              incomplete_number: `El número de tarjeta está incompleto.`,
              incomplete_expiry: `La fecha de vencimiento de la tarjeta está incompleta.`,
              incomplete_zip: `El código postal está incompleto.`,
              incorrect_cvc: `El código de seguridad de la tarjeta no es correcto.`,
              expired_card: `La tarjeta ha vencido.`,
              processing_error: `Se produjo un error al procesar tu tarjeta. Espera un momento y vuelve a intentarlo.`,
              generic_decline: `Tu tarjeta fue rechazada.`,
              fraudulent: `Tu tarjeta ha sido rechazada.`,
              insufficient_funds: `Tu tarjeta no tiene fondos suficientes.`,
              lost_card: `Tu tarjeta ha sido rechazada.`,
              stolen_card: `Tu tarjeta ha sido rechazada.`,
              card_declined: `Tu tarjeta ha sido rechazada.`,
              undefined: `Tu tarjeta fue rechazada.`,
              null: `Tu tarjeta fue rechazada.`
            },
            modal: {
              title: `¡Felicitaciones!`,
              description: ` <p>
        Tu pago se ha realizado con éxito.
      </p>
      <p>
       Tu factura será enviada a tu buzón de correo electrónico y la podrás encontrar también en la sección de <strong>Mis pagos</strong>.
      </p>`,
              button: `Continuar`
            },
            modalError: {
              title: `Pago rechazado`,
              description: `<p>Tu pago ha sido rechazado. Por favor, intenta de nuevo o utiliza otro método de pago.</p>`,
              button: `Continuar`
            },
            modalPaying: {
              title: `Tu pago se está procesando`,
              description: `Por favor espera. No refresques ni cierres esta pestaña.`
            },
            modalFreePaying: {
              title: `Tu inscripción se está procesando`,
              description: `Por favor espera. No refresques ni cierres esta pestaña.`
            },
            modalFree: {
              title: `¡Felicitaciones!`,
              description: `<p>Te has inscrito exitosamente en la prueba gratis del programa <strong>{{programName}}</strong>. En cualquier momento de los <strong>{{freePeriodDays}}</strong> días que dura la prueba gratuita, puedes realizar el pago de la inscripción para continuar tus estudios como un estudiante regular del programa. </p>`,
              button: `Comenzar a estudiar`
            }
          },
          paypal: {
            title: `Plataforma PayPal`,
            description: `Serás redirigido al sitio web de PayPal para completar tu pago.`,
            modal: {
              title: `¡Felicitaciones!`,
              description: ` <p>
        Tu pago se ha realizado con éxito.
      </p>
      <p>
       Tu factura será enviada a tu buzón de correo electrónico y la podrás encontrar también en la sección de <strong>Mis pagos</strong>.
      </p>`,
              button: `Continuar`
            }
          }
        }
      }
    },
    enrolment: {
      seo: {
        title: `Proceso de inscripción`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      leavePage: `¿Estás seguro que quieres abandonar el proceso?`,
      steps: {
        1: {
          title: `Calendario`,
          description: `Selecciona el calendario académico que deseas cursar`,
          format: `Del {{start}} al {{end}}`
        },
        2: {
          title: `Nivel académico`,
          description: `Indica el nivel académico en el que deseas inscribirte`,
          lastOption: `No estoy seguro`,
          alertDocs: {
            title: `Documentos de inscripción`,
            p1: `Para verificar que el nivel académico escogido es el correcto y
            poder formalizar tu matriculación en Dawere, haz entrega de los
            siguientes documentos:`,
            p2: `Una vez que realices tu inscripción, contarás con 15 días para
              entregar estos documentos.`
          }
        },
        3: {
          title: `Confirmación`,
          description: `Confirma tu compra y realiza el pago`,
          billing: {
            title: `Datos fiscales`,
            cancel: `Cancelar`,
            save: `Guardar datos fiscales`,
            edit: `Editar datos fiscales`,
            alertTitle: `Información fiscal incompleta`,
            alertDescription: `Es necesario que ingreses todos los datos de facturación para poder realizar tu compra.`
          },
          gateways: {
            title: `Realiza tu pago`,
            creditCard: `Tarjeta de crédito o débito`,
            paypal: `Plataforma Paypal`,
            transfer: `Transferencia`,
            cash: `Efectivo`
          },
          products: {
            title: `Productos`,
            quantity: `Cantidad: {{qty}}`
          },
          summary: {
            isFullPayment: `¿Pago completo o fraccionado?`,
            fullPayment: `Completo`,
            partialPayment: `Fraccionado`,
            title: `Resumen de compra`,
            subtotal: `Sub-total: {{qty}} x {{price}}`,
            beforeTaxes: `Monto antes de impuestos`,
            total: `TOTAL A PAGAR`,
            hasCoupon: ` ¿Tienes cupón de descuento o regalo?`,
            placeholder: `Ingresa el código de tu cupón`,
            tooltip: `Con el pago fraccionado te comprometes a pagar todas las cuotas, incluso si no culminas el periodo escolar. Debes realizar los pagos en los tiempos indicados o se bloqueará la plataforma de estudio.`
          },
          transfer: {
            title: `Transferencia bancaria`,
            description: `Debes tomar los 3 pasos a continuación para realizar tu pago. Los tres
      pasos son obligatorios para poder acceder al servicio educativo.`,
            step1: `1. Elige el banco al que deseas realizar la transferencia.`,
            step2: `2. Utiliza la información a continuación para realizar la transferencia bancaria.`,
            step3: `3. Completa los datos del siguiente formulario para que confirmes tu pago.`,
            step3Note: `Ten en cuenta que el proceso de validación de tu pago puede tomar hasta 3 días laborales. Si no registras tu pago, este no será validado y no podrás acceder al servicio educativo.`,
            form: {
              date: `Fecha de la transferencia`,
              bank: `Banco receptor`,
              reference: `Código de referencia completo`,
              payer: `Nombre completo del pagador`,
              amount: `Monto`,
              button: `Registrar pago`,
              referenceError: `El código de referencia es inválido.`
            },
            modal: {
              title: `Los datos de tu pago se han recibido con éxito`,
              description: ` <p>
        Nuestro equipo administrativo debe validar el pago antes de confirmar
        tu compra.
      </p>
      <p>
        La validación puede tomar hasta 3 días laborales. Recibirás
        una notificación en tu buzón de correo electrónico.
      </p>`,
              button: `Continuar`
            }
          },
          creditCard: {
            title: `Tarjeta de crédito o débito`,
            description: `Ingresa los datos de tu tarjeta de crédito o débito.`,
            button: `Pagar`,
            errors: {
              invalid_number: `El número de tarjeta no es válido.`,
              invalid_expiry_year: `El año de vencimiento de la tarjeta no es válido.`,
              invalid_expiry_year_past: `El año de vencimiento de la tarjeta está en el pasado.`,
              invalid_expiry_month_past: `La fecha de vencimiento de la tarjeta está en el pasado.`,
              invalid_expiry_month: `El mes de vencimiento de la tarjeta no es válido.`,
              incomplete_cvc: `El código de seguridad de la tarjeta está incompleto.`,
              incomplete_number: `El número de tarjeta está incompleto.`,
              incomplete_expiry: `La fecha de vencimiento de la tarjeta está incompleta.`,
              incomplete_zip: `El código postal está incompleto.`,
              incorrect_cvc: `El código de seguridad de la tarjeta no es correcto.`,
              expired_card: `La tarjeta ha vencido.`,
              processing_error: `Se produjo un error al procesar tu tarjeta. Espera un momento y vuelve a intentarlo.`,
              generic_decline: `Tu tarjeta fue rechazada.`,
              fraudulent: `Tu tarjeta ha sido rechazada.`,
              insufficient_funds: `Tu tarjeta no tiene fondos suficientes.`,
              lost_card: `Tu tarjeta ha sido rechazada.`,
              stolen_card: `Tu tarjeta ha sido rechazada.`,
              card_declined: `Tu tarjeta ha sido rechazada.`,
              undefined: `Tu tarjeta fue rechazada.`,
              null: `Tu tarjeta fue rechazada.`
            },
            modal: {
              title: `¡Felicitaciones!`,
              description: ` <p>
        Tu pago se ha realizado con éxito.
      </p>
      <p>
       Tu factura será enviada a tu buzón de correo electrónico y la podrás encontrar también en la sección de <strong>Mis pagos</strong>.
      </p>`,
              button: `Continuar`
            },
            modalPaying: {
              title: `Tu pago se está procesando`,
              description: `Por favor espera. No refresques ni cierres esta pestaña.`
            }
          },
          paypal: {
            title: `Plataforma PayPal`,
            description: `Serás redirigido al sitio web de PayPal para completar tu pago.`,
            modal: {
              title: `¡Felicitaciones!`,
              description: ` <p>
        Tu pago se ha realizado con éxito.
      </p>
      <p>
       Tu factura será enviada a tu buzón de correo electrónico y la podrás encontrar también en la sección de <strong>Mis pagos</strong>.
      </p>`,
              button: `Continuar`
            }
          }
        }
      }
    },
    pdfReceipt: {
      title: `RECIBO`,
      docName: `Recibo {{code}}`,
      info: {
        title: `Dawere International Inc.`,
        address: `8554 NW 61st ST`,
        country: `Miami, FL 33166 US`,
        phoneNumber: `+1 9542972392`,
        email: `admin@dawere.com`,
        web: `www.dawere.com`
      },
      billingInfo: {
        title: `FACTURADO A`,
        number: `RECIBO Nº`,
        date: `FECHA DE PAGO`
      },
      table: {
        columns: {
          lineNumber: `#`,
          product: `Producto`,
          quantity: `Cant.`,
          price: `Precio`,
          total: `Sub-total`
        },
        subTotal: `SUB-TOTAL`,
        discounts: `DESCUENTOS`,
        earlyPayment: `PAGO ANTICIPADO`,
        vouchers: `VÁUCHERS`,
        tax: `IMPUESTO`,
        total: `TOTAL`,
        payment: `PAGO`,
        PAID: `PAGADO`,
        PENDING: `PENDIENTE`,
        FAILED: `RECHAZADO`,
        VOID: `ANULADO`,
        VOIDED: `ANULADO`
      }
    },
    settings: {
      seo: {
        title: `Configuración`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      title: `Configuración`,
      tabs: {
        1: {
          title: `Mi cuenta`,
          changePassword: {
            title: `CAMBIAR CONTRASEÑA`,
            oldPassword: `Contraseña actual`,
            newPassword: `Contraseña nueva`,
            confirmPassword: `Confirma tu contraseña nueva`,
            submit: `Guardar cambios`
          },
          profile: {
            title: `PERFIL`,
            sentence1: `Quiero utilizar Dawere como:`,
            student: `Estudiante`,
            teacher: `Padre/Mentor`,
            organization: `Organización`,
            sentence2: `Quiero que mi perfil por defecto sea:`,
            submit: `Guardar cambios`,
            STUDENT: `Estudiante`,
            ORGANIZATION: `Organización`,
            TUTOR: `Padre/Mentor`,
            toast: `Cambios guardados.`
          },
          deleteAccount: {
            title: `Eliminar cuenta`,
            shortDesc: `Una vez que eliminas tu cuenta, no hay vuelta atrás. Por favor, confirma que esto es lo que deseas hacer.`,
            longDesc1: `Antes de eliminar tu cuenta debes saber que al hacerlo borrarás tu
              <strong>email</strong>, <strong>teléfono</strong> y <strong>contraseña</strong> de nuestros servidores. Sin embargo,
              por requerimientos de las autoridades educativas de cada país, el
              registro e historial de tu récord académico no puede ser
              eliminado. Esta información queda registrada en los colegios y no
              se puede modificar ni eliminar.`,
            longDesc2: `Escribe la palabra <code>BORRAR</code> para confirmar la acción:`,
            toast: `Tu cuenta fue eliminada correctamente.`
          }
        },
        2: {
          title: `Notificaciones`,
          description: `¿Cómo deseas recibir información relevante para ti? Puedes seleccionar
      ambas opciones.`,
          option1: `Acepto recibir emails con promociones y noticias de Dawere.`,
          option2: `Acepto recibir mensajes de texto SMS con promociones y noticias de
            Dawere. <br />
            Pueden aplicar tarifas por envío de mensajes y uso de datos
            móviles.`,
          submit: `Guardar preferencias`,
          toast: `Tus preferencias fueron actualizadas con éxito.`
        },
        3: {
          title: `Datos de facturación`,
          billing: {
            title: `INFORMACIÓN FISCAL`,
            description: `Las facturas que recibas por los servicios educativos de Dawere serán
      emitidas con los datos que ingreses a continuación.`,
            billingName: `Nombre o razón social`,
            billingTin: `Número de registro fiscal o identidad`,
            billingCountry: `País`,
            billingState: `Estado`,
            billingTownship: `Localidad`,
            billingAddress: `Dirección`,
            billingPostalCode: `Código postal`,
            submit: `Guardar`
          }
        }
      }
    },
    teacherDashboard: {
      seo: {
        title: `Panel de profesores`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      breadcrumbs: {
        1: `Mis organizaciones`,
        2: `Panel de profesores`
      },
      welcome: `Bienvenido a, {{name}}`,
      title: `Panel de profesores`,
      description: `A continuación podrá visualizar las materias que imparte por periodo.`,
      descriptionEmpty: `Aún no tienes materias asignadas. Comuníquese con su organización.`,
      buttons: {
        monitor: `Monitoreo`,
        virtualClass: `Aula virtual`,
        chat: `Chat`
      }
    },
    teacherMonitoring: {
      seo: {
        title: `Monitoreo de estudiantes`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      breadcrumbs: {
        2: `Monitoreo de estudiantes`
      },
      title: `Monitoreo`,
      table: {
        count: `estudiantes(es)`,
        columns: {
          name: `Nombre del estudiante`,
          progress: `Progreso`,
          score: `Calificación acumulada`,
          evaluations: `Evaluaciones`,
          students: `Estudiantes`
        }
      },
      buttons: {
        virtualClass: `Aula virtual`,
        chat: `Chat`
      }
    },
    formativeEvaluationGrades: {
      seo: {
        title: `Calificación obtenida: Evaluación formativa`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      breadcrumbs: {
        2: `Calificaciones`
      },
      title: `Calificación obtenida`,
      description: `Este reporte presenta la calificación obtenida en la evaluación y un resumen de tu desempeño por competencias`,
      level: `Nivel de preparación`,
      topic: `Tema`,
      cognitiveDemand: `Demanda cognitiva`,
      button: `Seguir practicando`,
      answers: `Respuestas {{points}}/{{qty}}`
    },
    pisaEvaluationGrades: {
      seo: {
        title: `Calificación obtenida: Evaluación sumativa - PISA`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      breadcrumbs: {
        2: `Calificaciones`
      },
      title: `Calificación obtenida`,
      description: `Este reporte presenta la calificación obtenida en la evaluación y un resumen de tu desempeño por competencias`,
      level: `Nivel de preparación`,
      topic: `Tema`,
      cognitiveDemand: `Demanda cognitiva`,
      button: `Seguir practicando`,
      answers: `Respuestas {{points}}/{{qty}}`
    },
    grades: {
      seo: {
        title: ` Reporte de calificaciones`,
        description: ``,
        keywords: ``,
        ogTitle: ``,
        ogDescription: ``,
        ogImage: ``
      },
      title: ` Reporte de calificaciones`,
      description: `A continuación podrás ver tus calificaciones obtenidas por cada materia inscrita:`,
      download: `Descargar`,
      average: `Promedio`,
      position: `Posición`,
      school: {
        title: `Datos de la institución`,
        1: `Institución educativa`,
        2: `Código del plantel`,
        3: `Dirección`,
        4: `Circuíto educativo`,
        5: `Autoridad educativa`
      },
      student: {
        title: `Datos del estudiante`,
        1: `Nombres`,
        2: `Apellidos`,
        3: `Tipo de identificación`,
        4: `Número de identificación`
      },
      enrollment: {
        title: `Datos de la inscripción`,
        1: `Programa educativo`,
        2: `Nivel académico`,
        3: `Calendario`,
        4: `Fecha de emisión`
      }
    }
  },
  sections: {
    navbar: {
      roles: {
        ORGANIZATION: `Organización`,
        STUDENT: `Estudiante`
      },
      links: {
        catalog: `Programas educativos`,
        help: `Ayuda`,
        home: `Inicio`,
        organizations: `Para organizaciones`,
        organizationsDashboard: `Panel de organizaciones`,
        parentDashboard: `Panel de padre/mentor`,
        studentDashboard: `Panel de estudiante`,
        dashboardOf: `Panel de {{name}}`,
        profile: `Perfil`,
        myDocuments: `Mis documentos`,
        myOrganizations: `Mis organizaciones`,
        settings: `Configuración`,
        procedures: `Trámites`,
        payments: `Cuotas y pagos`,
        permissions: `Gestión de permisos`,
        wallet: `Vales de descuento`,
        closeSession: `Cerrar sesión`
      },
      buttons: {
        login: `Inicia sesión`,
        register: `Regístrate`
      }
    },
    footer: {
      column1: {
        title: `Dawere`,
        text: `U.E.P.A Dawere - Registrados en el Ministerio del Poder Popular para la Educación de Venezuela bajo el permiso de funcionamiento Nº 05-698-20 como una institución de educación para adultos con el siguiente código de plantel: PN-18181519`,
        copyright: `© 2021 Dawere`
      },
      column2: {
        title: `Empresa`,
        links: {
          1: `Filosofía Educativa`,
          2: `Manual del estudiante y representante`,
          3: `Términos y condiciones`,
          4: `Política de privacidad`
        },
        urls: {
          1: ``,
          2: `https://storage.googleapis.com/dawere-web-assets/pdf/PT-222-038%20Student%20Handbook%20Dawere%20USA.pdf`,
          3: `https://storage.googleapis.com/dawere-web-assets/pdf/TerminosyCondicionesEEUU.pdf`,
          4: `https://storage.googleapis.com/dawere-web-assets/pdf/PoliticadetratamientodedatosEEUUV2.pdf`
        }
      },
      column3: {
        title: `Recursos`,
        links: {
          1: `Ayuda`,
          2: `Becas`,
          3: `Blog`,
          4: `Países`
        }
      },
      column4: {
        title: `Conéctate`,
        links: {
          1: `Contáctanos`,
          2: `Instagram`,
          3: `Facebook`,
          4: `X`,
          5: `YouTube`
        },
        urls: {
          1: ``,
          2: `https://www.instagram.com/dawereoficial/`,
          3: `https://www.facebook.com/dawereoficial`,
          4: `https://twitter.com/dawereoficial`,
          5: `https://www.youtube.com/channel/UC4EJUs2GGy2Ja2Wu9Urkf_g`
        }
      }
    }
  },
  latex: {
    loading: 'Cargando...'
  },
  tours: {
    finish: `Finalizar`,
    studentDahboard: {
      intro1: `<p class="fw-bold">¡Bienvenido al programa {{programName}}!</p><p>A continuación, te haremos un pequeño recorrido para que te familiarices con el panel de estudiantes.</p>`,
      intro2: `Aquí debes subir <span class="fw-bold">tus documentos</span>, lo antes posible, para que podamos formalizar tu inscripción.`,
      intro3: `En la sección de <span class="fw-bold">"Ayuda"</span>, puedes crear tickets para resolver asuntos técnicos, administrativos, de control de estudios y de contenido educativo.`,
      intro4: `Aquí conseguirás las materias que tienes inscritas.`,
      intro5: `Haz clic sobre alguna de las materias para entrar a ver su contenido educativo.`,
      intro6: `Aquí recibirás comunicaciones importantes para tu éxito académico. Revisa esta bandeja de entrada con regularidad.`
    },
    classroom: {
      intro1: `Debes revisar cada tema aquí listado. Haz clic sobre un tema para acceder a su contenido educativo.`,
      intro2: `Haz clic sobre una <span class="fw-bold">videoclase</span> para ver su contenido.`,
      intro3: `Haz clic sobre una <span class="fw-bold">lectura</span> para leer su contenido.`,
      intro4: `Haz clic sobre una <span class="fw-bold">evaluación formativa</span> para practicar lo que has aprendido en el tema y prepararte para tus evaluaciones sumativas.`,
      intro5: `<p>Cuando te sientas preparado, haz clic aquí para presentar las evaluaciones sumativas.</p><p>El resultado de estas evaluaciones formarán parte de tu calificación final.</p>`,
      intro6: `Haz clic sobre el chat de la materia para interactuar con tu profesor y con tus compañeros de clase.`
    }
  },
  inviteFriends: {
    button: 'Invita a un amigo',
    title: 'Invita a un amigo',
    text1:
      'Invita a tus amigos a inscribirse en alguno de los programas educativos de Dawere y gana vales de descuento.',
    text2:
      'Por cada amigo que se inscriba en alguno de nuestros programas gracias a tu enlace de invitación ganarás un vale de descuento de $20 para usarlo en tu próxima inscripción. La persona que se registre en Dawere a través de tu enlace de invitación recibirá un vale de descuento de $10 para usarlo en su próxima inscripción.',
    text3: 'Comparte con tus conocidos el siguiente enlace de invitación:',
    successModal: {
      title: '¡Te has ganado un vale de descuento de $10!',
      text1: `Por registrarte en Dawere a través del enlace de invitación de <strong>{{name}}</strong> te has ganado un vale de descuento de $10. Podrás usar dicho vale como parte de pago de tu próxima inscripción en cualquiera de nuestros programas.`,
      text2: `Tu también puedes invitar a tus amigos y ganar un vale de descuento de $20 por cada amigo que se inscriba en Dawere gracias a tu enlace de invitación. Ve a la sección de <strong>"Vales de descuento"</strong> para ver tu saldo y tu propio enlace de invitación.`,
      button1: 'Ver Programas educativos',
      button2: ' Ir a Vales de descuento'
    }
  }
};

export { es };
