import { Utils } from 'dawere-uic';
import { useContext } from 'react';
import { EnrollContext } from '../EnrollProvider';

export const useEnroll = () => {
  const context = useContext(EnrollContext);

  if (Utils.isEmpty(context)) {
    throw new Error('"useEnroll" must be used within a EnrollProvider');
  }

  return context;
};
