import { Utils } from 'dawere-uic';
import { BareLayout, EvaluationLayout, PDFLayout } from 'layouts';
import { lazy } from 'react';
import { COUNTRIES_REGEXP } from 'utils';
import { RouteProps } from './props';

export const basePath: string = `/:lang${COUNTRIES_REGEXP}`;

export const routes: RouteProps[] = flatten([
  {
    key: 'HOME',
    exact: true,
    path: '/',
    component: lazy(() => import('pages/public/home/Home')),
    striped: true,
    inverse: true
  },
  {
    key: 'REFERENCE',
    exact: true,
    dual: true,
    path: '/referencia/:referredBy',
    component: lazy(() => import('pages/public/reference/Reference'))
  },
  {
    key: 'ORGANIZATIONS',
    exact: true,
    dual: true,
    path: '/organizaciones',
    component: lazy(() => import('pages/public/organizations/Organizations')),
    striped: true,
    routes: [
      {
        key: 'ORGANIZATIONS_DEMO_REQUESTED',
        exact: true,
        path: '/demostracion-solicitada',
        component: lazy(
          () => import('pages/public/organizations/DemoRequested')
        )
      }
    ]
  },
  {
    key: 'REGISTER',
    exact: true,
    dual: true,
    path: '/registro',
    component: lazy(() => import('pages/public/signup/Signup')),
    routes: [
      {
        key: 'ORGANIZATION_REGISTER',
        exact: true,
        dual: true,
        path: '/organizacion',
        component: lazy(() => import('pages/public/signup/OrganizationSignup'))
      },
      {
        key: 'SUCCESS_REGISTER',
        exact: true,
        dual: true,
        path: '/verificar-cuenta',
        component: lazy(() => import('pages/public/account/OpenDashboard'))
      }
    ]
  },
  {
    key: 'LOGIN',
    exact: true,
    dual: true,
    path: '/inicio-sesion',
    component: lazy(() => import('pages/public/signin/SignIn'))
  },
  {
    key: 'PRIVACY_POLICY',
    exact: true,
    dual: true,
    path: '/politica-de-privacidad',
    component: lazy(() => import('pages/public/misc/PrivacyPolicy'))
  },
  {
    key: 'TERMS_AND_CONDITIONS',
    exact: true,
    dual: true,
    path: '/terminos-y-condiciones',
    component: lazy(() => import('pages/public/misc/TermsNConditions'))
  },
  {
    key: 'FORGOT_PASSWORD',
    exact: true,
    dual: true,
    path: '/olvide-contrasena',
    component: lazy(() => import('pages/public/passwords/ForgotPassword')),
    routes: [
      {
        key: 'RESET_PASSWORD_REQUESTED',
        exact: true,
        dual: true,
        path: '/reinicio-solicitado',
        component: lazy(
          () => import('pages/public/passwords/ResetPasswordRequested')
        )
      }
    ]
  },
  {
    key: 'RESET_PASSWORD',
    exact: true,
    dual: true,
    path: '/reiniciar-contrasena',
    component: lazy(() => import('pages/public/passwords/ResetPassword'))
  },
  {
    key: 'VERIFY_EMAIL',
    exact: true,
    dual: true,
    path: '/verificar-cuenta',
    component: lazy(() => import('pages/public/account/CheckYourEmail')),
    routes: [
      {
        key: 'VERIFIED_ACCOUNT',
        exact: true,
        dual: true,
        path: '/verificada',
        component: lazy(() => import('pages/public/account/VerifiedAccount'))
      }
    ]
  },
  {
    key: 'PASSWORD_CHANGED',
    exact: true,
    dual: true,
    path: '/contrasena-cambiada',
    component: lazy(() => import('pages/public/passwords/PasswordChanged'))
  },
  {
    key: 'PROGRAM',
    exact: true,
    dual: true,
    path: '/programa/:programId',
    component: lazy(() => import('pages/public/program/Program')),
    striped: true,
    inverse: true,
    routes: [
      {
        key: 'PROGRAM_SUBJECT',
        exact: true,
        dual: true,
        path: '/materia/:subjectId',
        component: lazy(() => import('pages/public/subject/Subject'))
      }
    ]
  },
  {
    key: 'COURSE',
    exact: true,
    dual: true,
    path: '/curso/:programId',
    component: lazy(() => import('pages/public/program/Course')),
    striped: true,
    inverse: true,
    routes: [
      {
        key: 'COURSE_SUBJECT',
        exact: true,
        dual: true,
        path: '/materia/:subjectId',
        component: lazy(() => import('pages/public/subject/Subject'))
      }
    ]
  },
  {
    key: 'CATALOG',
    exact: true,
    dual: true,
    striped: true,
    path: '/programas-educativos',
    component: lazy(() => import('pages/public/catalog/Catalog'))
  },
  {
    key: 'HELP_CENTER',
    exact: true,
    dual: true,
    path: '/ayuda',
    component: lazy(() => import('pages/public/misc/RedirectHelp'))
  },
  {
    key: 'ORGANIZATIONS_DASHBOARD',
    exact: true,
    dual: true,
    path: '/panel/organizacion/:organizationId',
    restricted: true,
    component: lazy(
      () =>
        import('pages/private/dashboards/organizations/OrganizationsDashboard')
    )
  },
  {
    key: 'TEACHERS_DASHBOARD',
    exact: true,
    dual: true,
    path: '/panel/profesor/:organizationId',
    restricted: true,
    component: lazy(
      () => import('pages/private/dashboards/teachers/TeachersDashboard')
    )
  },
  {
    key: 'STUDENTS_DASHBOARD',
    exact: true,
    dual: true,
    path: '/panel/estudiante',
    restricted: true,
    component: lazy(
      () => import('pages/private/dashboards/students/StudentsDashboard')
    )
  },
  {
    key: 'TUTORS_DASHBOARD',
    exact: true,
    dual: true,
    path: '/panel/mentor',
    restricted: true,
    component: lazy(
      () => import('pages/private/dashboards/tutors/TutorsDashboard')
    ),
    routes: [
      {
        key: 'TUTORS_DASHBOARD_STUDENT_VIEW',
        exact: true,
        dual: true,
        path: '/estudiante/:studentId',
        restricted: true,
        component: lazy(
          () =>
            import('pages/private/dashboards/students/StudentsViewDashboard')
        )
      }
    ]
  },
  {
    key: 'TEACHERS_MONITORING',
    exact: true,
    dual: true,
    path: '/monitoreo/profesor/:organizationId/p/:programId/m/:subjectId/np/:periodName',
    restricted: true,
    component: lazy(
      () => import('pages/private/teacherMonitoring/TeacherMonitoring')
    )
  },
  {
    key: 'STUDENT_VIEW',
    exact: true,
    dual: true,
    path: '/detalles/estudiante/:studentId',
    restricted: true,
    component: lazy(() => import('pages/private/studentView/StudentView'))
  },
  {
    key: 'PROFILE',
    exact: true,
    dual: true,
    path: '/perfil',
    restricted: true,
    component: lazy(() => import('pages/private/profile/Profile'))
  },
  {
    key: 'MY_ORGANIZATIONS',
    exact: true,
    dual: true,
    path: '/mis-organizaciones',
    restricted: true,
    component: lazy(
      () => import('pages/private/myOrganizations/MyOrganizations')
    ),
    routes: [
      {
        key: 'CREATE_ORGANIZATIONS',
        exact: true,
        path: '/crear',
        restricted: true,
        component: lazy(
          () => import('pages/private/myOrganizations/CreateOrganization')
        )
      },
      {
        key: 'EDIT_ORGANIZATIONS',
        exact: true,
        path: '/configurar/:organizationId',
        restricted: true,
        component: lazy(
          () => import('pages/private/myOrganizations/EditOrganization')
        )
      }
    ]
  },
  {
    key: 'ROOT',
    exact: true,
    ignoreIntl: true,
    path: '/',
    component: lazy(() => import('pages/public/home/CountryPicker')),
    layout: BareLayout
  },
  {
    key: 'PHILOSOPHY',
    exact: true,
    dual: true,
    path: '/filosofia',
    component: lazy(() => import('pages/public/philosophy/Philosophy')),
    striped: true
  },
  {
    key: 'INVALID_TOKEN',
    exact: true,
    path: '/token-expirado',
    component: lazy(() => import('pages/public/misc/InvalidToken')),
    striped: true
  },
  {
    key: 'MEMBERS_LIST',
    exact: true,
    path: '/miembros/:organizationId',
    restricted: true,
    component: lazy(
      () => import('pages/private/organizationCharts/members/Members')
    )
  },
  {
    key: 'TEACHERS_LIST',
    exact: true,
    path: '/profesores/:organizationId',
    restricted: true,
    component: lazy(
      () => import('pages/private/organizationCharts/teachers/Teachers')
    )
  },
  {
    key: 'REPORT_TRANSCRIPTS',
    exact: true,
    path: '/reportes/calificaciones/:organizationId',
    restricted: true,
    component: lazy(() => import('pages/private/reports/TranscriptsReport'))
  },
  {
    key: 'REPORT_DOCUMENTS',
    exact: true,
    path: '/reportes/documentos-de-inscripcion/:organizationId',
    restricted: true,
    component: lazy(() => import('pages/private/reports/DocumentsReport'))
  },
  {
    key: 'PERMISSIONS',
    exact: true,
    dual: true,
    path: '/gestion-permisos',
    restricted: true,
    component: lazy(() => import('pages/private/permissions/Permissions'))
  },
  {
    key: 'BUY_COUPONS',
    exact: true,
    dual: true,
    path: '/comprar-cupones',
    restricted: true,
    component: lazy(() => import('pages/private/buyCoupons/BuyCoupons'))
  },
  {
    key: 'PAY',
    exact: true,
    dual: true,
    path: '/pagar/:orderId',
    restricted: true,
    component: lazy(() => import('pages/private/payment/Payment'))
  },
  {
    key: 'FREE_TRIAL_ENROLL',
    exact: true,
    dual: true,
    path: '/inscripcion-prueba-gratis/:orderId/p/:programId',
    restricted: true,
    component: lazy(
      () => import('pages/private/freeTrialPayment/FreeTrialPayment')
    )
  },
  {
    key: 'ENROLL',
    exact: true,
    dual: true,
    path: '/inscripcion/:programId/m/:currencyId',
    restricted: true,
    component: lazy(() => import('pages/private/enroll/Enroll'))
  },
  {
    key: 'RECEIPT',
    exact: true,
    layout: PDFLayout,
    path: '/recibo/:receiptId',
    component: lazy(() => import('pages/public/receipt/ReceiptPDF')),
    dual: true
  },
  {
    key: 'PISA_EVALUATION_VIEW',
    exact: true,
    layout: BareLayout,
    path: '/evaluacion/o/:attemptId',
    component: lazy(() => import('pages/public/results/PisaReview')),
    dual: true
  },
  {
    key: 'PROJECT_EVALUATION_VIEW',
    exact: true,
    layout: BareLayout,
    path: '/proyecto/o/:attemptId',
    component: lazy(() => import('pages/public/results/ProjectReview')),
    dual: true
  },
  {
    key: 'SCORECARDS_VIEW',
    exact: true,
    layout: BareLayout,
    path: '/boletin/:enrollmentId',
    component: lazy(() => import('pages/public/scorecards/Scorecards')),
    dual: true,
    routes: [
      {
        key: 'SCORECARDS_VIEW_PDF',
        exact: true,
        dual: true,
        path: '/pdf',
        layout: PDFLayout,
        component: lazy(() => import('pages/public/scorecards/ScorecardsPDF'))
      }
    ]
  },
  {
    key: 'SETTINGS',
    exact: true,
    path: '/configuracion',
    restricted: true,
    component: lazy(() => import('pages/private/settings/Settings')),
    dual: true
  },
  {
    key: 'WALLET',
    exact: true,
    path: '/monedero',
    restricted: true,
    component: lazy(() => import('pages/private/wallet/Wallet')),
    dual: true
  },
  {
    key: 'CLASSROOM',
    exact: true,
    dual: true,
    path: '/aula-virtual/m/:subjectId/p/:enrollmentId',
    restricted: true,
    component: lazy(() => import('pages/private/classroom/Classroom'))
  },
  {
    key: 'CLASSROOM_VARIANT',
    exact: true,
    dual: true,
    path: '/aula-virtual/e/:enrollmentId/m/:subjectId',
    restricted: true,
    component: lazy(() => import('pages/private/classroom/Classroom'))
  },
  {
    key: 'FORMATIVE_EVALUATION',
    exact: true,
    dual: true,
    path: '/evaluacion-formativa/c/:contentId/m/:subjectId/e/:enrollmentId/t/:topicId',
    restricted: true,
    layout: EvaluationLayout,
    component: lazy(
      () => import('pages/private/evaluations/FormativeEvaluation')
    ),
    routes: [
      {
        key: 'FORMATIVE_EVALUATION_GRADES',
        exact: true,
        dual: true,
        path: '/resultados',
        restricted: true,
        component: lazy(
          () => import('pages/private/evaluations/FormativeScore')
        )
      }
    ]
  },
  {
    key: 'PISA_EVALUATION',
    exact: true,
    dual: true,
    path: '/evaluacion-pisa/o/:attemptId/c/:contentId/m/:subjectId/e/:enrollmentId',
    restricted: true,
    layout: EvaluationLayout,
    component: lazy(() => import('pages/private/evaluations/PisaEvaluation')),
    routes: [
      {
        key: 'PISA_EVALUATION_GRADES',
        exact: true,
        dual: true,
        path: '/resultados',
        restricted: true,
        component: lazy(() => import('pages/private/evaluations/PisaScore'))
      },
      {
        key: 'PISA_EVALUATION_DETAILS',
        exact: true,
        dual: true,
        path: '/detalles',
        restricted: true,
        component: lazy(() => import('pages/private/evaluations/PisaReview'))
      }
    ]
  },
  {
    key: 'NOTIFICATIONS',
    exact: true,
    path: '/notificaciones',
    restricted: true,
    component: lazy(() => import('pages/private/notifications/Notifications')),
    dual: true
  }
  // {
  //   key: 'OFFICIAL_TRANSCRIPT',
  //   exact: true,
  //   layout: PDFLayout,
  //   path: '/notas-certificadas/e/:studentId/p/:programId/t/:token',
  //   component: lazy(() => import('pages/public/OfficialTranscriptPDF')),
  //   dual: true
  // },
  // {
  //   key: 'FORUM',
  //   exact: true,
  //   path: '/foro',
  //   restricted: true,
  //   component: lazy(() => import('pages/private/forum/Forum')),
  //   dual: true
  // }
]);

function flatten(routes: RouteProps[]): RouteProps[] {
  // Recursive loop
  const flattenDeep = (
    routes: RouteProps[] = [],
    accum: any[],
    path: string = ''
  ): void => {
    routes.forEach((route: RouteProps) => {
      const newPath = path + route.path;

      if (route.dual) {
        accum.push({
          ...route,
          path: `${basePath}${newPath}`,
          rootPath: newPath
        });
        accum.push({
          ...route,
          key: `${route.key}_DUAL`,
          path: newPath,
          rootPath: newPath
        });
      } else {
        accum.push({
          ...route,
          path: `${route.ignoreIntl ? '' : basePath}${newPath}`,
          rootPath: newPath
        });
      }

      if (Utils.isArray(route.routes)) {
        flattenDeep(route.routes, accum, newPath);
      }
    });
  };

  const flattened: RouteProps[] = [];
  flattenDeep(routes, flattened);
  return flattened;
}
