import { Session } from 'dawere-commons';
import { Utils } from 'dawere-uic';
import { getFullCountryInfo } from 'utils';

function createItem(data: any, value = 'id', label = 'itemValue') {
  return data
    ? {
        value: data[value],
        label: data[label]
      }
    : null;
}

export function getProfile() {
  const account = Session.getAccountInfo();

  const user = {
    name: account.name,
    lastName: account.lastName,
    phoneCode: account.phoneCode || getFullCountryInfo().dialCode,
    phoneNumber: account.phoneNumber || null,
    identityType: createItem(account.userDetails?.identityType),
    identityCard: account.userDetails?.identityCard || null,
    gender: createItem(account.userDetails?.gender),
    race: createItem(account.userDetails?.race),
    birthdate: Utils.createDateFromUTC(account.userDetails?.birthdate),
    birthCountry: createItem(account.userDetails?.birthCountry, 'id', 'name'),
    birthState: createItem(account.userDetails?.birthState, 'id', 'name'),
    birthTownship: createItem(account.userDetails?.birthTownship, 'id', 'name'),
    country: createItem(account.userDetails?.country, 'id', 'name'),
    state: createItem(account.userDetails?.state, 'id', 'name'),
    township: createItem(account.userDetails?.township, 'id', 'name'),
    foundAboutUs: createItem(account.userDetails?.foundAboutUs),
    specialNeeds: account.userDetails?.specialNeeds
      ? account.userDetails?.specialNeeds.map((e: any) => createItem(e))
      : []
  };

  return user;
}
