import { API } from 'api';
import { PayingModal } from 'components';
import { i18n } from 'dawere-commons';
import {
  AutoComplete,
  Button,
  Callout,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Utils
} from 'dawere-uic';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import ErrorModal from './ErrorModal';

export function TransferGateway({
  total = 0,
  currency = null,
  installments = [],
  onSuccess = () => {},
  onError = () => {},
  redirectTo = '',
  isBillingInfoCompleted = () => true,
  banks = [],
  walletAmount = null
}: any) {
  const [bank, setBank] = useState<any>(null);
  const [openRedirectModal, setOpenRedirectModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [paying, setPaying] = useState(false);
  const [asyncId, setAsyncId] = useState<string | null>(null);

  const handleSubmit = useCallback(
    (data: any) => {
      setPaying(true);

      const payload = {
        bank: {
          id: bank?.id
        },
        date: Utils.formatDate(data.date, 'yyyy-mm-dd', false),
        installments,
        reference: data.reference,
        payer: data.payer,
        walletAmount
      };

      API.Cart.createTransfer(payload)
        .then(({ asyncProcessId }: any) => {
          if (Utils.isString(asyncProcessId)) {
            setAsyncId(asyncProcessId);
          } else {
            setPaying(false);
            onSuccess('transferencia');
            setOpenRedirectModal(true);
          }
        })
        .catch(() => {
          setPaying(false);
          onError();
          setOpenErrorModal(true);
        });
    },
    [bank?.id, installments, onError, onSuccess, walletAmount]
  );

  return (
    <>
      <h4>{i18n('v2.pages.buyCoupons.steps.2.transfer.title')}</h4>
      <p>{i18n('v2.pages.buyCoupons.steps.2.transfer.description')}</p>

      <p>{i18n('v2.pages.buyCoupons.steps.2.transfer.step1')}</p>

      <AutoComplete
        name='bank'
        options={banks}
        onChange={(bank) => setBank(bank)}
      />

      {bank ? (
        <div className='mt-4'>
          <p>{i18n('v2.pages.buyCoupons.steps.2.transfer.step2')}</p>

          <Callout className='mb-4'>
            <Row align='center'>
              <Col xs={12} md={4}>
                <img
                  src={bank?.logo}
                  alt={bank?.name}
                  className='img-fluid | mb-4 mb-md-0'
                />
              </Col>
              <Col>
                {bank?.coordinates?.map((coordinate: any) => (
                  <p key={coordinate.id}>
                    <span>{coordinate.key}:</span> <br />
                    <strong>{coordinate.value}</strong>
                  </p>
                ))}
              </Col>
            </Row>
          </Callout>

          <p> {i18n('v2.pages.buyCoupons.steps.2.transfer.step3')}</p>

          <p className='mb-4'>
            {i18n('v2.pages.buyCoupons.steps.2.transfer.step3Note')}
          </p>

          <Form onSubmit={handleSubmit}>
            <DatePicker
              name='date'
              label={i18n('v2.pages.buyCoupons.steps.2.transfer.form.date')}
              validators={{ required: true }}
            />

            <Input
              name='reference'
              label={i18n(
                'v2.pages.buyCoupons.steps.2.transfer.form.reference'
              )}
              validators={{ required: true }}
            />

            <Input
              name='payer'
              label={i18n('v2.pages.buyCoupons.steps.2.transfer.form.payer')}
              validators={{ required: true }}
              preventPaste
            />

            <Input
              name='amount'
              label={i18n('v2.pages.buyCoupons.steps.2.transfer.form.amount')}
              value={`${currency?.symbol}${Utils.toCurrency(total || 0)}`}
              readOnly
            />

            <Button type='submit' block disabled={!isBillingInfoCompleted()}>
              {i18n('v2.pages.buyCoupons.steps.2.transfer.form.button')}
            </Button>
          </Form>
        </div>
      ) : null}

      <Modal
        type='success'
        open={openRedirectModal}
        backdrop='static'
        onClose={setOpenRedirectModal}
        closable={false}
        keyboard={false}
        title={i18n('v2.pages.buyCoupons.steps.2.transfer.modal.title')}
        footer={
          <Button block as={Link} to={redirectTo}>
            {i18n('v2.pages.buyCoupons.steps.2.transfer.modal.button')}
          </Button>
        }>
        <p
          dangerouslySetInnerHTML={{
            __html: i18n(
              'v2.pages.buyCoupons.steps.2.transfer.modal.description'
            )
          }}
        />
      </Modal>

      <ErrorModal
        open={openErrorModal}
        onClose={setOpenErrorModal}
        redirectTo={redirectTo}
      />

      <PayingModal
        paying={paying}
        onClose={setPaying}
        asyncId={asyncId}
        onFinished={() => {
          setPaying(false);
          onSuccess('transferencia');
          setOpenRedirectModal(true);
        }}
        onError={() => {
          setPaying(false);
          onError();
          setOpenErrorModal(true);
        }}
      />
    </>
  );
}
