import { PageWrapper } from 'pages';
import { getSEO } from 'utils';
import { CatalogProvider } from './CatalogProvider';
import { PrimarySection } from './sections/PrimarySection';
import { QuaternarySection } from './sections/QuaternarySection';
import { SecondarySection } from './sections/SecondarySection';
import { TertiarySection } from './sections/TertiarySection';

/**
 * Path: /programas-educativos
 * Key: CATALOG
 * @returns {React.ReactElement}
 */
export default function CatalogPage() {
  return (
    <PageWrapper>
      {getSEO('v2.pages.catalog.seo')}

      <CatalogProvider>
        {/* Programas educativos estadounidenses */}
        {/* Programas educativos nacionales */}
        <PrimarySection />

        {/* Evaluaciones de convalidación */}
        <SecondarySection />

        {/* Cursos Particulares */}
        <TertiarySection />

        {/* Otros Cursos */}
        <QuaternarySection />
      </CatalogProvider>
    </PageWrapper>
  );
}
