import { i18n, Session } from 'dawere-commons';
import { Button, Callout, Col, Row } from 'dawere-uic';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { ASSETS, QPARAMS } from 'values';

export function ReEnrollmentPanel({
  program,
  period,
  rootRoute,
  studentId
}: any) {
  const [currency, setCurrency] = useState<any>(Session.getDefaultCurrency());

  useEffect(() => {
    setCurrency(Session.getDefaultCurrency());
  }, [program]);

  return (
    <Row>
      <Col>
        <Callout className='text-center border border-platinium bg-white'>
          <img
            src={ASSETS.ILLUSTRATIONS.HI5}
            alt={i18n('v2.pages.studentDashboard.tabs.1.congrats', {
              period: period?.name,
              program: program?.name
            })}
            className='img-fluid w-70 w-md-25 mb-4'
          />

          <h3>
            {i18n('v2.pages.studentDashboard.tabs.1.congrats', {
              period: period?.name,
              program: program?.name
            })}
          </h3>

          <p>
            {i18n('v2.pages.studentDashboard.tabs.1.degree', {
              degreeName: program?.degreeName
            })}
          </p>

          <Button
            className='w-100 w-md-auto mb-4'
            as={Link}
            to={PATHS.get(
              'ENROLL',
              {
                programId: program?.id,
                currencyId: currency?.id
              },
              {
                [QPARAMS.Student]: studentId,
                [QPARAMS.Redirect]: encodeURIComponent(
                  PATHS.get(rootRoute, { studentId }, { [QPARAMS.Tab]: 3 })
                )
              }
            )}>
            {i18n('v2.pages.studentDashboard.tabs.1.enrollNextPeriod')}
          </Button>
        </Callout>
      </Col>
    </Row>
  );
}
