import { Rest } from 'dawere-commons';

function contact(data: any) {
  return Rest.post('public/help-desk', data, {
    recaptchaAction: 'helpDesk'
  });
}

export const Help = {
  contact
};
