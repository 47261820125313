import { i18n } from 'dawere-commons';
import { Tabs } from 'dawere-uic';
import { DocumentsTab } from './DocumentsTab';
import { HistoricalTab } from './HistoricalTab';
import { ScorecardsTab } from './ScorecardsTab';
import { SubjectsTab } from './SubjectsTab';

export function EnrollmentAccordion({ enrollment }: any) {
  const { subjects } = enrollment;

  // DEBT -> PENDIENTE DE PAGO
  // ACTIVE -> CURSANDO
  // GRADUATED -> GRADUADO
  // DEPRECATED -> DEPRECADO
  // CONCLUDED -> REINSCRIBIR

  return (
    <Tabs>
      <Tabs.List scrollable className='mb-4'>
        {subjects.length ? (
          <Tabs.Tab>{i18n('v2.pages.studentDetails.tabs.1.title')}</Tabs.Tab>
        ) : null}
        <Tabs.Tab>{i18n('v2.pages.studentDetails.tabs.2.title')}</Tabs.Tab>
        <Tabs.Tab>{i18n('v2.pages.studentDetails.tabs.3.title')}</Tabs.Tab>
        <Tabs.Tab>{i18n('v2.pages.studentDashboard.tabs.6.title')}</Tabs.Tab>
      </Tabs.List>

      {/* Materias */}
      {subjects.length ? (
        <Tabs.Panel>
          <SubjectsTab enrollment={enrollment} />
        </Tabs.Panel>
      ) : null}

      {/* Documentos */}
      <Tabs.Panel>
        <DocumentsTab enrollment={enrollment} />
      </Tabs.Panel>

      {/* Historial Académico */}
      <Tabs.Panel>
        <HistoricalTab enrollment={enrollment} />
      </Tabs.Panel>

      {/* Boletines */}
      <Tabs.Panel>
        <ScorecardsTab enrollment={enrollment} />
      </Tabs.Panel>
    </Tabs>
  );
}
