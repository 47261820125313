import { Block, breakpoints, Carousel, Col, useMatchMedia } from 'dawere-uic';
import { useMemo } from 'react';
import { QuaternaryCard } from './QuaternaryCard';

export function QuaternarySlider({ entry }: any) {
  const { isMobile } = useMatchMedia();

  const items = useMemo(
    () =>
      entry?.subcategories?.map((category: any) => (
        <QuaternaryCard key={category.id} {...category} />
      )),
    [entry?.subcategories]
  );

  return (
    <Block narrow>
      <Col>
        <h2 className='mb-4'>{entry?.title}</h2>

        <p className='mb-3'>{entry?.description}</p>

        <Carousel
          disableButtonsControls={isMobile}
          mouseTracking
          items={items}
          resizeTimeout={0}
          responsive={{
            0: {
              items: 1
            },
            [breakpoints.md]: {
              items: 3
            }
          }}
        />
      </Col>
    </Block>
  );
}
