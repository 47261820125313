import { API } from 'api';
import { BillingInfoForm } from 'components';
import { Utils } from 'dawere-uic';
import { useGeo } from 'hooks';
import { useCallback, useState } from 'react';
import { useEnroll } from '../hooks/useEnroll';

export function BillingForm() {
  const {
    isLoadingSummary,
    setBillingInfo,
    summary,
    setSummary,
    billingInfo,
    isBillingInfoCompleted,
    isEarlyPayment,
    getTaxData
  } = useEnroll();
  const picklists = useGeo(billingInfo?.country, billingInfo?.state);

  const [isEditing, setIsEditing] = useState(false);
  const [original, setOriginal] = useState(Utils.copy(billingInfo));

  const handleSubmit = useCallback(
    (data: any) => {
      setBillingInfo(data);
      setIsEditing(false);

      if (summary?.id) {
        // Lista de descuentos previos aplicados
        const discounts = summary?.discounts.map((d: any) => d.coupon);

        API.Enrollment.updateOrder(summary?.id, {
          discountCodes: discounts,
          earlyPayment: isEarlyPayment,
          taxData: getTaxData(data)
        }).then((response: any) => {
          setSummary(response);
        });
      }
    },
    [
      getTaxData,
      isEarlyPayment,
      setBillingInfo,
      setSummary,
      summary?.discounts,
      summary?.id
    ]
  );

  const handleStartEdit = useCallback(
    (data: any) => {
      setIsEditing(true);
      setOriginal(Utils.copy(billingInfo));
    },
    [billingInfo]
  );

  const handleCancel = useCallback(
    (data: any) => {
      setBillingInfo(original);
      setIsEditing(false);
    },
    [original, setBillingInfo]
  );

  return (
    <BillingInfoForm
      loading={isLoadingSummary}
      data={billingInfo}
      editing={isEditing}
      onEditing={handleStartEdit}
      picklists={picklists}
      showAlert={!isBillingInfoCompleted()}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onChange={setBillingInfo}
    />
  );
}
