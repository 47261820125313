import { GoogleButton } from 'components';
import { i18n } from 'dawere-commons';
import { Button, Divider, Form, Input, useRouter } from 'dawere-uic';
import { usePath, useTokenValidation } from 'hooks';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { QPARAMS } from 'values';

export function StudentSignupForm({
  onSubmit = (data: any) => {},
  onGoogleAuth = () => {},
  isLoading = false
}: any) {
  const { query } = useRouter();
  const { decoded, token } = useTokenValidation();
  const { getPathWithRedirect } = usePath();

  const handleSubmit = useCallback(
    (data: any) => {
      onSubmit({ defaultDashboard: 'STUDENT', ...data, token });
    },
    [token, onSubmit]
  );

  const handleGoogleSubmit = useCallback(
    (gToken: string) => {
      onGoogleAuth(gToken, 'STUDENT', token);
    },
    [token, onGoogleAuth]
  );

  return (
    <Form onSubmit={handleSubmit}>
      <GoogleButton
        onAuth={handleGoogleSubmit}
        loading={isLoading}
        text={i18n('v2.pages.register.buttons.googleLogIn')}
      />

      <Divider>{i18n('ui.commons.or')}</Divider>

      <Input
        name='name'
        label={i18n('v2.pages.register.tabs.1.form.name')}
        validators={{
          required: true,
          pattern: {
            value: /^[a-z\sáéíóúÁÉÍÓÚñÑçÇ]*$/gi,
            message: i18n('v2.pages.register.form.errors.name')
          }
        }}
        loading={isLoading}
      />

      <Input
        name='lastName'
        label={i18n('v2.pages.register.tabs.1.form.lastName')}
        validators={{
          required: true,
          pattern: {
            value: /^[a-z\sáéíóúÁÉÍÓÚñÑçÇ]*$/gi,
            message: i18n('v2.pages.register.form.errors.lastname')
          }
        }}
        loading={isLoading}
      />

      <Input
        name='email'
        label={i18n('v2.pages.register.tabs.1.form.email')}
        type='email'
        validators={{
          required: true,
          email: true
        }}
        loading={isLoading}
        value={decoded?.userEmail || query[QPARAMS.Email]}
        readOnly={!!decoded?.userEmail}
      />

      <Input
        strength
        name='password'
        label={i18n('v2.pages.register.tabs.1.form.password')}
        type='password'
        helpText={i18n('v2.pages.register.helps.password')}
        validators={{
          required: true,
          minLength: 8
        }}
        loading={isLoading}
      />

      <Button type='submit' className='mt-2' block loading={isLoading}>
        {i18n('v2.pages.register.buttons.create')}
      </Button>

      <p className='mt-4 text-center'>
        {i18n('v2.pages.register.withAccount')}{' '}
        <Link to={getPathWithRedirect('LOGIN')}>
          {i18n('v2.pages.register.links.login')}
        </Link>
      </p>
    </Form>
  );
}
