import { PageWrapper } from 'pages';
import { getSEO } from 'utils';
import { AlliesSection } from './sections/AlliesSection';
import { BenefitsSection } from './sections/BenefitsSection';
import { CatalogSection } from './sections/CatalogSection';
import { CertificationsSection } from './sections/CertificationsSection';
import { ContactSection } from './sections/ContactSection';
import { DegreeSection } from './sections/DegreeSection';
import { DownloadSection } from './sections/DownloadSection';
import { EnrollSection } from './sections/EnrollSection';
import { HowWorksSection } from './sections/HowWorksSection';
import { StartSection } from './sections/StartSection';
import { TestimonialsSection } from './sections/Testimonials';
import { WelcomeSection } from './sections/WelcomeSection';

/**
 * Path: /{:lang}/
 * Key: HOME
 * @returns {React.ReactElement}
 */
export default function HomePage() {
  return (
    <PageWrapper>
      {getSEO('v2.pages.home.seo')}
      {/* Ahora sí puedes graduarte de bachillerato */}
      <WelcomeSection />
      {/* El título de bachillerato es avalado por el MPPE */}
      <DegreeSection />
      {/* Nuestros estudiantes dicen */}
      <TestimonialsSection />
      {/* Reviews */}
      {/* <GoogleReviews /> */}
      {/* Inscríbete y comienza tu periodo de prueba gratis */}
      <EnrollSection />
      {/* Download app */}
      <DownloadSection />
      {/* Cognia (only USA) */}
      <CertificationsSection />
      {/* Beneficios de estudiar el bachillerato con Dawere */}
      <BenefitsSection />
      {/* ¿Cómo funciona Dawere? */}
      <HowWorksSection />
      {/* Aliados y clientes de Dawere */}
      <AlliesSection />
      {/* Monitorea el avance académico de tus hijos;
          Gestiona tu proyecto educativo;
          Estás más cerca de ser bachiller */}
      <StartSection />
      {/* Contáctanos */}
      <ContactSection />
      {/* Explora todos nuestros cursos */}
      <CatalogSection />
      {/* Resultados de la encuesta de satisfacción de estudiantes 2020 */}
      {/* <SurveysSection /> */}
      {/* Obtén la doble titulación (only not USA) */}
      {/* <DoubleDegreeSection /> */}
    </PageWrapper>
  );
}
