import { i18n, Locales } from 'dawere-commons';

declare const window: any;

export const MULTILANGUAGE_COUNTRIES: string[] = ['USA'];

window.dawereCountries = (window.dawereCountries || [])
  .filter(({ active }: any) => active)
  .map((country: any) => {
    country.nameFrom = `ui.commons.countries.${country.iso3.toLowerCase()}`;
    country.multilanguage = MULTILANGUAGE_COUNTRIES.includes(country.iso3);
    return country;
  });

export const COUNTRIES_LIST: any[] = window.dawereCountries;

export const COUNTRIES_LOOKUP: any = COUNTRIES_LIST.reduce(
  (acc: any, country: any) => {
    acc[country.iso2] = country;
    return acc;
  },
  {}
);

export const getFullCountryInfo = (): any => {
  const def = {
    iso2: 'us',
    iso3: 'USA',
    dialCode: '+1',
    nameFrom: 'ui.commons.countries.usa',
    active: true,
    name: i18n('ui.commons.countries.usa'),
    lang: 'es-US,es',
    google_tag_id: 'GTM-WRK4WF',
    site_verification: '7avcGz_wP4bpr0EwVGQfrYCFrGtxrU9fd_pj1BhzCSU',
    multilanguage: true
  };

  const key = Locales.getCountry();
  const found = COUNTRIES_LOOKUP[key];

  if (found) {
    found.name = i18n(found.nameFrom);
  }

  return found || def;
};

const joined = COUNTRIES_LIST.map(({ iso2 }: any) => iso2)
  .join('|')
  .toLowerCase();

export const COUNTRIES_REGEXP = `(${joined})`;

export const COUNTRIES_ISO2 = COUNTRIES_LIST.map(({ iso2 }: any) => iso2);
