import { PaymentBar } from 'components';
import { useEnroll } from '../hooks/useEnroll';
import { SummaryCard } from './SummaryCard';

export function SummarySidebar() {
  const { step } = useEnroll();

  return step === 'checkout' ? (
    <PaymentBar>
      <SummaryCard />
    </PaymentBar>
  ) : null;
}
