import { useMutation } from '@tanstack/react-query';
import { API } from 'api';
import { Session, i18n } from 'dawere-commons';
import {
  Button,
  Callout,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  toast,
  useRouter
} from 'dawere-uic';
import { useSession } from 'hooks';
import { useState } from 'react';
import { PATHS } from 'router';

export function DropOutSection() {
  const { setUser } = useSession();
  const { push } = useRouter();
  const [open, setOpen] = useState(false);
  const [phrase, setPhrase] = useState<null | undefined | string>();

  const { mutate: deleteAccount, isLoading } = useMutation({
    mutationFn: () => API.Account.deleteAccount(),
    onSuccess: () => {
      setOpen(false);
      toast.success(i18n('v2.pages.settings.tabs.1.deleteAccount.toast'));
      // Logout
      Session.clean();
      setUser(null);
      push(PATHS.HOME);
    }
  });

  return (
    <>
      <Divider section>
        {i18n('v2.pages.settings.tabs.1.deleteAccount.title')}
      </Divider>

      <Row>
        <Col xs={12} md={6}>
          <p>{i18n('v2.pages.settings.tabs.1.deleteAccount.shortDesc')}</p>
          <Button onClick={() => setOpen(true)}>
            {i18n('v2.pages.settings.tabs.1.deleteAccount.title')}
          </Button>
        </Col>
      </Row>

      <Modal
        open={open}
        onClose={setOpen}
        type='warning'
        title={i18n('v2.pages.settings.tabs.1.deleteAccount.title')}
        backdrop='static'
        closable={false}
        footer={
          <>
            <Button
              block
              variant='outline'
              onClick={() => setOpen(false)}
              loading={isLoading}>
              {i18n('ui.commons.cancel')}
            </Button>
            <Button
              block
              onClick={deleteAccount}
              disabled={phrase !== 'BORRAR'}
              loading={isLoading}>
              {i18n('v2.pages.settings.tabs.1.deleteAccount.title')}
            </Button>
          </>
        }>
        <Callout variant='powderTint' className='mb-4'>
          <p
            dangerouslySetInnerHTML={{
              __html: i18n('v2.pages.settings.tabs.1.deleteAccount.longDesc1')
            }}
          />
        </Callout>

        <p
          dangerouslySetInnerHTML={{
            __html: i18n('v2.pages.settings.tabs.1.deleteAccount.longDesc2')
          }}
        />

        <Input
          name='confirm'
          placeholder='BORRAR'
          className='mb-4'
          loading={isLoading}
          onChange={(e) => setPhrase(e.target.value)}
        />
      </Modal>
    </>
  );
}
