import { Rest, i18n } from 'dawere-commons';
import { Badge, Skeleton, SubjectCard } from 'dawere-uic';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import styled from 'styled-components';
import { trackEvent } from 'utils';

const QuaternaryCardStyled = styled.div`
  margin: var(--dw-spacer-1);
  width: 100%;

  a {
    color: var(--dw-color-text);

    > div {
      border: solid 2px var(--dw-color-lightGray);
    }

    &:hover {
      color: var(--dw-color-text);
      text-decoration: none;

      > div {
        border: solid 2px var(--dw-color-primary);
        transform: scale(1.01);
      }
    }
  }
`;

export function QuaternaryCard({
  title,
  description,
  thumbnail,
  friendlyPath,
  url
}: any) {
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (url && !data) {
      setIsLoading(true);

      Rest.get(url)
        .then(({ data }) => {
          setData(data);
        })
        .finally(() => setIsLoading(false));
    }
  }, [data, url]);

  const handleClick = useCallback(() => {
    trackEvent('program_clicks', { program_name: title });
  }, [title]);

  return (
    <div className='p-2 h-100'>
      <QuaternaryCardStyled>
        <Link
          to={PATHS.get('COURSE', { programId: friendlyPath })}
          onClick={handleClick}>
          <SubjectCard
            className='bezier'
            title={title}
            thumbnail={thumbnail}
            description={description}
            hideProgress
            hideGrades
            footer={
              <div className='border-top border-platinium pt-3'>
                <div className='mb-3'>
                  <small className='d-block text-spanish'>
                    {i18n('v2.pages.catalog.primary.description')}
                  </small>
                  {isLoading ? <Skeleton /> : data?.description || '-'}
                </div>

                <div>
                  <small className='d-block text-muted'>
                    {i18n('v2.pages.catalog.primary.duration')}
                  </small>
                  {isLoading ? <Skeleton /> : data?.duration || '-'}
                </div>

                {data?.freeTrial && (
                  <Badge small pilled variant='tertiary' className='mt-3'>
                    {i18n('v2.pages.home.section5.buttons.enroll')}
                  </Badge>
                )}
              </div>
            }
          />
        </Link>
      </QuaternaryCardStyled>
    </div>
  );
}
