import { i18n } from 'dawere-commons';
import { Button, useMatchMedia } from 'dawere-uic';
import { ModalAddMember } from 'pages/private/dashboards/organizations/components/ModalAddMember';
import { useCallback, useState } from 'react';
import ReactDOM from 'react-dom';

export function InviteButton({ organizationId, onSuccess = () => {} }: any) {
  const { isMobile } = useMatchMedia();
  const [isOpen, setIsOpen] = useState(false);

  let el: HTMLElement | null = document.getElementById('invite-member-portal');

  const handleOpen = useCallback(() => setIsOpen(true), []);

  if (el) {
    return ReactDOM.createPortal(
      <>
        <Button
          className='mr-2 | mb-2 mb-md-0'
          block={isMobile}
          onClick={handleOpen}>
          {i18n('v2.pages.members.buttons.add')}
        </Button>

        <ModalAddMember
          organizationId={organizationId}
          open={isOpen}
          onClose={setIsOpen}
          onSuccess={onSuccess}
        />
      </>,
      el
    );
  }

  return null;
}
