import { useLayout } from 'hooks';
import React, { useEffect } from 'react';

/**
 * Las propiedades en esta interfaz deben corresponder con las que se desean
 * cambiar en el contexto global del "LayoutContext.Provider".
 */
interface PageWrapperProps {
  children?: React.ReactNode;
  disableCountryPicker?: boolean;
}

export function PageWrapper({
  children,
  disableCountryPicker = false
}: PageWrapperProps) {
  const { setDisableCountryPicker } = useLayout();

  // De esta manera cada vez que se active o cambie una propiedad,
  // el contexto global se actualiza.
  useEffect(() => {
    setDisableCountryPicker(disableCountryPicker);
  }, [disableCountryPicker, setDisableCountryPicker]);

  return <>{children}</>;
}
