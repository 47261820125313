import { API } from 'api';
import { i18n } from 'dawere-commons';
import {
  AvatarChip,
  Button,
  Col,
  Dropdown,
  Flex,
  Icons,
  Pagination,
  Row,
  Search,
  Skeleton,
  Table,
  useRouter,
  Utils,
  Visible
} from 'dawere-uic';
import { useOrganization } from 'hooks';
import { SearchKeyword } from 'pages/private/organizationCharts/components/SearchKeyword';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PATHS } from 'router';
import { QPARAMS } from 'values';

export function StudentsRemovedTab() {
  const { push } = useRouter();
  const { organization } = useOrganization();

  const [data, setData] = useState<any[]>([]);
  const [isLoadingPrograms, setIsLoadingPrograms] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [sorting, setSorting] = useState('');
  const [keyword, setKeyword] = useState('');
  const [filters, setFilters] = useState([]);
  const [activeProgram, setActiveProgram] = useState<any>(null);
  const [activePeriod, setActivePeriod] = useState<any>(null);

  const desktopColumns = useMemo(
    () => [
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.2.full.tabs.3.table.columns.name'
        ),
        accessor: 'id',
        Cell: ({ value, row }: any) => {
          return (
            <AvatarChip
              align={row.subRows.length > 1 ? 'start' : 'center'}
              size={35}
              src={row.subRows[0]?.original?.avatar}
              gap={2}>
              <div>{row.subRows[0]?.original?.name}</div>
              <small
                className='d-block text-muted'
                style={{ marginTop: '-2px' }}>
                {row.subRows[0]?.original?.email}
              </small>
            </AvatarChip>
          );
        }
      },
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.2.full.tabs.3.table.columns.program'
        ),
        accessor: 'program',
        disableSortBy: true,
        aggregate: (leafValues: any[], aggregatedValues: any[]) => {
          return aggregatedValues.map((v, idx: number) => (
            <div key={idx} className='aggregated'>
              {v}
            </div>
          ));
        }
      },
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.2.full.tabs.3.table.columns.period'
        ),
        accessor: 'period',
        disableSortBy: true,
        aggregate: (leafValues: any[], aggregatedValues: any[]) => {
          return aggregatedValues.map((v, idx: number) => (
            <div key={idx} className='aggregated'>
              {v}
            </div>
          ));
        }
      },
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.2.full.tabs.3.table.columns.date'
        ),
        accessor: 'removedDate',
        disableSortBy: true,
        aggregate: (leafValues: any[], aggregatedValues: any[]) => {
          return aggregatedValues.map((v, idx: number) => (
            <div key={idx} className='aggregated'>
              {v}
            </div>
          ));
        }
      },
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.2.full.tabs.3.table.columns.status'
        ),
        accessor: 'status',
        disableSortBy: true,
        aggregate: (leafValues: any[], aggregatedValues: any[]) => {
          return aggregatedValues.map((v, idx: number) => (
            <div key={idx} className='aggregated'>
              {i18n(`v2.pages.organizationDashboard.tabs.2.full.tabs.3.${v}`)}
            </div>
          ));
        }
      }
    ],
    []
  );

  const mobileColumns = useMemo(
    () => [
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.2.full.tabs.3.table.columns.student'
        ),
        accessor: 'id',
        Cell: ({ value, row }: any) => {
          return (
            <div>
              <strong className='mb-0 d-block fs-1'>
                {row.original?.name}
              </strong>
              <small
                className='d-block text-muted mb-2'
                style={{ marginTop: '-2px' }}>
                {row.original?.email}
              </small>
              <div>{row.original?.program}</div>
              <div>{row.original?.period}</div>
              <div>{row.original?.removedDate}</div>
              <div>
                {i18n(
                  `v2.pages.organizationDashboard.tabs.2.full.tabs.3.${row.original?.status}`
                )}
              </div>
            </div>
          );
        }
      }
    ],
    []
  );

  // Reload data
  useEffect(() => {
    if (!activeProgram?.id) {
      return;
    }

    setIsLoading(true);

    API.Organizations.getStudents(
      organization.id,
      {
        page: pageIndex,
        size: pageSize,
        sort: sorting,
        keyword: keyword,
        program_id: activeProgram?.id,
        period: activePeriod?.name
      },
      'REMOVED'
    )
      .then((response: any) => {
        setData(response.data);
        setPageCount(response.totalPages);
        setTotal(response.totalRows);
      })
      .finally(() => setIsLoading(false));
  }, [
    organization.id,
    pageIndex,
    pageSize,
    sorting,
    keyword,
    activeProgram?.id,
    activePeriod?.name
  ]);

  // Get filters
  useEffect(() => {
    setIsLoadingPrograms(true);
    API.Organizations.getPrograms(organization.id, 'REMOVED')
      .then((response: any) => {
        setFilters(response);
        if (response.length) {
          setActiveProgram(response[0]);
        }
      })
      .finally(() => setIsLoadingPrograms(false));
  }, [organization.id]);

  const handlePaging = useCallback(({ pageSize, pageIndex }: any) => {
    setPageSize(pageSize);
    setPageIndex(pageIndex);
  }, []);

  const handleSorting = useCallback((data: any) => {
    const [sort] = data;
    if (sort) {
      const description = `student.name,${sort.desc ? 'desc' : 'asc'}`;
      setSorting(description);
    } else {
      setSorting('');
    }
  }, []);

  const handleSearch = useCallback((data: any) => {
    setKeyword(() => {
      setPageIndex(0);
      return data.search;
    });
  }, []);

  const handleDownload = useCallback(() => {
    setIsDownloading(true);

    API.Organizations.getStudents(
      organization.id,
      {
        keyword: keyword,
        program_id: activeProgram?.id,
        period: activePeriod?.name
      },
      'REMOVED',
      true
    ).finally(() => setIsDownloading(false));
  }, [activePeriod?.name, activeProgram?.id, keyword, organization.id]);

  const handleSelectProgram = useCallback((program: any) => {
    setActiveProgram(program);
    setActivePeriod(null);
  }, []);

  const handleSelectPeriod = useCallback(
    (period: any) => {
      setActivePeriod(period.id === activePeriod?.id ? null : period);
    },
    [activePeriod?.id]
  );

  const handleRowClick = useCallback(
    (row: any) => {
      const [first] = Utils.isArray(row) ? row : [row];
      const path = PATHS.get(
        'STUDENT_VIEW',
        { studentId: first.id },
        {
          [QPARAMS.Organization]: `id-${organization?.id}_name-${organization?.name}`
        }
      );
      push(path);
    },
    [organization?.id, organization?.name, push]
  );

  return (
    <>
      <SearchKeyword keyword={keyword} onSearch={handleSearch} />

      <Visible xs sm>
        <Search
          name='search'
          allowEmptySearch
          value={keyword}
          onSubmit={handleSearch}
          disabled={isLoading || isLoadingPrograms}
        />
      </Visible>

      <Flex className='mb-2' align='center' justify='between'>
        <div>
          {filters?.length ? (
            <Dropdown
              className='mr-1'
              disabled={isLoading || isLoadingPrograms}
              autoWidth
              style={{ maxWidth: '400px' }}
              toggle={
                <Button
                  variant='filter'
                  caret
                  loading={isLoading || isLoadingPrograms}>
                  {activeProgram?.name ??
                    i18n(
                      'v2.pages.organizationDashboard.tabs.2.full.tabs.1.buttons.program'
                    )}
                </Button>
              }>
              {filters?.map((item: any) => (
                <Dropdown.Item
                  key={item.id}
                  active={activeProgram?.id === item.id}
                  onClick={() => handleSelectProgram(item)}>
                  {item.name}
                </Dropdown.Item>
              ))}
            </Dropdown>
          ) : null}

          {activeProgram && activeProgram?.periods?.length ? (
            <Dropdown
              className='mr-1'
              disabled={isLoading || isLoadingPrograms}
              style={{ maxWidth: '220px' }}
              toggle={
                <Button
                  variant='filter'
                  caret
                  loading={isLoading || isLoadingPrograms}
                  disabled={!activeProgram}>
                  {activePeriod?.name ??
                    i18n(
                      'v2.pages.organizationDashboard.tabs.2.full.tabs.1.buttons.period'
                    )}
                </Button>
              }>
              {activeProgram?.periods?.map((item: any) => (
                <Dropdown.Item
                  key={item.id}
                  active={activePeriod?.id === item.id}
                  onClick={() => handleSelectPeriod(item)}>
                  {item.name}
                </Dropdown.Item>
              ))}
            </Dropdown>
          ) : null}
        </div>

        <Visible md lg xl>
          <div>
            <Button
              variant='clear'
              className='ml-2'
              disabled={isLoading || isLoadingPrograms}
              loading={isDownloading}
              onClick={handleDownload}
              icon={<Icons.Download />}>
              {i18n(
                'v2.pages.organizationDashboard.tabs.2.full.tabs.3.buttons.download'
              )}
            </Button>
          </div>
        </Visible>
      </Flex>

      <Visible xs sm>
        <Table
          data={data}
          columns={mobileColumns}
          loading={isLoading || isLoadingPrograms}
          pageable={false}
          manualSorting
          onSorting={handleSorting}
          clickable
          onRowClick={handleRowClick}
        />
      </Visible>

      <Visible md lg xl>
        <Table
          data={data}
          groupByField='id'
          columns={desktopColumns}
          loading={isLoading || isLoadingPrograms}
          pageable={false}
          manualSorting
          onSorting={handleSorting}
          clickable
          onRowClick={handleRowClick}
        />
      </Visible>

      <Row>
        <Visible lg xl>
          <Col xs={3} />
        </Visible>

        <Col>
          <Pagination
            align='center'
            className='my-4'
            onUpdate={handlePaging}
            pageIndex={pageIndex}
            pageCount={pageCount}
            disabled={isLoading || isLoadingPrograms}
          />
        </Col>

        <Visible md lg xl>
          <Col xs={3} className='d-flex align-items-center justify-content-end'>
            {isLoading || isLoadingPrograms ? (
              <Skeleton width={120} wrapper='small' />
            ) : (
              <small>
                <strong>{total}</strong>{' '}
                {i18n(
                  'v2.pages.organizationDashboard.tabs.2.full.tabs.3.table.count'
                )}
              </small>
            )}
          </Col>
        </Visible>
      </Row>
    </>
  );
}
