import { i18n } from 'dawere-commons';
import { Button, ButtonGroup, Modal, Utils } from 'dawere-uic';
import { useApp } from 'hooks';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { Referrals } from 'utils';

export function ReferralsModal() {
  const { referral, setReferral } = useApp();

  useEffect(() => {
    Referrals.clean();
  }, []);

  return (
    <Modal
      title={i18n('v2.inviteFriends.successModal.title')}
      open={!!referral}
      onClose={() => setReferral(null)}>
      <p
        dangerouslySetInnerHTML={{
          __html: i18n('v2.inviteFriends.successModal.text1', {
            name: Utils.getFullname(
              referral?.referralUser?.name,
              referral?.referralUser?.lastName
            )
          })
        }}
      />
      <p
        dangerouslySetInnerHTML={{
          __html: i18n('v2.inviteFriends.successModal.text2')
        }}
      />

      <ButtonGroup.Confirm>
        <Button
          variant='outline'
          to={PATHS.CATALOG}
          as={Link}
          onClick={() => setReferral(null)}>
          {i18n('v2.inviteFriends.successModal.button1')}
        </Button>
        <Button to={PATHS.WALLET} as={Link} onClick={() => setReferral(null)}>
          {i18n('v2.inviteFriends.successModal.button2')}
        </Button>
      </ButtonGroup.Confirm>
    </Modal>
  );
}
