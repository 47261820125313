import { API } from 'api';
import { i18n, Session } from 'dawere-commons';
import {
  Block,
  Button,
  Col,
  Flex,
  Header,
  Row,
  Skeleton,
  toast,
  useRouter,
  Utils,
  Visible
} from 'dawere-uic';
import { useSetDefaultDashboard } from 'hooks';
import { PageWrapper } from 'pages';
import { useCallback, useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { PATHS } from 'router';
import { getLoginRedirectPath, getSEO } from 'utils';
import { ASSETS } from 'values';
import { InvitationCard } from './components/InvitationCard';
import { ModalAddStudent } from './components/ModalAddStudent';
import { StudentCard } from './components/StudentCard';

/**
 * Path: /panel/mentor/
 * Key: TUTORS_DASHBOARD
 * @returns {React.ReactElement}
 */
export default function TutorsDashboardPage() {
  const { push } = useRouter();
  useSetDefaultDashboard('TUTOR');

  const account = Session.getAccountInfo();
  const profiles = Session.getProfiles();
  const isProfileCompleted = Session.isProfileCompleted(true);

  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [invitations, setInvitations] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const load = useCallback(() => {
    setIsLoading(true);

    API.Tutors.getStudents({
      size: 999
    })
      .then((response) => {
        setData(response.data);
        return API.Tutors.getStudentInvitations();
      })
      .then((response) => {
        setInvitations(response.data);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const handleOpen = useCallback(() => setIsOpen(true), []);

  const handleClick = useCallback(
    (student: any) => {
      if (!isProfileCompleted) {
        toast.warning(i18n('v2.pages.profile.toasts.parentIncomplete'));
        return;
      }

      push(
        PATHS.get('TUTORS_DASHBOARD_STUDENT_VIEW', { studentId: student.id })
      );
    },
    [isProfileCompleted, push]
  );

  useEffect(() => {
    load();
  }, [load]);

  if (!profiles?.tutor) {
    const path = getLoginRedirectPath();
    return <Redirect to={path} />;
  }

  return (
    <PageWrapper>
      {getSEO('v2.pages.tutorDashboard.seo', true)}

      <Block narrow>
        {/* Titulo y avatar */}
        <Col xs={12}>
          <Header
            src={account?.picturePath}
            title={i18n('v2.pages.tutorDashboard.welcome', {
              name: Utils.getFirstPart(account?.name)
            })}
            subtext={[i18n('v2.pages.tutorDashboard.title')]}
          />
        </Col>

        {/* Kids */}
        <Col>
          {data.length || invitations.length ? (
            <>
              <Visible xs sm>
                <Button
                  className='mb-2'
                  block
                  disabled={isLoading}
                  onClick={handleOpen}>
                  {i18n('v2.pages.tutorDashboard.buttons.addStudent')}
                </Button>
              </Visible>

              <Visible md lg xl>
                <Flex align='center' className='mb-4' justify='end'>
                  <Button disabled={isLoading} onClick={handleOpen}>
                    {i18n('v2.pages.tutorDashboard.buttons.addStudent')}
                  </Button>
                </Flex>
              </Visible>
            </>
          ) : null}

          <Row>
            {isLoading ? (
              <>
                {Array(6)
                  .fill(0)
                  .map((_, index) => (
                    <Col md={6} key={index}>
                      <Skeleton height={80} className='mb-2' />
                    </Col>
                  ))}
              </>
            ) : (
              <>
                {data.length || invitations.length ? (
                  <>
                    {data.map((item) => (
                      <Col md={6} key={item.id}>
                        <StudentCard
                          item={item}
                          onClick={handleClick}
                          disabled={!isProfileCompleted}
                        />
                      </Col>
                    ))}

                    {invitations.map((item) => (
                      <Col md={6} key={item.id}>
                        <InvitationCard item={item} onChange={load} />
                      </Col>
                    ))}
                  </>
                ) : (
                  <>
                    <Col md={6}>
                      <img
                        src={ASSETS.ILLUSTRATIONS.STUDENT_BACKPACK}
                        alt={i18n('v2.pages.tutorDashboard.empty.title')}
                        className='mb-5 mb-md-0 | img-fluid'
                      />
                    </Col>

                    <Col md={6} className='order-md-first'>
                      <h3 className='mb-4'>
                        {i18n('v2.pages.tutorDashboard.empty.title')}
                      </h3>

                      <p className='mb-4'>
                        {i18n('v2.pages.tutorDashboard.empty.description')}
                      </p>
                      <Button
                        disabled={isLoading}
                        onClick={handleOpen}
                        className='w-100 w-md-auto'>
                        {i18n('v2.pages.tutorDashboard.buttons.addStudent')}
                      </Button>
                    </Col>
                  </>
                )}
              </>
            )}
          </Row>
        </Col>

        <ModalAddStudent open={isOpen} onClose={setIsOpen} onSuccess={load} />
      </Block>
    </PageWrapper>
  );
}
