import { API } from 'api';
import { i18n } from 'dawere-commons';
import {
  AutoComplete,
  Block,
  Button,
  Callout,
  Col,
  Divider,
  Form,
  Header,
  Input,
  ProfilePic,
  Row,
  toast,
  useRouter
} from 'dawere-uic';
import { useSession } from 'hooks';
import { PageWrapper } from 'pages';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { getSEO } from 'utils';

/**
 * Path: /mis-organizaciones/crear
 * Key: CREATE_ORGANIZATIONS
 * @returns {React.ReactElement}
 */
export default function CreateOrganizationPage() {
  const { push } = useRouter();
  const { getMe } = useSession();

  const [data, setData] = useState<any>({});
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [logoPath, setLogoPath] = useState<any>(null);

  const [picklists, setPicklists] = useState({
    isLoadingCountries: true,
    isLoadingStates: false,
    isLoadingTownships: false,
    countries: [],
    states: [],
    townships: []
  });

  // Countries
  useEffect(() => {
    setPicklists((s: any) => ({ ...s, isLoadingCountries: true }));
    API.Geo.getCountries()
      .then((response) => {
        setPicklists((s: any) => ({ ...s, countries: response }));
      })
      .finally(() =>
        setPicklists((s: any) => ({ ...s, isLoadingCountries: false }))
      );
  }, []);

  // States
  useEffect(() => {
    if (data?.country) {
      setPicklists((s: any) => ({ ...s, isLoadingStates: true }));
      API.Geo.getStates(data.country.value)
        .then((response) => {
          setPicklists((s: any) => ({ ...s, states: response }));
        })
        .finally(() =>
          setPicklists((s: any) => ({ ...s, isLoadingStates: false }))
        );
    }
  }, [data?.country]);

  // Townships
  useEffect(() => {
    if (data?.state) {
      setPicklists((s: any) => ({ ...s, isLoadingTownships: true }));
      API.Geo.getTownships(data?.state.value)
        .then((response) => {
          setPicklists((s: any) => ({ ...s, townships: response }));
        })
        .finally(() =>
          setPicklists((s: any) => ({ ...s, isLoadingTownships: false }))
        );
    }
  }, [data?.state]);

  const handleSubmit = useCallback(
    (body: any) => {
      const payload = {
        billingAddress: body.billingAddress,
        logo: data.logo
          ? {
              content: data.logo.content,
              fileExtension: data.logo.fileExtension
            }
          : null,
        name: body.name,
        postalCode: body.postalCode,
        tin: body.tin,
        townshipId: body.township.value
      };

      setIsLoading(true);

      API.Organizations.create(payload)
        .then(({ data }: any) => {
          toast.success(
            i18n('v2.pages.createOrganization.toasts.successCreated', {
              name: body.name
            })
          );
          push(
            PATHS.get('ORGANIZATIONS_DASHBOARD', { organizationId: data.id })
          );

          return getMe();
        })
        .then(() => {})
        .finally(() => setIsLoading(false));
    },
    [data.logo, getMe, push]
  );

  const handleUpload = useCallback((data: any) => {
    setIsUploading(true);

    setData((s: any) => ({
      ...s,
      logo: {
        content: data.base64,
        fileExtension: data.extension
      }
    }));

    setLogoPath(data.url);

    setIsUploading(false);
  }, []);

  return (
    <PageWrapper>
      {getSEO('v2.pages.createOrganization.seo', true)}

      <Block narrow>
        <Col xs={12}>
          <Header
            withoutAvatar
            title={i18n('v2.pages.createOrganization.title')}
            as={Link}
            routes={[
              {
                breadcrumb: i18n('v2.pages.createOrganization.breadcrumbs.1'),
                to: PATHS.MY_ORGANIZATIONS
              },
              {
                breadcrumb: i18n('v2.pages.createOrganization.breadcrumbs.2')
              }
            ]}
          />
        </Col>

        <Col xs={12} className='mb-6'>
          <Callout className='bg-platinium px-3 pt-3 pb-2 text-center'>
            <ProfilePic
              src={logoPath}
              size={160}
              onChange={handleUpload}
              loading={isUploading}
            />
          </Callout>
        </Col>

        <Col>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <Divider section>
                  {i18n('v2.pages.createOrganization.section1Title')}
                </Divider>
              </Col>

              <Col xs={12} md={6}>
                <Input
                  loading={isLoading}
                  name='name'
                  label={i18n('v2.pages.createOrganization.form.name')}
                  value={data?.name}
                  validators={{
                    required: true,
                    maxLength: 150
                  }}
                />
              </Col>

              <Col xs={12} md={6}>
                <Input
                  loading={isLoading}
                  name='tin'
                  label={i18n('v2.pages.createOrganization.form.tin')}
                  value={data?.tin}
                  validators={{
                    required: true,
                    maxLength: 100
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Divider section>
                  {i18n('v2.pages.createOrganization.section2Title')}
                </Divider>
              </Col>

              <Col xs={12} md={6}>
                <AutoComplete
                  loading={picklists.isLoadingCountries || isLoading}
                  options={picklists.countries}
                  name='country'
                  label={i18n('v2.pages.createOrganization.form.country')}
                  value={data?.country}
                  onChange={(country: any) => {
                    setData({
                      ...data,
                      country,
                      state: null,
                      township: null
                    });
                  }}
                  validators={{
                    required: true
                  }}
                />
              </Col>

              <Col xs={12} md={6}>
                <AutoComplete
                  loading={picklists.isLoadingStates || isLoading}
                  options={picklists.states}
                  name='state'
                  label={i18n('v2.pages.createOrganization.form.state')}
                  value={data?.state}
                  onChange={(state: any) => {
                    setData({
                      ...data,
                      state,
                      township: null
                    });
                  }}
                  disabled={!data?.country}
                  validators={{
                    required: true
                  }}
                />
              </Col>

              <Col xs={12} md={6}>
                <AutoComplete
                  loading={picklists.isLoadingTownships || isLoading}
                  options={picklists.townships}
                  name='township'
                  label={i18n('v2.pages.createOrganization.form.township')}
                  value={data?.township}
                  onChange={(township: any) => {
                    setData({
                      ...data,
                      township
                    });
                  }}
                  disabled={!data?.state}
                  validators={{
                    required: true
                  }}
                />
              </Col>

              <Col xs={12} md={6}>
                <Input
                  loading={isLoading}
                  name='billingAddress'
                  label={i18n(
                    'v2.pages.createOrganization.form.billingAddress'
                  )}
                  value={data?.billingAddress}
                  validators={{
                    required: true,
                    maxLength: 250
                  }}
                />
              </Col>

              <Col xs={12} md={6}>
                <Input
                  loading={isLoading}
                  name='postalCode'
                  label={i18n('v2.pages.createOrganization.form.postalCode')}
                  value={data?.postalCode}
                  validators={{
                    required: true,
                    maxLength: 10
                  }}
                />
              </Col>

              <Col xs={12}>
                <Button type='submit' loading={isLoading}>
                  {i18n('v2.pages.createOrganization.buttons.submit')}
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Block>
    </PageWrapper>
  );
}
