import { Rest } from 'dawere-commons';
import { Utils } from 'dawere-uic';

function createTranscripts(periodId?: string, studentId?: string) {
  const student = studentId ? { student: { id: studentId } } : {};

  return Rest.post('transcripts', {
    period: { id: periodId },
    ...student
  });
}

function enrollFree(orderId: string) {
  return Rest.post(`me/orders/${orderId}/trial`);
}

function enroll(path: string, body: any = {}) {
  return Rest.post(path, body).then(({ data }: any) => {
    if (data?.installments?.length > 0) {
      data.installments[0].checked = true;
    }
    return { data };
  });
}

function createEnroll(
  calendarId: any,
  periodId: any,
  studentId?: string,
  earlyPayment = false
) {
  return enroll(studentId ? `students/${studentId}/enrolments` : 'enrolments', {
    earlyPayment,
    academicCalendar: { id: calendarId },
    ...(Utils.isString(periodId) ? { period: { id: periodId } } : {})
  });
}

function createFlexEnroll(
  programId: any,
  studentId?: string,
  earlyPayment = false
) {
  return enroll(studentId ? `students/${studentId}/enrolments` : 'enrolments', {
    earlyPayment,
    program: { id: programId }
  });
}

function updateOrder(orderId: string, payload: any = {}) {
  const body = Utils.copy(payload);

  if (!body?.taxData?.address) {
    delete body.taxData;
  }

  return Rest.put(`me/orders/${orderId}`, body).then(({ data }: any) => {
    if (data?.installments?.length > 0) {
      data.installments[0].checked = true;
    }
    return data;
  });
}

function getReportCard(enrolmentId: string) {
  return Rest.get(`public/enrolments/${enrolmentId}/report-card`).then(
    ({ data }: any) => {
      return data;
    }
  );
}

function getOrder(orderId: string) {
  return Rest.get(`me/orders/${orderId}`).then(({ data }: any) => {
    if (data?.installments?.length > 0) {
      data.installments[0].checked = true;
    }
    return data;
  });
}

export const Enrollment = {
  getOrder,
  createTranscripts,
  createEnroll,
  createFlexEnroll,
  updateOrder,
  getReportCard,
  enrollFree
};
