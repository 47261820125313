import { i18n } from 'dawere-commons';
import { Button } from 'dawere-uic';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { QPARAMS } from 'values';

export function BuyCouponsButton({
  programId,
  className = 'w-100',
  ...rest
}: any) {
  return (
    <Button
      as={Link}
      to={PATHS.get(
        'BUY_COUPONS',
        {},
        {
          [QPARAMS.Program]: programId
        }
      )}
      className={className}
      {...rest}>
      {i18n('v2.pages.organizationDashboard.tabs.1.empty.buttons.buy')}
    </Button>
  );
}
