import { Avatar, Card, Flex, Utils } from 'dawere-uic';
import styled from 'styled-components';

const CardStyled = styled(Card)`
  overflow: hidden;
  margin-bottom: var(--dw-spacer-3);
  padding: var(--dw-spacer-3);
  cursor: pointer;

  :hover {
    background-color: var(--dw-color-cultured);
  }

  /* &.disabled {
    pointer-events: none;
    cursor: default;
  } */
`;

export function StudentCard({ item, onClick, disabled = false }: any) {
  return (
    <CardStyled
      onClick={() => onClick(item)}
      className={Utils.styles(disabled && 'disabled')}>
      <Flex gap={3}>
        <Flex kept>
          <Avatar
            size={60}
            src={item.picturePath}
            icon={Utils.getInitialLetters(item.name, item.lastName)}
          />
        </Flex>

        <div className='overflow-hidden'>
          <h4 title={item.fullName} className='text-truncate w-100 mb-1'>
            {item.fullName}
          </h4>
          <div title={item.email} className='text-muted text-truncate w-100'>
            {item.email}
          </div>
        </div>
      </Flex>
    </CardStyled>
  );
}
