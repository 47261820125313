import { Session } from 'dawere-commons';
import { Block, Visible } from 'dawere-uic';
import { PageWrapper } from 'pages';
import { getSEO } from 'utils';
import { BuyCouponsProvider } from './BuyCouponsProvider';
import { MainWrapper } from './sections/MainWrapper';
import { SummarySidebar } from './sections/SummarySidebar';

/**
 * Path: /comprar-cupones
 * Key: BUY_COUPONS
 * @returns {React.ReactElement}
 */
export default function BuyCouponsPage() {
  const activeOrganization = Session.getActiveOrganization();

  return (
    <PageWrapper>
      {getSEO('v2.pages.buyCoupons.seo', true)}

      <BuyCouponsProvider id={activeOrganization?.id}>
        <div id='summary-content'>
          <Block narrow align='stretch'>
            <MainWrapper />

            {/* Barra gris del summary para desktop */}
            <Visible md lg xl>
              <SummarySidebar />
            </Visible>
          </Block>
        </div>
      </BuyCouponsProvider>
    </PageWrapper>
  );
}
