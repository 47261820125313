import { i18n } from 'dawere-commons';
import { Col, Empty, Radio, RadioGroup, Skeleton, Utils } from 'dawere-uic';
import { useCallback } from 'react';
import { useEnroll } from '../hooks/useEnroll';

export function CalendarPicker() {
  const {
    isLoadingCalendars,
    isLoadingPeriods,
    isLoadingDocuments,
    isLoadingProgram,
    calendars,
    calendar,
    setCalendar
  } = useEnroll();

  const handleSelection = useCallback(
    (calendar: any) => {
      setCalendar(calendar);
    },
    [setCalendar]
  );

  return (
    <>
      <h2 className='mb-4'>{i18n('v2.pages.enrolment.steps.1.description')}</h2>

      {isLoadingCalendars ||
      isLoadingPeriods ||
      isLoadingDocuments ||
      isLoadingProgram ? (
        <>
          {Array(6)
            .fill(0)
            .map((_, index) => (
              <Col key={index} md={6} className='mb-2'>
                <Skeleton
                  circle
                  width={30}
                  height={30}
                  style={{ marginRight: 10 }}
                />
                <Skeleton height={30} style={{ width: 'calc(90% - 26px)' }} />
              </Col>
            ))}
        </>
      ) : (
        <>
          {calendars.length ? (
            <RadioGroup value={calendar} onChange={handleSelection}>
              {calendars.map((calendar: any, index: number) => (
                <Radio
                  key={index}
                  name='calendar'
                  value={calendar.id}
                  label={
                    <>
                      <strong className='d-block'>
                        {i18n('v2.pages.enrolment.steps.1.format', {
                          start: Utils.formatDate(
                            calendar.dateInit,
                            'dd/mmm/yyyy'
                          ),
                          end: Utils.formatDate(calendar.dateEnd, 'dd/mmm/yyyy')
                        })}
                      </strong>
                      <span className='text-muted'>
                        (
                        {Utils.getElapsedTime(
                          calendar.dateInit,
                          calendar.dateEnd,
                          'months',
                          true
                        )}
                        )
                      </span>
                    </>
                  }
                  className='mb-4'
                />
              ))}
            </RadioGroup>
          ) : (
            <Empty />
          )}
        </>
      )}
    </>
  );
}
