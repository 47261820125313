import { i18n } from 'dawere-commons';
import { Button, Flex } from 'dawere-uic';
import { PageWrapper } from 'pages';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { ASSETS } from 'values';

export function NotFound() {
  return (
    <PageWrapper>
      <Helmet>
        <title>404</title>
        <meta name='robots' content='noindex,nofollow' />
      </Helmet>

      <Flex
        direction='column'
        align='center'
        justify='center'
        className='p-3 vh-100'>
        <img
          src={ASSETS.ILLUSTRATIONS.NOT_FOUND}
          alt='404'
          className='img-fluid w-md-35 | mb-4'
          style={{ maxWidth: '400px' }}
        />

        <h2 className='text-center mb-2'>{i18n('v2.pages.notFound.title')}</h2>

        <h4 className='text-center mb-5 text-muted'>
          {i18n('v2.pages.notFound.subtitle')}
        </h4>

        <Button as={Link} to={PATHS.HOME}>
          {i18n('v2.sections.navbar.links.home')}
        </Button>
      </Flex>
    </PageWrapper>
  );
}
