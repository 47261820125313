import { Badge, Button, Icons, Modal, Search, Spinner } from 'dawere-uic';
import { useCallback, useState } from 'react';

export function ModalAddStudent({ open, onClose }: any) {
  const [email, setEmail] = useState<string>('');
  const [list, setList] = useState<string[]>([]);
  const [checking, setChecking] = useState(false);

  const handleCheckEmail = useCallback(
    (data: any) => {
      setEmail(data.email);
      setChecking(true);

      setTimeout(() => {
        if (!list.includes(data.email)) {
          setList([...list, data.email]);
        }
        setEmail('');
        setChecking(false);
      }, 3000);
    },
    [list]
  );

  const handleRemoveEmail = useCallback(
    (id: string) => {
      const newList = list.filter((item: string) => item !== id);
      setList(newList);
    },
    [list]
  );

  const handleClose = useCallback(() => {
    setList([]);
    onClose(false);
  }, [onClose]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      type='email'
      title='Añadir estudiante'
      backdrop='static'
      footer={
        <Button
          block
          onClick={() => {}}
          disabled={checking || list.length === 0}>
          Enviar invitación
        </Button>
      }>
      <p>
        Si quieres añadir a algún estudiante a tu lista de beneficiarios,
        ingresa su correo electrónico a continuación.
      </p>
      <p>
        El estudiante recibirá una invitación de tu parte. Una vez la acepte,
        podrás seguir y apoyar su avance académico.
      </p>
      <p>Puedes añadir tantas direcciones de correo como desees.</p>

      <Search
        name='email'
        onSubmit={handleCheckEmail}
        placeholder={checking ? `Verificando ${email}...` : 'Añadir email...'}
        icon={<Icons.Plus />}
        disabled={checking}
        clearAfterSubmit
        validators={{
          email: true
        }}
      />

      {list.map((email: string) => (
        <Badge
          key={email}
          id={email}
          closable
          onClose={handleRemoveEmail}
          disabled={checking}>
          {email}
        </Badge>
      ))}
      {email ? (
        <Badge id={email}>
          <Spinner
            variant='pulse'
            size={0.75}
            className='d-inline-block mr-2'
          />
          {email}
        </Badge>
      ) : null}
    </Modal>
  );
}
