import { i18n } from 'dawere-commons';
import {
  Alert,
  AutoComplete,
  Button,
  Col,
  Form,
  Input,
  Row,
  Skeleton
} from 'dawere-uic';

export function BillingInfoForm({
  loading = false,
  data = {},
  readonly = false,
  editing = false,
  onChange = () => {},
  onEditing = () => {},
  onSubmit = () => {},
  onCancel = () => {},
  picklists = {},
  showAlert = false
}: any) {
  return (
    <>
      {showAlert && !loading ? (
        <Alert
          icon='warning'
          title={i18n('v2.pages.buyCoupons.steps.2.billing.alertTitle')}
          className='mb-4'>
          <p>{i18n('v2.pages.buyCoupons.steps.2.billing.alertDescription')}</p>
        </Alert>
      ) : null}

      <h3 className='mb-3 text-primary'>
        {i18n('v2.pages.buyCoupons.steps.2.billing.title')}
      </h3>

      {editing ? (
        <Form onSubmit={onSubmit}>
          <Row>
            <Col xs={12} lg={6}>
              <Input
                name='name'
                label={i18n('v2.pages.editOrganization.tabs.1.form.name')}
                value={data?.name}
                validators={{
                  required: true,
                  maxLength: 150
                }}
              />
            </Col>

            <Col xs={12} lg={6}>
              <Input
                name='tin'
                label={i18n('v2.pages.editOrganization.tabs.1.form.tin')}
                value={data?.tin}
                validators={{
                  required: true,
                  maxLength: 100
                }}
              />
            </Col>

            <Col xs={12} lg={6}>
              <AutoComplete
                loading={picklists.isLoadingCountries}
                options={picklists.countries}
                name='country'
                label={i18n('v2.pages.editOrganization.tabs.1.form.country')}
                value={data?.country}
                onChange={(country: any) => {
                  onChange({
                    ...data,
                    country,
                    state: null,
                    township: null
                  });
                }}
                validators={{
                  required: true
                }}
              />
            </Col>

            <Col xs={12} lg={6}>
              <AutoComplete
                loading={picklists.isLoadingStates}
                options={picklists.states}
                name='state'
                label={i18n('v2.pages.editOrganization.tabs.1.form.state')}
                value={data?.state}
                onChange={(state: any) => {
                  onChange({
                    ...data,
                    state,
                    township: null
                  });
                }}
                disabled={!data?.country}
                validators={{
                  required: true
                }}
              />
            </Col>

            <Col xs={12} lg={6}>
              <AutoComplete
                loading={picklists.isLoadingTownships}
                options={picklists.townships}
                name='township'
                label={i18n('v2.pages.editOrganization.tabs.1.form.township')}
                value={data?.township}
                onChange={(township: any) => {
                  onChange({
                    ...data,
                    township
                  });
                }}
                disabled={!data?.state}
                validators={{
                  required: true
                }}
              />
            </Col>

            <Col xs={12} lg={6}>
              <Input
                name='postalCode'
                label={i18n('v2.pages.editOrganization.tabs.1.form.postalCode')}
                value={data?.postalCode}
                validators={{
                  required: true,
                  maxLength: 10
                }}
              />
            </Col>

            <Col xs={12}>
              <Input
                name='billingAddress'
                label={i18n(
                  'v2.pages.editOrganization.tabs.1.form.billingAddress'
                )}
                value={data?.billingAddress}
                validators={{
                  required: true,
                  maxLength: 250
                }}
              />
            </Col>
          </Row>

          <div className='d-flex flex-column flex-md-row'>
            <Button
              type='button'
              onClick={onCancel}
              variant='outline'
              className='mr-md-2 | w-100 w-md-auto'>
              {i18n('v2.pages.buyCoupons.steps.2.billing.cancel')}
            </Button>

            <Button
              type='submit'
              className='w-100 w-md-auto | order-first order-md-last | mb-2 mb-md-0'>
              {i18n('v2.pages.buyCoupons.steps.2.billing.save')}
            </Button>
          </div>
        </Form>
      ) : (
        <div>
          <Row>
            <Col xs={12} md={6}>
              <p>
                <strong className='d-block'>
                  {i18n('v2.pages.createOrganization.form.name')}
                </strong>
                {loading ? <Skeleton /> : <span>{data?.name || '-'}</span>}
              </p>
            </Col>

            <Col xs={12} md={6}>
              <p>
                <strong className='d-block'>
                  {i18n('v2.pages.createOrganization.form.tin')}
                </strong>
                {loading ? <Skeleton /> : <span>{data?.tin || '-'}</span>}
              </p>
            </Col>

            <Col xs={12} md={6}>
              <p>
                <strong className='d-block'>
                  {i18n('v2.pages.createOrganization.form.country')}
                </strong>
                {loading ? (
                  <Skeleton />
                ) : (
                  <span>{data?.country?.label || '-'}</span>
                )}
              </p>
            </Col>

            <Col xs={12} md={6}>
              <p>
                <strong className='d-block'>
                  {i18n('v2.pages.createOrganization.form.state')}
                </strong>
                {loading ? (
                  <Skeleton />
                ) : (
                  <span>{data?.state?.label || '-'}</span>
                )}
              </p>
            </Col>

            <Col xs={12} md={6}>
              <p>
                <strong className='d-block'>
                  {i18n('v2.pages.createOrganization.form.township')}
                </strong>
                {loading ? (
                  <Skeleton />
                ) : (
                  <span>{data?.township?.label || '-'}</span>
                )}
              </p>
            </Col>

            <Col xs={12} md={6}>
              <p>
                <strong className='d-block'>
                  {i18n('v2.pages.createOrganization.form.postalCode')}
                </strong>
                {loading ? (
                  <Skeleton />
                ) : (
                  <span>{data?.postalCode || '-'}</span>
                )}
              </p>
            </Col>

            <Col xs={12}>
              <p>
                <strong className='d-block'>
                  {i18n('v2.pages.createOrganization.form.billingAddress')}
                </strong>
                {loading ? (
                  <Skeleton />
                ) : (
                  <span>{data?.billingAddress || '-'}</span>
                )}
              </p>
            </Col>
          </Row>

          {readonly ? null : (
            <Button
              variant='outline'
              onClick={() => onEditing(true)}
              disabled={loading}>
              {i18n('v2.pages.buyCoupons.steps.2.billing.edit')}
            </Button>
          )}
        </div>
      )}
    </>
  );
}
