import { ScienceOutlined } from '@mui/icons-material';
import { API } from 'api';
import { Tasks } from 'components';
import { Session, i18n } from 'dawere-commons';
import { Button, Modal, Spinner, Utils } from 'dawere-uic';
import { useEnroll } from 'pages/private/enroll/hooks/useEnroll';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import styled from 'styled-components';

export function FreeTrialEnrollButton({ gateway = false, className }: any) {
  const { program, isLoadingSummary, summary, setIsPaid, track } = useEnroll();
  const account = Session.getAccountInfo();
  const enrollment = Session.getEnrolmentByProgram(program?.id);

  const [isPaying, setIsPaying] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);
  const [asyncId, setAsyncId] = useState<string | null>(null);

  const handleEnrollFree = useCallback(() => {
    setIsPaying(true);

    API.Enrollment.enrollFree(summary?.id).then(({ data }) => {
      setIsPaid(true);
      track('funnel_step3_payment', ['period', 'payment_type'], {
        payment_method: 'trial'
      });

      if (Utils.has(data, 'asyncProcessId')) {
        setAsyncId(data.asyncProcessId);
      } else {
        setIsPaying(false);
        setIsRedirect(true);
      }
    });
  }, [setIsPaid, summary?.id, track]);

  if (!program?.freeTrial) {
    return null;
  }

  if (account?.freeTrialAvailable !== true) {
    return null;
  }

  if (['FREE_TRIAL', 'FREE_TRIAL_ENDED'].includes(enrollment?.status)) {
    return null;
  }

  return (
    <div className={className}>
      {gateway ? (
        <ButtonStyled
          block
          type='button'
          onClick={handleEnrollFree}
          variant='tertiary'
          disabled={isLoadingSummary || isPaying || isRedirect}>
          <ScienceOutlined className='fs-4' />
          <span>{i18n('v2.pages.home.section1.buttons.inscription')}</span>
        </ButtonStyled>
      ) : (
        <Button
          block
          type='button'
          onClick={handleEnrollFree}
          variant='tertiary'
          disabled={isLoadingSummary || isPaying || isRedirect}>
          {i18n('v2.pages.home.section1.buttons.inscription')}
        </Button>
      )}

      <Modal
        open={isPaying}
        backdrop='static'
        onClose={setIsPaying}
        closable={false}
        keyboard={false}>
        <div>
          <div className='text-center'>
            <Spinner
              variant='pulse'
              size={4}
              color='primary'
              className='mb-3 mt-3'
            />
            <p className='fs-2 mb-0'>
              {i18n(
                'v2.pages.buyCoupons.steps.2.creditCard.modalFreePaying.title'
              )}
            </p>
            <p className='fs-1 text-muted'>
              {i18n(
                'v2.pages.buyCoupons.steps.2.creditCard.modalFreePaying.description'
              )}
            </p>
          </div>

          <Tasks
            asyncId={asyncId}
            onFinished={() => {
              setIsPaying(false);
              setIsRedirect(true);
            }}
          />
        </div>
      </Modal>

      <Modal
        type='success'
        open={isRedirect}
        backdrop='static'
        onClose={setIsRedirect}
        closable={false}
        keyboard={false}
        title={i18n('v2.pages.buyCoupons.steps.2.creditCard.modalFree.title')}
        footer={
          <Button block as={Link} to={PATHS.STUDENTS_DASHBOARD}>
            {i18n('v2.pages.buyCoupons.steps.2.creditCard.modalFree.button')}
          </Button>
        }>
        <p
          dangerouslySetInnerHTML={{
            __html: i18n(
              'v2.pages.buyCoupons.steps.2.creditCard.modalFree.description',
              {
                programName: program?.name,
                freePeriodDays: program?.freeTrialDays
              }
            )
          }}
        />
      </Modal>
    </div>
  );
}

const ButtonStyled = styled(Button)`
  height: 100%;
  height: 100% !important;
  font-size: 0.85rem;
  border-radius: 8px;

  > span {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    row-gap: var(--dw-spacer-2);
  }
`;
