import { Rest } from 'dawere-commons';
import { Utils } from 'dawere-uic';

function getCurrencies() {
  return Rest.get('catalog/currencies').then(({ data = [] }: any) => {
    return data;
  });
}

function getPrograms(currencyId?: string) {
  return Rest.get('catalog/vouchers', {
    params: { currency_id: currencyId }
  }).then(({ data = [] }: any) => {
    return data;
  });
}

function getGateways(currencyId?: string) {
  return Rest.get('payment-gateways', {
    params: { currency_id: currencyId }
  }).then(({ data }: any) => {
    return {
      ...data,
      banks: data.banks.map((bank: any) => ({
        ...bank,
        value: bank.id,
        label: bank.name
      }))
    };
  });
}

function createOrder(organizationId: string, payload: any = {}) {
  const body = Utils.copy(payload);

  if (!body?.taxData?.address) {
    delete body.taxData;
  }

  return Rest.post(
    `my-organizations/${organizationId}/service-voucher`,
    body
  ).then(({ data }: any) => {
    return data;
  });
}

function updateOrder(
  organizationId: string,
  orderId: string,
  payload: any = {}
) {
  const body = Utils.copy(payload);

  if (!body?.taxData?.address) {
    delete body.taxData;
  }

  return Rest.put(
    `my-organizations/${organizationId}/orders/${orderId}`,
    body
  ).then(({ data }: any) => {
    return data;
  });
}

function validateDiscountCoupon(coupon: string) {
  return Rest.get(`coupons/${coupon}/value`).then(({ data }: any) => {
    return data;
  });
}

function createTransfer(payload: any = {}) {
  return Rest.post('payment-gateways/transfer', payload);
}

function createPayPalOrder(payload: any = {}) {
  return Rest.post('payment-gateways/paypal', payload);
}

function updatePayPalOrder(orderId: string) {
  return Rest.get(`paypal/orders/${orderId}`).then(({ data }: any) => {
    return data;
  });
}

function createStripeIntent(payload: any = {}) {
  return Rest.post('payment-gateways/stripe/intent', payload);
}

function updateStripeOrder(secretId: string, rejectCode?: string) {
  return Rest.post(`payment-gateways/stripe/intent/${secretId}`, {
    rejectCode
  }).then(({ data }: any) => {
    return data;
  });
}

function createDirectPayment(payload: any = {}) {
  return Rest.post('payment-gateways/checkout', payload);
}

function createWalletPayment(payload: any = {}) {
  return Rest.post('payment-gateways/wallet', payload);
}

export const Cart = {
  getCurrencies,
  getPrograms,
  getGateways,
  createOrder,
  updateOrder,
  validateDiscountCoupon,
  createTransfer,
  createStripeIntent,
  createPayPalOrder,
  updatePayPalOrder,
  updateStripeOrder,
  createDirectPayment,
  createWalletPayment
};
