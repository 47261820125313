import { API } from 'api';
import { i18n } from 'dawere-commons';
import {
  Avatar,
  Button,
  ButtonGroup,
  Card,
  Confirm,
  Dropdown,
  Flex,
  Icons,
  toast,
  Utils,
  Visible
} from 'dawere-uic';
import { useCallback, useState } from 'react';
import styled from 'styled-components';

const CardStyled = styled(Card)`
  margin-bottom: var(--dw-spacer-3);
  padding: var(--dw-spacer-3);
  background-color: var(--dw-color-cultured);
`;

export function InvitationCard({ item, onChange = () => {} }: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState(false);
  const [isOpenConfirmResend, setIsOpenConfirmResend] = useState(false);

  const handleOpenDelete = useCallback(() => {
    setIsOpenConfirmDelete(true);
  }, []);

  const handleOpenResend = useCallback(() => {
    setIsOpenConfirmResend(true);
  }, []);

  const handleInvite = useCallback(() => {
    setIsLoading(true);

    API.Tutors.inviteStudent({ email: item.email })
      .then((response) => {
        onChange();
        toast.success(
          i18n('v2.pages.members.modals.add.success', { email: item.email })
        );
      })
      .finally(() => setIsLoading(false));
  }, [item.email, onChange]);

  const handleDelete = useCallback(() => {
    setIsLoading(true);

    API.Invitations.remove(item.id)
      .then(() => {
        onChange();
        toast.success(i18n('v2.pages.members.modals.delete.invitationSuccess'));
      })
      .finally(() => setIsLoading(false));
  }, [item.id, onChange]);

  return (
    <CardStyled>
      <Flex gap={3} align='center'>
        <Flex kept>
          <Avatar size={60} iconClassName='text-light' />
        </Flex>
        <Flex full direction='column' className='overflow-hidden'>
          <h4 title={item.email} className='text-truncate w-100 mb-1'>
            {item.email}
          </h4>
          <div className='text-truncate w-100 text-muted'>
            {i18n('v2.pages.permissions.invitations.sent', {
              date: Utils.formatDate(item.invitationDate, 'dd/mmm/yyyy')
            })}
          </div>

          <Visible xs sm>
            <ButtonGroup.Confirm className='ml-1 mt-2'>
              <Button
                variant='outline'
                onClick={handleOpenDelete}
                loading={isLoading}>
                {i18n('v2.pages.permissions.invitations.buttons.delete')}
              </Button>
              <Button
                variant='outline'
                onClick={handleOpenResend}
                loading={isLoading}>
                {i18n('v2.pages.permissions.invitations.buttons.send')}
              </Button>
            </ButtonGroup.Confirm>
          </Visible>
        </Flex>

        <Visible md lg xl>
          <Flex kept>
            <Dropdown
              toggle={<Icons.Dots className='anchor mx-3' />}
              align='right'
              disabled={isLoading}>
              <Dropdown.Item onClick={handleOpenDelete}>
                {i18n('v2.pages.permissions.invitations.buttons.delete')}
              </Dropdown.Item>
              <Dropdown.Item onClick={handleOpenResend}>
                {i18n('v2.pages.permissions.invitations.buttons.send')}
              </Dropdown.Item>
            </Dropdown>
          </Flex>
        </Visible>
      </Flex>

      <Confirm
        type='warning'
        title={i18n('v2.pages.members.modals.delete.invitationTitle')}
        open={isOpenConfirmDelete}
        onClose={setIsOpenConfirmDelete}
        onConfirm={handleDelete}
        confirmText={i18n('v2.pages.members.modals.delete.confirmText')}>
        <p
          dangerouslySetInnerHTML={{
            __html: i18n('v2.pages.permissions.invitations.deleteQuestion', {
              email: item?.email
            })
          }}
        />
      </Confirm>

      <Confirm
        type='email'
        title={i18n('v2.pages.members.modals.resend.title')}
        open={isOpenConfirmResend}
        onClose={setIsOpenConfirmResend}
        onConfirm={handleInvite}
        confirmText={i18n('v2.pages.members.modals.resend.confirmText')}>
        <p
          dangerouslySetInnerHTML={{
            __html: i18n('v2.pages.permissions.invitations.sendQuestion', {
              email: item?.email
            })
          }}
        />
      </Confirm>
    </CardStyled>
  );
}
