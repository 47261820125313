import { API } from 'api';
import { i18n } from 'dawere-commons';
import {
  Col,
  Divider,
  Empty,
  Previewer,
  Row,
  Skeleton,
  useRouter
} from 'dawere-uic';
import { useEffect, useState } from 'react';

export function DocumentsTab({ enrollment }: any) {
  const { program } = enrollment;
  const { query } = useRouter();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (query?.studentId && program?.id) {
      setIsLoading(true);

      API.Students.getDocuments(query?.studentId, program?.id)
        .then((response: any) => setData(response))
        .finally(() => setIsLoading(false));
    }
  }, [program?.id, query?.studentId]);

  return isLoading ? (
    <Row>
      {Array(6)
        .fill(0)
        .map((_, index) => (
          <Col key={index} xs={12} md={6} lg={4} className='mb-4'>
            <Skeleton.Previewer />
          </Col>
        ))}
    </Row>
  ) : (
    <>
      {data?.total ? (
        <>
          <Row>
            {data?.mandatory?.length ? (
              <>
                <Col xs={12}>
                  <Divider section align='left'>
                    {i18n('v2.pages.myDocuments.mandatory')}
                  </Divider>
                </Col>

                {data?.mandatory?.map((document: any) => (
                  <Col key={document.id} xs={12} md={6} lg={4} className='mb-4'>
                    <Previewer
                      id={document.id}
                      name={document.name}
                      title={document.name}
                      status={document.status}
                      src={document.src}
                      physical={document.physical}
                      reasonsToReject={document.reasonsToReject}
                    />
                  </Col>
                ))}
              </>
            ) : null}
          </Row>

          <Row>
            {data?.optional?.length ? (
              <>
                <Col xs={12}>
                  <Divider section align='left'>
                    {i18n('v2.pages.myDocuments.optional')}
                  </Divider>
                </Col>

                {data?.optional?.map((document: any) => (
                  <Col key={document.id} xs={12} md={6} lg={4} className='mb-4'>
                    <Previewer
                      id={document.id}
                      name={document.name}
                      title={document.name}
                      status={document.status}
                      src={document.src}
                      physical={document.physical}
                      reasonsToReject={document.reasonsToReject}
                    />
                  </Col>
                ))}
              </>
            ) : null}
          </Row>
        </>
      ) : (
        <Empty />
      )}
    </>
  );
}
