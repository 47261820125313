import { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Loading } from 'dawere-uic';

import { NotFound } from 'pages';
import { DefaultLayout } from 'layouts';
import { RouteProps } from './props';
import { routes } from './routes';

export function RouteMapper() {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        {routes.map(
          ({ key, layout: Layout = DefaultLayout, ...rest }: RouteProps) => {
            return <Layout key={key} {...rest} />;
          }
        )}
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </Suspense>
  );
}
