import { Rest } from 'dawere-commons';

function getGuides(topicId: string) {
  return Rest.get(`public/catalog/topics/${topicId}/guides`);
}

function getVideos(topicId: string) {
  return Rest.get(`public/catalog/topics/${topicId}/videos`);
}

function getTests(topicId: string) {
  return Rest.get(`public/catalog/topics/${topicId}/tests`);
}

export const Topics = {
  getGuides,
  getVideos,
  getTests
};
