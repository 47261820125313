import { API } from 'api';
import { useRouter, Utils } from 'dawere-uic';
import { useEffect, useState } from 'react';
import { PATHS } from 'router';
import { QPARAMS } from 'values';

export function useTokenValidation() {
  const { push, query } = useRouter();

  const decoded = Utils.decodeJWT(query[QPARAMS.Token]);

  const [isValidating, setIsValidating] = useState(true);

  useEffect(() => {
    if (query[QPARAMS.Token]) {
      setIsValidating(true);

      API.Account.validateToken(query[QPARAMS.Token])
        .then(({ data }: any) => {
          if (data === false) {
            push(PATHS.INVALID_TOKEN);
          }
        })
        .finally(() => setIsValidating(false));
    }
  }, [push, query]);

  return { decoded, token: query[QPARAMS.Token], isValidating };
}
