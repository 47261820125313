import { Utils } from 'dawere-uic';

export function getTotals(
  amount: number,
  installments: any[] = [],
  walletBalance: number | null = null
): {
  total: number;
  usedWalletAmount: number | null;
} {
  // Process installments and total
  // Ponemos el precio total como inicial y luego determino si
  // tiene o no cuotas
  let total = amount; // Using wallet

  // Check if the installments is full of IDs: came from pay view
  const isFullOfStrings = installments.every((i: any) => Utils.isString(i));

  // Tiene más de una cuta, entonces calculo el total
  // en base a las cuotas pendientes por pagarse
  if (installments.length > 1 && !isFullOfStrings) {
    total = 0;

    installments.forEach((i: any) => {
      if ((i?.status === 'PENDING' && i?.due) || i?.checked) {
        total += i.amount * amount;
      }
    });
  }

  const totalWithoutWallet = total;

  if (walletBalance && Utils.isNumber(walletBalance)) {
    total = Math.max(total - walletBalance, 0);
  }

  // amount: 100, walletBalance: 150 -> total: 0; usedWalletAmount: 100
  // amount: 150, walletBalance: 100 -> total: 20; usedWalletAmount: 100
  // amount: 150, walletBalance: 150 -> total: 0; usedWalletAmount: 150
  // amount: 150, walletBalance: 0 -> total: 150; usedWalletAmount: 0
  // amount: 150, walletBalance: null -> total: 150; usedWalletAmount: null
  return {
    total,
    usedWalletAmount:
      walletBalance && Utils.isNumber(walletBalance)
        ? Math.min(totalWithoutWallet, walletBalance)
        : null
  };
}

export function getInstallmentIds(installments: any[] = []) {
  const isFullOfStrings = installments.every((i: any) => Utils.isString(i));

  if (isFullOfStrings) {
    return installments;
  }

  return installments
    .filter((i: any) => (i.status === 'PENDING' && i.due) || i?.checked)
    .map(({ id }: any) => id);
}
