export const ASSETS = {
  FLAGS: {
    USA: 'https://storage.googleapis.com/dawere-web-assets/countries/USA.svg',
    COL: 'https://storage.googleapis.com/dawere-web-assets/countries/COL.svg',
    MEX: 'https://storage.googleapis.com/dawere-web-assets/countries/MEX.svg',
    DOM: 'https://storage.googleapis.com/dawere-web-assets/countries/DOM.svg',
    VEN: 'https://storage.googleapis.com/dawere-web-assets/countries/VEN.svg',
    HDN: 'https://storage.googleapis.com/dawere-web-assets/countries/HDN.svg'
  },
  ILLUSTRATIONS: {
    STUDENT_HAPPY_READING:
      'https://storage.googleapis.com/dawere-web-assets/Ilustraciones/Ilustracion_-_Prueba_gratis.svg',
    ZZZ: 'https://storage.googleapis.com/dawere-web-assets/Ilustraciones/*DASHBOARD%20PADRES%20%26%20EMPRESAS/zzz_no%20archivos.svg',
    HI5: 'https://storage.googleapis.com/dawere-web-assets/Ilustraciones/hi5.svg',
    NOT_FOUND:
      'https://storage.googleapis.com/dawere-web-assets/Ilustraciones/*ERRORES/404.svg',
    TOKEN_EXPIRED:
      'https://storage.googleapis.com/dawere-web-assets/Ilustraciones/*ERRORES/token-expired.svg',
    CLOCK:
      'https://storage.googleapis.com/dawere-web-assets/Ilustraciones/*DASHBOARD%20PADRES%20%26%20EMPRESAS/Reloj.svg',
    STUDENTS_STAIRS:
      'https://storage.googleapis.com/dawere-web-assets/Ilustraciones/*DASHBOARD%20PADRES%20%26%20EMPRESAS/ID_DashboardPadres%26Empresas_CeroEstudiantes.svg',
    PROFILE:
      'https://storage.googleapis.com/dawere-web-assets/Ilustraciones/profile.svg',
    OVNI: 'https://storage.googleapis.com/dawere-web-assets/Ilustraciones/ovni.svg',
    OVNI_NEG:
      'https://storage.googleapis.com/dawere-web-assets/Ilustraciones/ovni-neg.svg',
    STUDENTS_JUMPING:
      'https://storage.googleapis.com/dawere-web-assets/Ilustraciones/1%20SCENE-01.svg',
    STUDENT_READING:
      'https://storage.googleapis.com/dawere-web-assets/Ilustraciones/3%20SCENE-01.svg',
    STUDENT_EXAM:
      'https://storage.googleapis.com/dawere-web-assets/Ilustraciones/estudiante%20_concentrado.svg',
    HANDS_DIPLOMAS:
      'https://storage.googleapis.com/dawere-web-assets/Ilustraciones/Doble_Titulacion-01.svg',
    HAND_PAPER:
      'https://storage.googleapis.com/dawere-web-assets/Ilustraciones/hand-message.svg',
    MOM_HOMEWORK:
      'https://storage.googleapis.com/dawere-web-assets/Ilustraciones/Registro-02.svg',
    VIDEO_CHAT:
      'https://storage.googleapis.com/dawere-web-assets/Ilustraciones/Registro-01.svg',
    GUY_LAPTOP:
      'https://storage.googleapis.com/dawere-web-assets/Ilustraciones/Registro-03.svg',
    GUY_QUESTIONS:
      'https://storage.googleapis.com/dawere-web-assets/Ilustraciones/questions.svg',
    GENERAL_VIDEO_CLASS:
      'https://storage.googleapis.com/dawere-web-assets/Ilustraciones/5%20SCENE.svg',
    MATH_VIDEO_CLASS:
      'https://storage.googleapis.com/dawere-web-assets/Ilustraciones/profesor_estudiante.svg',
    CUSTOMER_SERVICE:
      'https://storage.googleapis.com/dawere-web-assets/Ilustraciones/4%20SCENE.svg',
    CELEBRATION:
      'https://storage.googleapis.com/dawere-web-assets/Ilustraciones/2%20SCENE.svg',
    STUDENT_BACKPACK:
      'https://storage.googleapis.com/dawere-web-assets/Ilustraciones/6%20SCENE.svg',
    CAREER_CYCLE:
      'https://storage.googleapis.com/dawere-web-assets/Ilustraciones/*PARA%20ORGANIZACIONES/ID_Pagina_Organizaciones_Bloque1_HERO.svg',
    TOURIST:
      'https://storage.googleapis.com/dawere-web-assets/Ilustraciones/COGNIA/Globo%20terr%C3%A1queo%20-%20Cognia_Mesa%20de%20trabajo%201.svg',
    ROCKETS:
      'https://storage.googleapis.com/dawere-web-assets/Ilustraciones/*FILOSOFIA/ID_Pagina_Filosofia1.svg',
    WORLD:
      'https://storage.googleapis.com/dawere-web-assets/Ilustraciones/*FILOSOFIA/ID_Pagina_Filosofia2.svg',
    STUDENT_COGNIA:
      'https://storage.googleapis.com/dawere-web-assets/Ilustraciones/COGNIA/Estudiante%20-%20Sello_Mesa%20de%20trabajo%201.svg',
    STUDENT_THINKING:
      'https://storage.googleapis.com/dawere-web-assets/Ilustraciones/student-reading-small.svg'
  },
  ICONS: {
    EMAIL_SEND:
      'https://storage.googleapis.com/dawere-web-assets/Iconos/ic_iniciodesesion_revisatuemail.svg',
    DEMO_REQUESTED:
      'https://storage.googleapis.com/dawere-web-assets/Iconos/ic_organizaciones_solicitud_demo_recibida.svg',
    ACCESS:
      'https://storage.googleapis.com/dawere-web-assets/Iconos/ic_organizaciones_bloque2_acceso.svg',
    SHIELD:
      'https://storage.googleapis.com/dawere-web-assets/Iconos/ic_homepage_bloque3.svg',
    QUALITY:
      'https://storage.googleapis.com/dawere-web-assets/Iconos/Passive%20Income.svg',
    CERTIFICATE:
      'https://storage.googleapis.com/dawere-web-assets/Iconos/Certificate.svg',
    CLOCK: 'https://storage.googleapis.com/dawere-web-assets/Iconos/Clock.svg',
    ONLINE_BOOK:
      'https://storage.googleapis.com/dawere-web-assets/Iconos/Online%20LEarning.svg',
    REGISTER:
      'https://storage.googleapis.com/dawere-web-assets/Iconos/Registration.svg',
    TUTORIAL:
      'https://storage.googleapis.com/dawere-web-assets/Iconos/Tutorial.svg',
    TARGET:
      'https://storage.googleapis.com/dawere-web-assets/Iconos/Target.svg',
    CHECK:
      'https://storage.googleapis.com/dawere-web-assets/Iconos/ic_iniciodesesion_contrasenacreada.svg'
  },
  ALLIES: {
    UNICEF:
      'https://storage.googleapis.com/dawere-web-assets/logotipos/UNICEF_Logo.png',
    MLB: 'https://storage.googleapis.com/dawere-web-assets/logotipos/MLB-01.png',
    SANTANDER_UNIVERSITY:
      'https://storage.googleapis.com/dawere-web-assets/logotipos/UniversidadSantander.svg',
    DIGITEL:
      'https://storage.googleapis.com/dawere-web-assets/logotipos/Digitel-01.png',
    ALTICE:
      'https://storage.googleapis.com/dawere-web-assets/logotipos/Altice-01.png',
    ARTURO_USLAR_PIETRI:
      'https://storage.googleapis.com/dawere-web-assets/logotipos/arturo-uslar-Gabriela%E2%80%99s%20MacBook%20Air.png',
    USAID:
      'https://storage.googleapis.com/dawere-web-assets/logotipos/USAID-01.png',
    ARUBA:
      'https://storage.googleapis.com/dawere-web-assets/logotipos/Aruba_DE-Gabriela%E2%80%99s%20MacBook%20Air.png',
    GEORGETOWN:
      'https://storage.googleapis.com/dawere-web-assets/logotipos/GCLSeal540-Gabriela%E2%80%99s%20MacBook%20Air.png',
    UNHCR:
      'https://storage.googleapis.com/dawere-web-assets/logotipos/UNHCR-01.png',
    PADF: 'https://storage.googleapis.com/dawere-web-assets/logotipos/PADF.png',
    BID: 'https://storage.googleapis.com/dawere-web-assets/logotipos/BID.svg'
  }
};
