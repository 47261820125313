import { i18n } from 'dawere-commons';
import { Col, Divider, Skeleton } from 'dawere-uic';
import { PendingCard } from '../components/PendingCard';
import { usePermissions } from '../hooks/usePermissions';

export function PendingInvitations() {
  const {
    invitations,
    isLoadingInvitations,
    loadInvitations,
    onInvitationChange
  } = usePermissions();

  if (invitations.length === 0 && !isLoadingInvitations) {
    return null;
  }

  return (
    <Col xs={12} className='mb-4'>
      <Divider section>{i18n('v2.pages.permissions.pending.title')}</Divider>

      {isLoadingInvitations ? (
        <>
          {Array(3)
            .fill(0)
            .map((_, index) => (
              <Skeleton key={index} height={100} className='mb-2' />
            ))}
        </>
      ) : (
        <>
          {invitations.map((invitation: any) => (
            <PendingCard
              invitation={invitation}
              key={invitation.id}
              onAccept={onInvitationChange}
              onReject={loadInvitations}
            />
          ))}
        </>
      )}
    </Col>
  );
}
