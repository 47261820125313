import { API } from 'api';
import { i18n } from 'dawere-commons';
import { Button, Form, Input, Modal, toast, useRouter } from 'dawere-uic';
import { PageWrapper, TwoColumnTemplate } from 'pages';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { getSEO } from 'utils';
import { ASSETS, QPARAMS } from 'values';

/**
 * Path: /olvide-contrasena
 * Key: FORGOT_PASSWORD
 * @returns {React.ReactElement}
 */
export default function ForgotPasswordPage() {
  const { push, query } = useRouter();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState<string>(query[QPARAMS.Email]);

  const hanldeSubmit = useCallback(
    (data: any) => {
      setEmail(data.email);
      setIsLoading(true);

      API.Account.forgotPassword(data)
        .then(() => {
          toast.success(i18n('v2.pages.forgotPassword.toast'));
          push(PATHS.LOGIN);
        })
        .catch((err: any) => {
          const { status } = err.response;

          if (status === 403) {
            // Verify account
            setOpenModal(true);
          }
        })
        .finally(() => setIsLoading(false));
    },
    [push]
  );

  const handleResendEmail = useCallback(() => {
    setIsLoading(true);

    API.Account.resendVerification(email)
      .then(() => {
        push(PATHS.VERIFY_EMAIL);
      })
      .finally(() => setIsLoading(false));
  }, [email, push]);

  return (
    <PageWrapper>
      {getSEO('v2.pages.forgotPassword.seo')}

      <TwoColumnTemplate
        mobileImage={
          <img
            src={ASSETS.ILLUSTRATIONS.GUY_QUESTIONS}
            alt={i18n('v2.pages.forgotPassword.pageTitle')}
            className='img-fluid w-60'
          />
        }
        desktopImage={
          <img
            src={ASSETS.ILLUSTRATIONS.GUY_LAPTOP}
            alt={i18n('v2.pages.forgotPassword.title')}
            className='img-fluid'
          />
        }>
        <h1 className='mb-3 text-center text-lg-left h2'>
          {i18n('v2.pages.forgotPassword.title')}
        </h1>

        <h5 className='mb-5'>{i18n('v2.pages.forgotPassword.subtitle')}</h5>

        <Form onSubmit={hanldeSubmit}>
          <Input
            name='email'
            label={i18n('v2.pages.forgotPassword.form.email')}
            type='email'
            value={email}
            validators={{
              required: true,
              email: true
            }}
            loading={isLoading}
          />

          <Button type='submit' block loading={isLoading}>
            {i18n('v2.pages.forgotPassword.buttons.submit')}
          </Button>
        </Form>

        <p className='mt-4 text-center'>
          {i18n('v2.pages.forgotPassword.withoutAccount')}{' '}
          <Link to={PATHS.get('REGISTER', {}, { [QPARAMS.Email]: email })}>
            {i18n('v2.pages.forgotPassword.links.register')}
          </Link>
        </p>

        <Modal
          type='email'
          open={openModal}
          backdrop='static'
          onClose={setOpenModal}
          title={i18n('v2.pages.forgotPassword.checkEmail.title')}
          footer={
            <Button
              block
              variant='outline'
              onClick={handleResendEmail}
              loading={isLoading}>
              {i18n('v2.pages.forgotPassword.checkEmail.buttons.submit')}
            </Button>
          }>
          <p>{i18n('v2.pages.forgotPassword.checkEmail.text')}</p>
        </Modal>
      </TwoColumnTemplate>
    </PageWrapper>
  );
}
