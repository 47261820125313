import { Rest } from 'dawere-commons';
import { Utils } from 'dawere-uic';

function getMyCoupons(params: any = {}) {
  return Rest.get('me/service-vouchers', {
    params
  }).then((response: any) => {
    const data = response.data.content.map((el: any) => {
      const purchasedDate = Utils.formatDate(el.purchasedDate, 'dd/mmm/yyyy');
      const assignedDate = Utils.formatDate(el.assignedDate, 'dd/mmm/yyyy');

      return {
        ...el,
        purchasedDate,
        assignedDate
      };
    });

    return {
      data,
      totalPages: response.data.totalPages,
      totalRows: response.data.totalElements
    };
  });
}

function getMyBalance() {
  return Rest.get('me/wallet');
}

export const Wallet = {
  getMyCoupons,
  getMyBalance
};
