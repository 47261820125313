import { API } from 'api';
import { i18n } from 'dawere-commons';
import {
  AutoComplete,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  toast
} from 'dawere-uic';
import { useGeo } from 'hooks';
import { useCallback, useEffect, useState } from 'react';

export function BillingInfoSection() {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [info, setInfo] = useState<any>({});

  const picklists = useGeo(info?.billingCountry, info?.billingState);

  const handleSubmit = useCallback((data: any) => {
    setIsSaving(true);

    const payload = {
      billingAddress: data.billingAddress,
      billingName: data.billingName,
      billingPostalCode: data.billingPostalCode,
      billingTin: data.billingTin,
      billingTownship: {
        id: data.billingTownship.value
      }
    };

    API.Account.updateBillingInfo(payload)
      .then((response: any) => toast.success(response.message))
      .finally(() => setIsSaving(false));
  }, []);

  useEffect(() => {
    setIsLoading(true);

    API.Account.getBillingInfo()
      .then((response: any) => {
        setInfo({
          ...response,
          billingCountry: response?.billingCountry
            ? {
                value: response?.billingCountry?.id,
                label: response?.billingCountry?.name
              }
            : null,
          billingState: response?.billingState
            ? {
                value: response?.billingState?.id,
                label: response?.billingState?.name
              }
            : null,
          billingTownship: response?.billingTownship
            ? {
                value: response?.billingTownship?.id,
                label: response?.billingTownship?.name
              }
            : null
        });
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <>
      <Divider section>
        {i18n('v2.pages.settings.tabs.3.billing.title')}
      </Divider>

      <p className='mb-4'>
        {i18n('v2.pages.settings.tabs.3.billing.description')}
      </p>

      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} md={6}>
            <Input
              name='billingName'
              label={i18n('v2.pages.settings.tabs.3.billing.billingName')}
              value={info?.billingName}
              validators={{
                required: true,
                maxLength: 150
              }}
              loading={isLoading || isSaving}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              name='billingTin'
              label={i18n('v2.pages.settings.tabs.3.billing.billingTin')}
              value={info?.billingTin}
              validators={{
                required: true,
                maxLength: 100
              }}
              loading={isLoading || isSaving}
            />
          </Col>
          <Col xs={12} md={6}>
            <AutoComplete
              name='billingCountry'
              label={i18n('v2.pages.settings.tabs.3.billing.billingCountry')}
              loading={picklists.isLoadingCountries || isLoading || isSaving}
              options={picklists.countries}
              value={info?.billingCountry}
              onChange={(billingCountry: any) => {
                setInfo({
                  ...info,
                  billingCountry,
                  billingState: null,
                  billingTownship: null
                });
              }}
              validators={{
                required: true
              }}
            />
          </Col>
          <Col xs={12} md={6}>
            <AutoComplete
              name='billingState'
              label={i18n('v2.pages.settings.tabs.3.billing.billingState')}
              loading={picklists.isLoadingStates || isLoading || isSaving}
              options={picklists.states}
              value={info?.billingState}
              onChange={(billingState: any) => {
                setInfo({
                  ...info,
                  billingState,
                  billingTownship: null
                });
              }}
              validators={{
                required: true
              }}
              disabled={!info?.billingCountry}
            />
          </Col>
          <Col xs={12} md={6}>
            <AutoComplete
              name='billingTownship'
              label={i18n('v2.pages.settings.tabs.3.billing.billingTownship')}
              loading={picklists.isLoadingTownships || isLoading || isSaving}
              options={picklists.townships}
              value={info?.billingTownship}
              onChange={(billingTownship: any) => {
                setInfo({
                  ...info,
                  billingTownship
                });
              }}
              validators={{
                required: true
              }}
              disabled={!info?.billingState}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              name='billingPostalCode'
              label={i18n('v2.pages.settings.tabs.3.billing.billingPostalCode')}
              value={info?.billingPostalCode}
              validators={{
                required: true,
                maxLength: 10
              }}
              loading={isLoading || isSaving}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              name='billingAddress'
              label={i18n('v2.pages.settings.tabs.3.billing.billingAddress')}
              value={info?.billingAddress}
              validators={{
                required: true,
                maxLength: 250
              }}
              loading={isLoading || isSaving}
            />
          </Col>
        </Row>

        <Button type='submit' loading={isSaving} disabled={isLoading}>
          {i18n('v2.pages.settings.tabs.1.profile.submit')}
        </Button>
      </Form>
    </>
  );
}
