import { API } from 'api';
import { i18n, Locales } from 'dawere-commons';
import { Button, Form, Input, Phone, Textarea, useRouter } from 'dawere-uic';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';

export function ApplyForm() {
  const [isLoading, setIsLoading] = useState(false);
  const { push } = useRouter();

  const handleSubmit = useCallback(
    async (data: any) => {
      setIsLoading(true);

      API.Organizations.requestDemo(data)
        .then(() => {
          // Hubspot form
          API.Account.hubspotDemoTrack(data);

          const { fullName } = data;
          const firstName = fullName
            .trim()
            .split(' ')
            .slice(0, -1)
            .join(' ')
            .trim();
          const lastName = fullName
            .trim()
            .split(' ')
            .slice(-1)
            .join(' ')
            .trim();

          push(
            PATHS.get(
              'ORGANIZATIONS_DEMO_REQUESTED',
              {},
              {
                firstName: firstName || lastName,
                lastName: lastName || firstName,
                email: data.email
              }
            )
          );
        })
        .finally(() => setIsLoading(true));
    },
    [push]
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        name='fullName'
        label={i18n('v2.pages.organizations.section2.form.fullName')}
        validators={{
          required: true,
          pattern: {
            value: /^[a-z\sáéíóúÁÉÍÓÚñÑçÇ]*$/gi,
            message: i18n(
              'v2.pages.organizations.section2.form.errors.fullName'
            )
          }
        }}
        loading={isLoading}
      />
      <Input
        name='email'
        type='email'
        label={i18n('v2.pages.organizations.section2.form.email')}
        validators={{ required: true, email: true }}
        loading={isLoading}
      />
      <Phone
        name='phoneNumber'
        label={i18n('v2.pages.organizations.section2.form.phoneNumber')}
        validators={{
          minLength: 5,
          maxLength: 15,
          required: true,
          phone: true
        }}
        defaultCountry={Locales.getCountry()}
        loading={isLoading}
      />
      <Input
        name='organizationName'
        label={i18n('v2.pages.organizations.section2.form.organizationName')}
        validators={{ required: true }}
        loading={isLoading}
      />
      <Input
        name='role'
        label={i18n('v2.pages.organizations.section2.form.role')}
        validators={{ required: true }}
        loading={isLoading}
      />
      <Textarea
        name='summary'
        label={i18n('v2.pages.organizations.section2.form.summary')}
        validators={{ required: true }}
        loading={isLoading}
      />

      <p>
        {i18n('v2.pages.organizations.section2.footer.sentence1')}{' '}
        <Link to={PATHS.TERMS_AND_CONDITIONS}>
          {i18n('v2.pages.organizations.section2.links.1')}
        </Link>{' '}
        {i18n('v2.pages.organizations.section2.footer.sentence2')}{' '}
        <Link to={PATHS.PRIVACY_POLICY}>
          {i18n('v2.pages.organizations.section2.links.2')}
        </Link>
      </p>

      <Button
        type='submit'
        variant='secondary'
        className='mb-6 mb-md-0 | w-100 w-md-auto'
        loading={isLoading}>
        {i18n('v2.pages.organizations.section2.buttons.send')}
      </Button>
    </Form>
  );
}
