import { Rest } from 'dawere-commons';
import { Utils } from 'dawere-uic';

function _processResponse(
  response: any,
  status: 'ASSIGNED' | 'UNASSIGNED' | 'USED' = 'UNASSIGNED'
) {
  let data = [];

  if (status === 'UNASSIGNED') {
    data = response.data.content.map((el: any) => {
      const { product } = el;

      return {
        id: el.id,
        type: product?.name,
        code: el.code,
        date: Utils.formatDate(el.purchasedDate, 'dd/mmm/yyyy')
      };
    });
  }

  if (status === 'ASSIGNED') {
    data = response.data.content.map((el: any) => {
      const { product, assignedTo } = el;

      return {
        id: el.id,
        type: product?.name,
        code: el.code,
        assignedTo: assignedTo,
        date: Utils.formatDate(el.purchasedDate, 'dd/mmm/yyyy')
      };
    });
  }

  if (status === 'USED') {
    data = response.data.content.map((el: any) => {
      const { product, assignedTo } = el;

      return {
        id: el.id,
        type: product?.name,
        code: el.code,
        assignedTo: assignedTo,
        date: Utils.formatDate(el.redeemDate, 'dd/mmm/yyyy')
      };
    });
  }

  return {
    data: data,
    totalPages: response.data.totalPages,
    totalRows: response.data.totalElements
  };
}

function list(
  organizationId: string,
  params: any = {},
  status: 'ASSIGNED' | 'UNASSIGNED' | 'USED' = 'UNASSIGNED',
  download: boolean = false
) {
  if (download) {
    const fileName = `cupones-${status
      .toLocaleLowerCase()
      .replaceAll('_', '-')}.csv`;

    return Rest.get(`/service-vouchers.csv`, {
      params: {
        ...params,
        organization_id: organizationId,
        status
      },
      responseType: 'blob'
    }).then((response) => {
      Utils.saveBlob(response.data, fileName);
    });
  }

  return Rest.get(`/service-vouchers`, {
    params: {
      ...params,
      organization_id: organizationId,
      status
    }
  }).then((response) => _processResponse(response, status));
}

function assign(organizationId: string, payload: any) {
  return Rest.post(
    `my-organizations/${organizationId}/service-vouchers/assign`,
    payload
  );
}

export const Coupons = {
  list,
  assign
};
