import { API } from 'api';
import { Session } from 'dawere-commons';
import { Utils } from 'dawere-uic';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

const QUERY_SELECTOR_CHAT =
  'iframe[title^="Botón para iniciar la ventana de mensajería"]';
const QUERY_SELECTOR_CLOSE = 'iframe[title^="Cerrar mensaje"]';
const QUERY_SELECTOR_MESSAGE = 'iframe[title^="Mensaje de la compañía"]';
const QUERY_SELECTOR_UNREAD = 'iframe[title^="Número de mensajes sin leer"]';

const { isEmpty } = Utils;

export function ZendeskChat() {
  const [allTrials, setAllTrials] = useState(false);
  const [enrollmentsTotal, setEnrollmentsTotal] = useState(0);

  useEffect(() => {
    const account = Session.getAccountInfo();

    if (account?.student) {
      API.Students.getAllEnrollments({ latest: true }).then(
        (data: any = []) => {
          setAllTrials(
            data.every((e: any) =>
              ['FREE_TRIAL', 'FREE_TRIAL_ENDED'].includes(e.status)
            )
          );
          setEnrollmentsTotal(data.length);

          const el1 = document.querySelector(QUERY_SELECTOR_CHAT);
          const el2 = document.querySelector(QUERY_SELECTOR_CLOSE);
          const el3 = document.querySelector(QUERY_SELECTOR_MESSAGE);
          const el4 = document.querySelector(QUERY_SELECTOR_UNREAD);

          el1?.setAttribute('class', isEmpty(data) ? 'd-none' : 'd-block');
          el2?.setAttribute(
            'class',
            isEmpty(data) ? 'd-none' : 'd-inline-block'
          );
          el3?.setAttribute(
            'class',
            isEmpty(data) ? 'd-none' : 'd-inline-block'
          );
          el4?.setAttribute(
            'class',
            isEmpty(data) ? 'd-none' : 'd-inline-block'
          );
        }
      );
    }

    return () => {
      const el1 = document.querySelector(QUERY_SELECTOR_CHAT);
      const el2 = document.querySelector(QUERY_SELECTOR_CLOSE);
      const el3 = document.querySelector(QUERY_SELECTOR_MESSAGE);
      const el4 = document.querySelector(QUERY_SELECTOR_UNREAD);

      el1?.setAttribute('class', 'd-none');
      el2?.setAttribute('class', 'd-none');
      el3?.setAttribute('class', 'd-none');
      el4?.setAttribute('class', 'd-none');
    };
  }, []);

  return (
    <>
      {!allTrials && enrollmentsTotal > 0 && (
        <Helmet>
          <script
            id='ze-snippet'
            src='https://static.zdassets.com/ekr/snippet.js?key=996d642f-7d07-4422-97ba-0d4c50b1c0e2'></script>
        </Helmet>
      )}
    </>
  );
}
