import {
  AltRouteOutlined,
  AutoStoriesOutlined,
  Celebration,
  PaymentOutlined,
  PersonOff,
  PublishedWithChanges,
  SchoolOutlined,
  ScienceOutlined
} from '@mui/icons-material';
import { Accordion, Col, IconChip, Row, Skeleton } from 'dawere-uic';
import { useCallback, useEffect, useState } from 'react';
import { useStudentDashboard } from '../hooks/useStudentDashboard';
import { EnrollmentPanel } from './panels/EnrollmentPanel';
import { ToEnrollPanel } from './panels/ToEnrollPanel';

export function InCourseTab() {
  const { getEnrollments } = useStudentDashboard();

  const [expandedId, setExpandedId] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [enrollments, setEnrollments] = useState<any[]>([]);

  const getIcon = useCallback((status) => {
    switch (status) {
      case 'UNCOMPLETED_PROFILE':
        return <PersonOff />;
      case 'RE_ENROLLMENT':
        return <PublishedWithChanges />;
      case 'DEBT':
        return <PaymentOutlined />;
      case 'GRADUATED':
        return <SchoolOutlined />;
      case 'CONCLUDED':
        return <Celebration />;
      case 'DEPRECATED':
        return <AltRouteOutlined />;
      case 'FREE_TRIAL_ENDED':
      case 'FREE_TRIAL':
        return <ScienceOutlined />;
      default:
        return <AutoStoriesOutlined />;
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);

    getEnrollments()
      .then((data: any = []) => {
        setEnrollments(data);

        if (data.length) {
          setExpandedId(data[0].program.id);
        }
      })
      .finally(() => setIsLoading(false));
  }, [getEnrollments]);

  if (isLoading) {
    return (
      <>
        {Array(3)
          .fill(0)
          .map((_, index) => (
            <Skeleton.Image key={index} height={48} className='mb-3' />
          ))}

        <Row>
          {Array(3)
            .fill(0)
            .map((_, index) => (
              <Col key={index} xs={12} md={4}>
                <Skeleton.Image className='mb-3' />
              </Col>
            ))}
        </Row>
      </>
    );
  }

  if (enrollments.length) {
    return (
      <>
        {enrollments.map((enrollment: any) => (
          <Accordion
            className={`list-subjects-${enrollment.program?.id}`}
            key={enrollment?.program?.id}
            expanded={enrollment.program.id === expandedId}
            onToggle={() => setExpandedId(enrollment.program.id)}
            summary={
              <IconChip icon={getIcon(enrollment.status)}>
                {enrollment.program?.name}{' '}
                {enrollment.school?.name ? `— ${enrollment.school?.name}` : ''}
              </IconChip>
            }>
            <EnrollmentPanel enrollment={enrollment} />
          </Accordion>
        ))}
      </>
    );
  }
  // Inscripcion: no hay programas que mostrar
  return <ToEnrollPanel />;
}
