import { API } from 'api';
import { DocumentUploader } from 'components';
import { i18n } from 'dawere-commons';
import { Col, Divider, Empty, Previewer, Row, Skeleton } from 'dawere-uic';
import { useEffect, useState } from 'react';
import { useStudentDashboard } from '../../hooks/useStudentDashboard';

export function DocumentPanel({ entry, isExpanded = false }: any) {
  const { studentId, readOnly } = useStudentDashboard();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (studentId && entry?.program?.id && isExpanded && !data) {
      setIsLoading(true);

      API.Students.getDocuments(studentId, entry?.program?.id)
        .then((response: any) => setData(response))
        .finally(() => setIsLoading(false));
    }
  }, [entry?.program?.id, studentId, isExpanded, data]);

  return isLoading ? (
    <Row>
      {Array(6)
        .fill(0)
        .map((_, index) => (
          <Col key={index} xs={12} md={6} lg={4} className='mb-4'>
            <Skeleton.Previewer />
          </Col>
        ))}
    </Row>
  ) : (
    <>
      {data?.total ? (
        <>
          <Row>
            {data?.mandatory?.length ? (
              <>
                <Col xs={12}>
                  <Divider section>
                    {i18n('v2.pages.myDocuments.mandatory')}
                  </Divider>
                </Col>

                {data?.mandatory?.map((document: any) => (
                  <Col key={document.id} xs={12} md={6} lg={4} className='mb-4'>
                    {readOnly ? (
                      <Previewer
                        id={document.id}
                        name={document.name}
                        status={document.status}
                        src={document.src}
                        physical={document.physical}
                        reasonsToReject={document.reasonsToReject}
                        onAskForHelp={() => API.Zendesk.go()}
                      />
                    ) : (
                      <DocumentUploader
                        id={document.id}
                        name={`did${document.id}`}
                        title={document.name}
                        status={document.status}
                        src={document.src}
                        physical={document.physical}
                        reasonsToReject={document.reasonsToReject}
                      />
                    )}
                  </Col>
                ))}
              </>
            ) : null}
          </Row>

          <Row>
            {data?.optional?.length ? (
              <>
                <Col xs={12}>
                  <Divider section>
                    {i18n('v2.pages.myDocuments.optional')}
                  </Divider>
                </Col>

                {data?.optional?.map((document: any) => (
                  <Col key={document.id} xs={12} md={6} lg={4} className='mb-4'>
                    {readOnly ? (
                      <Previewer
                        id={document.id}
                        name={document.name}
                        status={document.status}
                        src={document.src}
                        physical={document.physical}
                        reasonsToReject={document.reasonsToReject}
                        onAskForHelp={() => API.Zendesk.go()}
                      />
                    ) : (
                      <DocumentUploader
                        id={document.id}
                        name={`did${document.id}`}
                        title={document.name}
                        status={document.status}
                        src={document.src}
                        physical={document.physical}
                        reasonsToReject={document.reasonsToReject}
                      />
                    )}
                  </Col>
                ))}
              </>
            ) : null}
          </Row>
        </>
      ) : (
        <Empty />
      )}
    </>
  );
}
