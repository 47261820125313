import { i18n } from 'dawere-commons';
import { Block, Button, Col } from 'dawere-uic';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { getFullCountryInfo } from 'utils';
import { ASSETS } from 'values';

export function WelcomeSection() {
  const { iso3: country } = getFullCountryInfo();

  return (
    <Block narrow align='center'>
      <Col md={6}>
        {country === 'USA' ? null : (
          <h5 className='text-primary text-uppercase'>
            {i18n('v2.pages.organizations.section1.slogan')}
          </h5>
        )}
        <h1 className='mb-4'>
          {i18n('v2.pages.organizations.section1.title')}
        </h1>
        {country === 'USA' ? null : (
          <h4 className='text-muted'>
            {i18n('v2.pages.organizations.section1.subtitle')}
          </h4>
        )}
        <div className='mt-4'>
          <Button
            as={Link}
            to={`${PATHS.REGISTER}?tab=2`}
            variant='outline'
            className='mb-2 mr-md-2 | w-100 w-md-auto | click_register'>
            {i18n('v2.pages.organizations.section1.buttons.signup')}
          </Button>
          <Button
            className='mb-6 mb-lg-2 | w-100 w-md-auto'
            as={Link}
            to={PATHS.LOGIN}>
            {i18n('v2.pages.organizations.section1.buttons.login')}
          </Button>
        </div>
      </Col>

      <Col md={6}>
        <img
          src={ASSETS.ILLUSTRATIONS.CAREER_CYCLE}
          alt={i18n('v2.pages.organizations.section1.title')}
          className='img-fluid'
        />
      </Col>
    </Block>
  );
}
