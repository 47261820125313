import { i18n } from 'dawere-commons';
import { Block, Button, Col } from 'dawere-uic';
import { useProgramFreeTrial } from 'hooks';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { getEnvironment, getFullCountryInfo } from 'utils';
import { ASSETS } from 'values';

export function EnrollSection() {
  const { iso3: country } = getFullCountryInfo();
  const programId = i18n('v2.pages.home.mainProgramId.' + getEnvironment());

  const trialInfo = useProgramFreeTrial(programId);

  if (trialInfo?.available) {
    return (
      <Block align='center'>
        <Col md={6}>
          <img
            src={ASSETS.ILLUSTRATIONS.STUDENT_HAPPY_READING}
            alt={i18n('v2.pages.home.section4.title')}
            className='img-fluid mb-4 mb-md-0'
          />
        </Col>

        <Col md={6}>
          <h2 className='mb-4'>{i18n('v2.pages.home.section4.title')}</h2>
          <p
            className='mb-4'
            dangerouslySetInnerHTML={{
              __html: i18n('v2.pages.home.section4.text', {
                days: trialInfo?.days,
                program: trialInfo?.name
              })
            }}
          />

          {country === 'USA' ? (
            <Button
              as={Link}
              to={PATHS.get('CATALOG')}
              variant='outline'
              className='w-100 w-md-auto'>
              {i18n('v2.pages.home.section1.buttons.moreCatalog')}
            </Button>
          ) : (
            <Button
              as={Link}
              to={trialInfo?.route}
              variant='tertiary'
              className='mb-2 mb-md-0 mr-md-2 | w-100 w-md-auto'>
              {trialInfo?.text}
            </Button>
          )}
        </Col>
      </Block>
    );
  }

  return null;
}
