import { i18n, Session } from 'dawere-commons';
import { Block, Col } from 'dawere-uic';
import { PageWrapper } from 'pages';
import { getSEO } from 'utils';
import { PermissionsProvider } from './PermissionsProvider';
import { MyAuthorizedOrganizations } from './sections/MyAuthorizedOrganizations';
import { MyOrganizations } from './sections/MyOrganizations';
import { MyStudents } from './sections/MyStudents';
import { MyTutors } from './sections/MyTutors';
import { PendingInvitations } from './sections/PendingInvitations';

/**
 * Path: /gestion-permisos
 * Key: PERMISSIONS
 * @returns {React.ReactElement}
 */
export default function PermissionsPage() {
  const profiles = Session.getProfiles();

  return (
    <PageWrapper>
      {getSEO('v2.pages.permissions.seo', true)}

      <Block narrow>
        <Col xs={12}>
          <h2 className='mb-2'>{i18n('v2.pages.permissions.title')}</h2>
        </Col>

        <PermissionsProvider>
          <PendingInvitations />

          {profiles?.organization ? <MyOrganizations /> : null}

          {profiles?.student ? (
            <>
              <MyTutors />

              <MyAuthorizedOrganizations />
            </>
          ) : null}

          {profiles?.tutor ? <MyStudents /> : null}
        </PermissionsProvider>
      </Block>
    </PageWrapper>
  );
}
