import { Block, Col, Skeleton } from 'dawere-uic';
import { SecondarySlider } from '../components/SecondarySlider';
import { useCatalog } from '../hooks/useCatalog';

export function SecondarySection() {
  const { isLoading, catalog } = useCatalog();

  if (isLoading) {
    return (
      <>
        {Array(1)
          .fill({})
          .map((_, i: number) => (
            <Block key={i} narrow>
              <Col>
                <Skeleton.Title />
                <Skeleton.Paragraph />
                <Skeleton.Image />
              </Col>
            </Block>
          ))}
      </>
    );
  }

  return (
    <>
      {catalog?.secondary?.map((entry: any) => (
        <SecondarySlider key={entry.id} entry={entry} />
      ))}
    </>
  );
}
