import { QueryKey } from '@tanstack/react-query';
import { Session } from 'dawere-commons';
import { MS_TO_MIN } from './time';

export const DEFAULT_STALE_TIME = MS_TO_MIN * 10;

export enum QUERY_KEYS {
  PROGRAM_DETAILS = 'PROGRAM_DETAILS',
  PROGRAM_PRICES = 'PROGRAM_PRICES',
  PROGRAM_CALENDARS = 'PROGRAM_CALENDARS',
  PROGRAM_PERIODS = 'PROGRAM_PERIODS'
}

export function createQueryKey(key: QUERY_KEYS, ...args: unknown[]): QueryKey {
  const keys = [key, Session.getAccountInfo()?.id].concat(args);
  return keys;
}
