import { i18n } from 'dawere-commons';
import {
  Affix,
  Block,
  Col,
  Iframe,
  Skeleton,
  useMatchMedia,
  Visible
} from 'dawere-uic';
import { getSEO } from 'utils';
import { useProgram } from '../hooks/useProgram';

export function Heading({ isCourse = false, children }: any) {
  const { isDesktop, isTablet } = useMatchMedia();
  const { isLoading, program } = useProgram();

  return (
    <>
      {getSEO(
        {
          title: program?.seoTitle,
          description: program?.seoDescription,
          keywords: program?.seoKeywords,
          ogTitle: program?.seoOgTitle,
          ogDescription: program?.seoOgDescription,
          ogImage: program?.seoImage
        },
        isCourse
      )}

      <Visible xs sm>
        {isLoading ? (
          <div className='p-3 pb-0'>
            <Skeleton.Image height={200} />
          </div>
        ) : (
          <>
            {program?.introductoryVideo ? (
              <Iframe height={220} src={program?.introductoryVideo} />
            ) : null}
          </>
        )}
      </Visible>

      <Block>
        <Col xs={12} md={5} lg={7} id='program-hero'>
          {isLoading ? (
            <Skeleton.Title />
          ) : (
            <>
              {isCourse ? null : (
                <div className='fs-1 text-uppercase text-primary'>
                  {i18n('v2.pages.program.section1.pretitle')}
                </div>
              )}
              <h1 className='mb-4'>{program?.name}</h1>
            </>
          )}
          {isLoading ? (
            <>
              <Skeleton.Paragraph count={3} />
            </>
          ) : (
            <>
              <div
                dangerouslySetInnerHTML={{
                  __html: program?.longDescription
                }}
              />

              {/* TODO: remove USA condition */}
              {/* <Button
                onClick={handleClick}
                className={`mt-2 | w-100 w-md-auto ${
                  account?.organization ? 'mr-0 mr-md-2 ' : ''
                }`}
                href={
                  program?.friendlyPath ===
                  'bachillerato-virtual-estados-unidos'
                    ? 'https://app.dawere.us/#!/?register=true'
                    : temporalRedirect('')
                }>
                {i18n('v2.pages.program.section1.buttons.tryIt')}
              </Button> */}

              {/* {account?.student || !account ? (
                <>
                  {program?.hasTrial ? (
                    <Button
                      variant={account?.organization ? 'outline' : undefined}
                      onClick={handleClick}
                      className={`mt-2 | w-100 w-md-auto ${
                        account?.organization ? 'mr-0 mr-md-2 ' : ''
                      }`}
                      href={
                        program?.friendlyPath ===
                        'bachillerato-virtual-estados-unidos'
                          ? 'https://app.dawere.us/#!/?register=true'
                          : temporalRedirect('')
                      }>
                      {i18n('v2.pages.program.section1.buttons.tryIt')}
                    </Button>
                  ) : null}
                </>
              ) : null} */}

              {/* {account?.organization ? (
                <>
                  {program?.hasTrial ? (
                    <Button
                      className='mt-2 | w-100 w-md-auto'
                      as={Link}
                      to={`${PATHS.BUY_COUPONS}?p=${programId}`}>
                      {i18n(
                        'v2.pages.organizationDashboard.tabs.1.empty.buttons.buy'
                      )}
                    </Button>
                  ) : null}
                </>
              ) : null} */}
            </>
          )}
        </Col>
        <Visible md lg xl>
          <Col md={7} lg={5}>
            <Affix
              enabled={isDesktop || isTablet}
              top={110}
              bottomBoundary='#program-hero'>
              {children}
            </Affix>
          </Col>
        </Visible>
      </Block>
    </>
  );
}
