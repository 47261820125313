import { Utils } from 'dawere-uic';
import { OrganizationContext } from 'providers';
import { useContext } from 'react';

export const useOrganization = () => {
  const context = useContext(OrganizationContext);

  if (Utils.isEmpty(context)) {
    throw new Error(
      '"useOrganization" must be used within a OrganizationProvider'
    );
  }

  return context;
};
