import { Session, i18n } from 'dawere-commons';
import { Button } from 'dawere-uic';
import { useProgramFreeTrial } from 'hooks';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { QPARAMS } from 'values';

export function EnrollButton({
  programId,
  onClick = () => {},
  isLoadingMyPrograms,
  program,
  priceSelected,
  status,
  className = 'w-100',
  earlyPayment = false,
  ...rest
}: any) {
  const trialInfo = useProgramFreeTrial(
    programId,
    earlyPayment,
    status === 'ACTIVE' || ['FREE_TRIAL', 'FREE_TRIAL_ENDED'].includes(status)
  );

  if (
    status === 'ACTIVE' ||
    ['FREE_TRIAL', 'FREE_TRIAL_ENDED'].includes(status)
  ) {
    return (
      <Button
        variant='secondary'
        as={Link}
        loading={isLoadingMyPrograms}
        to={PATHS.get(
          'STUDENTS_DASHBOARD',
          {},
          { [QPARAMS.Program]: program?.id }
        )}
        className={className}
        {...rest}>
        {i18n('v2.pages.program.details.buttons.studentDashboard')}
      </Button>
    );
  }

  return (
    <>
      <Button
        onClick={onClick}
        variant='outline'
        as={Link}
        loading={isLoadingMyPrograms}
        to={
          Session.getAccountInfo()
            ? PATHS.get(
                'ENROLL',
                {
                  programId: program?.id,
                  currencyId: priceSelected?.currency?.id
                },
                { [QPARAMS.EarlyPayment]: earlyPayment ? 1 : 0 }
              )
            : PATHS.get('REGISTER', null, {
                [QPARAMS.Redirect]: encodeURIComponent(
                  PATHS.get(
                    'ENROLL',
                    {
                      programId: program?.id,
                      currencyId: priceSelected?.currency?.id
                    },
                    { [QPARAMS.EarlyPayment]: earlyPayment ? 1 : 0 }
                  )
                )
              })
        }
        className={className}
        {...rest}>
        {i18n('v2.pages.program.details.buttons.enroll')}
      </Button>

      {trialInfo?.available && (
        <Button as={Link} to={trialInfo?.route} variant='tertiary'>
          {trialInfo?.text}
        </Button>
      )}
    </>
  );
}
