import { API } from 'api';
import { i18n } from 'dawere-commons';
import {
  AutoComplete,
  Avatar,
  AvatarChip,
  Badge,
  Button,
  Form,
  Modal,
  toast,
  Utils
} from 'dawere-uic';
import { useCallback, useState } from 'react';

const AutoCompleteOption = ({ children, data, ...rest }: any) => {
  return (
    <AutoComplete.Option {...rest}>
      {data?.__isNew__ ? (
        <>{children}</>
      ) : (
        <AvatarChip
          size={40}
          src={data.user?.picturePath}
          icon={Utils.getInitialLetters(data.user?.name, data.user?.lastName)}
          gap={2}>
          <strong className='d-block'>
            {Utils.getFullname(data.user?.name, data.user?.lastName)}
          </strong>
          <small className='d-block text-muted'>{data.user?.email}</small>
        </AvatarChip>
      )}
    </AutoComplete.Option>
  );
};

export function ModalAssignTeacher({
  teachers = [],
  organizationId,
  subjectTo,
  open,
  onClose,
  onSuccess = () => {}
}: any) {
  const [email, setEmail] = useState<string>('');
  const [tags, setTags] = useState<string[]>([]);
  const [emails, setEmails] = useState<string[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = useCallback(
    (data: any) => {
      const newEmail = data.user?.email;

      // Reset input
      setEmail(newEmail);
      setTimeout(() => setEmail(''), 1);

      if (tags.includes(newEmail)) {
        return;
      }

      if (users.find((item: any) => item.id === data.user?.id)) {
        return;
      }

      setUsers((state) => [
        ...state,
        {
          id: data.user?.id,
          email: newEmail
        }
      ]);
      setTags((state) => [...state, newEmail]);
    },
    [tags, users]
  );

  const handleCreate = useCallback(
    (data: any) => {
      const newEmail = data.toLowerCase();

      if (tags.includes(newEmail)) {
        return;
      }

      if (emails.includes(newEmail)) {
        return;
      }

      setEmails((state) => [...state, newEmail]);
      setTags((state) => [...state, newEmail]);
    },
    [emails, tags]
  );

  const handleRemoveEmail = useCallback(
    (email: string) => {
      setTags(tags.filter((item: string) => item !== email));
      setEmails(emails.filter((item: string) => item !== email));
      setUsers(users.filter((item: any) => item.email !== email));
    },
    [emails, tags, users]
  );

  const handleClose = useCallback(() => {
    setEmails([]);
    setUsers([]);
    setTags([]);
    onClose(false);
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    setIsLoading(true);

    API.Organizations.Teachers.assign(organizationId, {
      emails: emails,
      teachers: users.map((item: any) => item.id),
      program: subjectTo?.program,
      subjectId: subjectTo?.id
    })
      .then((response) => {
        if (tags.length === 1) {
          toast.success(
            i18n('v2.pages.teachers.modals.add.success', { email: tags[0] })
          );
        } else {
          toast.success(
            i18n('v2.pages.teachers.modals.add.successMultiple', {
              qty: tags.length
            })
          );
        }
        setEmails([]);
        setUsers([]);
        setTags([]);
        onClose(false);
        onSuccess();
      })
      .finally(() => setIsLoading(false));
  }, [
    emails,
    onClose,
    onSuccess,
    organizationId,
    subjectTo?.id,
    subjectTo?.program,
    tags,
    users
  ]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closable={isLoading ? false : true}
      keyboard={isLoading ? false : true}
      title={
        <>
          <Avatar size={60} src={subjectTo?.thumbnail} />

          <div className='mt-2'>
            {i18n('v2.pages.teachers.modals.add.title')}
          </div>
        </>
      }
      backdrop='static'>
      <p>
        {i18n('v2.pages.teachers.modals.add.textAssign')}{' '}
        <strong>
          {subjectTo?.title} - {subjectTo?.period?.name}
        </strong>
        .
      </p>

      <Form onSubmit={handleSubmit}>
        <AutoComplete
          name='email'
          customOption={AutoCompleteOption}
          placeholder={i18n('v2.pages.teachers.modals.add.placeholderAssign')}
          disabled={isLoading}
          creatable
          options={teachers.map((item: any) => ({
            isDisabled: tags.includes(item.user?.email),
            value: item.user?.email,
            label: Utils.getFullname(item.user?.name, item.user?.lastName),
            ...item
          }))}
          onIsNewValue={(inputValue: string, options: any[]) => {
            const found = options
              .map((item: any) => item.user?.email)
              .find((item: string) =>
                item.toLowerCase().includes(inputValue.toLowerCase())
              );

            return Utils.isEmail(inputValue) && !found;
          }}
          value={email}
          onChange={handleChange}
          onCreate={handleCreate}
          menuPlacement='top'
          maxMenuHeight={180}
        />

        <div className='mb-4'>
          {tags.map((email: string) => (
            <Badge
              key={email}
              id={email}
              closable
              onClose={handleRemoveEmail}
              disabled={isLoading}>
              {email}
            </Badge>
          ))}
        </div>

        <Button
          type='submit'
          block
          disabled={tags.length === 0}
          loading={isLoading}>
          {i18n('v2.pages.teachers.modals.add.button')}
        </Button>
      </Form>
    </Modal>
  );
}
