import { Button } from 'dawere-uic';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';

export function OpenProgramButton({ program, replacement }: any) {
  return (
    <Button
      block
      variant='outline'
      className='mb-2'
      as={Link}
      to={PATHS.get('PROGRAM', {
        programId: replacement?.id
      })}>
      {replacement?.name}
    </Button>
  );
}
