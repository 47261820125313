import { i18n } from 'dawere-commons';
import { Card, Dropdown, Flex, Icons, Utils } from 'dawere-uic';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const IconStyled = styled.div`
  background-color: var(--dw-color-tertiary);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;

  svg {
    color: var(--dw-color-white);
    font-size: 1.6em;
  }
`;

const CardStyled = styled(Card)`
  padding: var(--dw-spacer-4);
  margin-bottom: var(--dw-spacer-2);

  :hover {
    background-color: var(--dw-color-cultured);
  }

  &.read {
    background-color: var(--dw-color-cultured);

    ${IconStyled} {
      background-color: var(--dw-color-muted);
    }
  }
`;

export function NotificationCard({
  data,
  onMarkAsRead = (e: any) => {},
  onMarkAsOpen = (e: any) => {}
}: any) {
  return (
    <CardStyled
      className={Utils.styles(
        data?.read && 'read',
        'translate-left-animation'
      )}>
      <Flex align='center' gap={4}>
        {data?.icon ? (
          <Flex kept>
            <IconStyled className='shadow'>{data?.icon}</IconStyled>
          </Flex>
        ) : null}
        <Flex full direction='column'>
          {data?.title ? (
            <div className='fw-500'>
              {data?.to ? (
                <NavLink to={data?.to} onClick={() => onMarkAsOpen(data)}>
                  {data?.title}
                </NavLink>
              ) : (
                <>{data?.title}</>
              )}
            </div>
          ) : null}

          <p className='m-0'>
            {!data?.title && data?.to ? (
              <NavLink to={data?.to} onClick={() => onMarkAsOpen(data)}>
                {data?.description}
              </NavLink>
            ) : (
              <>{data?.description}</>
            )}
          </p>
          <small className='text-muted fw-normal'>
            {Utils.timeAgo(data?.time)}
          </small>
        </Flex>
        {data.read ? null : (
          <Flex kept>
            <Dropdown
              className='dwui-notification-actions'
              toggle={<Icons.Dots className='anchor' />}
              align='right'>
              <Dropdown.Item
                disabled={data?.read}
                onClick={() => onMarkAsRead(data)}>
                {i18n('ui.components.notifications.markAsRead')}
              </Dropdown.Item>
            </Dropdown>
          </Flex>
        )}
      </Flex>
    </CardStyled>
  );
}
