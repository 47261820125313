import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  #root {
    width: 100vw;
  }

  .grecaptcha-badge {
    visibility: hidden !important;
  }

  .btn-tour {
    background-color: transparent;
    color: var(--dw-color-primaryShade);
    border: solid 2px transparent;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    height: 48px;
    outline: none;
    padding: 0.7rem 1rem 0.656rem;
    transition: all 0.15s ease-in-out;
    display: inline-grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: center;
    grid-gap: 0.5rem;
    text-decoration: none;
    line-height: 1.5;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    text-align: center;
  }

  .btn-tour:hover {
    color: var(--dw-color-primaryShade);
    background-color: #ffffff;
    box-shadow: 0px 4px 5px rgba(0,0,0,0.2),0px 3px 14px rgba(0,0,0,0.12),0px 8px 10px rgba(0,0,0,0.14);
    opacity: 1;
    text-decoration: none;
  }

  #MathJax_Message {
    display: none !important;
  }
`;
