import esDO from './es-DO';

const enDO = {
  ...esDO,
  lang: `en-DO,es`,
  shortLang: 'en-DO'
};

export default enDO;

export { enDO };
