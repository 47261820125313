import { Rest } from 'dawere-commons';
import { Utils } from 'dawere-uic';

function _processStudentResponse(response: any) {
  let data = [];

  data = response.data.content.map((el: any) => {
    const fullName = Utils.getFullname(el.name, el.lastName);

    return {
      fullName,
      ...el
    };
  });

  return {
    data: data,
    totalPages: response.data.totalPages,
    totalRows: response.data.totalElements
  };
}

function getStudents(params: any = {}) {
  return Rest.get('/me/students', {
    params: {
      ...params
    }
  }).then((response: any) => {
    return _processStudentResponse(response);
  });
}

function list(params: any = {}) {
  return Rest.get('/me/tutors', {
    params: {
      ...params
    }
  }).then((response: any) => {
    return response.data;
  });
}

function inviteStudent(data: any = {}) {
  return Rest.post('tutored-invitations', data);
}

function getStudentInvitations() {
  return Rest.get('tutored-invitations');
}

function resignStudent(id: string) {
  return Rest.delete(`me/students/${id}`);
}

function inviteTutor(data: any = {}) {
  return Rest.post('tutor-invitations', data);
}

function getTutorInvitations() {
  return Rest.get('tutor-invitations');
}

function resignTutor(id: string) {
  return Rest.delete(`me/tutors/${id}`);
}

export const Tutors = {
  list,
  getStudents,
  inviteStudent,
  getStudentInvitations,
  resignStudent,
  inviteTutor,
  getTutorInvitations,
  resignTutor
};
