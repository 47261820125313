import { API } from 'api';
import { Block, Utils, Visible, useRouter } from 'dawere-uic';
import React, { useCallback, useEffect, useState } from 'react';
import { PATHS } from 'router';
import { QPARAMS } from 'values';
import { EnrollmentWizard } from './sections/EnrollmentWizard';
import { SummarySidebar } from './sections/SummarySidebar';

export interface FreeEnrollProps {
  gateways: any;
  summary: any;
  setSummary: React.Dispatch<React.SetStateAction<any>>;
  billingInfo: any;
  setBillingInfo: React.Dispatch<React.SetStateAction<any>>;
  isEarlyPayment: boolean;
  setIsEarlyPayment: React.Dispatch<React.SetStateAction<boolean>>;

  // User selection
  program: any;

  // Feedback
  isLoadingProgram: boolean;
  isLoadingGateways: boolean;
  isLoadingSummary: boolean;

  // Methods
  isBillingInfoCompleted: () => boolean;
  getTaxData: (data?: any) => any;
  track: (event: string, extraFields?: any[], data?: any) => void;

  isPaid: boolean;
  setIsPaid: React.Dispatch<React.SetStateAction<boolean>>;

  walletBalance: number | null;
  onInstallmentsChange: (installments?: any[]) => void;

  redirectTo?: string;
}

export const FreeEnrollContext = React.createContext<FreeEnrollProps>({
  gateways: null,
  summary: null,
  setSummary: () => {},
  billingInfo: {},
  setBillingInfo: () => {},
  isEarlyPayment: false,
  setIsEarlyPayment: () => {},

  program: null,

  isLoadingProgram: true,
  isLoadingGateways: true,
  isLoadingSummary: false,

  isBillingInfoCompleted: () => false,
  getTaxData: (data?: any) => {},
  track: () => {},

  isPaid: false,
  setIsPaid: () => {},

  walletBalance: null,
  onInstallmentsChange: (installments: any[] = []) => {},

  redirectTo: ''
});

export function FreeEnrollProvider({
  orderId,
  programId,
  studentId,
  redirectTo = PATHS.get('STUDENTS_DASHBOARD', {}, { [QPARAMS.Tab]: 3 })
}: any) {
  const { push } = useRouter();

  const [program, setProgram] = useState<any>(null);

  // Basic info
  const [gateways, setGateways] = useState<any>(null);
  const [billingInfo, setBillingInfo] = useState<any>({});
  const [summary, setSummary] = useState<any>(null);
  const [isEarlyPayment, setIsEarlyPayment] = useState(false);

  const [isPaid, setIsPaid] = useState(false);

  // Loadings
  const [isLoadingProgram, setIsLoadingProgram] = useState<boolean>(true);
  const [isLoadingGateways, setIsLoadingGateways] = useState(true);
  const [isLoadingSummary, setIsLoadingSummary] = useState<boolean>(false);

  // Wallet
  const [walletBalance, setWalletBalance] = useState<number | null>(null);

  const track = useCallback(
    (eventName: string, extraFields: any = [], data: any = {}) => {
      // if (!isReEnrolment) {
      //   const foundCalendar = calendars.find((c: any) => calendar === c.id);
      //   const foundPeriod =
      //     period === 'Empty'
      //       ? { name: i18n('v2.pages.enrolment.steps.2.lastOption') }
      //       : periods?.find((p: any) => period === p.id);
      //   const periodAttr = extraFields.includes('period')
      //     ? { period: foundPeriod?.name }
      //     : {};
      //   const paymentTypeAttr = extraFields.includes('payment_type')
      //     ? { payment_type: isEarlyPayment ? 'completo' : 'fraccionado' }
      //     : {};
      //   trackEvent(eventName, {
      //     program_name: program?.name,
      //     calendar: i18n('v2.pages.enrolment.steps.1.format', {
      //       start: Utils.formatDate(foundCalendar?.dateInit, 'dd/mmm/yyyy'),
      //       end: Utils.formatDate(foundCalendar?.dateEnd, 'dd/mmm/yyyy')
      //     }),
      //     ...periodAttr,
      //     ...paymentTypeAttr,
      //     ...data
      //   });
      // }
    },
    []
  );

  const isBillingInfoCompleted = useCallback(() => {
    return true;
  }, []);

  const getTaxData = useCallback(
    (data: any = null) => {
      if (data) {
        return {
          address: data.billingAddress,
          name: data.name,
          postalCode: data.postalCode,
          tin: data.tin,
          township: {
            id: data.township?.value
          }
        };
      }

      return {
        address: billingInfo.billingAddress,
        name: billingInfo.name,
        postalCode: billingInfo.postalCode,
        tin: billingInfo.tin,
        township: {
          id: billingInfo.township?.value
        }
      };
    },
    [
      billingInfo.billingAddress,
      billingInfo.name,
      billingInfo.postalCode,
      billingInfo.tin,
      billingInfo.township?.value
    ]
  );

  const onInstallmentsChange = useCallback((installments: any[] = []) => {
    setSummary((state: any) => {
      state.installments = installments;
      return { ...state };
    });
  }, []);

  // Get program
  useEffect(() => {
    if (programId && !program) {
      setIsLoadingProgram(true);

      API.Programs.get(programId)
        .then((data) => {
          setProgram(data);
        })
        .catch(() => {
          setIsPaid(true);
          push(PATHS.STUDENTS_DASHBOARD);
        })
        .finally(() => setIsLoadingProgram(false));
    }
  }, [program, programId, push]);

  // Load order
  useEffect(() => {
    if (orderId) {
      setIsLoadingSummary(true);

      API.Enrollment.getOrder(orderId)
        .then((data) => {
          setSummary(data);
          setIsEarlyPayment(data.numberOfInstallments === 1);

          if (Utils.isEmpty(billingInfo)) {
            setBillingInfo(createBillingInfo(data));
          }
        })
        .catch(() => {
          setIsPaid(true);
          push(redirectTo);
        })
        .finally(() => setIsLoadingSummary(false));
    }
  }, [billingInfo, orderId, push, redirectTo]);

  // Get gateways
  useEffect(() => {
    if (summary?.currency?.id) {
      setIsLoadingGateways(true);

      API.Cart.getGateways(summary?.currency?.id)
        .then((response) => {
          setGateways(response);
        })
        .finally(() => setIsLoadingGateways(false));
    }
  }, [summary?.currency?.id]);

  // Wallet
  useEffect(() => {
    if (summary?.id) {
      API.Payments.getWalletBalance(summary?.id).then((response) => {
        setWalletBalance(response);
      });
    }
  }, [summary?.id]);

  return (
    <FreeEnrollContext.Provider
      value={{
        billingInfo,
        setBillingInfo,
        isBillingInfoCompleted,
        gateways,
        summary,
        setSummary,
        isLoadingGateways,
        isPaid,
        setIsPaid,
        isEarlyPayment,
        setIsEarlyPayment,
        isLoadingSummary,
        getTaxData,
        track,
        walletBalance,
        onInstallmentsChange,
        redirectTo,
        program,
        isLoadingProgram
      }}>
      <div id='summary-content'>
        <Block narrow align='stretch'>
          <EnrollmentWizard />

          <Visible md lg xl>
            <SummarySidebar />
          </Visible>
        </Block>
      </div>
    </FreeEnrollContext.Provider>
  );
}

function createBillingInfo(data: any) {
  return {
    tin: data?.tin,
    name: data?.billTo,
    postalCode: data?.billingPostalCode,
    billingAddress: data?.billingAddress,
    country: data?.billingCountry
      ? {
          ...data?.billingCountry,
          value: data?.billingCountry.id,
          label: data?.billingCountry?.name
        }
      : null,
    state: data?.billingState
      ? {
          ...data?.billingState,
          value: data?.billingState.id,
          label: data?.billingState?.name
        }
      : null,
    township: data?.billingTownship
      ? {
          ...data?.billingTownship,
          value: data?.billingTownship.id,
          label: data?.billingTownship?.name
        }
      : null
  };
}
