import { i18n, Session } from 'dawere-commons';
import {
  Button,
  Callout,
  Col,
  Dropdown,
  Flex,
  Icons,
  Iframe,
  Row,
  Skeleton,
  Toggle,
  Tooltip,
  useCollapse,
  Utils,
  Visible
} from 'dawere-uic';
import { useProgramStatus } from 'hooks';
import { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { BooleanParam, useQueryParams } from 'use-query-params';
import { trackEvent } from 'utils';
import { QPARAMS } from 'values';
import { BuyCouponsButton } from '../components/BuyCouponsButton';
import { EnrollButton } from '../components/EnrollButton';
import { useProgram } from '../hooks/useProgram';

const DetailWrapperStyled = styled.div`
  background-color: var(--dw-color-white);
  overflow: hidden;

  @media (min-width: 768px) {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.161);
    border-radius: 8px;
  }
`;

export function Details({ programId }: any) {
  const account = Session.getAccountInfo();

  const {
    isLoading,
    calendars,
    product,
    priceSelected,
    setPriceSelected,
    program,
    currencyMenu
  } = useProgram();
  const { status, isLoading: isLoadingMyPrograms } =
    useProgramStatus(programId);

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  const [queryString, setQueryString] = useQueryParams({
    [QPARAMS.EarlyPayment]: BooleanParam
  });

  const handleSelection = useCallback(
    (e: any) => {
      setPriceSelected(e);
    },
    [setPriceSelected]
  );

  const handleClick = useCallback(() => {
    trackEvent('button_click_inscribete', { position: 1 });
  }, []);

  const renderButtons = useCallback(() => {
    // Sin sesión
    if (!account) {
      return (
        <EnrollButton
          programId={programId}
          onClick={handleClick}
          isLoadingMyPrograms={isLoadingMyPrograms}
          program={program}
          priceSelected={priceSelected}
          earlyPayment={queryString[QPARAMS.EarlyPayment]}
        />
      );
    }

    // Cuando ha iniciado sesion
    // Si es estudiante y organizacion
    if (account?.student && account?.organization) {
      return (
        <>
          <EnrollButton
            programId={programId}
            onClick={handleClick}
            isLoadingMyPrograms={isLoadingMyPrograms}
            program={program}
            priceSelected={priceSelected}
            status={status}
            earlyPayment={queryString[QPARAMS.EarlyPayment]}
          />

          <BuyCouponsButton programId={programId} />
        </>
      );
    }

    // Si es estudiante y no es organizacion
    if (account?.student && !account?.organization) {
      return (
        <EnrollButton
          programId={programId}
          onClick={handleClick}
          isLoadingMyPrograms={isLoadingMyPrograms}
          program={program}
          priceSelected={priceSelected}
          status={status}
          earlyPayment={queryString[QPARAMS.EarlyPayment]}
        />
      );
    }

    // Si no es estudiante y es organizacion
    if (!account?.student && account?.organization) {
      return <BuyCouponsButton programId={programId} />;
    }

    return null;
  }, [
    account,
    handleClick,
    isLoadingMyPrograms,
    priceSelected,
    program,
    programId,
    queryString,
    status
  ]);

  useEffect(() => {
    if (product && !product.acceptInstallments) {
      setQueryString({ [QPARAMS.EarlyPayment]: true }, 'pushIn');
    }
  }, [product]);

  return (
    <DetailWrapperStyled>
      <Visible md lg xl>
        {isLoading ? (
          <div className='p-2'>
            <Skeleton.Image height={200} />
          </div>
        ) : (
          <>
            {program?.introductoryVideo ? (
              <Iframe
                height={220}
                src={program?.introductoryVideo}
                className='rounded-top-1'
              />
            ) : null}
          </>
        )}
      </Visible>

      <div className='p-4'>
        {product?.acceptInstallments && (
          <Toggle
            className='mb-2'
            loading={isLoading}
            block
            items={[
              {
                label: i18n('v2.pages.program.details.tabs.2.title'),
                value: 0
              },
              { label: i18n('v2.pages.program.details.tabs.1.title'), value: 1 }
            ]}
            onChange={(value) => {
              setQueryString({ [QPARAMS.EarlyPayment]: value !== 0 }, 'pushIn');
            }}
            value={queryString[QPARAMS.EarlyPayment] ? 1 : 0}
          />
        )}
        {queryString[QPARAMS.EarlyPayment] ? (
          <Callout>
            {isLoading ? (
              <Skeleton />
            ) : (
              <>
                {product?.earlyPaymentDiscountRate > 0 ? (
                  <span>
                    {i18n('v2.pages.program.details.discountPerc', {
                      perc: product?.earlyPaymentDiscountRate * 100
                    })}{' '}
                    <span className='text-decoration-line-through text-spanish'>
                      ({priceSelected?.currency?.symbol}
                      {Utils.toCurrency(priceSelected?.finalPriceWithTax)})
                    </span>
                  </span>
                ) : null}
              </>
            )}

            <Row>
              <Col
                xs={7}
                className={
                  isLoading
                    ? ''
                    : 'd-flex align-items-center justify-content-start'
                }>
                {isLoading ? (
                  <Skeleton height='3rem' />
                ) : (
                  <>
                    <h3 className='m-0'>
                      {priceSelected?.currency?.symbol}
                      {Utils.toCurrency(priceSelected?.finalPrice)}
                    </h3>
                  </>
                )}
              </Col>
              <Col xs={5} className='text-right'>
                {isLoading ? (
                  <Skeleton height='3rem' />
                ) : (
                  <>
                    <Dropdown
                      toggle={
                        <h3 className='m-0 d-inline-block mr-1'>
                          {priceSelected?.currency?.isoCode}
                        </h3>
                      }
                      caret
                      align='right'
                      toggleClassName='text-primary d-flex align-items-center'>
                      {currencyMenu.map((currency: any, index: number) => (
                        <Dropdown.Item
                          key={index}
                          value={currency.value}
                          onClick={handleSelection}
                          active={
                            priceSelected?.currency?.isoCode === currency.label
                          }>
                          {currency.label}
                        </Dropdown.Item>
                      ))}
                    </Dropdown>
                  </>
                )}
              </Col>
            </Row>
          </Callout>
        ) : (
          <Callout>
            {isLoading ? (
              <Skeleton />
            ) : (
              <span className='text-spanish'>
                {i18n('v2.pages.program.details.installments', {
                  installments: product?.numberOfInstallmentPayments
                })}
              </span>
            )}

            <Row>
              <Col
                xs={7}
                className={
                  isLoading
                    ? ''
                    : 'd-flex align-items-center justify-content-start'
                }>
                {isLoading ? (
                  <Skeleton height='3rem' />
                ) : (
                  <>
                    <h3 className='m-0 d-inline-block mr-2'>
                      {priceSelected?.currency?.symbol}
                      {Utils.toCurrency(priceSelected?.finalInstallmentAmount)}
                    </h3>
                    <Tooltip text={i18n('v2.pages.program.details.tooltip')}>
                      <small>
                        <Icons.Info className='text-primary' />
                      </small>
                    </Tooltip>
                  </>
                )}
              </Col>
              <Col xs={5} className='text-right'>
                {isLoading ? (
                  <Skeleton height='3rem' />
                ) : (
                  <>
                    <Dropdown
                      toggle={
                        <h3 className='m-0 d-inline-block mr-1'>
                          {priceSelected?.currency?.isoCode}
                        </h3>
                      }
                      caret
                      align='right'
                      toggleClassName='text-primary d-flex align-items-center'>
                      {currencyMenu.map((currency: any, index: number) => (
                        <Dropdown.Item
                          key={index}
                          value={currency.value}
                          onClick={handleSelection}
                          active={
                            priceSelected?.currency?.isoCode === currency.label
                          }>
                          {currency.label}
                        </Dropdown.Item>
                      ))}
                    </Dropdown>
                    {}
                  </>
                )}
              </Col>
            </Row>
          </Callout>
        )}
        {calendars?.length && program?.byCalendar ? (
          <>
            <Callout variant='white'>
              <p className='text-center'>
                {i18n('v2.pages.program.details.calendarsDescription')}
              </p>

              {calendars?.map((calendar: any, index: number) => {
                if (index > 1) {
                  return null;
                }
                return (
                  <Row
                    key={calendar?.id}
                    gutterWidth={16}
                    align='center'
                    className='mb-2'>
                    <Col xs={6} className='text-left'>
                      {isLoading ? (
                        <Skeleton />
                      ) : (
                        <small>
                          <strong>
                            {i18n('v2.pages.program.details.start')}
                          </strong>{' '}
                          {Utils.formatDate(calendar.dateInit, 'dd/mmm/yyyy')}
                        </small>
                      )}
                    </Col>
                    <Col xs={6} className='text-right'>
                      {isLoading ? (
                        <Skeleton />
                      ) : (
                        <small>
                          <strong>
                            {i18n('v2.pages.program.details.end')}
                          </strong>{' '}
                          {Utils.formatDate(calendar.dateEnd, 'dd/mmm/yyyy')}
                        </small>
                      )}
                    </Col>
                  </Row>
                );
              })}

              <div {...getCollapseProps()}>
                {calendars?.map((calendar: any, index: number) => {
                  if (index < 2) {
                    return null;
                  }
                  return (
                    <Row
                      key={calendar?.id}
                      gutterWidth={16}
                      align='center'
                      className='mb-2'>
                      <Col xs={6} className='text-left'>
                        {isLoading ? (
                          <Skeleton />
                        ) : (
                          <small>
                            <strong>
                              {i18n('v2.pages.program.details.start')}
                            </strong>{' '}
                            {Utils.formatDate(calendar.dateInit, 'dd/mmm/yyyy')}
                          </small>
                        )}
                      </Col>
                      <Col xs={6} className='text-right'>
                        {isLoading ? (
                          <Skeleton />
                        ) : (
                          <small>
                            <strong>
                              {i18n('v2.pages.program.details.end')}
                            </strong>{' '}
                            {Utils.formatDate(calendar.dateEnd, 'dd/mmm/yyyy')}
                          </small>
                        )}
                      </Col>
                    </Row>
                  );
                })}
              </div>
            </Callout>

            {calendars?.length > 2 ? (
              <Button
                variant='link'
                block
                {...getToggleProps()}
                className='mb-2'>
                {isExpanded
                  ? i18n('v2.pages.program.details.buttons.lessDates')
                  : i18n('v2.pages.program.details.buttons.moreDates')}
              </Button>
            ) : null}
          </>
        ) : null}

        <div className='text-center mt-2'>
          {isLoading || isLoading ? (
            <Skeleton.Button />
          ) : (
            <Flex gap={2} direction='column'>
              {status === 'ACTIVE' ? null : (
                <p className='text-primary mb-2'>
                  <Icons.CalendarChecked className='mr-2' />{' '}
                  {i18n('v2.pages.subject.details.description')}
                </p>
              )}

              <ButtonsGridStyled>{renderButtons()}</ButtonsGridStyled>
            </Flex>
          )}
        </div>
      </div>
    </DetailWrapperStyled>
  );
}

const ButtonsGridStyled = styled.div`
  display: grid;
  grid-gap: var(--dw-spacer-2);
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);

    button:first-child:last-child,
    button:nth-child(3),
    a:first-child:last-child,
    a:nth-child(3) {
      grid-column: span 2;
    }
  }
`;
