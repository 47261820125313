import { Utils } from 'dawere-uic';

function getItem(data: any) {
  return data ? { id: data.value } : null;
}

export function createPayload(data: any) {
  const payload = {
    name: data.name,
    lastName: data.lastName,
    phoneCode: data.phoneNumber ? data.phoneNumber_dialCode : null,
    phoneNumber: data.phoneNumber,
    identityType: getItem(data.identityType),
    identityCard: data.identityCard || null,
    gender: getItem(data.gender),
    race: getItem(data.race),
    birthdate: data.birthdate
      ? Utils.formatDate(data.birthdate, 'yyyy-mm-dd', false)
      : null,
    birthCountry: getItem(data.birthCountry),
    birthState: getItem(data.birthState),
    birthTownship: getItem(data.birthTownship),
    country: getItem(data.country),
    state: getItem(data.state),
    township: getItem(data.township),
    foundAboutUs: getItem(data.foundAboutUs),
    specialNeeds: data.specialNeeds
      ? data.specialNeeds.map((e: any) => getItem(e))
      : null
  };

  return payload;
}
