import { PaymentBar } from 'components';
import { SummaryCard } from './SummaryCard';

export function SummarySidebar() {
  return (
    <PaymentBar>
      <SummaryCard />
    </PaymentBar>
  );
}
