import { Search } from 'dawere-uic';
import ReactDOM from 'react-dom';

export function SearchKeyword({
  keyword = '',
  onSearch = () => {},
  disabled = false
}: any) {
  let el: HTMLElement | null = document.getElementById('grid-portal-search');

  if (el) {
    return ReactDOM.createPortal(
      <div>
        <Search
          name='search'
          className='mb-4 mb-md-0'
          allowEmptySearch
          value={keyword}
          onSubmit={onSearch}
          style={{ minWidth: '250px' }}
          disabled={disabled}
        />
      </div>,
      el
    );
  }

  return null;
}
