import { API } from 'api';
import { i18n } from 'dawere-commons';
import {
  AutoComplete,
  Block,
  Col,
  Empty,
  Pagination,
  Row,
  Skeleton,
  useRouter,
  Visible
} from 'dawere-uic';
import { PageWrapper } from 'pages/wrappers/PageWrapper';
import React, { useCallback, useEffect, useState } from 'react';
import { getSEO } from 'utils';
import { NotificationCard } from './components/NotificationCard';

/**
 * Path: /notificaciones
 * Key: NOTIFICATIONS
 * @returns {React.ReactElement}
 */
export default function NotificationsPage() {
  const { push } = useRouter();

  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [read, setRead] = useState<any>({
    label: 'Ver no leídas',
    value: false
  });

  useEffect(() => {
    setIsLoading(true);
    API.Notifications.list({
      page: pageIndex,
      size: pageSize,
      read: read.value
    })
      .then((response) => {
        setData(
          response.content.map((n: any) => ({
            ...n,
            icon: API.Notifications.getIcon(n.campaign)
          }))
        );
        setPageCount(response.totalPages);
        setTotal(response.totalElements);
      })
      .finally(() => setIsLoading(false));
  }, [pageIndex, pageSize, read]);

  const handlePaging = useCallback(({ pageSize, pageIndex }: any) => {
    setPageSize(pageSize);
    setPageIndex(pageIndex);
  }, []);

  const handleMarkAsRead = useCallback((notification: any, index: number) => {
    console.log('Mark as read', notification, index);
    API.Notifications.markAsRead(notification.id).then(() => {
      notification.read = true;
    });
  }, []);

  const handleMarkAsOpen = useCallback((notification: any, index: number) => {
    console.log('Mark as open', notification, index);
    API.Notifications.markAsOpen(notification.id).then(() => {
      notification.read = true;
    });
  }, []);

  return (
    <PageWrapper>
      {getSEO('v2.pages.notifications.seo', true)}

      <Block narrow>
        <Col md={8} offset={{ md: 2 }} className='mb-4'>
          <Row align='center'>
            <Col xs={12} md={8}>
              <h3 className='m-0'>
                {i18n('ui.components.notifications.title')}
              </h3>
            </Col>

            <Col xs={12} md={4}>
              <AutoComplete
                onChange={(e: any) => setRead(e)}
                value={read}
                searchable={false}
                name='filter'
                options={[
                  { label: 'Ver no leídas', value: false },
                  { label: 'Ver todas', value: null },
                  { label: 'Ver leídas', value: true }
                ]}
                loading={isLoading}
              />
            </Col>
          </Row>
        </Col>

        <Col md={8} offset={{ md: 2 }}>
          {isLoading ? (
            <>
              {Array(5)
                .fill(0)
                .map((_, index) => (
                  <Skeleton height={80} key={index} className='mb-2' />
                ))}
            </>
          ) : (
            <>
              {data.length ? (
                <>
                  {data.map((notification) => (
                    <NotificationCard
                      key={notification.id}
                      data={notification}
                      onMarkAsRead={handleMarkAsRead}
                      onMarkAsOpen={handleMarkAsOpen}
                    />
                  ))}
                </>
              ) : (
                <Empty />
              )}
            </>
          )}

          <Row>
            <Visible lg xl>
              <Col xs={3} />
            </Visible>

            <Col>
              <Pagination
                align='center'
                className='my-4'
                onUpdate={handlePaging}
                pageIndex={pageIndex}
                pageCount={pageCount}
                disabled={isLoading}
              />
            </Col>

            <Visible md lg xl>
              <Col
                xs={3}
                className='d-flex align-items-center justify-content-end'>
                {isLoading ? (
                  <Skeleton width={120} wrapper='small' />
                ) : (
                  <small>
                    <strong>{total}</strong>{' '}
                    {i18n(
                      'ui.components.notifications.title'
                    ).toLocaleLowerCase()}
                  </small>
                )}
              </Col>
            </Visible>
          </Row>
        </Col>
      </Block>
    </PageWrapper>
  );
}
