import { API } from 'api';
import { useEffect, useState } from 'react';

export function useGeo(
  country: any,
  state: any,
  loadCountries: boolean = true
) {
  const [isLoadingCountries, setIsLoadingCountries] = useState(true);
  const [isLoadingStates, setIsLoadingStates] = useState(false);
  const [isLoadingTownships, setIsLoadingTownships] = useState(false);

  const [countries, setCountries] = useState<any[]>([]);
  const [states, setStates] = useState<any[]>([]);
  const [townships, setTownships] = useState<any[]>([]);

  // Countries
  useEffect(() => {
    if (loadCountries) {
      setIsLoadingCountries(true);

      API.Geo.getCountries()
        .then((response) => {
          setCountries(response);
        })
        .finally(() => setIsLoadingCountries(false));
    }
  }, [loadCountries]);

  // States
  useEffect(() => {
    if (country) {
      setIsLoadingStates(true);

      API.Geo.getStates(country.value)
        .then((response) => {
          setStates(response);
        })
        .finally(() => setIsLoadingStates(false));
    }
  }, [country]);

  // Townships
  useEffect(() => {
    if (state) {
      setIsLoadingTownships(true);

      API.Geo.getTownships(state.value)
        .then((response) => {
          setTownships(response);
        })
        .finally(() => setIsLoadingTownships(false));
    }
  }, [state]);

  return {
    isLoadingCountries,
    isLoadingStates,
    isLoadingTownships,
    countries,
    states,
    townships
  };
}
