import { Col, Skeleton } from 'dawere-uic';
import { useEnroll } from '../hooks/useEnroll';
import { EnrollmentWizard } from './EnrollmentWizard';
import { FlexEnrollmentWizard } from './FlexEnrollmentWizard';
import { ReEnrollmentWizard } from './ReEnrollmentWizard';

export function MainWrapper() {
  const {
    isReEnrolment,
    isLoadingProgramStatus,
    isLoadingDocuments,
    isLoadingPeriods,
    isLoadingCalendars,
    isLoadingGateways,
    isLoadingProgram,
    program
  } = useEnroll();

  if (
    isLoadingProgramStatus ||
    isLoadingDocuments ||
    isLoadingPeriods ||
    isLoadingCalendars ||
    isLoadingGateways ||
    isLoadingProgram ||
    isReEnrolment === null ||
    !program
  ) {
    return (
      <>
        <Col xs={12} className='mb-3'>
          <Skeleton width={100} />
        </Col>
        <Col xs={12} className='mb-4'>
          <Skeleton circle height={40} width={40} className='mr-5' />
          <Skeleton circle height={40} width={40} className='mr-5' />
          <Skeleton circle height={40} width={40} />
        </Col>
        <Col xs={12} className='mb-4'>
          <Skeleton.Title />
        </Col>
        <Col xs={12} className='mb-4'>
          {Array(6)
            .fill(0)
            .map((_, index) => (
              <Col key={index} md={6} className='mb-2'>
                <Skeleton
                  circle
                  width={30}
                  height={30}
                  style={{ marginRight: 10 }}
                />
                <Skeleton height={30} style={{ width: 'calc(90% - 26px)' }} />
              </Col>
            ))}
        </Col>
        <Col xs={12} className='text-right'>
          <Skeleton.Button />
        </Col>
      </>
    );
  }

  if (program) {
    if (program.byCalendar) {
      return isReEnrolment ? <ReEnrollmentWizard /> : <EnrollmentWizard />;
    }
    return <FlexEnrollmentWizard />;
  }
  return null;
}
