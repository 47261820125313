import esCO from './es-CO';

const enCO = {
  ...esCO,
  lang: `en-CO,es`,
  shortLang: 'en-CO'
};

export default enCO;

export { enCO };
