import { API } from 'api';
import { Session } from 'dawere-commons';
import { Block } from 'dawere-uic';
import React, { useCallback, useEffect, useState } from 'react';
import { Heading } from './sections/Heading';
import { TabsSection } from './sections/TabsSection';

export interface StudentDashboardProps {
  studentId?: string;
  student?: any;
  isLoading?: boolean;
  ignorePendingProfile?: boolean;
  readOnly?: boolean;
  getEnrollments: () => any;
  getOrders: () => any;
  getBills: (params?: any) => any;
  getPayments: (params?: any) => any;
  getInstallments: (orderId: string) => any;
  rootRoute: 'STUDENTS_DASHBOARD' | 'TUTORS_DASHBOARD_STUDENT_VIEW';
}

export const StudentDashboardContext =
  React.createContext<StudentDashboardProps>({
    ignorePendingProfile: false,
    readOnly: false,
    getEnrollments: () => {},
    getOrders: () => {},
    getBills: (params?: any) => {},
    getPayments: (params?: any) => {},
    getInstallments: (orderId: string) => {},
    rootRoute: 'STUDENTS_DASHBOARD'
  });

export function StudentDashboardProvider({
  id,
  ignorePendingProfile = false,
  seenAs = 'STUDENT',
  rootRoute = 'STUDENTS_DASHBOARD'
}: any) {
  const account = Session.getAccountInfo();
  const studentId = id || account?.id;
  const readOnly = seenAs !== 'STUDENT';

  const [student, setStudent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getEnrollments = useCallback(() => {
    return API.Students.getAllEnrollments({
      student_id: id,
      latest: true,
      online: true
    });
  }, [id]);

  const getOrders = useCallback(() => {
    return readOnly
      ? API.Students.getOrders(studentId)
      : API.Payments.getMyOrders();
  }, [studentId, readOnly]);

  const getPayments = useCallback(
    (params?: any) => {
      return readOnly
        ? API.Students.getPayments(studentId, params)
        : API.Payments.getMyPayments(params);
    },
    [studentId, readOnly]
  );

  const getBills = useCallback(
    (params?: any) => {
      return readOnly
        ? API.Students.getBills(studentId, params)
        : API.Payments.getMyBills(params);
    },
    [studentId, readOnly]
  );

  const getInstallments = useCallback(
    (orderId: string) => {
      return readOnly
        ? API.Students.getInstallments(studentId, orderId)
        : API.Payments.getMyInstallments(orderId);
    },
    [studentId, readOnly]
  );

  useEffect(() => {
    if (studentId) {
      setIsLoading(true);

      API.Students.get(studentId)
        .then((response) => setStudent(response.data))
        .finally(() => setIsLoading(false));
    }
  }, [studentId]);

  return (
    <StudentDashboardContext.Provider
      value={{
        studentId,
        student,
        isLoading,
        ignorePendingProfile,
        readOnly,
        getEnrollments,
        getOrders,
        getBills,
        getPayments,
        getInstallments,
        rootRoute
      }}>
      <Block narrow>
        {/* Titulo y avatar */}
        <Heading />
        {/* Tabs principales */}
        <TabsSection />
      </Block>
    </StudentDashboardContext.Provider>
  );
}
