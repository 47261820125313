import { API } from 'api';
import { Session } from 'dawere-commons';
import { useRouter } from 'dawere-uic';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PATHS } from 'router';
import { QPARAMS } from 'values';
import { getData } from './utils/getData';

export interface BuyCouponsProps {
  organization: any;
  categories: any[];
  items: any[];
  getItems: (currencyId?: string) => void;
  total: number;
  step: string;
  setStep: React.Dispatch<React.SetStateAction<any>>;
  billingInfo: any;
  setBillingInfo: React.Dispatch<React.SetStateAction<any>>;
  isBillingInfoCompleted: () => boolean;
  updateItem: (item: any) => void;
  currencies: any[];
  currency: any;
  gateways: any;
  orderPayload: any;
  summary: any;
  setSummary: React.Dispatch<React.SetStateAction<any>>;
  updateCurrency: (currency: any) => void;
  isLoadingOrganization: boolean;
  isLoadingCurrencies: boolean;
  isLoadingItems: boolean;
  isPaid: boolean;
  setIsPaid: React.Dispatch<React.SetStateAction<boolean>>;
  walletBalance: number | null;
}

export const BuyCouponsContext = React.createContext<BuyCouponsProps>({
  organization: null,
  categories: [],
  items: [],
  getItems: () => {},
  total: 0,
  step: '1',
  setStep: () => {},
  updateItem: () => {},
  billingInfo: {},
  setBillingInfo: () => {},
  isBillingInfoCompleted: () => false,
  currencies: [],
  currency: null,
  gateways: null,
  orderPayload: null,
  summary: null,
  setSummary: () => {},
  updateCurrency: () => {},
  isLoadingOrganization: true,
  isLoadingCurrencies: true,
  isLoadingItems: true,
  isPaid: false,
  setIsPaid: () => {},
  walletBalance: null
});

export function BuyCouponsProvider({ children, id }: any) {
  const { query, push } = useRouter();
  const ref = useRef<any>();

  const [organization, setOrganization] = useState<any>(null);
  const [categories, setCategories] = useState<any[]>([]);
  const [items, setItems] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [step, setStep] = useState('1');
  const [billingInfo, setBillingInfo] = useState<any>({});
  const [currencies, setCurrencies] = useState<any[]>([]);
  const [currency, setCurrency] = useState<any>(null);
  const [gateways, setGateways] = useState<any>(null);
  const [orderPayload, setOrderPayload] = useState<any>(null);
  const [summary, setSummary] = useState<any>(null);
  const [walletBalance, setWalletBalance] = useState<number | null>(null);

  const [isLoadingOrganization, setIsLoadingOrganization] = useState(true);
  const [isLoadingCurrencies, setIsLoadingCurrencies] = useState(true);
  const [isLoadingItems, setIsLoadingItems] = useState(true);
  const [isPaid, setIsPaid] = useState(false);

  const updateItem = useCallback(
    (item: any) => {
      const found = items.find((i: any) => i.id === item.id);

      if (found) {
        found.quantity = item.quantity;
        found.subtotal = item.subtotal;
        setItems([...items]);
      }
    },
    [items]
  );

  const getItems = useCallback(
    (currencyId?: string) => {
      setIsLoadingItems(true);

      API.Cart.getPrograms(currencyId)
        .then((response) => {
          const items: any[] = [];
          const categories = response.map((category: any) => {
            return {
              id: category.id,
              title: category.title,
              subcategories: category.subcategories.map((item: any) => {
                const qty =
                  query[QPARAMS.Program] &&
                  (query[QPARAMS.Program] === item?.friendlyPath ||
                    query[QPARAMS.Program] === item.id)
                    ? 1
                    : 0;

                const newItem = {
                  id: item.id,
                  title: item.title,
                  price: +item?.price?.price,
                  currency: item?.price?.currency,
                  taxType: item?.price?.taxType,
                  resourceId: item.resourceId,
                  quantity: qty,
                  subtotal: qty * +item?.price?.price
                };

                items.push(newItem);

                return newItem;
              })
            };
          });

          setCategories(categories);
          setItems(items);
        })
        .finally(() => setIsLoadingItems(false));
    },
    [query]
  );

  const updateCurrency = useCallback(
    (currency: any) => {
      setCurrency(currency);
      // Actualizo la sesion para que use la moneda actual
      Session.setDefaultCurrency(currency);

      getItems(currency.id);
    },
    [getItems]
  );

  const isBillingInfoCompleted = useCallback(() => {
    return (
      billingInfo?.name &&
      billingInfo?.tin &&
      billingInfo?.country &&
      billingInfo?.state &&
      billingInfo?.township &&
      billingInfo?.postalCode &&
      billingInfo?.billingAddress
    );
  }, [
    billingInfo?.billingAddress,
    billingInfo?.country,
    billingInfo?.name,
    billingInfo?.postalCode,
    billingInfo?.state,
    billingInfo?.tin,
    billingInfo?.township
  ]);

  // Get organization base info
  useEffect(() => {
    if (id) {
      setIsLoadingOrganization(true);

      API.Organizations.get(id)
        .then((data: any) => {
          setOrganization(getData(data));
          setBillingInfo(getData(data));
        })
        .finally(() => setIsLoadingOrganization(false));
    } else {
      push(PATHS.MY_ORGANIZATIONS);
    }
  }, [id, push]);

  // Currencies
  useEffect(() => {
    if (id) {
      setIsLoadingCurrencies(true);

      API.Cart.getCurrencies()
        .then((response) => {
          setCurrencies(response);

          const defaultCurrency = Session.getDefaultCurrency();

          // Verifico si dentro del listado de monedas existe la moneda por defecto
          // del usuario, si no existe uso la primera moneda de la lista
          const found = response.find(
            (currency: any) => currency.id === defaultCurrency?.id
          );
          const [first] = response;
          const currency = found ? defaultCurrency : first;
          setCurrency(currency);

          // Actualizo la sesion para que use la moneda actual
          Session.setDefaultCurrency(currency);

          // Busco los items con la moneda actual
          getItems(currency?.id);
        })
        .finally(() => setIsLoadingCurrencies(false));
    }
  }, [getItems, id]);

  // Gateways
  useEffect(() => {
    if (id) {
      if (currency) {
        setIsLoadingCurrencies(true);

        API.Cart.getGateways(currency?.id)
          .then((response) => {
            setGateways(response);
          })
          .finally(() => setIsLoadingCurrencies(false));
      }
    }
  }, [currency, id]);

  // Wallet
  useEffect(() => {
    if (summary) {
      API.Payments.getWalletBalance(summary?.id).then((response) => {
        setWalletBalance(response);
      });
    }
  }, [summary]);

  // Update total
  useEffect(() => {
    const total = items.reduce((acc: number, item: any) => {
      return acc + item.subtotal;
    }, 0);

    setTotal(total);
    return total;
  }, [items]);

  // Update summary card info
  useEffect(() => {
    const filtered = items.filter((item: any) => item.quantity > 0);

    if (!filtered.length) {
      return;
    }

    if (!billingInfo) {
      return;
    }

    const services = filtered.map((item: any) => ({
      id: item.resourceId,
      qty: item.quantity
    }));

    const taxData = {
      address: billingInfo.billingAddress,
      name: billingInfo.name,
      postalCode: billingInfo.postalCode,
      tin: billingInfo.tin,
      township: {
        id: billingInfo.township?.value
      }
    };

    setOrderPayload({ services, taxData });
  }, [billingInfo, items]);

  // Update ref with date to run unmounted
  ref.current = {
    isPaid,
    orderId: summary?.id
  };

  // Remove order if not completed
  // useEffect(() => {
  //   return () => {
  //     const { isPaid, orderId } = ref.current;

  //     if (!isPaid && orderId) {
  //       API.Payments.deleteOrder(orderId).then(() => {
  //         console.log('Orden borrada:', orderId);
  //       });
  //     }
  //   };
  // }, []);

  return (
    <BuyCouponsContext.Provider
      value={{
        organization,
        categories,
        items,
        getItems,
        total,
        step,
        setStep,
        updateItem,
        billingInfo,
        setBillingInfo,
        isBillingInfoCompleted,
        currencies,
        currency,
        gateways,
        orderPayload,
        summary,
        setSummary,
        updateCurrency,
        isLoadingOrganization,
        isLoadingCurrencies,
        isLoadingItems,
        isPaid,
        setIsPaid,
        walletBalance
      }}>
      {children}
    </BuyCouponsContext.Provider>
  );
}
