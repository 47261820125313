import { Utils } from 'dawere-uic';
import { useContext } from 'react';
import { BuyCouponsContext } from '../BuyCouponsProvider';

export const useBuyCoupons = () => {
  const context = useContext(BuyCouponsContext);

  if (Utils.isEmpty(context)) {
    throw new Error('"useBuyCoupons" must be used within a BuyCouponsProvider');
  }

  return context;
};
