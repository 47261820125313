import { useQuery } from '@tanstack/react-query';
import { API } from 'api';
import { Session } from 'dawere-commons';
import { IBaseProps, TaskManager, Utils } from 'dawere-uic';

type TaskProps = {
  asyncId: string | null;
  onFinished?: () => void;
  onError?: () => void;
} & IBaseProps;

const INTERVAL_IN_SECONDS = 1000 * 1;

export function Tasks({
  asyncId,
  onFinished,
  onError = () => {},
  ...rest
}: TaskProps) {
  const { data } = useQuery({
    enabled: !!asyncId && !!Session.getAccountInfo(),
    queryKey: ['async-process', asyncId],
    queryFn: () => API.TaskManager.get(asyncId),
    refetchInterval: INTERVAL_IN_SECONDS,
    onError: () => {
      if (Utils.isString(asyncId)) {
        onError?.();
      }
    }
  });

  if (!asyncId || !data) {
    return null;
  }

  return (
    <TaskManager
      description={data.description}
      updates={data.updates}
      progress={data.progress}
      onFinished={onFinished}
      {...rest}
    />
  );
}
