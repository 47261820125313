import { Locales, Session } from 'dawere-commons';
import { PATHS } from 'router';
import { COUNTRIES_REGEXP } from './countries';

export function isMember(role: string | null) {
  return role === 'OWNER' || role === 'ADMINISTRATOR';
}

export function isOwner(role: string | null) {
  return role === 'OWNER';
}

export function isAdministrator(role: string | null) {
  return role === 'ADMINISTRATOR';
}

export function isTeacher(role: string | null) {
  return role === 'TEACHER';
}

export function isTutor(role: string | null) {
  return role === 'TUTOR';
}

/**
 * Gets redirect path to the dashboard of the current organization
 * @param organization user's organization object (Session.getActiveOrganization())
 * @returns string path to redirect
 */
export function getDashboardPath(organization: any = {}) {
  const { id, role } = organization;

  if (isMember(role)) {
    return PATHS.get('ORGANIZATIONS_DASHBOARD', {
      organizationId: id
    });
  }

  if (isTeacher(role)) {
    return PATHS.get('TEACHERS_DASHBOARD', {
      organizationId: id
    });
  }

  return PATHS.MY_ORGANIZATIONS;
}

/**
 * Gets redirect to the dashboard of the current organization or to a specific (restricted) one
 * @param restrictedPath get this from query.r
 * @returns string path to redirect
 */
export function getLoginRedirectPath(restrictedPath = '') {
  if (restrictedPath) {
    return restrictedPath;
  }

  const defaultDashboard = Session.getDefaultDashboard();
  const activeOrganization = Session.getActiveOrganization();

  switch (defaultDashboard) {
    case 'ORGANIZATION':
      // Weird error
      if (!activeOrganization?.id) {
        return PATHS.MY_ORGANIZATIONS;
      }

      if (isMember(activeOrganization?.role)) {
        return PATHS.get('ORGANIZATIONS_DASHBOARD', {
          organizationId: activeOrganization?.id
        });
      }

      if (isTeacher(activeOrganization?.role)) {
        return PATHS.get('TEACHERS_DASHBOARD', {
          organizationId: activeOrganization?.id
        });
      }
      break;
    case 'STUDENT':
      return PATHS.STUDENTS_DASHBOARD;
    case 'TUTOR':
      return PATHS.TUTORS_DASHBOARD;
    default:
      return PATHS.HOME;
  }
}

/**
 * Fix or add the current country before the path
 * @param path
 * @returns fixed path
 */
export function addCountryToPath(path: string) {
  const re = new RegExp(`/${COUNTRIES_REGEXP}/?.*$`, 'gi');
  const iso2 = Locales.getCountry();
  const match = re.exec(path);

  if (match) {
    return encodeURIComponent(path.replace(`/${match[1]}/`, `/${iso2}/`));
  } else {
    return encodeURIComponent(`/${iso2}${path[0] === '/' ? path : `/${path}`}`);
  }
}

export function updateCountryInUrl(path?: string) {
  // En este punto el lang ya esta almacenado en el localstorage, solo
  // resta resfrecar la pagina con el nuevo idioma
  const iso2 = Locales.getCountry();
  const { href, pathname, origin } = window.location;

  if (path) {
    const regexp = new RegExp(`/${COUNTRIES_REGEXP}/`, 'gi').exec(path);
    const urlCountry = regexp?.length ? regexp[1] : '';

    if (urlCountry.length) {
      const country = path.length > 3 ? path.substring(3) : '';
      window.location.href = `${origin}/${iso2}${country}`;
    } else {
      window.location.href = `${origin}/${iso2}${path}`;
    }
  } else {
    const regexp = new RegExp(`/${COUNTRIES_REGEXP}/`, 'gi').exec(href);
    const urlCountry = regexp?.length ? regexp[1] : '';

    if (urlCountry.length) {
      const country = pathname.length > 3 ? pathname.substring(3) : '';
      window.location.pathname = `/${iso2}${country}`;
    } else {
      window.location.pathname = `/${iso2}${pathname}`;
    }
  }
}
