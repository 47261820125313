import { Rest } from 'dawere-commons';
import { Utils } from 'dawere-uic';
import { Members } from './members';
import { Teachers } from './teachers';

function _processStudentResponse(
  response: any,
  status?:
    | 'IN_COURSE'
    | 'GRADUATED'
    | 'LATEST_COURSE'
    | 'REMOVED'
    | 'INVITATIONS'
    | 'NOT_ENROLLED'
) {
  let data = [];

  if (status === 'IN_COURSE') {
    data = response.data.content.map((el: any) => {
      const { student, course } = el;
      const fullName = Utils.getFullname(student.name, student.lastName);
      const startDateC = Utils.formatDate(course.calendar_start, 'dd/mmm/yyyy');
      const endDateC = Utils.formatDate(course.calendar_end, 'dd/mmm/yyyy');
      const startDateA = Utils.formatDate(course.admission_date, 'dd/mmm/yyyy');
      const endDateA = Utils.formatDate(course.culmination_date, 'dd/mmm/yyyy');

      return {
        id: student.id,
        name: fullName,
        email: student.email,
        avatar: student.picturePath,
        programId: course.program_id,
        program: course.program,
        period: course.period,
        calendar: `${startDateC} - ${endDateC}`,
        admission: `${startDateA} - ${endDateA}`
      };
    });
  }

  if (status === 'GRADUATED') {
    data = response.data.content.map((el: any) => {
      const { student, course } = el;
      const fullName = Utils.getFullname(student.name, student.lastName);

      return {
        id: student.id,
        name: fullName,
        email: student.email,
        avatar: student.picturePath,
        programId: course.program_id,
        program: course.program,
        phoneNumber: student.phoneNumber
          ? `${student.phoneCode} ${student.phoneNumber}`
          : null,
        date: Utils.formatDate(course.calendar_end, 'dd/mmm/yyyy')
      };
    });
  }

  if (status === 'LATEST_COURSE') {
    data = response.data.content.map((el: any) => {
      const { student, course } = el;
      const fullName = Utils.getFullname(student.name, student.lastName);
      const startDateC = Utils.formatDate(course.calendar_start, 'dd/mmm/yyyy');
      const endDateC = Utils.formatDate(course.calendar_end, 'dd/mmm/yyyy');
      const startDateA = Utils.formatDate(course.admission_date, 'dd/mmm/yyyy');
      const endDateA = Utils.formatDate(course.culmination_date, 'dd/mmm/yyyy');

      return {
        id: student.id,
        name: fullName,
        email: student.email,
        avatar: student.picturePath,
        programId: course.program_id,
        program: course.program,
        period: course.period,
        calendar: `${startDateC} - ${endDateC}`,
        admission: `${startDateA} - ${endDateA}`
      };
    });
  }

  if (status === 'REMOVED') {
    data = response.data.content.map((el: any) => {
      const { student, removed } = el;
      const fullName = Utils.getFullname(student.name, student.lastName);

      return {
        id: student.id,
        name: fullName,
        email: student.email,
        avatar: student.picturePath,
        program: removed.program,
        period: removed.period,
        removedBy: removed.removedBy,
        removedDate: Utils.formatDate(removed.removedDate, 'dd/mmm/yyyy'),
        status: removed.action
      };
    });
  }

  if (status === 'INVITATIONS') {
    data = response.data.content.map((el: any) => {
      return {
        id: el.id,
        email: el.email,
        date: Utils.formatDate(el.invitationDate, 'dd/mmm/yyyy'),
        status: el.status
      };
    });
  }

  if (status === 'NOT_ENROLLED') {
    data = response.data.content.map((el: any) => {
      const { student } = el;
      const fullName = Utils.getFullname(student.name, student.lastName);

      return {
        id: student.id,
        email: student.email,
        avatar: student.picturePath,
        name: fullName
      };
    });
  }

  if (!status) {
    data = response.data.content.map((el: any) => {
      const fullName = Utils.getFullname(el.name, el.lastName);

      return {
        id: el.id,
        name: fullName,
        email: el.email,
        avatar: el.picturePath,
        initials: Utils.getInitialLetters(el.name, el.lastName)
      };
    });

    data = data.sort((a: any, b: any) => a.name.localeCompare(b.name));
  }

  return {
    data: data,
    totalPages: response.data.totalPages,
    totalRows: response.data.totalElements
  };
}

function requestDemo(data: any) {
  return Rest.post('public/demo-request', data, {
    recaptchaAction: 'demo'
  });
}

function list(params: any = {}) {
  return Rest.get(
    'my-organizations?sort=role,desc&sort=organization.name,asc',
    { params }
  ).then((response: any) => {
    return response.data;
  });
}

function get(organizationId: string) {
  return Rest.get(`my-organizations/${organizationId}`).then(
    (response: any) => {
      return response.data;
    }
  );
}

function create(data: any = {}) {
  return Rest.post('my-organizations', data);
}

function update(organizationId: string, data: any = {}) {
  return Rest.put(`my-organizations/${organizationId}`, data);
}

function uploadPicture(organizationId: string, data: any) {
  return Rest.put(`my-organizations/${organizationId}/logo`, data);
}

function getStudents(
  organizationId: string,
  params: any = {},
  status?:
    | 'IN_COURSE'
    | 'GRADUATED'
    | 'LATEST_COURSE'
    | 'REMOVED'
    | 'NOT_ENROLLED',
  download: boolean = false
) {
  if (download) {
    const fileName = `students-${
      status || 'IN_COURSE'.toLocaleLowerCase().replaceAll('_', '-')
    }.csv`;

    return Rest.get(`my-organizations/${organizationId}/students.csv`, {
      params: {
        ...params,
        status
      },
      responseType: 'blob'
    }).then((response) => {
      Utils.saveBlob(response.data, fileName);
    });
  }

  return Rest.get(`my-organizations/${organizationId}/students`, {
    params: {
      ...params,
      status
    }
  }).then((response: any) => {
    return _processStudentResponse(response, status);
  });
}

function getStudentInvitations(
  organizationId: string,
  params: any = {},
  download: boolean = false
) {
  if (download) {
    const fileName = 'students-invitations.csv';

    return Rest.get(`my-organizations/${organizationId}/invitations.csv`, {
      params: {
        ...params
      },
      responseType: 'blob'
    }).then((response) => {
      Utils.saveBlob(response.data, fileName);
    });
  }

  return Rest.get(`my-organizations/${organizationId}/invitations`, {
    params: {
      ...params
    }
  }).then((response: any) => {
    return _processStudentResponse(response, 'INVITATIONS');
  });
}

function getPrograms(
  organizationId: string,
  status:
    | 'IN_COURSE'
    | 'GRADUATED'
    | 'LATEST_COURSE'
    | 'REMOVED'
    | 'NOT_ENROLLED' = 'IN_COURSE'
) {
  return Rest.get(`/my-organizations/${organizationId}/programs`, {
    params: { status }
  }).then((response: any) => {
    return response?.data.map((el: any) => {
      return {
        ...el.program,
        periods: el.periods,
        calendars: el.calendars.map((calendar: any) => {
          return {
            ...calendar,
            dates: `${Utils.formatDate(
              calendar.dateInit,
              'mmm/yyyy'
            )} - ${Utils.formatDate(calendar.dateEnd, 'mmm/yyyy')}`
          };
        })
      };
    });
  });
}

function getLineup(organizationId: string, programId: string) {
  return Rest.get(`my-organizations/${organizationId}/lineups`, {
    params: { program_id: programId }
  }).then((response: any) => {
    return response.data?.periods?.map((period: any) => {
      return {
        id: period.id,
        name: period.name,
        subjects: period.teacherSubjects?.map(
          ({ subject, teachers = [] }: any) => {
            return {
              ...subject,
              teachers: teachers.map((teacher: any) => {
                return {
                  id: teacher.user?.id,
                  email: teacher.user?.email,
                  name: teacher.user?.name,
                  lastName: teacher.user?.lastName,
                  fullName: Utils.getFullname(
                    teacher.user?.name,
                    teacher.user?.lastName
                  ),
                  avatar: teacher.user?.picturePath,
                  phone: Utils.joinStrings(
                    teacher.user?.phoneCode,
                    teacher.user?.phoneNumber
                  ),
                  relationshipId: teacher.id,
                  pending: false
                };
              })
            };
          }
        )
      };
    });
  });
}

function removePendingInvitation(organizationId: string, elementId: string) {
  return Rest.delete(
    `my-organizations/${organizationId}/invitations/${elementId}`
  );
}

function validateEmailInvitation(organizationId: string, email: string) {
  return Rest.get(`my-organizations/${organizationId}/validate-invitations`, {
    params: { email }
  });
}

function monitoring(
  organizationId: string,
  programId: string,
  subjectId: string,
  params: any = {}
) {
  return Rest.get(`my-organizations/${organizationId}/monitoring`, {
    params: { ...params, program_id: programId, subject_id: subjectId }
  }).then((response: any) => {
    const data = response.data.progress.content.map((el: any) => {
      const { user, subject } = el;
      const fullName = Utils.getFullname(user.name, user.lastName);

      return {
        id: user.id,
        name: fullName,
        email: user.email,
        avatar: user.picturePath,
        subjectName: subject.name,
        period: subject.period,
        progress: subject.progress,
        score: subject.score,
        takenTests: subject.takenTests,
        totalTests: subject.totalTests,
        evaluations: `${subject.takenTests}/${subject.totalTests}`
      };
    });

    return {
      program: response.data.program,
      subject: response.data.subject,
      period: data.length ? data[0].period : null,
      data: data,
      totalPages: response.data.progress.totalPages,
      totalRows: response.data.progress.totalElements
    };
  });
}

function getBills(organizationId: string, params: any = {}) {
  return Rest.get(`my-organizations/${organizationId}/receipts`, {
    params
  }).then((response) => {
    let data = response.data.content.map((el: any) => {
      return {
        ...el,
        paymentDate: Utils.formatDate(el.paymentDate, 'dd/mmm/yyyy')
      };
    });

    return {
      data: data,
      totalPages: response.data.totalPages,
      totalRows: response.data.totalElements
    };
  });
}

function getPayments(organizationId: string, params: any = {}) {
  return Rest.get(`my-organizations/${organizationId}/payments`, {
    params
  }).then((response) => {
    let data = response.data.content.map((el: any) => {
      return {
        ...el,
        paymentDate: Utils.formatDate(el.paymentDate, 'dd/mmm/yyyy')
      };
    });

    return {
      data,
      totalPages: response.data.totalPages,
      totalRows: response.data.totalElements
    };
  });
}

function getPaymentDetails(organizationId: string, paymentId: string) {
  return Rest.get(
    `my-organizations/${organizationId}/payments/${paymentId}/details`
  );
}

function cancelOrder(organizationId: string, receiptId: string) {
  return Rest.delete(`my-organizations/${organizationId}/orders/${receiptId}`);
}

function getBalance(organizationId: string) {
  return Rest.get(`my-organizations/${organizationId}/wallet`);
}

export const Organizations = {
  Teachers,
  Members,
  list,
  get,
  create,
  update,
  uploadPicture,
  getLineup,
  requestDemo,
  getStudents,
  getStudentInvitations,
  getPrograms,
  removePendingInvitation,
  validateEmailInvitation,
  monitoring,
  getBills,
  getPayments,
  getPaymentDetails,
  cancelOrder,
  getBalance
};
