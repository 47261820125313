import { API } from 'api';
import { Session } from 'dawere-commons';
import React, { useCallback, useState } from 'react';

export interface SessionProps {
  user: any;
  setUser: React.Dispatch<React.SetStateAction<any>>;
  getMe: () => Promise<any>;
}

export const SessionContext = React.createContext<SessionProps>({
  user: null,
  setUser: () => {},
  getMe: () => {
    return Promise.resolve({});
  }
});

export function SessionProvider({ children }: any) {
  const [user, setUser] = useState<any>(Session.getAccountInfo());

  const getMe = useCallback(() => {
    // Update main navbar
    return API.Account.me().then(({ data }: any) => {
      Session.setAccountInfo(data);
      // Trigger provider
      setUser(data);

      return data;
    });
  }, [setUser]);

  return (
    <SessionContext.Provider
      value={{
        user,
        setUser,
        getMe
      }}>
      {children}
    </SessionContext.Provider>
  );
}
