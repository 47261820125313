import { Rest } from 'dawere-commons';

function _processResponse(response: any) {
  const arr = response.data.content.map((country: any) => ({
    value: country.id,
    label: country.itemValue
  }));

  return arr.sort((a: any, b: any) => a.label.localeCompare(b.label));
}

function getDocTypes() {
  return Rest.get('picklists/doc-types').then((response) => {
    return _processResponse(response);
  });
}

function getFoundAboutUs() {
  return Rest.get('picklists/found-about-us').then((response) => {
    return _processResponse(response);
  });
}

function getGenders() {
  return Rest.get('picklists/genders').then((response) => {
    return _processResponse(response);
  });
}

function getRaces() {
  return Rest.get('picklists/races').then((response) => {
    return _processResponse(response);
  });
}

function getSpecialNeeds() {
  return Rest.get('picklists/special-needs').then((response) => {
    return _processResponse(response);
  });
}

export const Picklists = {
  getDocTypes,
  getFoundAboutUs,
  getGenders,
  getRaces,
  getSpecialNeeds
};
