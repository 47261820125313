import { i18n } from 'dawere-commons';
import { Block, Col, Mesh } from 'dawere-uic';
import { ASSETS } from 'values';

export function AlliesSection() {
  return (
    <Block align='center'>
      <Col xs={12}>
        <h2 className='mb-6 text-center'>
          {i18n('v2.pages.shared.allies.title')}
        </h2>

        <Mesh
          className='align-items-center'
          style={{ justifyItems: 'center', rowGap: 'var(--dw-spacer-4)' }}>
          <img
            width='60%'
            src={ASSETS.ALLIES.UNICEF}
            alt='UNICEF'
            className='img-fluid'
          />

          <img
            width='60%'
            src={ASSETS.ALLIES.MLB}
            alt='MLB'
            className='img-fluid'
          />

          <img
            width='60%'
            src={ASSETS.ALLIES.SANTANDER_UNIVERSITY}
            alt='Universidad de Santander'
            className='img-fluid'
          />

          <img
            width='60%'
            src={ASSETS.ALLIES.DIGITEL}
            alt='Digitel'
            className='img-fluid'
          />

          <img
            width='60%'
            src={ASSETS.ALLIES.ALTICE}
            alt='Altice'
            className='img-fluid'
          />

          <img
            width='60%'
            src={ASSETS.ALLIES.ARTURO_USLAR_PIETRI}
            alt='Arturo Uslar'
            className='img-fluid'
          />

          <img
            width='60%'
            src={ASSETS.ALLIES.USAID}
            alt='USAID'
            className='img-fluid'
          />

          <img
            width='60%'
            src={ASSETS.ALLIES.ARUBA}
            alt='Aruba'
            className='img-fluid'
          />

          <img
            width='60%'
            src={ASSETS.ALLIES.GEORGETOWN}
            alt='GCL'
            className='img-fluid'
          />

          <img
            width='60%'
            src={ASSETS.ALLIES.UNHCR}
            alt='UNHCR'
            className='img-fluid'
          />

          <img
            width='60%'
            src={ASSETS.ALLIES.PADF}
            alt='PADF'
            className='img-fluid'
          />

          <img
            width='60%'
            src={ASSETS.ALLIES.BID}
            alt='BID'
            className='img-fluid'
          />
        </Mesh>
      </Col>
    </Block>
  );
}
