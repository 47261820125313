import { API } from 'api';
import { i18n } from 'dawere-commons';
import {
  AvatarChip,
  Button,
  Col,
  Confirm,
  Pagination,
  Row,
  Skeleton,
  Table,
  toast,
  Visible
} from 'dawere-uic';
import { useOrganization } from 'hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SearchKeyword } from '../../components/SearchKeyword';
import { InviteButton } from '../components/InviteButton';

export function ActiveTab() {
  const { organization } = useOrganization();

  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [sorting, setSorting] = useState('');
  const [keyword, setKeyword] = useState('');

  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [teacherTo, setTeacherTo] = useState<any>(null);

  const handleOpenDelete = useCallback((member: any) => {
    setTeacherTo(member);
    setIsOpenConfirm(true);
  }, []);

  const desktopColumns = useMemo(
    () => [
      {
        Header: i18n('v2.pages.teachers.tabs.1.table.columns.name'),
        accessor: 'id',
        Cell: ({ value, row }: any) => {
          return (
            <AvatarChip size={35} src={row.original?.avatar} gap={2}>
              <div>{row.original?.name}</div>
              <small
                className='d-block text-muted'
                style={{ marginTop: '-2px' }}>
                {row.original?.email}
              </small>
            </AvatarChip>
          );
        }
      },
      {
        Header: i18n('v2.pages.teachers.tabs.1.table.columns.phone'),
        accessor: 'phoneNumber',
        disableSortBy: true
      },
      {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
        unclickable: true,
        Cell: ({ value, row }: any) => {
          return (
            <div className='text-right'>
              <Button onClick={() => handleOpenDelete(row.original)}>
                {i18n('v2.pages.teachers.tabs.1.buttons.delete')}
              </Button>
            </div>
          );
        }
      }
    ],
    [handleOpenDelete]
  );

  const mobileColumns = useMemo(
    () => [
      {
        Header: i18n('v2.pages.teachers.tabs.1.table.columns.member'),
        accessor: 'id',
        Cell: ({ value, row }: any) => {
          return (
            <>
              <AvatarChip align='start' size={50} src={row.original?.avatar}>
                <strong className='mb-0 d-block fs-1'>
                  {row.original?.name}
                </strong>
                <small
                  className='d-block text-muted mb-2'
                  style={{ marginTop: '-2px' }}>
                  {row.original?.email}
                </small>
                <div>{row.original?.phoneNumber}</div>
              </AvatarChip>

              <Button
                block
                className='mt-3'
                onClick={() => handleOpenDelete(row.original)}>
                {i18n('v2.pages.teachers.tabs.1.buttons.delete')}
              </Button>
            </>
          );
        }
      }
    ],
    [handleOpenDelete]
  );

  const load = useCallback(() => {
    if (organization?.id) {
      setIsLoading(true);

      API.Organizations.Teachers.list(organization?.id, {
        page: pageIndex,
        size: pageSize,
        sort: sorting,
        keyword: keyword
      })
        .then((response: any) => {
          setData(response.data);
          setPageCount(response.totalPages);
          setTotal(response.totalRows);
        })
        .finally(() => setIsLoading(false));
    }
  }, [keyword, organization?.id, pageIndex, pageSize, sorting]);

  // Reload data
  useEffect(() => {
    load();
  }, [load]);

  const handlePaging = useCallback(({ pageSize, pageIndex }: any) => {
    setPageSize(pageSize);
    setPageIndex(pageIndex);
  }, []);

  const handleSorting = useCallback((data: any) => {
    const [sort] = data;
    if (sort) {
      const description = `user.name,${sort.desc ? 'desc' : 'asc'}`;
      setSorting(description);
    } else {
      setSorting('');
    }
  }, []);

  const handleSearch = useCallback((data: any) => {
    setKeyword(() => {
      setPageIndex(0);
      return data.search;
    });
  }, []);

  const handleDelete = useCallback(() => {
    API.Organizations.Teachers.remove(organization?.id, teacherTo.id).then(
      () => {
        toast.success(
          i18n('v2.pages.teachers.modals.delete.success', {
            name: teacherTo?.name
          })
        );
        setTeacherTo(null);
        load();
      }
    );
  }, [load, teacherTo?.id, teacherTo?.name, organization?.id]);

  return (
    <>
      <InviteButton organizationId={organization?.id} />

      <SearchKeyword keyword={keyword} onSearch={handleSearch} />

      <Visible xs sm>
        <Table
          data={data}
          columns={mobileColumns}
          loading={isLoading}
          pageable={false}
          manualSorting
          onSorting={handleSorting}
        />
      </Visible>
      <Visible md lg xl>
        <Table
          data={data}
          columns={desktopColumns}
          loading={isLoading}
          pageable={false}
          manualSorting
          onSorting={handleSorting}
        />
      </Visible>
      <Row>
        <Visible lg xl>
          <Col xs={3} />
        </Visible>

        <Col>
          <Pagination
            align='center'
            className='my-4'
            onUpdate={handlePaging}
            pageIndex={pageIndex}
            pageCount={pageCount}
            disabled={isLoading}
          />
        </Col>

        <Visible md lg xl>
          <Col xs={3} className='d-flex align-items-center justify-content-end'>
            {isLoading ? (
              <Skeleton width={120} wrapper='small' />
            ) : (
              <small>
                <strong>{total}</strong>{' '}
                {i18n('v2.pages.teachers.tabs.1.table.count')}
              </small>
            )}
          </Col>
        </Visible>
      </Row>

      <Confirm
        type='warning'
        title={i18n('v2.pages.teachers.modals.delete.title')}
        open={isOpenConfirm}
        onClose={setIsOpenConfirm}
        onConfirm={handleDelete}
        confirmText={i18n('v2.pages.teachers.modals.delete.confirmText')}>
        <p
          dangerouslySetInnerHTML={{
            __html: i18n('v2.pages.teachers.modals.delete.text', {
              name: teacherTo?.name
            })
          }}
        />
      </Confirm>
    </>
  );
}
