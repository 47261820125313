import { Utils } from 'dawere-uic';
import { useContext } from 'react';
import { PermissionsContext } from '../PermissionsProvider';

export const usePermissions = () => {
  const context = useContext(PermissionsContext);

  if (Utils.isEmpty(context)) {
    throw new Error(
      '"usePermissions" must be used within a PermissionsProvider'
    );
  }

  return context;
};
