import { useQuery } from '@tanstack/react-query';
import { API } from 'api';
import { SubjectCardLink } from 'components';
import { i18n, Session } from 'dawere-commons';
import {
  Col,
  Empty,
  Mesh,
  Row,
  SubjectCard,
  useRouter,
  Utils
} from 'dawere-uic';
import { useCallback } from 'react';
import { PATHS } from 'router';
import { autofill, QPARAMS } from 'values';

export function SubjectsTab({ enrollment }: any) {
  const { query } = useRouter();

  const { program } = enrollment;
  const subjectsQty = enrollment?.subjects?.length || 0;
  const withCalendar = program?.byCalendar;

  const {
    data: progress,
    isLoading: isLoadingProgress,
    isFetching: isFetchingProgress
  } = useQuery({
    enabled: !!enrollment?.id && !!Session.getAccountInfo(),
    queryKey: ['enrollment-progress', enrollment?.id],
    queryFn: () => API.Students.getEnrollmentProgress(enrollment?.id)
  });

  const {
    data: teachers,
    isLoading: isLoadingTeachers,
    isFetching: isFetchingTeachers
  } = useQuery({
    enabled: !!enrollment?.id && !!Session.getAccountInfo(),
    queryKey: ['enrollment-teachers', enrollment?.id],
    queryFn: () => API.Students.getEnrollmentTeachers(enrollment?.id)
  });

  const getProgressVal = useCallback(
    (subjectId: string, field: string) => {
      return progress?.[subjectId]?.[field] ?? 0;
    },
    [progress]
  );

  const getTeachersVal = useCallback(
    (subjectId: string, field: string) => {
      return teachers?.[subjectId]?.[field];
    },
    [teachers]
  );

  return (
    <>
      <Row align='center'>
        <Col xs={12} md={6}></Col>
        {enrollment?.calendar && (
          <Col xs={12} md={6} className='fs-1 text-muted text-md-right mb-4'>
            {i18n('v2.pages.studentDetails.tabs.1.calendar')}{' '}
            {Utils.formatDate(enrollment?.calendar?.dateInit, 'dd/mmm/yyyy')} -{' '}
            {Utils.formatDate(enrollment?.calendar?.dateEnd, 'dd/mmm/yyyy')}
          </Col>
        )}
      </Row>

      <Row className='mb-4'>
        <Col>
          {subjectsQty > 0 ? (
            <Mesh gap={3}>
              {enrollment?.subjects?.map(({ id, ...subject }: any) => (
                <SubjectCardLink
                  key={id}
                  className='fade-animation'
                  to={PATHS.get(
                    'CLASSROOM_VARIANT',
                    {
                      enrollmentId: enrollment?.id,
                      subjectId: id
                    },
                    {
                      [QPARAMS.Student]: query?.studentId,
                      [QPARAMS.Back]: encodeURIComponent(
                        PATHS.get(
                          'STUDENT_VIEW',
                          { studentId: query?.studentId },
                          autofill(query, [QPARAMS.Organization])
                        )
                      )
                    }
                  )}>
                  <SubjectCard
                    title={subject.title}
                    thumbnail={subject.icon}
                    description={subject.period?.name}
                    teacher={Utils.getFullname(
                      getTeachersVal(id, 'name'),
                      getTeachersVal(id, 'lastName')
                    )}
                    avatar={getTeachersVal(id, 'picturePath')}
                    score={getProgressVal(id, 'averageScore')}
                    maxScore={program.maxScore}
                    grade={getProgressVal(id, 'averageGrade')}
                    maxGrade={program.maxGrade}
                    progressStatus={getProgressVal(id, 'progressStatus')}
                    completedProgress={getProgressVal(id, 'completedProgress')}
                    expectedProgress={getProgressVal(id, 'expectedProgress')}
                    progressTotal={getProgressVal(id, 'progressTotal')}
                    evaluationsTaken={getProgressVal(id, 'evaluationsTaken')}
                    totalEvaluations={getProgressVal(id, 'totalEvaluations')}
                    loadingProgress={isLoadingProgress || isFetchingProgress}
                    loadingTeacher={isLoadingTeachers || isFetchingTeachers}
                    hideStatus={!withCalendar}
                    approved={subject.approved && !withCalendar}
                  />
                </SubjectCardLink>
              ))}
            </Mesh>
          ) : (
            <Empty />
          )}
        </Col>
      </Row>
    </>
  );
}
