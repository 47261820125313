import { API } from 'api';
import { i18n, Session } from 'dawere-commons';
import {
  AutoComplete,
  Block,
  Button,
  Callout,
  Col,
  DatePicker,
  Divider,
  Form,
  Header,
  Input,
  Phone,
  ProfilePic,
  Row,
  toast,
  Utils
} from 'dawere-uic';
import { useGeo, useSession } from 'hooks';
import { PageWrapper } from 'pages';
import React, { useCallback, useEffect, useState } from 'react';
import { getFullCountryInfo, getSEO } from 'utils';
import { ConfirmPhoneModal } from './sections/ConfirmPhoneModal';
import { createPayload } from './utils/createPayload';
import { getProfile } from './utils/getProfile';

/**
 * Path: /perfil
 * Key: PROFILE
 * @returns {React.ReactElement}
 */
export default function ProfilePage() {
  const { getMe, setUser } = useSession();

  const account = Session.getAccountInfo();

  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [profile, setProfile] = useState<any>(() => getProfile());
  const [picturePath, setPicturePath] = useState(account?.picturePath);

  const [openModal, setOpenModal] = useState(false);

  const [picklists, setPicklists] = useState({
    isLoadingIdentityTypes: false,
    isLoadingGenders: false,
    isLoadingRaces: false,
    isLoadingFoundAboutUs: false,
    isLoaingSpecialNeeds: false,
    identityTypes: [],
    genders: [],
    races: [],
    foundAboutUs: [],
    specialNeeds: []
  });

  const geo = useGeo(profile?.country, profile?.state);
  const birthGeo = useGeo(profile?.birthCountry, profile?.birthState, false);

  // Identity Types
  useEffect(() => {
    setPicklists((s: any) => ({ ...s, isLoadingIdentityTypes: true }));
    API.Picklists.getDocTypes()
      .then((response) => {
        setPicklists((s: any) => ({ ...s, identityTypes: response }));
      })
      .finally(() =>
        setPicklists((s: any) => ({ ...s, isLoadingIdentityTypes: false }))
      );
  }, []);

  // Genders
  useEffect(() => {
    setPicklists((s: any) => ({ ...s, isLoadingGenders: true }));
    API.Picklists.getGenders()
      .then((response) => {
        setPicklists((s: any) => ({ ...s, genders: response }));
      })
      .finally(() =>
        setPicklists((s: any) => ({ ...s, isLoadingGenders: false }))
      );
  }, []);

  // Races
  useEffect(() => {
    setPicklists((s: any) => ({ ...s, isLoadingRaces: true }));
    API.Picklists.getRaces()
      .then((response) => {
        setPicklists((s: any) => ({ ...s, races: response }));
      })
      .finally(() =>
        setPicklists((s: any) => ({ ...s, isLoadingRaces: false }))
      );
  }, []);

  // Found About Us
  useEffect(() => {
    setPicklists((s: any) => ({ ...s, isLoadingFoundAboutUs: true }));
    API.Picklists.getFoundAboutUs()
      .then((response) => {
        setPicklists((s: any) => ({ ...s, foundAboutUs: response }));
      })
      .finally(() =>
        setPicklists((s: any) => ({ ...s, isLoadingFoundAboutUs: false }))
      );
  }, []);

  // Special Needs
  useEffect(() => {
    setPicklists((s: any) => ({ ...s, isLoaingSpecialNeeds: true }));
    API.Picklists.getSpecialNeeds()
      .then((response) => {
        setPicklists((s: any) => ({ ...s, specialNeeds: response }));
      })
      .finally(() =>
        setPicklists((s: any) => ({ ...s, isLoaingSpecialNeeds: false }))
      );
  }, []);

  const handleSubmit = useCallback(
    (data: any) => {
      const payload = createPayload(data);

      setIsLoading(true);

      API.Account.updateProfile(payload)
        .then(() => {
          if (
            (payload?.phoneNumber &&
              account?.phoneNumber !== payload.phoneNumber) ||
            (payload.phoneCode && account?.phoneCode !== payload.phoneCode)
          ) {
            setOpenModal(true);
          }
          toast.success(i18n('v2.pages.profile.toasts.successUpdate'));
          getMe();
        })
        .finally(() => setIsLoading(false));
    },
    [account?.phoneCode, account?.phoneNumber, getMe]
  );

  const handleUpload = useCallback(
    (data: any) => {
      setIsUploading(true);

      API.Account.uploadPicture({
        content: data.base64,
        fileExtension: data.extension
      })
        .then((response: any) => {
          Session.setAvatar(response.data?.picturePath);
          setPicturePath(response.data?.picturePath);
          setUser(Session.getAccountInfo());
        })
        .finally(() => setIsUploading(false));
    },
    [setUser]
  );

  const handleModalClose = useCallback(
    (message?: string | boolean) => {
      if (message !== 'success') {
        // Rollback phone number
        setProfile({
          ...profile,
          phoneCode: account?.phoneCode || getFullCountryInfo().dialCode,
          phoneNumber: account?.phoneNumber || null
        });
      } else {
        // Update account phone number
        account.phoneCode = profile.phoneCode;
        account.phoneNumber = profile.phoneNumber;
        Session.setAccountInfo(account);
        toast.success(i18n('v2.pages.profile.toasts.successPhoneNumber'));
      }
      setOpenModal(false);
    },
    [account, profile]
  );

  return (
    <PageWrapper>
      {getSEO('v2.pages.profile.seo', true)}

      <Block narrow>
        <Col xs={12}>
          <Header
            withoutAvatar
            title={i18n('v2.pages.profile.title')}
            subtext={[i18n('v2.pages.profile.subtitle')]}
          />
        </Col>

        <Col xs={12} className='mb-6'>
          <Callout className='bg-platinium px-3 pt-3 pb-2 text-center'>
            <ProfilePic
              src={picturePath}
              size={160}
              onChange={handleUpload}
              loading={isUploading}
            />
          </Callout>
        </Col>

        <Col>
          <Form onSubmit={handleSubmit} removeDialCode={false}>
            {/* Información de contacto */}
            <Row>
              <Col xs={12}>
                <Divider section>
                  {i18n('v2.pages.profile.section1Title')}
                </Divider>
              </Col>

              <Col xs={12} md={6}>
                <Input
                  loading={isLoading}
                  name='name'
                  label={i18n('v2.pages.profile.form.name')}
                  value={profile?.name}
                  disabled={account?.userDetails?.dataVerified}
                  validators={{
                    required: true
                  }}
                />
              </Col>
              <Col xs={12} md={6}>
                <Input
                  loading={isLoading}
                  name='lastName'
                  label={i18n('v2.pages.profile.form.lastName')}
                  value={profile?.lastName}
                  disabled={account?.userDetails?.dataVerified}
                  validators={{
                    required: true
                  }}
                />
              </Col>
              <Col xs={12} md={6}>
                <Phone
                  loading={isLoading}
                  name='phoneNumber'
                  label={i18n('v2.pages.profile.form.phone')}
                  value={profile?.phoneNumber}
                  codeValue={profile?.phoneCode}
                  onChange={({ code, value }) => {
                    setProfile({
                      ...profile,
                      phoneCode: code,
                      phoneNumber: value
                    });
                  }}
                  validators={{
                    minLength: 5,
                    maxLength: 15,
                    phone: true,
                    required: !Utils.isEmpty(account?.phoneNumber)
                  }}
                  helpText={i18n('v2.pages.profile.helps.phoneNumber')}
                />
              </Col>
              <Col xs={12} md={6}>
                <Input
                  loading={isLoading}
                  name='email'
                  label={i18n('v2.pages.profile.form.email')}
                  readOnly
                  value={account?.email}
                />
              </Col>
            </Row>

            {/* Información de identidad */}
            <Row>
              <Col xs={12}>
                <Divider section>
                  {i18n('v2.pages.profile.section2Title')}
                </Divider>
              </Col>

              <Col xs={12} md={6}>
                <AutoComplete
                  loading={picklists.isLoadingIdentityTypes || isLoading}
                  options={picklists.identityTypes}
                  name='identityType'
                  label={i18n('v2.pages.profile.form.identityType')}
                  value={profile?.identityType}
                  onChange={(data: any) => {
                    setProfile({
                      ...profile,
                      identityType: data
                    });
                  }}
                  disabled={account?.userDetails?.dataVerified}
                  validators={{
                    required: !Utils.isEmpty(account?.userDetails?.identityType)
                  }}
                />
              </Col>

              <Col xs={12} md={6}>
                <Input
                  loading={isLoading}
                  name='identityCard'
                  label={i18n('v2.pages.profile.form.indetityNumber')}
                  value={profile?.identityCard}
                  disabled={account?.userDetails?.dataVerified}
                  validators={{
                    required: !Utils.isEmpty(account?.userDetails?.identityCard)
                  }}
                />
              </Col>

              {account?.student ? (
                <Col xs={12} md={6}>
                  <AutoComplete
                    loading={picklists.isLoadingGenders || isLoading}
                    options={picklists.genders}
                    name='gender'
                    label={i18n('v2.pages.profile.form.gender')}
                    value={profile?.gender}
                    onChange={(data: any) => {
                      setProfile({
                        ...profile,
                        gender: data
                      });
                    }}
                    validators={{
                      required: !Utils.isEmpty(account?.userDetails?.gender)
                    }}
                  />
                </Col>
              ) : null}

              {account?.student ? (
                <Col xs={12} md={6}>
                  <AutoComplete
                    loading={picklists.isLoadingRaces || isLoading}
                    options={picklists.races}
                    name='race'
                    label={i18n('v2.pages.profile.form.race')}
                    value={profile?.race}
                    onChange={(data: any) => {
                      setProfile({
                        ...profile,
                        race: data
                      });
                    }}
                    validators={{
                      required: !Utils.isEmpty(account?.userDetails?.race)
                    }}
                  />
                </Col>
              ) : null}
            </Row>

            {/* Información de nacimiento */}
            {account?.student ? (
              <Row>
                <Col xs={12}>
                  <Divider section>
                    {i18n('v2.pages.profile.section3Title')}
                  </Divider>
                </Col>

                <Col xs={12} md={6}>
                  <AutoComplete
                    loading={geo.isLoadingCountries || isLoading}
                    options={geo.countries}
                    name='birthCountry'
                    label={i18n('v2.pages.profile.form.birthCountry')}
                    value={profile?.birthCountry}
                    onChange={(data: any) => {
                      setProfile({
                        ...profile,
                        birthCountry: data,
                        birthState: null,
                        birthTownship: null
                      });
                    }}
                    validators={{
                      required: !Utils.isEmpty(
                        account?.userDetails?.birthCountry
                      )
                    }}
                  />
                </Col>

                <Col xs={12} md={6}>
                  <AutoComplete
                    loading={birthGeo.isLoadingStates || isLoading}
                    options={birthGeo.states}
                    name='birthState'
                    label={i18n('v2.pages.profile.form.birthState')}
                    value={profile?.birthState}
                    onChange={(data: any) => {
                      setProfile({
                        ...profile,
                        birthState: data,
                        birthTownship: null
                      });
                    }}
                    disabled={!profile?.birthCountry}
                    validators={{
                      required: !Utils.isEmpty(account?.userDetails?.birthState)
                    }}
                  />
                </Col>

                <Col xs={12} md={6}>
                  <AutoComplete
                    loading={birthGeo.isLoadingTownships || isLoading}
                    options={birthGeo.townships}
                    name='birthTownship'
                    label={i18n('v2.pages.profile.form.birthTownship')}
                    value={profile?.birthTownship}
                    onChange={(data: any) => {
                      setProfile({
                        ...profile,
                        birthTownship: data
                      });
                    }}
                    disabled={!profile?.birthState}
                    validators={{
                      required: !Utils.isEmpty(
                        account?.userDetails?.birthTownship
                      )
                    }}
                  />
                </Col>

                <Col xs={12} md={6}>
                  <DatePicker
                    loading={isLoading}
                    name='birthdate'
                    label={i18n('v2.pages.profile.form.birthday')}
                    startDate={profile?.birthdate}
                    onChange={(data: any) => {
                      setProfile({
                        ...profile,
                        birthdate: data
                      });
                    }}
                    disabled={account?.userDetails?.dataVerified}
                    validators={{
                      required: !Utils.isEmpty(account?.userDetails?.birthdate)
                    }}
                  />
                </Col>
              </Row>
            ) : null}

            {/* Informacion de residencia */}
            <Row>
              <Col xs={12}>
                <Divider section>
                  {i18n('v2.pages.profile.section4Title')}
                </Divider>
              </Col>

              <Col xs={12} md={6}>
                <AutoComplete
                  loading={geo.isLoadingCountries || isLoading}
                  options={geo.countries}
                  name='country'
                  label={i18n('v2.pages.profile.form.country')}
                  value={profile?.country}
                  onChange={(data: any) => {
                    setProfile({
                      ...profile,
                      country: data,
                      state: null,
                      township: null
                    });
                  }}
                  validators={{
                    required: !Utils.isEmpty(account?.userDetails?.country)
                  }}
                />
              </Col>

              <Col xs={12} md={6}>
                <AutoComplete
                  loading={geo.isLoadingStates || isLoading}
                  options={geo.states}
                  name='state'
                  label={i18n('v2.pages.profile.form.state')}
                  value={profile?.state}
                  onChange={(data: any) => {
                    setProfile({
                      ...profile,
                      state: data,
                      township: null
                    });
                  }}
                  disabled={!profile?.country}
                  validators={{
                    required: !Utils.isEmpty(account?.userDetails?.state)
                  }}
                />
              </Col>

              <Col xs={12} md={6}>
                <AutoComplete
                  loading={geo.isLoadingTownships || isLoading}
                  options={geo.townships}
                  name='township'
                  label={i18n('v2.pages.profile.form.township')}
                  value={profile?.township}
                  onChange={(data: any) => {
                    setProfile({
                      ...profile,
                      township: data
                    });
                  }}
                  disabled={!profile?.state}
                  validators={{
                    required: !Utils.isEmpty(account?.userDetails?.township)
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Divider section>
                  {i18n('v2.pages.profile.section5Title')}
                </Divider>
              </Col>

              <Col xs={12} md={6}>
                <AutoComplete
                  loading={picklists.isLoadingFoundAboutUs || isLoading}
                  options={picklists.foundAboutUs}
                  name='foundAboutUs'
                  label={i18n('v2.pages.profile.form.howFoundUs')}
                  value={profile?.foundAboutUs}
                  onChange={(data: any) => {
                    setProfile({
                      ...profile,
                      foundAboutUs: data
                    });
                  }}
                  validators={{
                    required: !Utils.isEmpty(account?.userDetails?.foundAboutUs)
                  }}
                />
              </Col>

              {account?.student ? (
                <Col xs={12} md={6}>
                  <AutoComplete
                    loading={picklists.isLoaingSpecialNeeds || isLoading}
                    options={picklists.specialNeeds}
                    name='specialNeeds'
                    label={i18n('v2.pages.profile.form.specialNeeds')}
                    value={profile?.specialNeeds}
                    onChange={(data: any) => {
                      setProfile({
                        ...profile,
                        specialNeeds: data
                      });
                    }}
                    multiple
                    validators={{
                      required: !Utils.isEmpty(
                        account?.userDetails?.specialNeeds
                      )
                    }}
                  />
                </Col>
              ) : null}

              <Col xs={12}>
                <Button type='submit' loading={isLoading}>
                  {i18n('v2.pages.profile.buttons.submit')}
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Block>

      <ConfirmPhoneModal
        open={openModal}
        onClose={handleModalClose}
        phoneCode={profile?.phoneCode}
        phoneNumber={profile?.phoneNumber}
      />
    </PageWrapper>
  );
}
