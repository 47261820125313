import { API } from 'api';
import { i18n } from 'dawere-commons';
import { Button, Form, Input, Modal, toast, Utils } from 'dawere-uic';
import { useCallback, useState } from 'react';

export function ModalAddMember({
  organizationId,
  open,
  onClose,
  onSuccess = () => {}
}: any) {
  const [email, setEmail] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(
    (data: any) => {
      setIsLoading(true);
      API.Organizations.Members.invite(organizationId, { email })
        .then((response) => {
          toast.success(i18n('v2.pages.members.modals.add.success', { email }));
          onClose(false);
          onSuccess();
        })
        .finally(() => setIsLoading(false));
    },
    [email, onClose, onSuccess, organizationId]
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      closable={isLoading ? false : true}
      keyboard={isLoading ? false : true}
      type='email'
      title={i18n('v2.pages.members.modals.add.title')}
      backdrop='static'>
      <p>{i18n('v2.pages.members.modals.add.text')}</p>

      <Form onSubmit={handleSubmit}>
        <Input
          type='email'
          name='email'
          placeholder={i18n('v2.pages.members.modals.add.placeholder')}
          disabled={isLoading}
          onChange={(e: any) => setEmail(e.target.value)}
          validators={{
            required: true,
            email: true
          }}
        />

        <Button
          type='submit'
          block
          disabled={!Utils.isEmail(email)}
          loading={isLoading}>
          {i18n('v2.pages.members.modals.add.button')}
        </Button>
      </Form>
    </Modal>
  );
}
