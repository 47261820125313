import esMX from './es-MX';

const enMX = {
  ...esMX,
  lang: `en-MX,en`,
  shortLang: 'en-MX'
};

export default enMX;

export { enMX };
