const esMX = {
  lang: `es-MX,es`,
  shortLang: 'es-MX',
  pages: {
    home: {
      seo: {
        title: `Dawere México | Escuela Virtual de Calidad`,
        description: `Educación prémium, asequible y certificada por la Secretaría de Educación Pública de México. Continúa tus estudios con la mejor educación virtual de América Latina. `,
        keywords: `escuela, colegio`,
        ogTitle: `Dawere México | Escuela Virtual de Calidad`,
        ogDescription: `Educación prémium, asequible y certificada por la Secretaría de Educación Pública de México. Continúa tus estudios con la mejor educación virtual de América Latina.`,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/seo_homepage.jpg`
      },
      mainProgramId: {
        dev: `preparatoria-virtual-mexico`,
        qa: `preparatoria-virtual-mexico`,
        prod: `preparatoria-virtual-mexico`
      },
      doubleDegreeProgramId: {
        dev: `bachillerato-virtual-estados-unidos`,
        qa: `bachillerato-virtual-estados-unidos`,
        prod: `bachillerato-virtual-estados-unidos`
      },
      info: {
        whatsappNumber: `+52 1 55 1452 8499`,
        phoneNumber: `+52 1 55 1452 8499`,
        emailInfo: `inscripcion@dawere.com.mx`
      },
      section1: {
        title: `¡Ahora sí puedes graduarte! Cursa tu preparatoria virtualmente`,
        subtitle: `Preparatoria: Dawere México`,
        buttons: {
          inscription: `Pruébala gratis`
        }
      },
      section2: {
        title: `El certificado de preparatoria  es avalado por la SEP`,
        text: `
          <p>Obtén tu certificado de preparatoria avalado por la Secretaría de Educación Pública de México.</p>

          <p>La plataforma de educación formal online Dawere y la Universidad Santander han formado una alianza educativa para traerte la mejor  Preparatoria Virtual.</p>

          <p>La Universidad Santander está registrada ante la Secretaría de Educación Pública de México bajo el RVOE EMS1302021.</p>
        `,
        images: {
          1: {
            url: `https://storage.googleapis.com/dawere-web-assets/logotipos/Logo_Secretaria%20de%20educaci%C3%B3n%20de%20M%C3%A9xico-01.png`,
            alt: `SECRETARIA DE EDUCACION PUBLICA DE MEXICO`
          }
        }
      },
      section3: {
        title: `Beneficios de estudiar la preparatoria con nosotros`,
        benefits: {
          3: {
            description: `La preparatoria puede ser cursada en 2 años o menos`
          }
        }
      },
      section6: {
        steps: {
          1: {
            text: ` Si tienes 15 años de edad o más puedes inscribirte en Dawere sin importar tu ubicación geográfica. Solo debes llenar el formulario de inscripción, señalar los años aprobados hasta la fecha y realizar tu pago.`
          },
          2: {
            title: `Sube tus requisitos`,
            text: `Debes subir de forma digital el CURP y el acta de nacimiento, entre otros recaudos.`
          },
          4: {
            text: `Al aprobar todas las materias de rigor y asistir a tu ceremonia de graduación virtual, podrás retirar tu título en la Universidad Santander o solicitar que se te envíe hasta la puerta de tu casa.`
          }
        }
      },
      section9: {
        categories: {
          3: {
            text: `Estudia en una escuela virtual de calidad y obtén tu certificado de preparatoria avalado por la SEP. Estudiarás de forma rápida y divertida a través de videoclases pregrabadas, material de apoyo, evaluaciones de práctica, un foro-chat para aclarar tus dudas y más. Regístrate para recibir más información.`
          }
        }
      },
      section12: {
        text: `Dawere tiene una variedad de programas educativos y cursos además del programa de Preparatoria Virtual. Entra en nuestro catálogo y explora nuestra oferta.`
      }
    },
    catalog: {
      seo: {
        title: `Programas Educativos de México |Registrados en la SEP`,
        description: `Explora el catálogo de programas educativos y descubre las bondades de una educación virtual que se ajusta a tu ritmo y horario. Ofrecemos becas a quienes califiquen. `,
        keywords: `SEP, Secretaria de educacion, educacion en Mexico`,
        ogTitle: `Programas Educativos de México | Registrados en la SEP`,
        ogDescription: `Explora el catálogo de programas educativos y descubre las bondades de una educación virtual que se ajusta a tu ritmo y horario. Ofrecemos becas a quienes califiquen.  `,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/seo_logo_secreteducac_mexico.jpg`
      }
    },
    organizations: {
      seo: {
        title: `Dawere México | Para Organizaciones`,
        keywords: `organizaciones, sistema educativo, fundaciones`,
        ogTitle: `Dawere México para Organizaciones`
      },
      section2: {
        subtitle: `Descubre por qué fundaciones, empresas y gobiernos eligen la Preparatoria Virtual de Dawere para la formación de sus ciudadanos o beneficiarios.`
      },
      section4: {
        governments: {
          text: `
             <p>
              Según la UNESCO, en los sistemas educativos tradicionales de América Latina, solo 45% de los que inician el bachillerato se gradúan.
              </p>
            <p>
              Hay una deserción del 15% de los estudiantes todos los años y más del 30% de los jóvenes en edad escolar están fuera del sistema educativo tradicional. Con el uso de nuestra tecnología educativa, esto puede cambiar.
              </p>

            <p>
              Te invitamos a proveerle a todos tus ciudadanos educación de calidad gratuita. Parece una propuesta utópica pero se puede convertir en realidad gracias a los servicios de educación a distancia de Dawere. Nuestra Preparatoria Virtual ha sido diseñada para educar a millones de personas a una fracción del costo de la educación tradicional presencial. Compruébalo por ti mismo y empodera al país con la excelente educación que todos merecen.
              </p>
          `
        },
        companies: {
          text: `
            <p>
              El mundo del deporte profesional es extremadamente reñido, por lo que solo un porcentaje minúsculo logra convertirse en jugador profesional. ¿Qué ocurre con el resto?
            </p>

            <p>
              Recibir educación de calidad en paralelo a los entrenamientos puede garantizarle un futuro a todos tus jugadores sin excepción.
            </p>

            <p>
              Nuestra Preparatoria Virtual se adapta al ritmo y horario de los estudiantes. Con Dawere tus jugadores pueden formarse académicamente y perseguir sus sueños.
            </p>
          `
        }
      }
    },
    shared: {
      contact: {
        info: {
          phone: {
            title: `TELÉFONO`,
            text: ``
          },
          whatsapp: {
            title: `WHATSAPP`,
            text: `+52 1 55 1452 8499`
          },
          email: {
            title: `EMAIL`,
            text: ``
          }
        },
        buttons: {
          faq: `Preguntas frecuentes`
        }
      }
    },
    helpCenter: {
      seo: {
        keywords: `dawere, dawere mexico`
      },
      urls: {
        scholarshipForm: `https://forms.gle/L4XFHXP8NDAzLpg7A`
      },
      contact: {
        info: {
          enrolled: {
            whatsapp: `+52 1 55 1452 8499`
          },
          unregistered: {
            whatsapp: `+52 1 55 1452 8499`
          }
        }
      }
    },
    philosophy: {
      seo: {
        keywords: `Educacion, modelo educativo, calidad educativa, educacion de calidad`
      }
    }
  },
  sections: {
    footer: {
      column1: {
        text: `<p>La plataforma de educación formal online Dawere y la Universidad Santander han formado una alianza para traerte la mejor  experiencia de aprendizaje virtual. </p>
        <p>La Universidad Santander está registrada ante la Secretaría de Educación Pública de México bajo el RVOE EMS1302021. </p>`
      }
    }
  }
};

export default esMX;

export { esMX };
