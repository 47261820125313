import { OrderBox } from 'components';
import { usePayment } from '../hooks/usePayment';

export function SummaryCard() {
  const { isLoading, data, walletBalance } = usePayment();

  return (
    <OrderBox
      className='mb-md-6'
      loading={isLoading}
      currency={data?.currency}
      lines={data?.lines}
      discounts={data?.order?.discounts}
      taxBase={data?.taxBase}
      taxes={data?.taxes}
      total={data?.amount}
      earlyPaymentDiscount={data?.order?.earlyPaymentDiscountAmt}
      detail
      totalDiscounts={data?.discountAmt}
      walletBalance={walletBalance}
    />
  );
}
