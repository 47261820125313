import { i18n } from 'dawere-commons';
import { Button, Modal } from 'dawere-uic';
import { Link } from 'react-router-dom';

export default function ErrorModal({
  open = false,
  onClose = () => {},
  redirectTo = '/'
}: any) {
  return (
    <Modal
      type='warning'
      open={open}
      backdrop='static'
      onClose={onClose}
      closable={false}
      keyboard={false}
      title={i18n('v2.pages.buyCoupons.steps.2.creditCard.modalError.title')}
      footer={
        <Button block as={Link} to={redirectTo}>
          {i18n('v2.pages.buyCoupons.steps.2.creditCard.modalError.button')}
        </Button>
      }>
      <p
        dangerouslySetInnerHTML={{
          __html: i18n(
            'v2.pages.buyCoupons.steps.2.creditCard.modalError.description'
          )
        }}
      />
    </Modal>
  );
}
