import { API } from 'api';
import { Accordion, Col, Empty, Row, Skeleton, useRouter } from 'dawere-uic';
import { useEffect, useState } from 'react';
import { EnrollmentAccordion } from './EnrollmentAccordion';

export function MainWrapper() {
  const { query } = useRouter();

  const [isLoading, setIsLoading] = useState(true);
  const [enrollments, setEnrollments] = useState<any[]>([]);

  useEffect(() => {
    if (query?.studentId) {
      setIsLoading(true);

      API.Students.getAllEnrollments({
        student_id: query?.studentId,
        latest: true
      })
        .then((response: any[]) => setEnrollments(response))
        .finally(() => setIsLoading(false));
    }
  }, [query]);

  return (
    <Col xs={12}>
      {/* <h3 className='mb-4'>
        {i18n('v2.pages.studentDetails.title')}
      </h3> */}
      {isLoading ? (
        <>
          <Skeleton height={50} width='100%' className='mb-4' />

          <Row>
            <Col xs={12} md={6} lg={4} className='mb-4'>
              <Skeleton.SubjectCard />
            </Col>
            <Col xs={12} md={6} lg={4} className='mb-4'>
              <Skeleton.SubjectCard />
            </Col>
            <Col xs={12} md={6} lg={4} className='mb-4'>
              <Skeleton.SubjectCard />
            </Col>
          </Row>
        </>
      ) : (
        <>
          {enrollments?.length ? (
            enrollments?.map((enrollment: any, index) => (
              <Accordion
                key={enrollment.program?.id}
                expanded={index === 0}
                summary={
                  <>
                    {enrollment.program?.name}{' '}
                    {enrollment.school?.name
                      ? `— ${enrollment.school?.name}`
                      : ''}
                  </>
                }>
                <EnrollmentAccordion enrollment={enrollment} />
              </Accordion>
            ))
          ) : (
            <Empty />
          )}
        </>
      )}
    </Col>
  );
}
