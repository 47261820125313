import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { i18n } from 'dawere-commons';
import { Utils } from 'dawere-uic';
import { colors } from 'dawere-uic/lib/styles/colors';
import { PDF_BASE_FONT_SIZE, PDF_MUTED_COLOR } from 'values';

const styles = StyleSheet.create({
  head: {
    backgroundColor: colors.powderTint,
    fontSize: PDF_BASE_FONT_SIZE,
    flexDirection: 'row',
    borderRadius: 4,
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  body: {
    fontSize: PDF_BASE_FONT_SIZE,
    flexDirection: 'row',
    alignItems: 'center'
  },
  foot: {
    fontSize: PDF_BASE_FONT_SIZE,
    flexDirection: 'row'
  }
});

const perc = ['', '54%', '10%', '18%', '18%'];

export function TableSection({ data }: any) {
  const currency = data?.currency;

  return (
    <>
      <View style={styles.head}>
        <View style={{ padding: 10, textAlign: 'center' }}>
          <Text>{i18n('v2.pages.pdfReceipt.table.columns.lineNumber')}</Text>
        </View>
        <View style={{ padding: 10, width: perc[1] }}>
          <Text>{i18n('v2.pages.pdfReceipt.table.columns.product')}</Text>
        </View>
        <View style={{ padding: 10, width: perc[2], textAlign: 'center' }}>
          <Text>{i18n('v2.pages.pdfReceipt.table.columns.quantity')}</Text>
        </View>
        <View style={{ padding: 10, width: perc[3], textAlign: 'right' }}>
          <Text>{i18n('v2.pages.pdfReceipt.table.columns.price')}</Text>
        </View>
        <View style={{ padding: 10, width: perc[4], textAlign: 'right' }}>
          <Text>{i18n('v2.pages.pdfReceipt.table.columns.total')}</Text>
        </View>
      </View>
      {data?.lines.map((line: any, index: number) => (
        <View key={index} style={styles.body}>
          <View
            style={{
              padding: 10,
              textAlign: 'center'
            }}>
            <Text
              style={{
                fontSize: PDF_BASE_FONT_SIZE * 0.85
              }}>
              {index + 1}
            </Text>
          </View>
          <View style={{ padding: 10, width: perc[1] }}>
            <Text
              style={{
                fontSize: PDF_BASE_FONT_SIZE
              }}>
              {line.description}
            </Text>
          </View>
          <View style={{ padding: 10, width: perc[2], textAlign: 'center' }}>
            <Text
              style={{
                fontSize: PDF_BASE_FONT_SIZE
              }}>
              {line.qty}
            </Text>
          </View>
          <View style={{ padding: 10, width: perc[3], textAlign: 'right' }}>
            <Text
              style={{
                fontSize: PDF_BASE_FONT_SIZE
              }}>
              {currency?.symbol}
              {Utils.toCurrency(line.listPrice)}
            </Text>
          </View>
          <View style={{ padding: 10, width: perc[4], textAlign: 'right' }}>
            <Text
              style={{
                fontSize: PDF_BASE_FONT_SIZE
              }}>
              {currency?.symbol}
              {Utils.toCurrency(line.listPrice * line.qty)}
            </Text>
          </View>
        </View>
      ))}

      {data?.order?.earlyPaymentDiscountAmt ? (
        <View style={styles.foot}>
          <View style={{ width: '65%' }}></View>
          <View
            style={{
              padding: '6px 10px',
              width: '35%',
              flexDirection: 'row',
              justifyContent: 'space-between',
              borderTop: '1px dotted #ccc'
            }}>
            <Text
              style={{
                color: PDF_MUTED_COLOR,
                fontSize: PDF_BASE_FONT_SIZE * 0.9
              }}>
              {i18n('v2.pages.pdfReceipt.table.earlyPayment')}
            </Text>
            <Text>
              -{currency?.symbol}
              {Utils.toCurrency(data?.order?.earlyPaymentDiscountAmt)}
            </Text>
          </View>
        </View>
      ) : null}

      {data?.discountAmt > 0 ? (
        <View style={styles.foot}>
          <View style={{ width: '65%' }}></View>
          <View
            style={{
              padding: '6px 10px',
              width: '35%',
              flexDirection: 'row',
              justifyContent: 'space-between',
              borderTop: '1px dotted #ccc'
            }}>
            <Text
              style={{
                color: PDF_MUTED_COLOR,
                fontSize: PDF_BASE_FONT_SIZE * 0.9
              }}>
              {i18n('v2.pages.pdfReceipt.table.discounts')}
            </Text>
            <Text>
              -{currency?.symbol}
              {Utils.toCurrency(data?.discountAmt)}
            </Text>
          </View>
        </View>
      ) : null}

      <View style={styles.foot}>
        <View style={{ width: '65%' }}></View>
        <View
          style={{
            padding: '3px 10px',
            width: '35%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            borderTop: '1px dotted #ccc'
          }}>
          <Text
            style={{
              color: PDF_MUTED_COLOR,
              fontSize: PDF_BASE_FONT_SIZE * 0.9
            }}>
            {i18n('v2.pages.pdfReceipt.table.subTotal')}
          </Text>
          <Text>
            {currency?.symbol}
            {Utils.toCurrency(data?.taxBase)}
          </Text>
        </View>
      </View>

      {Object.entries(data?.taxes || {}).map(
        ([key, value]: any, index: number) => (
          <View key={index} style={styles.foot}>
            <View style={{ width: '65%' }}></View>
            <View
              style={{
                padding: '3px 10px',
                width: '35%',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}>
              <Text
                style={{
                  color: PDF_MUTED_COLOR,
                  textTransform: 'uppercase',
                  fontSize: PDF_BASE_FONT_SIZE * 0.9
                }}>
                {key}
              </Text>
              <Text>
                {currency?.symbol}
                {Utils.toCurrency(value)}
              </Text>
            </View>
          </View>
        )
      )}

      <View style={styles.foot}>
        <View style={{ width: '65%' }}></View>
        <View
          style={{
            padding: '6px 10px',
            width: '35%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: colors.cultured,
            borderRadius: 4
          }}>
          <Text style={{ color: PDF_MUTED_COLOR }}>
            {i18n('v2.pages.pdfReceipt.table.total')}
          </Text>
          <Text
            style={{
              color: colors.primary,
              fontSize: 13,
              fontWeight: 'bold'
            }}>
            {currency?.symbol}
            {Utils.toCurrency(data?.amount)}
          </Text>
        </View>
      </View>
      <View style={styles.foot}>
        <View style={{ width: '65%' }}></View>
        <View
          style={{
            padding: '3px 10px',
            width: '35%',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}>
          <Text
            style={{
              color: PDF_MUTED_COLOR,
              textTransform: 'uppercase',
              fontSize: PDF_BASE_FONT_SIZE * 0.9
            }}>
            {i18n('v2.pages.pdfReceipt.table.PAID')}
          </Text>
          <Text>
            {currency?.symbol}
            {Utils.toCurrency(data?.amount)}
          </Text>
        </View>
      </View>
    </>
  );
}
