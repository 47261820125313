import { i18n, Session } from 'dawere-commons';
import { Block, Col, Flex, Skeleton, Visible } from 'dawere-uic';
import { useProgramStatus } from 'hooks';
import { useCallback } from 'react';
import { trackEvent } from 'utils';
import { BuyCouponsButton } from '../components/BuyCouponsButton';
import { EnrollButton } from '../components/EnrollButton';
import { useProgram } from '../hooks/useProgram';

export function DegreeSection({ isCourse = false, programId }: any) {
  const { isLoading, program, priceSelected } = useProgram();
  const { status, isLoading: isLoadingMyPrograms } =
    useProgramStatus(programId);

  const account = Session.getAccountInfo();

  const handleClick = useCallback(() => {
    trackEvent('button_click_inscribete', { position: 2 });
  }, []);

  const renderButtons = useCallback(() => {
    // Sin sesión
    if (!account) {
      return (
        <EnrollButton
          onClick={handleClick}
          programId={programId}
          isLoadingMyPrograms={isLoadingMyPrograms}
          program={program}
          priceSelected={priceSelected}
          className='w-100 w-md-auto'
        />
      );
    }

    // Cuando ha iniciado sesion
    // Si es estudiante y organizacion
    if (account?.student && account?.organization) {
      return (
        <>
          <Flex>
            <EnrollButton
              onClick={handleClick}
              programId={programId}
              isLoadingMyPrograms={isLoadingMyPrograms}
              program={program}
              priceSelected={priceSelected}
              status={status}
              className='w-100 w-md-auto'
              hideTrial
            />
          </Flex>

          <Flex className='order-first order-md-last'>
            <BuyCouponsButton
              programId={programId}
              className='w-100 w-md-auto'
            />
          </Flex>
        </>
      );
    }

    // Si es estudiante y no es organizacion
    if (account?.student && !account?.organization) {
      return (
        <EnrollButton
          onClick={handleClick}
          programId={programId}
          isLoadingMyPrograms={isLoadingMyPrograms}
          program={program}
          priceSelected={priceSelected}
          status={status}
          className='w-100 w-md-auto'
        />
      );
    }

    // Si no es estudiante y es organizacion
    if (!account?.student && account?.organization) {
      return (
        <BuyCouponsButton programId={programId} className='w-100 w-md-auto' />
      );
    }

    return null;
  }, [
    account,
    handleClick,
    isLoadingMyPrograms,
    priceSelected,
    program,
    programId,
    status
  ]);

  if (!program?.leadsToDegree) {
    return null;
  }

  return (
    <Block align='center'>
      <Col md={6}>
        {isLoading ? (
          <Skeleton.Title className='mb-4' />
        ) : (
          <>
            <h4 className='text-uppercase text-primary'>
              {i18n(
                'v2.pages.program.section2.subtitle' + (isCourse ? '2' : '1')
              )}
            </h4>
            <h2 className='mb-4'>{program?.degreeName}</h2>
          </>
        )}

        {isLoading ? (
          <>
            <Skeleton.Paragraph count={2} lines={3} minLines={5} maxLines={7} />
          </>
        ) : (
          <>
            <p>{program?.degreeDescription}</p>

            {program?.school?.name ? (
              <p>
                <small className='d-block text-muted'>
                  {i18n('v2.pages.program.section2.license')}
                </small>
                {program?.school?.licenseUrl ? (
                  <a href={program?.school?.licenseUrl}>
                    {program?.school?.name}
                  </a>
                ) : (
                  program?.school?.name
                )}
              </p>
            ) : null}

            <div className='mt-4'>
              {program?.degreeImage2 ? (
                <img
                  src={program?.degreeImage2}
                  alt={program?.degreeName}
                  className='img-fluid mr-4 w-35'
                />
              ) : null}
              {program?.degreeImage3 ? (
                <img
                  src={program?.degreeImage3}
                  alt={program?.degreeName}
                  className='img-fluid w-35'
                />
              ) : null}
            </div>
          </>
        )}

        <Visible xs sm>
          {isLoading ? (
            <Skeleton.Image className='mt-6' />
          ) : (
            <img
              src={program?.degreeImage}
              alt={program?.degreeName}
              className='img-fluid rounded border mt-6 w-100'
            />
          )}
        </Visible>

        <Flex gap={2} className='flex-column flex-md-row | mt-4'>
          {renderButtons()}
        </Flex>
      </Col>

      <Visible md lg xl>
        <Col md={6} className='text-center'>
          {isLoading ? (
            <Skeleton.Image />
          ) : (
            <img
              src={program?.degreeImage}
              alt={program?.degreeName}
              className='img-fluid rounded border w-100'
            />
          )}
        </Col>
      </Visible>
    </Block>
  );
}
