import { i18n } from 'dawere-commons';
import {
  AvatarChip,
  Button,
  Card,
  Col,
  Container,
  Row,
  Utils
} from 'dawere-uic';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import styled from 'styled-components';
import { isMember } from 'utils';

const CardStyled = styled(Card)`
  :hover {
    background-color: var(--dw-color-cultured);
  }
`;

export function OrganizationCard({ item, onClick }: any) {
  return (
    <CardStyled className='py-3 px-2 mb-2 translate-left-animation'>
      <Container fluid>
        <Row align='center'>
          <Col
            xs={12}
            md={8}
            lg={10}
            className='cursor-pointer'
            onClick={() => onClick(item)}>
            <AvatarChip
              size={60}
              src={item.logo}
              icon={Utils.getInitialLetters(item.name)}>
              <h4 className='m-0'>{item.name}</h4>
              <span className='d-block text-muted'>
                {i18n(`ui.commons.${item.role}`)}
              </span>
            </AvatarChip>
          </Col>
          <Col xs={12} md={4} lg={2} className='text-md-right'>
            {isMember(item.role) ? (
              <Button
                className='w-100 w-md-auto | mt-4 mt-md-0'
                as={Link}
                to={PATHS.get('EDIT_ORGANIZATIONS', {
                  organizationId: item.id
                })}>
                {i18n('v2.pages.myOrganizations.buttons.configuration')}
              </Button>
            ) : null}
          </Col>
        </Row>
      </Container>
    </CardStyled>
  );
}
