import { i18n } from 'dawere-commons';
import { Accordion, Skeleton } from 'dawere-uic';
import { useEffect, useState } from 'react';
import { useStudentDashboard } from '../hooks/useStudentDashboard';
import { DocumentPanel } from './panels/DocumentPanel';
import { ToEnrollPanel } from './panels/ToEnrollPanel';

export default function DocumentsTab() {
  const { getEnrollments, readOnly } = useStudentDashboard();
  const [expandedId, setExpandedId] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [enrollments, setEnrollments] = useState<any[]>([]);

  useEffect(() => {
    setIsLoading(true);

    getEnrollments()
      .then((data: any = []) => {
        setEnrollments(data);
        if (data.length) {
          setExpandedId(data[0].program.id);
        }
      })
      .finally(() => setIsLoading(false));
  }, [getEnrollments]);

  if (isLoading) {
    return (
      <>
        {Array(3)
          .fill(0)
          .map((_, index) => (
            <Skeleton.Image key={index} height={48} className='mb-3' />
          ))}
      </>
    );
  }

  if (enrollments.length) {
    return (
      <>
        {readOnly ? null : (
          <p
            dangerouslySetInnerHTML={{
              __html: i18n('v2.pages.myDocuments.text')
            }}
          />
        )}

        {enrollments?.map((entry: any) => (
          <Accordion
            key={entry.program?.id}
            expanded={entry.program?.id === expandedId}
            onToggle={() => setExpandedId(entry.program?.id)}
            summary={
              <>
                {entry?.program?.name}{' '}
                {entry?.school?.name ? `— ${entry?.school?.name}` : ''}
              </>
            }>
            <DocumentPanel
              entry={entry}
              isExpanded={entry.program.id === expandedId}
            />
          </Accordion>
        ))}
      </>
    );
  }

  return <ToEnrollPanel />;
}
