import { Rest } from 'dawere-commons';

function get(params: any = {}) {
  return Rest.get('invitations', {
    params
  });
}

function accept(id: string) {
  return Rest.post(`invitations/${id}/accept`);
}

function reject(id: string) {
  return Rest.post(`invitations/${id}/reject`);
}

function resign(organizationId: string) {
  return Rest.delete(`my-organizations/${organizationId}/resign`);
}

function remove(id: string) {
  return Rest.delete(`invitations/${id}`);
}

export const Invitations = {
  get,
  accept,
  reject,
  resign,
  remove
};
