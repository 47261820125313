import { i18n } from 'dawere-commons';
import { Button, Icons } from 'dawere-uic';
import { PageWrapper, TwoColumnTemplate } from 'pages';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { getSEO } from 'utils';
import { ASSETS } from 'values';

/**
 * Path: /olvide-contrasena/reinicio-solicitado
 * Key: RESET_PASSWORD_REQUESTED
 * @returns {React.ReactElement}
 */
export default function ResetPasswordRequestedPage() {
  return (
    <PageWrapper>
      {getSEO('v2.pages.rememberPasswordRequested.seo', true)}

      <TwoColumnTemplate
        mobileImage={
          <span className='rounded-circle bg-platinium d-inline-block p-4'>
            <Icons.Email open className='text-secondary fs-9' />
          </span>
        }
        desktopImage={
          <img
            src={ASSETS.ILLUSTRATIONS.GUY_LAPTOP}
            alt={i18n('v2.pages.rememberPasswordRequested.title')}
            className='img-fluid'
          />
        }>
        <h2 className='mb-3 text-center text-lg-left'>
          {i18n('v2.pages.rememberPasswordRequested.title')}
        </h2>

        <h5 className='mb-5'>
          {i18n('v2.pages.rememberPasswordRequested.subtitle')}
        </h5>

        <Button as={Link} to={PATHS.LOGIN} block>
          {i18n('v2.pages.rememberPasswordRequested.buttons.login')}
        </Button>

        <p className='mt-4 text-center'>
          {i18n('v2.pages.rememberPasswordRequested.noEmail')}{' '}
          <Link to={PATHS.HOME}>
            {i18n('v2.pages.rememberPasswordRequested.links.contactUs')}
          </Link>
        </p>
      </TwoColumnTemplate>
    </PageWrapper>
  );
}
