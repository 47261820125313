import { Text, View } from '@react-pdf/renderer';
import { SVGLogo } from 'components';
import { i18n } from 'dawere-commons';
import { PDF_BASE_FONT_SIZE, PDF_MUTED_COLOR } from 'values';

export function DawereInfoSection() {
  return (
    <View
      style={{
        width: '80%',
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'row'
      }}>
      <View style={{ marginRight: 10, paddingTop: 5 }}>
        <SVGLogo width='210px' />
      </View>

      <View style={{ marginLeft: 10 }}>
        <Text
          style={{
            fontSize: PDF_BASE_FONT_SIZE,
            fontWeight: 'bold',
            marginBottom: 3
          }}>
          {i18n('v2.pages.pdfReceipt.info.title')}
        </Text>
        <Text
          style={{
            fontSize: PDF_BASE_FONT_SIZE * 0.85,
            color: PDF_MUTED_COLOR
          }}>
          {i18n('v2.pages.pdfReceipt.info.address')},{' '}
          {i18n('v2.pages.pdfReceipt.info.country')}
        </Text>
        <Text
          style={{
            fontSize: PDF_BASE_FONT_SIZE * 0.85,
            color: PDF_MUTED_COLOR
          }}></Text>
        <Text
          style={{
            fontSize: PDF_BASE_FONT_SIZE * 0.85,
            color: PDF_MUTED_COLOR
          }}>
          {i18n('v2.pages.pdfReceipt.info.phoneNumber')}
        </Text>
        <Text
          style={{
            fontSize: PDF_BASE_FONT_SIZE * 0.85,
            color: PDF_MUTED_COLOR
          }}>
          {i18n('v2.pages.pdfReceipt.info.email')}
        </Text>
        <Text
          style={{
            fontSize: PDF_BASE_FONT_SIZE * 0.85,
            color: PDF_MUTED_COLOR
          }}>
          {i18n('v2.pages.pdfReceipt.info.web')}
        </Text>
      </View>
    </View>
  );
}
