import { useQuery } from '@tanstack/react-query';
import { API } from 'api';
import { Session } from 'dawere-commons';
import { Dawere, useRouter } from 'dawere-uic';
import { useCallback, useState } from 'react';
import { PATHS } from 'router';

const INTERVAL_IN_SECONDS = 1000 * 20;

export function Notifications() {
  const { push } = useRouter();
  const [notifications, setNotifications] = useState<any[]>([]);

  const { isLoading, isFetching } = useQuery({
    enabled: !!Session.getAccountInfo(),
    queryKey: ['live-notifications'],
    queryFn: () => API.Notifications.getLive(),
    refetchInterval: INTERVAL_IN_SECONDS,
    onSuccess: (data) => {
      setNotifications(
        data.map((n: any) => ({
          ...n,
          icon: API.Notifications.getIcon(n.campaign)
        }))
      );
    }
  });

  const handleMarkAsRead = useCallback((notification: any, index: number) => {
    console.log('Mark as read', notification, index);
    API.Notifications.markAsRead(notification.id).then(() => {
      setNotifications((notifications) => {
        notifications[index].read = true;
        return [...notifications];
      });
    });
  }, []);

  const handleMarkAsOpen = useCallback(
    (notification: any, index: number) => {
      console.log('Mark as open', notification, index);
      API.Notifications.markAsOpen(notification.id).then(() => {
        setNotifications((notifications) => {
          notifications[index].read = true;
          return [...notifications];
        });
      });
      push(notification.to);
    },
    [push]
  );

  const handleMarkAllAsRead = useCallback(() => {
    console.log('All as read');
    const promises: any[] = [];

    notifications.forEach((notification) => {
      promises.push(
        API.Notifications.markAsRead(notification.id).then(() => {
          notification.read = true;
        })
      );
    });

    Promise.all(promises).then(() => setNotifications([]));
  }, [notifications]);

  return (
    <Dawere.Notifications
      className='notifications-navbar-button'
      to={PATHS.NOTIFICATIONS}
      loading={isLoading}
      data={notifications}
      onMarkAllAsRead={handleMarkAllAsRead}
      onMarkAsRead={handleMarkAsRead}
      onMarkAsOpen={handleMarkAsOpen}
    />
  );
}
