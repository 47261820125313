import { Text, View } from '@react-pdf/renderer';
import { i18n } from 'dawere-commons';
import { Utils } from 'dawere-uic';
import { colors } from 'dawere-uic/lib/styles/colors';
import { PDF_BASE_FONT_SIZE, PDF_MUTED_COLOR } from 'values';

export function DateInfoSection({ data }: any) {
  return (
    <View style={{ textAlign: 'center', width: '20%' }}>
      <Text
        style={{
          color: colors.primary,
          marginBottom: 5,
          fontSize: PDF_BASE_FONT_SIZE * 2.3
        }}>
        {i18n('v2.pages.pdfReceipt.title')}
      </Text>

      <View
        style={{
          textAlign: 'center',
          backgroundColor: colors.cultured,
          borderRadius: 4,
          padding: 10,
          paddingTop: 5,
          paddingBottom: 5,
          marginBottom: 10
        }}>
        <Text
          style={{
            fontSize: PDF_BASE_FONT_SIZE * 0.85,
            color: PDF_MUTED_COLOR
          }}>
          {i18n('v2.pages.pdfReceipt.billingInfo.date')}
        </Text>

        <View style={{}}>
          <Text
            style={{
              fontSize: PDF_BASE_FONT_SIZE
            }}>
            {Utils.formatDate(data?.paymentDate, 'dd/mm/yyyy')}
          </Text>
        </View>
      </View>
    </View>
  );
}
