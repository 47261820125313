import { Utils } from 'dawere-uic';
import { useContext } from 'react';
import { CatalogContext } from '../CatalogProvider';

export const useCatalog = () => {
  const context = useContext(CatalogContext);

  if (Utils.isEmpty(context)) {
    throw new Error('"useCatalog" must be used within a CatalogProvider');
  }

  return context;
};
