import { i18n } from 'dawere-commons';
import { Col, Icons, Row } from 'dawere-uic';
import { useOrganization } from 'hooks';
import { PATHS } from 'router';
import { ReportTile } from '../components/ReportTile';

export function ReportsTab() {
  const { organization } = useOrganization();

  return (
    <Row>
      <Col xs={12} md={4}>
        <ReportTile
          to={PATHS.get('REPORT_TRANSCRIPTS', {
            organizationId: organization?.id
          })}
          title={i18n('v2.pages.progressReport.title')}
          icon={<Icons.ChartColumns />}>
          {i18n('v2.pages.progressReport.description')}
        </ReportTile>
      </Col>

      <Col xs={12} md={4}>
        <ReportTile
          to={PATHS.get('REPORT_DOCUMENTS', {
            organizationId: organization?.id
          })}
          title={i18n('v2.pages.documentsReport.title')}
          icon={<Icons.Article />}>
          {i18n('v2.pages.documentsReport.description')}
        </ReportTile>
      </Col>
    </Row>
  );
}
