import { i18n } from 'dawere-commons';
import { Button, Col, Row } from 'dawere-uic';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { ASSETS } from 'values';

export function CouponsEmpty() {
  return (
    <Row>
      <Col md={6}>
        <img
          src={ASSETS.ILLUSTRATIONS.CLOCK}
          alt={i18n('v2.pages.organizationDashboard.tabs.1.empty.title')}
          className='mb-5 mb-md-0 | img-fluid'
        />
      </Col>

      <Col md={6} className='order-md-first'>
        <h3 className='mb-4'>
          {i18n('v2.pages.organizationDashboard.tabs.1.empty.title')}
        </h3>

        <p
          className='mb-4'
          dangerouslySetInnerHTML={{
            __html: i18n(
              'v2.pages.organizationDashboard.tabs.1.empty.description'
            )
          }}
        />

        <Button as={Link} to={PATHS.BUY_COUPONS} className='w-100 w-md-auto'>
          {i18n('v2.pages.organizationDashboard.tabs.1.empty.buttons.buy')}
        </Button>
      </Col>
    </Row>
  );
}
