import { Utils } from 'dawere-uic';
import { useEffect, useState } from 'react';

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

export const useCountdown = (milliseconds: any) => {
  const [count, setCount] = useState(milliseconds);

  useEffect(() => {
    setCount(milliseconds);
  }, [milliseconds]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((ms: any) => ms - 1000);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return getReturnValues(count);
};

const getReturnValues = (time: number) => {
  if (!Utils.isNumber(time)) {
    return [NaN, NaN, NaN, NaN];
  }

  const days = Math.floor(time / DAY);
  const hours = Math.floor((time % DAY) / HOUR);
  const minutes = Math.floor((time % HOUR) / MINUTE);
  const seconds = Math.floor((time % MINUTE) / SECOND);

  return [
    days > 0 ? days : 0,
    hours > 0 ? hours : 0,
    minutes > 0 ? minutes : 0,
    seconds > 0 ? seconds : 0
  ];
};
