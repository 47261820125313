import { API } from 'api';
import { i18n } from 'dawere-commons';
import { Block, Button, Col, Icons } from 'dawere-uic';

export function ContactSection() {
  return (
    <Block>
      <Col xs={12}>
        <h2>{i18n('v2.pages.shared.contact.title')}</h2>
        <p className='mb-4'>{i18n('v2.pages.shared.contact.text')}</p>
      </Col>

      {i18n('v2.pages.shared.contact.info.phone.text') ? (
        <Col xs={6} lg={4}>
          <h5 className='text-primary text-uppercase'>
            <Icons.Phone className='text-primary' />{' '}
            {i18n('v2.pages.shared.contact.info.phone.title')}
          </h5>
          <p className='ml-4'>
            {i18n('v2.pages.shared.contact.info.phone.text')}
          </p>
        </Col>
      ) : null}

      {i18n('v2.pages.shared.contact.info.whatsapp.text') ? (
        <Col xs={6} lg={4}>
          <h5 className='text-primary text-uppercase'>
            <Icons.Whatsapp className='text-primary' />{' '}
            {i18n('v2.pages.shared.contact.info.whatsapp.title')}
          </h5>
          <p className='ml-4'>
            {i18n('v2.pages.shared.contact.info.whatsapp.text')}
          </p>
        </Col>
      ) : null}

      {i18n('v2.pages.shared.contact.info.email.text') ? (
        <Col xs={6} lg={4}>
          <h5 className='text-primary text-uppercase'>
            <Icons.Email className='text-primary' />{' '}
            {i18n('v2.pages.shared.contact.info.email.title')}
          </h5>
          <p className='ml-4'>
            <a
              className='text-break'
              href={`mailto:${i18n(
                'v2.pages.shared.contact.info.email.text'
              )}`}>
              {i18n('v2.pages.shared.contact.info.email.text')}
            </a>
          </p>
        </Col>
      ) : null}

      <Col xs={12} className='mt-3'>
        <Button
          onClick={() => API.Zendesk.go()}
          variant='outline'
          className='w-100 w-md-auto'>
          {i18n('v2.pages.shared.contact.buttons.faq')}
        </Button>
      </Col>
    </Block>
  );
}
