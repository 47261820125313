import { Rest } from 'dawere-commons';

function _processResponse(response: any) {
  let arr = response.data.content.map(({ id, name }: any) => ({
    value: id,
    label: name
  }));

  arr = arr.sort((a: any, b: any) => a.label.localeCompare(b.label));

  const other = arr.find(({ label }: any) => label === 'Otro');

  if (other) {
    arr = arr.filter(({ label }: any) => label !== 'Otro');
    arr.push(other);
  }

  return arr;
}

function getCountries() {
  return Rest.get('countries').then(_processResponse);
}

function getStates(countryId: string) {
  return Rest.get(`countries/${countryId}/states`).then(_processResponse);
}

function getTownships(stateId: string) {
  return Rest.get(`states/${stateId}/townships`).then(_processResponse);
}

export const Geo = {
  getCountries,
  getStates,
  getTownships
};
