import { Accordion, Skeleton } from 'dawere-uic';
import { useCallback, useEffect, useState } from 'react';
import { useStudentDashboard } from '../hooks/useStudentDashboard';
import { HistoricalPanel } from './panels/HistoricalPanel';
import { PendingPaymentPanel } from './panels/PendingPaymentPanel';
import { ToEnrollPanel } from './panels/ToEnrollPanel';

export function HistoricalTab() {
  const { getEnrollments } = useStudentDashboard();

  const [expandedId, setExpandedId] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [enrollments, setEnrollments] = useState<any[]>([]);

  const render = useCallback(
    (program: any, status: string) => {
      if (status === 'DEBT') {
        return <PendingPaymentPanel />;
      }

      return (
        <HistoricalPanel
          program={program}
          isExpanded={program.id === expandedId}
        />
      );
    },
    [expandedId]
  );

  useEffect(() => {
    setIsLoading(true);

    getEnrollments()
      .then((data: any = []) => {
        setEnrollments(data);
        if (data.length) {
          setExpandedId(data[0].program.id);
        }
      })
      .finally(() => setIsLoading(false));
  }, [getEnrollments]);

  if (isLoading) {
    return (
      <>
        {Array(3)
          .fill(0)
          .map((_, index) => (
            <Skeleton.Image key={index} height={48} className='mb-3' />
          ))}
      </>
    );
  }

  if (enrollments.length === 0) {
    // Inscripcion: no hay programas que mostrar
    return <ToEnrollPanel />;
  }

  return (
    <>
      {enrollments?.map(({ program, status }: any) => (
        <Accordion
          key={program.id}
          expanded={program.id === expandedId}
          onToggle={() => setExpandedId(program.id)}
          summary={program?.name}>
          {render(program, status)}
        </Accordion>
      ))}
    </>
  );
}
