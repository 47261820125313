import { API } from 'api';
import { i18n } from 'dawere-commons';
import { Button, CodeInput, Input, Modal, toast, Visible } from 'dawere-uic';
import { useCallback, useEffect, useState } from 'react';
import { trackEvent } from 'utils';

export function ConfirmPhoneModal({
  open = false,
  onClose = (message?: string | boolean) => {},
  phoneNumber = '',
  phoneCode = ''
}: any) {
  const [otp, setOTP] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [seconds, setSeconds] = useState<number>(60);

  const handleVerify = useCallback(() => {
    setIsLoading(true);

    API.Account.verifyPhoneNumber({
      otp,
      phoneNumber,
      phoneCode
    })
      .then(() => {
        onClose('success');
        trackEvent('phone_number_confirmed');
      })
      .finally(() => {
        setIsLoading(false);
        setOTP('');
      });
  }, [otp, phoneNumber, phoneCode, onClose]);

  const handleResend = useCallback(() => {
    setSeconds(60);

    API.Account.resendOTP({
      phoneNumber: `${phoneCode}${phoneNumber}`
    })
      .then(() => {
        toast.success(i18n('v2.pages.profile.toasts.successResend'));
      })
      .finally(() => {
        setOTP('');
      });
  }, [phoneCode, phoneNumber]);

  const handleChangeCodeInput = useCallback((data: string) => {
    setOTP(data);
  }, []);

  const handleChangeInput = useCallback(({ target }: any) => {
    setOTP(target.value);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((value) => value - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (open) {
      setSeconds(60);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      type='info'
      title={i18n('v2.pages.profile.modal.title')}
      backdrop='static'
      footer={
        <Button
          block
          onClick={handleVerify}
          disabled={!otp || otp.length < 6}
          loading={isLoading}>
          {i18n('v2.pages.profile.modal.button')}
        </Button>
      }>
      <p>{i18n('v2.pages.profile.modal.description')}</p>

      <Visible xs sm>
        <div className='mb-4'>
          <Input
            name='otp'
            placeholder={i18n('v2.pages.profile.modal.placeholder')}
            value={otp}
            onChange={handleChangeInput}
            maxLength={6}
            loading={isLoading}
          />
        </div>
      </Visible>
      <Visible md lg xl>
        <div className='d-flex justify-content-center mb-4'>
          <CodeInput
            name='otp'
            value={otp}
            onChange={handleChangeCodeInput}
            loading={isLoading}
          />
        </div>
      </Visible>

      <p className='m-0'>
        {i18n('v2.pages.profile.modal.text1')}
        {seconds >= 0 ? (
          <>
            {i18n('v2.pages.profile.modal.text2')} en{' '}
            <span>
              {seconds} segundo{seconds > 1 ? 's' : ''}
            </span>
            .
          </>
        ) : (
          <>
            <span className='anchor cursor-pointer' onClick={handleResend}>
              {i18n('v2.pages.profile.modal.text2')}
            </span>
            .
          </>
        )}
      </p>
    </Modal>
  );
}
