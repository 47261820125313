import { i18n } from 'dawere-commons';
import { Button, Callout, Col, Row } from 'dawere-uic';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { ASSETS, QPARAMS } from 'values';
import { useStudentDashboard } from '../../hooks/useStudentDashboard';

export function PendingPaymentPanel() {
  const { rootRoute, studentId } = useStudentDashboard();

  return (
    <Row align='center'>
      <Col>
        <Callout className='text-center border border-platinium bg-white'>
          <img
            src={ASSETS.ILLUSTRATIONS.STUDENT_READING}
            alt=''
            className='img-fluid w-70 w-md-25 mb-4'
          />
          <h3>
            {i18n('v2.pages.studentDashboard.tabs.1.pendingPayment.title')}
          </h3>
          <p>
            {i18n(
              'v2.pages.studentDashboard.tabs.1.pendingPayment.description'
            )}
          </p>
          <Button
            className='w-100 w-md-auto mb-4'
            as={Link}
            to={PATHS.get(
              rootRoute,
              { studentId },
              {
                [QPARAMS.Tab]: 3,
                [QPARAMS.Subtab]: 1
              }
            )}>
            {i18n('v2.pages.studentDashboard.tabs.1.pendingPayment.button')}
          </Button>
        </Callout>
      </Col>
    </Row>
  );
}
