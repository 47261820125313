import { Block, useRouter, Visible } from 'dawere-uic';
import { PageWrapper } from 'pages/wrappers/PageWrapper';
import React from 'react';
import { getSEO } from 'utils';
import { QPARAMS } from 'values';
import { PaymentProvider } from './PaymentProvider';
import { MainWrapper } from './sections/MainWrapper';
import { SummarySidebar } from './sections/SummarySidebar';

/**
 * Path: /pagar/:orderId
 * Key: PAY
 * @returns {React.ReactElement}
 */
export default function PaymentPage() {
  const { query } = useRouter();

  return (
    <PageWrapper>
      {getSEO('v2.pages.buyCoupons.seo', true)}

      <PaymentProvider orderId={query?.orderId} draft={query[QPARAMS.Draft]}>
        <div id='summary-content'>
          <Block narrow align='stretch'>
            <MainWrapper />

            {/* Barra gris del summary para desktop */}
            <Visible md lg xl>
              <SummarySidebar />
            </Visible>
          </Block>
        </div>
      </PaymentProvider>
    </PageWrapper>
  );
}
