import { API } from 'api';
import { Accordion, Empty, Skeleton, Utils } from 'dawere-uic';
import { useEffect, useState } from 'react';
import { useStudentDashboard } from '../hooks/useStudentDashboard';
import { ScorecardPanel } from './panels/ScorecardPanel';

export function ScorecardsTab() {
  const { studentId } = useStudentDashboard();
  const [expandedId, setExpandedId] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [enrollments, setEnrollments] = useState<any[]>([]);

  useEffect(() => {
    if (studentId) {
      setIsLoading(true);

      API.Students.getAllEnrollments({ student_id: studentId })
        .then((data: any = []) => {
          const grouped = Utils.groupBy(data, ({ program }: any) => program.id);
          const enrollments = Object.values(grouped).map((enrollments: any) => {
            const first = Utils.first(enrollments);

            return {
              program: first.program,
              enrollments: enrollments
                .filter((enrollment: any) => !enrollment.isTrial)
                .map(({ program, ...rest }: any) => rest)
            };
          });

          setEnrollments(
            enrollments.filter((enroll: any) => enroll.enrollments.length)
          );

          if (enrollments.length) {
            setExpandedId(enrollments[0].program.id);
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [studentId]);

  if (isLoading) {
    return (
      <>
        {Array(3)
          .fill(0)
          .map((_, index) => (
            <Skeleton.Image key={index} height={48} className='mb-3' />
          ))}
      </>
    );
  }

  if (enrollments.length) {
    return (
      <>
        {enrollments?.map((entry: any) => (
          <Accordion
            key={entry.id}
            expanded={entry.program?.id === expandedId}
            onToggle={() => setExpandedId(entry.program?.id)}
            summary={entry?.program?.name}>
            <ScorecardPanel entry={entry} />
          </Accordion>
        ))}
      </>
    );
  }

  return <Empty />;
}
