import { i18n } from 'dawere-commons';
import { Col, Icons, Mesh, Row, Skeleton } from 'dawere-uic';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { getInstallmentIds, getTotals } from 'utils';
import { CashGateway } from './CashGateway';
import { CreditCardGateway } from './CreditCardGateway';
import { DirectCheckout } from './DirectCheckout';
import { PayPalGateway } from './PayPalGateway';
import { TransferGateway } from './TransferGateway';

export enum Options {
  None,
  CreditCard,
  PayPal,
  Transfer,
  Cash
}

const BoxStyled = styled.div`
  margin-top: var(--dw-spacer-3);
  padding: var(--dw-spacer-3);
  border: 2px solid var(--dw-color-primary);
  border-radius: 8px;

  @media (min-width: 768px) {
    padding: var(--dw-spacer-4);
  }
`;

const PaymentButtonStyled = styled.button`
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.161);
  border: none;
  appareance: none;
  width: 100%;
  height: 100%;
  color: var(--dw-color-primary);
  font-size: 0.85rem;
  line-height: 1.45;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  row-gap: var(--dw-spacer-3);
  background-color: var(--dw-color-white);
  padding: 1rem;
  cursor: pointer;
  border: 2px solid var(--dw-color-white);

  &:hover {
    background-color: var(--dw-color-cultured);
    border-color: var(--dw-color-cultured);
  }

  &:active {
    background-color: var(--dw-color-powderTint);
  }

  &.active {
    border-color: var(--dw-color-primary);
  }

  &[disabled] {
    pointer-events: none;
    cursor: auto;
    background-color: var(--dw-color-cultured);
    border-color: var(--dw-color-cultured);
    filter: grayscale(1);
  }
`;

export function Gateways({
  options = {},
  isBillingInfoCompleted = () => true,
  loading = false,
  disabled = false,
  total = 0,
  currency = null,
  installments = [],
  discounts = [],
  onSuccess = () => {},
  onError = () => {},
  redirectTo = '',
  allowDirectPayment = false,
  totalDiscounts = 0,
  walletBalance = null,
  children
}: any) {
  const [state, setState] = useState<Options>(Options.None);

  const handleClick = useCallback((option: Options) => {
    setState((state) => (option === state ? Options.None : option));
  }, []);

  const finalTotals = getTotals(total, installments, walletBalance);

  const isDirectPayment =
    finalTotals?.total <= 0 /* &&
    (discounts.length || totalDiscounts > 0) */ && allowDirectPayment;

  return (
    <>
      <h3 className='text-primary mb-3'>
        {i18n('v2.pages.buyCoupons.steps.2.gateways.title')}
      </h3>

      {loading ? (
        <Row>
          {Array(4)
            .fill(0)
            .map((_, index) => (
              <Col key={index} xs={6} lg={3}>
                <Skeleton.Image height={130} />
              </Col>
            ))}
        </Row>
      ) : (
        <>
          {isDirectPayment ? (
            <DirectCheckout
              installments={getInstallmentIds(installments)}
              onSuccess={onSuccess}
              onError={onError}
              redirectTo={redirectTo}
              isBillingInfoCompleted={isBillingInfoCompleted}
              loading={loading}
              disabled={disabled}
              walletAmount={finalTotals?.usedWalletAmount}
            />
          ) : (
            <>
              <Mesh minSize={150}>
                {children}

                {options?.cardGateway ? (
                  <PaymentButtonStyled
                    type='button'
                    disabled={!isBillingInfoCompleted() || loading || disabled}
                    className={state === Options.CreditCard ? 'active' : ''}
                    onClick={() => handleClick(Options.CreditCard)}>
                    <Icons.CreditCard colored className='fs-3' />
                    <span>
                      {i18n('v2.pages.buyCoupons.steps.2.gateways.creditCard')}
                    </span>
                  </PaymentButtonStyled>
                ) : null}
                {options?.acceptPayPal ? (
                  <PaymentButtonStyled
                    type='button'
                    disabled={!isBillingInfoCompleted() || loading || disabled}
                    className={state === Options.PayPal ? 'active' : ''}
                    onClick={() => handleClick(Options.PayPal)}>
                    <Icons.Paypal colored className='fs-3' />
                    <span>
                      {i18n('v2.pages.buyCoupons.steps.2.gateways.paypal')}
                    </span>
                  </PaymentButtonStyled>
                ) : null}
                {options?.banks?.length ? (
                  <PaymentButtonStyled
                    type='button'
                    disabled={!isBillingInfoCompleted() || loading || disabled}
                    className={state === Options.Transfer ? 'active' : ''}
                    onClick={() => handleClick(Options.Transfer)}>
                    <Icons.Transfer colored className='fs-3' />
                    <span>
                      {i18n('v2.pages.buyCoupons.steps.2.gateways.transfer')}
                    </span>
                  </PaymentButtonStyled>
                ) : null}
                {options?.cashGateway ? (
                  <PaymentButtonStyled
                    type='button'
                    disabled={!isBillingInfoCompleted() || loading || disabled}
                    className={state === Options.Cash ? 'active' : ''}
                    onClick={() => handleClick(Options.Cash)}>
                    <Icons.Cash colored className='fs-3' />
                    <span>
                      {i18n('v2.pages.buyCoupons.steps.2.gateways.cash')}
                    </span>
                  </PaymentButtonStyled>
                ) : null}
              </Mesh>

              {state !== Options.None ? (
                <BoxStyled>
                  {state === Options.CreditCard && (
                    <CreditCardGateway
                      installments={getInstallmentIds(installments)}
                      onSuccess={onSuccess}
                      onError={onError}
                      redirectTo={redirectTo}
                      isBillingInfoCompleted={isBillingInfoCompleted}
                      walletAmount={finalTotals?.usedWalletAmount}
                    />
                  )}
                  {state === Options.PayPal && (
                    <PayPalGateway
                      total={finalTotals?.total}
                      currency={currency}
                      installments={getInstallmentIds(installments)}
                      onSuccess={onSuccess}
                      onError={onError}
                      redirectTo={redirectTo}
                      isBillingInfoCompleted={isBillingInfoCompleted}
                      walletAmount={finalTotals?.usedWalletAmount}
                    />
                  )}
                  {state === Options.Transfer && (
                    <TransferGateway
                      total={finalTotals?.total}
                      currency={currency}
                      installments={getInstallmentIds(installments)}
                      onSuccess={onSuccess}
                      onError={onError}
                      redirectTo={redirectTo}
                      isBillingInfoCompleted={isBillingInfoCompleted}
                      banks={options?.banks}
                      walletAmount={finalTotals?.usedWalletAmount}
                    />
                  )}
                  {state === Options.Cash && <CashGateway />}
                </BoxStyled>
              ) : null}
            </>
          )}
        </>
      )}
    </>
  );
}
