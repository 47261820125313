import { API } from 'api';
import { Utils } from 'dawere-uic';
import React, { useEffect, useState } from 'react';

export interface PaymentProps {
  isLoading: boolean;
  data: any;
  gateways: any;
  isPaid: boolean;
  setIsPaid: React.Dispatch<React.SetStateAction<boolean>>;
  walletBalance: number | null;
}

export const PaymentContext = React.createContext<PaymentProps>({
  isLoading: true,
  data: null,
  gateways: null,
  isPaid: false,
  setIsPaid: () => {},
  walletBalance: null
});

export function PaymentProvider({ children, orderId, draft = false }: any) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>(null);
  const [gateways, setGateways] = useState<any>(null);
  const [isPaid, setIsPaid] = useState(false);
  const [walletBalance, setWalletBalance] = useState<number | null>(null);

  useEffect(() => {
    if (orderId) {
      setIsLoading(true);
      let apiCall: any = draft
        ? API.Payments.getInstallmentsOrder(orderId)
        : API.Payments.getReceipt(orderId);

      apiCall
        .then((response: any) => {
          if (Utils.has(response, 'billing')) {
            response.billing = {
              ...response.billing,
              name: response.billing.billTo,
              postalCode: response.billing.billingPostalCode,
              country: {
                ...response.billing.billingCountry,
                label: response.billing.billingCountry?.name
              },
              state: {
                ...response.billing.billingState,
                label: response.billing.billingState?.name
              },
              township: {
                ...response.billing.billingTownship,
                label: response.billing.billingTownship?.name
              }
            };
          }

          setData(response);

          return API.Cart.getGateways(response?.currency?.id);
        })
        .then((response: any) => setGateways(response))
        .finally(() => setIsLoading(false));
    }
  }, [draft, orderId]);

  // Wallet
  useEffect(() => {
    if (data) {
      API.Payments.getWalletBalance(data?.orderId).then((response) => {
        setWalletBalance(response);
      });
    }
  }, [data]);

  return (
    <PaymentContext.Provider
      value={{
        isLoading,
        data,
        gateways,
        isPaid,
        setIsPaid,
        walletBalance
      }}>
      {children}
    </PaymentContext.Provider>
  );
}
