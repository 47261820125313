import { i18n } from 'dawere-commons';
import { Breadcrumbs, Col, Skeleton, useRouter } from 'dawere-uic';
import { useOrganization } from 'hooks';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';

export function Heading() {
  const { organization, isLoading } = useOrganization();
  const { history } = useRouter();

  const handleClick = useCallback(
    (e: any) => {
      e.preventDefault();
      history?.goBack();
    },
    [history]
  );

  return isLoading ? (
    <Col xs={12} className='mb-4'>
      <Skeleton.Breadcrumbs count={2} />
      <Skeleton.Title className='d-block mb-2' />
    </Col>
  ) : (
    <Col xs={12} className='mb-4'>
      <Breadcrumbs
        as={Link}
        withBackArrow
        onBackClick={handleClick}
        routes={[
          {
            breadcrumb: organization?.name,
            isBack: true
          },
          {
            breadcrumb: i18n('v2.pages.teachers.breadcrumbs.2')
          }
        ]}
      />
      <h3 className='mb-2'>
        {i18n('v2.pages.teachers.title', { name: organization?.name })}
      </h3>
    </Col>
  );
}
