import { useMutation } from '@tanstack/react-query';
import { API } from 'api';
import { SchedulePicker } from 'dawere-uic';

export function Schedule({ enrollmentId, days, onChange = () => {} }: any) {
  const { mutate: update, isLoading } = useMutation({
    mutationFn: (schedule: any) =>
      API.Classroom.changeSchedule(enrollmentId, schedule),
    onSuccess: onChange
  });

  return <SchedulePicker days={days} onConfirm={update} loading={isLoading} />;
}
