import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const SubjectCardLink = styled(Link)`
  color: var(--dw-color-text);

  &:hover {
    text-decoration: none;
    color: var(--dw-color-text);
  }

  > div {
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.03);
    }
  }

  &.disabled {
    pointer-events: none;
    filter: grayscale(1);
  }
`;
