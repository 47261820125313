import { useRouter } from 'dawere-uic';
import { PageWrapper } from 'pages';
import { Redirect } from 'react-router';
import { PATHS } from 'router';
import { getSEO } from 'utils';
import { QPARAMS } from 'values';
import { FreeEnrollProvider } from './FreeEnrollProvider';

/**
 * Path: /inscripcion-prueba-gratis/:orderId/p/:programId
 * Key: FREE_TRIAL_ENROLL
 * @returns {React.ReactElement}
 */
export default function FreeTrialPaymentPage() {
  const { query } = useRouter();

  if (!query?.orderId) {
    return <Redirect to={PATHS.STUDENTS_DASHBOARD} />;
  }

  return (
    <PageWrapper disableCountryPicker>
      {getSEO('v2.pages.enrolment.seo', true)}

      <FreeEnrollProvider
        orderId={query?.orderId}
        programId={query?.programId}
        studentId={query[QPARAMS.Student]}
        redirectTo={query[QPARAMS.Redirect]}
      />
    </PageWrapper>
  );
}
