import { useQuery } from '@tanstack/react-query';
import { API } from 'api';
import React, { useEffect, useState } from 'react';
import { DEFAULT_STALE_TIME, QUERY_KEYS, createQueryKey } from 'values';

export interface ProgramProps {
  program: any;
  calendars: any[] | null;
  periods: any;
  priceSelected: any;
  setPriceSelected: React.Dispatch<React.SetStateAction<any>>;
  product: any;
  currencyMenu: any[];
  isLoading: boolean;
}

export const ProgramContext = React.createContext<ProgramProps>({
  program: null,
  calendars: null,
  periods: null,
  priceSelected: null,
  setPriceSelected: () => {},
  product: null,
  currencyMenu: [],
  isLoading: true
});

export function ProgramProvider({ children, id }: any) {
  const [priceSelected, setPriceSelected] = useState<any>(null);
  const [currencyMenu, setCurrencyMenu] = useState<any[]>([]);

  const { data: program, isLoading: isLoadingProgram } = useQuery({
    staleTime: DEFAULT_STALE_TIME,
    enabled: !!id,
    queryKey: createQueryKey(QUERY_KEYS.PROGRAM_DETAILS, id),
    queryFn: () => API.Programs.get(id)
  });

  const { data: periods, isLoading: isLoadingPeriods } = useQuery({
    staleTime: DEFAULT_STALE_TIME,
    enabled: !!program?.id,
    queryKey: createQueryKey(QUERY_KEYS.PROGRAM_PERIODS, program?.id),
    queryFn: () => API.Programs.getPeriods(program?.id)
  });

  const { data: calendars, isLoading: isLoadingCalendars } = useQuery({
    staleTime: DEFAULT_STALE_TIME,
    enabled: !!program?.id,
    queryKey: createQueryKey(QUERY_KEYS.PROGRAM_CALENDARS, program?.id),
    queryFn: () => API.Programs.getCalendars(program?.id)
  });

  const { data: product, isLoading: isLoadingPrices } = useQuery({
    staleTime: DEFAULT_STALE_TIME,
    enabled: !!program?.id,
    queryKey: createQueryKey(QUERY_KEYS.PROGRAM_PRICES, program?.id),
    queryFn: () => API.Programs.getPrices(program?.id)
  });

  useEffect(() => {
    if (product) {
      setPriceSelected([...product?.prices].shift());
      setCurrencyMenu(() =>
        product?.prices.map((item: any) => ({
          label: item.currency.isoCode,
          value: item
        }))
      );
    }
  }, [product]);

  return (
    <ProgramContext.Provider
      value={{
        program,
        product,
        calendars,
        periods,
        priceSelected,
        setPriceSelected,
        isLoading:
          isLoadingProgram ||
          isLoadingPeriods ||
          isLoadingCalendars ||
          isLoadingPrices,
        currencyMenu
      }}>
      {children}
    </ProgramContext.Provider>
  );
}
