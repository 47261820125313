import { API } from 'api';
import { i18n } from 'dawere-commons';
import {
  AvatarChip,
  Button,
  Col,
  Flex,
  Icons,
  Pagination,
  Row,
  Search,
  Skeleton,
  Table,
  Visible
} from 'dawere-uic';
import { useOrganization } from 'hooks';
import { SearchKeyword } from 'pages/private/organizationCharts/components/SearchKeyword';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ModalAddStudent } from '../../components/ModalAddStudent';

export function StudentsToEnrollTab() {
  const { organization } = useOrganization();

  const [data, setData] = useState<any[]>([]);
  const [selection, setSelection] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [sorting, setSorting] = useState('');
  const [keyword, setKeyword] = useState('');

  const [openAddModal, setOpenAddModal] = useState(false);

  const desktopColumns = useMemo(
    () => [
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.2.full.tabs.6.table.columns.name'
        ),
        accessor: 'id',
        Cell: ({ value, row }: any) => {
          return (
            <AvatarChip size={35} src={row?.original?.avatar} gap={2}>
              <div>{row?.original?.name}</div>
            </AvatarChip>
          );
        }
      },
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.2.full.tabs.6.table.columns.email'
        ),
        accessor: 'email'
      }
    ],
    []
  );

  const mobileColumns = useMemo(
    () => [
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.2.full.tabs.1.table.columns.student'
        ),
        accessor: 'id',
        Cell: ({ value, row }: any) => {
          return (
            <div>
              <strong className='mb-0 d-block fs-1'>
                {row.original?.name}
              </strong>
              <small
                className='d-block text-muted mb-2'
                style={{ marginTop: '-2px' }}>
                {row.original?.email}
              </small>
            </div>
          );
        }
      }
    ],
    []
  );

  // Reload data
  useEffect(() => {
    setIsLoading(true);

    API.Organizations.getStudents(
      organization.id,
      {
        page: pageIndex,
        size: pageSize,
        sort: sorting,
        keyword: keyword
      },
      'NOT_ENROLLED'
    )
      .then((response: any) => {
        setData(response.data);
        setPageCount(response.totalPages);
        setTotal(response.totalRows);
      })
      .finally(() => setIsLoading(false));
  }, [organization.id, pageIndex, pageSize, sorting, keyword]);

  const handlePaging = useCallback(({ pageSize, pageIndex }: any) => {
    setPageSize(pageSize);
    setPageIndex(pageIndex);
  }, []);

  const handleSorting = useCallback((data: any) => {
    const [sort] = data;
    if (sort) {
      const description = `student.name,${sort.desc ? 'desc' : 'asc'}`;
      setSorting(description);
    } else {
      setSorting('');
    }
  }, []);

  const handleSearch = useCallback((data: any) => {
    setKeyword(() => {
      setPageIndex(0);
      return data.search;
    });
  }, []);

  const handleDownload = useCallback(() => {
    setIsDownloading(true);

    API.Organizations.getStudents(
      organization.id,
      { keyword: keyword },
      'NOT_ENROLLED',
      true
    ).finally(() => setIsDownloading(false));
  }, [keyword, organization.id]);

  return (
    <>
      <SearchKeyword keyword={keyword} onSearch={handleSearch} />

      <Visible xs sm>
        <Search
          name='search'
          allowEmptySearch
          value={keyword}
          onSubmit={handleSearch}
          disabled={isLoading}
        />
      </Visible>

      <Flex className='mb-2' align='center' justify='end'>
        <Visible md lg xl>
          <div>
            <Button
              variant='clear'
              className='ml-2'
              disabled={isLoading}
              loading={isDownloading}
              onClick={handleDownload}>
              {i18n(
                'v2.pages.organizationDashboard.tabs.2.full.tabs.1.buttons.download'
              )}{' '}
              <Icons.Download />
            </Button>
          </div>
        </Visible>
      </Flex>

      <Visible xs sm>
        <Table
          data={data}
          columns={mobileColumns}
          loading={isLoading}
          pageable={false}
          manualSorting
          onSorting={handleSorting}
        />
      </Visible>
      <Visible md lg xl>
        <Table
          data={data}
          columns={desktopColumns}
          loading={isLoading}
          pageable={false}
          manualSorting
          onSorting={handleSorting}
        />
      </Visible>

      <Row>
        <Visible lg xl>
          <Col xs={3} />
        </Visible>

        <Col>
          <Pagination
            align='center'
            className='my-4'
            onUpdate={handlePaging}
            pageIndex={pageIndex}
            pageCount={pageCount}
            disabled={isLoading}
          />
        </Col>

        <Visible md lg xl>
          <Col xs={3} className='d-flex align-items-center justify-content-end'>
            {isLoading ? (
              <Skeleton width={120} wrapper='small' />
            ) : (
              <small>
                <strong>{total}</strong>{' '}
                {i18n(
                  'v2.pages.organizationDashboard.tabs.2.full.tabs.6.table.count'
                )}
              </small>
            )}
          </Col>
        </Visible>
      </Row>

      <ModalAddStudent open={openAddModal} onClose={setOpenAddModal} />
    </>
  );
}
