import { Rest } from 'dawere-commons';
import { Utils } from 'dawere-uic';

async function _processEvaluation(evaluation: any, readOnly: boolean = false) {
  let contextNumber = 1;
  let questionNumber = 1;

  evaluation.realNumberOfQuestions = evaluation.questions.filter(
    (element: any) => !Utils.has(element, 'context')
  ).length;

  evaluation.questions.forEach((question: any, index: number) => {
    const isFirst = index === 0;
    const isLast = index === evaluation.questions.length - 1;

    question.readOnly = readOnly;
    question.index = index;
    question.isFirst = isFirst;
    question.isLast = isLast;
    question.value = question.id;
    question.isAnswered = false;
    question.isCorrect = question.readOnly
      ? evaluation.correctAnswers.includes(question.id)
      : null;
    question.next = Math.min(index + 1, evaluation.questions.length - 1);
    question.previous = Math.max(index - 1, 0);
    question.status = null;

    if (Utils.has(question, 'context')) {
      question.kind = 'CONTEXT';
      question.label = `Contexto ${contextNumber++}`;
      question.isContext = true;
      question.isQuestion = false;
    } else {
      const answers = Utils.has(evaluation, 'answers')
        ? evaluation.answers[question.id]
        : null;
      question.isContext = false;
      question.isQuestion = true;
      question.label = `Pregunta ${questionNumber++}/${
        evaluation.realNumberOfQuestions
      }`;
      question.sources = question.sources.sort((a: any, b: any) =>
        a.name.localeCompare(b.name)
      );
      question.status = question.readOnly
        ? question.isCorrect
          ? 'CORRECT'
          : 'INCORRECT'
        : null;

      if (Utils.has(question, 'rows')) {
        question.kind = 'TABLE';
        question.columns = Utils.copy(question.options);
        question.rows = question.rows.map((option: any, index: number) => ({
          index,
          ...option,
          value: answers?.length ? answers[index].toString() : null
        }));
        question.isAnswered = question.rows.every((a: any) => a.value);

        // To fix autocomplete bug
        delete question.options;
      }

      if (Utils.has(question, 'qty')) {
        question.kind = 'COMPLETION';
        question.answers = Array(question.qty)
          .fill({})
          .map((option: any, index: number) => ({
            index,
            label: `${index + 1})`,
            // label: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[index],
            value: answers ? answers[index] : null
          }));
        question.isAnswered = question.answers.every((a: any) => a.value);
      }

      if (Utils.has(question, 'selectionOptions') && question.simple) {
        question.kind = 'SIMPLE_CHOICE';
        question.answers = answers ? answers[0] : null;
        question.isAnswered = !Utils.isEmpty(question.answers);
      }

      if (Utils.has(question, 'selectionOptions') && !question.simple) {
        question.kind = 'MULTIPLE_CHOICE';
        question.selectionOptions = question.selectionOptions.map(
          (option: any, index: number) => ({
            index,
            ...option,
            isChecked: answers ? answers.includes(option.id) : false
          })
        );
        question.isAnswered = question.selectionOptions.some(
          (a: any) => a.isChecked
        );
      }
    }
  });

  // Timer set up
  let milliseconds = 0;
  let nowMs = 0;
  let endMs = new Date(evaluation.endTime).getTime();

  try {
    const { data: now } = await Rest.get('classroom/public/time');
    nowMs = new Date(now + 'Z').getTime();
  } catch (e) {
    nowMs = new Date().getTime();
  }

  milliseconds = endMs - nowMs;
  evaluation.isClosed = milliseconds <= 0;
  evaluation.milliseconds = milliseconds;

  // Calculate answered questions count
  const answered = evaluation.questions.filter(
    (q: any) => q.isQuestion && q.isAnswered
  );
  evaluation.answeredCount = answered.length || 0;

  return evaluation;
}

function getFormativeTest(subjectId: string, topicId: string) {
  return Rest.get(
    `classroom/subjects/${subjectId}/topics/${topicId}/formative-assessments`
  ).then(({ data: evaluation }: any) => {
    return _processEvaluation(evaluation);
  });
}

function checkAnswer(questionId: string, body: any) {
  return Rest.post(`classroom/questions/${questionId}/verify`, body);
}

function submitFormativeTest(body: any) {
  return Rest.post('classroom/formative-assessments', body);
}

function getPisaTest(attemptId: string) {
  return Rest.get(`classroom/assessments/attempts/${attemptId}`).then(
    ({ data: evaluation }: any) => {
      return _processEvaluation(evaluation);
    }
  );
}

function saveAnswer(attemptId: string, body: any) {
  return Rest.post(`classroom/assessments/attempts/${attemptId}/answers`, body);
}

function submitPisaTest(attemptId: string, body: any) {
  return Rest.post(`classroom/assessments/attempts/${attemptId}/submit`, body);
}

function getPisaTestScore(attemptId: string) {
  return Rest.get(`/classroom/assessments/attempts/${attemptId}/scores`);
}

function getPisaTestAnswers(attemptId: string, isPublic: boolean = false) {
  return Rest.get(
    `classroom/${
      isPublic ? 'public/' : ''
    }assessments/attempts/${attemptId}/answers`
  ).then(({ data: evaluation }: any) => {
    return _processEvaluation(evaluation, true);
  });
}

function getPeerReviewers(projectId: string) {
  return Rest.get(`/classroom/projects/${projectId}/peer-reviews`).then(
    ({ data }: any) => {
      return data;
    }
  );
}

function submitPeerReview(attemptId: string, payload: any) {
  return Rest.post(
    `/classroom/projects/attempts/${attemptId}/peer-reviews`,
    payload
  );
}

function getProjectScores(attemptId: string) {
  return Rest.get(`/classroom/projects/attempts/${attemptId}/scores`).then(
    ({ data }) => {
      const scores = {
        maxScore: data.maxScore,
        passingScore: data.passingScore,
        score: data.score,
        peerReviews: data.peerReviews.map((review: any, index: number) => {
          const rubrics = review.rubrics.rubrics.map((criteria: any) => {
            const answerId = review?.rubrics?.answers
              ? review?.rubrics?.answers[criteria.id]
              : 0;
            const factor = [0.1, 0.65, 0.85, 1][answerId] ?? 0;
            const border =
              [
                'border-error',
                'border-wrong',
                'border-tertiary',
                'border-success'
              ][answerId] ?? 'border-error';
            const level = ['0', '33', '66', '100'][answerId] ?? '0';
            const description =
              criteria[
                `description${level}` as
                  | 'description100'
                  | 'description66'
                  | 'description33'
                  | 'description0'
              ];

            return {
              id: criteria.id,
              proficiency: criteria.proficiency,
              weight: criteria.weight,
              points: data.maxScore * (criteria.weight / 100),
              aspect: criteria.aspect,
              description: criteria.description,
              cognitiveDemand: criteria.cognitiveDemand,
              result: {
                score: data.maxScore * (criteria.weight / 100) * factor,
                border,
                description,
                level
              }
            };
          });

          return {
            number: index + 1,
            attemptId: review.attemptId,
            reviewUrl: review.reviewUrl,
            reviewScore: review.score,
            comments: review.comments,
            reviewDate: review.reviewDate,
            wasEvaluated: review.score > 0 && !Utils.isEmpty(review.comments),
            rubrics
          };
        })
      };

      return scores;
    }
  );
}

function getProjectRubrics(attemptId: string) {
  return Rest.get(
    `classroom/public/projects/attempts/${attemptId}/scores`
  ).then(({ data }) => {
    const scores = {
      maxScore: data.score.maxScore,
      passingScore: data.score.passingScore,
      score: data.score.score,
      student: data.student,
      evaluationName:
        data.score.peerReviews.length > 0
          ? data.score.peerReviews[0].rubrics.projectName
          : 'Proyecto con co-evaluación',
      peerReviews: data.score.peerReviews.map((review: any, index: number) => {
        const rubrics = review.rubrics.rubrics.map((criteria: any) => {
          const answerId = review?.rubrics?.answers
            ? review?.rubrics?.answers[criteria.id]
            : 0;
          const factor = [0.1, 0.65, 0.85, 1][answerId] ?? 0;
          const border =
            [
              'border-error',
              'border-wrong',
              'border-tertiary',
              'border-success'
            ][answerId] ?? 'border-error';
          const level = ['0', '33', '66', '100'][answerId] ?? '0';
          const description =
            criteria[
              `description${level}` as
                | 'description100'
                | 'description66'
                | 'description33'
                | 'description0'
            ];

          return {
            id: criteria.id,
            proficiency: criteria.proficiency,
            weight: criteria.weight,
            points: data.score.maxScore * (criteria.weight / 100),
            aspect: criteria.aspect,
            description: criteria.description,
            cognitiveDemand: criteria.cognitiveDemand,
            result: {
              score: data.score.maxScore * (criteria.weight / 100) * factor,
              border,
              description,
              level
            }
          };
        });

        return {
          number: index + 1,
          attemptId: review.attemptId,
          reviewUrl: review.reviewUrl,
          reviewScore: review.score,
          comments: review.comments,
          reviewDate: review.reviewDate,
          wasEvaluated: review.score > 0 && !Utils.isEmpty(review.comments),
          rubrics
        };
      })
    };

    return scores;
  });
}

export const Evaluations = {
  FormativeTest: {
    get: getFormativeTest,
    submit: submitFormativeTest
  },
  SummativeTest: {
    get: getPisaTest,
    submit: submitPisaTest,
    saveAnswer: saveAnswer,
    getScore: getPisaTestScore,
    getDetails: getPisaTestAnswers
  },
  Projects: {
    getPeerReviewers,
    submitPeerReview,
    getScores: getProjectScores,
    getDetails: getProjectRubrics
  },
  checkAnswer
};
