const esDO = {
  lang: `es-DO,es`,
  shortLang: 'es-DO',
  pages: {
    home: {
      seo: {
        title: `Dawere República Dominicana | Escuela Virtual de Calidad`,
        description: `Educación prémium, asequible y certificada por el Ministerio de Educación de República Dominicana. Continúa tus estudios con la mejor educación virtual de América Latina.`,
        keywords: `escuela, colegio`,
        ogTitle: `Dawere República Dominicana | Escuela Virtual de Calidad`,
        ogDescription: `Educación prémium, asequible y certificada por el Ministerio de Educación de República Dominicana. Continúa tus estudios con la mejor educación virtual de América Latina. `,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/seo_homepage.jpg`
      },
      mainProgramId: {
        dev: `bachillerato-virtual-republica-dominicana`,
        qa: `bachillerato-virtual-republica-dominicana`,
        prod: `bachillerato-virtual-republica-dominicana`
      },
      doubleDegreeProgramId: {
        dev: `bachillerato-virtual-estados-unidos`,
        qa: `bachillerato-virtual-estados-unidos`,
        prod: `bachillerato-virtual-estados-unidos`
      },
      info: {
        whatsappNumber: `+1 (849) 287-8545`,
        phoneNumber: ``,
        emailInfo: `inscripciones@dawere.com.do`
      },
      section1: {
        subtitle: `Escuela: Dawere República Dominicana`
      },
      section2: {
        title: `El título de bachiller es avalado por el MINERD`,
        text: `
          <p>Obtén tu título de bachiller avalado por el Ministerio de Educación de República Dominicana.</p>

          <p>La plataforma de educación formal online Dawere y la Fundación RBICG Sport Academy han formado una alianza educativa para traerte el mejor Bachillerato Virtual.</p>

          <p>La Fundación RBICG Sport Academy con RNC 4-30-15629-9 está registrada ante el Ministerio de Educación de República Dominicana bajo el código del sistema de gestión no. 15716.</p>
        `,
        images: {
          1: {
            url: `https://storage.googleapis.com/dawere-web-assets/logotipos/Ministerio%20de%20educaci%C3%B3n%20-%20Dominicana.png`,
            alt: `MINISTERIO DE EDUCACION DE REP DOMINICANA`
          }
        }
      },
      section3: {
        benefits: {
          3: {
            description: `El bachillerato puede ser cursado en 6 años o menos`
          }
        }
      },
      section6: {
        steps: {
          1: {
            text: ` Si tienes 15 años de edad o más puedes inscribirte en Dawere sin importar tu ubicación geográfica. Solo debes llenar el formulario de inscripción, señalar los años aprobados hasta la fecha y realizar tu pago.`
          },
          2: {
            title: `Sube tus requisitos`,
            text: `Debes entregar copia de tu documento de identidad y una foto actualizada 2x2, entre otros recaudos.`
          },
          4: {
            text: `Al aprobar todas las materias de rigor y asistir a tu ceremonia de graduación virtual, podrás retirar tu título en la Fundación RBICG Sport Academy o solicitar que se te envíe hasta la puerta de tu casa.`
          }
        }
      },
      section9: {
        categories: {
          3: {
            text: `Estudia en una escuela virtual de calidad y obtén título de bachiller avalado por el MINERD. Estudiarás de forma rápida y divertida a través de videoclases pregrabadas, material de apoyo, evaluaciones de práctica, un foro-chat para aclarar tus dudas y más. Regístrate para recibir más información.`
          }
        }
      }
    },
    catalog: {
      seo: {
        title: `Programas Educativos de Rep. Dominicana | Avalados por el MINERD`,
        description: `Explora el catálogo de programas educativos y descubre las bondades de una educación virtual que se ajusta a tu ritmo y horario. Ofrecemos becas a quienes califiquen. `,
        keywords: `MINERD, Ministerio de Educacion, Educacion en Republica Dominicana`,
        ogTitle: `Programas Educativos de Rep. Dominicana | Avalados por el MINERD`,
        ogDescription: `Explora el catálogo de programas educativos y descubre las bondades de una educación virtual que se ajusta a tu ritmo y horario. Ofrecemos becas a quienes califiquen.  `,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/seo_logo_minerd_dominicana.jpg`
      }
    },
    organizations: {
      seo: {
        title: `Dawere República Dominicana | Para Organizaciones`,
        keywords: `organizaciones, sistema educativo, fundaciones`,
        ogTitle: `Dawere República Domicana para Organizaciones`
      },
      section4: {
        governments: {
          text: `
            <p>
              Según la UNESCO, en los sistemas educativos tradicionales de América Latina, solo 45% de los que inician el bachillerato se gradúan.
              </p>
            <p>
              Hay una deserción del 15% de los estudiantes todos los años y más del 30% de los jóvenes en edad escolar están fuera del sistema educativo tradicional. Con el uso de nuestra tecnología educativa, esto puede cambiar.
              </p>

            <p>
              Te invitamos a proveerle a todos tus ciudadanos educación de calidad gratuita. Parece una propuesta utópica pero se puede convertir en realidad gracias a los servicios de educación a distancia de Dawere. Nuestro Bachillerato Virtual ha sido diseñado para educar a millones de personas a una fracción del costo de la educación tradicional presencial. Compruébalo por ti mismo y empodera al país con la excelente educación que todos merecen.
              </p>
          `
        },
        companies: {
          text: `
           <p>
              El mundo del deporte profesional es extremadamente reñido, por lo que solo un porcentaje minúsculo logra convertirse en jugador profesional. ¿Qué ocurre con el resto?

              </p>
            <p>
              Recibir educación de calidad en paralelo a los entrenamientos puede garantizarle un futuro a todos tus jugadores sin excepción.

              </p>
            <p>
              Nuestro Bachillerato Virtual se adapta al ritmo y horario de los estudiantes. Con Dawere tus jugadores pueden formarse académicamente y perseguir sus sueños.
              </p>
          `
        }
      }
    },
    shared: {
      contact: {
        info: {
          phone: {
            title: `TELÉFONO`,
            text: ``
          },
          whatsapp: {
            title: `WHATSAPP`,
            text: `+1 (849) 287-8545`
          },
          email: {
            title: `EMAIL`,
            text: ``
          }
        },
        buttons: {
          faq: `Preguntas frecuentes`
        }
      }
    },
    helpCenter: {
      seo: {
        keywords: `dawere, dawere dominicana, dawere republica dominicana`
      },
      urls: {
        scholarshipForm: `https://forms.gle/Lmv1TwqoyGkvW2hS6`
      },
      contact: {
        info: {
          enrolled: {
            whatsapp: `+1 (849) 287-8545`
          },
          unregistered: {
            whatsapp: `+1 (849) 287-8545`
          }
        }
      }
    },
    philosophy: {
      seo: {
        keywords: `Educacion, enseñanza virtual, modelo educativo, calidad educativa, educacion de calidad`
      }
    }
  },
  sections: {
    footer: {
      column1: {
        text: `<p>La plataforma de educación formal online Dawere y la Fundación RBICG Sport Academy han formado una alianza para traerte la mejor experiencia de aprendizaje virtual.</p>
        <p>La Fundación RBICG Sport Academy con RNC 4-30-15629-9 está registrada ante el Ministerio de Educación de República Dominicana bajo el código del sistema de gestión no. 15716.</p>`
      }
    }
  }
};

export default esDO;

export { esDO };
