import { Refresh } from '@mui/icons-material';
import { API } from 'api';
import { i18n } from 'dawere-commons';
import {
  Badge,
  Button,
  Col,
  DatePicker,
  Flex,
  Pagination,
  Row,
  Search,
  Skeleton,
  Table,
  Utils,
  Visible
} from 'dawere-uic';
import { useOrganization } from 'hooks';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';

const DatePickerButton = forwardRef(({ value, onClick }: any, ref: any) => (
  <Button variant='filter' onClick={onClick} ref={ref} caret>
    {value || i18n('v2.pages.organizationDashboard.tabs.4.buttons.filter')}
  </Button>
));

export function BillingTab() {
  const { organization } = useOrganization();

  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const desktopColumns = useMemo(
    () => [
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.4.table.columns.status'
        ),
        accessor: 'status',
        Cell: ({ cell }: any) => {
          // DRAFT: "En curso" o "Impompleto" boton de continuar (entro a stripe y me salgo)
          // PENDING: "Procesando" no mostrar boton
          // CONFIRMED: "Pagado" boton de "Descargar PDF"
          // REJECTED: "Rechazado" boton de los puntos para volver a pagar o anular
          // ERROR: "Error" sin boton
          // VOIDED: "Anulado" boton de "Ver detalles"
          const colors: any = {
            DRAFT: 'warning',
            PENDING: 'warning',
            CONFIRMED: 'success',
            PAID: 'success',
            REJECTED: 'error',
            ERROR: 'warning',
            VOIDED: 'muted'
          };

          return (
            <Badge pilled variant={colors[cell.value]}>
              {i18n(`v2.pages.organizationDashboard.tabs.4.${cell.value}`)}
            </Badge>
          );
        }
      },
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.4.table.columns.number'
        ),
        accessor: 'number'
      },
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.4.table.columns.date'
        ),
        accessor: 'paymentDate'
      },
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.4.table.columns.amount'
        ),
        accessor: 'amount',
        Cell: ({ cell }: any) => {
          return (
            <>
              {cell.row?.original?.currency?.symbol || '$'}
              {Utils.toCurrency(cell.value)} (
              {cell.row?.original?.currency?.isoCode})
            </>
          );
        }
      },
      {
        Header: '',
        accessor: 'action',
        unclickable: true,
        disableSortBy: true,
        Cell: (cell: any) => {
          return (
            <div className='text-right'>
              <Button
                variant='outline'
                as={Link}
                target='_blank'
                to={PATHS.get('RECEIPT', {
                  receiptId: cell.row?.original?.id
                })}>
                {i18n('v2.pages.organizationDashboard.tabs.4.buttons.download')}
              </Button>
            </div>
          );
        }
      }
    ],
    []
  );

  const mobileColumns = useMemo(
    () => [
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.4.table.columns.payments'
        ),
        accessor: 'id',
        Cell: ({ value, row }: any) => {
          const status = row?.original?.status;

          const colors: any = {
            DRAFT: 'warning',
            PENDING: 'warning',
            CONFIRMED: 'success',
            PAID: 'success',
            REJECTED: 'error',
            ERROR: 'warning',
            VOIDED: 'muted'
          };

          return (
            <div>
              <Badge pilled variant={colors[status]}>
                {i18n(`v2.pages.organizationDashboard.tabs.4.${status}`)}
              </Badge>

              <strong className='mb-0 d-block fs-1'>
                {row.original?.number}
              </strong>

              <div>{row.original?.paymentDate}</div>
              <div>
                {row.original?.currency?.symbol || '$'}
                {Utils.toCurrency(row.original?.amount)}
              </div>
              <div className='mt-2'>
                <Button
                  variant='outline'
                  as={Link}
                  target='_blank'
                  block
                  to={PATHS.get('RECEIPT', {
                    receiptId: row?.original?.id
                  })}>
                  {i18n(
                    'v2.pages.organizationDashboard.tabs.4.buttons.download'
                  )}
                </Button>
              </div>
            </div>
          );
        }
      }
    ],
    []
  );

  const load = useCallback(() => {
    setIsLoading(true);

    API.Organizations.getBills(organization.id, {
      page: pageIndex,
      size: pageSize,
      keyword: keyword,
      startDate: startDate
        ? Utils.formatDate(startDate, 'yyyy-mm-dd', true)
        : null,
      endDate: endDate ? Utils.formatDate(endDate, 'yyyy-mm-dd', true) : null
    })
      .then((response: any) => {
        setData(response.data);
        setPageCount(response.totalPages);
        setTotal(response.totalRows);
      })
      .finally(() => setIsLoading(false));
  }, [endDate, keyword, organization.id, pageIndex, pageSize, startDate]);

  // Reload data
  useEffect(() => {
    load();
  }, [load]);

  const handlePaging = useCallback(({ pageSize, pageIndex }: any) => {
    setPageSize(pageSize);
    setPageIndex(pageIndex);
  }, []);

  const handleSearch = useCallback((data: any) => {
    setKeyword(() => {
      setPageIndex(0);
      return data.search;
    });
  }, []);

  const handleDateChange = useCallback((data: any) => {
    if (data[0] && data[1]) {
      setStartDate(data[0]);
      setEndDate(data[1]);
    }
  }, []);

  return (
    <>
      <Visible xs sm>
        <Search
          name='search'
          allowEmptySearch
          value={keyword}
          onSubmit={handleSearch}
          disabled={isLoading}
        />

        <DatePicker
          name='datepicker'
          monthsShown={2}
          selectsRange
          onChange={handleDateChange}
          customInput={<DatePickerButton />}
          disabled={isLoading}
        />
      </Visible>

      <Visible md lg xl>
        <Flex className='mb-2' align='center' justify='between'>
          <DatePicker
            className='m-0'
            name='datepicker'
            monthsShown={2}
            selectsRange
            onChange={handleDateChange}
            customInput={<DatePickerButton />}
            disabled={isLoading}
          />

          <Flex>
            <Button
              variant='action'
              className='mr-2'
              loading={isLoading}
              circle
              icon={<Refresh fontSize='small' />}
              style={{ borderRadius: '8px' }}
              onClick={load}
            />

            <Search
              className='m-0'
              name='search'
              allowEmptySearch
              value={keyword}
              onSubmit={handleSearch}
              disabled={isLoading}
            />
          </Flex>
        </Flex>
      </Visible>

      <Visible xs sm>
        <Table
          data={data}
          columns={mobileColumns}
          loading={isLoading}
          pageable={false}
          sortable={false}
        />
      </Visible>

      <Visible md lg xl>
        <Table
          data={data}
          columns={desktopColumns}
          loading={isLoading}
          pageable={false}
          sortable={false}
        />
      </Visible>

      <Row>
        <Visible lg xl>
          <Col xs={3} />
        </Visible>

        <Col>
          <Pagination
            align='center'
            className='my-4'
            onUpdate={handlePaging}
            pageIndex={pageIndex}
            pageCount={pageCount}
            disabled={isLoading}
          />
        </Col>

        <Visible md lg xl>
          <Col xs={3} className='d-flex align-items-center justify-content-end'>
            {isLoading ? (
              <Skeleton width={120} wrapper='small' />
            ) : (
              <small>
                <strong>{total}</strong>{' '}
                {i18n('v2.pages.organizationDashboard.tabs.4.table.count')}
              </small>
            )}
          </Col>
        </Visible>
      </Row>
    </>
  );
}
