import { AlternateEmail, Fingerprint, LocalPhone } from '@mui/icons-material';
import { API } from 'api';
import { Col, Flex, Header, Tooltip, Utils, useRouter } from 'dawere-uic';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { ObjectParam, useQueryParams } from 'use-query-params';
import { QPARAMS } from 'values';

export function Heading() {
  const { query } = useRouter();
  const [qs] = useQueryParams({
    [QPARAMS.Organization]: ObjectParam
  });

  const [isLoading, setIsLoading] = useState(true);
  const [student, setStudent] = useState<any>(null);

  useEffect(() => {
    if (query?.studentId) {
      setIsLoading(true);

      API.Students.get(query?.studentId)
        .then((response) => setStudent(response.data))
        .finally(() => setIsLoading(false));
    }
  }, [query?.studentId]);

  return (
    <Col xs={12}>
      <Header
        asBox
        withBackArrow
        as={Link}
        background='platinium'
        loading={isLoading}
        src={student?.picturePath}
        title={Utils.getFullname(student?.name, student?.lastName)}
        subtext={[
          <Flex gap={1}>
            <AlternateEmail fontSize='small' />
            <a href={`mailto:${student?.email}`}>{student?.email}</a>
          </Flex>,
          student?.phoneNumber ? (
            <Flex gap={1}>
              <LocalPhone fontSize='small' />
              <a
                href={`tel:${Utils.joinStrings(
                  student?.phoneCode,
                  student?.phoneNumber
                )}`}>
                {Utils.joinStrings(student?.phoneCode, student?.phoneNumber)}
              </a>
            </Flex>
          ) : null,
          student?.userDetails?.identityCard ? (
            <Flex gap={1}>
              <Fingerprint fontSize='small' />
              <Tooltip text={student?.userDetails?.identityType?.itemValue}>
                <span>{student?.userDetails?.identityCard}</span>
              </Tooltip>
            </Flex>
          ) : null
        ]}
        routes={[
          {
            breadcrumb: qs[QPARAMS.Organization]?.name,
            to: PATHS.get(
              'ORGANIZATIONS_DASHBOARD',
              {
                organizationId: qs[QPARAMS.Organization]?.id
              },
              { [QPARAMS.Tab]: 1 }
            )
          }
          // {
          //   breadcrumb: i18n('v2.pages.studentDetails.breadcrumbs.2')
          // }
        ]}
      />
    </Col>
  );
}
