import { i18n } from 'dawere-commons';
import { Block, Button, Col, Row } from 'dawere-uic';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { getEnvironment, getFullCountryInfo } from 'utils';

export function HowWorksSection() {
  const { iso3: country } = getFullCountryInfo();

  return (
    <Block>
      <Col xs={12}>
        <h2 className='mb-6'>{i18n('v2.pages.home.section6.title')}</h2>

        <Row>
          <Col xs={12} lg={6} className='mb-4 mb-lg-6 order-lg-0'>
            <h5 className='text-primary text-uppercase'>
              {i18n('v2.pages.home.section6.steps.1.subtitle')}
            </h5>
            <h3>{i18n('v2.pages.home.section6.steps.1.title')}</h3>
            <p>{i18n('v2.pages.home.section6.steps.1.text')}</p>
          </Col>

          <Col xs={12} lg={6} className='mb-4 mb-lg-6 order-lg-2'>
            <h5 className='text-primary text-uppercase'>
              {i18n('v2.pages.home.section6.steps.2.subtitle')}
            </h5>
            <h3>{i18n('v2.pages.home.section6.steps.2.title')}</h3>
            <p>
              {i18n('v2.pages.home.section6.steps.2.text')}{' '}
              {i18n('v2.pages.home.section6.steps.2.programId') &&
              country !== 'USA' ? (
                <Link
                  to={PATHS.get('PROGRAM', {
                    programId: i18n(
                      'v2.pages.home.mainProgramId.' + getEnvironment()
                    )
                  })}>
                  {i18n('v2.pages.home.section6.links.requirements')}
                </Link>
              ) : null}
            </p>
          </Col>

          <Col xs={12} lg={6} className='mb-4 mb-lg-6 order-lg-1'>
            <h5 className='text-primary text-uppercase'>
              {i18n('v2.pages.home.section6.steps.3.subtitle')}
            </h5>
            <h3>{i18n('v2.pages.home.section6.steps.3.title')}</h3>
            <p>{i18n('v2.pages.home.section6.steps.3.text')}</p>
          </Col>

          <Col xs={12} lg={6} className='mb-4 mb-lg-6 order-lg-4'>
            <h5 className='text-primary text-uppercase'>
              {i18n('v2.pages.home.section6.steps.4.subtitle')}
            </h5>
            <h3>{i18n('v2.pages.home.section6.steps.4.title')}</h3>
            <p>{i18n('v2.pages.home.section6.steps.4.text')}</p>
          </Col>
        </Row>
      </Col>

      {country !== 'USA' ? (
        <Col xs={12}>
          <Button
            as={Link}
            to={PATHS.get('PROGRAM', {
              programId: i18n('v2.pages.home.mainProgramId.' + getEnvironment())
            })}
            variant='outline'
            className='w-100 w-md-auto'>
            {i18n('v2.pages.home.section6.buttons.moreInfo')}
          </Button>
        </Col>
      ) : null}
    </Block>
  );
}
