import { API } from 'api';
import { Session } from 'dawere-commons';
import { Comments, IComment } from 'dawere-uic';
import { useCallback, useEffect, useState } from 'react';

type Props = {
  resourceId: string;
  subjectId: string;
  pageSize?: number;
  readOnly?: boolean;
};

type StatusProps = {
  comments: IComment[];
  lastId?: number;
  total: number;
  offset: number;
  eoc: boolean;
  sort: 'CHRONOLOGICAL' | 'RELEVANCE';
  isLoading: boolean;
  isPaginating: boolean;
  isLoadingTotal: boolean;
};

function reset(): StatusProps {
  return {
    comments: [],
    lastId: undefined,
    total: 0,
    offset: 0,
    eoc: false,
    sort: 'CHRONOLOGICAL',
    isLoading: true,
    isPaginating: false,
    isLoadingTotal: true
  };
}

export function Forum({
  resourceId,
  subjectId,
  pageSize = 25,
  readOnly = false
}: Props) {
  const [status, setStatus] = useState<StatusProps>(reset());

  const handleVote = useCallback((comment: any, value: boolean = true) => {
    return API.Forum.vote(comment.id, value);
  }, []);

  const handleSort = useCallback((sort) => {
    setStatus((state) => ({ ...state, sort: sort }));
  }, []);

  const handleComment = useCallback(
    (text: string) => {
      return API.Forum.comment(resourceId, subjectId, text).then((comment) => {
        setStatus((state) => ({
          ...state,
          comments: [comment, ...state.comments],
          total: state.total + 1
        }));
      });
    },
    [resourceId, subjectId]
  );

  const handleReply = useCallback(
    (parentComment: any, text: string) => {
      return API.Forum.comment(
        resourceId,
        subjectId,
        text,
        parentComment.id
      ).then((reply) => {
        parentComment.comments.unshift(reply);
        parentComment.numberOfComments++;
        setStatus((state) => ({ ...state, total: state.total + 1 }));
      });
    },
    [resourceId, subjectId]
  );

  // Paginate main comments using global OFFSET
  const handlePagination = useCallback(() => {
    API.Forum.list(resourceId, {
      sort: status.sort,
      size: pageSize,
      offset: status.offset + pageSize,
      lastId: status.lastId
    }).then((data) => {
      setStatus((state) => ({
        ...state,
        offset: state.offset + pageSize,
        comments: state.comments.concat(data.comments),
        lastId: data.lastId,
        eoc: data.eoc
      }));
    });
  }, [resourceId, status.sort, status.offset, status.lastId, pageSize]);

  // Paginate replies using internal OFFSET
  const handleSeeMore = useCallback(
    (comment: any) => {
      return API.Forum.list(resourceId, {
        parent_id: comment.id,
        sort: status.sort,
        size: pageSize,
        offset: comment.offset + pageSize,
        lastId: comment.lastId
      }).then((data) => {
        comment.comments = comment.comments.concat(data.comments);
        comment.lastId = data.lastId;
        comment.offset = comment.offset + pageSize;
      });
    },
    [pageSize, resourceId, status.sort]
  );

  // Open replies list
  const handleOpen = useCallback(
    (comment: any) => {
      return API.Forum.list(resourceId, {
        parent_id: comment.id,
        sort: status.sort,
        size: pageSize,
        offset: 0
      }).then((data) => {
        comment.comments = data.comments;
        comment.lastId = data.lastId;
        comment.offset = 0;
      });
    },
    [pageSize, resourceId, status.sort]
  );

  // First load with OFFSET == 0
  useEffect(() => {
    if (resourceId) {
      setStatus(reset());

      API.Forum.list(resourceId, {
        sort: status.sort,
        size: pageSize,
        offset: 0
      })
        .then((data) => {
          setStatus((state) => ({
            ...state,
            comments: state.comments.concat(data.comments),
            lastId: data.lastId,
            eoc: data.eoc
          }));
        })
        .finally(() => setStatus((state) => ({ ...state, isLoading: false })));
    }
  }, [pageSize, resourceId, status.sort]);

  // Load totals
  useEffect(() => {
    if (resourceId) {
      setStatus((state) => ({ ...state, isLoadingTotal: true }));

      API.Forum.getTotal(resourceId)
        .then((total) => setStatus((state) => ({ ...state, total })))
        .finally(() =>
          setStatus((state) => ({ ...state, isLoadingTotal: false }))
        );
    }
  }, [resourceId]);

  return (
    <Comments
      hideSorting
      comments={status.comments}
      resourceId={resourceId}
      account={Session.getAccountInfo()}
      onComment={handleComment}
      onReply={handleReply}
      onSort={handleSort}
      onOpen={handleOpen}
      onVote={handleVote}
      onPaginate={handlePagination}
      hasMore={!status.eoc}
      onSeeMore={handleSeeMore}
      loading={status.isLoading || status.isLoadingTotal}
      total={status.total}
      readOnly={readOnly}
    />
  );
}
