import { Session } from 'dawere-commons';
import { Block, useRouter } from 'dawere-uic';
import { useSetDefaultDashboard } from 'hooks';
import { PageWrapper } from 'pages';
import { OrganizationProvider } from 'providers';
import { Redirect } from 'react-router';
import { getLoginRedirectPath, getSEO } from 'utils';
import { Heading } from './sections/Heading';
import { TabsSection } from './sections/TabsSection';

/**
 * Path: /panel/organizacion/:organizationId
 * Key: ORGANIZATIONS_DASHBOARD
 * @returns {React.ReactElement}
 */
export default function OrganizationsDashboardPage() {
  const { query } = useRouter();
  useSetDefaultDashboard('ORGANIZATION', query?.organizationId);

  const account = Session.getProfiles();

  if (!account?.organization) {
    const path = getLoginRedirectPath();
    return <Redirect to={path} />;
  }

  return (
    <PageWrapper>
      {getSEO('v2.pages.organizationDashboard.seo', true)}

      <OrganizationProvider id={query?.organizationId}>
        <Block narrow>
          {/* Titulo y avatar */}
          <Heading />

          {/* Tabs principales */}
          <TabsSection />
        </Block>
      </OrganizationProvider>
    </PageWrapper>
  );
}
