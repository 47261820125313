import { API } from 'api';
import { useRouter } from 'dawere-uic';
import React, { useEffect, useState } from 'react';
import { PATHS } from 'router';

export interface OrganizationProps {
  organization: any;
  isLoading: boolean;
}

export const OrganizationContext = React.createContext<OrganizationProps>({
  organization: null,
  isLoading: true
});

export function OrganizationProvider({ children, id }: any) {
  const { push } = useRouter();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [organization, setOrganization] = useState<any>(null);

  // Get organization base info
  useEffect(() => {
    if (id) {
      setIsLoading(true);

      API.Organizations.get(id)
        .then((data: any) => {
          setOrganization(data);
        })
        .catch((error: any) => {
          push(PATHS.MY_ORGANIZATIONS);
        })
        .finally(() => setIsLoading(false));
    }
  }, [id, push]);

  return (
    <OrganizationContext.Provider
      value={{
        organization,
        isLoading
      }}>
      {children}
    </OrganizationContext.Provider>
  );
}
