import { CalendarMonth } from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';
import { API } from 'api';
import { Schedule, StudentDashboardTour, SubjectCardLink } from 'components';
import { Session, i18n } from 'dawere-commons';
import { Callout, Empty, Flex, Mesh, SubjectCard, Utils } from 'dawere-uic';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { QPARAMS } from 'values';
import { useStudentDashboard } from '../../hooks/useStudentDashboard';
import { NextPeriodPanel } from './NextPeriodPanel';

export function SubjectsPanel({ enrollment }: any) {
  const { rootRoute, readOnly, studentId } = useStudentDashboard();

  const [data, setData] = useState(enrollment);
  const [hideContinuePanel, setHideContinuePanel] = useState(false);

  const { program, status, schedule } = data;
  const subjectsQty = data?.subjects?.length || 0;
  const withCalendar = program?.byCalendar;

  const {
    data: progress,
    isLoading: isLoadingProgress,
    isFetching: isFetchingProgress
  } = useQuery({
    enabled: !!enrollment?.id && !!Session.getAccountInfo(),
    queryKey: ['enrollment-progress', enrollment?.id],
    queryFn: () => API.Students.getEnrollmentProgress(enrollment?.id)
  });

  const {
    data: teachers,
    isLoading: isLoadingTeachers,
    isFetching: isFetchingTeachers
  } = useQuery({
    enabled: !!enrollment?.id && !!Session.getAccountInfo(),
    queryKey: ['enrollment-teachers', enrollment?.id],
    queryFn: () => API.Students.getEnrollmentTeachers(enrollment?.id)
  });

  const handleNextSubject = useCallback((data: any) => {
    setData(data);
    // Obligar a ocultar el panel sin refrescar el enrollment
    setHideContinuePanel(true);
  }, []);

  const getProgressVal = useCallback(
    (subjectId: string, field: string) => {
      return progress?.[subjectId]?.[field] ?? 0;
    },
    [progress]
  );

  const getTeachersVal = useCallback(
    (subjectId: string, field: string) => {
      return teachers?.[subjectId]?.[field];
    },
    [teachers]
  );

  /* Nada que mostrar */
  if (subjectsQty === 0) {
    return (
      <Callout className='text-center border border-platinium bg-white'>
        <Empty size='small' />
      </Callout>
    );
  }

  if (status === 'CONTINUE' && !readOnly && !hideContinuePanel) {
    return <NextPeriodPanel enrollment={data} onContinue={handleNextSubject} />;
  }

  return (
    <>
      <StudentDashboardTour program={program} />

      {status === 'FREE_TRIAL' && data?.order?.id && (
        <Callout className='mb-3 py-3 px-4 bg-tertiary text-white'>
          <span
            dangerouslySetInnerHTML={{
              __html: i18n('v2.pages.studentDashboard.tabs.1.trial.active', {
                trialEndsAt: Utils.formatDate(data?.trialEndsAt, 'dd/mmm/yyyy')
              })
            }}
          />{' '}
          <Link
            to={PATHS.get(
              'FREE_TRIAL_ENROLL',
              {
                orderId: data?.order?.id,
                programId: program?.id
              },
              {
                [QPARAMS.Student]: studentId,
                [QPARAMS.Redirect]: encodeURIComponent(
                  PATHS.get(
                    'TUTORS_DASHBOARD_STUDENT_VIEW',
                    { studentId },
                    { [QPARAMS.Tab]: 3 }
                  )
                )
              }
            )}
            className='fw-500 text-white text-decoration-underline'>
            {i18n('v2.pages.studentDashboard.tabs.1.trial.activeLink')}
          </Link>
          .
        </Callout>
      )}

      {status === 'FREE_TRIAL_ENDED' && data?.order?.id && (
        <Callout className='mb-3 py-3 px-4 bg-tertiary text-white'>
          {i18n('v2.pages.studentDashboard.tabs.1.trial.noActive1')}{' '}
          <Link
            to={PATHS.get(
              'FREE_TRIAL_ENROLL',
              {
                orderId: data?.order?.id,
                programId: program?.id
              },
              {
                [QPARAMS.Student]: studentId,
                [QPARAMS.Redirect]: encodeURIComponent(
                  PATHS.get(
                    'TUTORS_DASHBOARD_STUDENT_VIEW',
                    { studentId },
                    { [QPARAMS.Tab]: 3 }
                  )
                )
              }
            )}
            className='fw-500 text-white text-decoration-underline'>
            {i18n('v2.pages.studentDashboard.tabs.1.trial.noActiveLink')}
          </Link>{' '}
          {i18n('v2.pages.studentDashboard.tabs.1.trial.noActive2')}
        </Callout>
      )}

      <Callout className='mb-3 p-3'>
        <Flex gap={2} align='center' justify='between'>
          {!readOnly && status !== 'FREE_TRIAL_ENDED' ? (
            <Schedule days={schedule} enrollmentId={enrollment?.id} />
          ) : (
            <div />
          )}

          {withCalendar && enrollment?.calendar && (
            <Flex gap={2} align='center'>
              <CalendarMonth fontSize='small' />
              <span>
                {Utils.formatDate(
                  enrollment?.calendar?.dateInit,
                  'dd/mmm/yyyy'
                )}{' '}
                -{' '}
                {Utils.formatDate(enrollment?.calendar?.dateEnd, 'dd/mmm/yyyy')}
              </span>
            </Flex>
          )}
        </Flex>
      </Callout>

      <Mesh gap={3}>
        {data?.subjects?.map(({ id, ...subject }: any, index: number) => (
          <SubjectCardLink
            key={id}
            className={Utils.styles(
              status === 'FREE_TRIAL_ENDED' && 'disabled',
              index === 0 ? 'first-subject-card' : '',
              'fade-animation'
            )}
            to={PATHS.get(
              'CLASSROOM_VARIANT',
              {
                enrollmentId: data?.id,
                subjectId: id
              },
              readOnly
                ? {
                    [QPARAMS.Student]: studentId,
                    [QPARAMS.Back]: encodeURIComponent(
                      PATHS.get(rootRoute, { studentId })
                    )
                  }
                : {}
            )}>
            <SubjectCard
              title={subject.title}
              thumbnail={subject.icon}
              description={subject.period?.name}
              teacher={Utils.getFullname(
                getTeachersVal(id, 'name'),
                getTeachersVal(id, 'lastName')
              )}
              avatar={getTeachersVal(id, 'picturePath')}
              score={getProgressVal(id, 'averageScore')}
              maxScore={program.maxScore}
              grade={getProgressVal(id, 'averageGrade')}
              maxGrade={program.maxGrade}
              progressStatus={getProgressVal(id, 'progressStatus')}
              completedProgress={getProgressVal(id, 'completedProgress')}
              expectedProgress={getProgressVal(id, 'expectedProgress')}
              progressTotal={getProgressVal(id, 'progressTotal')}
              evaluationsTaken={getProgressVal(id, 'evaluationsTaken')}
              totalEvaluations={getProgressVal(id, 'totalEvaluations')}
              loadingProgress={isLoadingProgress || isFetchingProgress}
              loadingTeacher={isLoadingTeachers || isFetchingTeachers}
              hideStatus={!withCalendar}
              approved={subject.approved && !withCalendar}
            />
          </SubjectCardLink>
        ))}
      </Mesh>
    </>
  );
}
