import { Iframe, Skeleton } from 'dawere-uic';
import { useSubject } from '../hooks/useSubject';

export function Video() {
  const { isLoading, subject } = useSubject();

  if (!isLoading && !subject?.introductionVideo) {
    return null;
  }

  return (
    <>
      {isLoading ? (
        <div className='p-3 pb-0'>
          <Skeleton.Image height={260} />
        </div>
      ) : (
        <Iframe height={260} src={subject?.introductionVideo} />
      )}
    </>
  );
}
