import { Tasks } from 'components/Tasks';
import { i18n } from 'dawere-commons';
import { Modal, Spinner } from 'dawere-uic';

export function PayingModal({
  paying = false,
  onClose = () => {},
  asyncId,
  onFinished = () => {},
  onError = () => {}
}: any) {
  return (
    <Modal
      open={paying}
      backdrop='static'
      onClose={onClose}
      closable={false}
      keyboard={false}>
      <div>
        <div className='text-center'>
          <Spinner
            variant='pulse'
            size={4}
            color='primary'
            className='mb-3 mt-3'
          />
          <p className='fs-2 mb-0'>
            {i18n('v2.pages.buyCoupons.steps.2.creditCard.modalPaying.title')}
          </p>
          <p className='fs-1 text-muted'>
            {i18n(
              'v2.pages.buyCoupons.steps.2.creditCard.modalPaying.description'
            )}
          </p>
        </div>

        <Tasks asyncId={asyncId} onFinished={onFinished} onError={onError} />
      </div>
    </Modal>
  );
}
