import { API } from 'api';
import React, { useEffect, useState } from 'react';

export interface OrganizationChartProps {
  programs: any[];
  isLoadingPrograms: boolean;

  members: any[];
  isLoadingMembers: boolean;

  teachers: any[];
  isLoadingTeachers: boolean;
}

export const OrganizationChartContext =
  React.createContext<OrganizationChartProps>({
    programs: [],
    isLoadingPrograms: true,

    members: [],
    isLoadingMembers: true,

    teachers: [],
    isLoadingTeachers: true
  });

export function OrganizationChartProvider({ children, id }: any) {
  const [isLoadingPrograms, setIsLoadingPrograms] = useState<boolean>(true);
  const [programs, setPrograms] = useState<any[]>([]);

  const [isLoadingMembers, setIsLoadingMembers] = useState<boolean>(true);
  const [members, setMembers] = useState<any[]>([]);

  const [isLoadingTeachers, setIsLoadingTeachers] = useState<boolean>(true);
  const [teachers, setTeachers] = useState<any[]>([]);

  // Get programs list
  useEffect(() => {
    setIsLoadingPrograms(true);

    API.Programs.list()
      .then(({ data }: any) => {
        setPrograms(
          data.map((program: any) => ({
            value: program.id,
            label: program.name
          }))
        );
      })
      .finally(() => setIsLoadingPrograms(false));
  }, []);

  // Get teachers avatars
  useEffect(() => {
    if (id) {
      setIsLoadingTeachers(true);

      API.Organizations.Teachers.list(id, { simple: true })
        .then((data: any) => {
          setTeachers(data);
        })
        .finally(() => setIsLoadingTeachers(false));
    }
  }, [id]);

  // Get members avatars
  useEffect(() => {
    if (id) {
      setIsLoadingMembers(true);

      API.Organizations.Members.list(id, { simple: true })
        .then((data: any) => {
          setMembers(data);
        })
        .finally(() => setIsLoadingMembers(false));
    }
  }, [id]);

  return (
    <OrganizationChartContext.Provider
      value={{
        programs,
        isLoadingPrograms,
        members,
        isLoadingMembers,
        isLoadingTeachers,
        teachers
      }}>
      {children}
    </OrganizationChartContext.Provider>
  );
}
