import { i18n } from 'dawere-commons';
import {
  Avatar,
  Button,
  Empty,
  Flex,
  Popover,
  Search,
  Skeleton,
  useMatchMedia,
  Utils
} from 'dawere-uic';
import { useOrganization } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import styled from 'styled-components';
import { useOrganizationChart } from './hooks/useOrganizationChart';

const GridStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 0.15em;
`;

const MAX_VISIBLE = 5;

export function MembersAvatars() {
  const { organization } = useOrganization();
  const { isDesktop } = useMatchMedia();
  const { members, isLoadingMembers } = useOrganizationChart();
  const [filteredMembers, setFilteredMembers] = useState<any[]>(members);

  const restTotal = members.length - MAX_VISIBLE;
  const firstAvatars = members.slice(0, MAX_VISIBLE);

  const handleSearch = useCallback(
    (data: any) => {
      const { search } = data;
      let sliced = members;

      if (search) {
        const filtered = sliced.filter(({ user }: any) =>
          Utils.getFullname(user.name, user.lastName)
            .toLowerCase()
            .includes(search.toLowerCase())
        );

        setFilteredMembers(filtered);
      } else {
        setFilteredMembers(sliced);
      }
    },
    [members]
  );

  useEffect(() => {
    setFilteredMembers(members);
  }, [members]);

  return (
    <Flex direction='column' className='mb-3 mb-lg-0'>
      <small className='text-muted'>
        {i18n('v2.pages.organizationDashboard.tabs.5.avatars.members')}
      </small>

      {isLoadingMembers ? (
        <Flex>
          {Array(5)
            .fill(0)
            .map((_, index) => (
              <div key={index} className='mr-1'>
                <Skeleton
                  height={isDesktop ? 33 : 47}
                  width={isDesktop ? 33 : 47}
                  circle
                />
              </div>
            ))}
        </Flex>
      ) : (
        <Flex align='center' justify={isDesktop ? 'start' : 'between'}>
          <Avatar.Group className='ml-2 mr-1'>
            {firstAvatars.map((member) => (
              <Avatar
                key={member.id}
                size={isDesktop ? 34 : 48}
                src={member.user?.picturePath}
                icon={Utils.getInitialLetters(
                  member.user?.name,
                  member.user?.lastName
                )}
                tooltip={Utils.getFullname(
                  member.user?.name,
                  member.user?.lastName
                )}
              />
            ))}

            {members.length > MAX_VISIBLE ? (
              <Popover
                placement='right'
                content={
                  <>
                    <Search
                      name='search'
                      onSubmit={handleSearch}
                      allowEmptySearch
                    />
                    {filteredMembers.length === 0 ? (
                      <Empty size='xsmall' />
                    ) : (
                      <GridStyled>
                        {filteredMembers.map((member) => (
                          <Avatar
                            size={40}
                            inPopover
                            key={member.id}
                            src={member.user?.picturePath}
                            icon={Utils.getInitialLetters(
                              member.user?.name,
                              member.user?.lastName
                            )}
                            tooltip={Utils.getFullname(
                              member.user?.name,
                              member.user?.lastName
                            )}
                          />
                        ))}
                      </GridStyled>
                    )}
                  </>
                }>
                <Button circle size={isDesktop ? 34 : 48}>
                  {restTotal < 100 ? `+${restTotal}` : '...'}
                </Button>
              </Popover>
            ) : null}
          </Avatar.Group>

          <Button
            variant='link'
            style={{ height: '34px' }}
            as={Link}
            to={PATHS.get('MEMBERS_LIST', {
              organizationId: organization?.id
            })}>
            {i18n('v2.pages.organizationDashboard.tabs.5.avatars.more')}
          </Button>
        </Flex>
      )}
    </Flex>
  );
}
