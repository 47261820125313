import { useQuery } from '@tanstack/react-query';
import { API } from 'api';
import { i18n, Session } from 'dawere-commons';
import { useRouter, Utils } from 'dawere-uic';
import { useProgramStatus } from 'hooks';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PATHS } from 'router';
import { trackEvent } from 'utils';
import {
  createQueryKey,
  DEFAULT_STALE_TIME,
  QPARAMS,
  QUERY_KEYS
} from 'values';

export interface EnrollProps {
  // Basic info
  currency: any;
  gateways: any;
  calendars: any[];
  periods: any[];
  documents: any[];
  summary: any;
  setSummary: React.Dispatch<React.SetStateAction<any>>;
  billingInfo: any;
  setBillingInfo: React.Dispatch<React.SetStateAction<any>>;
  isEarlyPayment: boolean;
  setIsEarlyPayment: React.Dispatch<React.SetStateAction<boolean>>;

  // User selection
  step: string;
  calendar: any;
  setCalendar: React.Dispatch<React.SetStateAction<any>>;
  period: any;
  setPeriod: React.Dispatch<React.SetStateAction<any>>;
  program: any;

  // Feedback
  isLoadingProgram: boolean;
  isLoadingGateways: boolean;
  isLoadingCalendars: boolean;
  isLoadingPeriods: boolean;
  isLoadingDocuments: boolean;
  isLoadingSummary: boolean;

  // Methods
  isBillingInfoCompleted: () => boolean;
  onStepChange: (step: string, prev?: string) => void;
  getTaxData: (data?: any) => any;
  track: (event: string, extraFields?: any[], data?: any) => void;

  isPaid: boolean;
  setIsPaid: React.Dispatch<React.SetStateAction<boolean>>;
  isReEnrolment: boolean | null;
  isLoadingProgramStatus: boolean;

  walletBalance: number | null;
  onInstallmentsChange: (installments?: any[]) => void;

  redirectTo?: string;
}

export const EnrollContext = React.createContext<EnrollProps>({
  currency: null,
  gateways: null,
  calendars: [],
  periods: [],
  documents: [],
  summary: null,
  setSummary: () => {},
  billingInfo: {},
  setBillingInfo: () => {},
  isEarlyPayment: false,
  setIsEarlyPayment: () => {},

  step: 'periods',
  calendar: null,
  setCalendar: () => {},
  period: null,
  setPeriod: () => {},
  program: null,

  isLoadingProgram: true,
  isLoadingGateways: true,
  isLoadingCalendars: true,
  isLoadingPeriods: false,
  isLoadingDocuments: true,
  isLoadingSummary: false,

  isBillingInfoCompleted: () => false,
  onStepChange: () => {},
  getTaxData: (data?: any) => {},
  track: () => {},

  isPaid: false,
  setIsPaid: () => {},
  isReEnrolment: false,
  isLoadingProgramStatus: true,

  walletBalance: null,
  onInstallmentsChange: (installments: any[] = []) => {},

  redirectTo: ''
});

export function EnrollProvider({
  children,
  programId,
  currencyId,
  studentId,
  redirectTo = PATHS.get('STUDENTS_DASHBOARD', {}, { [QPARAMS.Tab]: 3 }),
  earlyPayment = false
}: any) {
  const { push } = useRouter();
  const ref = useRef<any>();

  const { status: programStatus, isLoading: isLoadingProgramStatus } =
    useProgramStatus(programId, studentId);
  const isReEnrolment = programStatus
    ? programStatus === 'CONCLUDED'
    : programStatus === undefined
    ? false
    : null;

  // Lists
  const [documents, setDocuments] = useState<any>([]);
  const [program, setProgram] = useState<any>(null);

  // Basic info
  const [step, setStep] = useState('periods');
  const [isEarlyPayment, setIsEarlyPayment] = useState(earlyPayment);
  const [currency, setCurrency] = useState<any>(Session.getDefaultCurrency());
  const [period, setPeriod] = useState(null);
  const [calendar, setCalendar] = useState(null);
  const [gateways, setGateways] = useState<any>(null);
  const [billingInfo, setBillingInfo] = useState<any>({});
  const [summary, setSummary] = useState<any>(null);

  const [isPaid, setIsPaid] = useState(false);

  // Prev values
  const [prevCalendar, setPrevCalendar] = useState(null);
  const [prevPeriod, setPrevPeriod] = useState(null);

  // Loadings
  const [isLoadingDocuments, setIsLoadingDocuments] = useState<boolean>(false);
  const [isLoadingProgram, setIsLoadingProgram] = useState<boolean>(true);
  const [isLoadingGateways, setIsLoadingGateways] = useState(true);
  const [isLoadingSummary, setIsLoadingSummary] = useState<boolean>(false);

  // Wallet
  const [walletBalance, setWalletBalance] = useState<number | null>(null);

  // Get periods
  const { data: periods, isFetching: isLoadingPeriods } = useQuery({
    staleTime: DEFAULT_STALE_TIME,
    enabled: !!program?.id && program?.byCalendar && isReEnrolment === false,
    queryKey: createQueryKey(QUERY_KEYS.PROGRAM_PERIODS, program?.id),
    queryFn: () => API.Programs.getPeriods(program?.id)
  });

  // Get calendars
  const { data: calendars, isFetching: isLoadingCalendars } = useQuery({
    staleTime: DEFAULT_STALE_TIME,
    enabled: !!program?.id && program?.byCalendar,
    queryKey: createQueryKey(QUERY_KEYS.PROGRAM_CALENDARS, program?.id),
    queryFn: () => API.Programs.getCalendars(program?.id)
  });

  const track = useCallback(
    (eventName: string, extraFields: any = [], data: any = {}) => {
      if (!isReEnrolment) {
        const foundCalendar = calendars.find((c: any) => calendar === c.id);
        const foundPeriod =
          period === 'Empty'
            ? { name: i18n('v2.pages.enrolment.steps.2.lastOption') }
            : periods?.find((p: any) => period === p.id);

        const periodAttr = extraFields.includes('period')
          ? { period: foundPeriod?.name }
          : {};

        const paymentTypeAttr = extraFields.includes('payment_type')
          ? { payment_type: isEarlyPayment ? 'completo' : 'fraccionado' }
          : {};

        trackEvent(eventName, {
          program_name: program?.name,
          calendar: i18n('v2.pages.enrolment.steps.1.format', {
            start: Utils.formatDate(foundCalendar?.dateInit, 'dd/mmm/yyyy'),
            end: Utils.formatDate(foundCalendar?.dateEnd, 'dd/mmm/yyyy')
          }),
          ...periodAttr,
          ...paymentTypeAttr,
          ...data
        });
      }
    },
    [
      calendar,
      calendars,
      isEarlyPayment,
      isReEnrolment,
      period,
      periods,
      program?.name
    ]
  );

  const createFlexEnrollment = useCallback(
    (programId: string) => {
      setIsLoadingSummary(true);

      API.Enrollment.createFlexEnroll(programId, studentId, isEarlyPayment)
        .then(({ data }: any) => {
          // Set summary info
          setSummary(data);

          // Set billing info
          if (Utils.isEmpty(billingInfo)) {
            setBillingInfo(createBillingInfo(data));
          }
        })
        .catch(() => {
          setIsPaid(true);
          push(PATHS.STUDENTS_DASHBOARD);
        })
        .finally(() => setIsLoadingSummary(false));
    },
    [billingInfo, isEarlyPayment, push, studentId]
  );

  const onStepChange = useCallback(
    (step: string) => {
      // Set new step
      setStep(step);

      if (step === 'periods') {
        track('funnel_step2_level', ['period']);

        setCalendar(null);
        setSummary(null);
      }

      // Create Enrolment:
      // Create the enrolment when the user came from the first step (1)
      // and has a different calendar selected
      if (step === 'checkout') {
        setIsLoadingSummary(true);

        track('funnel_step1_calendar');

        API.Enrollment.createEnroll(
          calendar,
          period === 'Empty' ? periods && periods[0]?.id : period,
          studentId,
          isEarlyPayment
        )
          .then(({ data }: any) => {
            setPrevCalendar(calendar);

            // Set summary info
            setSummary(data);

            // Set billing info
            if (Utils.isEmpty(billingInfo)) {
              setBillingInfo(createBillingInfo(data));
            }
          })
          .catch(() => {
            setIsPaid(true);
            push(PATHS.STUDENTS_DASHBOARD);
          })
          .finally(() => setIsLoadingSummary(false));
      }
    },
    [
      billingInfo,
      calendar,
      isEarlyPayment,
      period,
      periods,
      push,
      studentId,
      track
    ]
  );

  const isBillingInfoCompleted = useCallback(() => {
    return true;
  }, []);

  const getTaxData = useCallback(
    (data: any = null) => {
      if (data) {
        return {
          address: data.billingAddress,
          name: data.name,
          postalCode: data.postalCode,
          tin: data.tin,
          township: {
            id: data.township?.value
          }
        };
      }

      return {
        address: billingInfo.billingAddress,
        name: billingInfo.name,
        postalCode: billingInfo.postalCode,
        tin: billingInfo.tin,
        township: {
          id: billingInfo.township?.value
        }
      };
    },
    [
      billingInfo.billingAddress,
      billingInfo.name,
      billingInfo.postalCode,
      billingInfo.tin,
      billingInfo.township?.value
    ]
  );

  const onInstallmentsChange = useCallback((installments: any[] = []) => {
    setSummary((state: any) => {
      state.installments = installments;
      return { ...state };
    });
  }, []);

  // Set default currency
  useEffect(() => {
    // Only update when the currency is different
    const defCurrencyId = Session.getDefaultCurrency()?.id;

    if (currencyId && defCurrencyId !== currencyId) {
      API.Account.setDefaultCurrency(currencyId).then(({ data }: any) => {
        // Actualizo la sesion para que use la moneda actual
        Session.setDefaultCurrency(data);
        // Set default currency
        setCurrency(data);
      });
    }
  }, [currencyId]);

  // Get program
  useEffect(() => {
    if (programId && !program) {
      setIsLoadingProgram(true);

      API.Programs.get(programId)
        .then((data) => {
          setProgram(data);

          if (data?.id && !data?.byCalendar) {
            setStep('checkout');
            createFlexEnrollment(data?.id);
          }
        })
        .catch(() => {
          setIsPaid(true);
          push(PATHS.STUDENTS_DASHBOARD);
        })
        .finally(() => setIsLoadingProgram(false));
    }
  }, [createFlexEnrollment, program, programId, push]);

  // Get mandatory documents
  useEffect(() => {
    if (program?.id && program?.byCalendar && isReEnrolment === false) {
      setIsLoadingDocuments(true);

      API.Programs.getDocuments(program?.id)
        .then(({ data }: any) => {
          setDocuments(data.filter((doc: any) => doc.mandatory));
        })
        .finally(() => setIsLoadingDocuments(false));
    }
  }, [isReEnrolment, program]);

  // Get gateways
  useEffect(() => {
    if (programId && currencyId) {
      setIsLoadingGateways(true);

      API.Cart.getGateways(currencyId)
        .then((response) => {
          setGateways(response);
        })
        .finally(() => setIsLoadingGateways(false));
    }
  }, [currencyId, programId]);

  // Wallet
  useEffect(() => {
    if (summary?.id) {
      API.Payments.getWalletBalance(summary?.id).then((response) => {
        setWalletBalance(response);
      });
    }
  }, [summary?.id]);

  // Updating reEnrolment flag
  // useEffect(() => {
  //   if (programStatus) {
  //     const reEnroll = programStatus === 'CONCLUDED';
  //     setIsReEnrolment(reEnroll);

  //     if (reEnroll) {
  //       setStep('calendars');
  //     }
  //   }
  // }, [programStatus]);

  // Set early payment bool to true if calendar changes
  useEffect(() => {
    setIsEarlyPayment(earlyPayment);
  }, [calendar, earlyPayment]);

  // Update ref with date to run unmounted
  ref.current = {
    isPaid,
    orderId: summary?.id
  };

  // Remove order if not completed
  // useEffect(() => {
  //   return () => {
  //     const { isPaid, orderId } = ref.current;

  //     if (!isPaid && orderId) {
  //       API.Payments.deleteOrder(orderId).then(() => {
  //         console.log('Orden borrada:', orderId);
  //       });
  //     }
  //   };
  // }, []);

  return (
    <EnrollContext.Provider
      value={{
        documents,
        isLoadingDocuments,
        periods,
        period,
        setPeriod,
        isLoadingPeriods,
        calendars,
        calendar,
        setCalendar,
        isLoadingCalendars,
        step,
        billingInfo,
        setBillingInfo,
        isBillingInfoCompleted,
        currency,
        gateways,
        summary,
        setSummary,
        isLoadingGateways,
        isPaid,
        setIsPaid,
        onStepChange,
        isEarlyPayment,
        setIsEarlyPayment,
        isLoadingSummary,
        getTaxData,
        isReEnrolment,
        isLoadingProgramStatus,
        track,
        walletBalance,
        onInstallmentsChange,
        redirectTo,
        program,
        isLoadingProgram
      }}>
      {children}
    </EnrollContext.Provider>
  );
}

function createBillingInfo(data: any) {
  return {
    tin: data?.tin,
    name: data?.billTo,
    postalCode: data?.billingPostalCode,
    billingAddress: data?.billingAddress,
    country: data?.billingCountry
      ? {
          ...data?.billingCountry,
          value: data?.billingCountry.id,
          label: data?.billingCountry?.name
        }
      : null,
    state: data?.billingState
      ? {
          ...data?.billingState,
          value: data?.billingState.id,
          label: data?.billingState?.name
        }
      : null,
    township: data?.billingTownship
      ? {
          ...data?.billingTownship,
          value: data?.billingTownship.id,
          label: data?.billingTownship?.name
        }
      : null
  };
}
