import { i18n } from 'dawere-commons';
import { Col, Flex, Header, Skeleton } from 'dawere-uic';
import { useOrganization } from 'hooks';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';

export function Heading() {
  const { organization, isLoading } = useOrganization();

  if (isLoading) {
    return (
      <Col xs={12} className='mb-5'>
        <Skeleton.Breadcrumbs count={2} />
        <Flex>
          <Skeleton.Avatar size={90} className='mr-3' />
          <Flex full direction='column'>
            <Skeleton.Title className='mb-2' />
            <Skeleton width={150} />
          </Flex>
        </Flex>
      </Col>
    );
  }

  return (
    <Col xs={12}>
      <Header
        as={Link}
        loading={isLoading}
        src={organization?.logo}
        title={i18n('v2.pages.organizationDashboard.welcome', {
          name: organization?.name
        })}
        routes={[
          {
            breadcrumb: i18n('v2.pages.organizationDashboard.breadcrumbs.1'),
            to: PATHS.MY_ORGANIZATIONS
          },
          {
            breadcrumb: i18n('v2.pages.organizationDashboard.breadcrumbs.2')
          }
        ]}
        subtext={[
          i18n(`ui.commons.${organization?.role}`),
          <Link
            to={PATHS.get('EDIT_ORGANIZATIONS', {
              organizationId: organization?.id
            })}>
            {i18n('v2.pages.myOrganizations.buttons.configuration')}
          </Link>
        ]}
      />
    </Col>
  );
}
