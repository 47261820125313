import { API } from 'api';
import { i18n, Session } from 'dawere-commons';
import { Button, Checkbox, Col, Divider, Form, Row, toast } from 'dawere-uic';
import { useSession } from 'hooks';
import { useCallback, useState } from 'react';

export function ProfileSection() {
  const { getMe } = useSession();
  const sessionProfiles = Session.getProfiles();

  const [profiles, setProfiles] = useState<any>(sessionProfiles);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(
    (data: any) => {
      setIsLoading(true);

      API.Account.updateMe(data)
        .then(() => {
          toast.success(i18n('v2.pages.settings.tabs.1.profile.toast'));
          return getMe();
        })
        .finally(() => setIsLoading(false));
    },
    [getMe]
  );

  return (
    <>
      <Divider section>
        {i18n('v2.pages.settings.tabs.1.profile.title')}
      </Divider>

      <Row>
        <Col xs={12} md={6}>
          <p>{i18n('v2.pages.settings.tabs.1.profile.sentence1')}</p>

          <Form onSubmit={handleSubmit}>
            <Checkbox
              name='student'
              label={i18n('v2.pages.settings.tabs.1.profile.student')}
              className='mb-3'
              value={profiles?.student}
              onChange={() => {
                setProfiles({
                  ...profiles,
                  student: !profiles?.student
                });
              }}
              disabled={sessionProfiles?.student}
              loading={isLoading}
            />
            <Checkbox
              name='tutor'
              label={i18n('v2.pages.settings.tabs.1.profile.teacher')}
              className='mb-3'
              value={profiles?.tutor}
              onChange={() => {
                setProfiles({
                  ...profiles,
                  tutor: !profiles?.tutor
                });
              }}
              disabled={sessionProfiles?.tutor}
              loading={isLoading}
            />
            <Checkbox
              name='organization'
              label={i18n('v2.pages.settings.tabs.1.profile.organization')}
              className='mb-5'
              value={profiles?.organization}
              onChange={() => {
                setProfiles({
                  ...profiles,
                  organization: !profiles?.organization
                });
              }}
              disabled={sessionProfiles?.organization}
              loading={isLoading}
            />

            <Button type='submit' loading={isLoading}>
              {i18n('v2.pages.settings.tabs.1.profile.submit')}
            </Button>
          </Form>
        </Col>
      </Row>
    </>
  );
}
