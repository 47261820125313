import { API } from 'api';
import { i18n } from 'dawere-commons';
import {
  Badge,
  Button,
  Icons,
  Modal,
  Search,
  Spinner,
  toast
} from 'dawere-uic';
import { useCallback, useState } from 'react';

export function ModalAddTeacher({
  organizationId,
  open,
  onClose,
  onSuccess = () => {}
}: any) {
  const [email, setEmail] = useState<string>('');
  const [list, setList] = useState<string[]>([]);
  const [checking, setChecking] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCheckEmail = useCallback(
    (data: any) => {
      if (list.includes(data.email)) {
        return;
      }

      setEmail(data.email);
      setChecking(true);

      API.Organizations.validateEmailInvitation(organizationId, data.email)
        .then((response: any) => {
          if (response.data === true) {
            setList((state) => [...state, data.email]);
          }
        })
        .finally(() => {
          setEmail('');
          setChecking(false);
        });
    },
    [list, organizationId]
  );

  const handleRemoveEmail = useCallback(
    (id: string) => {
      const newList = list.filter((item: string) => item !== id);
      setList(newList);
    },
    [list]
  );

  const handleClose = useCallback(() => {
    setList([]);
    onClose(false);
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    setIsLoading(true);
    API.Organizations.Teachers.invite(organizationId, { emails: list })
      .then((response) => {
        if (list.length === 1) {
          toast.success(
            i18n('v2.pages.teachers.modals.add.success', { email: list[0] })
          );
        } else {
          toast.success(
            i18n('v2.pages.teachers.modals.add.successMultiple', {
              qty: list.length
            })
          );
        }
        setList([]);
        onClose(false);
        onSuccess();
      })
      .finally(() => setIsLoading(false));
  }, [list, onClose, onSuccess, organizationId]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closable={isLoading ? false : true}
      keyboard={isLoading ? false : true}
      type='email'
      title={i18n('v2.pages.teachers.modals.add.title')}
      backdrop='static'
      footer={
        <Button
          block
          onClick={handleSubmit}
          loading={isLoading}
          disabled={checking || list.length === 0}>
          {i18n('v2.pages.teachers.modals.add.button')}
        </Button>
      }>
      <p>{i18n('v2.pages.teachers.modals.add.text')}</p>

      <Search
        name='email'
        onSubmit={handleCheckEmail}
        placeholder={
          checking
            ? i18n('v2.pages.teachers.modals.add.placeholderChecking', {
                email
              })
            : i18n('v2.pages.teachers.modals.add.placeholder')
        }
        icon={<Icons.Plus />}
        disabled={checking || isLoading}
        clearAfterSubmit
        validators={{
          email: true
        }}
      />

      {list.map((email: string) => (
        <Badge
          key={email}
          id={email}
          closable
          onClose={handleRemoveEmail}
          disabled={checking || isLoading}>
          {email}
        </Badge>
      ))}
      {email ? (
        <Badge id={email}>
          <Spinner
            variant='pulse'
            size={0.75}
            className='d-inline-block mr-2'
          />
          {email}
        </Badge>
      ) : null}
    </Modal>
  );
}
