import { i18n } from 'dawere-commons';
import { Col, Divider, Empty, Skeleton } from 'dawere-uic';
import { useSession } from 'hooks';
import { useCallback } from 'react';
import { StudentCard } from '../components/StudentCard';
import { usePermissions } from '../hooks/usePermissions';

export function MyStudents({ ...rest }: any) {
  const { getMe } = useSession();
  const { myStudents, isLoadingMyStudents, loadMyStudents } = usePermissions();

  const handleChange = useCallback(() => {
    getMe().then(() => loadMyStudents());
  }, [getMe, loadMyStudents]);

  return (
    <Col xs={12} className='mb-4'>
      <Divider section>{i18n('v2.pages.permissions.myStudents.title')}</Divider>

      <p className='mb-4'>
        {i18n('v2.pages.permissions.myStudents.description')}
      </p>

      {isLoadingMyStudents ? (
        <>
          {Array(3)
            .fill(0)
            .map((_, index) => (
              <Skeleton height={80} key={index} className='mb-2' />
            ))}
        </>
      ) : (
        <>
          {myStudents.length > 0 ? (
            <>
              {myStudents.map((item) => (
                <StudentCard
                  key={item.id}
                  item={item}
                  onChange={handleChange}
                />
              ))}
            </>
          ) : (
            <div className='bg-cultured rounded-3 '>
              <Empty size='xsmall'>
                {i18n('v2.pages.permissions.myStudents.nothing')}
              </Empty>
            </div>
          )}
        </>
      )}
    </Col>
  );
}
