import { i18n } from 'dawere-commons';
import { Block, Callout, Col, Skeleton } from 'dawere-uic';
import { ASSETS } from 'values';
import { useProgram } from '../hooks/useProgram';

export function RequirementsSection({ isCourse = false }: any) {
  const { isLoading, program } = useProgram();

  if (!program?.requirementsDescription) {
    return null;
  }

  return (
    <Block id='requirements' align='center'>
      <Col md={6}>
        <h2 className='mb-4'>
          {i18n('v2.pages.program.section3.title' + (isCourse ? '2' : '1'))}
        </h2>

        {isLoading ? (
          <>
            <Skeleton.Paragraph lines={2} />
            <div className='pl-3'>
              <Skeleton.List />
            </div>
            <Skeleton.Paragraph lines={4} />
          </>
        ) : (
          <>
            <p
              dangerouslySetInnerHTML={{
                __html: program?.requirementsDescription
              }}
            />

            {program?.requirementsDescription2 ? (
              <Callout variant='powderTint' className='mb-6 mb-md-0'>
                <p
                  dangerouslySetInnerHTML={{
                    __html: program?.requirementsDescription2
                  }}
                />
              </Callout>
            ) : null}
          </>
        )}
      </Col>
      <Col md={6} className='text-center'>
        <img
          src={ASSETS.ILLUSTRATIONS.MATH_VIDEO_CLASS}
          alt={i18n('v2.pages.program.section3.title')}
          className='img-fluid'
        />
      </Col>
    </Block>
  );
}
