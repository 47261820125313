import { API } from 'api';
import React, { useEffect, useState } from 'react';

export interface SubjectProps {
  subject: any;
  isLoading: boolean;
  programId: string;
}

export const SubjectContext = React.createContext<SubjectProps>({
  subject: null,
  isLoading: true,
  programId: ''
});

export function SubjectProvider({ children, programId, id }: any) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [subject, setSubject] = useState<any>(null);

  useEffect(() => {
    if (programId && id) {
      setIsLoading(true);

      API.Subjects.get(programId, id)
        .then(({ data }: any) => {
          setSubject(data);
        })
        .finally(() => setIsLoading(false));
    }
  }, [programId, id]);

  return (
    <SubjectContext.Provider
      value={{
        subject,
        isLoading,
        programId
      }}>
      {children}
    </SubjectContext.Provider>
  );
}
