import { Document, Font, Page, PDFViewer } from '@react-pdf/renderer';
import { i18n } from 'dawere-commons';
import { Spinner } from 'dawere-uic';
import { PDF_PAGE_STYLES } from 'values';
import { useOfficialTranscript } from '../hooks/useOfficialTranscript';
import { BannerSection } from './BannerSection';
import { DisclaimerSection } from './DisclaimerSection';
import { FooterSection } from './FooterSection';
import { HeaderSection } from './HeaderSection';
import { ObservationsSection } from './ObservationsSection';
import { PeriodSection } from './PeriodSection';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: 'https://fonts.cdnfonts.com/s/12165/Roboto-Regular.woff' },
    {
      src: 'https://fonts.cdnfonts.com/s/12165/Roboto-Bold.woff',
      fontWeight: 'bold'
    }
  ]
});

export function PDFWrapper() {
  const { transcripts, student, schools, isLoading } = useOfficialTranscript();

  if (isLoading) {
    return (
      <div className='text-center'>
        <Spinner variant='pulse' color='primary' size={5} />
        <span className='d-block fs-2 text-muted mt-2'>
          {i18n('ui.commons.loading')}
        </span>
      </div>
    );
  }

  return (
    <PDFViewer width='100%' height='100%'>
      <Document title={'Official Transcript'}>
        <Page size='A4' style={PDF_PAGE_STYLES}>
          <BannerSection />
          <HeaderSection data={student} />
          {/* Periods */}
          {transcripts.map((period, index) => (
            <PeriodSection key={index} data={period} />
          ))}
          <ObservationsSection data={schools} />
          {/* Disclaimer */}
          <DisclaimerSection />
          {/* Sign */}
          {/* <SignSection /> */}
          {/* Notary */}
          {/* <NotarySection /> */}
          {/* Footer */}
          <FooterSection data={student} />
        </Page>
      </Document>
    </PDFViewer>
  );
}
