import { Gateways } from 'components';
import { i18n } from 'dawere-commons';
import { Breadcrumbs, Col, useRouter } from 'dawere-uic';
import { useCallback, useEffect } from 'react';
import { Link, Prompt } from 'react-router-dom';
import { QPARAMS } from 'values';
import { usePayment } from '../hooks/usePayment';
import { Checkout } from './Checkout';

const initBeforeUnLoad = (showExitPrompt: boolean) => {
  window.onbeforeunload = (event) => {
    // Show prompt based on state
    if (showExitPrompt) {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = '';
      }
      return '';
    }
  };
};

export function MainWrapper() {
  const { query, history } = useRouter();
  const { gateways, isLoading, data, isPaid, setIsPaid, walletBalance } =
    usePayment();

  const handleClick = useCallback(
    (e: any) => {
      e.preventDefault();
      history?.goBack();
    },
    [history]
  );

  // Initialize the beforeunload event listener after the resources are loaded
  window.onload = function () {
    initBeforeUnLoad(true);
  };

  // Re-Initialize the onbeforeunload event listener
  useEffect(() => {
    initBeforeUnLoad(true);
    return () => initBeforeUnLoad(false);
  }, []);

  return (
    <Col xs={12} md={7}>
      <Breadcrumbs
        as={Link}
        withBackArrow
        onBackClick={handleClick}
        routes={[{ breadcrumb: i18n('ui.commons.exit'), isBack: true }]}
        className='mb-4'
      />

      <Checkout />

      {/* Botones de pago */}
      <Gateways
        options={gateways}
        loading={isLoading}
        total={data?.amount}
        installments={data?.installments}
        onSuccess={() => setIsPaid(true)}
        onError={() => setIsPaid(true)}
        redirectTo={query[QPARAMS.Redirect] || '/'}
        currency={data?.currency}
        totalDiscounts={data?.discountAmt}
        allowDirectPayment
        walletBalance={walletBalance}
      />

      <Prompt
        when={!isPaid}
        message={() => i18n('v2.pages.buyCoupons.leavePage')}
      />
    </Col>
  );
}
