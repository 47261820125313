import { API } from 'api';
import { OrderBox } from 'components';
import { useCallback, useEffect, useState } from 'react';
import { useBuyCoupons } from '../hooks/useBuyCoupons';

export function SummaryCard() {
  const {
    orderPayload,
    summary,
    setSummary,
    currency,
    organization,
    walletBalance
  } = useBuyCoupons();

  const [isLoadingDiscount, setIsLoadingDiscount] = useState(false);
  const [isRemovingDiscount, setIsRemovingDiscount] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleSubmitDiscount = useCallback(
    (coupon: any) => {
      if (!coupon) {
        return;
      }

      setIsLoadingDiscount(true);

      API.Cart.validateDiscountCoupon(coupon)
        .then(() => {
          return API.Cart.updateOrder(organization?.id, summary?.id, {
            discountCodes: [
              coupon,
              ...summary?.discounts.map((d: any) => d.coupon)
            ],
            taxData: orderPayload.taxData
          }).then((response: any) => {
            setSummary(response);
          });
        })
        .finally(() => setIsLoadingDiscount(false));
    },
    [
      summary?.discounts,
      summary?.id,
      organization?.id,
      orderPayload.taxData,
      setSummary
    ]
  );

  const handleRemoveDiscount = useCallback(
    (discount: any) => {
      setIsRemovingDiscount(true);

      const discounts = summary?.discounts
        .filter((d: any) => d.coupon !== discount.coupon)
        .map((d: any) => d.coupon);

      return API.Cart.updateOrder(organization?.id, summary?.id, {
        discountCodes: discounts,
        taxData: orderPayload.taxData
      })
        .then((response: any) => {
          setSummary(response);
        })
        .finally(() => setIsRemovingDiscount(false));
    },
    [
      orderPayload.taxData,
      organization?.id,
      setSummary,
      summary?.discounts,
      summary?.id
    ]
  );

  // Primera vez que veo la tarjeta:
  // Creo la orden de compra
  useEffect(() => {
    setIsLoading(true);

    API.Cart.createOrder(organization?.id, {
      services: orderPayload.services,
      taxData: orderPayload.taxData
    })
      .then((response: any) => {
        setSummary(response);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <OrderBox
      className='mb-md-6'
      loading={isLoading}
      loadingDiscount={isLoadingDiscount}
      removingDiscount={isRemovingDiscount}
      currency={currency}
      lines={summary?.lines}
      discounts={summary?.discounts}
      taxBase={summary?.taxBase}
      taxes={summary?.taxes}
      total={summary?.total}
      admitCoupons
      onRemoveDiscount={handleRemoveDiscount}
      onSubmitDiscount={handleSubmitDiscount}
      earlyPaymentDiscount={summary?.earlyPaymentDiscountAmt}
      walletBalance={walletBalance}
    />
  );
}
