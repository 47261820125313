import { API } from 'api';
import { i18n } from 'dawere-commons';
import {
  AutoComplete,
  Block,
  Button,
  Callout,
  Col,
  Divider,
  Form,
  Header,
  Input,
  ProfilePic,
  Row,
  toast,
  useRouter
} from 'dawere-uic';
import { PageWrapper } from 'pages';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { getDashboardPath, getSEO } from 'utils';
import { getData } from './utils/getData';

/**
 * Path: /mis-organizaciones/configurar/:organizationId
 * Key: EDIT_ORGANIZATIONS
 * @returns {React.ReactElement}
 */
export default function EditOrganizationPage() {
  const { query } = useRouter();

  const [data, setData] = useState<any>({});
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [logoPath, setLogoPath] = useState<any>(null);

  const [picklists, setPicklists] = useState({
    isLoadingCountries: true,
    isLoadingStates: false,
    isLoadingTownships: false,
    countries: [],
    states: [],
    townships: []
  });

  // Getting data
  useEffect(() => {
    setIsLoading(true);
    API.Organizations.get(query?.organizationId)
      .then((response) => {
        setData(getData(response));
        setLogoPath(response.logo);
      })
      .finally(() => setIsLoading(false));
  }, [query?.organizationId]);

  // Countries
  useEffect(() => {
    setPicklists((s: any) => ({ ...s, isLoadingCountries: true }));
    API.Geo.getCountries()
      .then((response) => {
        setPicklists((s: any) => ({ ...s, countries: response }));
      })
      .finally(() =>
        setPicklists((s: any) => ({ ...s, isLoadingCountries: false }))
      );
  }, []);

  // States
  useEffect(() => {
    if (data?.country) {
      setPicklists((s: any) => ({ ...s, isLoadingStates: true }));
      API.Geo.getStates(data.country.value)
        .then((response) => {
          setPicklists((s: any) => ({ ...s, states: response }));
        })
        .finally(() =>
          setPicklists((s: any) => ({ ...s, isLoadingStates: false }))
        );
    }
  }, [data?.country]);

  // Townships
  useEffect(() => {
    if (data?.state) {
      setPicklists((s: any) => ({ ...s, isLoadingTownships: true }));
      API.Geo.getTownships(data?.state.value)
        .then((response) => {
          setPicklists((s: any) => ({ ...s, townships: response }));
        })
        .finally(() =>
          setPicklists((s: any) => ({ ...s, isLoadingTownships: false }))
        );
    }
  }, [data?.state]);

  const handleSubmit = useCallback(
    (body: any) => {
      const payload = {
        billingAddress: body.billingAddress,
        name: body.name,
        postalCode: body.postalCode,
        tin: body.tin,
        townshipId: body.township.value
      };

      setIsSaving(true);

      API.Organizations.update(query?.organizationId, payload)
        .then(({ data }: any) => {
          toast.success(
            i18n('v2.pages.editOrganization.tabs.1.toasts.successUpdated')
          );
        })
        .finally(() => setIsSaving(false));
    },
    [query?.organizationId]
  );

  const handleUpload = useCallback(
    (data: any) => {
      setIsUploading(true);

      API.Organizations.uploadPicture(query?.organizationId, {
        content: data.base64,
        fileExtension: data.extension
      })
        .then((response: any) => {
          setLogoPath(response.data?.logo);
        })
        .finally(() => setIsUploading(false));
    },
    [query?.organizationId]
  );

  return (
    <PageWrapper>
      {getSEO('v2.pages.editOrganization.seo', true)}

      <Block narrow>
        <Col xs={12}>
          <Row>
            <Col xs={12}>
              <Header
                withoutAvatar
                title={i18n('v2.pages.editOrganization.title')}
                as={Link}
                routes={[
                  {
                    breadcrumb: i18n('v2.pages.editOrganization.breadcrumbs.1'),
                    to: PATHS.MY_ORGANIZATIONS
                  },
                  {
                    breadcrumb: data?.name,
                    to: getDashboardPath(data)
                  },
                  {
                    breadcrumb: i18n('v2.pages.editOrganization.breadcrumbs.3')
                  }
                ]}
              />
            </Col>

            <Col xs={12} className='mb-6'>
              <Callout className='bg-platinium px-3 pt-3 pb-2 text-center'>
                <ProfilePic
                  src={logoPath}
                  size={160}
                  onChange={handleUpload}
                  loading={isUploading}
                />
              </Callout>
            </Col>

            <Col>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12}>
                    <Divider section>
                      {i18n('v2.pages.editOrganization.tabs.1.section1Title')}
                    </Divider>
                  </Col>

                  <Col xs={12} md={6}>
                    <Input
                      loading={isLoading || isSaving}
                      name='name'
                      label={i18n('v2.pages.editOrganization.tabs.1.form.name')}
                      value={data?.name}
                      validators={{
                        required: true,
                        maxLength: 150
                      }}
                    />
                  </Col>

                  <Col xs={12} md={6}>
                    <Input
                      loading={isLoading || isSaving}
                      name='tin'
                      label={i18n('v2.pages.editOrganization.tabs.1.form.tin')}
                      value={data?.tin}
                      validators={{
                        required: true,
                        maxLength: 100
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={12}>
                    <Divider section>
                      {i18n('v2.pages.editOrganization.tabs.1.section2Title')}
                    </Divider>
                  </Col>

                  <Col xs={12} md={6}>
                    <AutoComplete
                      loading={
                        picklists.isLoadingCountries || isLoading || isSaving
                      }
                      options={picklists.countries}
                      name='country'
                      label={i18n(
                        'v2.pages.editOrganization.tabs.1.form.country'
                      )}
                      value={data?.country}
                      onChange={(country: any) => {
                        setData({
                          ...data,
                          country,
                          state: null,
                          township: null
                        });
                      }}
                      validators={{
                        required: true
                      }}
                    />
                  </Col>

                  <Col xs={12} md={6}>
                    <AutoComplete
                      loading={
                        picklists.isLoadingStates || isLoading || isSaving
                      }
                      options={picklists.states}
                      name='state'
                      label={i18n(
                        'v2.pages.editOrganization.tabs.1.form.state'
                      )}
                      value={data?.state}
                      onChange={(state: any) => {
                        setData({
                          ...data,
                          state,
                          township: null
                        });
                      }}
                      disabled={!data?.country}
                      validators={{
                        required: true
                      }}
                    />
                  </Col>

                  <Col xs={12} md={6}>
                    <AutoComplete
                      loading={
                        picklists.isLoadingTownships || isLoading || isSaving
                      }
                      options={picklists.townships}
                      name='township'
                      label={i18n(
                        'v2.pages.editOrganization.tabs.1.form.township'
                      )}
                      value={data?.township}
                      onChange={(township: any) => {
                        setData({
                          ...data,
                          township
                        });
                      }}
                      disabled={!data?.state}
                      validators={{
                        required: true
                      }}
                    />
                  </Col>

                  <Col xs={12} md={6}>
                    <Input
                      loading={isLoading || isSaving}
                      name='billingAddress'
                      label={i18n(
                        'v2.pages.editOrganization.tabs.1.form.billingAddress'
                      )}
                      value={data?.billingAddress}
                      validators={{
                        required: true,
                        maxLength: 250
                      }}
                    />
                  </Col>

                  <Col xs={12} md={6}>
                    <Input
                      loading={isLoading || isSaving}
                      name='postalCode'
                      label={i18n(
                        'v2.pages.editOrganization.tabs.1.form.postalCode'
                      )}
                      value={data?.postalCode}
                      validators={{
                        required: true,
                        maxLength: 10
                      }}
                    />
                  </Col>

                  <Col xs={12}>
                    <Button
                      type='submit'
                      disabled={isLoading}
                      loading={isSaving}>
                      {i18n('v2.pages.editOrganization.tabs.1.buttons.submit')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Block>
    </PageWrapper>
  );
}
