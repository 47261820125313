import { i18n } from 'dawere-commons';
import { Block, Button, Col } from 'dawere-uic';
import { useProgramFreeTrial } from 'hooks';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { getEnvironment, getFullCountryInfo } from 'utils';
import { ASSETS } from 'values';

export function WelcomeSection() {
  const { iso3: country } = getFullCountryInfo();
  const programId = i18n('v2.pages.home.mainProgramId.' + getEnvironment());

  const trialInfo = useProgramFreeTrial(programId, false, country === 'USA');

  return (
    <Block narrow align='center' className='pb-0'>
      <Col md={6}>
        <h5 className='text-primary text-uppercase'>
          {i18n('v2.pages.home.section1.subtitle')}
        </h5>
        <h1 className='mb-6'>{i18n('v2.pages.home.section1.title')}</h1>

        {country === 'USA' ? (
          <Button
            as={Link}
            to={PATHS.get('CATALOG')}
            variant='outline'
            className='mb-2 mb-md-0 mr-md-2 | w-100 w-md-auto'>
            {i18n('v2.pages.home.section12.buttons.catalog')}
          </Button>
        ) : (
          <>
            <Button
              as={Link}
              to={PATHS.get('PROGRAM', { programId })}
              variant='outline'
              className='mb-2 mb-md-0 mr-md-2 | w-100 w-md-auto'>
              {i18n('v2.pages.home.section1.buttons.moreInfo')}
            </Button>

            {trialInfo?.available && (
              <Button
                as={Link}
                to={trialInfo?.route}
                variant='tertiary'
                className='mb-2 mb-md-0 mr-md-2 | w-100 w-md-auto'>
                {trialInfo?.text}
              </Button>
            )}
          </>
        )}
      </Col>
      <Col md={6} className='text-center'>
        {country === 'USA' ? (
          <img
            src={ASSETS.ILLUSTRATIONS.STUDENT_COGNIA}
            alt={i18n('v2.pages.home.section1.title')}
            className='img-fluid w-100 w-md-65'
          />
        ) : (
          <img
            src={ASSETS.ILLUSTRATIONS.STUDENTS_JUMPING}
            alt={i18n('v2.pages.home.section1.title')}
            style={{ position: 'relative', bottom: '-20px' }}
            className='img-fluid'
          />
        )}
      </Col>
    </Block>
  );
}
