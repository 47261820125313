import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { API } from 'api';
import { i18n } from 'dawere-commons';
import { Button, Modal, Utils } from 'dawere-uic';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { getPublicKey } from 'utils';
import ErrorModal from './ErrorModal';
import { PayingModal } from './PayingModal';

export function PayPalGateway({
  total = 0,
  currency = null,
  installments = [],
  onSuccess = () => {},
  redirectTo = '',
  isBillingInfoCompleted = () => true,
  walletAmount = null,
  onError = () => {}
}: any) {
  const [paying, setPaying] = useState(false);
  const [openRedirectModal, setOpenRedirectModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [asyncId, setAsyncId] = useState<string | null>(null);

  const handleCreateOrder = useCallback(
    (orderId: any) => {
      setPaying(true);

      // Create payment intent on the server
      const payload = {
        orderId,
        installmentIds: installments,
        walletAmount
      };

      API.Cart.createPayPalOrder(payload)
        .then(({ asyncProcessId }: any) => {
          if (Utils.isString(asyncProcessId)) {
            setAsyncId(asyncProcessId);
          } else {
            setPaying(false);
            onSuccess('paypal');
            setOpenRedirectModal(true);
          }
        })
        .catch(() => {
          setPaying(false);
          onError();
          setOpenErrorModal(true);
        });
    },
    [installments, onError, onSuccess, walletAmount]
  );

  return (
    <>
      <h4>{i18n('v2.pages.buyCoupons.steps.2.paypal.title')}</h4>
      <p>{i18n('v2.pages.buyCoupons.steps.2.paypal.description')}</p>

      <div
        style={{
          pointerEvents: paying || !isBillingInfoCompleted() ? 'none' : 'auto'
        }}>
        <PayPalScriptProvider
          options={{
            'client-id': getPublicKey('PAYPAL_API_KEY'),
            components: 'buttons',
            currency: currency?.isoCode
          }}>
          <PayPalButtons
            disabled={paying || !isBillingInfoCompleted()}
            forceReRender={[total, currency?.isoCode]}
            style={{ layout: 'horizontal' }}
            createOrder={(data, actions) => {
              return actions.order
                .create({
                  purchase_units: [
                    {
                      amount: {
                        currency_code: currency?.isoCode,
                        value: total
                      }
                    }
                  ]
                })
                .then((orderId) => {
                  return orderId;
                });
            }}
            onApprove={(data, actions: any) => {
              return actions.order.capture().then(() => {
                handleCreateOrder(data?.orderID);
              });
            }}
          />
        </PayPalScriptProvider>
      </div>

      <Modal
        type='success'
        open={openRedirectModal}
        backdrop='static'
        onClose={setOpenRedirectModal}
        closable={false}
        keyboard={false}
        title={i18n('v2.pages.buyCoupons.steps.2.paypal.modal.title')}
        footer={
          <Button block as={Link} to={redirectTo}>
            {i18n('v2.pages.buyCoupons.steps.2.paypal.modal.button')}
          </Button>
        }>
        <p
          dangerouslySetInnerHTML={{
            __html: i18n('v2.pages.buyCoupons.steps.2.paypal.modal.description')
          }}
        />
      </Modal>

      <ErrorModal
        open={openErrorModal}
        onClose={setOpenErrorModal}
        redirectTo={redirectTo}
      />

      <PayingModal
        paying={paying}
        onClose={setPaying}
        asyncId={asyncId}
        onFinished={() => {
          setPaying(false);
          onSuccess('paypal');
          setOpenRedirectModal(true);
        }}
        onError={() => {
          setPaying(false);
          onError();
          setOpenErrorModal(true);
        }}
      />
    </>
  );
}
