import { API } from 'api';
import { i18n, Session } from 'dawere-commons';
import { Steps } from 'intro.js-react';
import { useCallback } from 'react';

export function StudentDashboardTour({ program, enabled = false }: any) {
  const isVisible = !Session.isTourCompleted('STUDENT_DASHBOARD_TOUR');

  const turnOff = useCallback(() => {
    if (Session.getToken()) {
      API.Account.completeTour('STUDENT_DASHBOARD_TOUR').then(() =>
        Session.completeTour('STUDENT_DASHBOARD_TOUR')
      );
    }
  }, []);

  return (
    <Steps
      enabled={enabled && isVisible}
      steps={[
        {
          element: '.student-dashboard-tab-1',
          intro: i18n('v2.tours.studentDahboard.intro1', {
            programName: program?.name
          })
          // position: 'right',
          // tooltipClass: 'myTooltipClass',
          // highlightClass: 'myHighlightClass'
        },
        {
          element: '.student-dashboard-tab-2',
          intro: i18n('v2.tours.studentDahboard.intro2')
        },
        {
          element: '.help-navbar-button',
          intro: i18n('v2.tours.studentDahboard.intro3')
        },
        {
          element: '.notifications-navbar-button',
          intro: i18n('v2.tours.studentDahboard.intro6')
        },
        {
          element: `.list-subjects-${program?.id}`,
          intro: i18n('v2.tours.studentDahboard.intro4'),
          position: 'right'
        },
        {
          element: '.first-subject-card',
          intro: i18n('v2.tours.studentDahboard.intro5'),
          position: 'right'
        }
      ]}
      initialStep={0}
      onChange={(nextStepIndex, nextElement) => {
        // console.log(
        //   nextStepIndex
        //   // nextElement.dispatchEvent(
        //   //   new MouseEvent('click', {
        //   //     view: window,
        //   //     bubbles: true,
        //   //     cancelable: true,
        //   //     buttons: 1
        //   //   })
        //   // )
        // );
      }}
      onBeforeChange={(nextStepIndex, nextElement) => {}}
      onAfterChange={(nextStepIndex, nextElement) => {}}
      onComplete={turnOff}
      onExit={turnOff}
      options={{
        // showStepNumbers: true,
        // scrollPadding: -350,
        scrollToElement: true,
        showButtons: true,
        hidePrev: true,
        exitOnOverlayClick: false,
        exitOnEsc: false,
        nextLabel: i18n('ui.components.pagination.next'),
        prevLabel: i18n('ui.components.pagination.prev'),
        doneLabel: i18n('v2.tours.finish'),
        buttonClass: 'btn-tour',
        overlayOpacity: 0.8
      }}
    />
  );
}
