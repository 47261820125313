const esCO = {
  lang: `es-CO,es`,
  shortLang: 'es-CO',
  pages: {
    home: {
      seo: {
        title: `Dawere Colombia | Colegio Virtual de Calidad`,
        description: `Educación prémium, asequible y avalada por la Secretaría de Educación de Villavicencio en Colombia. Continúa tus estudios con la mejor educación virtual de América Latina.`,
        keywords: `colegio, escuela`,
        ogTitle: `Dawere Colombia | Colegio Virtual de Calidad`,
        ogDescription: `Educación prémium, asequible y avalada por la Secretaría de Educación de Villavicencio en Colombia. Continúa tus estudios con la mejor educación virtual de América Latina. `,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/seo_homepage.jpg`
      },
      mainProgramId: {
        dev: `bachillerato-virtual-colombia`,
        qa: `bachillerato-virtual-colombia`,
        prod: `bachillerato-virtual-colombia`
      },
      doubleDegreeProgramId: {
        dev: `bachillerato-virtual-estados-unidos`,
        qa: `bachillerato-virtual-estados-unidos`,
        prod: `bachillerato-virtual-estados-unidos`
      },
      info: {
        whatsappNumber: `+57 311-2540133`,
        phoneNumber: ``,
        emailInfo: `atencionalcliente@dawere.com.co`
      },
      section1: {
        subtitle: `Colegio: Dawere Colombia`
      },
      section2: {
        title: `El título de bachiller es avalado por la SED`,
        images: {
          1: {
            url: `https://storage.googleapis.com/dawere-web-assets/logotipos/Logo_Secretar%C3%ADa%20de%20educaci%C3%B3n%20distrital%20de%20Villavicencio-01.png`,
            alt: `SECRETARIA DE EDUCACION DISTRITAL`
          }
        },
        text: `
          <p>Obtén tu título de bachiller avalado por la Secretaría de Educación Distrital de Villavicencio.</p>

          <p>La plataforma de educación formal online Dawere y el Colegio Petroschool han formado una alianza educativa para traerte el mejor Bachillerato Virtual. </p>

          <p>El Colegio Petroschool está registrado ante la Secretaría de Educación Distrital de Villavicencio bajo la resolución 0665 y ante el Ministerio de Educación Nacional bajo el código DANE 350001007794.</p>
        `
      },
      section6: {
        steps: {
          1: {
            text: ` Si tienes 14 años de edad o más puedes inscribirte en Dawere sin importar tu ubicación geográfica. Solo debes llenar el formulario de inscripción, señalar los años aprobados hasta la fecha y realizar tu pago.`
          },
          2: {
            title: `Sube tus requisitos`,
            subtitle: `PASO 2`,
            text: `Debes subir de forma digital tu documento de identidad y certificado de EPS (Salud), entre otros recaudos.`
          },
          3: {
            text: `En nuestro colegio virtual aprendes con videoclases pregrabadas, guías PDF, evaluaciones de práctica, un foro-chat para aclarar dudas y más. Estudia a tu ritmo, en tu horario y desde cualquier lugar con acceso a internet. Dawere es fácil de navegar desde una computadora, tablet o teléfono inteligente. `
          },
          4: {
            title: `Gradúate`,
            subtitle: `PASO 4`,
            text: `Al aprobar todas las materias de rigor y asistir a tu ceremonia de graduación virtual, podrás retirar tu título en el Colegio Petroschool o solicitar que se te envíe hasta la puerta de tu casa.`
          }
        },
        buttons: {
          moreInfo: `Más información`
        }
      },
      section9: {
        categories: {
          1: {
            text: `Como padre podrás monitorear el avance académico de tus hijos e identificar con prontitud sus oportunidades de mejora. También recibirás avisos sobre sus calificaciones, su rendimiento escolar en cada materia hasta la fecha, los exámenes por presentar y más. Regístrate en nuestro colegio virtual para recibir más información sobre los beneficios de nuestra modalidad de estudio a distancia.  `
          },
          3: {
            text: `Estudia en un colegio virtual de calidad y obtén tu título de bachiller avalado por la SED de Villavicencio. Estudiarás de forma rápida y divertida a través de videoclases pregrabadas, material de apoyo, evaluaciones de práctica, un foro-chat para aclarar tus dudas y más. Regístrate para recibir más información.`
          }
        }
      },
      section12: {
        text: `Dawere tiene una variedad de programas educativos y cursos además del programa de Bachillerato Virtual. Entra en nuestro catálogo y explora nuestra oferta.`
      }
    },
    catalog: {
      seo: {
        title: `Programas Educativos de Colombia | Avalados por la Secretaría de Educación`,
        description: `Explora el catálogo de programas educativos y descubre las bondades de una educación virtual que se ajusta a tu ritmo y horario. Ofrecemos becas a quienes califiquen. `,
        keywords: `Secretaria de Educacion, SED, Educacion en Colombia`,
        ogTitle: `Programas Educativos de Colombia | Avalados por la Secretaría de Educación`,
        ogDescription: `Explora el catálogo de programas educativos y descubre las bondades de una educación virtual que se ajusta a tu ritmo y horario. Ofrecemos becas a quienes califiquen.  `,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/seo_logo_secretari%CC%81aeducacio%CC%81n_colombia.jpg`
      }
    },
    organizations: {
      seo: {
        title: `Dawere Colombia | Para Organizaciones`,
        keywords: `organizaciones, fundaciones, sistema educativo`,
        ogTitle: `Dawere Colombia para Organizaciones`
      },
      section4: {
        governments: {
          text: `
             <p>
              Según la UNESCO, en los sistemas educativos tradicionales de América Latina, solo 45% de los que inician el bachillerato se gradúan.
              </p>
            <p>
              Hay una deserción del 15% de los estudiantes todos los años y más del 30% de los jóvenes en edad escolar están fuera del sistema educativo tradicional. Con el uso de nuestra tecnología educativa, esto puede cambiar.
              </p>

            <p>
              Te invitamos a proveerle a todos tus ciudadanos educación de calidad gratuita. Parece una propuesta utópica pero se puede convertir en realidad gracias a los servicios de educación a distancia de Dawere. Nuestro Bachillerato Virtual ha sido diseñado para educar a millones de personas a una fracción del costo de la educación tradicional presencial. Compruébalo por ti mismo y empodera al país con la excelente educación que todos merecen.
              </p>
          `
        },
        companies: {
          text: `
            <p>
              El mundo del deporte profesional es extremadamente reñido, por lo que solo un porcentaje minúsculo logra convertirse en jugador profesional. ¿Qué ocurre con el resto?

              </p>
            <p>
              Recibir educación de calidad en paralelo a los entrenamientos puede garantizarle un futuro a todos tus jugadores sin excepción.

              </p>
            <p>
              Nuestro Bachillerato Virtual se adapta al ritmo y horario de los estudiantes. Con Dawere tus jugadores pueden formarse académicamente y perseguir sus sueños.
              </p>
          `
        }
      }
    },
    shared: {
      contact: {
        info: {
          phone: {
            title: `TELÉFONO`,
            text: ``
          },
          whatsapp: {
            title: `WHATSAPP`,
            text: `+57 311-2540133`
          },
          email: {
            title: `EMAIL`,
            text: ``
          }
        },
        buttons: {
          faq: `Preguntas frecuentes`
        }
      }
    },
    helpCenter: {
      seo: {
        keywords: `dawere, dawere colombia`
      },
      urls: {
        scholarshipForm: `https://forms.gle/XMEiQwH55Pma54aZ9`
      },
      contact: {
        info: {
          enrolled: {
            whatsapp: `+58 4122318073`
          },
          unregistered: {
            whatsapp: `+57 311-2540133`
          }
        }
      }
    },
    philosophy: {
      seo: {
        keywords: `Educacion, educacion de calidad, calidad educativa, modelo educativo, enseñanza virtual`
      }
    }
  },
  sections: {
    footer: {
      column1: {
        text: `<p>La plataforma de educación formal online Dawere y el Colegio Petroschool han formado una alianza para traerte la mejor experiencia de aprendizaje virtual. </p>
        <p>El Colegio Petroschool está registrado ante la Secretaría de Educación Distrital de Villavicencio bajo la resolución 0665 y ante el Ministerio de Educación Nacional bajo el código DANE 350001007794. </p>`
      }
    }
  }
};

export default esCO;

export { esCO };
