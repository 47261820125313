import { API } from 'api';
import React, { useEffect, useState } from 'react';

export interface CatalogProps {
  catalog: any;
  isLoading: boolean;
}

export const CatalogContext = React.createContext<CatalogProps>({
  catalog: null,
  isLoading: true
});

export function CatalogProvider({ children }: any) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [catalog, setCatalog] = useState<any>(null);

  useEffect(() => {
    setIsLoading(true);

    API.Programs.getCatalog(false)
      .then((catalog: any) => {
        setCatalog(catalog);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <CatalogContext.Provider
      value={{
        catalog,
        isLoading
      }}>
      {children}
    </CatalogContext.Provider>
  );
}
