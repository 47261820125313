import {
  Affix,
  Block,
  Col,
  useMatchMedia,
  useRouter,
  Visible
} from 'dawere-uic';
import { PageWrapper } from 'pages';
import { getSEO } from 'utils';
import { ProgramProvider } from '../program/ProgramProvider';
import { Details } from './sections/Details';
import { Heading } from './sections/Heading';
import { TopicsSection } from './sections/TopicsSection';
import { Video } from './sections/Video';
import { SubjectProvider } from './SubjectProvider';

/**
 * Path: /programa/:programId/materia/:subjectId
 * Key: SUBJECT
 * @returns {React.ReactElement}
 */
export default function SubjectPage() {
  const { isDesktop } = useMatchMedia();
  const { query }: any = useRouter();

  const isCourse = query?.course === 'true';

  return (
    <PageWrapper disableCountryPicker>
      {getSEO('v2.pages.subject.seo', true)}

      <ProgramProvider id={query?.programId}>
        <SubjectProvider id={query?.subjectId} programId={query?.programId}>
          <Visible xs sm>
            <Video />

            <Block narrow>
              <Col xs={12}>
                <Heading isCourse={isCourse} />
              </Col>
            </Block>

            {/* Details mobile */}
            <Details />

            <Block narrow>
              <Col xs={12}>
                {/* Content */}
                <TopicsSection />
              </Col>
            </Block>
          </Visible>

          <Visible md>
            <Block narrow>
              <Col xs={6}>
                {/* Content */}
                <Heading isCourse={isCourse} />
              </Col>

              <Col xs={6}>
                {/* Details  */}
                <Details />
              </Col>
            </Block>

            <Block narrow>
              <Col xs={12}>
                {/* Content */}
                <TopicsSection />
              </Col>
            </Block>
          </Visible>

          <Visible lg xl>
            <div id='subject-content' className='mb-6'>
              <Block narrow>
                <Col xs={8}>
                  {/* Details */}
                  <Heading isCourse={isCourse} />

                  <div className='mb-7'></div>

                  {/* Content */}
                  <TopicsSection />
                </Col>

                <Col xs={4}>
                  <Affix
                    enabled={isDesktop}
                    top={110}
                    bottomBoundary='#subject-content'>
                    <Details />
                  </Affix>
                </Col>
              </Block>
            </div>
          </Visible>
        </SubjectProvider>
      </ProgramProvider>
    </PageWrapper>
  );
}
