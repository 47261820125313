import { i18n } from 'dawere-commons';
import { Button, Col, Row } from 'dawere-uic';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { ASSETS } from 'values';

export function StudentsEmpty() {
  // const [openAddModal, setOpenAddModal] = useState(false);

  // const hanldeOpenAddModal = useCallback(() => {
  //   setOpenAddModal(true);
  // }, []);

  return (
    <Row>
      <Col md={6}>
        <img
          src={ASSETS.ILLUSTRATIONS.STUDENTS_STAIRS}
          alt={i18n('v2.pages.organizationDashboard.tabs.2.empty.title')}
          className='mb-5 mb-md-0 | img-fluid'
        />
      </Col>

      <Col md={6} className='order-md-first'>
        <h3 className='mb-4'>
          {i18n('v2.pages.organizationDashboard.tabs.2.empty.title')}
        </h3>

        <p
          className='mb-4'
          dangerouslySetInnerHTML={{
            __html: i18n(
              'v2.pages.organizationDashboard.tabs.2.empty.description'
            )
          }}
        />

        {/* <Button
          variant='outline'
          className='w-100 w-md-auto mr-md-2 mb-2'
          onClick={hanldeOpenAddModal}>
          {i18n('v2.pages.organizationDashboard.tabs.2.empty.buttons.add')}
        </Button> */}

        <Button
          as={Link}
          to={PATHS.BUY_COUPONS}
          className='w-100 mb-2 w-md-auto'>
          {i18n('v2.pages.organizationDashboard.tabs.2.empty.buttons.buy')}
        </Button>
      </Col>

      {/* <ModalAddStudent open={openAddModal} onClose={setOpenAddModal} /> */}
    </Row>
  );
}
