import { i18n } from 'dawere-commons';
import { Col, Flex, Row, Tab, TabList, TabPanel, Tabs } from 'dawere-uic';
import { useCallback } from 'react';
import { NumberParam, useQueryParams } from 'use-query-params';
import { QPARAMS } from 'values';
import { StudentsGraduatedTab } from './StudentsGraduatedTab';
import { StudentsInCourseTab } from './StudentsInCourseTab';
import { StudentsLatestCourseTab } from './StudentsLatestCourseTab';
import { StudentsPendingInvitationTab } from './StudentsPendingInvitationTab';
import { StudentsRemovedTab } from './StudentsRemovedTab';
import { StudentsToEnrollTab } from './StudentsToEnrollTab';

export function StudentsSubtabs() {
  const [query, setQuery] = useQueryParams({
    [QPARAMS.Subtab]: NumberParam
  });

  const handleTabSelection = useCallback(
    (value: number) => {
      setQuery({ [QPARAMS.Subtab]: value }, 'pushIn');
    },
    [setQuery]
  );

  return (
    <Row>
      <Col>
        <Tabs
          onSelect={handleTabSelection}
          defaultIndex={query[QPARAMS.Subtab] || 0}>
          <Flex align='center' justify='between' className='mb-4'>
            <TabList scrollable='true'>
              <Tab>
                {i18n(
                  'v2.pages.organizationDashboard.tabs.2.full.tabs.1.title'
                )}
              </Tab>
              <Tab>
                {i18n(
                  'v2.pages.organizationDashboard.tabs.2.full.tabs.2.title'
                )}
              </Tab>
              <Tab>
                {i18n(
                  'v2.pages.organizationDashboard.tabs.2.full.tabs.3.title'
                )}
              </Tab>
              <Tab>
                {i18n(
                  'v2.pages.organizationDashboard.tabs.2.full.tabs.4.title'
                )}
              </Tab>
              <Tab>
                {i18n(
                  'v2.pages.organizationDashboard.tabs.2.full.tabs.5.title'
                )}
              </Tab>
              <Tab>
                {i18n(
                  'v2.pages.organizationDashboard.tabs.2.full.tabs.6.title'
                )}
              </Tab>
            </TabList>

            <div id='grid-portal-search'></div>
          </Flex>

          <TabPanel>
            <StudentsInCourseTab />
          </TabPanel>
          <TabPanel>
            <StudentsPendingInvitationTab />
          </TabPanel>
          <TabPanel>
            <StudentsRemovedTab />
          </TabPanel>
          <TabPanel>
            <StudentsGraduatedTab />
          </TabPanel>
          <TabPanel>
            <StudentsLatestCourseTab />
          </TabPanel>
          <TabPanel>
            <StudentsToEnrollTab />
          </TabPanel>
        </Tabs>
      </Col>
    </Row>
  );
}
