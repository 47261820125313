import { API } from 'api';
import { i18n } from 'dawere-commons';
import { Button, Callout, Col, Row } from 'dawere-uic';
import { useCallback, useState } from 'react';
import { ASSETS } from 'values';

export function NextPeriodPanel({ enrollment, onContinue = () => {} }: any) {
  const { period } = enrollment;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleContinue = useCallback(() => {
    setIsLoading(true);

    API.Students.getNextSubject(enrollment?.id)
      .then((response: any) => onContinue(response.data))
      .finally(() => setIsLoading(false));
  }, [enrollment?.id, onContinue]);

  return (
    <Row>
      <Col>
        <Callout className='text-center border border-platinium bg-white'>
          <img
            src={ASSETS.ILLUSTRATIONS.HI5}
            alt={i18n('v2.pages.studentDashboard.tabs.1.congrats2', {
              period: period?.name
            })}
            className='img-fluid w-70 w-md-25 mb-4'
          />

          <h3>
            {i18n('v2.pages.studentDashboard.tabs.1.congrats2', {
              period: period?.name
            })}
          </h3>

          <p>{i18n('v2.pages.studentDashboard.tabs.1.subtext')}</p>

          <Button
            className='w-100 w-md-auto mb-4'
            onClick={handleContinue}
            loading={isLoading}>
            {i18n('v2.pages.studentDashboard.tabs.1.continueNextPeriod')}
          </Button>
        </Callout>
      </Col>
    </Row>
  );
}
