import { i18n, Session } from 'dawere-commons';
import {
  Avatar,
  Button,
  Flex,
  Icons,
  ReadMore,
  Skeleton,
  useRouter,
  Visible
} from 'dawere-uic';
import { useProgramStatus } from 'hooks';
import { useProgram } from 'pages/public/program/hooks/useProgram';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import styled from 'styled-components';
import { trackEvent } from 'utils';
import { QPARAMS } from 'values';
import { EnrollButton } from '../components/EnrollButton';

import { useSubject } from '../hooks/useSubject';
import { Video } from './Video';

const DetailWrapperStyled = styled.div`
  background-color: var(--dw-color-white);

  @media (min-width: 768px) {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.161);
    border-radius: 8px;
  }
`;

export const TeacherCardStyled = styled.div`
  background: var(--dw-color-white);
  height: 100%;
  display: flex;

  p {
    margin-bottom: 0;
    font-size: 80%;
  }
`;

export function Details() {
  const account = Session.getAccountInfo();
  const { query } = useRouter();

  const { program } = useProgram();
  const { status, isLoading: isLoadingMyPrograms } = useProgramStatus(
    program?.id
  );
  const { isLoading, subject } = useSubject();

  const handleClick = useCallback(() => {
    trackEvent('button_click_inscribete', { position: 3 });
  }, []);

  const renderButtons = useCallback(() => {
    // Sin sesión
    if (!account) {
      return (
        <EnrollButton
          onClick={handleClick}
          programId={program?.id}
          isLoadingMyPrograms={isLoadingMyPrograms}
          program={program}
          currencyId={query[QPARAMS.Currency]}
          className='w-100 w-md-auto'
        />
      );
    }

    // Cuando ha iniciado sesion
    // Si es estudiante y organizacion
    if (account?.student && account?.organization) {
      return (
        <>
          <Flex>
            <EnrollButton
              onClick={handleClick}
              programId={program?.id}
              isLoadingMyPrograms={isLoadingMyPrograms}
              program={program}
              currencyId={query[QPARAMS.Currency]}
              status={status}
              className='w-100 w-md-auto'
            />
          </Flex>

          <Flex className='order-first order-md-last'>
            <Button
              block
              as={Link}
              to={PATHS.get(
                'BUY_COUPONS',
                {},
                {
                  [QPARAMS.Program]: query?.programId
                }
              )}>
              {i18n('v2.pages.organizationDashboard.tabs.1.empty.buttons.buy')}
            </Button>
          </Flex>
        </>
      );
    }

    // Si es estudiante y no es organizacion
    if (account?.student && !account?.organization) {
      return (
        <EnrollButton
          onClick={handleClick}
          programId={program?.id}
          isLoadingMyPrograms={isLoadingMyPrograms}
          program={program}
          currencyId={query[QPARAMS.Currency]}
          status={status}
          className='w-100 w-md-auto'
        />
      );
    }

    // Si no es estudiante y es organizacion
    if (!account?.student && account?.organization) {
      return (
        <Button
          block
          as={Link}
          to={PATHS.get(
            'BUY_COUPONS',
            {},
            {
              [QPARAMS.Program]: query?.programId
            }
          )}>
          {i18n('v2.pages.organizationDashboard.tabs.1.empty.buttons.buy')}
        </Button>
      );
    }

    return null;
  }, [account, handleClick, isLoadingMyPrograms, program, query, status]);

  return (
    <DetailWrapperStyled>
      <Visible md lg xl>
        <Video />
      </Visible>

      <div>
        <div className='p-4'>
          <TeacherCardStyled>
            {isLoading ? (
              <div className='mr-3 mb-3'>
                <Skeleton.Avatar size={45} />
              </div>
            ) : (
              <Flex className='mr-3 mb-3'>
                <Avatar
                  src={subject?.author?.picture}
                  alt={subject?.author?.name}
                  size={45}
                />
              </Flex>
            )}
            {isLoading ? (
              <div className='w-100'>
                <Skeleton.Paragraph lines={3} />
              </div>
            ) : (
              <Flex basis='auto' direction='column'>
                <h4 className='mt-0'>{subject?.author?.name}</h4>

                <ReadMore>{subject?.author?.resume}</ReadMore>
              </Flex>
            )}
          </TeacherCardStyled>
        </div>

        <div className='text-center'>
          {isLoading ? (
            <div className='border-top p-4'>
              <Skeleton.Button />
            </div>
          ) : (
            <div className='border-top p-4'>
              {status === 'ACTIVE' ? null : (
                <p className='text-primary'>
                  <Icons.CalendarChecked className='mr-2' />{' '}
                  {i18n('v2.pages.subject.details.description')}
                </p>
              )}

              <Flex gap={2} direction='column'>
                {renderButtons()}
              </Flex>
            </div>
          )}
        </div>
      </div>
    </DetailWrapperStyled>
  );
}
