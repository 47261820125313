import { Link } from 'react-router-dom';
import styled from 'styled-components';

const ReportTileStyled = styled(Link)`
  color: var(--dw-color-text);

  > div {
    background-color: var(--dw-color-platinium);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--dw-color-primaryShade);
    padding: var(--dw-spacer-6) var(--dw-spacer-2);
    margin-bottom: var(--dw-spacer-3);
    font-size: 5.5rem;
    transition: all 0.3s ease;

    svg {
      transition: all 0.3s ease;
    }
  }

  &:hover {
    text-decoration: none;
    color: var(--dw-color-text);

    > div {
      background-color: var(--dw-color-primaryShade);

      svg {
        transform: scale(1.3);
        color: var(--dw-color-white);
      }
    }
  }
`;

export function ReportTile({
  children,
  title = '',
  icon = null,
  ...rest
}: any) {
  return (
    <ReportTileStyled {...rest}>
      <div>{icon}</div>
      <h4>{title}</h4>
      <p>{children}</p>
    </ReportTileStyled>
  );
}
