import { i18n } from 'dawere-commons';
import { Block, Button, Col } from 'dawere-uic';
import { PageWrapper } from 'pages';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { getSEO } from 'utils';
import { ASSETS } from 'values';

/**
 * Path: /verificar-cuenta
 * Key: VERIFY_EMAIL
 * @returns {React.ReactElement}
 */
export default function CheckYourEmailPage() {
  return (
    <PageWrapper>
      {getSEO('v2.pages.checkEmail.seo')}

      <Block className='text-center'>
        <Col>
          <img
            src={ASSETS.ICONS.EMAIL_SEND}
            alt={i18n('v2.pages.checkEmail.title')}
            className='mb-3 img-fluid'
            width={150}
          />

          <h2 className='mb-3'>{i18n('v2.pages.checkEmail.title')}</h2>
          <h4>{i18n('v2.pages.checkEmail.subtitle')}</h4>
          <div className='mt-6'>
            <Button
              className='mb-2 mb-md-0 | w-100 w-md-auto'
              as={Link}
              to={PATHS.HOME}>
              {i18n('v2.sections.navbar.links.home')}
            </Button>
            <p className='mt-4 text-center'>
              {i18n('v2.pages.checkEmail.noEmail')}{' '}
              <Link to={PATHS.HOME}>
                {i18n('v2.pages.checkEmail.links.contactUs')}
              </Link>
            </p>
          </div>
        </Col>
      </Block>
    </PageWrapper>
  );
}
