import { API } from 'api';
import { Historical } from 'components';
import { useRouter } from 'dawere-uic';
import { useEffect, useState } from 'react';

export function HistoricalTab({ enrollment }: any) {
  const { program } = enrollment;
  const { query } = useRouter();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (query?.studentId && program?.id) {
      setIsLoading(true);

      API.Students.getTranscripts(query?.studentId, program?.id)
        .then((response: any) => setData(response))
        .finally(() => setIsLoading(false));
    }
  }, [program?.id, query?.studentId]);

  return (
    <Historical program={program} transcripts={data} isLoading={isLoading} />
  );
}
