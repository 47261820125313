import { LocalStorage } from 'dawere-commons';
import { MS_TO_MIN } from 'values';

export class Referrals {
  private static key1 = 'dw-referral-id';
  private static key2 = 'dw-referral';
  private static key3 = 'dw-referral-date';

  public static init(referralId?: string) {
    this.clean();

    if (referralId) {
      LocalStorage.put(this.key1, referralId);
      LocalStorage.put(this.key3, Date.now());
    }
  }

  public static save(referral: any) {
    LocalStorage.put(this.key2, referral);
  }

  public static clean() {
    LocalStorage.remove(this.key1);
    LocalStorage.remove(this.key2);
    LocalStorage.remove(this.key3);
  }

  public static getId() {
    const date = LocalStorage.get(this.key3);

    if (date && Date.now() - date > MS_TO_MIN * 15) {
      this.clean();
    }

    return LocalStorage.get(this.key1);
  }

  public static get() {
    return LocalStorage.get(this.key2);
  }
}
