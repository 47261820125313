import { Refresh } from '@mui/icons-material';
import { API } from 'api';
import { i18n } from 'dawere-commons';
import {
  Avatar,
  Badge,
  Button,
  Col,
  Confirm,
  DatePicker,
  Flex,
  Icons,
  Pagination,
  Row,
  Search,
  Skeleton,
  Table,
  toast,
  Utils,
  Visible
} from 'dawere-uic';
import { useOrganization } from 'hooks';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { ModalOrderDetails } from '../components/ModalOrderDetails';

const DatePickerButton = forwardRef(({ value, onClick }: any, ref: any) => (
  <Button variant='filter' onClick={onClick} ref={ref} caret>
    {value || i18n('v2.pages.organizationDashboard.tabs.4.buttons.filter')}
  </Button>
));

export function PaymentsTab() {
  const { organization } = useOrganization();

  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const [rowSelected, setRowSelected] = useState<any>(null);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openRevisionModal, setOpenRevisionModal] = useState(false);

  const handleOpenDetails = useCallback((payment: any) => {
    setRowSelected(payment);
  }, []);

  const desktopColumns = useMemo(
    () => [
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.4.table.columns.status'
        ),
        accessor: 'status',
        Cell: ({ cell }: any) => {
          // DRAFT: "En curso" o "Impompleto" boton de continuar (entro a stripe y me salgo)
          // PENDING: "Procesando" no mostrar boton
          // CONFIRMED: "Pagado" boton de "Descargar PDF"
          // REJECTED: "Rechazado" boton de los puntos para volver a pagar o anular
          // ERROR: "Error" sin boton
          // VOIDED: "Anulado" boton de "Ver detalles"
          const colors: any = {
            DRAFT: 'warning',
            PENDING: 'warning',
            CONFIRMED: 'success',
            PAID: 'success',
            REJECTED: 'error',
            ERROR: 'warning',
            VOIDED: 'muted'
          };

          return (
            <Badge pilled variant={colors[cell.value]}>
              {i18n(`v2.pages.organizationDashboard.tabs.4.${cell.value}`)}
            </Badge>
          );
        }
      },
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.4.table.columns.method'
        ),
        accessor: 'id',
        Cell: ({ cell }: any) => {
          const method = cell.row?.original?.gateway;

          if (method === 'STRIPE') {
            const last4digits = cell.row?.original?.last4digits;
            const brand = cell.row?.original?.brand;
            const icon =
              brand === 'visa' ? (
                <Icons.Visa className='fs-1' />
              ) : brand === 'mastercard' ? (
                <Icons.Mastercard className='fs-1' />
              ) : (
                <Icons.CreditCard className='fs-1' />
              );

            return (
              <>
                <span className='d-block'>
                  {icon} {Utils.titleize(brand)}
                </span>
                <small className='text-muted'>
                  **** **** **** {last4digits}
                </small>
              </>
            );
          }

          if (method === 'PAYPAL') {
            return (
              <>
                <Icons.Paypal colored /> Paypal
              </>
            );
          }

          if (cell.row?.original?.paymentMethod === 'WALLET') {
            return (
              <span>
                {i18n('v2.pages.organizationDashboard.tabs.4.modal.WALLET')}
              </span>
            );
          }

          if (method === 'TRANSFER') {
            return cell.row?.original?.bank.name === 'Zelle' ? (
              <>
                <span className='d-block'>
                  <Icons.Zelle colored className='fs-1' />{' '}
                  {cell.row?.original?.bank.name}
                </span>
                <small className='text-muted'>
                  {cell.row?.original?.reference?.toUpperCase()}
                </small>
              </>
            ) : (
              <>
                <span className='d-block'>
                  <img
                    alt={cell.row?.original?.bank.name}
                    src={cell.row?.original?.bank.logo}
                    height={16}
                  />{' '}
                  {cell.row?.original?.bank.name}
                </span>
                <span className='text-muted'>
                  {cell.row?.original?.reference?.toUpperCase()}
                </span>
              </>
            );
          }

          return <>-</>;
        }
      },
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.4.table.columns.paymentNumber'
        ),
        accessor: 'number'
      },
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.4.table.columns.date'
        ),
        accessor: 'paymentDate'
      },
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.4.table.columns.amount'
        ),
        accessor: 'amount',
        Cell: ({ cell }: any) => {
          return (
            <>
              {cell.row?.original?.currency?.symbol || '$'}
              {Utils.toCurrency(cell.value)} (
              {cell.row?.original?.currency?.isoCode})
            </>
          );
        }
      },
      {
        Header: '',
        accessor: 'action',
        unclickable: true,
        disableSortBy: true,
        Cell: (cell: any) => {
          return (
            <div className='text-right'>
              <Button
                style={{ minWidth: 'max-content' }}
                onClick={() => handleOpenDetails(cell.row?.original)}>
                {i18n(
                  'v2.pages.organizationDashboard.tabs.4.buttons.seeDetails'
                )}
              </Button>
            </div>
          );
        }
      }
    ],
    [handleOpenDetails]
  );

  const mobileColumns = useMemo(
    () => [
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.4.table.columns.payments'
        ),
        accessor: 'id',
        Cell: ({ value, row }: any) => {
          const status = row?.original?.status;
          const method = row?.original?.gateway;
          let icon = null;
          let last4digits = '';
          let brand = '';

          if (method === 'STRIPE') {
            last4digits = row?.original?.last4digits;
            brand = row?.original?.brand;
            icon =
              brand === 'visa' ? (
                <Icons.Visa className='fs-1' />
              ) : brand === 'mastercard' ? (
                <Icons.Mastercard className='fs-1' />
              ) : (
                <Icons.CreditCard className='fs-1' />
              );
          }

          const colors: any = {
            DRAFT: 'warning',
            PENDING: 'warning',
            CONFIRMED: 'success',
            PAID: 'success',
            REJECTED: 'error',
            ERROR: 'warning',
            VOIDED: 'muted'
          };

          return (
            <div>
              <Badge pilled variant={colors[status]}>
                {i18n(`v2.pages.organizationDashboard.tabs.4.${status}`)}
              </Badge>

              <strong className='mb-0 d-block fs-1'>
                {row.original?.number}
              </strong>

              <div>{row.original?.dueDate}</div>
              <Flex align='center' justify='between'>
                <div>
                  {row.original?.currency?.symbol || '$'}
                  {Utils.toCurrency(row.original?.amount)} (
                  {row?.original?.currency?.isoCode})
                </div>
                <div>{row?.original?.paymentDate}</div>
              </Flex>

              <Flex align='center' justify='between' className='my-2'>
                {method === 'STRIPE' ? (
                  <>
                    <span className='d-block'>
                      {icon} {Utils.titleize(brand)}
                    </span>
                    <span className='text-muted'>
                      **** **** **** {last4digits}
                    </span>
                  </>
                ) : null}

                {method === 'PAYPAL' ? (
                  <span>
                    <Icons.Paypal colored /> Paypal
                  </span>
                ) : null}

                {row?.original?.paymentMethod === 'WALLET' ? (
                  <span>
                    {i18n('v2.pages.organizationDashboard.tabs.4.modal.WALLET')}
                  </span>
                ) : null}

                {method === 'TRANSFER' ? (
                  <>
                    {row?.original?.bank.name === 'Zelle' ? (
                      <>
                        <span className='d-block'>
                          <Icons.Zelle colored className='fs-1' />{' '}
                          {row?.original?.bank.name}
                        </span>
                        <span className='text-muted'>
                          {row?.original?.reference?.toUpperCase()}
                        </span>
                      </>
                    ) : (
                      <>
                        <span className='d-block'>
                          <Avatar src={row?.original?.bank.logo} size={24} />{' '}
                          {row?.original?.bank.name}
                        </span>
                        <span className='text-muted'>
                          {row?.original?.reference?.toUpperCase()}
                        </span>
                      </>
                    )}
                  </>
                ) : null}
              </Flex>

              <div className='mt-2'>
                <Button
                  block
                  onClick={() => handleOpenDetails(row.cell.row?.original)}>
                  {i18n(
                    'v2.pages.organizationDashboard.tabs.4.buttons.seeDetails'
                  )}
                </Button>
              </div>
            </div>
          );
        }
      }
    ],
    [handleOpenDetails]
  );

  const load = useCallback(() => {
    if (organization?.id) {
      setIsLoading(true);

      API.Organizations.getPayments(organization.id, {
        page: pageIndex,
        size: pageSize,
        keyword: keyword,
        startDate: startDate
          ? Utils.formatDate(startDate, 'yyyy-mm-dd', true)
          : null,
        endDate: endDate ? Utils.formatDate(endDate, 'yyyy-mm-dd', true) : null
      })
        .then((response: any) => {
          setData(response.data);
          setPageCount(response.totalPages);
          setTotal(response.totalRows);
        })
        .finally(() => setIsLoading(false));
    }
  }, [endDate, keyword, organization.id, pageIndex, pageSize, startDate]);

  // Reload data
  useEffect(() => {
    load();
  }, [load]);

  const handlePaging = useCallback(({ pageSize, pageIndex }: any) => {
    setPageSize(pageSize);
    setPageIndex(pageIndex);
  }, []);

  const handleSearch = useCallback((data: any) => {
    setKeyword(() => {
      setPageIndex(0);
      return data.search;
    });
  }, []);

  const handleDateChange = useCallback((data: any) => {
    if (data[0] && data[1]) {
      setStartDate(data[0]);
      setEndDate(data[1]);
    }
  }, []);

  const handleDelete = useCallback(() => {
    setIsDeleting(true);

    API.Organizations.cancelOrder(organization.id, rowSelected)
      .then(() => {
        load();
        toast.success(
          i18n('v2.pages.organizationDashboard.tabs.4.deleteModal.toast')
        );
      })
      .finally(() => setIsDeleting(false));
  }, [load, organization.id, rowSelected]);

  return (
    <>
      <Visible xs sm>
        <Search
          name='search'
          allowEmptySearch
          value={keyword}
          onSubmit={handleSearch}
          disabled={isLoading || isDeleting}
        />

        <DatePicker
          name='datepicker'
          monthsShown={2}
          selectsRange
          onChange={handleDateChange}
          customInput={<DatePickerButton />}
          disabled={isLoading || isDeleting}
        />
      </Visible>

      <Visible md lg xl>
        <Flex className='mb-2' align='center' justify='between'>
          <DatePicker
            className='m-0'
            name='datepicker'
            monthsShown={2}
            selectsRange
            onChange={handleDateChange}
            customInput={<DatePickerButton />}
            disabled={isLoading || isDeleting}
          />

          <Flex>
            <Button
              variant='action'
              className='mr-2'
              loading={isLoading}
              circle
              icon={<Refresh fontSize='small' />}
              style={{ borderRadius: '8px' }}
              onClick={load}
            />

            <Search
              className='m-0'
              name='search'
              allowEmptySearch
              value={keyword}
              onSubmit={handleSearch}
              disabled={isLoading || isDeleting}
            />
          </Flex>
        </Flex>
      </Visible>

      <Visible xs sm>
        <Table
          data={data}
          columns={mobileColumns}
          loading={isLoading || isDeleting}
          pageable={false}
          sortable={false}
        />
      </Visible>

      <Visible md lg xl>
        <Table
          data={data}
          columns={desktopColumns}
          loading={isLoading || isDeleting}
          pageable={false}
          sortable={false}
        />
      </Visible>

      <Row>
        <Visible lg xl>
          <Col xs={3} />
        </Visible>

        <Col>
          <Pagination
            align='center'
            className='my-4'
            onUpdate={handlePaging}
            pageIndex={pageIndex}
            pageCount={pageCount}
            disabled={isLoading || isDeleting}
          />
        </Col>

        <Visible md lg xl>
          <Col xs={3} className='d-flex align-items-center justify-content-end'>
            {isLoading || isDeleting ? (
              <Skeleton width={120} wrapper='small' />
            ) : (
              <small>
                <strong>{total}</strong>{' '}
                {i18n('v2.pages.organizationDashboard.tabs.4.table.count')}
              </small>
            )}
          </Col>
        </Visible>
      </Row>

      <ModalOrderDetails
        open={openDetailsModal}
        onClose={setOpenDetailsModal}
        id={rowSelected}
      />

      <Confirm
        type='warning'
        title={i18n('v2.pages.organizationDashboard.tabs.4.deleteModal.title')}
        open={openDeleteModal}
        onClose={setOpenDeleteModal}
        onConfirm={handleDelete}
        confirmText={i18n(
          'v2.pages.organizationDashboard.tabs.4.deleteModal.button'
        )}>
        <p>
          {i18n(
            'v2.pages.organizationDashboard.tabs.4.deleteModal.description'
          )}
        </p>
      </Confirm>

      <ModalOrderDetails
        open={!!rowSelected}
        onClose={setRowSelected}
        payment={rowSelected}
        sourceId={organization.id}
        type='ORGANIZATION'
      />
    </>
  );
}
