import { API } from 'api';
import { i18n } from 'dawere-commons';
import { Button, Col, Divider, Form, Input, Row, toast } from 'dawere-uic';
import { useCallback, useRef, useState } from 'react';

export function PasswordSection() {
  const passwordRef = useRef<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const handleSubmit = useCallback(({ oldPassword, newPassword }: any) => {
    setIsLoading(true);

    API.Account.changePassword(oldPassword, newPassword)
      .then((response: any) => {
        toast.success(response.message);
        setState({
          oldPassword: '',
          newPassword: '',
          confirmPassword: ''
        });
      })
      .finally(() => setIsLoading(false));
  }, []);

  const handleChange = useCallback((data: any) => {
    passwordRef.current = data.newPassword;
  }, []);

  return (
    <>
      <Divider section>
        {i18n('v2.pages.settings.tabs.1.changePassword.title')}
      </Divider>

      <Row>
        <Col xs={12} md={6}>
          <Form onSubmit={handleSubmit} onChange={handleChange}>
            <Input
              name='oldPassword'
              label={i18n(
                'v2.pages.settings.tabs.1.changePassword.oldPassword'
              )}
              type='password'
              validators={{
                required: true
              }}
              loading={isLoading}
              value={state.oldPassword}
              onChange={(e: any) =>
                setState({ ...state, oldPassword: e.target.value })
              }
            />
            <Input
              name='newPassword'
              label={i18n(
                'v2.pages.settings.tabs.1.changePassword.newPassword'
              )}
              type='password'
              validators={{
                required: true,
                minLength: 8
              }}
              loading={isLoading}
              value={state.newPassword}
              onChange={(e: any) =>
                setState({ ...state, newPassword: e.target.value })
              }
            />
            <Input
              name='newPassword_repeat'
              label={i18n(
                'v2.pages.settings.tabs.1.changePassword.confirmPassword'
              )}
              type='password'
              validators={{
                required: true,
                minLength: 8,
                passwordMatch: (password: string) =>
                  passwordRef.current === '' || password === passwordRef.current
              }}
              loading={isLoading}
              value={state.confirmPassword}
              onChange={(e: any) =>
                setState({ ...state, confirmPassword: e.target.value })
              }
            />
            <Button type='submit' loading={isLoading}>
              {i18n('v2.pages.settings.tabs.1.changePassword.submit')}
            </Button>
          </Form>
        </Col>
      </Row>
    </>
  );
}
