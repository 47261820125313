import { i18n } from 'dawere-commons';
import { Block, Button, Col } from 'dawere-uic';
import { PageWrapper } from 'pages';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { getSEO } from 'utils';
import { ASSETS } from 'values';

/**
 * Path: /contrasena-cambiada
 * Key: PASSWORD_CHANGED
 * @returns {React.ReactElement}
 */
export default function PasswordChangedPage() {
  return (
    <PageWrapper>
      {getSEO('v2.pages.passwordCreated.seo', true)}

      <Block className='text-center'>
        <Col>
          <img
            src={ASSETS.ICONS.CHECK}
            alt={i18n('v2.pages.demoRequested.title')}
            className='mb-6 img-fluid'
            width={150}
          />

          <h2 className='mb-3'>{i18n('v2.pages.passwordCreated.title')}</h2>
          <h4>{i18n('v2.pages.passwordCreated.subtitle')}</h4>
          <div className='mt-6'>
            <Button
              as={Link}
              to={PATHS.LOGIN}
              className='mb-1 mb-md-0 | w-100 w-md-auto'>
              {i18n('v2.pages.passwordCreated.buttons.login')}
            </Button>
          </div>
        </Col>
      </Block>
    </PageWrapper>
  );
}
