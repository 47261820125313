import { PaymentBar } from 'components';
import { useBuyCoupons } from '../hooks/useBuyCoupons';
import { SummaryCard } from './SummaryCard';

export function SummarySidebar() {
  const { step } = useBuyCoupons();

  return step === '2' ? (
    <PaymentBar>
      <SummaryCard />
    </PaymentBar>
  ) : null;
}
