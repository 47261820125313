import { Block, Carousel, Col, Row, useMatchMedia } from 'dawere-uic';
import { useMemo } from 'react';
import { PrimaryCard } from './PrimaryCard';

export function PrimarySlider({ entry }: any) {
  const { isMobile } = useMatchMedia();

  const items = useMemo(
    () =>
      entry?.subcategories?.map((category: any) => (
        <PrimaryCard key={category.id} {...category} />
      )),
    [entry?.subcategories]
  );

  return (
    <Block narrow>
      <Col>
        <h1 className='mb-4 h2'>{entry?.title}</h1>

        <Row className='mb-3'>
          <Col xs={12}>
            <p className='mb-3 | mb-md-0'>{entry?.description}</p>
          </Col>
        </Row>

        <Carousel
          disableButtonsControls={isMobile}
          mouseTracking
          items={items}
          resizeTimeout={0}
        />
      </Col>
    </Block>
  );
}
