import { i18n } from 'dawere-commons';
import { Block, Col, Row } from 'dawere-uic';

export function DegreeSection() {
  return (
    <Block>
      <Col xs={12}>
        <h5 className='text-uppercase text-primary'>
          {i18n('v2.pages.home.section2.subtitle')}
        </h5>
        <h2 className='mb-6'>{i18n('v2.pages.home.section2.title')}</h2>

        <Row align='center'>
          <Col md={6} className='order-last order-md-first text-center'>
            <img
              src={i18n('v2.pages.home.section2.images.1.url')}
              alt={i18n('v2.pages.home.section2.images.1.alt')}
              className='img-fluid mt-6 mt-md-0 w-100'
            />
          </Col>
          <Col
            md={6}
            dangerouslySetInnerHTML={{
              __html: i18n('v2.pages.home.section2.text')
            }}
          />
        </Row>
      </Col>
    </Block>
  );
}
