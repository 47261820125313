import { Gateways } from 'components';
import { i18n } from 'dawere-commons';
import { Divider, Visible } from 'dawere-uic';
import { useFreeEnroll } from '../hooks/useFreeEnroll';
import { BillingForm } from './BillingForm';
import { SummaryCard } from './SummaryCard';

export function Checkout() {
  const {
    gateways,
    isBillingInfoCompleted,
    summary,
    setIsPaid,
    isLoadingSummary,
    track,
    walletBalance,
    redirectTo
  } = useFreeEnroll();

  return (
    <>
      <h2 className='mb-4'>{i18n('v2.pages.enrolment.steps.3.description')}</h2>

      <div className='shadow shadow-md-0 | p-3 p-md-0'>
        {/* Datos fiscales */}
        <BillingForm />

        <Visible md lg xl>
          <Divider />
        </Visible>
      </div>

      <Visible xs sm>
        <div
          className='bg-cultured p-3 my-4'
          style={{ marginLeft: '-15px', marginRight: '-15px' }}>
          <SummaryCard />
        </div>
      </Visible>

      {/* Botones de pago */}
      <Gateways
        disabled={isLoadingSummary}
        options={gateways}
        isBillingInfoCompleted={isBillingInfoCompleted}
        total={summary?.total}
        installments={summary?.installments}
        onSuccess={(method: string) => {
          setIsPaid(true);
          track('funnel_step3_payment', ['period', 'payment_type'], {
            payment_method: method
          });
        }}
        onError={() => setIsPaid(true)}
        redirectTo={redirectTo}
        currency={summary?.currency}
        discounts={summary?.discounts}
        allowDirectPayment
        walletBalance={walletBalance}
      />
    </>
  );
}
