import { Text, View } from '@react-pdf/renderer';
import { i18n } from 'dawere-commons';
import { PDF_BASE_FONT_SIZE, PDF_MUTED_COLOR } from 'values';

export function FooterSection({ data }: any) {
  return (
    <View
      fixed
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        position: 'absolute',
        fontSize: PDF_BASE_FONT_SIZE * 0.9,
        bottom: 30,
        left: 0,
        right: 0,
        color: PDF_MUTED_COLOR,
        paddingLeft: 35,
        paddingRight: 35
      }}>
      <View style={{}}>
        <Text>
          {i18n('v2.pages.pdfReceipt.docName', { code: data?.number })}
        </Text>
      </View>
      <View style={{ textAlign: 'right' }}>
        <Text
          render={({ pageNumber, totalPages }) => `${pageNumber}/${totalPages}`}
        />
      </View>
    </View>
  );
}
