import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import TagManager from 'react-gtm-module';
import { hotjar } from 'react-hotjar';
import { getFullCountryInfo, getPublicKey } from 'utils';
import App from './App';

import 'dawere-uic/lib/utilities.css';
import 'intro.js/introjs.css';

// Current country
const country = getFullCountryInfo();

// Stripe
const stripePromise = loadStripe(getPublicKey('STRIPE_KEY'));

// Tracking
TagManager.initialize({
  gtmId: country?.google_tag_id
});

// Hotjar Tracking Code for Dawere 2024
hotjar.initialize(3190624, 6);

ReactDOM.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider
      reCaptchaKey={getPublicKey('GOOGLE_RECAPTCHA_KEY')}>
      <Elements stripe={stripePromise}>
        <App />
      </Elements>
    </GoogleReCaptchaProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
