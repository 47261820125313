import { API } from 'api';
import { OrderBox } from 'components';
import { useCallback, useState } from 'react';
import { FreeTrialEnrollButton } from '../components/FreeTrialEnrollButton';
import { useEnroll } from '../hooks/useEnroll';

export function SummaryCard() {
  const {
    isEarlyPayment,
    setIsEarlyPayment,
    isLoadingSummary,
    summary,
    setSummary,
    currency,
    getTaxData,
    walletBalance,
    onInstallmentsChange
  } = useEnroll();

  const [isLoadingDiscount, setIsLoadingDiscount] = useState(false);
  const [isRemovingDiscount, setIsRemovingDiscount] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitDiscount = useCallback(
    (coupon: any) => {
      if (!coupon) {
        return;
      }

      setIsLoadingDiscount(true);

      API.Cart.validateDiscountCoupon(coupon)
        .then(() => {
          return API.Enrollment.updateOrder(summary?.id, {
            discountCodes: [
              coupon,
              ...summary?.discounts.map((d: any) => d.coupon)
            ],
            earlyPayment: isEarlyPayment,
            taxData: getTaxData()
          }).then((response: any) => {
            setSummary(response);
          });
        })
        .finally(() => setIsLoadingDiscount(false));
    },
    [getTaxData, isEarlyPayment, setSummary, summary?.discounts, summary?.id]
  );

  const handleRemoveDiscount = useCallback(
    (discount: any) => {
      setIsRemovingDiscount(true);

      const discounts = summary?.discounts
        .filter((d: any) => d.coupon !== discount.coupon)
        .map((d: any) => d.coupon);

      return API.Enrollment.updateOrder(summary?.id, {
        discountCodes: discounts,
        earlyPayment: isEarlyPayment,
        taxData: getTaxData()
      })
        .then((response: any) => {
          setSummary(response);
        })
        .finally(() => setIsRemovingDiscount(false));
    },
    [getTaxData, isEarlyPayment, setSummary, summary?.discounts, summary?.id]
  );

  const handleEarlyPaymentChange = useCallback(
    (value: boolean) => {
      setIsLoading(true);

      // Lista de descuentos previos aplicados
      const discounts = summary?.discounts.map((d: any) => d.coupon);

      API.Enrollment.updateOrder(summary?.id, {
        discountCodes: discounts,
        earlyPayment: value,
        taxData: getTaxData()
      })
        .then((response: any) => {
          setIsEarlyPayment(value);
          setSummary(response);
        })
        .finally(() => setIsLoading(false));
    },
    [getTaxData, setIsEarlyPayment, setSummary, summary?.discounts, summary?.id]
  );

  return (
    <>
      <FreeTrialEnrollButton className='mb-3' />

      <OrderBox
        className='mb-md-6'
        loading={isLoadingSummary || isLoading}
        loadingDiscount={isLoadingDiscount}
        removingDiscount={isRemovingDiscount}
        currency={currency}
        lines={summary?.lines}
        installments={summary?.installments}
        discounts={summary?.discounts}
        taxBase={summary?.taxBase}
        taxes={summary?.taxes}
        total={summary?.total}
        admitCoupons
        onRemoveDiscount={handleRemoveDiscount}
        onSubmitDiscount={handleSubmitDiscount}
        admitPartialPayment
        isEarlyPayment={isEarlyPayment}
        onEarlyPaymentChange={handleEarlyPaymentChange}
        earlyPaymentDiscount={summary?.earlyPaymentDiscountAmt}
        walletBalance={walletBalance}
        onInstallmentsChange={onInstallmentsChange}
      />
    </>
  );
}
