import { API } from 'api';
import { ScorecardLink } from 'components';
import { Empty, Mesh, Skeleton, Utils, useRouter } from 'dawere-uic';
import { useEffect, useState } from 'react';

export function ScorecardsTab({ enrollment }: any) {
  const { program } = enrollment;
  const { query } = useRouter();

  const [isLoading, setIsLoading] = useState(true);
  const [enrollments, setEnrollments] = useState<any>(null);

  useEffect(() => {
    if (query?.studentId) {
      setIsLoading(true);

      API.Students.getAllEnrollments({ student_id: query?.studentId })
        .then((data: any = []) => {
          const grouped = Utils.groupBy(data, ({ program }: any) => program.id);
          const enrollments = Object.values(grouped)
            .map((enrollments: any) => {
              const first = Utils.first(enrollments);

              return {
                program: first.program,
                enrollments: enrollments
                  .filter((enrollment: any) => !enrollment.isTrial)
                  .map(({ program, ...rest }: any) => rest)
              };
            })
            .find(
              (enroll: any) =>
                enroll.enrollments.length && enroll.program.id === program.id
            )?.enrollments;

          setEnrollments(enrollments);
        })
        .finally(() => setIsLoading(false));
    }
  }, [program.id, query?.studentId]);

  if (isLoading) {
    return (
      <Mesh gap={3} minSize={380} className='mb-3'>
        {Array(4)
          .fill(0)
          .map((_, index) => (
            <Skeleton.Image key={index} height={160} />
          ))}
      </Mesh>
    );
  }

  return enrollments.length > 0 ? (
    <Mesh gap={3} minSize={380} className='mb-3'>
      {enrollments?.map((entry: any) => (
        <ScorecardLink
          key={enrollment.id}
          enrollment={enrollment}
          entry={entry}
        />
      ))}
    </Mesh>
  ) : (
    <Empty />
  );
}
