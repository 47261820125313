import { i18n } from 'dawere-commons';
import { Skeleton, Tab, TabList, TabPanel, Tabs } from 'dawere-uic';
import { useCallback, useEffect, useState } from 'react';
import { NumberParam, useQueryParams } from 'use-query-params';
import { QPARAMS } from 'values';
import { useStudentDashboard } from '../hooks/useStudentDashboard';
import { BillingTab } from './paymentsSubtabs/BillingTab';
import { InstallmentsTab } from './paymentsSubtabs/InstallmentsTab';
import { PaymentsTab } from './paymentsSubtabs/PaymentsTab';

export function PaymentsAndInstallmentsTab() {
  const { getOrders } = useStudentDashboard();

  const [orders, setOrders] = useState<any[]>([]);
  const [isLoadingOrders, setIsLoadingOrders] = useState(true);

  const [query, setQuery] = useQueryParams({
    [QPARAMS.Subtab]: NumberParam
  });

  const handleTabSelection = useCallback(
    (value: number) => {
      setQuery({ [QPARAMS.Subtab]: value }, 'pushIn');
    },
    [setQuery]
  );

  useEffect(() => {
    setIsLoadingOrders(true);

    getOrders()
      .then(({ data }: any) => {
        const orders = data.map((order: any) => ({
          ...order,
          label: order.number,
          value: order.id
        }));

        setOrders(orders);
      })
      .finally(() => setIsLoadingOrders(false));
  }, [getOrders]);

  if (isLoadingOrders) {
    return <Skeleton.Table columns={5} />;
  }

  return (
    <Tabs
      onSelect={handleTabSelection}
      defaultIndex={query[QPARAMS.Subtab] || 0}>
      <TabList scrollable='true' className='mb-4'>
        <Tab>{i18n('v2.pages.studentDashboard.tabs.4.tabs.1.title')}</Tab>

        {orders.length ? (
          <Tab>{i18n('v2.pages.studentDashboard.tabs.4.tabs.2.title')}</Tab>
        ) : null}

        <Tab>{i18n('v2.pages.studentDashboard.tabs.4.tabs.3.title')}</Tab>
      </TabList>

      <TabPanel>
        <PaymentsTab />
      </TabPanel>

      {orders.length ? (
        <TabPanel>
          <InstallmentsTab orders={orders} loading={isLoadingOrders} />
        </TabPanel>
      ) : null}

      <TabPanel>
        <BillingTab />
      </TabPanel>
    </Tabs>
  );
}
