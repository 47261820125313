import { Callout, Empty } from 'dawere-uic';
import { useCallback } from 'react';
import { useStudentDashboard } from '../../hooks/useStudentDashboard';
import { FinishedCoursePanel } from './FinishedCoursePanel';
import { GraduatedPanel } from './GraduatedPanel';
import { PendingPaymentPanel } from './PendingPaymentPanel';
import { PendingProfilePanel } from './PendingProfilePanel';
import { ProgramDeprecatedPanel } from './ProgramDeprecatedPanel';
import { ReEnrollmentPanel } from './ReEnrollmentPanel';
import { SubjectsPanel } from './SubjectsPanel';

export function EnrollmentPanel({ enrollment = {} }: any) {
  const { rootRoute, studentId, ignorePendingProfile } = useStudentDashboard();

  const { program, period, status } = enrollment;

  const render = useCallback(() => {
    // DEBT -> PENDIENTE DE PAGO
    // UNCOMPLETED_PROFILE -> PERFIL INCOMPLETO
    // DEPRECATED_PROGRAM -> PROGRAMA OBSOLETO
    // GRADUATED -> GRADUADO
    // ACTIVE -> CURSANDO
    // RE_ENROLLMENT -> REINSCRIPCIÓN
    // CONTINUE -> CONTINUAR PROGRAMA SIN CALENDARIO
    // FINISHED_COURSE -> CURSO FINALIZADO
    // FREE_TRIAL_ENDED -> PRUEBA GRATUITA FINALIZADA
    // FREE_TRIAL -> PRUEBA GRATUITA
    // UNKNOW -> DESCONOCIDO

    if (status === 'DEBT') {
      return <PendingPaymentPanel />;
    }

    if (status === 'UNCOMPLETED_PROFILE') {
      if (ignorePendingProfile) {
        return <SubjectsPanel enrollment={enrollment} />;
      }
      return <PendingProfilePanel />;
    }

    if (status === 'DEPRECATED_PROGRAM') {
      return <ProgramDeprecatedPanel program={program} />;
    }

    if (status === 'GRADUATED') {
      return <GraduatedPanel program={program} />;
    }

    if (status === 'RE_ENROLLMENT') {
      return (
        <ReEnrollmentPanel
          period={period}
          program={program}
          studentId={studentId}
          rootRoute={rootRoute}
        />
      );
    }

    if (status === 'FINISHED_COURSE') {
      return <FinishedCoursePanel program={program} />;
    }

    if (status === 'ACTIVE' || status === 'CONTINUE' || enrollment.isTrial) {
      return <SubjectsPanel enrollment={enrollment} />;
    }

    return (
      <Callout className='text-center border border-platinium bg-white'>
        <Empty size='small'>
          Algo salió mal. Ve a la sección de ayuda para solicitar soporte sobre
          este caso.
        </Empty>
      </Callout>
    );
  }, [
    enrollment,
    ignorePendingProfile,
    period,
    program,
    rootRoute,
    status,
    studentId
  ]);

  return render();
}
