import esUS from './es-US';

const enUS = {
  ...esUS,
  lang: `en-US,en`,
  shortLang: 'en-US',
  pages: {
    home: {
      seo: {
        title: `Your Online School in Spanish - Dawere USA`,
        description: `Dawere USA is an online school in Spanish that serves grades 7th through 12th. The school is registered in the Florida Department of Education (FLDOE)`,
        keywords: `online school in Spanish`,
        ogTitle: `Your Online School in Spanish - Dawere USA`,
        ogDescription: `Dawere USA is an online school in Spanish that serves grades 7th through 12th. The school is registered in the Florida Department of Education (FLDOE).`,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/og/Open%20Graphs_Homepage.jpg`
      },
      mainProgramId: {
        dev: `bachillerato-virtual-estados-unidos`,
        qa: `bachillerato-virtual-estados-unidos`,
        prod: `bachillerato-virtual-estados-unidos`
      },
      doubleDegreeProgramId: {
        dev: ``,
        qa: ``,
        prod: ``
      },
      info: {
        whatsappNumber: `+58 412-2318073`,
        phoneNumber: ``,
        emailInfo: `inscripciones@dawere.us`
      },
      section1: {
        subtitle: `Dawere International High School`,
        title: `Your online school in Spanish—At your pace, on your time.`
      },
      section2: {
        title: `Earn your US high school diploma`,
        text: `
          <p>Earn your high school diploma with Dawere International High School. We are registered with the Florida Department of Education (FLDOE) under number 6139 in the Miami-Dade district. We are the only online school with an American curriculum in Spanish, accredited by Cognia™.</p>
        `,
        images: {
          1: {
            url: `https://storage.googleapis.com/dawere-web-assets/Ilustraciones/COGNIA/FLDOE%20-%20COGNIA_Mesa%20de%20trabajo%201.svg`,
            alt: `Florida Department of Education`
          }
        }
      },
      section4: {
        title: `Start your free trial period`,
        text: `You can take any educational program for free for 7 days. Learn first-hand the benefits of the virtual classroom, including video classes, readings, evaluations (formative and summative) and access to forum-chats by subject and synchronous meetings with teachers. `,
        buttons: {
          enroll: `Try it free`
        }
      },
      section6: {
        steps: {
          1: {
            title: `Sign up`,
            subtitle: `STEP 1`,
            text: `If you are 15 years of age or older you can enroll in Dawere regardless of your geographic location. You only have to fill out the registration form, indicate your school years approved to date, and make your payment.`
          },
          2: {
            title: `Submit your requirements`,
            subtitle: `STEP 2`,
            text: `You must deliver a copy of your identity card and a copy of the birth certificate, among other things.`
          },
          3: {
            title: `Start studying`,
            subtitle: `STEP 3`,
            text: `In our virtual school you learn with pre-recorded video classes, PDF guides, practice tests, a forum-chat where you get your questions answered and more. Study at your own pace and from anywhere with internet access. Dawere is easy to navigate from a computer, tablet, or smartphone.`
          },
          4: {
            title: `Graduate`,
            subtitle: `STEP 4`,
            text: `After successfully finishing all your school courses, you'll attend a virtual graduation ceremony, and will receive your high school diploma.`
          }
        }
      },
      section9: {
        categories: {
          3: {
            text: `Study in a quality virtual school and earn your US high school. You will study in a fast and fun way through pre-recorded video classes, support material, practice tests, a forum-chat to clarify your doubts and more. Sign up to receive more information.`
          }
        }
      }
    },
    login: {
      seo: {
        title: `Sign in to Your Online School- All in Spanish - Dawere USA`,
        description: `Sign in to access the educational programs where you've enrolled. Keep on learning online with Dawere USA.`,
        ogTitle: `Sign in to Your Online School- All in Spanish - Dawere USA`,
        ogDescription: `Sign in to access the educational programs where you've enrolled. Keep on learning online with Dawere USA. `,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/og/Open%20Graphs_Inicio%20de%20sesio%CC%81n.jpg`
      }
    },
    register: {
      seo: {
        title: `Sign Up to Access Free High School Programs in Spanish - Dawere USA`,
        description: `Not sure if our learning model meets your needs? Sign up today to acces a 7-day free trial on a wide range of high school and middle school programs.`,
        keywords: `Free High School Programs in Spanish`,
        ogTitle: `Sign Up to Access Free High School Programs in Spanish - Dawere USA`,
        ogDescription: `Not sure if our learning model meets your needs? Sign up today to acces a 7-day free trial on a wide range of high school and middle school programs. `,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/og/Open%20Graphs_Registro.jpg`
      },
      title: `Sign Up to Access Free High School Programs in Spanish`
    },
    forgotPassword: {
      seo: {
        ogTitle: `Password Reset - Recover Access to Your Online School - Dawere USA `,
        ogDescription: `Forgot your password? Reset it and keep on the good work in Dawere USA's online school. `,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/og/Open%20Graphs_Contrasen%CC%83a.jpg`
      }
    },
    catalog: {
      seo: {
        title: `Online High School Programs in Spanish - Dawere USA`,
        description: `Explore our catalogue: 24 Credit High School, 18 Credit High School, High School for Adults, Flexible High School, Doble Diploma, and Middle School.`,
        keywords: `Online high school programs in Spanish`,
        ogTitle: `Online High School Programs in Spanish - Dawere USA`,
        ogDescription: `Explore our catalogue: 24 Credit High School (7th-12th grade), Accelerated High School (18 credits), High School for Adults, and Dual Degree Program. `,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/og/Open%20Graphs_Programas%20Educativos.jpg`
      }
    },
    organizations: {
      seo: {
        title: `Online School for Hispanics - For Organizations - Dawere USA`,
        description: `Dawere works with governments, NGOs, sport clubs, and private companies to bring quality online schooling (7th to 12th grade) to Hispanics.`,
        keywords: `Online school for Hispanics`,
        ogTitle: `Online School for Hispanics - For Organizations - Dawere USA`,
        ogDescription: `Dawere works with governments, NGOs, sport clubs, and private companies to bring quality online schooling (7th to 12th grade) to Hispanics. `,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/og/Open%20Graphs_%20Para%20Organizaciones.jpg`
      },
      section1: {
        title: `We are the online school for Hispanics | Join our virtuous circle`
      },
      section4: {
        governments: {
          text: `
            <p>
              According to UNESCO, in the traditional educational systems of Latin America, only 45% of those who start high school graduate.
              </p>
            <p>
             Every year, 15% of students drop out, and more than 30% of school-age youth are outside the traditional educational system. With the use of our educational technology, this could change.
              </p>

            <p>
              We invite you to provide all your citizens with free quality education. It seems like a utopian proposal but it can become a reality thanks to Dawere's online learning services. Our virtual educational programs have been designed to educate millions of people at a fraction of the cost of traditional face-to-face education. See for yourself and empower the country with the excellent education that everyone deserves.
              </p>
          `
        },
        companies: {
          text: `
           <p>
              The world of professional sports is extremely close, which is why only a minuscule percentage manage to become a professional player. What happens to the rest?

              </p>
            <p>
              Receiving quality education in parallel to training can guarantee a future for all your players without exception.

              </p>
            <p>
              Our Virtual Middle School and High School adapts to the rhythm and schedule of the students. With Dawere your players can train academically and pursue their dreams.
              </p>
          `
        }
      }
    },
    shared: {
      contact: {
        info: {
          phone: {
            title: `Phone number`,
            text: ``
          },
          whatsapp: {
            title: `WHATSAPP`,
            text: `+58 412-2318073`
          },
          email: {
            title: `EMAIL`,
            text: ``
          }
        },
        buttons: {
          faq: `FAQs`
        }
      }
    },
    helpCenter: {
      seo: {
        keywords: `dawere, dawere estados unidos, dawere usa, dawere eeuu`
      },
      urls: {
        scholarshipForm: `https://forms.gle/FSZeAAn24C35bMiJ9`
      },
      contact: {
        info: {
          enrolled: {
            whatsapp: `+58 412-2318073`
          },
          unregistered: {
            whatsapp: `+58 412-2318073`
          }
        }
      }
    },
    philosophy: {
      seo: {
        title: `The School's Educational Philosophy - Mission & Vision - Dawere USA`,
        description: `Our online learning model promotes students' autonomy and self-determination. The school respects the freedom to choose when, where and how to learn.`,
        keywords: `The School's Mission, Vision, and Educational Philosophy`,
        ogTitle: `The School's Educational Philosophy - Mission & Vision - Dawere USA`,
        ogDescription: `Our online learning model promotes students' autonomy and self-determination. The school respects the freedom to choose when, where and how to learn. `,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/og/Open%20Graphs_Filosofi%CC%81a%20educativa.jpg`
      }
    }
  },
  sections: {
    footer: {
      column1: {
        title: `Dawere International High School`,
        text: `This school is accredited by Cognia™ and is registered with the Florida Department of Education (FLDOE) under number 6139 and trade name Dawere International High School.`
      }
    }
  }
};

export default enUS;

export { enUS };
