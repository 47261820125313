import { i18n } from 'dawere-commons';
import { Block, Col, Flex } from 'dawere-uic';
import { ASSETS } from 'values';
import { ApplyForm } from '../forms/ApplyForm';

export function ApplySection() {
  return (
    <Block>
      <Col md={6}>
        <h3 className='mb-6'>
          {i18n('v2.pages.organizations.section2.subtitle')}
        </h3>

        <Flex gap={3} align='center' className='mb-6'>
          <img
            src={ASSETS.ICONS.REGISTER}
            alt={i18n('v2.pages.organizations.section2.benefits.1.title')}
            width={80}
            className='img-fluid mr-3'
          />
          <div>
            <h3 className='text-primary'>
              {i18n('v2.pages.organizations.section2.benefits.1.title')}
            </h3>
            <p className='m-0'>
              {i18n('v2.pages.organizations.section2.benefits.1.subtitle')}
            </p>
          </div>
        </Flex>

        <Flex gap={3} align='center' className=' mb-6'>
          <img
            src={ASSETS.ICONS.TUTORIAL}
            alt={i18n('v2.pages.organizations.section2.benefits.2.title')}
            width={80}
            className='img-fluid mr-3'
          />
          <div>
            <h3 className='text-primary'>
              {i18n('v2.pages.organizations.section2.benefits.2.title')}
            </h3>
            <p className='m-0'>
              {i18n('v2.pages.organizations.section2.benefits.2.subtitle')}
            </p>
          </div>
        </Flex>

        <Flex gap={3} align='center'>
          <img
            src={ASSETS.ICONS.ACCESS}
            alt={i18n('v2.pages.organizations.section2.benefits.3.title')}
            width={80}
            className='img-fluid mr-3'
          />
          <div>
            <h3 className='text-primary'>
              {i18n('v2.pages.organizations.section2.benefits.3.title')}
            </h3>
            <p className='m-0'>
              {i18n('v2.pages.organizations.section2.benefits.3.subtitle')}
            </p>
          </div>
        </Flex>
      </Col>

      <Col md={6} className='order-first order-md-last'>
        <h2 className='mb-5'>
          {i18n('v2.pages.organizations.section2.title')}
        </h2>

        <ApplyForm />
      </Col>
    </Block>
  );
}
