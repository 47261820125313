import { Accordion, Col, Empty, Row, Skeleton } from 'dawere-uic';
import { useCallback } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { QPARAMS } from 'values';
import { SubjectsSection } from './SubjectsSection';

export function ProgramsSection({ data, isLoading = true }: any) {
  const [query, setQuery] = useQueryParams({
    [QPARAMS.Program]: StringParam
  });

  const handleProgramSelection = useCallback(
    (value: string) => {
      setQuery({ [QPARAMS.Program]: value }, 'pushIn');
    },
    [setQuery]
  );

  const preExpanded = query[QPARAMS.Program]
    ? [query[QPARAMS.Program]]
    : data?.programs?.length
    ? ['0']
    : [];

  return (
    <Col xs={12}>
      {isLoading ? (
        <>
          <Skeleton height={50} width='100%' className='mb-4' />

          <Row>
            <Col xs={12} md={6} lg={4} className='mb-4'>
              <Skeleton.SubjectCard />
            </Col>
            <Col xs={12} md={6} lg={4} className='mb-4'>
              <Skeleton.SubjectCard />
            </Col>
            <Col xs={12} md={6} lg={4} className='mb-4'>
              <Skeleton.SubjectCard />
            </Col>
          </Row>
        </>
      ) : (
        <>
          {data?.programs?.length ? (
            data.programs.map((program: any) => (
              <Accordion
                key={program.id}
                onToggle={() => handleProgramSelection(program.id)}
                expanded={preExpanded === program.id}
                summary={program.name}>
                <SubjectsSection
                  programId={program.id}
                  subjects={program.subjects}
                />
              </Accordion>
            ))
          ) : (
            <Empty />
          )}
        </>
      )}
    </Col>
  );
}
