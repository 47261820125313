import { useQuery } from '@tanstack/react-query';
import { API } from 'api';
import { Session, i18n } from 'dawere-commons';
import { Button, Callout, Col, Row, Skeleton } from 'dawere-uic';
import { ASSETS } from 'values';
import { OpenProgramButton } from '../../components/OpenProgramButton';

export function ProgramDeprecatedPanel({ program }: any) {
  const {
    data: replacedBy,
    isLoading,
    isFetching
  } = useQuery({
    enabled: !!program?.id && !!Session.getAccountInfo(),
    queryKey: ['program-deprecated-panel', program?.id],
    queryFn: () => API.Programs.getReplacements(program?.id)
  });

  return (
    <Row>
      <Col>
        <Callout className='text-center border border-platinium bg-white'>
          <img
            src={ASSETS.ILLUSTRATIONS.ZZZ}
            alt={i18n('v2.pages.studentDashboard.tabs.1.deprecated')}
            className='img-fluid w-70 w-md-25 mb-4'
          />

          <h3>{i18n('v2.pages.studentDashboard.tabs.1.deprecated')}</h3>

          <p>{i18n('v2.pages.studentDashboard.tabs.1.chooseProgram')}</p>

          {isLoading || isFetching ? (
            <Row className='mb-3'>
              {Array(2)
                .fill({})
                .map((_, index) => (
                  <Col key={index} xs={12} md={6} offset={{ md: 3 }}>
                    <Skeleton.Title key={index} className='mb-1' />
                  </Col>
                ))}
            </Row>
          ) : (
            <Row>
              {replacedBy?.map((replacement: any, index: number) => (
                <Col key={index} xs={12} md={6} offset={{ md: 3 }}>
                  <OpenProgramButton
                    key={replacement.id}
                    program={program}
                    replacement={replacement}
                  />
                </Col>
              ))}
            </Row>
          )}

          <Button
            className='w-100 w-md-auto mb-4'
            variant='link'
            onClick={() => API.Zendesk.go()}>
            {i18n('v2.pages.studentDashboard.tabs.1.askForHelp')}
          </Button>
        </Callout>
      </Col>
    </Row>
  );
}
