import { i18n } from 'dawere-commons';
import { Button, Cart, Col, Dropdown, Empty, Row, Skeleton } from 'dawere-uic';
import { useCallback } from 'react';
import { useBuyCoupons } from '../hooks/useBuyCoupons';

export function Step1() {
  const {
    updateItem,
    total,
    currencies,
    currency,
    updateCurrency,
    categories,
    isLoadingOrganization,
    isLoadingItems
  } = useBuyCoupons();

  const handleCurrencyChange = useCallback(
    (currency: any) => {
      updateCurrency(currency);
    },
    [updateCurrency]
  );

  return (
    <>
      <h2>{i18n('v2.pages.buyCoupons.steps.1.title')}</h2>
      <p className='fs-1 mb-0'>
        {i18n('v2.pages.buyCoupons.steps.1.description')}
      </p>

      {isLoadingItems || isLoadingOrganization ? (
        <>
          <Row align='center' className='my-4'>
            <Col>
              <Skeleton.Title className='m-0' />
            </Col>
            <Col xs={4} md={2} className='text-right'>
              <Skeleton height={35} />
            </Col>
          </Row>

          <Skeleton height={80} />
          <Skeleton height={80} />
          <Skeleton height={80} className='mb-3' />

          <Row>
            <Col></Col>
            <Col xs={6} md={3} className='text-right'>
              <Skeleton.Title />
            </Col>
          </Row>
        </>
      ) : categories.length ? (
        <>
          {categories.map((category: any, index: number) => (
            <div key={category.id}>
              <Row align='center' className='mb-3 mt-4'>
                <Col xs={9}>
                  <h3 className='mb-0'>{category.title}</h3>
                </Col>

                {currencies.length && index === 0 ? (
                  <Col xs={3} className='text-right'>
                    <Dropdown
                      align='right'
                      toggle={
                        <Button variant='link' caret className='fw-500'>
                          {currency?.isoCode}
                        </Button>
                      }>
                      {currencies.map((curr: any) => (
                        <Dropdown.Item
                          key={curr.id}
                          active={currency?.id === curr.id}
                          onClick={() => handleCurrencyChange(curr)}>
                          {curr.isoCode}
                        </Dropdown.Item>
                      ))}
                    </Dropdown>
                  </Col>
                ) : null}
              </Row>

              {category.subcategories.map((item: any) => (
                <Cart.Item
                  key={item.id}
                  id={item.id}
                  price={item.price}
                  quantity={item.quantity}
                  currency={currency?.symbol}
                  title={item.title}
                  onChange={updateItem}
                />
              ))}
            </div>
          ))}

          <Cart.Total total={total} currency={currency?.symbol} />
        </>
      ) : (
        <Empty />
      )}
    </>
  );
}
