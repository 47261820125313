import { i18n, Rest } from 'dawere-commons';
import { Utils } from 'dawere-uic';

function _getAttempts(evaluationId: string, enrollmentId: string) {
  return Rest.get('classroom/attempts', {
    params: {
      enrolment_id: enrollmentId,
      evaluation_id: evaluationId
    }
  }).then(({ data: attempts }: any) => {
    let totalTaken = 0;

    attempts.forEach((attempt: any) => {
      if (Utils.has(attempt, 'correctedScore')) {
        attempt.oldScore = attempt.weightedScore;
        attempt.weightedScore = attempt.correctedScore;
      }

      attempt.isTaken =
        attempt.status === 'OVERDUE' ||
        attempt.status === 'TAKEN' ||
        attempt.status === 'EVALUATED';
      attempt.date =
        attempt.status === 'OVERDUE' ? attempt.dueDate : attempt.takenAt;
      attempt.inProgress = attempt.status === 'IN_PROGRESS';

      if (attempt.isTaken) {
        totalTaken++;
      }
    });

    const next = attempts.find((attempt: any) => !attempt.isTaken);
    const best = attempts
      .filter(
        (attempt: any) => attempt.isTaken || attempt.status === 'COEVALUATED'
      )
      .sort((a: any, b: any) => b.weightedScore - a.weightedScore);

    return {
      total: attempts.length,
      totalTaken,
      number: next ? next.attemptNumber : attempts.length,
      minGrade: attempts.length > 0 ? attempts[0].scoreSystem.passingScore : 0,
      maxGrade: attempts.length > 0 ? attempts[0].scoreSystem.maxScore : 0,
      next: next ? next : attempts[attempts.length - 1],
      best: best.length > 0 ? best[0] : null,
      list: attempts,
      closed: attempts.every((attempt: any) => attempt.isTaken),
      nextIsActive: next ? next.status === 'AVAILABLE' : false,
      nextInProgress: next ? next.status === 'IN_PROGRESS' : false
    };
  });
}

function getSyllabus(enrollmentId: string, subjectId: string) {
  return Rest.get('classroom/syllabus', {
    params: {
      subject_id: subjectId,
      enrolment_id: enrollmentId
    }
  }).then(({ data = [] }: any) =>
    createSyllabus(data, subjectId, enrollmentId)
  );
}

function markItemAsSeen(elementId: string, enrollmentId: string) {
  return Rest.post('classroom/seen', { elementId, enrolmentId: enrollmentId });
}

function markProjectInstructionAsRead(attemptId: string) {
  return Rest.post(`classroom/projects/attempts/${attemptId}/seen`);
}

function submitProject(attemptId: string, payload: any = {}) {
  return Rest.post(`classroom/projects/attempts/${attemptId}/submit`, payload);
}

function getTopicProgress(enrollmentId: string, topicId: string) {
  return Rest.get('classroom/preparation', {
    params: {
      enrolment_id: enrollmentId,
      topic_id: topicId
    }
  });
}

function getScores(subjectId: string, enrollmentId: string) {
  return Rest.get('classroom/scores', {
    params: {
      subject_id: subjectId,
      enrolment_id: enrollmentId
    }
  });
}

function getDelayStatus(suggestedDate?: string, checked = false) {
  const now = new Date();

  if (checked) {
    return 'ON_TIME';
  }

  if (suggestedDate) {
    suggestedDate = suggestedDate.includes('T')
      ? suggestedDate
      : `${suggestedDate}T23:59:59.999Z`;
  }

  const isLate = Utils.isString(suggestedDate)
    ? Utils.isDateAfter(now, suggestedDate)
    : false;
  const isToday = Utils.isString(suggestedDate)
    ? Utils.isToday(suggestedDate)
    : false;

  if (isToday) {
    return 'TODAY';
  }

  if (isLate) {
    return 'LATE';
  }

  return 'ON_TIME';
}

function createSyllabus(data: any[], subjectId: string, enrollmentId: string) {
  let topicNumber = 1;
  let pisaEvaluationNumber = 1;
  let projectEvaluationNumber = 1;
  const topics: any = {};

  const ret = data.map((element: any) => {
    const isTopic = Utils.has(element, 'topic');
    const isEvaluation = Utils.has(element, 'evaluation');

    let videoNumber = 1;
    let lectureNumber = 1;
    let evaluationNumber = 1;

    // Topics
    if (isTopic) {
      element.type = 'TOPIC';
      element.topic.content = element.topic.content || [];
      element.totalSeen = 0;
      element.topic.totalElements = element.topic.content.length;
      element.totalDuration = 0;

      element.topic.content.forEach((content: any, index: number) => {
        const isFirst = index === 0;
        const isLast = index === element.topic.content.length - 1;

        const isVideo = content.mediaType === 'VIDEO';
        const isLecture = content.mediaType === 'PDF';
        const isEvaluation = content.type === 'test';

        content.subjectId = subjectId;
        content.enrollmentId = enrollmentId;
        content.duration = content.duration || 0;
        element.totalDuration += content.duration;
        content.isFirst = isFirst;
        content.isLast = isLast;
        content.index = index;
        content.parentId = element.id;
        content.topic = {
          id: element.topic.id,
          title: element.topic.title,
          number: topicNumber
        };

        content.delayStatus = getDelayStatus(
          content.suggestedDate,
          content.seen
        );
        content.suggestedDate = Utils.isString(content.suggestedDate)
          ? content.suggestedDate.includes('T')
            ? content.suggestedDate
            : `${content.suggestedDate}T23:59:59.999Z`
          : undefined;

        if (!isFirst) {
          content.previous = element.topic.content[index - 1];
        }

        if (!isLast) {
          content.next = element.topic.content[index + 1];
        }

        if (isVideo) {
          element.totalSeen += content.seen ? 1 : 0;
          content.subtype = 'VIDEO';
          content.sequential = videoNumber++;
          content.preffix = i18n('v2.pages.classroom.VIDEO', {
            number: content.sequential
          });
        }
        if (isLecture) {
          element.totalSeen += content.seen ? 1 : 0;
          content.subtype = 'LECTURE';
          content.sequential = lectureNumber++;
          content.preffix = i18n('v2.pages.classroom.LECTURE', {
            number: content.sequential
          });
        }
        if (isEvaluation) {
          content.score = element.topic.preparationLevel;
          element.totalSeen += content.score >= 75 ? 1 : 0;
          content.subtype = 'FORMATIVE';
          content.sequential = evaluationNumber++;
          content.preffix = i18n('v2.pages.classroom.FORMATIVE');
        }
      });

      topics[element.topic.id] = topicNumber;

      // Delay status
      element.isToday = element.topic.content.find(
        (cont: any) => cont.delayStatus === 'TODAY'
      );
      element.isLate = element.topic.content.find(
        (cont: any) => cont.delayStatus === 'LATE'
      );

      return {
        ...element,
        sequential: topicNumber++
      };
    }

    // Pisa and projects evaluation
    if (isEvaluation) {
      element.type = 'EVALUATION';
      element.id = element.evaluation.id;
      element.parentId = element.evaluation.id;
      element.subjectId = subjectId;
      element.enrollmentId = enrollmentId;
      element.suggestedDate = Utils.isString(element.suggestedDate)
        ? element.suggestedDate
        : undefined;

      if (element.evaluation.type === 'test') {
        element.subtype = 'PISA';
        return {
          ...element,
          sequential: pisaEvaluationNumber++
        };
      } else {
        element.subtype = 'PROJECT';
        return {
          ...element,
          number: projectEvaluationNumber++,
          sequential: projectEvaluationNumber
        };
      }
    }

    return {
      ...element
    };
  });

  // Get topics numbers (index)
  const promises: any[] = [];

  ret
    .filter(({ type }: any) => type === 'EVALUATION')
    .forEach((element: any) => {
      element.evaluation.topics.forEach((topic: any) => {
        topic.number = topics[topic.id] || NaN;
      });

      // Sort topics list
      element.evaluation.topics.sort((a: any, b: any) => a.number - b.number);

      promises.push(_getAttempts(element.evaluation.id, enrollmentId));
    });

  return Promise.all(promises).then((attempts: any[]) => {
    ret
      .filter(({ type }: any) => type === 'EVALUATION')
      .forEach((element: any, index: number) => {
        element.attempts = attempts[index];
      });

    return ret;
  });
}

function changeSchedule(enrolmentId: string, payload: any = {}) {
  return Rest.put(`enrolments/${enrolmentId}/schedules`, payload);
}

export const Classroom = {
  getSyllabus,
  markItemAsSeen,
  getTopicProgress,
  getScores,
  createSyllabus,
  markProjectInstructionAsRead,
  submitProject,
  changeSchedule,
  getDelayStatus
};
