import { API } from 'api';
import { i18n, Session } from 'dawere-commons';
import { Block, Col, Header, useRouter } from 'dawere-uic';
import { useSetDefaultDashboard } from 'hooks';
import { PageWrapper } from 'pages';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { getLoginRedirectPath, getSEO } from 'utils';
import { ProgramsSection } from './sections/ProgramsSection';

/**
 * Path: /panel/profesor/:organizationId
 * Key: TEACHERS_DASHBOARD
 * @returns {React.ReactElement}
 */
export default function TeachersDashboardPage() {
  const { query, push } = useRouter();
  const organizationId = query?.organizationId;
  useSetDefaultDashboard('ORGANIZATION', organizationId);

  const account = Session.getProfiles();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (organizationId) {
      setIsLoading(true);

      API.Organizations.Teachers.getSubjects(organizationId)
        .then((data: any) => {
          console.log(data);
          setData(data);
        })
        .catch(() => {
          push(PATHS.MY_ORGANIZATIONS);
        })
        .finally(() => setIsLoading(false));
    }
  }, [push, organizationId]);

  console.log(data);

  if (!account?.organization) {
    const path = getLoginRedirectPath();
    return <Redirect to={path} />;
  }

  return (
    <PageWrapper>
      {getSEO('v2.pages.teacherDashboard.seo', true)}
      <Block narrow>
        {/* Titulo y avatar */}
        <Col xs={12} className='mb-2'>
          <Header
            as={Link}
            loading={isLoading}
            src={data?.organization?.logo}
            title={i18n('v2.pages.teacherDashboard.welcome', {
              name: data?.organization?.name
            })}
            subtext={[i18n(`v2.pages.teacherDashboard.title`)]}
            routes={[
              {
                breadcrumb: i18n('v2.pages.teacherDashboard.breadcrumbs.1'),
                to: PATHS.MY_ORGANIZATIONS
              },
              {
                breadcrumb: i18n('v2.pages.teacherDashboard.breadcrumbs.2')
              }
            ]}
          />

          {!isLoading && (
            <p>
              {i18n(
                `v2.pages.teacherDashboard.description${
                  data?.programs?.length ? '' : 'Empty'
                }`
              )}
            </p>
          )}
        </Col>

        {/* Listado de programas */}
        <ProgramsSection data={data} isLoading={isLoading} />
      </Block>
    </PageWrapper>
  );
}
