import { Locales, Rest, Session } from 'dawere-commons';

function signup(data: any, type: 'regular' | 'google' = 'regular') {
  return type === 'regular'
    ? Rest.post('sign-up', data, {
        recaptchaAction: 'signup'
      })
    : Rest.post('auth/google', data, { recaptchaAction: 'authGoogle' });
}

function referrals(referredBy: string) {
  return Rest.post('referrals', {
    referredBy: { id: referredBy }
  }).then(({ data }: any) => data);
}

function hubspotSignupTrack(
  email: string,
  name: string,
  lastName: string,
  iso3: 'USA' | 'DOM' | 'VEN' | 'COL' | 'MEX' | 'HND'
) {
  const HUBSPOT_PORTAL = '3965081';
  const HUBSPOT_FORMS = {
    COL: '1d6d080f-57f8-4c2b-9ae5-b71a7b124721',
    DOM: 'e7aba65c-f6b0-4562-b112-2aa13f0c0c27',
    HND: 'dd5a1f9f-2223-4c3f-90a0-c7a09c16dc11',
    MEX: '9a6b57ef-d17f-42eb-80d8-8c793febddae',
    USA: 'bddd7ea2-ed11-4203-b430-59372da5821d',
    VEN: 'faf78588-6cd8-4c44-b98b-11dcd869ad76'
  };

  const n = encodeURIComponent(name);
  const l = encodeURIComponent(lastName);
  const e = encodeURIComponent(email);

  Rest.post(
    `https://forms.hubspot.com/uploads/form/v2/${HUBSPOT_PORTAL}/${HUBSPOT_FORMS[iso3]}`,
    `email=${e}&firstname=${n}&lastname=${l}`,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
  );
}

function hubspotDemoTrack({
  fullName,
  email,
  phoneNumber,
  organizationName,
  role,
  summary
}: {
  fullName: string;
  email: string;
  phoneNumber: string;
  organizationName: string;
  role: string;
  summary: string;
}) {
  const HUBSPOT_PORTAL = '3965081';
  const HUBSPOT_FORM = 'afa94bba-4a02-408a-8b9a-da089645f4b4';

  const n = encodeURIComponent(fullName);
  const e = encodeURIComponent(email);
  const p = encodeURIComponent(phoneNumber);
  const c = encodeURIComponent(organizationName);
  const j = encodeURIComponent(role);
  const m = encodeURIComponent(summary);

  Rest.post(
    `https://forms.hubspot.com/uploads/form/v2/${HUBSPOT_PORTAL}/${HUBSPOT_FORM}`,
    `nombre_completo=${n}&email=${e}&phone=${p}&company=${c}&jobtitle=${j}&message=${m}`,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
  );
}

function login(data: any, type: 'regular' | 'google' = 'regular') {
  return type === 'regular'
    ? Rest.post('auth', data, {
        recaptchaAction: 'auth'
      })
    : Rest.post(
        'auth/google',
        { token: data },
        { recaptchaAction: 'authGoogle' }
      );
}

function refreshToken() {
  return Rest.post('refresh-token', {});
}

function me() {
  return Rest.get('me');
}

function updateMe(body: any = {}) {
  return Rest.patch('me', body);
}

function updateProfile(data: any) {
  return Rest.put('me/profile', data);
}

function verifyPhoneNumber(data: any) {
  return Rest.post('me/verify-phone-number', data);
}

function resendOTP(params: any = {}) {
  return Rest.get('me/otp', { params });
}

function uploadPicture(data: any) {
  return Rest.put('me/pictures', data);
}

function getDefaultDashboard() {
  return Rest.get('me/default-dashboard');
}

function setDefaultDashboard(data: any) {
  return Rest.put('me/default-dashboard', data);
}

function forgotPassword(data: any) {
  return Rest.post('forgot-password', data, {
    recaptchaAction: 'forgotPassword'
  });
}

function resendVerification(email: string) {
  return Rest.post(
    'public/resend-verification',
    { email },
    {
      recaptchaAction: 'resendVerification'
    }
  );
}

function verifyEmail(token: string, email: string) {
  return Rest.get('verify', { params: { token, email } });
}

function resetPassword(newPassword: string, resetToken: string) {
  return Rest.post(
    'reset-password',
    {
      newPassword,
      resetToken
    },
    {
      recaptchaAction: 'resetPassword'
    }
  );
}

function changePassword(oldPassword: string, newPassword: string) {
  return Rest.post('me/password', {
    oldPassword,
    newPassword
  });
}

function validateToken(token: string) {
  return Rest.get('token', {
    params: { token }
  });
}

function getBillingInfo() {
  return Rest.get('me/billing').then(({ data }: any) => data?.userDetails);
}

function updateBillingInfo(data: any) {
  return Rest.put('me/billing', data);
}

function getOptOut() {
  return Rest.get('me/opt-out').then(({ data }: any) => ({
    mailSuppressed: !data?.mailSuppressed,
    smsSuppressed: !data?.smsSuppressed
  }));
}

function updateOptOut(data: any) {
  return Rest.put('me/opt-out', {
    mailSuppressed: !data?.mailSuppressed,
    smsSuppressed: !data?.smsSuppressed
  });
}

function setDefaultCurrency(id: string) {
  return Rest.post(`me/default-currency/${id}`);
}

function getMyAuthorizedOrganizations() {
  return Rest.get('me/organizations');
}

function updateLocale() {
  if (Session.getToken()) {
    const locale = Locales.getLocale();
    return Rest.post('me/locale', { locale });
  } else {
    return new Promise((resolve) => {
      resolve(null);
    });
  }
}

function completeTour(tourName: 'STUDENT_DASHBOARD_TOUR' | 'SYLLABUS_TOUR') {
  return Rest.post(`me/tours/${tourName}`);
}

function deleteAccount() {
  return Rest.delete<void>('me');
}

export const Account = {
  login,
  refreshToken,
  signup,
  me,
  updateMe,
  updateProfile,
  verifyPhoneNumber,
  resendOTP,
  uploadPicture,
  getDefaultDashboard,
  setDefaultDashboard,
  forgotPassword,
  resendVerification,
  verifyEmail,
  validateToken,
  resetPassword,
  changePassword,
  getBillingInfo,
  updateBillingInfo,
  getOptOut,
  updateOptOut,
  setDefaultCurrency,
  getMyAuthorizedOrganizations,
  updateLocale,
  completeTour,
  hubspotSignupTrack,
  hubspotDemoTrack,
  deleteAccount,
  referrals
};
