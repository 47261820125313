import { i18n } from 'dawere-commons';
import { PageWrapper, TwoColumnTemplate } from 'pages';
import { getSEO } from 'utils';
import { ASSETS } from 'values';
import { SignInForm } from './forms/SignInForm';

/**
 * Path: /inicio-sesion
 * Key: LOGIN
 * @returns {React.ReactElement}
 */
export default function SignInPage() {
  return (
    <PageWrapper>
      {getSEO('v2.pages.login.seo')}

      <TwoColumnTemplate
        mobileImage={
          <img
            src={ASSETS.ILLUSTRATIONS.PROFILE}
            alt={i18n('v2.pages.login.title')}
            className='img-fluid w-60'
          />
        }
        desktopImage={
          <img
            src={ASSETS.ILLUSTRATIONS.GUY_LAPTOP}
            alt={i18n('v2.pages.login.title')}
            className='img-fluid'
          />
        }>
        <h1 className='mb-4 text-center h3'>{i18n('v2.pages.login.title')}</h1>
        <SignInForm />
      </TwoColumnTemplate>
    </PageWrapper>
  );
}
