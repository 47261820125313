import { API } from 'api';
import { OrderBox } from 'components';
import { i18n } from 'dawere-commons';
import { Button, Callout, Sider, Utils } from 'dawere-uic';
import { useEffect, useState } from 'react';

export function ModalOrderDetails({
  payment,
  open,
  onClose = () => {},
  sourceId,
  type
}: any) {
  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState<any>(null);

  useEffect(() => {
    if (payment?.id && open) {
      setIsLoading(true);

      API.Payments.getMyPaymentDetails(payment.id, sourceId, type)
        .then(({ data }: any) => {
          setDetails(data);
        })
        .finally(() => setIsLoading(false));
    }
  }, [open, payment?.id, sourceId, type]);

  if (!open) {
    return null;
  }

  const title =
    payment?.status === 'FAILED' || payment?.status === 'REJECTED'
      ? i18n('v2.pages.organizationDashboard.tabs.4.modal.failureTitle')
      : i18n('v2.pages.organizationDashboard.tabs.4.modal.successTitle', {
          code: payment?.number
        });

  return (
    <Sider
      position='right'
      open={open}
      onClose={() => onClose(null)}
      // type={payment?.status !== 'REJECTED' ? 'info' : 'warning'}
      header={title}>
      {/* Fail */}
      {payment?.status === 'FAILED' || payment?.status === 'REJECTED' ? (
        <>
          <p>
            {i18n(
              'v2.pages.organizationDashboard.tabs.4.modal.failureDescription',
              {
                code: payment?.number
              }
            )}
          </p>

          <Callout
            variant='warning'
            className={payment?.studentRejectNotes ? 'mb-2' : 'mb-4'}>
            <p>{payment?.rejectCause?.itemValue || '-'}</p>
          </Callout>

          {payment?.studentRejectNotes ? (
            <Callout variant='warning' className='mb-4'>
              <p>{payment?.studentRejectNotes || '-'}</p>
            </Callout>
          ) : null}
        </>
      ) : null}

      {/* Billing info */}
      <p className='fs-1'>
        {i18n('v2.pages.organizationDashboard.tabs.4.modal.billingTitle')}
      </p>

      <p className='mb-1'>
        <strong>{i18n('v2.pages.editOrganization.tabs.1.form.name')}: </strong>
        {details?.billTo}
      </p>
      {details?.tin ? (
        <p className='mb-1'>
          <strong>{i18n('v2.pages.editOrganization.tabs.1.form.tin')}: </strong>
          {details?.tin}
        </p>
      ) : null}
      {details?.billingCountry?.name ? (
        <p className='mb-1'>
          <strong>
            {i18n('v2.pages.editOrganization.tabs.1.form.country')}:{' '}
          </strong>
          {details?.billingCountry?.name}
        </p>
      ) : null}
      {details?.billingState?.name ? (
        <p className='mb-1'>
          <strong>
            {i18n('v2.pages.editOrganization.tabs.1.form.state')}:{' '}
          </strong>
          {details?.billingState?.name}
        </p>
      ) : null}
      {details?.billingTownship?.name ? (
        <p className='mb-1'>
          <strong>
            {i18n('v2.pages.editOrganization.tabs.1.form.township')}:{' '}
          </strong>
          {details?.billingTownship?.name}
        </p>
      ) : null}
      {details?.billingAddress ? (
        <p className='mb-1'>
          <strong>
            {i18n('v2.pages.editOrganization.tabs.1.form.billingAddress')}:{' '}
          </strong>
          {details?.billingAddress}
        </p>
      ) : null}
      {details?.billingPostalCode ? (
        <p className='mb-0'>
          <strong>
            {i18n('v2.pages.editOrganization.tabs.1.form.postalCode')}:{' '}
          </strong>
          {details?.billingPostalCode}
        </p>
      ) : null}

      {/* <Divider className='my-3' /> */}

      {/* Payment methods */}
      {payment?.gateway || payment?.paymentMethod === 'WALLET' ? (
        <Callout variant='powderTint' className='my-3'>
          <p className='mb-0'>
            <strong>
              {i18n(
                'v2.pages.organizationDashboard.tabs.4.modal.paymentMethod'
              )}
              :{' '}
            </strong>
            {i18n(
              `v2.pages.organizationDashboard.tabs.4.modal.${
                payment?.paymentMethod === 'WALLET'
                  ? 'WALLET'
                  : payment?.gateway
              }`
            )}
          </p>
          {payment?.gateway === 'STRIPE' ? (
            <>
              <p className='my-1'>
                <strong>
                  {i18n('v2.pages.organizationDashboard.tabs.4.modal.brand')}:{' '}
                </strong>
                {Utils.titleize(payment?.brand)}
              </p>
              <p className='mt-1'>
                <strong>
                  {i18n(
                    'v2.pages.organizationDashboard.tabs.4.modal.last4digits'
                  )}
                  :{' '}
                </strong>
                **** **** **** {payment?.last4digits}
              </p>
            </>
          ) : null}
          {payment?.gateway === 'TRANSFER' ? (
            <>
              <p className='my-1'>
                <strong>
                  {i18n('v2.pages.organizationDashboard.tabs.4.modal.bank')}:{' '}
                </strong>
                {payment?.bank?.name}
              </p>
              <p className='mb-1'>
                <strong>
                  {i18n(
                    'v2.pages.organizationDashboard.tabs.4.modal.transferDate'
                  )}
                  :{' '}
                </strong>
                {Utils.formatDate(payment?.transferDate, 'dd/mmm/yyyy')}
              </p>
              <p className='mb-1'>
                <strong>
                  {i18n(
                    'v2.pages.organizationDashboard.tabs.4.modal.reference'
                  )}
                  :{' '}
                </strong>
                {payment?.reference}
              </p>
              <p className='mb-1'>
                <strong>
                  {i18n('v2.pages.organizationDashboard.tabs.4.modal.payer')}:{' '}
                </strong>
                {payment?.payer || '-'}
              </p>
              <p className='mt-1'>
                <strong>
                  {i18n('v2.pages.organizationDashboard.tabs.4.modal.amount')}:{' '}
                </strong>
                {payment?.currency?.symbol}
                {Utils.toCurrency(payment?.amount)}
              </p>
            </>
          ) : null}
        </Callout>
      ) : null}

      {/* <Divider className='my-3' /> */}

      {/* payment */}
      <OrderBox
        loading={isLoading}
        currency={details?.currency}
        lines={details?.lines}
        discounts={details?.discounts}
        taxBase={details?.taxBase}
        taxes={details?.taxes}
        total={details?.total}
        earlyPaymentDiscount={details?.earlyPaymentDiscountAmt}
        detail
        totalLabel='Total'
        totalDiscounts={details?.discountAmt}
      />

      {payment?.status === 'FAILED' || payment?.status === 'REJECTED' ? (
        <>
          <p className='mt-4'>
            {i18n('v2.pages.organizationDashboard.tabs.4.modal.footnote')}
          </p>

          <Button block onClick={() => API.Zendesk.go()}>
            {i18n('v2.pages.organizationDashboard.tabs.4.modal.button')}
          </Button>
        </>
      ) : null}
    </Sider>
  );
}
