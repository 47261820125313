import { API } from 'api';
import { i18n } from 'dawere-commons';
import {
  Block,
  Button,
  Col,
  Empty,
  Flex,
  Pagination,
  Row,
  Search,
  Skeleton,
  useRouter,
  Visible
} from 'dawere-uic';
import { PageWrapper } from 'pages';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'router';
import { getDashboardPath, getSEO } from 'utils';
import { OrganizationCard } from './components/OrganizationCard';

/**
 * Path: /mis-organizaciones
 * Key: MY_ORGANIZATIONS
 * @returns {React.ReactElement}
 */
export default function MyOrganizationsPage() {
  const { push } = useRouter();

  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    setIsLoading(true);

    API.Organizations.list({
      page: pageIndex,
      size: pageSize,
      keyword: keyword
    })
      .then((response) => {
        setData(response.content);
        setPageCount(response.totalPages);
        setTotal(response.totalElements);
      })
      .finally(() => setIsLoading(false));
  }, [keyword, pageIndex, pageSize]);

  const handlePaging = useCallback(({ pageSize, pageIndex }: any) => {
    setPageSize(pageSize);
    setPageIndex(pageIndex);
  }, []);

  const handleSearch = useCallback((data: any) => {
    setKeyword(() => {
      setPageIndex(0);
      return data.search;
    });
  }, []);

  const handleClick = useCallback(
    (organization: any) => {
      const path = getDashboardPath(organization);

      push(path);
    },
    [push]
  );

  return (
    <PageWrapper>
      {getSEO('v2.pages.myOrganizations.seo', true)}

      <Block narrow>
        <Col xs={12}>
          <h3 className='mb-4'>{i18n('v2.pages.myOrganizations.title')}</h3>
        </Col>

        <Col>
          <Visible xs sm>
            <Button
              className='mb-2'
              block
              disabled={isLoading}
              as={Link}
              to={PATHS.CREATE_ORGANIZATIONS}>
              {i18n('v2.pages.myOrganizations.buttons.add')}
            </Button>

            <Search
              name='search'
              allowEmptySearch
              value={keyword}
              onSubmit={handleSearch}
              disabled={isLoading}
            />
          </Visible>

          <Visible md lg xl>
            <Flex align='center' className='mb-2' justify='end'>
              <Button
                disabled={isLoading}
                as={Link}
                to={PATHS.CREATE_ORGANIZATIONS}>
                {i18n('v2.pages.myOrganizations.buttons.add')}
              </Button>

              <Search
                name='search'
                className='ml-2 mb-0'
                allowEmptySearch
                value={keyword}
                onSubmit={handleSearch}
                disabled={isLoading}
              />
            </Flex>
          </Visible>

          {isLoading ? (
            <>
              {Array(5)
                .fill(0)
                .map((_, index) => (
                  <Skeleton height={80} key={index} className='mb-2' />
                ))}
            </>
          ) : (
            <>
              {data.length ? (
                <>
                  {data.map((item) => (
                    <OrganizationCard
                      key={item.id}
                      item={item}
                      onClick={handleClick}
                    />
                  ))}
                </>
              ) : (
                <Empty />
              )}
            </>
          )}

          <Row>
            <Visible lg xl>
              <Col xs={3} />
            </Visible>

            <Col>
              <Pagination
                align='center'
                className='my-4'
                onUpdate={handlePaging}
                pageIndex={pageIndex}
                pageCount={pageCount}
                disabled={isLoading}
              />
            </Col>

            <Visible md lg xl>
              <Col
                xs={3}
                className='d-flex align-items-center justify-content-end'>
                {isLoading ? (
                  <Skeleton width={120} wrapper='small' />
                ) : (
                  <small>
                    <strong>{total}</strong>{' '}
                    {i18n('v2.pages.myOrganizations.pagination')}
                  </small>
                )}
              </Col>
            </Visible>
          </Row>
        </Col>
      </Block>
    </PageWrapper>
  );
}
