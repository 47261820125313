const esHN = {
  lang: `es-HN,es`,
  shortLang: 'es-HN',
  pages: {
    home: {
      seo: {
        title: `Dawere Honduras | Escuela Virtual de Calidad`,
        description: `Educación prémium, asequible y avalada por la Secretaría de Educación de Honduras. continúa tus estudios con la mejor educación virtual de América Latina.`,
        keywords: `escuela`,
        ogTitle: `Dawere Honduras | Escuela Virtual de Calidad`,
        ogDescription: `Educación prémium, asequible y avalada por la Secretaría de Educación de Honduras. Continúa tus estudios con la mejor educación virtual de América Latina.  `,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/seo_homepage.jpg`
      },
      mainProgramId: {
        dev: `bachillerato-virtual-honduras`,
        qa: `bachillerato-virtual-honduras`,
        prod: `bachillerato-virtual-honduras`
      },
      doubleDegreeProgramId: {
        dev: `bachillerato-virtual-estados-unidos`,
        qa: `bachillerato-virtual-estados-unidos`,
        prod: `bachillerato-virtual-estados-unidos`
      },
      info: {
        whatsappNumber: `+504 3186-0773`,
        phoneNumber: ``,
        emailInfo: `inscripciones@dawere.com.hn`
      },
      section1: {
        subtitle: `Escuela: Dawere Honduras`
      },
      section2: {
        title: `El título de bachiller es avalado por la SE`,
        images: {
          1: {
            url: `https://storage.googleapis.com/dawere-web-assets/logotipos/SE-HDN.png`,
            alt: `Secretaría de Educación de Honduras`
          }
        },
        text: `
          <p>Obtén tu título de bachiller avalado por la Secretaría de Educación de Honduras. </p>
        `
      },

      // La plataforma de educación formal online Dawere y el Centro de Enseñanza Milenium  han formado una alianza educativa para traerte el mejor Bachillerato Virtual.
      // El Centro de Enseñanza Milenium  está registrado ante la Secretaría de Educación de Honduras bajo el acuerdo No. 15278-SE-2017.
      section3: {
        benefits: {
          3: {
            description: `El bachillerato puede ser cursado en 5 años o menos`
          }
        }
      },
      section6: {
        steps: {
          1: {
            text: ` Te puedes inscribir en Dawere sin importar tu ubicación geográfica. Solo debes llenar el formulario de inscripción, señalar los años aprobados hasta la fecha y realizar tu pago.`
          },
          2: {
            title: `Sube tus requisitos`,
            subtitle: `PASO 2`,
            text: `Debes subir de forma digital tu partida de nacimiento y certificado de estudios, entre otros recaudos.`
          },
          3: {
            title: `Estudia`,
            subtitle: `PASO 3`,
            text: `En nuestra escuela virtual aprendes con videoclases pregrabadas, guías PDF, evaluaciones de práctica, foro-chats para aclarar dudas y más. Estudia a tu ritmo, en tu horario y desde cualquier lugar con acceso a internet. Dawere es fácil de navegar desde una computadora, tablet o teléfono inteligente.`
          },
          4: {
            title: `Gradúate`,
            subtitle: `PASO 4`,
            text: `Al aprobar todas las materias de rigor y asistir a tu ceremonia de graduación virtual, podrás retirar tu título en el Centro de Enseñanzas Milenium  o solicitar que se te envíe hasta la puerta de tu casa.`
          }
        },
        buttons: {
          moreInfo: `Más información`
        }
      },
      section9: {
        categories: {
          3: {
            text: `Estudia en una escuela virtual de calidad y obtén tu título de bachiller avalado por la SE. Estudiarás de forma rápida y divertida a través de videoclases pregrabadas, material de apoyo, evaluaciones de práctica, un foro-chat para aclarar tus dudas y más. Regístrate para recibir más información.`
          }
        }
      },
      section12: {
        text: `Dawere tiene una variedad de programas educativos y cursos además del programa de Bachillerato Virtual. Entra en nuestro catálogo y explora nuestra oferta.`
      }
    },
    catalog: {
      seo: {
        title: `Programas Educativos de Honduras | Registrados en la SE`,
        description: `Explora el catálogo de programas educativos y descubre las bondades de una educación virtual que se ajusta a tu ritmo y horario. Ofrecemos becas a quienes califiquen. `,
        keywords: `SE, Secretaria de Educacion, educacion en honduras, educacion virtual`,
        ogTitle: `Programas Educativos de Honduras | Registrados en la SE`,
        ogDescription: `Explora el catálogo de programas educativos y descubre las bondades de una educación virtual que se ajusta a tu ritmo y horario. Ofrecemos becas a quienes califiquen.  `,
        ogImage: `https://storage.googleapis.com/dawere-web-assets/seo_jpg_small/seo_logo_secretari%CC%81aeducacio%CC%81n_colombia.jpg`
      }
    },
    organizations: {
      seo: {
        title: `Dawere Honduras | Para Organizaciones`,
        keywords: `organizaciones, sistema educativo, fundacione`,
        ogTitle: `Dawere Honduras para Organizaciones`
      },
      section4: {
        governments: {
          text: `
             <p>
              Según la UNESCO, en los sistemas educativos tradicionales de América Latina, solo 45% de los que inician el bachillerato se gradúan.
              </p>
            <p>
              Hay una deserción del 15% de los estudiantes todos los años y más del 30% de los jóvenes en edad escolar están fuera del sistema educativo tradicional. Con el uso de nuestra tecnología educativa, esto puede cambiar.
              </p>

            <p>
              Te invitamos a proveerle a todos tus ciudadanos educación de calidad gratuita. Parece una propuesta utópica pero se puede convertir en realidad gracias a los servicios de educación a distancia de Dawere. Nuestro Bachillerato Virtual ha sido diseñado para educar a millones de personas a una fracción del costo de la educación tradicional presencial. Compruébalo por ti mismo y empodera al país con la excelente educación que todos merecen.
              </p>
          `
        },
        companies: {
          text: `
            <p>
              El mundo del deporte profesional es extremadamente reñido, por lo que solo un porcentaje minúsculo logra convertirse en jugador profesional. ¿Qué ocurre con el resto?

              </p>
            <p>
              Recibir educación de calidad en paralelo a los entrenamientos puede garantizarle un futuro a todos tus jugadores sin excepción.

              </p>
            <p>
              Nuestro Bachillerato Virtual se adapta al ritmo y horario de los estudiantes. Con Dawere tus jugadores pueden formarse académicamente y perseguir sus sueños.
              </p>
          `
        }
      }
    },
    shared: {
      contact: {
        info: {
          phone: {
            title: `TELÉFONO`,
            text: ``
          },
          whatsapp: {
            title: `WHATSAPP`,
            text: `+504 3186-0773`
          },
          email: {
            title: `EMAIL`,
            text: ``
          }
        },
        buttons: {
          faq: `Preguntas frecuentes`
        }
      }
    },
    helpCenter: {
      seo: {
        keywords: `dawere, dawere honduras`
      },
      urls: {
        scholarshipForm: `https://forms.gle/XMEiQwH55Pma54aZ9`
      },
      contact: {
        info: {
          enrolled: {
            whatsapp: `+58 4122318073`
          },
          unregistered: {
            whatsapp: `+57 311-2540133`
          }
        }
      }
    },
    philosophy: {
      seo: {
        keywords: `Educacion, modelo educativo, calidad educativa, educacion de calidad, enseñanza virtual`
      }
    }
  },
  sections: {
    footer: {
      column1: {
        text: `<p>La plataforma de educación formal online Dawere y el Centro de Enseñanza Milenium  han formado una alianza educativa para traerte el mejor Bachillerato Virtual.  </p><p>El Centro de Enseñanza Milenium  está registrado ante la Secretaría de Educación de Honduras bajo el acuerdo No. 15278-SE-2017.</p><p class="fs-small">Choloma, Cortes. Carretera a Ticamaya Km 1, frente a la cancha de fútbol triple "A", Honduras.</p>`
      }
    }
  }
};

export default esHN;

export { esHN };
