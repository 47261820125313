import { API } from 'api';
import { i18n } from 'dawere-commons';
import {
  Button,
  Col,
  Copier,
  Flex,
  Icons,
  Pagination,
  Row,
  Search,
  Skeleton,
  Table,
  Visible
} from 'dawere-uic';
import { useOrganization } from 'hooks';
import { SearchKeyword } from 'pages/private/organizationCharts/components/SearchKeyword';
import { useCallback, useEffect, useMemo, useState } from 'react';

export function CouponsUsedTab() {
  const { organization } = useOrganization();

  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [keyword, setKeyword] = useState('');

  const desktopColumns = useMemo(
    () => [
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.1.full.tabs.3.table.columns.type'
        ),
        accessor: 'type'
      },
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.1.full.tabs.3.table.columns.code'
        ),
        accessor: 'code',
        Cell: ({ cell }: any) => {
          return <Copier text={cell.value} />;
        }
      },
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.1.full.tabs.3.table.columns.assignedTo'
        ),
        accessor: 'assignedTo'
      },
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.1.full.tabs.3.table.columns.date'
        ),
        accessor: 'date'
      }
    ],
    []
  );

  const mobileColumns = useMemo(
    () => [
      {
        Header: i18n(
          'v2.pages.organizationDashboard.tabs.1.full.tabs.3.table.columns.coupon'
        ),
        accessor: 'id',
        Cell: ({ value, row }: any) => {
          return (
            <div>
              <strong className='mb-0 d-block fs-1 mb-2'>
                {row.original?.type}
              </strong>
              <div>
                <Copier text={row.original?.code} />
              </div>
              <div>{row.original?.assignedTo}</div>
              <div>{row.original?.date}</div>
            </div>
          );
        }
      }
    ],
    []
  );

  // Reload data
  useEffect(() => {
    if (organization?.id) {
      setIsLoading(true);

      API.Coupons.list(
        organization.id,
        {
          page: pageIndex,
          size: pageSize,
          keyword: keyword
        },
        'USED'
      )
        .then((response: any) => {
          setData(response.data);
          setPageCount(response.totalPages);
          setTotal(response.totalRows);
        })
        .finally(() => setIsLoading(false));
    }
  }, [organization?.id, pageIndex, pageSize, keyword]);

  const handlePaging = useCallback(({ pageSize, pageIndex }: any) => {
    setPageSize(pageSize);
    setPageIndex(pageIndex);
  }, []);

  const handleSearch = useCallback((data: any) => {
    setKeyword(() => {
      setPageIndex(0);
      return data.search;
    });
  }, []);

  const handleDownload = useCallback(() => {
    if (organization?.id) {
      setIsDownloading(true);

      API.Coupons.list(
        organization.id,
        {
          keyword: keyword
        },
        'USED',
        true
      ).finally(() => setIsDownloading(false));
    }
  }, [keyword, organization?.id]);

  return (
    <>
      <SearchKeyword keyword={keyword} onSearch={handleSearch} />

      <Visible xs sm>
        <Search
          name='search'
          allowEmptySearch
          value={keyword}
          onSubmit={handleSearch}
          disabled={isLoading}
        />
      </Visible>

      <Flex className='mb-2' align='center' justify='between'>
        <div></div>

        <Visible md lg xl>
          <div>
            <Button
              variant='clear'
              className='ml-2'
              disabled={isLoading}
              loading={isDownloading}
              onClick={handleDownload}
              icon={<Icons.Download />}>
              {i18n(
                'v2.pages.organizationDashboard.tabs.1.full.tabs.3.buttons.download'
              )}
            </Button>
          </div>
        </Visible>
      </Flex>

      <Visible xs sm>
        <Table
          data={data}
          columns={mobileColumns}
          loading={isLoading}
          pageable={false}
          sortable={false}
        />
      </Visible>

      <Visible md lg xl>
        <Table
          data={data}
          columns={desktopColumns}
          loading={isLoading}
          pageable={false}
          sortable={false}
        />
      </Visible>

      <Row>
        <Visible lg xl>
          <Col xs={3} />
        </Visible>

        <Col>
          <Pagination
            align='center'
            className='my-4'
            onUpdate={handlePaging}
            pageIndex={pageIndex}
            pageCount={pageCount}
            disabled={isLoading}
          />
        </Col>

        <Visible md lg xl>
          <Col xs={3} className='d-flex align-items-center justify-content-end'>
            {isLoading ? (
              <Skeleton width={120} wrapper='small' />
            ) : (
              <small>
                <strong>{total}</strong>{' '}
                {i18n(
                  'v2.pages.organizationDashboard.tabs.1.full.tabs.3.table.count'
                )}
              </small>
            )}
          </Col>
        </Visible>
      </Row>
    </>
  );
}
