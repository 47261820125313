import { i18n } from 'dawere-commons';
import { Skeleton, Transcripts } from 'dawere-uic';

export function Historical({
  program,
  transcripts = {},
  isLoading = true
}: any) {
  if (isLoading) {
    return <Skeleton.Transcripts className='mb-3' />;
  }

  console.log(transcripts);

  return (
    <Transcripts className='mb-4'>
      <Transcripts.Summary
        obtainedCredits={transcripts?.approvedCredits}
        enrolledCredits={transcripts?.inProgressCredits}
        creditsRemaining={
          program?.creditsToGraduate - transcripts?.approvedCredits
        }
        creditsPerEnrollment={program?.maxEnrolmentCredits}
        creditsToGraduate={program?.creditsToGraduate}
      />

      {transcripts?.data?.map((period: any) => (
        <Transcripts.Column
          key={period.id}
          name={period.name}
          yearGPA={period.yearGPA}
          cumulativeGPA={period.cumulativeGPA}>
          {period.subjects?.map((subject: any) => (
            <Transcripts.Card
              key={subject.id}
              name={subject.name}
              thumbnail={subject.icon}
              school={subject.school}
              credits={subject.credits}
              grade={subject.grade}
              maxGrade={program?.maxGrade}
              score={subject.score}
              maxScore={program?.maxScore}
              status={i18n(`v2.pages.studentDetails.tabs.3.${subject.status}`)}
              badgeColor={subject.badgeColor}
              date={subject.date}
              pending={subject.isPending}
              deprecated={subject.isDeprecated}
            />
          ))}
        </Transcripts.Column>
      ))}
    </Transcripts>
  );
}
