import {
  Celebration,
  CreditCardOff,
  CreditScore,
  DocumentScanner,
  DownloadDone,
  EscalatorWarning,
  Face,
  GroupAdd,
  HourglassBottom,
  LocalOffer,
  NotificationImportant,
  RemoveCircle,
  SupportAgent,
  UploadFile,
  Verified
} from '@mui/icons-material';
import { Rest } from 'dawere-commons';
import { Utils } from 'dawere-uic';
import { PATHS } from 'router';
import { QPARAMS } from 'values';

export class Notifications {
  public static list({
    size,
    page,
    read = null
  }: {
    size: number;
    page: number;
    read: null | true | false;
  }) {
    return Rest.get('notification/app-messages', {
      params: { read, size, page }
    }).then(({ data }: any) => {
      data.content = data.content.map((e: any) => this.mapNotification(e));
      return data;
    });
  }

  public static getIcon(campaignId: string): React.ReactNode {
    const defaultIcon: any = <NotificationImportant />;

    switch (campaignId) {
      case 'ENLACE_DE_RECOMENDACION_HA_SIDO_USADO':
        return <Celebration />;
      case 'VERIFY_ACCOUNT':
        return <Verified />;
      case 'TE_REGISTRASTE_A_TRAVES_DE_UN_ENLACE_DE_REFERENCIA':
        return <GroupAdd />;
      case 'ORGANIZATION_PAYMENT_REJECTED':
        return <CreditCardOff />;
      case 'COEVALUATIONSCOREREADY':
        return <DownloadDone />;
      case 'REJECTED_PAY':
        return <CreditCardOff />;
      case 'ENROLLMENT_DOCUMENTS_DIGITAL':
      case 'ENROLLMENT_DOCUMENTS_ORIGINALS':
        return <UploadFile />;
      case 'QUOTES_EXPIRED':
      case 'QUOTE_ABOUT_TO_EXPIRE':
        return <HourglassBottom />;
      case 'STUDENT_RETENTION':
        return <DocumentScanner />;
      // case 'UNCOMPLETED_ORGANIZATION_PROFILE':
      //   break;
      case 'WELCOME_PARENT':
        return <EscalatorWarning />;
      case 'PAYMENT_SUCCESS':
        return <CreditScore />;
      case 'INVITE_PARENT_REMINDER':
        return <Face />;
      case 'COUPON_DELETED':
        return <RemoveCircle />;
      case 'COUPON_ASSIGNED':
        return <LocalOffer />;
      case 'PAYMENT_INFO_SENT':
        return <SupportAgent />;
      default:
        return defaultIcon;
    }
  }

  private static processParams(params: any) {
    const finalParams: any = {};

    if (Utils.isObject(params) && !Utils.isEmpty(params)) {
      Object.entries(params).forEach(([key, value]) => {
        let pKey = key.replaceAll('.id', 'Id').replaceAll('.name', 'Name');

        finalParams[pKey] = value;
      });
    }
    return finalParams;
  }

  public static mapNotification(notification: any) {
    let path = Utils.has(notification.data, 'url')
      ? notification.data.url
      : null;
    const params = this.processParams(notification.data);

    if (path) {
      path = path
        .replaceAll('https://dev.dawere.com', '')
        .replaceAll('http://dev.dawere.com', '')
        .replaceAll('https://dawere.com', '');
    }

    switch (notification.campaignId) {
      case 'ENLACE_DE_RECOMENDACION_HA_SIDO_USADO':
        path = PATHS.get('WALLET', params);
        break;
      case 'ORGANIZATION_PAYMENT_REJECTED':
        path = PATHS.get('ORGANIZATIONS_DASHBOARD', params, {
          [QPARAMS.Tab]: 4
        });
        break;
      case 'REJECTED_PAY':
        path = PATHS.get('STUDENTS_DASHBOARD', params, {
          [QPARAMS.Tab]: 3,
          [QPARAMS.Subtab]: 0
        });
        break;
      case 'QUOTES_EXPIRED':
      case 'QUOTE_ABOUT_TO_EXPIRE':
        path = PATHS.get('STUDENTS_DASHBOARD', params, {
          [QPARAMS.Tab]: 3,
          [QPARAMS.Subtab]: 1
        });
        // path = PATHS.get('PAY', params, {
        //   [QPARAMS.Draft]: true,
        //   [QPARAMS.Redirect]: '%2Fpanel%2Festudiante%3Ftab%3D3'
        // });
        break;
      case 'ENROLLMENT_DOCUMENTS_DIGITAL':
      case 'ENROLLMENT_DOCUMENTS_ORIGINALS':
      case 'STUDENT_RETENTION':
        path = PATHS.get('STUDENTS_DASHBOARD', params, {
          [QPARAMS.Tab]: 1
        });
        break;
      case 'UNCOMPLETED_ORGANIZATION_PROFILE':
        // path = PATHS.get('EDIT_ORGANIZATIONS', params);
        break;
      case 'WELCOME_PARENT':
        path = PATHS.get('PROFILE', params);
        break;
      case 'INVITE_PARENT_REMINDER':
        path = PATHS.get('PERMISSIONS', params);
        break;
      default:
        break;
    }

    return {
      id: notification.id,
      campaign: notification.campaignId,
      title: notification.subject,
      level: notification.level,
      read: notification.read,
      time: notification.createdAt,
      description: notification.content,
      params,
      to: path
    };
  }

  public static getLive() {
    return Rest.get('notification/app-messages', {
      params: { read: false }
    }).then(({ data }: any) => {
      data.content = data.content.map((e: any) => this.mapNotification(e));
      return data.content;
    });
  }

  public static markAsRead(notificationId: string) {
    return Rest.post(`notification/app-messages/${notificationId}/read`);
  }

  public static markAsOpen(notificationId: string) {
    return Rest.post(`notification/app-messages/${notificationId}/interacted`);
  }
}
