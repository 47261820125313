import { i18n } from 'dawere-commons';
import { Block, Button, Col, Show, Testimonials, Utils } from 'dawere-uic';
import { useEffect, useMemo, useState } from 'react';

export function TestimonialsSection() {
  const [show, setShow] = useState(false);

  const testimonials = useMemo(
    () =>
      Utils.shuffle([
        {
          thumbnailUrl:
            'https://storage.googleapis.com/dawere-web-assets/testimonios/Valeria-07.jpg',
          author: 'Valeria',
          profession: 'Tenista',
          videoUrl:
            'https://www.youtube.com/embed/PqDnC9S8ATw?si=2XkYnoRyxj8l8cE7'
        },
        {
          thumbnailUrl:
            'https://storage.googleapis.com/dawere-web-assets/testimonios/Ray-07.jpg',
          author: 'Ray',
          profession: 'Futuro Comunicador Audiovisual',
          videoUrl:
            'https://www.youtube.com/embed/d26cJVjTdTY?si=f-7iD3qdOQMTYNwL'
        },
        {
          thumbnailUrl:
            'https://storage.googleapis.com/dawere-web-assets/testimonios/Evariet-07.jpg',
          author: 'Evariet',
          profession: 'Futura odontóloga',
          videoUrl:
            'https://www.youtube.com/embed/_n33OfWF4D4?si=J258ksF33c1NEGY5'
        },
        {
          thumbnailUrl:
            'https://storage.googleapis.com/dawere-web-assets/testimonios/Miguel-07.jpg',
          author: 'Miguel',
          profession: 'Beisbolista',
          videoUrl:
            'https://www.youtube.com/embed/WNOj5_IexOc?si=OO0aT78nOkHit76Q'
        },
        {
          thumbnailUrl:
            'https://storage.googleapis.com/dawere-web-assets/testimonios/Samantha-07.jpg',
          author: 'Samantha',
          profession: 'Futura médico',
          videoUrl:
            'https://www.youtube.com/embed/YNLIsPYblY8?si=SWSsj0_btIVc2S3b'
        },
        {
          thumbnailUrl:
            'https://storage.googleapis.com/dawere-web-assets/testimonios/%C3%81ngela-07.jpg',
          author: 'Ángela',
          profession: 'Futura profesional',
          videoUrl:
            'https://www.youtube.com/embed/d_pxUGRb6ZI?si=_EMUhrVP4V-Uu4EF'
        }
      ]),
    []
  );

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 1);
  }, []);

  return (
    <Block>
      <Col>
        <h2 className='mb-6'>{i18n('v2.pages.home.section7.title')}</h2>

        <Show when={show}>
          <Testimonials entries={testimonials} infinite />
        </Show>

        <div className='text-center mt-4'>
          <Button
            variant='outline'
            className='w-100 w-md-auto'
            href='https://www.google.com/maps/place/UEPA+Dawere/@10.4859018,-66.8267669,17z/data=!4m8!3m7!1s0x8c2a58340260c695:0xaedbda2f1eaf34d1!8m2!3d10.4859018!4d-66.8267669!9m1!1b1!16s%2Fg%2F11f3nl6442?entry=ttu'
            target='_blank'>
            Ver reseñas de Google
          </Button>
        </div>
      </Col>
    </Block>
  );
}
