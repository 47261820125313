import { ZendeskChat } from 'components';
import { Session } from 'dawere-commons';
import { useSetDefaultDashboard } from 'hooks';
import { PageWrapper } from 'pages';
import { Redirect } from 'react-router';
import { getLoginRedirectPath, getSEO } from 'utils';
import { StudentDashboardProvider } from './StudentDashboardProvider';

/**
 * Path: /panel/estudiante/
 * Key: STUDENTS_DASHBOARD
 * @returns {React.ReactElement}
 */
export default function StudentsDashboardPage() {
  useSetDefaultDashboard('STUDENT');
  const account = Session.getProfiles();

  if (!account?.student) {
    const path = getLoginRedirectPath();
    return <Redirect to={path} />;
  }

  return (
    <PageWrapper>
      {getSEO('v2.pages.studentDashboard.seo', true)}

      <ZendeskChat />

      <StudentDashboardProvider
        seenAs='STUDENT'
        rootRoute='STUDENTS_DASHBOARD'
      />
    </PageWrapper>
  );
}
