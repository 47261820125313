import { API } from 'api';
import { i18n } from 'dawere-commons';
import { Document, toast } from 'dawere-uic';
import { useCallback, useState } from 'react';

export function DocumentUploader(props: any) {
  const [isUploading, setIsUploading] = useState(false);
  const [src, setSrc] = useState<any>(props.src);
  const [status, setStatus] = useState<any>(props.status);

  const handleUpload = useCallback(
    (data: any) => {
      setIsUploading(true);

      API.Documents.upload(props.id, {
        content: data.base64,
        fileExtension: data.extension
      })
        .then(({ data }: any) => {
          setSrc(data.path);
          setStatus(API.Documents.getStatus(data.status));
          toast.success(i18n('v2.pages.myDocuments.uploadSuccess'));
        })
        .finally(() => setIsUploading(false));
    },
    [props.id]
  );

  return (
    <Document
      {...props}
      src={src}
      uploading={isUploading}
      status={status}
      onUpload={handleUpload}
      onAskForHelp={() => API.Zendesk.go()}
      accept='application/pdf'
    />
  );
}
