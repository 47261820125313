import { useOrganization } from 'hooks';
import { MainWrapper } from './MainWrapper';
import { OrganizationChartProvider } from './providers/OrganizationChartProvider';

export function ChartTab() {
  const { organization } = useOrganization();

  return (
    <OrganizationChartProvider id={organization?.id}>
      <MainWrapper />
    </OrganizationChartProvider>
  );
}
