import { API } from 'api';
import { i18n, Session } from 'dawere-commons';
import { Loading, useRouter } from 'dawere-uic';
import { useSession, useTokenValidation } from 'hooks';
import { PageWrapper, TwoColumnTemplate } from 'pages';
import { useCallback, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { PATHS } from 'router';
import { getSEO } from 'utils';
import { ASSETS } from 'values';
import { OrganizationSpecialForm } from './forms/OrganizationSpecialForm';

/**
 * Path: /registro/organizacion?t=<token>
 * Key: ORGANIZATION_REGISTER
 * @returns {React.ReactElement}
 */
export default function OrganizationSignupPage() {
  const { decoded, token, isValidating } = useTokenValidation();
  const [isLoading, setIsLoading] = useState(false);
  const { push } = useRouter();
  const { getMe } = useSession();

  const handleSubmit = useCallback(
    async (data: any) => {
      setIsLoading(true);

      API.Account.signup({ ...data, token })
        .then((response: any) => {
          Session.setToken(response.token);
          // Get account info
          getMe().then((response: any) => {
            push(PATHS.SUCCESS_REGISTER);
          });
        })
        .finally(() => setIsLoading(false));
    },
    [getMe, push, token]
  );

  const handleGoogleAuth = useCallback(
    (gToken: string) => {
      setIsLoading(true);

      API.Account.signup(
        {
          token: gToken,
          invitationToken: token
        },
        'google'
      )
        .then((response: any) => {
          Session.setToken(response.token);
          // Get account info
          getMe().then((response: any) => {
            push(PATHS.SUCCESS_REGISTER);
          });
        })
        .finally(() => setIsLoading(false));
    },
    [getMe, push, token]
  );

  if (!decoded) {
    return <Redirect to={PATHS.HOME} />;
  }

  if (isValidating) {
    return <Loading className='py-9' />;
  }

  return (
    <PageWrapper>
      {getSEO('v2.pages.register.seo')}

      <TwoColumnTemplate
        align='start'
        desktopImage={
          <img
            src={ASSETS.ILLUSTRATIONS.STUDENT_EXAM}
            alt={i18n('v2.login.title')}
            className='img-fluid'
          />
        }>
        <h2 className='mb-2'>{i18n('v2.pages.register.title')}</h2>

        <p className='text-primary mb-5'>
          <span className='d-block fs-1 fw-500'>
            {decoded?.organizationName}
          </span>
          {i18n('v2.pages.register.subtext')} <strong>{decoded?.role}</strong>.
        </p>

        <OrganizationSpecialForm
          onSubmit={handleSubmit}
          onGoogleAuth={handleGoogleAuth}
          isLoading={isLoading}
          decoded={decoded}
        />
      </TwoColumnTemplate>
    </PageWrapper>
  );
}
