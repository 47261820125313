import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { ReferralsModal } from 'components';
import { Dawere, Skeleton, ToastContainer } from 'dawere-uic';
import { colors } from 'dawere-uic/lib/styles/colors';
import { AppProvider, LayoutProvider, SessionProvider } from 'providers';
import { BrowserRouter } from 'react-router-dom';
import { RouteMapper } from 'router';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from 'styles';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { createIDBPersister, i18nSetup, languageSetup } from 'utils';

Dawere.setConfiguration({
  defaultScreenClass: 'sm',
  maxScreenClass: 'xl'
});

// Language config
languageSetup();

/* Query setup */
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const persister = createIDBPersister();

function App() {
  // i18n setup
  i18nSetup();

  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister }}>
      <Skeleton.Theme color={colors.light}>
        <AppProvider>
          <SessionProvider>
            <LayoutProvider>
              <ThemeProvider theme={Dawere.theme}>
                {/* Styles */}
                <GlobalStyles />

                <BrowserRouter>
                  <QueryParamProvider adapter={ReactRouter5Adapter}>
                    {/* Scroll to top everytime the URL path changes */}
                    <Dawere.ScrollToTop />
                    {/* Printing all routes in ./router/routes.tsx */}
                    <RouteMapper />
                    {/* Referrals */}
                    <ReferralsModal />
                  </QueryParamProvider>
                </BrowserRouter>

                <ToastContainer />
              </ThemeProvider>
            </LayoutProvider>
          </SessionProvider>
        </AppProvider>
      </Skeleton.Theme>
    </PersistQueryClientProvider>
  );
}

export default App;
