import { Utils } from 'dawere-uic';
import { useContext } from 'react';
import { StudentDashboardContext } from '../StudentDashboardProvider';

export const useStudentDashboard = () => {
  const context = useContext(StudentDashboardContext);

  if (Utils.isEmpty(context)) {
    throw new Error(
      '"useStudentDashboard" must be used within a StudentDashboardProvider'
    );
  }

  return context;
};
